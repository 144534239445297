import { css } from '@emotion/css';
import { DesignToken } from '@livechat/design-system-react-components';

export const container = (isVisible): string => css`
  width: 100%;
  height: 100%;
  overflow: auto;
  display: ${isVisible ? 'block' : 'none'};
`;

export const widgetContainer = (isVisible = true): string => css`
  height: 100%;
  display: ${isVisible ? 'block' : 'none'};
  overflow: hidden;
  width: 100%;
  background-color: var(${DesignToken.SurfacePrimaryDefault});
`;
