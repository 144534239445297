import type { FC } from 'react';

import { cx } from '@emotion/css';
import { Text } from '@livechat/design-system-react-components';
import { useSelector } from 'react-redux';

import { useListSuggestions } from 'hooks/use-list-suggestions';
import { getIsOneModalExpanded } from 'store/views/one/selectors';

import { deserializeSuggestionWithShortcuts } from '../helpers';

import * as styles from './styles';

interface IProps {
  className?: string;
}

export const Placeholder: FC<IProps> = ({ className }) => {
  const isOneExpanded = useSelector(getIsOneModalExpanded);
  const { data } = useListSuggestions();
  const suggestions = deserializeSuggestionWithShortcuts(data);

  if (!isOneExpanded && suggestions.length) {
    return (
      <Text className={cx(styles.messageBoxTip, className)}>
        Type <strong>/</strong> to get suggestions
      </Text>
    );
  }

  return <div className={cx(styles.placeholder, className)}>Your message</div>;
};
