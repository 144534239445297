import { type FC, useRef } from 'react';

import { css } from '@emotion/css';
import { DesignToken } from '@livechat/design-system-react-components';

import { SafeIframe } from 'components/safe-iframe/SafeIframe';
import { IFRAME_SCOPES } from 'constants/integration-widget';
import { getConfig } from 'helpers/config';
import { getAccessToken } from 'services/auth/auth-storage-manager';

import { useIframeMessenger } from './use-iframe-messenger';

const wrapper = css`
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: var(${DesignToken.SurfacePrimaryDefault});
`;

const iframe = css`
  width: 100%;
  height: 100%;
`;

const ChatbotInLivechat: FC = () => {
  const token = getAccessToken();
  const iframeUrl = `${getConfig().chatbotInLivechatApiUrl}`;
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const { onMessage, sendMessage } = useIframeMessenger(iframeRef, 'chatbotApp');
  const handleSendMessage = (): void => {
    sendMessage({ type: 'chatbotApp', token });
  };
  const handleMessage = (): void => {
    handleSendMessage();
  };

  onMessage(handleMessage);

  return (
    <div className={wrapper}>
      <SafeIframe ref={iframeRef} className={iframe} src={iframeUrl} allow={IFRAME_SCOPES} />
    </div>
  );
};

export default ChatbotInLivechat;
