import { css } from '@emotion/css';
import { SpacingToken } from '@livechat/design-system-react-components';

export const loaderItemWrapper = css`
  display: flex;
  flex-flow: column nowrap;
  gap: var(${SpacingToken.Spacing2});

  > div:last-of-type {
    display: none;
  }

  @media (min-width: 500px) {
    flex-flow: row nowrap;
    justify-content: space-between;

    > div:last-of-type {
      display: flex;
      align-items: flex-end;
    }
  }
`;

export const loaderPaymentWrapper = css`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  gap: var(${SpacingToken.Spacing2});

  @media (min-width: 500px) {
    flex-flow: row;
  }
`;

export const alert = css`
  margin-bottom: 30px;

  div {
    margin-right: 0;
  }

  h5 {
    margin: 0 0 var(${SpacingToken.Spacing2});
  }

  button {
    margin-top: var(${SpacingToken.Spacing3});
  }
`;

export const divider = css`
  margin: var(${SpacingToken.Spacing5}) 0;
`;
