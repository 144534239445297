import { type FC } from 'react';

import { cx } from '@emotion/css';
import { Text } from '@livechat/design-system-react-components';
import Markdown from 'react-markdown';

import { OneLinkWithTooltip } from './OneLinkWithTooltip';

import * as styles from './styles';

interface Props {
  hasCancelledRequest?: boolean;
  text: string;
}

export const OneAgentMessage: FC<Props> = ({ hasCancelledRequest, text }) => (
  <Text className={cx(styles.popoverAgentMessage, { [styles.cancelledMessage]: hasCancelledRequest })}>
    <Markdown
      className={styles.markdown}
      components={{
        a: ({ children, href }) => <OneLinkWithTooltip href={href}>{children}</OneLinkWithTooltip>,
      }}
    >
      {text}
    </Markdown>
  </Text>
);
