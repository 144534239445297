import type { FC } from 'react';

import { BaseRouteName } from 'constants/routes';
import { useParamSelector } from 'hooks/use-param-selector';
import { getNavigationItemBadge } from 'store/views/navigation/selectors';

import { NavigationBadge } from './navigation-badge/NavigationBadge';

export const TicketsBadge: FC = () => {
  const badge = useParamSelector(getNavigationItemBadge, BaseRouteName.Tickets);
  const ticketsCount = badge?.numeric;

  return ticketsCount ? <NavigationBadge count={ticketsCount} /> : null;
};
