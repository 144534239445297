import type { Charge } from './interfaces';

export const formatDecimalPrice = (pence: number): string => {
  return (pence / 100).toFixed(2);
};

export const calculateChargePrice = ({
  charge,
  agentsCount,
}: {
  charge: Charge;
  agentsCount: number;
}): {
  taxRate: number;
  unitPrice: number;
  unitPriceTax: number;
  totalPrice: number;
  totalPriceTax: number;
} => {
  const agentsMultiplier = charge?.per_account ? agentsCount : 1;
  const taxes = charge?.taxes;

  let unitPrice = charge?.price || 0;
  let totalPrice = unitPrice * agentsMultiplier;
  let totalPriceTax = 0;
  let unitPriceTax = 0;
  let taxRate = 0;

  if (taxes) {
    totalPrice = taxes.total_price;
    unitPrice = totalPrice / agentsMultiplier;
    totalPriceTax = taxes.tax_value;
    unitPriceTax = totalPriceTax / agentsMultiplier;
    taxRate = taxes.tax_rate * 100;
  }

  return {
    taxRate,
    unitPrice,
    unitPriceTax,
    totalPrice,
    totalPriceTax,
  };
};
