export const SPOTLIGHT_TRIGGER_TEST_ID = 'spotlight-trigger';

export const SPOTLIGHT_ACTIONS_RELEASE_DATE = '2024-08-13';

const SPOTLIGHT_ACTIONS_HELP_CENTER_URL = 'https://www.livechat.com/help/search-navigate-your-livechat-app/';

export const SPOTLIGHT_ACTIONS_ONBOARDING_MESSAGE = {
  agent: 'How do I use the search bar?',
  one: `Hold tight, things are about to speed up! 🚀

Apart from being able to:
- navigate LiveChat
- search through archives
- ask One for help

Now you can also:
- update the chat you’re currently handling
- change your chatting status

You can end a chat, transfer it, get a reply suggestion, or change your chatting status using only your keyboard.

Check the [full list of available actions](${SPOTLIGHT_ACTIONS_HELP_CENTER_URL}) or ask me directly. 😎`,
  delay: 3000,
};
