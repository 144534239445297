import type { FC } from 'react';

import { Text, DesignToken } from '@livechat/design-system-react-components';
import { useSelector } from 'react-redux';

import { plural } from 'helpers/string';
import { withVar } from 'helpers/styles';
import { calculateChargePrice } from 'routes/apps/one-click-payment/helpers';
import { useOneClickPaymentContext } from 'routes/apps/one-click-payment/hooks/use-one-click-payment-context';
import { getActiveAgentsCount } from 'store/entities/agents/selectors';

import { PriceFormatter } from './PriceFormatter';

import * as styles from './styles';

export const PriceSummary: FC = () => {
  const { charge } = useOneClickPaymentContext();
  const agentsCount = useSelector(getActiveAgentsCount);

  if (!charge) {
    return null;
  }

  const isPerAccount = charge?.per_account;
  const frequency = charge?.type;
  const months = charge?.months || 1;

  const { unitPrice, totalPrice, totalPriceTax, taxRate } = calculateChargePrice({
    charge,
    agentsCount,
  });

  return (
    <div className={styles.summaryWrapper}>
      <Text size="md" bold>
        <PriceFormatter value={totalPrice} frequency={frequency} months={months} />
      </Text>

      {totalPriceTax > 0 && (
        <Text size="xs" customColor={withVar(DesignToken.ContentBasicSecondary)}>
          Includes tax {taxRate}% (<PriceFormatter value={totalPriceTax} />)
        </Text>
      )}

      {isPerAccount && agentsCount > 0 && (
        <Text size="xs" customColor={withVar(DesignToken.ContentBasicSecondary)}>
          <PriceFormatter value={unitPrice} /> ✕ ${agentsCount} {plural(agentsCount, 'Agent', 'Agents')}
        </Text>
      )}

      {!isPerAccount && (
        <Text size="xs" customColor={withVar(DesignToken.ContentBasicSecondary)}>
          Per organization
        </Text>
      )}
    </div>
  );
};
