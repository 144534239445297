import { DesignToken } from '@livechat/design-system-react-components';

import { TOP_BAR_HEIGHT } from 'components/notifications-bar/constants';
import { CONTENT_PAGE_MARGIN, SPOTLIGHT_MEDIA_QUERY } from 'constants/spotlight';
import { isSpotlightEnabled } from 'helpers/spotlight';
import { withVar } from 'helpers/styles';

export const common = `
  html,
  body {
    height: 100%;
  }

  body {
    font-size: 16px;
    background: var(--background);
    display: flex;

    // Hide chat bubble only, but leaves chat itself accessible
    #chat-widget-container {
      [id|='widget-global-'] > div > div > div {
        @media (max-width: 705px) {
          display: none;
        }
      }
    }
  }

  body,
  input,
  textarea,
  table,
  button {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  a {
    color: var(--action-primary-default);
    text-decoration: none;
    outline: none !important;
    &:hover {
      text-decoration: underline;
    }
  }

  hr {
    clear: both;
    padding: 0;
    margin: 0;
    height: 1px;
    background: #e6e6e6;
    border: 0;
  }

  label {
    cursor: pointer;

    &.disabled {
      cursor: auto;
    }
  }

  /* clearfix */
  #content:after,
  .state:after,
  #archives .filters:after,
  .clearfix:after,
  #settings-content-wrapper:after,
  #settings-content:after,
  #settings .options .item:after,
  #settings .options .switch:after,
  #settings-new .options .item:after,
  #settings-new .options .switch:after,
  #settings-fallback .options .item:after,
  #settings-fallback .options .switch:after,
  #theme:after,
  #themes:after,
  #code:after,
  #tutorials .category:after,
  #canned-responses .form:after,
  #agents .agent .card:after,
  #subscription-plans:after,
  .single-sheet .body dl:after,
  .single-sheet .body:after,
  #webhooks .webhook.new:after,
  #apps .links:after {
    content: '';
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }

  body.tutorial,
  body.hide-nav,
  body.install-code,
  body.onboarding {
    .plugins {
      display: none !important;
    }
    #notifications-bar-container {
     display: none;
    }
    #mobile-navigation {
      display: none;
    }
  }

  body.hide-nav {
    #top-container,
    #notification-bar-offline,
    #notification-bar-onboarding-progress,
    #notification-bar-migration,
    .plugins {
      display: none !important;
    }
    #hide-nav-logo {
      display: block;

      .back-to-previous-page,
      .back-to-login-screen {
        display: none;
      }
    }
  }

  body.hide-nav {
    #hide-nav-logo {
      display: none;
    }
    #content {
      padding-top: 0;
    }
  }
  body.offer-back-to-previous-page #hide-nav-logo .back-to-previous-page {
    display: block;
  }
  body.offer-back-to-login-screen #hide-nav-logo .back-to-login-screen {
    display: block;
  }

  // part of mailing.hbs
  .livechat-logo {
    background: url('../img/logo-livechat.svg') center center no-repeat;
    height: 50px;
    background-size: auto 40px;
    width: 177px;
    display: inline-block;
    vertical-align: middle;
  }
  .livechat-icon {
    background: url('../img/icon-livechat.svg') center center no-repeat;
    background-size: auto;
    height: 32px;
    width: 32px;
  }

  body.install-code,
  body.onboarding {
    &.hide-nav {
      #top-container,
      #notification-bar-status,
      #notification-bar-reconnect,
      #notification-bar-offline,
      #notification-bar-migration,
      #notification-bar-onboarding-progress,
      #learning-center,
      #company-details-overlay,
      #chat-tutorial-overlay,
      .plugins {
        display: none !important;
      }
      #hide-nav-logo {
        display: block;

        .livechat-logo {
          background: url('../img/logo-livechat.svg') center center no-repeat;
          height: 106px;
          background-size: auto 40px;
          width: 100%;
        }
      }
    }

    .content-container.collapsed {
      padding-top: 0 !important;
    }

    #mobile-navigation {
      display: none;
    }
  }

  body.install-code,
  body.onboarding {
    background-color: white;

    #content {
      background-color: white;
      padding: 0;
      width: 100%;
      height: 100vh;
      display: flex;
    }

    #spotlight-container {
      display: none;  
    }  


    &.hide-nav {
      #hide-nav-logo {
        display: none;
      }
    }
  }

  // part of content.hbs
  .page-wrapper {
    display: flex;
    min-height: 100vh;
    width: 100vw;
    background-color: ${isSpotlightEnabled ? withVar(DesignToken.NavbarBackground) : 'transparent'};

    .nav-container {
      width: 54px;
      min-height: 1px;
      flex-shrink: 0;

      @media (max-width: 705px), (pointer: coarse), (pointer: fine) and (hover: none) {
        body:not(.force-desktop-view) & {
          display: none;
        }
      }

      // Navigation must have width because of nav tooltips which render in the background
      body.install-code &,
      body.onboarding &,
      body.hide-nav & {
        opacity: 0;
        visibility: hidden;
      }
    }

    @media (min-width: 706px) and (hover: hover) and (pointer: fine), (pointer: none) {
      body.install-code &,
      body.onboarding &,
      body.hide-nav & {
        .content-container {
          margin-left: -54px;
        }
      };
    }

    body.force-desktop-view & {
      body.install-code &,
      body.onboarding &,
      body.hide-nav & {
        .content-container {
          margin-left: -64px;
        }
      }
    }

    body.force-desktop-view.install-code &,
    body.force-desktop-view.onboarding &,
    body.force-desktop-view.wizard &,
    body.force-desktop-view.hide-nav & {
      .content-container {
        margin-left: -64px;
      }
    }

    .content-container {
      flex: 1;
      height: 100vh;
      min-width: 0;
      margin-top: 0;
      transition: margin-top 0.4s cubic-bezier(0.33, 0, 0.67, 1), height 0.4s cubic-bezier(0.33, 0, 0.67, 1),
        padding-top 0.4s cubic-bezier(0.33, 0, 0.67, 1);
      overflow: hidden;
      box-sizing: border-box;

      @media (max-width: 705px), (pointer: coarse), (pointer: fine) and (hover: none) {
        body:not(.force-desktop-view):not(.onboarding) & {
          height: calc(100vh - 56px);

          &.full-height {
            height: 100vh;
          }
        }
      }
    }
  }

  // part of content.hbs
  #notifications-bar-container {
    height: 0;
    overflow: hidden;

    @media (max-width: ${SPOTLIGHT_MEDIA_QUERY}px) {
      height: 0;
    }
  }

  #content {
    margin: 0 auto;
    padding: 50px 20px 0;
    position: relative;
    z-index: 0;
    overflow: ${isSpotlightEnabled ? 'hidden' : 'auto'};
    height: ${isSpotlightEnabled ? `calc(100% - ${TOP_BAR_HEIGHT} - ${CONTENT_PAGE_MARGIN}px)` : '100%'};

    @media (max-width: ${SPOTLIGHT_MEDIA_QUERY}px) {
      height: 100% !important;
    }

    &.no-animation {
      transition-delay: 0ms !important;
      transition: none !important;
      transition-property: none !important;
    }

    &.full-size {
      margin: ${isSpotlightEnabled ? `0 ${CONTENT_PAGE_MARGIN}px ${CONTENT_PAGE_MARGIN}px 0` : 0};
      padding: 0;
      border-radius: ${isSpotlightEnabled ? 16 : 0}px;

      @media (max-width: ${SPOTLIGHT_MEDIA_QUERY}px) {
        border-radius: 0;
        margin: 0;
      }

      .main-section {
        width: 100%;
        height: 100%;
        overflow: auto;
      }

      &::after {
        content: ' ';
      }
    }

    .main-section {
      /*
      hide all sections by default to make sure that
      Backbones 'isVisible' method returns false until
      the particular view is opened by the user
      */
      display: none;
    }
  }

  // part of mailing.hbs
  .login-box-header {
    position: relative;
    width: 100%;
    margin: 0 auto 30px auto;

    .logo {
      display: block;
      margin: 0 auto;
    }
  }

  /* Loading text used throughout the app */
  .loading {
    text-align: center;
    font-size: 64px;
    line-height: 128px;
    color: #bbb;
    margin: 0 auto;
  }

  // used id debug.hbs
  #debug-info {
    position: fixed;
    top: 20px;
    right: 20px;
    color: red;
    font-size: 0.7em;
    line-height: 1em;
    height: 1.2em;
    z-index: 10005;
  }

  #debug-info .close {
    cursor: pointer;
    display: none;
    position: absolute;
    right: -3px;
    top: -7px;
    font-size: 11px;
    background: white;
    color: black;
    width: 12px;
    height: 12px;
    line-height: 10px;
    text-align: center;
    border-radius: 6px;
  }

  .fade-in-animation {
    rect,
    path {
      transition: fill 0.3s ease;
    }
  }
`;
