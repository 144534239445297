import { css } from '@emotion/css';

export const wrapper = css`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const summary = css`
  text-align: right;
  font-size: 18px;
  font-weight: 600;
  max-width: 220px;
  line-height: 20px;

  span {
    font-size: 13px;
    font-weight: 400;
    opacity: 0.7;
    margin-left: 8px;
    line-height: 16px;
  }
`;

export const bullets = css`
  margin-right: 5px;
`;

export const creditCard = css`
  display: flex;
  align-items: center;
  font-weight: 15px;

  > p {
    font-weight: 600;
    margin-right: 20px;
  }
`;

export const creditCardLogo = css`
  width: 26px;
  height: 18px;
  margin-right: 10px;
`;
