import { useMemo, useState, type FC } from 'react';

import { OneClickPaymentContext } from './hooks/use-one-click-payment-context';
import { useOneClickPaymentPostMessageEvents } from './hooks/use-one-click-payment-post-messages';
import { PurchaseModal } from './modals/PurchaseModal/PurchaseModal';
import { RequestBuyModal } from './modals/RequestBuyModal/RequestBuyModal';

export const OneClickPayment: FC = () => {
  const [isPurchaseModalOpen, setIsPurchaseModalOpen] = useState(false);
  const [isRequestBuyModalOpen, setIsRequestBuyModalOpen] = useState(false);

  const { isLoading, isUpdating, error, item, charge, isProcessable, updateBillingCycle, closeModal } =
    useOneClickPaymentPostMessageEvents({
      onModalOpen: (canUserBuy) => {
        if (canUserBuy) {
          setIsPurchaseModalOpen(true);
        } else {
          setIsRequestBuyModalOpen(true);
        }
      },
      onModalClose: () => {
        setIsPurchaseModalOpen(false);
        setIsRequestBuyModalOpen(false);
      },
    });

  const value = useMemo(
    () => ({
      item,
      charge,
      error,
      closeModal,
      updateBillingCycle,
      isLoading,
      isUpdating,
      isProcessable,
    }),
    [isLoading, item, charge, error, isUpdating, isProcessable, closeModal, updateBillingCycle]
  );

  return (
    <OneClickPaymentContext.Provider value={value}>
      {isPurchaseModalOpen && <PurchaseModal />}
      {isRequestBuyModalOpen && <RequestBuyModal />}
    </OneClickPaymentContext.Provider>
  );
};
