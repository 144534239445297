import { type StateSnapshot } from 'react-virtuoso';

import { type ActionsUnion, createAction } from 'store/helper';

export enum OneViewActionNames {
  RESET_ONE_UNREAD_MESSAGES_COUNT = 'VIEWS/ONE/RESET_LIVE_ASSISTANT_UNREAD_MESSAGES_COUNT',
  SET_ONE_IS_LOADING = 'VIEWS/ONE/SET_LIVE_ASSISTANT_IS_LOADING',
  INCREASE_ONE_UNREAD_MESSAGES_COUNT = 'VIEWS/ONE/INCREASE_LIVE_ASSISTANT_UNREAD_MESSAGES_COUNT',
  SET_ONE_LIST_STATE_SNAPSHOT = 'VIEWS/ONE/SET_LIVE_ASSISTANT_LIST_STATE_SNAPSHOT',
  SAVE_ONE_DRAFT_MESSAGE = 'VIEWS/ONE/SAVE_LIVE_ASSISTANT_DRAFT_MESSAGE',
  REMOVE_ONE_DRAFT_MESSAGE = 'VIEWS/ONE/REMOVE_LIVE_ASSISTANT_DRAFT_MESSAGE',
  SHOW_ONE_MODAL = 'VIEWS/ONE/SHOW_ONE_MODAL',
  HIDE_ONE_MODAL = 'VIEWS/ONE/HIDE_ONE_MODAL',
  TOGGLE_ONE_MODAL_EXPAND = 'VIEWS/ONE/TOGGLE_ONE_MODAL_EXPAND',
  TOGGLE_ONE_MODAL = 'VIEWS/ONE/TOGGLE_ONE_MODAL',
  SET_SCROLL_POSITION = 'VIEWS/ONE/SET_SCROLL_POSITION',
  OPEN_ONE_DETAILS = 'VIEWS/ONE/OPEN_ONE_DETAILS',
  CLOSE_ONE_DETAILS = 'VIEWS/ONE/CLOSE_ONE_DETAILS',
  SHOW_ONE_NEW_MESSAGE_TOAST = 'VIEWS/ONE/SHOW_ONE_MESSAGE_TOAST',
  HIDE_ONE_NEW_MESSAGE_TOAST = 'VIEWS/ONE/HIDE_ONE_MESSAGE_TOAST',
  OPEN_COMMAND_SUGGESTIONS = 'VIEWS/ONE/OPEN_COMMAND_SUGGESTIONS',
  CLOSE_COMMAND_SUGGESTIONS = 'VIEWS/ONE/CLOSE_COMMAND_SUGGESTIONS',
}

export const OneViewActions = {
  resetOneUnreadMessagesCount: () => createAction(OneViewActionNames.RESET_ONE_UNREAD_MESSAGES_COUNT),
  setOneIsLoading: (isLoading: boolean) => createAction(OneViewActionNames.SET_ONE_IS_LOADING, isLoading),
  increaseOneUnreadMessagesCount: (count: number) =>
    createAction(OneViewActionNames.INCREASE_ONE_UNREAD_MESSAGES_COUNT, count),
  setOneListStateSnapshot: (listStateSnapshot: StateSnapshot | null) =>
    createAction(OneViewActionNames.SET_ONE_LIST_STATE_SNAPSHOT, listStateSnapshot),
  saveOneDraftMessage: (draftMessage: string) => createAction(OneViewActionNames.SAVE_ONE_DRAFT_MESSAGE, draftMessage),
  removeOneDraftMessage: () => createAction(OneViewActionNames.REMOVE_ONE_DRAFT_MESSAGE),
  showModal: () => createAction(OneViewActionNames.SHOW_ONE_MODAL),
  hideModal: () => createAction(OneViewActionNames.HIDE_ONE_MODAL),
  toggleModal: () => createAction(OneViewActionNames.TOGGLE_ONE_MODAL),
  toggleExpand: () => createAction(OneViewActionNames.TOGGLE_ONE_MODAL_EXPAND),
  setScrollPosition: (scrollPosition: number | null) =>
    createAction(OneViewActionNames.SET_SCROLL_POSITION, scrollPosition),
  openDetails: () => createAction(OneViewActionNames.OPEN_ONE_DETAILS),
  closeDetails: () => createAction(OneViewActionNames.CLOSE_ONE_DETAILS),
  showMessageToast: () => createAction(OneViewActionNames.SHOW_ONE_NEW_MESSAGE_TOAST),
  hideMessageToast: () => createAction(OneViewActionNames.HIDE_ONE_NEW_MESSAGE_TOAST),
  openCommandSuggestions: () => createAction(OneViewActionNames.OPEN_COMMAND_SUGGESTIONS),
  closeCommandSuggestions: () => createAction(OneViewActionNames.CLOSE_COMMAND_SUGGESTIONS),
};

export type OneViewAction = ActionsUnion<typeof OneViewActions>;
