import type { FC } from 'react';

import { useSelector } from 'react-redux';

import { shouldShowBadgeInChatbotInLivechatNavItem } from 'store/entities/chatbot-in-livechat/selectors';

import { NavigationBadge } from './navigation-badge/NavigationBadge';

export const ChatbotBadge: FC = () => {
  const shouldBeShown = useSelector(shouldShowBadgeInChatbotInLivechatNavItem);

  return shouldBeShown ? <NavigationBadge kind="secondary" count={1} /> : null;
};
