import { type SagaIterator } from 'redux-saga';
import { all, call, put, select, take, takeEvery } from 'redux-saga/effects';

import { GlobalModal } from 'constants/global-modal';
import { Right } from 'constants/right';
import { Scope } from 'constants/scope';
import { EventPlace } from 'helpers/analytics';
import { getConfig } from 'helpers/config';
import { isGhostLogin } from 'helpers/ghost';
import { redirectToSessionAbuse } from 'helpers/routing';
import { getScopes } from 'services/auth/auth-storage-manager';
import { trackEvent } from 'services/event-tracking';
import { logout } from 'services/session';
import { RequestAction } from 'store/entities/actions';
import { ProfileMenuEvent } from 'store/entities/agents/constants';
import { getLoggedInAgentSessionsInLC } from 'store/entities/agents/selectors';
import { SubscriptionActionNames } from 'store/entities/subscription/actions';
import { getSubscriptionSeats } from 'store/entities/subscription/selectors';
import { AgentCustomPropertyName } from 'store/features/agent-custom-properties/interfaces';
import { handleSetCustomProperty } from 'store/features/agent-custom-properties/sagas';
import {
  getExceededSessionsInfo,
  getHomeScreenChecklistClosed,
} from 'store/features/agent-custom-properties/selectors';
import { GlobalModalActions } from 'store/features/global-modals/actions';
import { getIsOnboardingCompleted } from 'store/views/home-screen/computed';

import { AgentCustomPropertiesActions, AGENT_CUSTOM_PROPERTIES } from '../agent-custom-properties/actions';

import { SessionActionNames, SessionActions } from './actions';
import { EXCEEDED_SESSIONS_LIMIT } from './constants';
import { type IRights } from './interfaces';

const MAX_SESSIONS_ALLOWED = 1;

function hasAnyOfScopes(allScopes: Scope[], requiredScopes: Scope[]): boolean {
  return allScopes.some((scope) => requiredScopes.includes(scope));
}

function hasAllScopes(allScopes: Scope[], requiredScopes: Scope[]): boolean {
  return requiredScopes.every((scope) => allScopes.includes(scope));
}

function calculateRights(scopes: Scope[]): IRights {
  // GLOBAL ACCOUNTS TODO: ROLE SCOPE CHECK
  // TODO: this is temporary solution, please replace this dummy scope with the proper ones when they become available
  const isAdminOrAbove = scopes.includes(Scope.IntegrationsManage);
  const canManageSubscription = scopes.includes(Scope.SubscriptionRW) || scopes.includes(Scope.SubscriptionLCRW);
  const canFetchSubscription = scopes.includes(Scope.SubscriptionLCRO);

  return {
    [Right.AgentsManagement]: scopes.includes(Scope.AgentsAllRW),
    [Right.AgentsRoleManagement]: scopes.includes(Scope.AccountsRolesLCAllRW),
    [Right.ExportReportsAccess]: scopes.includes(Scope.ReportsRead),
    [Right.FormsManagement]: scopes.includes(Scope.FormsManage),
    [Right.GreetingsManagement]: scopes.includes(Scope.GreetingsWrite),
    [Right.CustomizationManagement]: isAdminOrAbove,
    [Right.SubscriptionFetching]: canFetchSubscription,
    [Right.SubscriptionManagement]: canManageSubscription,
    [Right.SubscriptionPotentialAccess]: canManageSubscription || isAdminOrAbove,
    [Right.CreditCardManagement]: canManageSubscription,
    [Right.BotAgentsManagement]: scopes.includes(Scope.AgentsBotMyRW),
    [Right.ChangeOwnerManagement]: scopes.includes(Scope.AgentsTransferOwnership),
    [Right.CancelLicenseAccess]: scopes.includes(Scope.AgentsTransferOwnership),
    [Right.InvoicesAccess]: scopes.includes(Scope.InvoicesManage),
    [Right.InvoicesEmailManagement]: canManageSubscription,
    [Right.TagsAccess]: hasAnyOfScopes(scopes, [Scope.TagsRead, Scope.TagsWrite, Scope.TagsManagement]),
    [Right.TagsManagement]: scopes.includes(Scope.TagsManagement),
    [Right.CannedResponsesAccess]: hasAnyOfScopes(scopes, [Scope.CannedResponsesRead, Scope.CannedResponsesWrite]),
    [Right.CannedResponsesManagement]: scopes.includes(Scope.CannedResponsesWrite),
    [Right.AgentsSectionAccess]: scopes.includes(Scope.AgentsRead),
    [Right.MyProfileManagement]: scopes.includes(Scope.AgentsMyRW),
    [Right.InviteLinkUsage]: scopes.includes(Scope.AgentsAllRW),
    [Right.WorkSchedulerManagement]: scopes.includes(Scope.PropertiesAllRO),
    [Right.MyPasswordChange]: scopes.includes(Scope.AccountsPasswordMyRW),
    [Right.PasswordsChange]: scopes.includes(Scope.AccountsPasswordAllRW),
    [Right.TicketsManagement]: scopes.includes(Scope.TicketsManage),
    [Right.GoalsAccess]: hasAnyOfScopes(scopes, [Scope.GoalsRead, Scope.GoalsWrite]),
    [Right.GoalsManagement]: isAdminOrAbove,
    [Right.AllGroupsManagement]: scopes.includes(Scope.GroupsAllRW),
    [Right.GroupsManagement]: scopes.includes(Scope.GroupsWrite),
    [Right.FacebookMessengerManagement]: scopes.includes(Scope.IntegrationsManage),
    [Right.EmailForwardManagement]: scopes.includes(Scope.TicketsManage),
    [Right.ThemeManagement]: isAdminOrAbove,
    [Right.LanguagesManagement]: scopes.includes(Scope.LanguagesWrite),
    [Right.ChatBoostersAccess]: scopes.includes(Scope.IntegrationsManage),
    [Right.ChatSurveyManagement]: scopes.includes(Scope.SurveysManage),
    [Right.ChatFormsManagement]: scopes.includes(Scope.FormsManage),
    [Right.EyeCatcherManagement]: scopes.includes(Scope.EyeCatcherManage),
    [Right.ChatButtonsManagement]: scopes.includes(Scope.ButtonsManage),
    [Right.SalesTrackerManagement]: isAdminOrAbove,
    [Right.ChatRoutingManagement]: isAdminOrAbove,
    [Right.MessagingModeManagement]: isAdminOrAbove,
    [Right.AutoAccessRulesManagement]: isAdminOrAbove,
    [Right.TranscriptForwardingManagement]: isAdminOrAbove,
    [Right.FileSharingManagement]: isAdminOrAbove,
    [Right.InactivityTimeoutsManagement]: isAdminOrAbove,
    [Right.EmailNotificationsManagement]: isAdminOrAbove,
    [Right.WebhooksManagement]: scopes.includes(Scope.WebhooksManage),
    [Right.QualityPageManagement]: scopes.includes(Scope.GroupsWrite),
    [Right.TrustedDomainsManagement]: scopes.includes(Scope.DomainManage),
    [Right.BanningVisitorsManagement]: hasAllScopes(scopes, [
      Scope.BanCustomer,
      Scope.UnbanCustomer,
      Scope.BanlistWrite,
    ]),
    [Right.CreditCardMaskingManagement]: isAdminOrAbove,
    [Right.AccessRestrictionManagement]: isAdminOrAbove,
    [Right.AgentsAuthenticationManagement]: isAdminOrAbove,
    [Right.AgentsAdd]: scopes.includes(Scope.AgentsWrite),
    [Right.ApplicationsViewAccess]: hasAnyOfScopes(scopes, [
      Scope.IntegrationsManage,
      Scope.KBIntegrationManage,
      Scope.HDIntegrationManage,
    ]),
    [Right.ApplicationsManagement]: scopes.includes(Scope.IntegrationsManage),
    [Right.KBIntegrationManagement]: scopes.includes(Scope.KBIntegrationManage),
    [Right.HDIntegrationManagement]: scopes.includes(Scope.HDIntegrationManage),
    [Right.BillingManagement]: scopes.includes(Scope.BillingManage),
    [Right.OrganizationManagement]: scopes.includes(Scope.OrganizationManagement),
    [Right.AgentSessionsManagement]: scopes.includes(Scope.SessionsAllRW),
    [Right.OwnSessionsManagement]: scopes.includes(Scope.SessionsMyRW),
    [Right.InvitationsManagement]: hasAllScopes(scopes, [Scope.AccountsAllRW, Scope.SubscriptionLCRW]),
    [Right.InsightsAccess]: isAdminOrAbove,
    [Right.OrganizationProductActivation]: scopes.includes(Scope.OrganizationProductActivation),
    [Right.GroupsPropertiesManagement]: scopes.includes(Scope.GroupPropertiesManagement),
  };
}

function* updateRights(): SagaIterator {
  const scopes = getScopes();

  if (scopes) {
    const rights = calculateRights(scopes);

    yield put(SessionActions.saveRights(rights));
  }
}

function* logoutAgent(): SagaIterator {
  const isChecklistCompleted = yield select(getIsOnboardingCompleted);
  const isTodoListClosed = yield select(getHomeScreenChecklistClosed);
  if (isChecklistCompleted && !isTodoListClosed) {
    const payload = { [AgentCustomPropertyName.HomeScreenChecklistClosed]: '1' };
    yield call(handleSetCustomProperty, payload);
    trackEvent(ProfileMenuEvent.ChecklistClosedOnLogout, EventPlace.ProfileMenu);
  }

  return logout();
}

function* checkSessionsLimit(): SagaIterator {
  if (isGhostLogin()) {
    return;
  }

  const sessions: ReturnType<typeof getLoggedInAgentSessionsInLC> = yield select(getLoggedInAgentSessionsInLC);
  yield take(SubscriptionActionNames.SUBSCRIPTION_FETCHED);
  const seats = yield select(getSubscriptionSeats);
  const { seats: oldSeatsCount, exceededSessionsCount } = yield select(getExceededSessionsInfo);
  const sessionsLimit = getConfig().activeSessionsLimit;
  const hasIncreasedSeatsCount = oldSeatsCount != 0 && seats > oldSeatsCount;

  if (sessions.length <= MAX_SESSIONS_ALLOWED) {
    return;
  }

  if (hasIncreasedSeatsCount) {
    const exceededSessionsCountPayload = {
      seats: 0,
      exceededSessionsCount: 0,
    };

    yield put(
      AgentCustomPropertiesActions.setAgentCustomProperty({
        [AgentCustomPropertyName.ExceededSessionsCount]: JSON.stringify(exceededSessionsCountPayload),
      })
    );

    return;
  }

  if (exceededSessionsCount >= EXCEEDED_SESSIONS_LIMIT) {
    redirectToSessionAbuse();
  }

  if (sessions.length > sessionsLimit && exceededSessionsCount < EXCEEDED_SESSIONS_LIMIT) {
    yield put(GlobalModalActions.showModal(GlobalModal.ExceededSessionsLimit));
  }
}

export default function* sessionSagas(): SagaIterator {
  yield all([
    takeEvery(SessionActionNames.SAVE_CURRENT_LICENSE, updateRights),
    takeEvery(SessionActionNames.SAVE_AUTHENTICATION_CREDENTIALS, updateRights),
    takeEvery(SessionActionNames.UPDATE_RIGHTS, updateRights),
    takeEvery(SessionActionNames.AGENT_LOGOUT, logoutAgent),
    takeEvery(AGENT_CUSTOM_PROPERTIES.FETCH_AGENT_CUSTOM_PROPERTIES[RequestAction.SUCCESS], checkSessionsLimit),
  ]);
}
