import { getTime } from 'date-fns';

import { formatPrice } from 'helpers/subscription';
import {
  FrequencyResource,
  type IResourceTransaction,
  type IResourcePrice,
} from 'store/entities/applications/interfaces';

interface GetResourcesPriceSummaryParams {
  price: IResourcePrice | null;
  agentsCount: number;
  quantity?: number;
  multiplied?: boolean;
}

export interface GetResourcePriceSummary {
  resourcePrice: string;
  totalPrice: string;
  paymentFrequency: string;
}

export function getPrice(price: number, payPerAgent: boolean, agentsCount: number): string {
  const recalculatedPrice = price / 100;

  return formatPrice(payPerAgent ? recalculatedPrice * agentsCount : recalculatedPrice);
}

export const getPaymentFrequency = (months?: number): string => {
  if ((months as FrequencyResource) === FrequencyResource.Monthly) {
    return '/ mo';
  }

  if ((months as FrequencyResource) === FrequencyResource.Yearly) {
    return '/ yr';
  }

  return '';
};

export const getResourcePriceSummary = ({
  price,
  quantity = 1,
  multiplied,
  agentsCount,
}: GetResourcesPriceSummaryParams): GetResourcePriceSummary => {
  const isPerAgent = price?.perAgent || false;
  const multiplier = multiplied ? quantity : 1;
  const agents = isPerAgent ? agentsCount : 1;
  const priceInCents = price?.cents || 0;

  const resourcePrice = formatPrice(priceInCents / 100);
  const totalPrice = getPrice(priceInCents * multiplier, isPerAgent, agents);
  const paymentFrequency = getPaymentFrequency(price?.months);

  return {
    totalPrice,
    paymentFrequency,
    resourcePrice,
  };
};

export const getPriceInfo = (price: IResourcePrice | null): string | null => {
  if (!price) {
    return null;
  }

  const { months, perAgent } = price;

  switch (months as FrequencyResource) {
    case FrequencyResource.Once:
      return 'one-time payment';

    case FrequencyResource.Monthly:
      return `per month, ${perAgent ? 'per agent' : 'per license'}`;

    case FrequencyResource.Yearly:
      return `per year, ${perAgent ? 'per agent' : 'per license'}`;

    default:
      return null;
  }
};

interface GetActionButtonParams {
  trialDays: number;
  totalPrice: string;
  isActive: boolean;
  purchaseOnly?: boolean;
}

export const getActionButtonCopy = ({
  trialDays,
  totalPrice,
  isActive,
  purchaseOnly,
}: GetActionButtonParams): string => {
  if (isActive && !purchaseOnly) {
    return 'Manage';
  }

  if (trialDays) {
    return `Try ${trialDays}-day trial`;
  }

  if (!Number(totalPrice)) {
    return 'Activate';
  }

  return `Buy $${totalPrice}`;
};

export const getPriceComponents = ({
  resourcePrice,
  payPerAgent,
  agentsCount,
  quantity = 1,
}: {
  resourcePrice: string | null;
  payPerAgent: boolean;
  agentsCount: number;
  quantity?: number;
}): string => {
  if (!Number(resourcePrice)) {
    return 'Free';
  }

  let priceComponents = `$${resourcePrice}`;

  if (quantity && quantity > 1) {
    priceComponents = `${quantity} x ${priceComponents}`;
  }

  if (payPerAgent && agentsCount > 1) {
    priceComponents = `${priceComponents} x ${agentsCount} Agents`;
  }

  return priceComponents;
};

export const getLastActiveTransaction = (transactions: IResourceTransaction[]): IResourceTransaction => {
  const sorted = [...transactions].sort((transactionA, transactionB) => {
    const dateA = getTime(transactionA.switch.enabled as string);
    const dateB = getTime(transactionB.switch.enabled as string);

    return dateB - dateA;
  });

  return sorted[0];
};
