import type { FC } from 'react';

import { SkeletonText, Text } from '@livechat/design-system-react-components';

import { useDeveloperProfile } from 'hooks/api/applications/use-developer-profile';

import * as styles from './styles';

type Props = {
  developerId?: string;
};

export const DeveloperDetails: FC<Props> = ({ developerId }) => {
  const { data: developerProfile, isLoading } = useDeveloperProfile(developerId);

  if (isLoading) {
    return (
      <div className={styles.developerDetailsSkeleton}>
        <SkeletonText width={70} height={14} animated />
      </div>
    );
  }

  return <Text size="sm">by {developerProfile?.company || 'Text Certified Developer'}</Text>;
};
