import { css } from '@emotion/css';

const secondaryText = css`
  font-size: 13px;
  line-height: 18px;
  opacity: 0.8;
`;

export const name = css`
  min-width: 140px;
  flex: 2;

  > p {
    font-weight: 600;
    line-height: 20px;
    font-size: 16px;
  }

  > span {
    ${secondaryText};
  }
`;

export const price = css`
  line-height: 20px;
  min-width: 210px;
  flex: 1;

  > span {
    ${secondaryText};
  }
`;

export const action = css`
  text-align: right;
  min-width: 80px;

  button {
    box-sizing: border-box;
  }
`;

export const resource = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;

export const divider = css`
  margin-top: 16px;
  margin-bottom: 0;
`;

export const list = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: 215px;
  overflow-y: auto;
  padding-right: 8px;
`;

export const modal = css`
  width: 100%;
  height: 320px;
  max-width: 700px;
`;

export const modalContent = css`
  overflow-x: auto;
`;

export const footer = css`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 8px;

  button {
    box-sizing: border-box;
  }
`;
