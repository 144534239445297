// @ts-strict-ignore
import { type ReactChild, PureComponent, type MouseEvent } from 'react';

import { cx } from '@emotion/css';
import { type IconSource, Icon } from '@livechat/design-system-react-components';

import { type BadgeType, Badge } from 'components/badge/Badge';

import * as styles from './styles';

interface IProps {
  icon: IconSource;
  activeIcon: IconSource;
  title: string;
  path: string;
  active: boolean;
  badgeContent?: ReactChild;
  badgeType?: BadgeType;
  onItemClick?(path?: string);
}

export class MobileNavigationItem extends PureComponent<IProps> {
  handleOnClick = (event: MouseEvent<HTMLAnchorElement>): void => {
    event.preventDefault();

    if (this.props.onItemClick && this.props.path) {
      this.props.onItemClick(this.props.path);
    } else if (this.props.onItemClick) {
      this.props.onItemClick();
    }
  };

  render(): JSX.Element {
    const { icon, activeIcon, title, active, badgeContent, badgeType } = this.props;

    return (
      <a className={styles.mobileNavigationItem} href="/" onClick={this.handleOnClick}>
        <div className={cx(styles.contentWrapper(active))}>
          {badgeContent && (
            <div className={styles.badgeContainer}>
              <Badge type={badgeType}>{badgeContent}</Badge>
            </div>
          )}
          <div className="icon-container">
            <Icon source={active ? activeIcon : icon} size="large" />
          </div>
          <span>{title}</span>
        </div>
      </a>
    );
  }
}
