import { useCallback, useEffect, useRef } from 'react';

type MessageHandler = (data: any) => void;

export const useIframeMessenger = (iframeRef: React.RefObject<HTMLIFrameElement>, expectedMessageType: string) => {
  const messageHandlerRef = useRef<MessageHandler | null>(null);

  const sendMessage = useCallback(
    (message: any) => {
      if (iframeRef.current && iframeRef.current.contentWindow) {
        iframeRef.current.contentWindow.postMessage(message, '*');
      }
    },
    [iframeRef]
  );

  const messageListener = useCallback(
    (event: MessageEvent) => {
      if (event.data?.type === expectedMessageType) {
        if (messageHandlerRef.current) {
          messageHandlerRef.current(event.data);
        }
      }
    },
    [expectedMessageType]
  );

  useEffect(() => {
    window.addEventListener('message', messageListener);

    return () => {
      window.removeEventListener('message', messageListener);
    };
  }, [messageListener]);

  const onMessage = useCallback((handler: MessageHandler) => {
    messageHandlerRef.current = handler;
  }, []);

  return { onMessage, sendMessage };
};
