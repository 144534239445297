import type { FC, ReactNode } from 'react';

import { Text, Tooltip } from '@livechat/design-system-react-components';
import { Link } from 'react-router-dom';

import * as styles from './styles';

type Props = {
  href?: string;
  children: ReactNode;
};

export const OneLinkWithTooltip: FC<Props> = ({ children, href }) => {
  const trigger = href ? (
    <Link target="_blank" rel="noopener noreferrer" to={href}>
      {children}
    </Link>
  ) : (
    <span>{children}</span>
  );

  return (
    <Tooltip
      hoverOnDelay={800}
      kind="invert"
      className={styles.linkInTextTooltip}
      triggerClassName={styles.linkTrigger}
      triggerRenderer={trigger}
    >
      <Text className={styles.linkContent}>{href}</Text>
    </Tooltip>
  );
};
