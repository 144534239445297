import { isValidUrl } from 'helpers/validate';
import type { IApplicationWidget } from 'store/entities/applications/interfaces';

export const getWidgetDomain = (visibleWidgetUrl: IApplicationWidget['url'] | undefined): string => {
  if (!visibleWidgetUrl || !isValidUrl(visibleWidgetUrl)) {
    return '';
  }

  const widgetUrl = new URL(visibleWidgetUrl);

  return `${widgetUrl.protocol}//${widgetUrl.host}`;
};
