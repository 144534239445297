import { css } from '@emotion/css';
import { SpacingToken, DesignToken } from '@livechat/design-system-react-components';

export const modal = css`
  width: 95vw;
  max-width: 600px;

  > div {
    padding: var(${SpacingToken.Spacing5}) var(${SpacingToken.Spacing7});
  }

  > div:first-of-type {
    border-bottom: 1px solid var(${DesignToken.BorderBasicTertiary});
    width: 100%;

    > div {
      width: 100%;
    }
  }

  > div:last-of-type {
    border-top: 1px solid var(${DesignToken.BorderBasicTertiary});
    width: 100%;

    > div {
      width: 100%;
    }
  }

  p {
    margin: 0;
  }
`;

export const alert = css`
  margin-bottom: 30px;

  div {
    margin-right: 0;
  }

  h5 {
    margin: 0 0 var(${SpacingToken.Spacing2});
  }
`;
