import { css } from '@emotion/css';
import { DesignToken } from '@livechat/design-system-react-components';

import { mobileMediaQuery } from 'helpers/media-queries';

export const container = css`
  display: flex;
  flex-direction: column;
  background-color: var(${DesignToken.NavbarBackground});
  width: 54px;
  height: 100%;
  position: fixed;
  z-index: 10000;

  ${mobileMediaQuery} {
    display: none;
  }

  > div {
    background-color: var(${DesignToken.NavbarBackground});
  }
`;

export const top = css`
  overflow-y: scroll;
  scrollbar-width: none;
  flex-grow: 1;

  &&::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
`;

export const bottom = css`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: center;
  padding-top: 6px;
  padding-bottom: 6px;
`;

export const news = css`
  &&& .beamer_icon {
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, sans-serif;
    top: 0px !important;
    right: -1px !important;
    background-color: var(${DesignToken.SurfaceAccentEmphasisHighNegative}) !important;
  }
`;
