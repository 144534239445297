export interface IFeaturesResponse {
  feature_flags: IResponseFeature[];
}

interface IResponseFeature {
  name: string;
  value: boolean;
}

export enum FeatureNames {
  TestFeature = 'testFeature',
  ChatsListPagination = 'chatsListPagination',
  RichTextEditor = 'richTextEditor',
  DisturbingContentDetection = 'disturbingContentDetection',
  CustomerIntentEnabled = 'customerIntentEnabled',
  ReviewsReportEnabled = 'reviewsReportEnabled',
  RecentInsightsEnabled = 'recentInsightsEnabled',
  ShowHelloTextProduct = 'showHelloTextProduct',
  AISuggestions = 'aiSuggestions',
  ProductSwitcher = 'productSwitcher',
}

export type Features = Record<FeatureNames, boolean>;
