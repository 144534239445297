import { useRef, type FC } from 'react';

import { cx } from '@emotion/css';
import { Badge, type ITooltipProps, Tag, Text, Tooltip } from '@livechat/design-system-react-components';
import { useDispatch, useSelector } from 'react-redux';

import oneActive from 'assets/img/automation/One-icon-active.png';
import oneHover from 'assets/img/automation/One-icon-animation.png';
import oneDefault from 'assets/img/automation/One-icon-default.png';
import { ONE_LOGO } from 'components/global-modals/ai-hub/constants';
import { OneTrackEvent } from 'constants/one-event';
import { EventPlace } from 'helpers/analytics';
import { isWindows } from 'helpers/browser';
import { getModKey } from 'helpers/keyboard';
import { useOnHover } from 'hooks/use-on-hover';
import { trackEvent } from 'services/event-tracking';
import { MAX_ONE_ONBOARDING_OPENED_COUNT } from 'store/entities/one/constants';
import { AgentCustomPropertiesActions } from 'store/features/agent-custom-properties/actions';
import { AgentCustomPropertyName } from 'store/features/agent-custom-properties/interfaces';
import { getOneOpenedCount } from 'store/features/agent-custom-properties/selectors';
import { OneViewActions } from 'store/views/one/actions';
import { getIsOneModalOpened, getOneIsLoading, getOneUndreadMessageCount } from 'store/views/one/selectors';

import { ONE_TRIGGER_TEST_ID } from './constants';

import * as styles from './styles';

const tooltipHooksConfig: Pick<ITooltipProps, 'useClickHookProps' | 'useDismissHookProps'> = {
  useClickHookProps: {
    ignoreMouse: true,
  },
  useDismissHookProps: {
    referencePress: true,
    referencePressEvent: 'click',
  },
};

const isMac = !isWindows();

export const OneWithBadge: FC = () => {
  const dispatch = useDispatch();

  const isOneModalOpened = useSelector(getIsOneModalOpened);
  const isOneTyping = useSelector(getOneIsLoading);
  const unreadOneMessages = useSelector(getOneUndreadMessageCount);
  const oneOpenedCount = useSelector(getOneOpenedCount);

  const logoRef = useRef<HTMLDivElement>(null);

  const { isHovered, handleMouseOut, handleMouseOver } = useOnHover();

  const shouldDisplayOneBadge = unreadOneMessages > 0 && !isOneModalOpened;
  const shouldAnimate = isHovered || (isOneTyping && !isOneModalOpened);

  const toggleOneModal = (): void => {
    if (isOneModalOpened) {
      dispatch(OneViewActions.hideModal());

      return;
    }

    dispatch(OneViewActions.showModal());
    trackEvent(OneTrackEvent.ChatOpened, EventPlace.One, { type: 'click' });

    if (oneOpenedCount < MAX_ONE_ONBOARDING_OPENED_COUNT) {
      dispatch(
        AgentCustomPropertiesActions.setAgentCustomProperty({
          [AgentCustomPropertyName.OneOpenedCount]: oneOpenedCount + 1,
        })
      );
    }
  };

  const getOneImageSrc = (): string => {
    if (isHovered) {
      return oneHover;
    }

    if (isOneModalOpened) {
      return oneActive;
    }

    return oneDefault;
  };

  return (
    <Tooltip
      placement="bottom-end"
      hoverOnDelay={300}
      floatingStrategy="fixed"
      kind="invert"
      referenceElement={logoRef?.current ?? undefined}
      triggerRenderer={
        <div
          ref={logoRef}
          className={styles.oneLogoContainer}
          onClick={toggleOneModal}
          onMouseEnter={handleMouseOver}
          onMouseLeave={handleMouseOut}
          data-testid={ONE_TRIGGER_TEST_ID}
        >
          <div className={styles.imageContainer}>
            <img
              src={getOneImageSrc()}
              alt="One Default"
              className={cx(styles.oneUnderlayer, { [styles.borderAnimation]: shouldAnimate })}
            />
            <img src={ONE_LOGO} alt="ONE_LOGO" className={styles.oneAssistant} />
          </div>
          {shouldDisplayOneBadge && <Badge type="dot" size="compact" className={styles.oneBadge} />}
        </div>
      }
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...tooltipHooksConfig}
    >
      <Text>Chat with One</Text>
      <div className={cx(styles.onePopoverShortcuts, 'lc-dark-theme')}>
        <Tag className={styles.shortcut} iconOnly={isMac}>
          {getModKey(true)}
        </Tag>
        <Tag className={styles.shortcut}>G</Tag>
      </div>
    </Tooltip>
  );
};
