/* eslint-disable @typescript-eslint/naming-convention */
import { type IconSource } from '@livechat/design-system-react-components';

import type { LogicOperator } from 'constants/logic-operator';
import type { KeyMap } from 'helpers/interface';

export interface IGreetingsEntityState {
  isSavingData: boolean;
  greetings: KeyMap<IGreeting>;
}

// Backend structure
export type ApiGreetingRuleType =
  | 'custom_variable'
  | 'url_funnel'
  | 'visit_time_site'
  | 'visit_time_page'
  | 'url_current'
  | 'url_visited'
  | 'pages_view_number'
  | 'url_referrer'
  | 'geolocation'
  | 'visits_number'
  | 'search_keyword'
  | 'ads_traffic';

export type ApiStringOperator = 'equals' | 'doesnt_equal' | 'contains' | 'doesnt_contain';

export type ApiOperator =
  | 'equals'
  | 'doesnt_equal'
  | 'lower_than'
  | 'lower_or_equal'
  | 'greater_than'
  | 'greater_or_equal'
  | 'contains'
  | 'doesnt_contain';

export type ApiCondition = 'and' | 'or';

export interface IApiFunnelUrl {
  url: string;
  operator: ApiStringOperator;
}

export interface IApiGreetingRule {
  id?: number;
  type: ApiGreetingRuleType;
  condition: ApiCondition;
  operator?: ApiOperator;
  value?: string;
  variable_name?: string;
  variable_value?: string;
  urls?: IApiFunnelUrl[];
}

interface IApiGreetingProperties {
  addon?: string;
  subtype?: TargetedMessageSubtype;
  active_until?: string;
  active_from?: string;
  active?: string;
  is_exit_intent?: number | string;
  'greeting-message_text': string;
  'greeting-message_json'?: string;
  'greeting-message_json_key'?: string | null;
  'greeting-message_phrase_key'?: string | null;
  'greeting-message_color'?: string;
  'greeting-message_font'?: string;
  'greeting-message_height'?: string;
  'greeting-message_left'?: string;
  'greeting-message_size'?: string;
  'greeting-message_top'?: string;
  'greeting-message_width'?: string;
}

export interface IApiGreeting {
  id?: number;
  active: number | boolean; // 0 or 1 or bool
  name: string;
  group: number;
  rules: IApiGreetingRule[];
  properties: IApiGreetingProperties;
}

// Frontend structure

export enum StringOperator {
  Equal = 'equal',
  NotEqual = 'not-equal',
  Contains = 'contains',
  DoesNotContain = 'doesnt-contain',
}

export enum AnyValueOperator {
  Equal = 'equal',
  NotEqual = 'not-equal',
  Contains = 'contains',
  DoesNotContain = 'doesnt-contain',
  LowerThan = 'lower-than',
  LowerOrEqual = 'lower-or-equal',
  GreaterThan = 'greater-than',
  GreaterOrEqual = 'greater-or-equal',
}

export enum PageOrderDisplayname {
  Equal = 'Page URL is exactly',
  NotEqual = 'Page URL is not',
  Contains = 'Page URL contains',
}

export enum LogicOperatorDisplayName {
  And = 'Match all of these conditions',
  Or = 'Match one of these conditions',
}

export enum GreetingRuleDisplayName {
  BrowsingTime = 'Customer’s browsing time',
  CurrentPageUrl = 'Current page URL',
  ReferrerUrl = 'Referring website address',
  VisitedPagesNumber = 'Number of viewed pages',
  VisitedPagesOrder = 'Pages visited in exact order',
  VisitedPage = 'Any page from session',
  Location = 'Customer’s location',
  CustomVariable = 'Custom variable',
  AdsTraffic = 'Ads traffic',
  Intent = 'Visitor Intent',
  ExitIntent = 'User activity',
}

export enum GreetingRuleType {
  BrowsingTime = 'browsing-time',
  CurrentPageUrl = 'current-page-url',
  ReferrerUrl = 'referrer-url',
  VisitedPagesNumber = 'visited-pages-number',
  VisitedPagesOrder = 'visited-pages-order',
  VisitedPage = 'visited-page',
  Location = 'location',
  CustomVariable = 'custom-variable',
  AdsTraffic = 'ads-traffic',
  Intent = 'intent-custom-variable',
  ExitIntent = 'exit-intent',
}

export enum GreetingVisitorType {
  All = 'all',
  FirstTime = 'first-time',
  Returning = 'returning',
}

export interface IGreetingSimpleRule {
  id: string;
  type: GreetingRuleType;
}

export interface IGreetingMeetValueRule<T> extends IGreetingSimpleRule {
  value: T;
}

export interface IGreetingCheckStringRule extends IGreetingSimpleRule {
  value: string;
  operator: StringOperator;
}

export interface IGreetingOwnVariableRule extends IGreetingSimpleRule {
  variableName: string;
  value: string;
  operator: AnyValueOperator;
}

export interface IGreetingAdsTrafficRule extends IGreetingSimpleRule {
  variableName: string;
}

export interface IGreetingOwnVariableRuleErrors {
  valueError?: string;
  variableError?: string;
}

export interface IGreetingFunnelUrl {
  url: string;
  operator: StringOperator;
  id: string;
}

export interface IGreetingUrlsFunnelRule extends IGreetingSimpleRule {
  urlsFunnel: IGreetingFunnelUrl[];
}

export interface IGreetingExitIntentRule extends IGreetingSimpleRule {
  value: boolean;
}

export type GreetingRule =
  | IGreetingSimpleRule
  | IGreetingMeetValueRule<number | string>
  | IGreetingCheckStringRule
  | IGreetingOwnVariableRule
  | IGreetingUrlsFunnelRule
  | IGreetingExitIntentRule;

export enum RichGreetingButtonType {
  Message = 'message',
  Phone = 'phone',
  Url = 'url',
  Webview = 'webview',
  Postback = 'postback',
  Cancel = 'cancel',
  Copy = 'copy',
}

export enum ButtonRole {
  Default = 'default',
  Primary = 'primary',
  Danger = 'danger',
}

export enum WebviewHeight {
  Full = 'full',
  Tall = 'tall',
  Compact = 'compact',
}

export interface IRichMessageButton {
  postback_id: string;
  text: string;
  type: RichGreetingButtonType;
  user_ids: string[];
  value: string;
  role?: ButtonRole;
}

export interface IRichGreetingElement {
  image?: {
    url: string;
    alternative_text: string;
  };
  title?: string;
  subtitle?: string;
  buttons?: IRichMessageButton[];
}

export enum RichMessageAddons {
  MessageInput = 'message_input',
}

export enum RichMessageType {
  Cards = 'cards',
  QuickReplies = 'quick_replies',
}

export interface IRichGreeting {
  template_id: RichMessageType.Cards | RichMessageType.QuickReplies;
  elements: IRichGreetingElement[];
}

export enum GreetingType {
  Card = 'card',
  QuickReplies = 'quick_replies',
  Text = 'text',
}

export enum TargetedMessageSubtype {
  Greeting = 'greeting',
  Announcement = 'announcement',
}

export interface IGetDefaultGreetingParameters {
  groupId: string;
  subtype: TargetedMessageSubtype;
  translatedMessage: string;
  translatedButton: string;
  isIntentEnabled: boolean;
  templateId?: string;
  templatesFromWidgets?: ITemplate[];
}

export interface IGreeting {
  id?: string;
  name: string;
  isNameChanged?: boolean;
  groupId: string;
  message: string;
  isActive: boolean;
  visitorType: GreetingVisitorType;
  timeOnPage: number;
  rulesLogicOperator: LogicOperator;
  rules: GreetingRule[];
  displayedCount?: number;
  acceptedCount?: number;
  conversionValue?: number;
  richGreetingData: IRichGreeting | null;
  addon: string | null;
  subtype: TargetedMessageSubtype;
  activeUntil: string | null;
  activeFrom: string | null;
}

export interface ICustomVariableError {
  valueError?: string;
  variableError?: string;
}

export interface IErrors {
  [key: string]: string | ICustomVariableError;
}

export const enum AdsPlatform {
  Facebook = 'facebook',
  Microsoft = 'microsoft',
  Google = 'google',
  Twitter = 'twitter',
  Yahoo = 'yahoo',
}

export const enum AdsPlatformName {
  Facebook = 'Facebook',
  Microsoft = 'Microsoft',
  Google = 'Google',
  Twitter = 'Twitter',
  Yahoo = 'Yahoo',
}

// Templates list

export enum TemplateButtonType {
  Moment = 'moment',
  Message = 'message',
  Cancel = 'cancel',
  Phone = 'phone',
  Url = 'url',
  Copy = 'copy',
}

interface ITemplateButton {
  type: TemplateButtonType;
  text: string;
  role?: ButtonRole;
  value?: string;
}

interface ITemplateButtonMoment extends ITemplateButton {
  type: TemplateButtonType.Moment;
  value: string;
  momentSize: WebviewHeight;
}

interface ITemplateButtonMessage extends ITemplateButton {
  type: TemplateButtonType.Message;
}

interface ITemplateButtonCancel extends ITemplateButton {
  type: TemplateButtonType.Cancel;
}

interface ITemplateButtonPhone extends ITemplateButton {
  type: TemplateButtonType.Phone;
  value: string;
}

interface ITemplateButtonUrl extends ITemplateButton {
  type: TemplateButtonType.Url;
  value: string;
}

export enum TemplatesCategory {
  GreetAndInform = 'Greet and inform',
  PromoteAndConvert = 'Promote and convert',
  GenerateLeads = 'Generate leads',
  Other = 'Other',
}

interface ITemplateButtonCopy extends ITemplateButton {
  type: TemplateButtonType.Copy;
  value: string;
}

export interface ITemplate {
  defaultTemplateIdToRemove?: string;
  badge?: string;
  category?: TemplatesCategory;
  id: string;
  name: string;
  shortDescription: string;
  description?: string;
  thumbnail: {
    url: string;
    alternativeText: string;
  };
  image?: {
    url: string;
    alternativeText: string;
  };
  note?: {
    title: string;
    link?: {
      url: string;
      text: string;
    };
  };
  tags: {
    text: string;
    icon?: string | IconSource;
  }[];
  rules?: GreetingRule[];
  timeOnPage?: number;
  definition: {
    messageType: TargetedMessageSubtype;
    templateType: GreetingType;
    titlePlaceholder?: string;
    subtitlePlaceholder?: string;
    image?: {
      url: string;
      alternativeText: string;
    };
    buttons?: Array<
      | ITemplateButtonMoment
      | ITemplateButtonMessage
      | ITemplateButtonCancel
      | ITemplateButtonPhone
      | ITemplateButtonUrl
      | ITemplateButtonCopy
    >;
  };
}

export type ITemplateCardProps = Pick<
  ITemplate,
  'id' | 'name' | 'shortDescription' | 'category' | 'badge' | 'thumbnail'
> & {
  onClick: (id: string, name: string, category?: string) => void;
  isLoading?: boolean;
};
