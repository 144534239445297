import isNil from 'lodash.isnil';
import max from 'lodash.max';

import { ChatThreadStatus, ChatThreadVisualStatus } from 'constants/chat-thread-status';

import { type IQueuedListItem, type IStartedChatListItem, type IUnassignedListItem } from '../interfaces';

function getThreadStatusToCompare(item: IStartedChatListItem): ChatThreadStatus {
  if (item?.visualStatus === ChatThreadVisualStatus.MyChatVisuallyClosed) {
    return ChatThreadStatus.Closed;
  }

  return item?.status ?? ChatThreadStatus.Closed;
}

function compareThreadStatus(statusA: ChatThreadStatus, statusB: ChatThreadStatus, reversePriority?: boolean): number {
  let statusToPriority = {
    [ChatThreadStatus.Closed]: 0,
    [ChatThreadStatus.Idle]: 1,
    [ChatThreadStatus.Active]: 2,
  };

  if (reversePriority) {
    statusToPriority = {
      [ChatThreadStatus.Closed]: 2,
      [ChatThreadStatus.Idle]: 1,
      [ChatThreadStatus.Active]: 0,
    };
  }

  return statusToPriority[statusB] - statusToPriority[statusA];
}

export function compareStartedChatListItems(
  a: IStartedChatListItem,
  b: IStartedChatListItem,
  reversePriority?: boolean
): number {
  const aStartedTimestamp = max([a.startedTimestamp, a.queuedAtInMs]) || null;
  const bStartedTimestamp = max([b.startedTimestamp, b.queuedAtInMs]) || null;

  const aStatus = getThreadStatusToCompare(a);
  const bStatus = getThreadStatusToCompare(b);

  if (aStatus === bStatus) {
    if (bStartedTimestamp === null) {
      return -1;
    }

    if (aStartedTimestamp === null) {
      return 1;
    }

    return aStartedTimestamp - bStartedTimestamp;
  }

  return compareThreadStatus(aStatus, bStatus, reversePriority);
}

/**
 * Compares two queued chat list items based on their queue position, waiting time, and queued time.
 * This function is typically used for sorting an array of queued chat list items.
 * @param {IQueuedListItem} a - The first queued chat list item to compare. This object should have properties for queue position, waiting time, and queued time.
 * @param {IQueuedListItem} b - The second queued chat list item to compare. This object should have properties for queue position, waiting time, and queued time.
 * @returns {number} - Returns a negative value if `a` should be sorted before `b`, a positive value if `a` should be sorted after `b`, and 0 if they are equal.
 * @example
 * ```
 * const items: IQueuedListItem[] = [...];
 * items.sort(compareQueuedChatListItems);
 * ```
 * @remarks
 * This function uses the lodash `isNil` function to check if the queue position, waiting time, or queued time is `null` or `undefined`. If any of these properties are `null` or `undefined` for either `a` or `b`, the function returns 0, indicating that `a` and `b` are equal in terms of the property being checked.
 * @see `IQueuedListItem` - This is the interface for the objects that this function compares.
 */
export function compareQueuedChatListItems(a: IQueuedListItem, b: IQueuedListItem): number {
  if (isNil(a.queuePosition) || isNil(b.queuePosition)) {
    return 0;
  }

  const positionDiff = a.queuePosition - b.queuePosition;
  if (positionDiff !== 0) {
    return positionDiff;
  }

  if (isNil(a.queueWaitingTime) || isNil(b.queueWaitingTime)) {
    return 0;
  }

  const waitingTimeDiff = b.queueWaitingTime - a.queueWaitingTime;
  if (waitingTimeDiff !== 0) {
    return waitingTimeDiff;
  }

  if (isNil(a.queuedAtInMs) || isNil(b.queuedAtInMs)) {
    return 0;
  }

  return a.queuedAtInMs - b.queuedAtInMs;
}

/**
 * Compares two unassigned list items based on their started timestamps. This function is typically used for sorting unassigned list items in a React application that uses react-redux for state management, redux-saga for side effects, and @tanstack/query for data fetching.
 * @param {IUnassignedListItem} a - The first unassigned list item to compare. This object should have a `startedTimestamp` property that represents the time when the item was started.
 * @param {IUnassignedListItem} b - The second unassigned list item to compare. This object should also have a `startedTimestamp` property.
 * @returns {number} - Returns a negative value if `a` should be sorted before `b`, a positive value if `a` should be sorted after `b`, and 0 if they are equal or if either timestamp is null.
 * @example
 * ```
 * const items = [item1, item2, item3];
 * items.sort(compareUnassignedListItems);
 * ```
 * @remarks
 * This function treats items with a null `startedTimestamp` as equal. If you want to handle null timestamps differently, you may need to modify this function or use a different comparison function.
 */
export function compareUnassignedListItems(a: IUnassignedListItem, b: IUnassignedListItem): number {
  if (isNil(a.startedTimestamp) || isNil(b.startedTimestamp)) {
    return 0;
  }

  return a.startedTimestamp - b.startedTimestamp;
}

export function compareSupervisedChatListItems(a: IStartedChatListItem, b: IStartedChatListItem): number {
  const aStartedTimestamp = max([a.startedTimestamp, a.queuedAtInMs]) || null;
  const bStartedTimestamp = max([b.startedTimestamp, b.queuedAtInMs]) || null;

  const aStatus = getThreadStatusToCompare(a);
  const bStatus = getThreadStatusToCompare(b);

  if (aStatus === bStatus) {
    if (bStartedTimestamp === null) {
      return -1;
    }

    if (aStartedTimestamp === null) {
      return 1;
    }

    return bStartedTimestamp - aStartedTimestamp;
  }

  return compareThreadStatus(aStatus, bStatus);
}

export function getPrevOrNextThreadIdIfExist(
  chatIds: { threadId: string }[],
  threadId: string,
  toPick?: 'next' | 'previous'
): string | null {
  const threadIndex = chatIds.findIndex((chat) => chat.threadId === threadId);
  const prevThread = chatIds[threadIndex - 1];
  const nextThread = chatIds[threadIndex + 1];

  if (nextThread && toPick !== 'previous') {
    return nextThread.threadId;
  }

  if (toPick === 'next') {
    return null;
  }

  if (prevThread) {
    return prevThread.threadId;
  }

  return null;
}

export function countThreadsByStatus(threads: IStartedChatListItem[], status: ChatThreadStatus): number {
  return threads.reduce((sum: number, thread: IStartedChatListItem) => {
    let newSum = sum;
    if (getThreadStatusToCompare(thread) === status) {
      newSum += 1;
    }

    return newSum;
  }, 0);
}
