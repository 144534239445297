// @ts-strict-ignore
import memoizeOne from 'memoize-one';

import { type LanguageCode } from 'constants/languages-available';
import type { Group, GroupBase, GroupWithAgents } from 'interfaces/groups';
import type { GroupDTO } from 'services/api/group/interfaces';
import { AppStateProvider } from 'services/app-state-provider';
import { SettingsViewActions } from 'store/views/settings/actions';
import { LanguageViewActions } from 'store/views/settings/language/actions';

export function setSettingsGroup(groupId: string, languageCode: string): void {
  AppStateProvider.dispatch(SettingsViewActions.setSelectedGroupId(groupId));
  AppStateProvider.dispatch(LanguageViewActions.setCurrentLanguageCode({ languageCode: languageCode as LanguageCode }));
}

/**
 * Map redux model to GroupInterface
 */
export function mapGroup(group: GroupWithAgents): Group & { agents: string[] } {
  return {
    id: String(group.id),
    name: group.name,
    status: group.status,
    agents: group.agents.map((agent) => agent.login),
  };
}

export const mapGroupsToFilterItems = memoizeOne((groups: GroupBase[]): GroupBase[] =>
  groups.map((group) => ({
    id: group.id,
    name: group.name,
  }))
);

export const getNonEmptyGroupAgents = (agents?: string[]): string[] | undefined => {
  return agents && agents.length > 0 ? agents : undefined;
};

export const mergeGroupProperties = (
  groupList: GroupDTO[],
  groupsProperties: Pick<GroupDTO, 'id' | 'properties'>[]
): GroupDTO[] => {
  const groupsPropertiesMap: Record<number, Pick<GroupDTO, 'properties'>> = groupsProperties.reduce(
    (groupsProperties, { id, properties }) => ({ ...groupsProperties, [id]: { properties } }),
    {}
  );

  return groupList.map((group) => {
    const corespondingGroupProperty = groupsPropertiesMap[group.id];

    return {
      ...group,
      ...(corespondingGroupProperty && { properties: corespondingGroupProperty.properties }),
    };
  });
};
