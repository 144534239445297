export enum LicensePropertiesNames {
  AdsMonitoring = 'use_monitoring',
  AutomaticUpsellingEnabled = 'automatic_upselling_enabled',
  BusinessModel = 'payment.business_model',
  ContinuousChatWidgetEnabled = 'continuous_chat_widget_enabled',
  DiscountCoupon = 'discount_coupon',
  FirstChatDate = 'first_chat_date',
  FormerChatio = 'former_chatio',
  HelpDeskInboundForwarding = 'helpdesk.inbound_forwarding',
  HelpDeskReplaceTickets = 'helpdesk.replace_tickets',
  LastTicketForwardedTimestamp = 'last_ticket_forwarded_timestamp',
  MaxAutoInviteShow = 'greeting.max_invites',
  NonProfit = 'non_profit',
  OperatorsAllowedIp = 'operators_allowed_ip',
  PartnerType = 'partner_type',
  PaymentRecurrentEnabled = 'payment.recurrent.enabled',
  SalesPlan = 'sales_plan',
  SendTranscriptEmail = 'send_transcript_email',
  WhiteLabelEnabled = 'whitelabel_enabled',
}
