export const enum SettingsRoutePage {
  AccessRestriction = 'access-restriction',
  AppleBusinessChat = 'apple-messages-for-business',
  ArchivesForwarding = 'archives-forwarding',
  AskForEmail = 'ask-for-email',
  AutomatedEmails = 'automated-emails',
  Availability = 'availability',
  BannedCustomers = 'banned-customers',
  CannedResponses = 'canned-responses',
  CannedResponsesNew = 'canned-responses/new',
  CannedResponsesEdit = 'canned-responses/:id',
  ChatButtons = 'chat-buttons',
  ChatButtonsAddText = 'chat-buttons/new/text',
  ChatButtonsAddImage = 'chat-buttons/new/image',
  ChatButtonsEdit = 'chat-buttons/:id',
  ChatButtonsInstall = 'chat-buttons/:id/install',
  ChatHistory = 'chat-history',
  ChatPage = 'chat-page',
  ChatPageSetup = 'chat-page/setup',
  ChatsAssignment = 'chats-assignment',
  Code = 'code',
  CreditCardsMasking = 'credit-cards-masking',
  DeprecatedAppleBusinessChat = 'apple-business-chat',
  DeprecatedChatBoosters = 'chat-boosters',
  DeprecatedPostChatSurvey = 'post-chat-survey',
  DeprecatedPreChatSurvey = 'pre-chat-survey',
  DirectChatLink = 'direct-chat-link',
  DownloadApps = 'download-apps',
  Email = 'email',
  EyeCatcher = 'eye-catcher',
  FacebookMessenger = 'facebook-messenger',
  FileSharing = 'file-sharing',
  Goals = 'goals',
  GoalsAdd = 'goals/add',
  GoalsEdit = 'goals/:id',
  Greetings = 'greetings/:id',
  GroupsRouting = 'groups-routing',
  GroupsRoutingAdd = 'groups-routing/add',
  GroupsRoutingEdit = 'groups-routing/:id',
  HelpDesk = 'helpdesk',
  InactivityTimeouts = 'inactivity-timeouts',
  Language = 'language',
  LoginSettings = 'login-settings',
  MessagingMode = 'messaging-mode',
  PostChatForm = 'post-chat-form',
  PreChatForm = 'pre-chat-form',
  QualityBadge = 'quality-badge',
  QualityBadgeCustomization = 'quality-badge/customization',
  Queued = 'queued',
  SalesTracker = 'sales-tracker',
  SalesTrackerAdd = 'sales-tracker/add',
  SalesTrackerDetails = 'sales-tracker/:id/details',
  SalesTrackerInstall = 'sales-tracker/:id/install',
  Tags = 'tags',
  ThemeBase = 'theme',
  Theme = 'theme/:section',
  TicketForm = 'ticket-form',
  TrustedDomains = 'trusted-domains',
  SuggestedResponses = 'suggested-responses',
  ReplySuggestions = 'reply-suggestions',
  ReplySuggestionsNew = 'reply-suggestions/new',
  WelcomeScreenBase = 'welcome-screen',
  WelcomeScreen = 'welcome-screen/:section',
  Overview = 'overview',
}

export const enum SettingsRouteAction {
  Add = 'add',
  AddImage = 'addImage',
  AddText = 'addText',
  Delete = 'delete',
  Details = 'details',
  Edit = 'edit',
  Install = 'install',
  List = 'list',
}

export const FULL_WIDTH_ROUTES = [
  SettingsRoutePage.Overview,
  SettingsRoutePage.BannedCustomers,
  SettingsRoutePage.CannedResponses,
  SettingsRoutePage.SuggestedResponses,
  SettingsRoutePage.Queued,
  SettingsRoutePage.GroupsRouting,
  SettingsRoutePage.GroupsRoutingAdd,
  SettingsRoutePage.GroupsRoutingEdit,
  SettingsRoutePage.ReplySuggestions,
  SettingsRoutePage.HelpDesk,
  SettingsRoutePage.ChatPage,
  SettingsRoutePage.ChatPageSetup,
  SettingsRoutePage.Tags,
  SettingsRoutePage.TrustedDomains,
  SettingsRoutePage.ChatButtons,
  SettingsRoutePage.SalesTracker,
  SettingsRoutePage.EyeCatcher,
];

export const GROUPABLE_ROUTES = [
  SettingsRoutePage.AskForEmail,
  SettingsRoutePage.AutomatedEmails,
  SettingsRoutePage.CannedResponses,
  SettingsRoutePage.SuggestedResponses,
  SettingsRoutePage.DeprecatedChatBoosters,
  SettingsRoutePage.ChatPage,
  SettingsRoutePage.ChatPageSetup,
  SettingsRoutePage.ChatsAssignment,
  SettingsRoutePage.Email,
  SettingsRoutePage.EyeCatcher,
  SettingsRoutePage.Language,
  SettingsRoutePage.PostChatForm,
  SettingsRoutePage.PreChatForm,
  SettingsRoutePage.QualityBadge,
  SettingsRoutePage.Tags,
  SettingsRoutePage.Theme,
  SettingsRoutePage.ThemeBase,
  SettingsRoutePage.TicketForm,
  SettingsRoutePage.WelcomeScreen,
  SettingsRoutePage.WelcomeScreenBase,
];

export const PREVIEW_COLUMN_ROUTES = [
  SettingsRoutePage.AskForEmail,
  SettingsRoutePage.DeprecatedChatBoosters,
  SettingsRoutePage.EyeCatcher,
  SettingsRoutePage.Language,
  SettingsRoutePage.PostChatForm,
  SettingsRoutePage.PreChatForm,
  SettingsRoutePage.Theme,
  SettingsRoutePage.ThemeBase,
  SettingsRoutePage.TicketForm,
  SettingsRoutePage.WelcomeScreen,
  SettingsRoutePage.WelcomeScreenBase,
];

export const MOBILE_PREVIEW_COLUMN_ROUTES = [SettingsRoutePage.Theme, SettingsRoutePage.ThemeBase];

export const FALLBACK_ROUTER_ROUTES = [
  SettingsRoutePage.CannedResponses,
  SettingsRoutePage.CannedResponsesNew,
  SettingsRoutePage.CannedResponsesEdit,
  SettingsRoutePage.SuggestedResponses,
  SettingsRoutePage.DeprecatedAppleBusinessChat,
  SettingsRoutePage.AppleBusinessChat,
  SettingsRoutePage.BannedCustomers,
  SettingsRoutePage.ChatPage,
  SettingsRoutePage.ChatPageSetup,
  SettingsRoutePage.Code,
  SettingsRoutePage.Overview,
  SettingsRoutePage.DirectChatLink,
  SettingsRoutePage.Email,
  SettingsRoutePage.EyeCatcher,
  SettingsRoutePage.FacebookMessenger,
  SettingsRoutePage.HelpDesk,
  SettingsRoutePage.Theme,
  SettingsRoutePage.ThemeBase,
  SettingsRoutePage.Language,
  SettingsRoutePage.Availability,
  SettingsRoutePage.DeprecatedChatBoosters,
  SettingsRoutePage.WelcomeScreen,
  SettingsRoutePage.WelcomeScreenBase,
  SettingsRoutePage.PreChatForm,
  SettingsRoutePage.DeprecatedPreChatSurvey,
  SettingsRoutePage.AskForEmail,
  SettingsRoutePage.Queued,
  SettingsRoutePage.TicketForm,
  SettingsRoutePage.PostChatForm,
  SettingsRoutePage.DeprecatedPostChatSurvey,
  SettingsRoutePage.MessagingMode,
  SettingsRoutePage.ChatHistory,
  SettingsRoutePage.ChatsAssignment,
  SettingsRoutePage.GroupsRouting,
  SettingsRoutePage.GroupsRoutingAdd,
  SettingsRoutePage.GroupsRoutingEdit,
  SettingsRoutePage.FileSharing,
  SettingsRoutePage.InactivityTimeouts,
  SettingsRoutePage.ReplySuggestions,
  SettingsRoutePage.ReplySuggestionsNew,
  SettingsRoutePage.ArchivesForwarding,
  SettingsRoutePage.CreditCardsMasking,
  SettingsRoutePage.ChatButtons,
  SettingsRoutePage.ChatButtonsAddText,
  SettingsRoutePage.ChatButtonsAddImage,
  SettingsRoutePage.ChatButtonsInstall,
  SettingsRoutePage.ChatButtonsEdit,
  SettingsRoutePage.Tags,
  SettingsRoutePage.QualityBadge,
  SettingsRoutePage.QualityBadgeCustomization,
  SettingsRoutePage.TrustedDomains,
  SettingsRoutePage.SalesTracker,
  SettingsRoutePage.SalesTrackerAdd,
  SettingsRoutePage.SalesTrackerDetails,
  SettingsRoutePage.SalesTrackerInstall,
  SettingsRoutePage.LoginSettings,
  SettingsRoutePage.AccessRestriction,
  SettingsRoutePage.GroupsRouting,
  SettingsRoutePage.AutomatedEmails,
];
