/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
// @ts-strict-ignore
import { createReconnectableIframe } from '@livechat/postmessage';

import { type INormalizedInboxMessage } from '../message/inbox/inbox-message';
import { denormalizeInboxMessage } from '../message/inbox/inbox-message-factory';
import { type IOutboxMessage } from '../message/outbox/outbox-message';
import { normalizeOutboxMessage } from '../message/outbox/outbox-message-factory';

import { type IConnection, type IInboxMessageSubscriber, CONNECTION_MISSING_ERROR_MESSAGE } from './connection';
import { createSubscriberNotifier } from './helpers';

interface ITrustedConnectionOptions {
  onReconnect?(): void;
}

export async function createTrustedConnection(
  iframe: HTMLIFrameElement,
  options: ITrustedConnectionOptions = {}
): Promise<IConnection> {
  const connectionId = String(Math.random());
  const subscribers = new Set<IInboxMessageSubscriber>();
  const notifySubscribers = createSubscriberNotifier(subscribers);

  const handleMessage = (message: INormalizedInboxMessage): void => {
    notifySubscribers(denormalizeInboxMessage(message), connectionId);
  };

  let connection;

  // Wait for first connection
  await new Promise((resolve) => {
    createReconnectableIframe(
      {
        frame: iframe,
        targetOrigin: '*',
        handshakeRetry: {
          interval: 500,
          count: 20,
        },
        onConnected: (c) => {
          if (!connection) {
            resolve('Connected');
          } else if (options.onReconnect) {
            options.onReconnect();
          }
          connection = c;
        },
      },
      { handle: handleMessage }
    );
  });

  return {
    connectionId,
    initialize() {
      // No initialization logic needed, all is handled by the SDK.
    },
    subscribe(subscriber: IInboxMessageSubscriber) {
      subscribers.add(subscriber);
    },
    unsubscribe(subscriber: IInboxMessageSubscriber) {
      subscribers.delete(subscriber);
    },
    send(message: IOutboxMessage) {
      if (connection) {
        return connection.call('handle', normalizeOutboxMessage(message));
      }

      return Promise.reject(new Error(CONNECTION_MISSING_ERROR_MESSAGE));
    },
    setContext(context) {
      this.msgContext = context;
    },
    getContext() {
      return this.msgContext || {};
    },
    destroy() {
      connection.destroy();
      subscribers.clear();
    },
  };
}
