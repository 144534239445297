import { DesignToken } from '@livechat/design-system-react-components';

import { withVar } from 'helpers/styles';

export const NAVIGATION_BAR_TEST_ID = 'navigation-bar';
export const MY_PROFILE_TEST_ID = 'my-profile';
export const STATUS_SWITCH_TEST_ID = 'status-switch';

export const activeNavigationItemColor = `color-mix(in srgb, ${withVar(
  DesignToken.SurfaceLockedWhite
)} 15%, transparent)`;
