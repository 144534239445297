import { FC } from 'react';

import { TourStep } from 'components/tours/TourStep';
export const TargetConfigButtonStep: FC = () => (
  <TourStep title="Change targets" message="You can easily change your targets here." />
);
export const ColumnConfigButtonStep: FC = () => (
  <TourStep
    title="Adjust the report view"
    message="Show, hide, and move the metrics in the table to adjust the view to your needs."
  />
);
