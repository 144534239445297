export const enum TrafficEvent {
  AssignChatUsed = 'Assign to me button clicked',
  GoToChatUsed = 'Go to chat button clicked',
  PickFromTheQueueUsed = 'Customer picked from queue',
  StartChatUsed = 'Chat started',
  ColumnResized = 'Column resized',
  ColumnsChanged = 'Columns changed',
  ColumnsMoved = 'Columns moved',
  ColumnsSettingsClosed = 'Columns Settings Closed',
  ColumnsSettingsOpened = 'Columns Settings Opened',
  DataSorted = 'Data sorted',
  StopSuperviseChatUsed = 'Stop supervising button clicked',
  /* eslint-disable @typescript-eslint/no-duplicate-enum-values */
  PickFromQueueClicked = 'Customer picked from queue',
  StopSupervisingClicked = 'Stop supervising button clicked',
  /* eslint-enable @typescript-eslint/no-duplicate-enum-values */
  SegmentUsed = 'Segment used',
  CustomerSelected = 'Customer selected',
  PickFromQueueWithShortcut = 'Customer picked from queue with keyboard shortcut',
  MessageSent = 'Message sent',
  IntentOnboardingTooltipCTAClicked = 'Intent onboarding tooltip CTA clicked',
  IntentOnboardingTooltipClosed = 'Intent onboarding tooltip closed',
  IntentModalOpened = 'Intent modal opened',
}
