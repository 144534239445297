import { type JSONIncomingEvent } from 'constants/socket';
import { SurveyType } from 'constants/survey-type';
import type { IAppState } from 'services/app-state-provider';
import { ChatsEntitiesActions } from 'store/entities/chats/actions';
import { getThreadByCustomerId } from 'store/entities/chats/selectors';

import { deserializeSurvey } from '../serialization/deserialize-event';

type Payload = {
  name: JSONIncomingEvent.QueuedVisitor;
  visitor: {
    id: string;
    email: string;
    name: string;
    /* eslint-disable @typescript-eslint/naming-convention */
    prechat_survey: string;
    integration_params: [];
    /* eslint-enable @typescript-eslint/naming-convention */
  };
};

export function handleCustomerStateChangeToQueued(payload: Payload, store: IAppState): void {
  const { id: customerId, prechat_survey: prechatSurveyResponses } = payload.visitor;
  const queuedAtInMs = new Date().getTime();
  const state = store.getState();
  const thread = getThreadByCustomerId(state, customerId);

  const prechatSurvey = deserializeSurvey(prechatSurveyResponses, customerId, SurveyType.Pre, queuedAtInMs);
  const events = {
    ...(prechatSurvey && { [prechatSurvey.id]: prechatSurvey }),
  };

  if (!thread) {
    return;
  }

  store.dispatch(
    ChatsEntitiesActions.updateChatThread({ threadId: thread.threadId, thread: { ...thread, queuedAtInMs } })
  );
  store.dispatch(ChatsEntitiesActions.updateEvents({ events, threadId: thread.threadId }));
}
