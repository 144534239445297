// @ts-strict-ignore
import { subDays } from 'date-fns';

import { type AgentAssignmentFilter } from 'constants/filters/agent-assignment-filter';
import { type CustomDateRange, type CustomDateRangeFilter } from 'constants/filters/date-range-filter';
import { Filter } from 'constants/filters/filter';
import { SurveyType } from 'constants/filters/survey-type-filter';
import { type Weekday } from 'constants/filters/weekday-filter';
import { ReportDistribution } from 'constants/reports/distribution';
import { ReportType } from 'constants/reports/report-type';
import { getWeekdayName } from 'helpers/date';
import {
  deserializeCustomDateRangeURLParams,
  deserializeCustomDateURLParams,
  prepareFilterOperators,
} from 'helpers/filters';
import { getReportId, getProperDistribution } from 'helpers/reports';

import {
  type IReportsViewAgentFilter,
  type ReportsViewFilters,
  type IReportsViewGroupFilter,
  type IReportsViewState,
} from '../../../interfaces/reports';

import { type ReportsViewAction, ReportsViewActionsNames } from './actions';
import { DEFAULT_COLUMNS_ORDER, DEFAULT_VISIBLE_COLUMNS } from './constants';
import {
  getStateForDeleteReportViewSuccess,
  getStateForLoadReportViews,
  getStateForSaveReportViewSuccess,
  getStateForSelectReportView,
  getStateForSetColumnsOrder,
  getStateForSetColumnsVisibility,
  getStateForUnselectReportView,
  getStateForUpdateFilterOperator,
} from './helpers/reducer';

export const initialState: IReportsViewState = {
  currentView: ReportType.Last7days,
  filters: {
    agent: null,
    agentAssignment: null,
    dateRange: deserializeCustomDateRangeURLParams(subDays(new Date(), 6).toString(), new Date().toString()),
    date: deserializeCustomDateURLParams(new Date().toString()),
    weekday: getWeekdayName(new Date()) as Weekday,
    goal: null,
    greeting: null,
    group: null,
    saleGoal: null,
    surveyType: SurveyType.PreChat,
    tag: null,
    channel: null,
    countryISO: null,
  },
  filtersOrder: ['date', 'dateRange', 'weekday', 'surveyType'],
  filtersOperators: {},
  distribution: ReportDistribution.Day,
  benchmarkEnabled: false,
  last7DaysMode: null,
  data: {},
  cacheExpirationTime: {},
  heatmapFilters: {},
  selectedView: null,
  views: {},
  visibleColumns: DEFAULT_VISIBLE_COLUMNS,
  columnsOrder: DEFAULT_COLUMNS_ORDER,
  showNavigation: true,
};

export const reportsReducer = (
  state: IReportsViewState = initialState,
  action: ReportsViewAction
): IReportsViewState => {
  switch (action.type) {
    case ReportsViewActionsNames.SET_CURRENT_VIEW: {
      const currentView = action.payload.reportType;

      return {
        ...state,
        currentView,
      };
    }
    case ReportsViewActionsNames.SET_CACHE_EXPIRATION_TIME: {
      const { reportType, expirationTime } = action.payload;

      return {
        ...state,
        cacheExpirationTime: {
          ...state.cacheExpirationTime,
          [reportType]: expirationTime,
        },
      };
    }

    case ReportsViewActionsNames.SET_DATA: {
      const { reportType, data } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          [reportType]: data,
        },
      };
    }

    case ReportsViewActionsNames.SET_QUEUE_ABANDONMENT_PAGE: {
      const { page } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          [ReportType.QueueAbandonment]: {
            ...state.data[ReportType.QueueAbandonment],
            page,
          },
        },
      };
    }

    case ReportsViewActionsNames.SET_GREETINGS_CONVERSION_BREAKDOWN_CHECKS: {
      const { checked } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          [ReportType.Greetings]: {
            ...state.data[ReportType.Greetings],
            checked,
          },
        },
      };
    }

    case ReportsViewActionsNames.SET_TAGS_USAGE_BREAKDOWN_CHECKS: {
      const { checked } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          [ReportType.TagsUsage]: {
            ...state.data[ReportType.TagsUsage],
            checked,
          },
        },
      };
    }

    case ReportsViewActionsNames.CREATE_SCHEDULED_REPORT_SUCCESS: {
      const { data } = action.payload;
      if (!data) {
        return { ...state };
      }
      const { sortedIds } = state.data[ReportType.ScheduledReports];
      const highlightedIds = state.data[ReportType.ScheduledReports].highlightedIds || [];
      const reports = state.data[ReportType.ScheduledReports].data;

      sortedIds.push(data.id);
      highlightedIds.push(data.id);
      reports[data.id] = data;

      return {
        ...state,
        data: {
          ...state.data,
          [ReportType.ScheduledReports]: {
            sortedIds,
            highlightedIds,
            data: reports,
          },
        },
      };
    }

    case ReportsViewActionsNames.EDIT_SCHEDULED_REPORT_SUCCESS: {
      const { data } = action.payload;
      let { sortedIds } = state.data[ReportType.ScheduledReports];
      const reports = state.data[ReportType.ScheduledReports].data;
      const currentReportName = getReportId(data.typeCurrent, data.periodCurrent);

      delete reports[currentReportName];

      sortedIds = sortedIds.filter((itemId) => {
        return itemId !== currentReportName;
      });
      sortedIds.push(data.id);

      reports[data.id] = {
        ...reports[data.id],
        ...data,
      };

      return {
        ...state,
        data: {
          ...state.data,
          [ReportType.ScheduledReports]: {
            sortedIds,
            data: reports,
          },
        },
      };
    }

    case ReportsViewActionsNames.DELETE_SCHEDULED_REPORT_SUCCESS: {
      const {
        data: { id },
      } = action.payload;

      const reports = state.data[ReportType.ScheduledReports].data;
      delete reports[id];

      const sortedIds = state.data[ReportType.ScheduledReports].sortedIds.filter((itemId) => {
        return itemId !== id;
      });

      return {
        ...state,
        data: {
          ...state.data,
          [ReportType.ScheduledReports]: {
            sortedIds,
            data: reports,
          },
        },
      };
    }

    case ReportsViewActionsNames.SET_DISTRIBUTION: {
      const { type } = action.payload;

      let distribution = type;
      let benchmarkEnabled = state.benchmarkEnabled;

      if (distribution === null) {
        const dateRange = state.filters[Filter.DateRange];
        distribution = getProperDistribution(dateRange, distribution);
      }

      if (distribution === ReportDistribution.Hour) {
        benchmarkEnabled = false;
      }

      return {
        ...state,
        distribution,
        benchmarkEnabled,
      };
    }

    case ReportsViewActionsNames.SET_BENCHMARK_ENABLED: {
      const { benchmarkEnabled } = action.payload;

      return {
        ...state,
        benchmarkEnabled,
      };
    }

    case ReportsViewActionsNames.RESET_FILTERS: {
      return {
        ...state,
        filters: initialState.filters,
        filtersOrder: initialState.filtersOrder,
        filtersOperators: initialState.filtersOperators,
        distribution: initialState.distribution,
      };
    }

    case ReportsViewActionsNames.UPDATE_FILTER: {
      const filter = action.payload;

      const newFilters = { ...state.filters };
      let newFiltersOrder = [...state.filtersOrder];

      if (filter.value === null) {
        const filterName = filter.name as Filter;
        if (![Filter.Date, Filter.DateRange].includes(filter.name)) {
          newFilters[filterName] = null;
          newFiltersOrder = newFiltersOrder.filter((filterName: Filter) => filterName !== filter.name);
        } else {
          newFilters[filterName] = initialState.filters[filter.name];
        }
      } else {
        if (filter.name === Filter.Group) {
          const groupValue: IReportsViewGroupFilter = {
            groups: filter.value as string[],
            compare: filter.compare as string[],
          };

          newFilters[filter.name] = groupValue;
        } else if (filter.name === Filter.Agent) {
          const agentValue: IReportsViewAgentFilter = {
            agents: filter.value as string[],
            compare: filter.compare as string[],
          };

          newFilters[filter.name] = agentValue;

          // Turn off AgentAssignment filter if agent is set
          newFilters[Filter.AgentAssignment] = null;
          newFiltersOrder = newFiltersOrder.filter((filterName: Filter) => filterName !== Filter.AgentAssignment);
        } else if (filter.name === Filter.DateRange) {
          const dateRangeValue: CustomDateRangeFilter = {
            ...(filter.value as CustomDateRange),
            compare: filter.compare as CustomDateRange,
          };

          newFilters[filter.name] = dateRangeValue;
        } else if (filter.name === Filter.AgentAssignment) {
          newFilters[filter.name] = filter.value as AgentAssignmentFilter[];

          // Turn off agent filter if AgentAssignment is set
          newFilters[Filter.Agent] = null;
          newFiltersOrder = newFiltersOrder.filter((filterName: Filter) => filterName !== Filter.Agent);
        } else {
          const filterName = filter.name as Filter;
          newFilters[filterName] = filter.value;
        }

        if (!newFiltersOrder.some((filterName: Filter) => filterName === filter.name)) {
          newFiltersOrder = [filter.name, ...newFiltersOrder];
        }
      }

      let { distribution, heatmapFilters } = state;

      if (filter.name === Filter.DateRange) {
        const dateRange = newFilters[filter.name];
        distribution = getProperDistribution(dateRange, distribution);

        heatmapFilters = {};
      }

      return {
        ...state,
        filters: newFilters,
        filtersOrder: newFiltersOrder,
        distribution,
        heatmapFilters,
      };
    }

    case ReportsViewActionsNames.SET_FILTERS: {
      const { distribution: newDistribution, operators, ...filters } = action.payload;
      const newFilters = {
        ...initialState.filters,
        ...filters,
      };

      const newOperators = {
        ...initialState.filtersOperators,
        ...operators,
      };

      let { distribution, heatmapFilters } = state;
      const { dateRange } = filters;

      if (dateRange) {
        distribution = getProperDistribution(dateRange, newDistribution || distribution);

        heatmapFilters = {};
      }

      const newFiltersOrder = Object.keys(filters).filter((f) => Boolean(newFilters[f]));

      return {
        ...state,
        distribution,
        filters: newFilters,
        filtersOperators: newOperators,
        filtersOrder: newFiltersOrder,
        heatmapFilters,
      };
    }

    case ReportsViewActionsNames.SET_QUERY_PARAMS_FROM_URL: {
      const {
        agent,
        dateRange,
        date,
        weekday,
        greeting,
        goal,
        group,
        rating,
        saleGoal,
        surveyType,
        tag,
        channel,
        agentAssignment,
        distribution = ReportDistribution.Day,
        benchmarkEnabled = true,
        operators,
        countryISO,
      } = action.payload.params;

      const newFilterValues: ReportsViewFilters = {
        ...(agent && { agent }),
        ...(dateRange && { dateRange }),
        ...(date && { date }),
        ...(weekday && { weekday }),
        ...(goal && { goal }),
        ...(greeting && { greeting }),
        ...(group && { group }),
        ...(rating && { rating }),
        ...(saleGoal && { saleGoal }),
        ...(surveyType && { surveyType }),
        ...(tag && { tag }),
        ...(channel && { channel }),
        ...(agentAssignment && { agentAssignment }),
        ...(countryISO && { countryISO }),
      };

      const filtersOperators = prepareFilterOperators(operators);

      return {
        ...state,
        filters: {
          ...initialState.filters,
          ...newFilterValues,
        },
        distribution,
        benchmarkEnabled,
        filtersOperators,
        filtersOrder: [
          Filter.Date,
          Filter.DateRange,
          Filter.Weekday,
          Filter.SurveyType,
          Filter.Agent,
          Filter.Group,
          Filter.Goal,
          Filter.SaleGoal,
          Filter.Tag,
          Filter.Rating,
          Filter.Greeting,
          Filter.Channel,
          Filter.AgentAssignment,
          Filter.Availability,
          Filter.CountryISO,
        ].filter((f) =>
          Boolean(newFilterValues[f] || [Filter.DateRange, Filter.Date, Filter.Weekday, Filter.SurveyType].includes(f))
        ),
      };
    }

    case ReportsViewActionsNames.SET_LAST_7_DAYS_MODE: {
      const { last7DaysMode } = action.payload;

      return {
        ...state,
        last7DaysMode,
      };
    }

    case ReportsViewActionsNames.UPDATE_FILTER_OPERATOR: {
      return getStateForUpdateFilterOperator(state, action.payload);
    }

    case ReportsViewActionsNames.SET_HEATMAP_FILTERS: {
      const { reportType, from, to, selectedFrom, selectedTo } = action.payload;

      return {
        ...state,
        heatmapFilters: {
          ...state.heatmapFilters,
          [reportType]: {
            from,
            to,
            selectedFrom,
            selectedTo,
          },
        },
      };
    }
    case ReportsViewActionsNames.SET_COLUMNS_VISIBILITY: {
      const { payload } = action;

      return getStateForSetColumnsVisibility(state, payload);
    }
    case ReportsViewActionsNames.SET_COLUMNS_ORDER: {
      const { payload } = action;

      return getStateForSetColumnsOrder(state, payload);
    }

    case ReportsViewActionsNames.LOAD_REPORT_VIEWS:
      return getStateForLoadReportViews(state, action.payload);

    case ReportsViewActionsNames.SAVE_REPORT_VIEW_SUCCESS:
      return getStateForSaveReportViewSuccess(state, action.payload);
    case ReportsViewActionsNames.DELETE_REPORT_VIEW_SUCCESS:
      return getStateForDeleteReportViewSuccess(state, action.payload);
    case ReportsViewActionsNames.SELECT_REPORT_VIEW:
      return getStateForSelectReportView(state, action.payload);
    case ReportsViewActionsNames.UNSELECT_REPORT_VIEW:
      return getStateForUnselectReportView(state);

    case ReportsViewActionsNames.SHOULD_SHOW_NAVIGATION:
      return {
        ...state,
        showNavigation: action.payload.showNavigation,
      };

    default:
      return state;
  }
};
