import { type UseQueryResult, useQuery } from '@tanstack/react-query';
import debug from 'debug';

import { DebugLogsNamespace } from 'constants/debug-logs-namespace';
import { QueryKey } from 'constants/query-key';
import { deserializeError } from 'helpers/deserialize-error';
import { ApiManager } from 'services/api/api-manager';
import { type Features } from 'services/api/feature-control/interfaces';
import { defaultFeatures, deserializeFeatures } from 'services/api/feature-control/serializer';

const log = debug(DebugLogsNamespace.AppDebug);

interface IUseFetchFeatures {
  data: Features;
  refetch: UseQueryResult<Features>['refetch'];
}

export async function fetchFeatures(): Promise<Features> {
  const { result, error } = await ApiManager.featureControlApi.fetchAll();

  if (error) {
    log('[Features] fetchFeatures error', error);

    throw deserializeError(error);
  }

  log('[Features] fetchFeatures result', result.feature_flags);

  return deserializeFeatures(result);
}

export function useFetchFeatures(): IUseFetchFeatures {
  const { data, refetch } = useQuery({
    queryKey: [QueryKey.Features],
    queryFn: fetchFeatures,
    retry: 1,
    initialData: defaultFeatures,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  log('[Features] useFetchFeatures data.customerIntentEnabled', data.customerIntentEnabled);

  return {
    data,
    refetch,
  };
}
