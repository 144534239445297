import type { FC, ReactNode } from 'react';

import * as styles from './styles';

interface IProps {
  children: ReactNode;
  className?: string;
}

export const NavigationList: FC<IProps> = ({ children, className }) => (
  <div className={className}>
    <ul className={styles.navigationList}>{children}</ul>
  </div>
);
