import { type FC } from 'react';

import { cx } from '@emotion/css';
import { Modal } from '@livechat/design-system-react-components';

import { type ModalProps } from 'components/apps/interfaces';
import { Divider } from 'components/divider/Divider';

import { useResourceContext } from '../../hooks/use-resource-context';

import { ManageResourcesModalFooter } from './ManageResourcesModalFooter';
import { TransactionDetails } from './TransactionDetails';

import * as styles from './styles';

export const ManageResourcesModal: FC<ModalProps> = ({ closeModal, openModal }) => {
  const { transactions } = useResourceContext();

  return (
    <Modal
      heading="Manage In-App Upgrades"
      className={cx(styles.modal, 'upgrades-modal')}
      contentClassName={styles.modalContent}
      onClose={closeModal}
      footer={<ManageResourcesModalFooter closeModal={closeModal} openModal={openModal} />}
    >
      <div className={styles.list}>
        {transactions?.map((transaction, index) => {
          const displayDivider = index !== transactions.length - 1;

          return (
            <div key={transaction.id}>
              <TransactionDetails
                key={transaction.id}
                transaction={transaction}
                isLastTransaction={transactions.length === 1}
                closeModal={closeModal}
              />
              {displayDivider && <Divider className={styles.divider} />}
            </div>
          );
        })}
      </div>
    </Modal>
  );
};
