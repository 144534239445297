import { type ConfigurationApiClientError, type ConfigurationApiNormalizedError } from './types';

/**
 * Normalizes an API error object.
 * @param error - The API error object.
 * @returns The normalized error object.
 */
export function normalizeError(error: ConfigurationApiClientError): ConfigurationApiNormalizedError {
  if (error.http instanceof Error) {
    return {
      type: 'internal',
      message: error.http.message,
      status: error.status,
    };
  }

  if (error.http && 'error' in error.http) {
    return {
      ...error.http.error,
      status: error.status,
    };
  }

  return {
    type: 'internal',
    message: JSON.stringify(error),
    status: error.status,
  };
}
