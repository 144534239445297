// @ts-strict-ignore
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import difference from 'lodash.difference';
import isEmpty from 'lodash.isempty';
import isEqual from 'lodash.isequal';
import { type SagaIterator } from 'redux-saga';
import { all, call, delay, fork, put, putResolve, race, select, take, takeEvery, throttle } from 'redux-saga/effects';

import { AutocompleteAmplitudeEvent } from 'constants/autocomplete-events';
import { ChatEventSubType } from 'constants/chat-event-type';
import { SET_TAG_WAIT_TIMEOUT } from 'constants/chat-tag-status';
import { ChatThreadStatus } from 'constants/chat-thread-status';
import { ChatType } from 'constants/chat-type';
import { UNASSIGNED_CHATS_PAGE_SIZE } from 'constants/chats/chat-list';
import { ChatEvent } from 'constants/chats/event';
import { DEFAULT_VISITOR_NAME, UNNAMED_AUTHOR } from 'constants/localization';
import { Section } from 'constants/section';
import { SortOrder } from 'constants/sort-order';
import { SoundNotification } from 'constants/sound-notifications';
import { SprigEvents } from 'constants/sprig-events';
import { ActionHandlerExecutionDelay, ToastAutoHideDelay, ToastContent } from 'constants/toasts';
import { UserType as ChatEventAuthor } from 'constants/user-type';
import { EventPlace } from 'helpers/analytics';
import { toKeyMap } from 'helpers/array';
import { isDesktopClient, isWindowsDesktopClient } from 'helpers/browser';
import { getConfig } from 'helpers/config';
import type { KeyMap } from 'helpers/interface';
import { getToastContent } from 'helpers/toast';
import { type GroupId } from 'interfaces/groups';
import { type IncomingResponseMessage } from 'interfaces/incoming-message';
import { RestrictedAccessInformation } from 'services/api/archive/serializers';
import { type IChatResult, type ChatSummaryResult, type ThreadDetailsResult } from 'services/api/chat/interfaces';
import { deserializeChatsThreadsDetails, type IDeserializeChatThreadDetails } from 'services/api/chat/serializer';
import { AppStateProvider } from 'services/app-state-provider';
import { chatFollowManager } from 'services/chat-follow-manager';
import { chatUsersClient } from 'services/connectivity/agent-chat-api/chat-users/client';
import { chatsClient } from 'services/connectivity/agent-chat-api/chats/client';
import { type GetChatResponse } from 'services/connectivity/agent-chat-api/chats/types/get-chat';
import {
  type ListChatsRequest,
  type ListChatsResponse,
} from 'services/connectivity/agent-chat-api/chats/types/list-chats';
import { type ListThreadsResponse } from 'services/connectivity/agent-chat-api/chats/types/list-threads';
import { type ResumeChatResponse } from 'services/connectivity/agent-chat-api/chats/types/resume-chat';
import { type StartChatResponse } from 'services/connectivity/agent-chat-api/chats/types/start-chat';
import { normalizeError } from 'services/connectivity/agent-chat-api/helpers';
import {
  AgentChatApiErrorType,
  UserType,
  Visibility,
  type AgentChatApiResponse,
} from 'services/connectivity/agent-chat-api/types';
import { Notification } from 'services/connectivity/configuration-api/agents/types';
import { HTTPStatus } from 'services/connectivity/http/types';
import { trackEvent } from 'services/event-tracking';
import { getThreadEntityTimestampInMs } from 'services/serialization/timestamp';
import { handleIncomingChat } from 'services/socket-lc3/chat/event-handling/incoming-chat';
import type { IIncomingChatPushEvent } from 'services/socket-lc3/chat/interfaces';
import { deserializeThread } from 'services/socket-lc3/chat/serialization/deserialize';
import { deserializeChatSummaryCollection } from 'services/socket-lc3/chat/serialization/deserialize-collection';
import { deserializeChatsSummaryCustomers } from 'services/socket-lc3/chat/serialization/deserialize-customer';
import { deserializeChatsSummaryCustomersVisitedPages } from 'services/socket-lc3/chat/serialization/deserialize-customer-visited-pages';
import { deserializeThreadEventCollection } from 'services/socket-lc3/chat/serialization/deserialize-event-collection';
import { getWasEventSeen } from 'services/socket-lc3/chat/serialization/helpers/common';
import { getSprigService } from 'services/sprig';
import { startChat as startChatting } from 'services/start-chat';
import { deactivateChat } from 'services/web-socket/actions/deactivate-chat';
import { stopSupervisingChat } from 'services/web-socket/actions/stop-supervising-chat';
import { superviseChat as supervise } from 'services/web-socket/actions/supervise-chat';
import { tagChatThread } from 'services/web-socket/actions/tag-chat-thread';
import { unpinChat as unpin } from 'services/web-socket/actions/unpin-chat';
import { untagChatThread } from 'services/web-socket/actions/untag-chat-thread';
import { updateEventProperties } from 'services/web-socket/actions/update-event-properties';
import { RequestAction } from 'store/entities/actions';
import {
  getAreNotificationsMuted,
  getAreNotificationsSoundsRepeated,
  getIsNotificationEnabled,
  getLoggedInAgentGroupsIds,
  getLoggedInAgentLogin,
} from 'store/entities/agents/selectors';
import { getIsChatSentimentEnabled } from 'store/entities/applications/selectors';
import { CustomerActions } from 'store/entities/customers/actions';
import { AGENT_CUSTOM_PROPERTIES } from 'store/features/agent-custom-properties/actions';
import {
  getAICannedAutocompleteVisible,
  hasFetchedAgentCustomProperties,
} from 'store/features/agent-custom-properties/selectors';
import { BrowserNotificationsActions } from 'store/features/browser-notifications/actions';
import { getIsOnSection } from 'store/features/routing/selectors';
import { getCanUseTags } from 'store/features/session/selectors';
import { SoundNotificationActions } from 'store/features/sound-notifications/actions';
import { ToastsActions } from 'store/features/toasts/actions';
import { ToastVariant } from 'store/features/toasts/interfaces';
import type { IActionWithPayload } from 'store/helper';
import { ChatsViewActions, ChatsViewActionsNames } from 'store/views/chats/actions';
import { createChatPath } from 'store/views/chats/helpers/navigation';
import {
  compareQueuedChatListItems,
  compareStartedChatListItems,
  compareSupervisedChatListItems,
  compareUnassignedListItems,
} from 'store/views/chats/helpers/selectors';
import type { IChatListItem, ITriggerChatNotificationPayload } from 'store/views/chats/interfaces';
import {
  getChatsSortType,
  getCurrentMessageBoxValue,
  getPersistedChatsSortType,
  getSelectedThreadChatId,
  getSelectedThreadId,
  getThreadAgentName,
  getThreadTagsProcessing,
} from 'store/views/chats/selectors';
import { TrafficActions } from 'store/views/traffic/actions';

import { CHATS, ChatsEntitiesActionNames, ChatsEntitiesActions } from '../actions';
import { getClosedThreadIds } from '../computed';
import {
  BrowserNotificationTag,
  CHATS_LIST_UPDATE_THROTTLE_TIME,
  CHATS_SENTIMENT_THROW_ERROR_TIME,
  NO_REACTION,
  QUEUED_LIST_UPDATE_THROTTLE_TIME,
} from '../constants';
import {
  isActiveUnassignedChat,
  isClosedThread,
  isNewAttachmentAction,
  isNewEventNotificationTriggerAction,
  isNewMessageAction,
  isNewSystemMessageAction,
  isQueuedChat,
  isSupervisedChat,
  isSystemMessage,
  isUnassignedChat,
} from '../helpers/common';
import { getExtractedMessageText, getThreadCreationDateComparator } from '../helpers/sagas';
import type {
  ChatEventEntity,
  ChatThreadEntity,
  IAddReactionPayload,
  IAssignChatPayload,
  IAssignChatToOtherAgentPayload,
  IChatHistoryBase,
  IChatHistorySummary,
  IChatUser,
  IFetchAdditionalUnassignedChatsSummaryPayload,
  IFetchChatDataPayload,
  IFetchChatHistoryPayload,
  IFetchChatThreadsDetailsPayload,
  IFetchChatThreadsDetailsSuccessPayload,
  IFetchIncompleteThreadEventsPayload,
  IIncomingChatThreadPayload,
  INewEventActionPayload,
  INewEventNotificationBody,
  INewMessageNotificationPayload,
  INewMessagePayload,
  IPickFromQueuePayload,
  ISetChatsSummaryPayload,
  ISetTagRequestPayload,
  ISetUnassignedChatsSummaryPayload,
  IStartChatPayload,
  IStartSupervisingPayload,
  IStartedThread,
  IStopSupervisingPayload,
  ISupervisePayload,
  IUnassignedChat,
  IUnpinChatPayload,
  IUpdateChatThreadPayload,
} from '../interfaces';
import {
  getAllChats,
  getChatHistorySummary,
  getChatIdByCustomerId,
  getChatIdByThreadId,
  getChatUsers,
  getCustomerMessageLastSentiment,
  getEventAuthorName,
  getEvents,
  getMyChats,
  getMyChatsIds,
  getQueuedIds,
  getSupervisedChats,
  getSupervisedIds,
  getThread,
  getThreadByCustomerId,
  getThreadEvents,
  getThreadType,
  getUnassignedIds,
  getUnassingedChatsNextPageId,
} from '../selectors';

const CRS_NAMESPACE = getConfig().crsNamespace;
const CHAT_SENTIMENT_NAMESPACE = getConfig().chatSentimentNamespace;
const CLIENT_ID = getConfig().accountsClientId;
const DEFAULT_UNASSIGNED_CHATS_FILTERS = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  include_active: false,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  include_chats_without_threads: false,
  properties: {
    routing: {
      pinned: {
        values: [true],
      },
    },
  },
};

/**
 * Used to mute 'incoming_chat' notifications after ie. pick from queue
 */
const chatsWithMutedNotifications = {};

export function* updateCustomersCollections(chatsSummary: ChatSummaryResult[]): SagaIterator {
  const customers = deserializeChatsSummaryCustomers(chatsSummary);
  const visitedPages = deserializeChatsSummaryCustomersVisitedPages(chatsSummary);
  yield put(CustomerActions.addOrUpdateCustomers({ customers, visitedPages }));

  chatsSummary.forEach((chat) => {
    if (chat && chat.users) {
      AppStateProvider.dispatch(ChatsEntitiesActions.addChatUsers({ chatId: chat.id, chatUsers: chat.users }));
    }
  });
}

function* fetchAdditionalUnassignedChats(
  action: IActionWithPayload<string, IFetchAdditionalUnassignedChatsSummaryPayload>
): SagaIterator {
  const { shouldReplace, limit } = action.payload || {};
  const agentGroupIds: GroupId[] = yield select(getLoggedInAgentGroupsIds);
  const sortType = yield select(getChatsSortType, ChatType.Unassigned);

  const { result }: AgentChatApiResponse<ListChatsResponse> = yield call(chatsClient.listChats, {
    filters: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      group_ids: agentGroupIds.map(Number),
      ...DEFAULT_UNASSIGNED_CHATS_FILTERS,
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    sort_order: sortType,
    limit,
  });

  if (result) {
    const { chats_summary: chatsSummary, next_page_id: nextPageId, found_chats: unassignedChatsCount } = result;
    const castedChatsSummary = chatsSummary as never as ChatSummaryResult[];
    yield call(updateCustomersCollections, castedChatsSummary);

    const currentAgentId: ReturnType<typeof getLoggedInAgentLogin> = yield select(getLoggedInAgentLogin);

    const { threads, events } = deserializeChatSummaryCollection(castedChatsSummary, currentAgentId, getWasEventSeen);

    yield put(
      ChatsEntitiesActions.fetchAdditionalUnassignedChatsSummarySuccess({
        threads,
        events,
        nextPageId,
        unassignedChatsCount,
        shouldReplace,
      })
    );
  }
}

function* fetchMoreUnassignedChats(): SagaIterator {
  const unassignedChatsNextPageId: string = yield select(getUnassingedChatsNextPageId);
  const agentGroupIds: GroupId[] = yield select(getLoggedInAgentGroupsIds);
  const sortType = yield select(getChatsSortType, ChatType.Unassigned);

  const listChatsParams: ListChatsRequest = unassignedChatsNextPageId
    ? {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        page_id: unassignedChatsNextPageId,
      }
    : {
        filters: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          group_ids: agentGroupIds.map(Number),
          ...DEFAULT_UNASSIGNED_CHATS_FILTERS,
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        sort_order: sortType,
      };
  const { result }: AgentChatApiResponse<ListChatsResponse> = yield call(chatsClient.listChats, listChatsParams);

  if (result) {
    const { chats_summary: chatsSummary, next_page_id: nextPageId, found_chats: unassignedChatsCount } = result;
    const castedChatsSummary = chatsSummary as never as ChatSummaryResult[];
    yield call(updateCustomersCollections, castedChatsSummary);

    const currentAgentId: string = yield select(getLoggedInAgentLogin);

    const { threads, events } = deserializeChatSummaryCollection(castedChatsSummary, currentAgentId, getWasEventSeen);

    yield put(
      ChatsEntitiesActions.fetchMoreUnassignedChatsSummarySuccess({ threads, events, nextPageId, unassignedChatsCount })
    );

    const importantThreads: string[] = Object.values(threads as KeyMap<IUnassignedChat>).reduce((acc, thread) => {
      if (thread.status === ChatThreadStatus.Closed) {
        acc.push(thread.threadId);
      }

      return acc;
    }, []);
    yield put(ChatsEntitiesActions.markMultipleChatsAsImportant({ threadsIds: importantThreads }));
  } else {
    yield put(
      ToastsActions.createToast({
        content: getToastContent(ToastContent.DEFAULT_ERROR),
        kind: ToastVariant.Error,
      })
    );
  }
}

function* fetchChatHistoryThreads(
  chatId: string,
  nextPageId?: string,
  minEventsCount?: number,
  skipThreadId?: string
): SagaIterator {
  const { result }: AgentChatApiResponse<ListThreadsResponse> = yield call(chatsClient.listThreads, {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    chat_id: chatId,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    page_id: nextPageId,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    min_events_count: minEventsCount,
  });

  const currentAgentId: string = yield select(getLoggedInAgentLogin);
  const users: IChatUser[] = yield select(getChatUsers, chatId);
  const customer = users && users.find((user) => (user.type as never as UserType) === UserType.CUSTOMER);

  if (!result || !customer) {
    return null;
  }

  const isThreadNewer = getThreadCreationDateComparator(skipThreadId);

  const resultThreads = result.threads.filter(
    (thread) => !skipThreadId || (thread.id !== skipThreadId && isThreadNewer(thread as never as ThreadDetailsResult))
  );

  const isHistoryLimitReached = !!result.threads.find((thread) =>
    thread.restricted_access?.includes(RestrictedAccessInformation.ThreadsOlderThan)
  );

  const threads: KeyMap<IChatHistoryBase> = toKeyMap(
    resultThreads.map((thread) => ({
      customerId: customer.id,
      currentAgentId,
      threadId: thread.id,
      customerName: customer.name,
      startedTimestamp: getThreadEntityTimestampInMs(thread),
      groupIds: (thread.access.group_ids || [0]).map(String),
    })),
    'threadId'
  );

  const events = resultThreads.reduce((acc, thread) => {
    if (thread.restricted_access) {
      return acc;
    }

    const threadEvents = deserializeThreadEventCollection(
      thread.events,
      users,
      currentAgentId,
      getWasEventSeen,
      thread.id
    );
    acc[thread.id] = threadEvents;

    return acc;
  }, {});

  const tags = resultThreads.reduce((acc, thread) => {
    acc[thread.id] = thread.tags || [];

    return acc;
  }, {});

  return {
    threads,
    events,
    tags,
    totalThreadsCount: result.found_threads,
    nextPageId: result.next_page_id,
    isHistoryLimitReached,
  };
}

function* fetchUnassignedChats(): SagaIterator {
  const areAgentCustomPropertiesFetched: boolean = yield select(hasFetchedAgentCustomProperties);

  if (!areAgentCustomPropertiesFetched) {
    yield race({
      success: take(AGENT_CUSTOM_PROPERTIES.FETCH_AGENT_CUSTOM_PROPERTIES[RequestAction.SUCCESS]),
      failure: take(AGENT_CUSTOM_PROPERTIES.FETCH_AGENT_CUSTOM_PROPERTIES[RequestAction.FAILURE]),
    });
  }

  const agentGroupsIds: GroupId[] = yield select(getLoggedInAgentGroupsIds);
  const sortType = yield select(getPersistedChatsSortType, ChatType.Unassigned);

  const { result }: AgentChatApiResponse<ListChatsResponse> = yield call(chatsClient.listChats, {
    filters: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      group_ids: agentGroupsIds.map(Number),
      ...DEFAULT_UNASSIGNED_CHATS_FILTERS,
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    sort_order: sortType,
  });

  if (result) {
    const { chats_summary: chatsSummary, next_page_id: nextPageId, found_chats: unassignedChatsCount } = result;
    const castedChatsSummary = chatsSummary as never as ChatSummaryResult[];
    yield call(updateCustomersCollections, castedChatsSummary);

    const currentAgentId: ReturnType<typeof getLoggedInAgentLogin> = yield select(getLoggedInAgentLogin);

    const { threads, events } = deserializeChatSummaryCollection(castedChatsSummary, currentAgentId, getWasEventSeen);

    yield put(
      ChatsEntitiesActions.setUnassignedChatsSummary({
        threads: threads as KeyMap<IUnassignedChat>,
        events,
        unassignedChatsNextPageId: nextPageId,
        unassignedChatsCount,
      })
    );
    const importantThreads: string[] = Object.values(threads as KeyMap<IUnassignedChat>).reduce((acc, thread) => {
      if (thread.status === ChatThreadStatus.Closed) {
        acc.push(thread.threadId);
      }

      return acc;
    }, []);
    yield put(ChatsEntitiesActions.markMultipleChatsAsImportant({ threadsIds: importantThreads }));
  } else {
    yield put(ChatsEntitiesActions.setUnassignedChatsSummary({ threads: {}, events: {} }));
  }
}

function* stopSupervising(action: IActionWithPayload<string, IStopSupervisingPayload>): SagaIterator {
  const { threadId, shouldMoveToOtherChats } = action.payload;
  const thread: ChatThreadEntity = yield select(getThread, threadId);

  if (thread) {
    yield call(stopSupervisingChat, thread.chatId);
  }

  if (!isClosedThread(thread) || !shouldMoveToOtherChats) {
    yield put(
      ChatsEntitiesActions.updateChatThread({
        threadId: thread.threadId,
        thread: {
          type: ChatType.Other,
        },
      })
    );
  }
}

function* runTagStatusListener(threadId: string, tag: string): SagaIterator {
  const tagsProcessing: string[] = yield select(getThreadTagsProcessing, threadId);

  if (!tagsProcessing?.includes(tag)) {
    return;
  }

  const { shouldChangeStatus }: { shouldChangeStatus: unknown; shouldIgnore: boolean } = yield race({
    shouldChangeStatus: delay(SET_TAG_WAIT_TIMEOUT),
    shouldIgnore: take(
      ({ payload, type }): boolean => type === ChatsEntitiesActionNames.SAVE_TAG && payload.tag === tag
    ),
  });

  if (shouldChangeStatus) {
    yield put(
      ChatsViewActions.setChatTagFailure({
        threadId,
        tag,
      })
    );
  }
}

function* setChatTag(action: IActionWithPayload<string, ISetTagRequestPayload>): SagaIterator {
  const { chatId, threadId, tag } = action.payload;

  try {
    yield call(tagChatThread, chatId, threadId, tag);
  } catch (e) {
    // https://github.com/livechat/product-technical-roadmap/issues/41
    // eslint-disable-next-line no-console
    console.error("Socket error, tag couldn't be added", e);

    yield put(
      ToastsActions.createToast({
        content: getToastContent(ToastContent.TAG_ADD_ERROR),
        kind: ToastVariant.Error,
      })
    );
  }

  yield call(runTagStatusListener, threadId, tag);
}

function* unsetChatTagRequest(action: IActionWithPayload<string, ISetTagRequestPayload>): SagaIterator {
  const { chatId, threadId, tag } = action.payload;
  yield call(untagChatThread, chatId, threadId, tag);
}

function* startChat(action: IActionWithPayload<string, IStartChatPayload>): SagaIterator {
  const { customerId, message } = action.payload;

  const { error }: AgentChatApiResponse<StartChatResponse | ResumeChatResponse> = yield call(
    startChatting,
    customerId,
    message
  );

  if (error) {
    const normalizedError = normalizeError(error);
    const { message } = normalizedError;

    yield put(
      ToastsActions.createToast({
        content: getToastContent(ToastContent.START_CHAT_ERROR, { message }),
        kind: ToastVariant.Error,
      })
    );
  } else {
    yield put(TrafficActions.setCustomMessageModalState({ isOpen: false }));
  }
}

function* superviseChat(action: IActionWithPayload<string, ISupervisePayload>): SagaIterator {
  const { chatId } = action.payload;

  yield call(supervise, chatId);
}

function* pickFromQueue(action: IActionWithPayload<string, IPickFromQueuePayload>): SagaIterator {
  const { customerId } = action.payload;
  const thread: ChatThreadEntity = yield select(getThreadByCustomerId, customerId);

  if (thread) {
    /**
     * Marking chat 'incoming_chat' notifications as muted
     */
    chatsWithMutedNotifications[thread.chatId] = true;

    const chatId: string = yield select(getChatIdByCustomerId, customerId);
    const login: string = yield select(getLoggedInAgentLogin);

    const { error }: AgentChatApiResponse<void> = yield call(chatUsersClient.addUserToChat, {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      chat_id: chatId,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      user_id: login,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      user_type: UserType.AGENT,
      visibility: Visibility.ALL,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      ignore_requester_presence: true,
    });

    if (error) {
      const normalizedError = normalizeError(error);

      if (normalizedError.status === HTTPStatus.UnprocessableEntity) {
        yield put(ChatsEntitiesActions.chatPickedByOtherAgent({ threadId: thread.threadId }));
      } else {
        // eslint-disable-next-line no-console
        console.error('Error while picking chat from queue', normalizedError);
      }
    }

    yield put(ChatsViewActions.clearAwaitingNavigation());
  }
}

function* assignChat(action: IActionWithPayload<string, IAssignChatPayload>): SagaIterator {
  const { threadId } = action.payload;
  const thread: IUnassignedChat = yield select(getThread, threadId);
  const currentAgentId = yield select(getLoggedInAgentLogin);

  if (thread) {
    let response: AgentChatApiResponse<unknown>;

    /**
     * Marking chat 'incoming_chat' notifications as muted
     */
    chatsWithMutedNotifications[thread.chatId] = true;

    if (thread.status !== ChatThreadStatus.Closed) {
      response = yield call(chatUsersClient.addUserToChat.bind(chatUsersClient), {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        chat_id: thread.chatId,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        user_id: currentAgentId,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        user_type: UserType.AGENT,
        visibility: Visibility.ALL,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        ignore_requester_presence: true,
      });
    } else {
      const groupIds = thread.groupIds.map((id) => parseInt(id, 10));
      response = yield call(chatsClient.resumeChat, {
        chat: {
          id: thread.chatId,
          access: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            group_ids: groupIds,
          },
        },
      });
    }

    // This statement is putted here because of need to handle response only from 'assignChat' endpoint.
    if (response.result) {
      yield put(ChatsEntitiesActions.assignChatSuccess(action.payload));
      const unassignedChatsNextPageId = yield select(getUnassingedChatsNextPageId);
      const unnasignedChatsIds: string[] = yield select(getUnassignedIds);
      const shouldFetchAdditionalUnassignedChat =
        unassignedChatsNextPageId && unnasignedChatsIds.length < UNASSIGNED_CHATS_PAGE_SIZE;

      if (shouldFetchAdditionalUnassignedChat) {
        yield put(ChatsEntitiesActions.fetchAdditionalUnassignedChatsSummary({ limit: UNASSIGNED_CHATS_PAGE_SIZE }));
      }
    }

    if (response.error) {
      const { message, type, status } = normalizeError(response.error);
      yield put(ChatsEntitiesActions.assignChatFailure({ error: message }));

      if (type === AgentChatApiErrorType.NOT_FOUND) {
        yield put(
          ToastsActions.createToast({
            content: getToastContent(ToastContent.CANT_ASSIGN_CHAT_THREAD),
            kind: ToastVariant.Error,
          })
        );
      } else if (status === HTTPStatus.UnprocessableEntity) {
        const agentName = yield select(getThreadAgentName, threadId);

        yield put(
          ToastsActions.createToast({
            content: getToastContent(ToastContent.CHAT_ALREADY_PICKED, { agentName }),
            kind: ToastVariant.Warning,
          })
        );
      } else {
        yield put(
          ToastsActions.createToast({
            content: getToastContent(ToastContent.ASSIGN_CHAT_ERROR),
            kind: ToastVariant.Error,
          })
        );
      }
    }
  }
}

function* watchComposedChatsSummarySet(): SagaIterator {
  while (true) {
    const [baseSummaryAction, unassignedSummaryAction]: [
      IActionWithPayload<string, ISetChatsSummaryPayload>,
      IActionWithPayload<string, ISetUnassignedChatsSummaryPayload>
    ] = yield all([
      take(ChatsEntitiesActionNames.SET_CHATS_SUMMARY),
      take(ChatsEntitiesActionNames.SET_UNASSIGNED_CHATS_SUMMARY),
    ]);

    const {
      payload: { threads: baseThreads, events: baseEvents, threadTags },
    } = baseSummaryAction;

    const {
      payload: {
        threads: unassignedThreads,
        events: unassignedThreadsEvents,
        unassignedChatsNextPageId,
        unassignedChatsCount,
      },
    } = unassignedSummaryAction;

    /**
     * Merging base threads with current ones
     * In case of reconnects, we are receiving `login` WS event that means that we have to fetch updated threads ...
     * ... updated threads are empty as we were logged out but we want to show what happened to chats agent was chatting with ...
     * ... that's why we are merging events before reconnect with these from Agent API but with closed status so agent at least can see ...
     * ... if chat was closed or transferred to another agent
     */
    const currentlyAvailableMyChatThreads: ChatThreadEntity = yield select(getMyChats);
    const currentlyAvailableSupervisedThreads: ChatThreadEntity = yield select(getSupervisedChats);
    const currentlyAvailableThreads = {
      ...currentlyAvailableMyChatThreads,
      ...currentlyAvailableSupervisedThreads,
    };
    const currentlyAvailableEvents: KeyMap<KeyMap<ChatEventEntity>> = yield select(getEvents);

    const missingThreadsIds = difference(Object.keys(currentlyAvailableThreads), Object.keys(baseThreads));

    const missingThreadsWithEvents = missingThreadsIds.reduce(
      (acc, threadId) => {
        acc.threads[threadId] = {
          ...currentlyAvailableThreads[threadId],
          status: ChatThreadStatus.Closed,
        };

        acc.events[threadId] = currentlyAvailableEvents[threadId];

        return acc;
      },
      { threads: {}, events: {} }
    );

    const missingEvents = missingThreadsWithEvents.events;

    const unassignedThreadIds = Object.keys(unassignedThreads);

    const missingThreads = Object.keys(missingThreadsWithEvents.threads).reduce<KeyMap<ChatThreadEntity>>(
      (acc, threadId) => {
        const thread = missingThreadsWithEvents.threads[threadId];
        if (unassignedThreadIds.includes(threadId)) {
          return acc;
        }

        acc[threadId] = thread;

        return acc;
      },
      {}
    );
    /**
     * In case of the reconnect, we want to keep currently stored events of the selected chat.
     * It prevents showing less or no content (events) on the visible chat feed.
     */
    const selectedThreadId: string = yield select(getSelectedThreadId);
    const currentSelectedThreadEvents: KeyMap<ChatEventEntity> = yield select(getThreadEvents, selectedThreadId);
    const newSelectedThreadEvents = unassignedThreadsEvents[selectedThreadId];

    const threads: KeyMap<ChatThreadEntity> = {
      ...unassignedThreads,
      ...baseThreads,
      ...missingThreads,
    };

    yield put(
      ChatsEntitiesActions.setData({
        events: {
          ...unassignedThreadsEvents,
          ...baseEvents,
          ...missingEvents,
          [selectedThreadId]: { ...currentSelectedThreadEvents, ...newSelectedThreadEvents },
        },
        threads,
        unassignedChatsNextPageId,
        unassignedChatsCount,
        threadTags,
      })
    );

    yield put(TrafficActions.customerThreadStatusChange());
  }
}

function* triggerChatNotification(action: IActionWithPayload<string, ITriggerChatNotificationPayload>): SagaIterator {
  const { thread } = action.payload;
  const areNotificationsMuted: boolean = yield select(getAreNotificationsMuted);

  const soundNotificationOptions = {
    mute: areNotificationsMuted,
    repeat: false,
  };

  const browserNotificationsOptions = {
    id: thread.threadId,
    afterClick: {
      targetPage: createChatPath(thread.chatId, thread.threadId),
    },
  };

  if (thread.type === ChatType.Queued) {
    const isNotificationEnabled = yield select(getIsNotificationEnabled, Notification.QueuedVisitor);
    if (!isNotificationEnabled) {
      return;
    }

    yield put(
      SoundNotificationActions.playSound({ ...soundNotificationOptions, sound: SoundNotification.QueuedVisitor })
    );
    yield put(
      BrowserNotificationsActions.show({
        ...browserNotificationsOptions,
        tag: BrowserNotificationTag.QueuedVisitor,
        text: thread.customerName || DEFAULT_VISITOR_NAME,
        title: 'Queued visitor',
      })
    );
  }
}

function* triggerNewChatThreadNotification(
  action: IActionWithPayload<string, IIncomingChatThreadPayload>
): SagaIterator {
  const { thread, convertedFrom, shouldSkipNotification } = action.payload;

  if (shouldSkipNotification) {
    return;
  }

  const areNotificationsMuted: boolean = yield select(getAreNotificationsMuted);
  const soundNotificationOptions = {
    sound: SoundNotification.IncomingChat,
    mute: areNotificationsMuted,
    repeat: false,
  };

  const browserNotificationsOptions = {
    id: thread.threadId,
    title: 'Incoming chat',
    tag: BrowserNotificationTag.ChatEvent,
    afterClick: {
      targetPage: createChatPath(thread.chatId, thread.threadId),
    },
    text: thread.customerName || DEFAULT_VISITOR_NAME,
  };

  if (thread.type === ChatType.Unassigned && !convertedFrom) {
    const isNotificationEnabled = yield select(getIsNotificationEnabled, Notification.IncomingChat);
    if (!isNotificationEnabled) {
      return;
    }

    yield put(SoundNotificationActions.playSound(soundNotificationOptions));
    yield put(
      BrowserNotificationsActions.show({
        ...browserNotificationsOptions,
        title: 'Incoming unassigned chat',
      })
    );
  }

  if (thread.type === ChatType.My) {
    const isNotificationEnabled = yield select(getIsNotificationEnabled, Notification.IncomingChat);
    if (!isNotificationEnabled) {
      return;
    }

    /**
     * Clear chatId from muted chats hash map
     */
    if (chatsWithMutedNotifications[thread.chatId]) {
      delete chatsWithMutedNotifications[thread.chatId];

      return;
    }

    yield put(SoundNotificationActions.playSound(soundNotificationOptions));
    yield put(BrowserNotificationsActions.show(browserNotificationsOptions));

    /**
     * browser notification should be cancelled when the user read chat content (listener `shouldCancelNotifications`)
     * `shouldCancelNotifications` should be turned off if there was other `INCOMING_CHAT_THREAD` action (`shouldIgnoreMarkAsSeenAction`)
     */
    const { shouldCancelNotifications }: { shouldCancelNotifications: boolean } = yield race({
      shouldIgnoreMarkAsSeenAction: take(({ payload, type }) => {
        if (type === ChatsEntitiesActionNames.INCOMING_CHAT_THREAD && payload.thread.threadId === thread.threadId) {
          if (payload.thread.type === ChatType.My) {
            return true;
          }

          if (payload.thread.type === ChatType.Unassigned && !payload.convertedFrom) {
            return true;
          }
        }

        return false;
      }),
      shouldCancelNotifications: take(({ payload, type }) => {
        if (type === ChatsViewActionsNames.MARK_EVENTS_AS_SEEN && payload.threadId === thread.threadId) {
          return true;
        }

        return false;
      }),
    });

    if (shouldCancelNotifications) {
      yield put(BrowserNotificationsActions.cancel(thread.threadId));

      if (isDesktopClient() && isWindowsDesktopClient()) {
        yield put(BrowserNotificationsActions.cancelSome([BrowserNotificationTag.ChatEvent]));
      }
    }

    return;
  }

  if (thread.type === ChatType.Queued) {
    yield call(triggerChatNotification, ChatsViewActions.triggerChatNotification({ thread }));
  }
}

/**
 * Resolving `title` and `text` of notification
 * Each type of new event action has its own structure and a notification for each event looks different
 * This method returns standardized object for further operations
 */
function* getNewEventBody(action: IActionWithPayload<string, INewEventActionPayload>): SagaIterator {
  const { threadId } = action.payload;
  const thread: ChatThreadEntity | undefined = yield select(getThread, threadId);

  if (!thread) {
    return null;
  }

  if (isNewMessageAction(action)) {
    const { message } = action.payload;
    const currentAgentId = yield select(getLoggedInAgentLogin);

    if (message.authorId === currentAgentId || !message.text) {
      return null;
    }

    const authorName = yield select(getEventAuthorName, threadId, message.id);
    const text = getExtractedMessageText(message.text);
    const title = `${authorName || UNNAMED_AUTHOR} ${
      message.authorType === ChatEventAuthor.Supervisor ? 'whispers' : 'says'
    }...`;

    return {
      title,
      text,
    };
  }

  if (isNewSystemMessageAction(action)) {
    const { systemMessage } = action.payload;

    if (!systemMessage.text) {
      return null;
    }

    let text = getExtractedMessageText(systemMessage.text);
    let title;

    switch (systemMessage.subType) {
      // Visitor transfered due to inactivity
      case ChatEventSubType.TransferredDueToInactivity:
        title = 'Chat closed';
        text = 'Visitor has been transferred due to your inactivity.';
        break;
      // Agent closed chat
      case ChatEventSubType.ManuallyArchivedByAgent:
        if (!isSupervisedChat(thread)) {
          return null;
        }
        title = 'Chat closed';
        break;
      default:
        return null;
    }

    return {
      title,
      text,
    };
  }

  if (isNewAttachmentAction(action)) {
    const { file } = action.payload;
    const currentAgentId = yield select(getLoggedInAgentLogin);

    if (file.authorId === currentAgentId) {
      return null;
    }

    const authorName = yield select(getEventAuthorName, threadId, file.id);
    const title = `${authorName || UNNAMED_AUTHOR} sends...`;
    const text = 'New file';

    return {
      title,
      text,
    };
  }

  return null;
}

function* manageNewEventNotificationCancelation(
  action: IActionWithPayload<string, INewEventActionPayload>
): SagaIterator {
  const currentAgentId = yield select(getLoggedInAgentLogin);
  const { threadId } = action.payload;
  const thread: ChatThreadEntity = yield select(getThread, threadId);

  /**
   * browser notification should be cancelled when the user read chat content (listener `shouldCancelNotifications`)
   * `shouldCancelNotifications` should be turned off if there was other new event action (`shouldIgnoreMarkAsSeenAction`)
   */
  const { shouldCancelNotifications }: { shouldCancelNotifications: boolean } = yield race({
    shouldIgnoreMarkAsSeenAction: take((raceAction: IActionWithPayload<string, INewMessagePayload>) => {
      const { payload } = raceAction;
      if (!isNewEventNotificationTriggerAction(raceAction)) {
        return false;
      }

      if (payload.threadId !== threadId) {
        return false;
      }

      if (isNewMessageAction(raceAction) && payload.message.authorId === currentAgentId) {
        return false;
      }

      return true;
    }),
    shouldCancelNotifications: take(({ payload, type }) => {
      if (type === ChatsViewActionsNames.MARK_EVENTS_AS_SEEN && payload.threadId === thread.threadId) {
        return true;
      }

      return false;
    }),
  });

  if (shouldCancelNotifications) {
    yield put(BrowserNotificationsActions.cancel(thread.threadId));

    if (isDesktopClient() && isWindowsDesktopClient()) {
      yield put(BrowserNotificationsActions.cancelSome([BrowserNotificationTag.ChatEvent]));
    }
  }
}

function* triggerNewMessageNotification(
  action: IActionWithPayload<string, INewMessageNotificationPayload>
): SagaIterator {
  const { threadId } = action.payload;
  const selectedThreadId = yield select(getSelectedThreadId);
  const thread: ChatThreadEntity = yield select(getThread, threadId);
  const isOnChats = yield select(getIsOnSection, Section.Chats, false);
  const isThreadTypeSupported = thread.type === ChatType.My || thread.type === ChatType.Supervised;
  const isThreadSelectedAndOnChatsSection = selectedThreadId === threadId && isOnChats && document.hasFocus();
  const isSystemMsg = isSystemMessage(action.payload?.systemMessage);

  const shouldBlockNotification = !isThreadTypeSupported || isThreadSelectedAndOnChatsSection || isSystemMsg;

  if (!thread || shouldBlockNotification) {
    return;
  }

  const newEventBody: INewEventNotificationBody = yield call(getNewEventBody, action);

  if (!newEventBody) {
    return;
  }

  if (isThreadTypeSupported) {
    const { title, text } = newEventBody;

    const areNotificationsMuted: boolean = yield select(getAreNotificationsMuted);
    const areNotificationsSoundsRepeated: boolean = yield select(getAreNotificationsSoundsRepeated);
    const soundNotificationOptions = {
      sound: SoundNotification.Message,
      mute: areNotificationsMuted,
      repeat: areNotificationsSoundsRepeated,
    };

    yield put(SoundNotificationActions.playSound(soundNotificationOptions));

    yield put(
      BrowserNotificationsActions.show({
        id: threadId,
        title,
        text,
        tag: BrowserNotificationTag.ChatEvent,
        afterClick: {
          targetPage: createChatPath(thread.chatId, threadId),
        },
        onClose: () => AppStateProvider.dispatch(SoundNotificationActions.stopRepeatSound()),
      })
    );
  }

  yield call(manageNewEventNotificationCancelation, action);
}

function* watchNotificationTriggerActions(): SagaIterator {
  /**
   * Waiting for initialization of chats collection
   */
  yield all([
    take(ChatsEntitiesActionNames.SET_CHATS_SUMMARY),
    take(ChatsEntitiesActionNames.SET_UNASSIGNED_CHATS_SUMMARY),
  ]);

  yield takeEvery(ChatsEntitiesActionNames.INCOMING_CHAT_THREAD, triggerNewChatThreadNotification);
  yield takeEvery(ChatsViewActionsNames.TRIGGER_CHAT_NOTIFICATION, triggerChatNotification);

  yield takeEvery(
    [
      ChatsEntitiesActionNames.NEW_MESSAGE,
      ChatsEntitiesActionNames.NEW_SYSTEM_MESSAGE,
      ChatsEntitiesActionNames.NEW_ATTACHMENT,
    ],
    triggerNewMessageNotification
  );
}

function* startSupervising(action: IActionWithPayload<string, IStartSupervisingPayload>): SagaIterator {
  const { chatId } = action.payload;

  yield put(ChatsEntitiesActions.fetchChatThreadDetails({ chatId }));

  const {
    payload: { thread, events },
  } = yield take(({ payload, type }) => {
    if (type !== ChatsEntitiesActionNames.INCOMING_CHAT_THREAD) {
      return false;
    }

    return payload.thread.chatId === chatId && isSupervisedChat(payload.thread);
  });

  yield put(
    ChatsEntitiesActions.startSupervisingSuccess({
      thread,
      events,
    })
  );
}

function* updateChatThread(action: IActionWithPayload<string, IUpdateChatThreadPayload>): SagaIterator {
  const { threadId, thread } = action.payload;
  const { status, customProperties } = thread as ChatThreadEntity & IStartedThread;
  const { type } = thread;
  const chatId: string = yield select(getChatIdByThreadId, threadId);
  const threadType = yield select(getThreadType, threadId);
  const currentMessageBoxValue = yield select(getCurrentMessageBoxValue);
  const isAICannedAutocompleteVisible = yield select(getAICannedAutocompleteVisible);
  const isMessageBoxEmpty = currentMessageBoxValue?.length === 0;

  if (status && status === ChatThreadStatus.Closed) {
    yield put(
      ChatsEntitiesActions.setSneakPeek({
        threadId,
        text: null,
      })
    );
  }

  const selectedChatId = yield select(getSelectedThreadChatId);
  if (selectedChatId === chatId && type === ChatType.Queued) {
    chatFollowManager.followChat(chatId);
  }

  if (
    customProperties?.[CRS_NAMESPACE] &&
    threadType === ChatType.My &&
    isMessageBoxEmpty &&
    isAICannedAutocompleteVisible
  ) {
    trackEvent(AutocompleteAmplitudeEvent.AIAutocompleteCannedDisplayed, EventPlace.Chats);
    void getSprigService().initSprigEvent(`${SprigEvents.AICannedDisplayed}`);
  }

  yield put(TrafficActions.customerThreadStatusChange());
}

function* incomingChatThread(action: IActionWithPayload<string, IIncomingChatThreadPayload>): SagaIterator {
  const { thread, shouldSkipNotification } = action.payload;

  if (!shouldSkipNotification) {
    yield put(ChatsViewActions.markChatAsNew({ threadId: thread.threadId }));
  }
}

function* assignChatToOtherAgent(action: IActionWithPayload<string, IAssignChatToOtherAgentPayload>): SagaIterator {
  const { shouldLoadAdditionalUnassignedChat, threadId } = action.payload;

  if (shouldLoadAdditionalUnassignedChat) {
    yield put(ChatsEntitiesActions.fetchAdditionalUnassignedChatsSummary({ limit: UNASSIGNED_CHATS_PAGE_SIZE }));
  } else {
    yield put(
      ChatsEntitiesActions.updateUnassignedChatsCount({
        threadId,
        type: 'decrease',
      })
    );
  }
}

/**
 * Fetches chat threads history for LC3 protocol based on chat thread summary.
 * Scenarios to handle:
 * 1. History already fetched, no more to fetch.
 * 2. No history fetched yet.
 * * No history available to fetch.
 * * History is available, first batch of events to fetch.
 * 3. Next page provided to fetch chat thread history.
 * * No threads to fetch on next page.
 * * Fetch first batch of threads from next chat thread history page.
 * 4. There are threads to fetch in the area of current chat threads summary page.
 * 5. There are no threads to fetch in area of current chat threads summary page.
 */
function* fetchLC3ChatHistory(threadId: string): SagaIterator {
  const thread: ChatThreadEntity = yield select(getThread, threadId);

  if (!thread) {
    yield put(ChatsEntitiesActions.fetchChatHistoryCompleted({ threadId }));

    return;
  }

  const { chatId } = thread;
  const chatHistorySummary: IChatHistorySummary = yield select(getChatHistorySummary, threadId);

  if (chatHistorySummary && !chatHistorySummary.hasMoreHistory) {
    yield put(ChatsEntitiesActions.fetchChatHistoryCompleted({ threadId, shouldStopFetching: true }));

    return;
  }

  let nextPageId;
  let skipThreadId;
  let minEventsCount = 30;

  if (chatHistorySummary) {
    nextPageId = chatHistorySummary.nextPageId;
  } else {
    const events = yield select(getThreadEvents, threadId);
    const eventsLength = Object.keys(events).length || 0;

    skipThreadId = threadId;
    minEventsCount += eventsLength;
  }

  const result = yield call(fetchChatHistoryThreads, chatId, nextPageId, minEventsCount, skipThreadId);

  if (result) {
    const { threads, events, tags, totalThreadsCount, nextPageId: newNextPageId, isHistoryLimitReached } = result;
    const hasMoreHistory = !!newNextPageId;
    const newThreadsOrder = Object.keys(threads).reverse();

    yield put(
      ChatsEntitiesActions.fetchChatHistoryCompleted({
        threadId,
        summaryBasedData: {
          threads,
          threadsOrder: newThreadsOrder,
          events,
          tags,
          nextPageId: newNextPageId,
          totalThreadsCount,
          hasMoreHistory,
        },
        isHistoryLimitReached,
      })
    );
  }
}

/**
 * Fetches history for given thread. Fetching scenario depends on protocol version and amount of history data already fetched.
 */
function* fetchChatHistory(action: IActionWithPayload<string, IFetchChatHistoryPayload>): SagaIterator {
  const { threadId } = action.payload;

  yield call(fetchLC3ChatHistory, threadId);
}

function* setChatsData(): SagaIterator {
  const myChatsIds: string[] = yield select(getMyChatsIds);
  const supervisedChatsIds: string[] = yield select(getSupervisedIds);
  const selectedThreadId: string = yield select(getSelectedThreadId);
  const allThreadsIds = [...myChatsIds, ...supervisedChatsIds];
  const allClosedThreadIds = yield select(getClosedThreadIds);
  const shouldPrefetchSelectedThread =
    selectedThreadId && !allClosedThreadIds.includes(selectedThreadId) && allThreadsIds.includes(selectedThreadId);

  let activeThreadIdsWithoutSelectedThread = shouldPrefetchSelectedThread
    ? allThreadsIds.filter((id) => id !== selectedThreadId)
    : allThreadsIds;

  activeThreadIdsWithoutSelectedThread = activeThreadIdsWithoutSelectedThread.filter(
    (threadId) => !allClosedThreadIds.includes(threadId)
  );

  /**
   * Selected threads prefetch
   * Separate request for events of selected chat thread in order to load it faster for user.
   */
  if (shouldPrefetchSelectedThread) {
    const threadData: IDeserializeChatThreadDetails = yield call(fetchChatThreadData, selectedThreadId);

    if (threadData?.events) {
      yield putResolve(ChatsEntitiesActions.setThreadsEvents({ events: threadData?.events }));
    }
  }

  const threadsData: IDeserializeChatThreadDetails[] = yield all(
    activeThreadIdsWithoutSelectedThread.map((threadId) => call(fetchChatThreadData, threadId))
  );

  const eventsToUpdate = threadsData.reduce((acc, threadsData): KeyMap<KeyMap<ChatEventEntity>> => {
    if (threadsData.events) {
      Object.assign(acc, threadsData.events);
    }

    return acc;
  }, {});

  // check if the array has some data to update in store
  if (!isEmpty(eventsToUpdate)) {
    yield put(ChatsEntitiesActions.setThreadsEvents({ events: eventsToUpdate }));
  }

  yield put(ChatsEntitiesActions.finalizeChatDataLoading());
}

export function* unpinChat(action: IActionWithPayload<string, IUnpinChatPayload>): SagaIterator {
  const { threadId } = action.payload;
  const thread: ChatThreadEntity = yield select(getThread, threadId);
  const isActiveUnassigned = isActiveUnassignedChat(thread);

  if (!isActiveUnassigned) {
    yield call(unpin, thread.chatId);
  } else {
    yield call(deactivateChat, thread.chatId, true);
  }
}

function* fetchChatThreadData(threadId: string): SagaIterator<IDeserializeChatThreadDetails> {
  const chatId: string = yield select(getChatIdByThreadId, threadId);
  const { result }: AgentChatApiResponse<GetChatResponse> = yield call(doFetchChatThreadDetails, chatId);

  if (!result) {
    return;
  }

  const canUseTags = yield select(getCanUseTags);

  if (canUseTags && result.thread.tags) {
    yield put(ChatsEntitiesActions.updateChatTags({ threadId, tags: result.thread.tags }));
  }

  const currentAgentId: string = yield select(getLoggedInAgentLogin);
  const users: IChatUser[] = yield select(getChatUsers, chatId);
  // HTTP and RTM responses have the same structure but use different types
  const chat = result as never as IChatResult;

  const deserializedThread = deserializeThread(chat, currentAgentId);

  if (chat.thread.active) {
    const deserializedEvents = deserializeThreadEventCollection(
      chat.thread.events,
      users,
      currentAgentId,
      getWasEventSeen,
      threadId
    );

    yield put(
      ChatsEntitiesActions.updateChat({
        thread: deserializedThread,
        events: deserializedEvents,
      })
    );
  }

  const threadData = deserializeChatsThreadsDetails([chat], currentAgentId);
  const mayBeIncomplete = isUnassignedChat(deserializedThread) || isQueuedChat(deserializedThread);

  if (
    mayBeIncomplete &&
    (chatFollowManager.isChatFollowed(deserializedThread.chatId) || isClosedThread(deserializedThread))
  ) {
    yield put(
      ChatsEntitiesActions.updateIncompleteThreadEvents({
        threadId,
        events: threadData.events[threadId],
      })
    );
  }

  return threadData;
}

function* fetchChatData(action: IActionWithPayload<string, IFetchChatDataPayload>): SagaIterator {
  const { threadId } = action.payload;
  yield call(fetchChatThreadData, threadId);
}

export function* doFetchChatThreadDetails(
  chatId: string,
  threadId?: string
): SagaIterator<AgentChatApiResponse<GetChatResponse>> {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  return yield call(chatsClient.getChat, { chat_id: chatId, thread_id: threadId });
}

export function* fetchChatThreadDetails(
  action: IActionWithPayload<string, IFetchChatThreadsDetailsPayload>
): SagaIterator {
  const { payload } = action;

  const { result, error }: AgentChatApiResponse<GetChatResponse> = yield call(doFetchChatThreadDetails, payload.chatId);
  if (result) {
    // HTTP and RTM responses have the same structure but use different types
    const chat = result as never as IChatResult;
    yield put(
      ChatsEntitiesActions.fetchChatThreadDetailsSuccess({
        chat,
      })
    );
  } else {
    const isChatInaccessible = error.status === HTTPStatus.Forbidden;
    yield put(ChatsEntitiesActions.fetchChatThreadDetailsFailure({ chatId: payload.chatId, isChatInaccessible }));
  }

  return { result, error };
}

function* fetchChatThreadDetailsSuccess(
  action: IActionWithPayload<string, IFetchChatThreadsDetailsSuccessPayload>
): SagaIterator {
  const { chat } = action.payload;
  if (chat.thread.active) {
    yield call(handleIncomingChat, action.payload as IIncomingChatPushEvent, AppStateProvider);
  }
}

function* fetchIncompleteThreadEvents(
  action: IActionWithPayload<string, IFetchIncompleteThreadEventsPayload>
): SagaIterator {
  const { threadId, chatId } = action.payload;

  const threadData: IDeserializeChatThreadDetails = yield call(fetchChatThreadData, threadId);

  if (threadData?.events) {
    yield put(
      ChatsEntitiesActions.updateIncompleteThreadEvents({
        threadId,
        events: threadData.events[threadId],
      })
    );
  } else {
    yield put(
      ToastsActions.createToast({
        content: getToastContent(ToastContent.INCOMPLETE_THREAD_FETCH_ERROR),
        action: {
          label: 'Retry',
          onClick: (): void => {
            setTimeout((): void => {
              AppStateProvider.dispatch(ChatsEntitiesActions.fetchIncompleteThreadEvents({ chatId, threadId }));
              trackEvent('Retry loading chat button clicked', EventPlace.Chats);
            }, ActionHandlerExecutionDelay.Short);
          },
          closeOnClick: true,
        },
        autoHideDelayTime: ToastAutoHideDelay.Long,
        kind: ToastVariant.Error,
      })
    );
  }
}

export function* updateChatsListOrder(
  type: ChatType,
  comparatorFnc: (a: IChatListItem, b: IChatListItem, reversePriority?: boolean) => number
): SagaIterator {
  const allChats: KeyMap<ChatThreadEntity> = yield select(getAllChats);
  let currentIds = [];
  const sortType = yield select(getChatsSortType, type);
  const isMyChats = type === ChatType.My;
  const shouldReverseMyChats = isMyChats && sortType === SortOrder.Desc;

  switch (type) {
    case ChatType.My:
      currentIds = yield select(getMyChatsIds);
      break;
    case ChatType.Queued:
      currentIds = yield select(getQueuedIds);
      break;
    case ChatType.Supervised:
      currentIds = yield select(getSupervisedIds);
      break;
    case ChatType.Unassigned:
      currentIds = yield select(getUnassignedIds);
      break;
  }

  const sortedIds = currentIds.slice().sort((idA, idB) => {
    const itemA = allChats[idA];
    const itemB = allChats[idB];

    return comparatorFnc(itemA, itemB, shouldReverseMyChats);
  });

  if (sortType === SortOrder.Desc) {
    sortedIds.reverse();
  }

  if (!isEqual(currentIds, sortedIds)) {
    yield put(ChatsEntitiesActions.sortChatList({ type, ids: sortedIds }));
  }
}

export const MAPPED_CHAT_TYPE_TO_COMPARATOR = {
  [ChatType.My]: compareStartedChatListItems,
  [ChatType.Queued]: compareQueuedChatListItems,
  [ChatType.Supervised]: compareSupervisedChatListItems,
  [ChatType.Unassigned]: compareUnassignedListItems,
};

function* updateChatList(args: ChatType[]): SagaIterator {
  yield all(args.map((type) => call(updateChatsListOrder, type, MAPPED_CHAT_TYPE_TO_COMPARATOR[type])));
}

function* setSentimentToNewMessage({ payload }: IActionWithPayload<string, INewMessagePayload>): SagaIterator {
  const isSentimentEnabled = yield select(getIsChatSentimentEnabled);

  if (!isSentimentEnabled) {
    return;
  }

  const { threadId, message } = payload;

  if (message.authorType !== ChatEventAuthor.Customer) {
    return;
  }

  const previousSentiment = yield select(getCustomerMessageLastSentiment, threadId);

  if (previousSentiment) {
    yield put(
      ChatsEntitiesActions.updateEvent({
        threadId,
        eventId: message.id,
        event: {
          properties: {
            [CHAT_SENTIMENT_NAMESPACE]: {
              sentimentScore: previousSentiment,
            },
          },
        },
      })
    );
  }

  const { shouldShowError }: { shouldShowError: unknown } = yield race({
    shouldShowError: delay(CHATS_SENTIMENT_THROW_ERROR_TIME),
    shouldIgnore: take(ChatsEntitiesActionNames.SET_SENTIMENT_SUCCESS),
  });

  if (shouldShowError) {
    yield put(
      ChatsEntitiesActions.updateEvent({
        threadId,
        eventId: message.id,
        event: {
          properties: {
            [CHAT_SENTIMENT_NAMESPACE]: {
              sentimentError: true,
            },
          },
        },
      })
    );
  }
}

function* addReaction(action: IActionWithPayload<string, IAddReactionPayload>): SagaIterator {
  const { threadId, eventId, event } = action.payload;
  const chatId: string = yield select(getChatIdByThreadId, threadId);
  const messageReaction = event[CLIENT_ID].messageReaction;
  const messageReactionAuthor = event[CLIENT_ID].messageReactionAuthor;

  const properties = {
    [CLIENT_ID]: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      message_reaction: messageReaction,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      message_reaction_author: messageReactionAuthor,
    },
  };

  const { success }: IncomingResponseMessage<void> = yield call(
    updateEventProperties,
    chatId,
    threadId,
    eventId,
    properties
  );
  if (success) {
    const reactionEvent =
      NO_REACTION === messageReaction ? ChatEvent.ChatsMessageReactionRemoved : ChatEvent.ChatsMessageReactionUsed;
    yield put(ChatsEntitiesActions.addReactionSuccess());
    trackEvent(reactionEvent, EventPlace.Chats, { emoji: messageReaction });
  } else {
    yield put(ChatsEntitiesActions.addReactionFailure());
  }
}

export function* chatEntitiesSaga(): SagaIterator {
  yield takeEvery(CHATS.FETCH_CHAT_THREAD_DETAILS[RequestAction.REQUEST], fetchChatThreadDetails);
  yield takeEvery(CHATS.FETCH_CHAT_THREAD_DETAILS[RequestAction.SUCCESS], fetchChatThreadDetailsSuccess);
  yield takeEvery(ChatsEntitiesActionNames.FETCH_ADDITIONAL_UNASSIGNED_CHATS_SUMMARY, fetchAdditionalUnassignedChats);
  yield takeEvery(ChatsViewActionsNames.LOAD_MORE_UNASSIGNED_CHATS, fetchMoreUnassignedChats);
  yield takeEvery(CHATS.FETCH_UNASSIGNED_CHATS_SUMMARY[RequestAction.REQUEST], fetchUnassignedChats);
  yield takeEvery(CHATS.FETCH_INCOMPLETE_THREAD_EVENTS[RequestAction.REQUEST], fetchIncompleteThreadEvents);
  yield takeEvery(ChatsEntitiesActionNames.PICK_FROM_QUEUE, pickFromQueue);
  yield takeEvery(ChatsEntitiesActionNames.START_CHAT, startChat);
  yield takeEvery(ChatsEntitiesActionNames.SUPERVISE_CHAT, superviseChat);
  yield takeEvery(ChatsEntitiesActionNames.ASSIGN_CHAT, assignChat);
  yield takeEvery(ChatsEntitiesActionNames.ADD_REACTION_REQUEST, addReaction);
  yield takeEvery(ChatsEntitiesActionNames.STOP_SUPERVISING, stopSupervising);
  yield takeEvery(ChatsEntitiesActionNames.SET_TAG_REQUEST, setChatTag);
  yield takeEvery(ChatsEntitiesActionNames.UNSET_TAG_REQUEST, unsetChatTagRequest);
  yield takeEvery(ChatsEntitiesActionNames.START_SUPERVISING, startSupervising);
  yield takeEvery(ChatsEntitiesActionNames.UPDATE_CHAT_THREAD, updateChatThread);
  yield takeEvery(ChatsEntitiesActionNames.INCOMING_CHAT_THREAD, incomingChatThread);
  yield takeEvery(ChatsEntitiesActionNames.ASSIGN_CHAT_TO_OTHER_AGENT, assignChatToOtherAgent);
  yield takeEvery(ChatsEntitiesActionNames.FETCH_CHAT_HISTORY, fetchChatHistory);
  yield takeEvery(ChatsEntitiesActionNames.SET_DATA, setChatsData);
  yield takeEvery(ChatsEntitiesActionNames.UNPIN_CHAT, unpinChat);
  yield takeEvery(ChatsEntitiesActionNames.FETCH_CHAT_DATA, fetchChatData);
  yield takeEvery(ChatsEntitiesActionNames.NEW_MESSAGE, setSentimentToNewMessage);
  yield fork(watchComposedChatsSummarySet);
  yield fork(watchNotificationTriggerActions);

  // moved from throttled actions as experiment to see if it will mitigate errors found in Sentry
  // Example: https://livechat.sentry.io/issues/4725070021/?project=1274943&query=is%3Aunresolved&referrer=issue-stream&statsPeriod=24h&stream_index=4
  yield takeEvery(ChatsEntitiesActionNames.UPDATE_CHAT_THREAD, updateChatList, [
    ChatType.Queued,
    ChatType.My,
    ChatType.Supervised,
    ChatType.Unassigned,
  ]);

  yield throttle(
    QUEUED_LIST_UPDATE_THROTTLE_TIME,
    [
      ChatsEntitiesActionNames.SET_DATA,
      ChatsEntitiesActionNames.INCOMING_CHAT_THREAD,
      ChatsEntitiesActionNames.UPDATE_CHAT,
      ChatsEntitiesActionNames.REMOVE_CHAT_THREAD,
      ChatsEntitiesActionNames.ASSIGN_CHAT,
      ChatsEntitiesActionNames.UPDATE_QUEUE_POSITIONS,
    ],
    updateChatList,
    [ChatType.Queued]
  );

  yield throttle(
    CHATS_LIST_UPDATE_THROTTLE_TIME,
    [
      ChatsEntitiesActionNames.SET_DATA,
      ChatsEntitiesActionNames.INCOMING_CHAT_THREAD,
      ChatsEntitiesActionNames.UPDATE_CHAT,
      ChatsEntitiesActionNames.REMOVE_CHAT_THREAD,
      ChatsEntitiesActionNames.ASSIGN_CHAT,
      ChatsEntitiesActionNames.START_SUPERVISING,
      ChatsEntitiesActionNames.STOP_SUPERVISING,
      ChatsEntitiesActionNames.UNPIN_CHAT,
      ChatsEntitiesActionNames.UPDATE_QUEUE_POSITIONS,
      ChatsEntitiesActionNames.FETCH_ADDITIONAL_UNASSIGNED_CHATS_SUMMARY_SUCCESS,
      ChatsEntitiesActionNames.FETCH_MORE_UNASSIGNED_CHATS_SUMMARY_SUCCESS,
    ],
    updateChatList,
    [ChatType.My, ChatType.Supervised, ChatType.Unassigned]
  );
}
