import { GlobalModal } from 'constants/global-modal';

import { ITourStep, TourStepType } from '../interfaces';

import { RulesStep, TableHeaderStep, PriorityStep, FallbackGroupStep } from './tour-steps';

export enum RoutingRulesTourStep {
  Modal = 'Modal',
  Rules = 'Rules',
  TableHeader = 'TableHeader',
  Priority = 'Priority',
  FallbackGroup = 'FallbackGroup',
}

export const routingRulesSteps: ITourStep[] = [
  {
    id: RoutingRulesTourStep.Modal,
    type: TourStepType.Modal,
    modal: GlobalModal.RoutingRulesTourInvitation,
  },
  {
    id: RoutingRulesTourStep.Rules,
    type: TourStepType.GuideTooltip,
    tooltip: {
      content: <RulesStep />,
      placement: 'bottom',
    },
  },
  {
    id: RoutingRulesTourStep.TableHeader,
    type: TourStepType.GuideTooltip,
    tooltip: {
      content: <TableHeaderStep />,
      placement: 'bottom',
    },
  },
  {
    id: RoutingRulesTourStep.Priority,
    type: TourStepType.GuideTooltip,
    tooltip: {
      content: <PriorityStep />,
      placement: 'bottom',
    },
  },
  {
    id: RoutingRulesTourStep.FallbackGroup,
    type: TourStepType.GuideTooltip,
    tooltip: {
      content: <FallbackGroupStep />,
      placement: 'left',
    },
  },
];
