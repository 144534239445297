import { type UseMutateFunction, useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { PostMessageEvent } from 'constants/post-message-event';
import { ToastContent } from 'constants/toasts';
import { sendPostMessageToMarketplace } from 'helpers/post-message';
import { showToast } from 'helpers/toast';
import type { RequestResult } from 'interfaces/api/client';
import { ApiManager } from 'services/api/api-manager';
import type { RequestError } from 'services/api/types';
import { ApplicationsActions } from 'store/entities/applications/actions';
import { ToastVariant } from 'store/features/toasts/interfaces';

type Variables = { chargeId: string; itemId?: string };

interface UseAcceptRecurrentCharge {
  acceptRecurrentCharge: UseMutateFunction<void, string, Variables, unknown>;
  isProcessing: boolean;
  error: string | null;
}

async function acceptAndActivateRecurrentCharge({ chargeId }: Variables): Promise<void> {
  const { error: acceptChargeError }: RequestResult<unknown, RequestError> =
    await ApiManager.billingApi.acceptRecurring(chargeId);
  const { error: activateChargeError }: RequestResult<unknown, RequestError> =
    await ApiManager.billingApi.activateRecurring(chargeId);

  if (acceptChargeError || activateChargeError) {
    throw acceptChargeError || activateChargeError;
  }
}

export const useAcceptRecurrentCharge = (): UseAcceptRecurrentCharge => {
  const dispatch = useDispatch();

  const { mutate, isLoading, error } = useMutation<void, string, Variables, unknown>(acceptAndActivateRecurrentCharge, {
    onSuccess: (_, { itemId }) => {
      sendPostMessageToMarketplace(PostMessageEvent.PaymentSuccess);
      showToast(ToastVariant.Success, ToastContent.ONE_CLICK_PAYMENT_SUCCESS);

      if (itemId) {
        dispatch(
          ApplicationsActions.refreshInstalledApplicationsData({ notifyMarketplace: true, installedAppId: itemId })
        );
      }
    },
    onError: (error) => {
      sendPostMessageToMarketplace(PostMessageEvent.PaymentFailure, { error: error.toString() });
    },
  });

  return {
    acceptRecurrentCharge: mutate,
    isProcessing: isLoading,
    error,
  };
};
