import { QueryKey } from 'constants/query-key';
import { shouldShowKnowledgeSourcesLimitAlert } from 'helpers/should-show-knowledge-sources-limit-alert';
import { shouldShowReplySuggestionsPromo } from 'helpers/should-show-reply-suggestions-promo';
import type { IKnowledgeSourceUrlItem } from 'interfaces/knowledge-sources';
import { AppStateProvider } from 'services/app-state-provider';
import { getQueryClient } from 'services/query-client/client';
import {
  getIsReplySuggestionsLimitsAlertSeen,
  getKnowledgeSourcesListSeen,
} from 'store/features/agent-custom-properties/selectors';
import { getIsCodeInstalled } from 'store/features/code-installation/selectors';
import { getCanUseReplySuggestionsFeature } from 'store/views/chats/selectors';

const shouldShowKnowledgeSourcesAlert = (): boolean => {
  const data = getQueryClient()?.getQueryData<IKnowledgeSourceUrlItem[]>([QueryKey.KnowledgeSourcesList]);

  return shouldShowKnowledgeSourcesLimitAlert(data);
};

export const shouldShowSettingsBadge = (): boolean => {
  if (!AppStateProvider.selectFromStore(getIsCodeInstalled)) {
    return true;
  }

  if (!AppStateProvider.selectFromStore(getCanUseReplySuggestionsFeature)) {
    return false;
  }

  if (!AppStateProvider.selectFromStore(getKnowledgeSourcesListSeen) && shouldShowReplySuggestionsPromo()) {
    return true;
  }

  if (!AppStateProvider.selectFromStore(getIsReplySuggestionsLimitsAlertSeen) && shouldShowKnowledgeSourcesAlert()) {
    return true;
  }

  return false;
};
