import { MenuOption } from '@lexical/react/LexicalTypeaheadMenuPlugin';

export class OnePickerOption extends MenuOption {
  title: string;
  description: string;
  icon?: JSX.Element;

  onSelect: () => void;

  constructor(
    title: string,
    options: {
      description: string;
      icon?: JSX.Element;
      onSelect: () => void;
    }
  ) {
    super(title);
    this.title = title;
    this.description = options.description;
    this.icon = options.icon;
    this.onSelect = options.onSelect.bind(this);
  }
}
