import type { FC } from 'react';

import { cx } from '@emotion/css';
import { Button } from '@livechat/design-system-react-components';
import { useDispatch } from 'react-redux';

import * as styles from 'components/notifications-bar/styles';
import { GlobalModal } from 'constants/global-modal';
import { InviteAgentsSource } from 'constants/invite-agents-source';
import { GlobalModalActions } from 'store/features/global-modals/actions';

export const SendPendingInvitesBar: FC = () => {
  const dispatch = useDispatch();

  const handleCompleteInvites = (): void => {
    dispatch(
      GlobalModalActions.showModal(GlobalModal.InviteAgents, { source: InviteAgentsSource.PendingInvitesTopBar })
    );
  };

  return (
    <div className={cx(styles.contentContainer, styles.containerWarning)}>
      <div>Some agents can’t start chatting. Finish inviting your team.</div>
      <Button tabIndex={-1} className={styles.blackButton} onClick={handleCompleteInvites} kind="high-contrast">
        Complete invites
      </Button>
    </div>
  );
};
