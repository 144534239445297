export enum QueryKey {
  AnalyzeSource = 'analyze-source',
  ArchivesSearch = 'archives-search',
  BannedCustomers = 'banned-customers',
  BotRoutingStatusChange = 'bot-routing-status-change',
  Campaign = 'campaign',
  ChatButtons = 'chat-buttons',
  ChatSummary = 'chat-summary',
  ChatSummaryMutate = 'chat-summary-mutate',
  ContactInfo = 'contact-info',
  DeveloperProfile = 'developer-profile',
  DownloadInvoice = 'download-invoice',
  EyeCatcher = 'eye-catcher',
  EyeCatcherGallery = 'eye-catcher-gallery',
  Features = 'features',
  FlashScreenshot = 'flash-screenshot',
  Goals = 'goals',
  GoalsReports = 'goals-reports',
  GoalsStats = 'goals-stats',
  Gravatar = 'gravatar',
  GroupDetails = 'group-details',
  GroupProperties = 'group-properties',
  HelpDeskFormConfiguration = 'helpdesk-form-configuration',
  HelpDeskSubscription = 'helpdesk-subscription',
  InAppUpgrade = 'in-app-upgrade',
  InactivityReasons = 'inactivity-reasons',
  Invoices = 'invoices',
  KnowledgeBaseSubscription = 'knowledgebase-subscription',
  KnowledgeSourcesList = 'knowledge-sources-list',
  LegacyIntegration = 'legacy-integration',
  LicenseProperties = 'license-properties',
  LivechatTickets = 'livechat-tickets',
  LocationDetails = 'location-details',
  LoginMethods = 'login-methods',
  LogoutAgent = 'logout-agent',
  MyInactivity = 'my-inactivity',
  OnboardingProgress = 'onboarding-progress',
  OneSuggestions = 'one-suggestions',
  PerformanceStats = 'performance-stats',
  PostChatSurvey = 'post-chat-survey',
  PreChatSurvey = 'pre-chat-survey',
  PricePreview = 'price-preview',
  ResendInvitation = 'resend-invitation',
  Reviews = 'reviews',
  SalesTracker = 'sales-tracker',
  Sessions = 'sessions',
  SubscriptionPreview = 'subscription-preview',
  Tags = 'tags',
  Webhooks = 'webhooks',
  MyProducts = 'my-products',
  ActivatedSubscriptions = 'activated-subscriptions',
}
