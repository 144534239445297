import type { FC } from 'react';

import { useSelector } from 'react-redux';

import { getQueuedIds } from 'store/entities/chats/selectors';

import { NavigationBadge } from './navigation-badge/NavigationBadge';

export const EngageBadge: FC = () => {
  const queuedChatsCount = useSelector(getQueuedIds).length;

  return queuedChatsCount > 0 ? <NavigationBadge count={queuedChatsCount} /> : null;
};
