import { type IInboxMessage, type INormalizedInboxMessage } from './inbox-message';
import { type IInboxMessageDenormalizer } from './inbox-message-denormalizer';
import { InboxMessageTitles } from './inbox-message-titles';

interface IPluginInitedMessage extends IInboxMessage {
  readonly title: InboxMessageTitles.PluginInited;
}

function createPluginInitedMessage(pluginId: string): IPluginInitedMessage {
  return { title: InboxMessageTitles.PluginInited, pluginId };
}

export const PluginInitedMessageDenormalizer: IInboxMessageDenormalizer<IPluginInitedMessage> = {
  createMessage: (message: INormalizedInboxMessage) => createPluginInitedMessage(message.plugin_id),
  messageTitle: InboxMessageTitles.PluginInited,
};
