// @ts-strict-ignore
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { getTime } from 'date-fns';

import { ChatEventStatus } from 'constants/chat-event-status';
import { ChatEventType } from 'constants/chat-event-type';
import RichMessageTemplate from 'constants/rich-message-template';
import { UserType } from 'constants/user-type';
import { parseSurveyFieldValue, mergeSurveyResponses } from 'helpers/filled-form';
import { JSONParse } from 'helpers/json';
import { isRichMessageJSON } from 'helpers/rich-message';
import { replaceSystemMessageText } from 'helpers/system-messages';
import { decodeQueryString } from 'helpers/url';
import type { RichMessageDetailsResult } from 'interfaces/api/event/rich-message';
import { type ISurveyResponse } from 'interfaces/entities/archive';
import { AppStateProvider } from 'services/app-state-provider';
import { standardizeChatRatingEvent } from 'services/serialization/chat-rating-event';
import { getPropertyValue } from 'services/serialization/property';
import { deserializeRichMessageDetails } from 'services/serialization/rich-message';
import { getThreadEntityTimestampInMs } from 'services/serialization/timestamp';
import {
  type ChatEventAuthor,
  type IMessage,
  type ISystemMessage,
  type IRichMessage,
  type ISurveyMessage,
  type IAttachmentMessage,
} from 'store/entities/chats/interfaces';
import { getCustomer } from 'store/entities/customers/selectors';
import { getAgentOrBot } from 'store/views/team/computed';

export function convertChatUserType(userType: UserType): ChatEventAuthor {
  if (userType === UserType.Customer || userType === UserType.Visitor) {
    return UserType.Customer;
  }

  if (userType === UserType.Agent) {
    return UserType.Agent;
  }

  if (userType === UserType.Supervisor) {
    return UserType.Supervisor;
  }

  return null;
}

export function getPrechatResponsesFromQueryStrings(questions = '', answers = ''): ISurveyResponse[] {
  const decodedQuestions = decodeQueryString(questions);
  const decodedAnswers = decodeQueryString(answers);

  if (decodedQuestions && decodedAnswers) {
    const prechatSurveyResponses = Object.keys(decodedQuestions).reduce(
      (acc: ISurveyResponse[], questionKey: string) => {
        const decodedQuestion = decodedQuestions[questionKey];
        const decodedAnswer = (decodedAnswers[questionKey] || '').split(/\n/g).join(', ');
        const decodedType = decodedQuestion[questionKey]?.type;

        if (decodedQuestion) {
          acc.push({
            id: questionKey,
            answer: decodedAnswer,
            question: decodedQuestion,
            type: decodedType,
          });
        }

        return acc;
      },
      []
    );

    return prechatSurveyResponses;
  }

  return null;
}

export function getAuthorId(data: any, type: ChatEventAuthor, customerId: string): string {
  const agentId = data.login ? data.login : data.agent_id;
  const authorIds = {
    [UserType.Agent]: agentId,
    [UserType.Supervisor]: agentId,
    [UserType.Customer]: customerId,
  };

  return authorIds[type];
}

export function getJSONData(json): any {
  return json && typeof json === 'string' ? JSONParse(json) : null;
}

export function getRichMessageData(messageJSON: any): {
  richMessageTemplate?: RichMessageTemplate;
  richMessageDetails?: RichMessageDetailsResult[];
} {
  if (!messageJSON || !messageJSON.payload) {
    return {};
  }
  const richMessageTemplate =
    messageJSON.payload.template_id === 'quick_replies' ? RichMessageTemplate.QuickReplies : RichMessageTemplate.Cards;

  return {
    richMessageTemplate,
    richMessageDetails: messageJSON.payload.elements && deserializeRichMessageDetails(messageJSON.payload.elements),
  };
}

export function deserializeHistoryAttachment(data: any): IAttachmentMessage {
  const { agent_id: agentId, user_type: userType, details } = data;
  const {
    content_type: contentType,
    size,
    url,
    width,
    height,
    safety_confirmation: safetyConfirmation,
    name,
  } = details[0];

  const authorId = agentId || userType;
  const authorType = userType === UserType.Visitor ? UserType.Customer : userType;
  const timestampInMs = getThreadEntityTimestampInMs(data) || getTime(new Date());
  const id = `${timestampInMs}-attachment`;

  return {
    name,
    authorId,
    authorType,
    status: ChatEventStatus.Delivered,
    wasSeen: false, // we are not supporting LC2 anymore so it's just mocked value
    contentType,
    safetyConfirmation,
    size,
    url,
    width,
    height,
    id,
    timestampInMs,
    type: ChatEventType.Attachmment,
  };
}

export function deserializeAttachment(data: any): IAttachmentMessage {
  const { author, files, attachment_token: attachmentToken } = data;
  const {
    'content-type': contentType,
    size,
    url,
    width,
    height,
    'safety-confirmation': safetyConfirmation,
    name,
  } = files[0];

  const authorId = author.login ? author.login : author.type;
  const authorType = author.type === UserType.Visitor ? UserType.Customer : author.type;
  const timestampInMs = getThreadEntityTimestampInMs(data) || getTime(new Date());

  return {
    name,
    authorId,
    authorType,
    status: ChatEventStatus.Delivered,
    wasSeen: false, // we are not supporting LC2 anymore so it's just mocked value
    contentType,
    safetyConfirmation,
    size,
    url,
    width,
    height,
    id: attachmentToken,
    timestampInMs,
    type: ChatEventType.Attachmment,
  };
}

export function deserializeSystemMessage(data: any, currentAgentName: string): ISystemMessage {
  const timestampInMs = getThreadEntityTimestampInMs(data);
  const messageText = replaceSystemMessageText(data.text, data.type, currentAgentName);
  const { subType, textVariables, text } = standardizeChatRatingEvent(messageText, data.type, data);

  return {
    id: `${timestampInMs}-${subType}`,
    text,
    timestampInMs,
    type: ChatEventType.Event,
    subType,
    ...(textVariables && { textVariables }),
  };
}

export function deserializeSurvey(
  fields: any,
  customerId: string,
  surveyType: any,
  timestampInMs: number
): ISurveyMessage {
  if (!fields) {
    return null;
  }

  const surveyResponses = fields
    .map((field: any) => ({
      answer: parseSurveyFieldValue(field),
      question: field.name,
      id: field.id,
    }))
    .reduce((acc, value) => mergeSurveyResponses(acc, value), []);

  return {
    id: `${timestampInMs}-prechat`,
    responses: surveyResponses,
    timestampInMs,
    authorId: customerId,
    authorType: UserType.Customer,
    type: ChatEventType.FilledForm,
    surveyType,
  };
}

export function deserializeChatMessage(data: any, customerId: string): IMessage | IRichMessage {
  const authorId = getAuthorId(data, convertChatUserType(data.user_type), customerId);
  const state = AppStateProvider.getState();

  const authorType = UserType.Agent as ChatEventAuthor; // we are not supporting LC2 anymore so it's just mocked value
  let authorName;

  if (authorType === UserType.Customer) {
    const customer = getCustomer(state, authorId);
    authorName = customer ? customer.name : null;
  } else {
    const agent = getAgentOrBot(state, authorId);
    authorName = agent ? agent.name : null;
  }

  const baseId = data.id ? data.id : `${data.type}_${getThreadEntityTimestampInMs(data)}`;

  const base = {
    authorId,
    authorType,
    authorName,
    status: data.seen ? ChatEventStatus.Read : ChatEventStatus.Delivered,
    id: baseId,
    text: data.text,
    wasSeen: false,
    timestampInMs: getThreadEntityTimestampInMs(data),
  };

  const messageJson = getJSONData(data.json || data.message_json);

  if (messageJson && isRichMessageJSON(messageJson)) {
    const richMessageData = getRichMessageData(messageJson);

    return {
      ...base,
      ...richMessageData,
      type: ChatEventType.RichMessage,
    };
  }

  const message: IMessage = {
    ...base,
    type: ChatEventType.Message,
  };

  if (data.properties?.translation) {
    message.translation = {
      sourceLangCode: getPropertyValue(data.properties, 'translation', 'source_lang_code', ''),
      targetLangCode: getPropertyValue(data.properties, 'translation', 'target_lang_code', ''),
      targetMessage: getPropertyValue(data.properties, 'translation', 'target_message', ''),
    };
  }

  return message;
}
