import { type FC } from 'react';

import { TopBarNotificationType } from 'constants/notifications';

import { ChangeBrowserBar } from './ChangeBrowserBar';
import { EnableNotificationsBar } from './EnableNotificationsBar';
import { UnblockNotificationsBar } from './UnblockNotififcationsBar';

interface IProps {
  type?: TopBarNotificationType;
  onSkip(name: TopBarNotificationType): void;
}
export const BrowserNotificationsBar: FC<IProps> = ({ type, onSkip }) => {
  const handleSkipClick = (): void => {
    onSkip(TopBarNotificationType.BrowserNotifications);
  };

  switch (type) {
    case TopBarNotificationType.ChangeBrowser:
      return <ChangeBrowserBar onSkip={handleSkipClick} />;
    case TopBarNotificationType.EnableNotifications:
      return <EnableNotificationsBar onSkip={handleSkipClick} />;
    case TopBarNotificationType.UnblockNotifications:
      return <UnblockNotificationsBar onSkip={handleSkipClick} />;
    default:
      return null;
  }
};
