import { css } from '@emotion/css';
import { SpacingToken, RadiusToken, DesignToken } from '@livechat/design-system-react-components';

export const wrapper = css`
  width: 100%;
  display: grid;
  grid-template-columns: 24px 1fr 1fr;
  grid-template-rows: auto auto;
  grid-column-gap: var(${SpacingToken.Spacing1});
  grid-template-areas:
    'image details price'
    '. picker picker';

  @media (min-width: 500px) {
    grid-template-columns: 48px 1fr auto;
    grid-template-rows: 48px auto;
    grid-template-areas:
      'image details price'
      '. picker price';
    grid-column-gap: var(${SpacingToken.Spacing4});
  }
`;

export const summaryWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: var(${SpacingToken.Spacing1});

  @media (min-width: 500px) {
    align-items: flex-start;
  }
`;

export const icon = css`
  grid-area: image;
  width: 24px;
  height: 24px;
  border-radius: var(${RadiusToken.Radius1});
  object-fit: contain;

  @media (min-width: 500px) {
    width: 48px;
    height: 48px;
  }
`;

export const productDetails = css`
  grid-area: details;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: hidden;

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: calc(100%);
  }

  @media (min-width: 500px) {
    align-items: flex-start;
    justify-content: center;
  }
`;

export const price = css`
  grid-area: price;

  > div {
    align-items: flex-end;
    white-space: nowrap;
  }
`;

export const frequency = css`
  color: var(${DesignToken.ContentBasicSecondary});
  margin-left: var(${SpacingToken.Spacing1});
`;

export const picker = css`
  grid-area: picker;

  > div {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: var(${SpacingToken.Spacing2});
    flex-wrap: nowrap;
    margin-top: var(${SpacingToken.Spacing2});
    margin-left: 0;

    > div {
      width: 100%;
      max-width: 160px;
    }
  }
`;

export const developerDetailsSkeleton = css`
  margin-top: var(${SpacingToken.Spacing1});
`;
