// @ts-strict-ignore
import { isAfter, isToday, isBefore } from 'date-fns';
import { createSelector } from 'reselect';

import { type DateFilter } from 'constants/filters/date-filter';
import { type CustomDateRangeFilter } from 'constants/filters/date-range-filter';
import { Filter, type Filters, type FiltersOperators, FilterOperator } from 'constants/filters/filter';
import { type SurveyType } from 'constants/filters/survey-type-filter';
import { PlanType } from 'constants/plan-type';
import { ReportDistribution } from 'constants/reports/distribution';
import { Last7DaysMode } from 'constants/reports/last-7-days-mode';
import { ReportType, type ReduxReportData, reportViewsDetails } from 'constants/reports/report-type';
import { getConfig } from 'helpers/config';
import { isReportTypeIsDisabled } from 'helpers/is-report-type-is-disabled';
import type {
  IReportsViewData,
  ReportsViewFilters,
  IReportsViewCacheExpirationTime,
  IReportsViewGroupFilter,
  IHeatmapFilter,
  IReportViewsById,
  IReportView,
  IReportsViewAgentFilter,
  IReportsViewState,
  IReportColumns,
} from 'interfaces/reports';
import type { IStoreState } from 'interfaces/store/store-state';
import { applicationsSelector, getIsChatAnalyserInstalled } from 'store/entities/applications/selectors';
import { BadgeKey, type BadgeValue } from 'store/entities/badges/interfaces';
import { getBadge } from 'store/entities/badges/selectors';
import { getCompanyDetails } from 'store/entities/company-details/selectors';
import { createSingleReportRequestTypeBase, ReportsEntitiesActionsNames } from 'store/entities/reports/actions';
import { getExportCSVInappSeen } from 'store/features/agent-custom-properties/selectors';
import { getPlanType, getCanExportReports, isAtLeastAdmin } from 'store/features/session/selectors';
import { createRequestFetchingSelector, createHasFetchFailedSelector } from 'store/requests/selectors';

export const START_COLLECTION_OF_BENCHMARK_DATA = '2019-01-01';
const END_OF_LOCAL_BENCHMARK_FREE_TRIAL = '2019-11-01';

const EMPTY_ARRAY: string[] = [];

export const benchmarkAvailable: string[] = [
  ReportType.TotalChats,
  ReportType.ChatRatings,
  ReportType.ChatDuration,
  ReportType.ChatResponseTime,
];

export interface IWithReportsViewState {
  views: {
    reports: IReportsViewState;
  };
}

export function getCurrentView(state: IWithReportsViewState): ReportType {
  return state.views.reports.currentView;
}

export function getReportCacheExpirationTime(state: IWithReportsViewState, reportType?: ReportType): Date {
  return state.views.reports.cacheExpirationTime[reportType] || null;
}

export function getAllReportsCacheExpirationTime(state: IWithReportsViewState): IReportsViewCacheExpirationTime {
  return state.views.reports.cacheExpirationTime;
}

export function getReportData<T extends ReduxReportData>(
  state: IWithReportsViewState,
  reportName: T
): IReportsViewData[T] {
  return state.views.reports.data[reportName];
}

export function getIsReportFetching(state: IStoreState, reportName: ReportType): boolean {
  return createRequestFetchingSelector([createSingleReportRequestTypeBase(reportName)])(state);
}

export function getIsReportFetchFailed(state: IStoreState, reportName: ReportType): boolean {
  return createHasFetchFailedSelector([createSingleReportRequestTypeBase(reportName)])(state);
}

export function getFilters(state: IWithReportsViewState): ReportsViewFilters {
  return state.views.reports.filters;
}

export function getFiltersOrder(state: IWithReportsViewState): string[] {
  return state.views.reports.filtersOrder;
}

export function getFiltersOperators(state: IWithReportsViewState): FiltersOperators {
  return state.views.reports.filtersOperators;
}

export function getDistribution(state: IWithReportsViewState): ReportDistribution {
  return state.views.reports.distribution;
}

export function getBenchmarkEnabled(state: IWithReportsViewState): boolean {
  return state.views.reports.benchmarkEnabled;
}

export function getFilterDateRange(state: IWithReportsViewState): CustomDateRangeFilter {
  return state.views.reports.filters[Filter.DateRange];
}

export function getFilterDate(state: IWithReportsViewState): DateFilter {
  return state.views.reports.filters[Filter.Date];
}

export function getFilterGroup(state: IWithReportsViewState): IReportsViewGroupFilter {
  return state.views.reports.filters[Filter.Group];
}

export function getGreetingFilter(state: IWithReportsViewState): Filters[Filter.Greeting] {
  return state.views.reports.filters[Filter.Greeting];
}

export function getFilterTag(state: IWithReportsViewState): Filters[Filter.Tag] {
  return state.views.reports.filters[Filter.Tag];
}

export function getFilterCountry(state: IWithReportsViewState): Filters[Filter.CountryISO] {
  return state.views.reports.filters[Filter.CountryISO];
}

export function getFilterGoal(state: IWithReportsViewState): Filters[Filter.Goal] {
  return state.views.reports.filters[Filter.Goal];
}

export function getSurveyTypeFilter(state: IWithReportsViewState): SurveyType {
  return state.views.reports.filters[Filter.SurveyType];
}

export function getFilterAgents(state: IWithReportsViewState): IReportsViewAgentFilter {
  return state.views.reports.filters[Filter.Agent];
}

export function getFilterAvailability(state: IWithReportsViewState): Filters[Filter.Availability] {
  return state.views.reports.filters[Filter.Availability];
}

export function hasReportComparisonDisabled(state: IWithReportsViewState): boolean {
  return isReportTypeIsDisabled(getCurrentView(state));
}

export function getReportComparisonDisabledFilters(state: IWithReportsViewState): Filter[] {
  switch (getCurrentView(state)) {
    case ReportType.AgentsPerformance:
      return [Filter.Group];
    default:
      return null;
  }
}

export function hasReportGroupFilterComparison(state: IWithReportsViewState): boolean {
  const group = getFilterGroup(state);

  return !!(group && group.compare);
}

export function hasReportAgentFilterComparison(state: IWithReportsViewState): boolean {
  const agent = getFilterAgents(state);

  return !!(agent && agent.compare);
}

export function hasReportDateRangeFilterComparison(state: IWithReportsViewState): boolean {
  const dateRange = getFilterDateRange(state);

  return !!(dateRange && dateRange.compare);
}

export function hasReportFiltersComparison(state: IWithReportsViewState): boolean {
  if (
    hasReportDateRangeFilterComparison(state) ||
    hasReportGroupFilterComparison(state) ||
    hasReportAgentFilterComparison(state)
  ) {
    return true;
  }

  return false;
}

// GLOBAL ACCOUNTS TODO: LICENSE PLAN SCOPE CHECK
export function hasFreeTrialEnded(state: IStoreState): boolean {
  const today = new Date();
  const plan = getPlanType(state);

  return plan === PlanType.Team && isAfter(today, END_OF_LOCAL_BENCHMARK_FREE_TRIAL);
}

export function hasBenchmarkData(state: IWithReportsViewState): boolean {
  const dateRange = getFilterDateRange(state);
  const isDateAfterBenchmarkDataCollection = isAfter(dateRange.to, START_COLLECTION_OF_BENCHMARK_DATA);

  return isDateAfterBenchmarkDataCollection;
}

export function hasHourlyDistribution(state: IWithReportsViewState): boolean {
  return getDistribution(state) === ReportDistribution.Hour;
}

function hasTodayDateFilter(state: IWithReportsViewState): boolean {
  const dateRange = getFilterDateRange(state);

  return dateRange && dateRange.from && isToday(dateRange.from) && isToday(dateRange.to);
}

export function hasActiveFilters(state: IWithReportsViewState): boolean {
  const filters = getFilters(state);

  return (
    hasTodayDateFilter(state) ||
    hasReportDateRangeFilterComparison(state) ||
    !!filters.agent ||
    !!filters.agentAssignment ||
    !!filters.availability ||
    !!filters.channel ||
    !!filters.group ||
    !!filters.goal ||
    !!filters.greeting ||
    !!filters.tag ||
    !!filters.saleGoal
  );
}

function isBenchmarkAvailable(state: IWithReportsViewState): boolean {
  return benchmarkAvailable.includes(getCurrentView(state));
}

export function canUseBenchmark(state: IStoreState): boolean {
  return (
    !hasFreeTrialEnded(state) &&
    isBenchmarkAvailable(state) &&
    hasBenchmarkData(state) &&
    !hasHourlyDistribution(state) &&
    !hasActiveFilters(state)
  );
}

export function shouldBenchmarkDataBeFetched(state: IStoreState): boolean {
  return !!getCompanyDetails(state) && canUseBenchmark(state) && getBenchmarkEnabled(state);
}

export function shouldWaitForCompanyDetails(state: IStoreState): boolean {
  return !getCompanyDetails(state) && canUseBenchmark(state) && getBenchmarkEnabled(state);
}

export function getQueueAbandonmentPage(state: IWithReportsViewState): number {
  const reportData = state.views.reports.data[ReportType.QueueAbandonment];

  return reportData ? reportData.page : 1;
}

export function getHighlightedIds(state: IWithReportsViewState): string[] {
  return state.views.reports.data[ReportType.ScheduledReports]
    ? state.views.reports.data[ReportType.ScheduledReports].highlightedIds
    : [];
}

export function hasReportsFromMarketPlace(state: IStoreState): boolean {
  const applications = applicationsSelector(state);
  const { reportsApplications } = getConfig();
  const reportsApplicationIds = Object.values(reportsApplications);

  return Object.keys(applications).some((applicationId) => reportsApplicationIds.includes(applicationId));
}

export function getBadgesCount(state: IStoreState): BadgeValue {
  const recentInsights = getBadge(state, BadgeKey.InsightsRecentInsights);
  const topCustomerQuestions = getBadge(state, BadgeKey.InsightsTopCustomerQuestions);

  return recentInsights + topCustomerQuestions;
}

function isReportFromMarketPlace(state: IStoreState, reportType: ReportType): boolean {
  const applications = applicationsSelector(state);
  const { reportsApplications } = getConfig();
  const reportName = Object.keys(ReportType).find((r) => ReportType[r] === reportType);

  return !!applications[reportsApplications[reportName]];
}

// GLOBAL ACCOUNTS TODO: NEED TO REFACTOR
function isReportAvailableInSalesPlan(state: IStoreState, reportType: ReportType): boolean {
  const salesPlan = getPlanType(state);

  switch (reportType) {
    case ReportType.ChatDuration:
    case ReportType.Activity:
    case ReportType.AgentsPerformance:
    case ReportType.ChatResponseTime:
    case ReportType.StaffingPrediction:
    case ReportType.ScheduledReports:
      return [PlanType.Business, PlanType.EnterprisePlus].includes(salesPlan);
    default:
      return [PlanType.Team, PlanType.Business, PlanType.EnterprisePlus].includes(salesPlan);
  }
}

// GLOBAL ACCOUNTS TODO: LICENSE PLAN SCOPE CHECK
export function isReportAvailable(state: IStoreState, reportType?: ReportType): boolean {
  if (isReportFromMarketPlace(state, reportType)) {
    return true;
  }

  return isReportAvailableInSalesPlan(state, reportType);
}

// GLOBAL ACCOUNTS TODO: LICENSE PLAN SCOPE CHECK
export function getShouldShowMarketplaceWarning(state: IStoreState, reportType: ReportType): boolean {
  return isReportFromMarketPlace(state, reportType) && isReportAvailableInSalesPlan(state, reportType);
}

export function getIsReportSaving(state: IStoreState): boolean {
  return createRequestFetchingSelector([ReportsEntitiesActionsNames.CREATE_REPORT])(state);
}

export function getIsReportEditing(state: IStoreState): boolean {
  return createRequestFetchingSelector([ReportsEntitiesActionsNames.EDIT_SCHEDULED_REPORT])(state);
}

export function getIsReportDeleting(state: IStoreState): boolean {
  return createRequestFetchingSelector([ReportsEntitiesActionsNames.DELETE_SCHEDULED_REPORT])(state);
}

export function getIsCreatingTicket(state: IStoreState): boolean {
  return createRequestFetchingSelector([ReportsEntitiesActionsNames.CREATE_TICKET_FROM_QUEUE_ABANDONMENT])(state);
}

export function getLast7DaysMode(state: IStoreState): Last7DaysMode {
  const initialLast7DaysMode = isAtLeastAdmin(state) ? Last7DaysMode.License : Last7DaysMode.Agent;
  const currentLast7DaysMode = state.views.reports.last7DaysMode;

  return currentLast7DaysMode ?? initialLast7DaysMode;
}

export function shouldDisplayExportCsvInAppMessage(state: IStoreState): boolean {
  return getCanExportReports(state) && !getExportCSVInappSeen(state);
}

export const getFilterOperator = (state: IWithReportsViewState, filter: Filter): FilterOperator => {
  const operators = getFiltersOperators(state);

  return operators[filter] || FilterOperator.Or;
};

export const getHeatmapFilters = (state: IWithReportsViewState, reportType: ReportType): IHeatmapFilter =>
  state.views.reports.heatmapFilters[reportType] as IHeatmapFilter;

export const getShouldShowHeatmapPreviousResults = (state: IWithReportsViewState, reportType: ReportType): boolean => {
  const heatmapFilters: IHeatmapFilter = getHeatmapFilters(state, reportType);

  return !!(heatmapFilters && isBefore(heatmapFilters.selectedFrom, heatmapFilters.from));
};

export const getShouldShowHeatmapNextResults = (state: IWithReportsViewState, reportType: ReportType): boolean => {
  const heatmapFilters: IHeatmapFilter = getHeatmapFilters(state, reportType);

  return !!(heatmapFilters && isAfter(heatmapFilters.selectedTo, heatmapFilters.to));
};

export function getReportViewsSelector(state: IWithReportsViewState): IReportViewsById {
  return state.views.reports.views;
}

export function getSelectedReportViewId(state: IWithReportsViewState): null | string {
  return state.views.reports.selectedView;
}

export const getReportViews = createSelector(
  getReportViewsSelector,
  getIsChatAnalyserInstalled,
  (viewsById, isChatAnalyserInstalled) =>
    Object.values<IReportView>(viewsById)
      .filter((view) => {
        if (!isChatAnalyserInstalled) {
          return view.type !== ReportType.ChatTopics;
        }

        return true;
      })
      .sort((a, b) => a.name.localeCompare(b.name))
      .sort((a, b) => reportViewsDetails[a.type]?.order - reportViewsDetails[b.type]?.order)
);

export const getSelectedReportView = createSelector(
  getReportViewsSelector,
  getSelectedReportViewId,
  (views, selectedViewId) => {
    if (!selectedViewId || !views[selectedViewId]) {
      return null;
    }

    return views[selectedViewId];
  }
);

export function getVisibleColumns(state: IWithReportsViewState): IReportColumns {
  return state.views.reports.visibleColumns;
}

export function getColumnsOrder(state: IWithReportsViewState): IReportColumns {
  return state.views.reports.columnsOrder;
}

export const getCurrentVisibleColumns = createSelector(
  getVisibleColumns,
  getCurrentView,
  (visibleColumns: IReportColumns, currentView: ReportType) => {
    const columns: string[] = visibleColumns[currentView] || EMPTY_ARRAY;

    return columns;
  }
);

export const getCurrentColumnsOrder = createSelector(
  getColumnsOrder,
  getCurrentView,
  (columnsOrder: IReportColumns, currentView: ReportType) => {
    const columns: string[] = columnsOrder[currentView] || EMPTY_ARRAY;

    return columns;
  }
);

export const getIsNavigationVisible = (state: IWithReportsViewState): boolean => state.views.reports.showNavigation;
