import { type FC } from 'react';

import { cx } from '@emotion/css';

import * as styles from './styles';

interface IProps {
  className?: string;
}

export const Divider: FC<IProps> = ({ className }) => {
  return <div className={cx(styles.divider, className)} />;
};
