import type { FC } from 'react';

import { PlayerStop } from '@livechat/design-system-icons';
import { Button, Icon, Tooltip, Text } from '@livechat/design-system-react-components';
import { useDispatch, useSelector } from 'react-redux';

import { OneTrackEvent } from 'constants/one-event';
import { EventPlace } from 'helpers/analytics';
import { trackEvent } from 'services/event-tracking';
import { OneEntitiesActions } from 'store/entities/one/actions';
import { getOneLatestAgentEventId } from 'store/entities/one/selectors';
import { getIsOneModalExpanded, getOneIsLoading } from 'store/views/one/selectors';

import { ONE_STOP_GENERATING_BTN_TEST_ID } from '../constants';
import * as styles from '../message-box/styles';

export const StopGenerateButton: FC = () => {
  const isOneLoading = useSelector(getOneIsLoading);
  const isExpanded = useSelector(getIsOneModalExpanded);
  const dispatch = useDispatch();
  const oneLatestAgentEventId = useSelector(getOneLatestAgentEventId);

  if (!isOneLoading) {
    return null;
  }

  const handleStopClick = (): void => {
    dispatch(OneEntitiesActions.cancelOneRequest());

    if (oneLatestAgentEventId) {
      dispatch(
        OneEntitiesActions.updateOneEvent({
          eventId: oneLatestAgentEventId,
          properties: {
            hasCancelledRequest: true,
          },
        })
      );
    }

    trackEvent(OneTrackEvent.ActionPerformed, EventPlace.One, { action: 'stop generating' });
  };

  return isExpanded ? (
    <Button
      kind="plain"
      iconPosition="right"
      icon={<Icon source={PlayerStop} />}
      className={styles.cancelGenerateButton}
      onClick={handleStopClick}
      data-testid={ONE_STOP_GENERATING_BTN_TEST_ID}
    >
      Stop generating
    </Button>
  ) : (
    <Tooltip
      className={styles.stopGeneratingTooltip}
      theme="invert"
      placement="left"
      hoverOnDelay={300}
      triggerRenderer={() => (
        <Button
          kind="high-contrast"
          className={styles.cancelGenerateButtonPopover}
          onClick={handleStopClick}
          data-testid={ONE_STOP_GENERATING_BTN_TEST_ID}
        >
          <Icon source={PlayerStop} />
        </Button>
      )}
    >
      <Text>Stop generating</Text>
    </Tooltip>
  );
};
