import { css, keyframes } from '@emotion/css';
import { DesignToken } from '@livechat/design-system-react-components';

import { LoaderCircleThickness } from './interfaces';

export const container = (size?: number, inline?: boolean): string => css`
  ${inline ? 'display: inline-block;' : 'margin: 0 auto 24px;'};
  width: ${size}px;
  height: ${size}px;
`;

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(1turn);
  }
`;

export const loaderCircle = (thickness?: LoaderCircleThickness): string => css`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 50%;
  animation: ${rotate} 1s infinite linear;
  border: ${thickness || LoaderCircleThickness.Thick}px solid var(${DesignToken.BorderBasicSecondary});
  border-top-color: var(${DesignToken.ActionPrimaryDefault});
`;

export const skeletonLoaderCircle = css`
  position: relative;
  border-width: 4px;
  visibility: visible;
  transition: all 0.2s ease-in-out;
`;

export const loaderContainer = css`
  width: 56px;
  height: 56px;
`;

export const loadingSpinnerWrapper = (isVisible: boolean): string => css`
  visibility: ${isVisible ? 'visible' : 'hidden'};
  opacity: ${isVisible ? 1 : 0};
  transition: all 0.2s ease-in-out;
  display: inline-block;
`;

export const icon = css`
  animation: rotate 1s cubic-bezier(0.17, 0.67, 0.83, 0.67) infinite;

  @keyframes rotate {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
