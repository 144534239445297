import { css } from '@emotion/css';
import { DesignToken, SpacingToken } from '@livechat/design-system-react-components';

export const filtersContainer = css`
  width: 100%;
  display: flex;
  align-items: flex-start;
  align-items: center;
  flex-wrap: wrap;
  flex: 1 1 auto;
  box-sizing: border-box;
  gap: var(${SpacingToken.Spacing1});
`;

export const compareCheckbox = css`
  margin-top: 0;

  .lc-checkbox__text {
    white-space: nowrap;
  }

  &.lc-checkbox--selected + .lc-text-field {
    margin-top: 10px;
  }
`;

export const selectIcon = css`
  [data-testid='multiselect-head-item'] {
    .lc-multiselect-head__item-content {
      display: flex;
    }

    svg {
      padding-top: 1px;
    }
  }
`;

export const select = css`
  margin-bottom: 10px;

  [class^='lc-Typography-module__paragraph-md'] {
    width: 100%;
    display: flex;
  }

  ${selectIcon}
`;

export const multiSelect = css`
  [class^='lc-Typography-module__paragraph-md'] {
    max-width: 100%;
  }
`;

export const stringFilter = css`
  margin: 3px;
`;

export const filteringTypeWrapper = css`
  width: 100%;
  margin-bottom: 20px;
`;

export const filteringTypeRadioButton = css`
  margin-bottom: 0;
`;

export const filterInputField = css`
  margin-top: 8px;
  padding-left: 20px;

  &:nth-of-type(2) {
    padding-left: 12px;
  }
`;

export const filterTextInput = css`
  padding-right: 20px;

  input.lc-input {
    width: 100%;
  }
`;

export const numberRangeFilter = css`
  width: 86px;
`;

export const filterNumericInputFieldWrapper = css`
  width: 100%;
`;

export const filterCountrySelect = css`
  padding-left: 20px;
  padding-right: 20px;

  .lc-select-body {
    width: 260px;
  }
`;

export const filtersMenuDropdownItemBody = css`
  position: relative;
`;

export const filtersMenuDropdownNewBadge = css`
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  color: var(${DesignToken.ColorNegativeDefault});
  font-size: 13px;
  font-weight: 600;
  pointer-events: none;
`;

export const closeButtonWrapper = css`
  cursor: pointer;
  display: inline-block;
  padding: 5px;
  margin: 2px 0 0 14px;
`;

export const mobileFiltersContainer = css`
  box-sizing: border-box;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(${DesignToken.Background});
  color: var(${DesignToken.ContentDefault});
  border-color: var(${DesignToken.BorderSubtle});
`;

export const mobileFiltersHeader = css`
  height: 52px;
  flex-shrink: 0;
`;

export const filtersList = css`
  padding: 12px;
  overflow-y: auto;
  flex-grow: 1;
`;

export const subHeader = css`
  box-shadow: 0 3px 10px -8px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 12px;
  box-sizing: border-box;
  border-bottom: 1px solid #dde2e6;
`;

export const mobileFiltersFooter = css`
  padding: 12px;
  box-sizing: border-box;
  width: 100%;
  background-color: var(${DesignToken.Background});
  border-top: 1px solid #dde2e6;
  box-shadow: 0 -1px 7px -2px rgba(0, 0, 0, 0.2);
`;

export const clearFiltersButton = css`
  font-size: 13px;
  line-height: 1.4;
  border: none;
  padding: 0;
  color: #4384f5;
  background-color: transparent;

  &:focus,
  &:active {
    outline: none;
  }

  &:disabled {
    opacity: 0.3;
  }
`;

export const filtersLabel = css`
  color: var(${DesignToken.ContentSubtle});
  font-size: 13px;
  line-height: 1.4;
`;

export const primaryButtonStyles = css`
  max-width: 100%;
`;

export const formField = css`
  width: 100%;
`;
