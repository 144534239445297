import type { CustomFormSurvey } from 'components/chat/chat-events/interfaces';
import { type ChatEventType, type ChatEventSubType } from 'constants/chat-event-type';
import { type IFacebookMessengerIntegration } from 'constants/integrations/facebook-messenger';
import type RichMessageTemplate from 'constants/rich-message-template';
import { UserType } from 'constants/user-type';
import type { RichMessageDetailsResult } from 'interfaces/api/event/rich-message';
import type { Properties } from 'interfaces/property';
import type { WithMarkdownEvent, WithTranslation } from 'store/entities/chats/interfaces';
import { type IGoal } from 'store/entities/goals/interfaces';

export enum ArchiveRate {
  Good = 'rated_good',
  Bad = 'rated_bad',
  Rated = 'rated',
  NotRated = 'not_rated',
}

export enum ArchiveType {
  Chat = 'chat',
  Missed = 'missed_chat',
}

export enum ArchiveAgentType {
  Agent = UserType.Agent,
  Supervisor = UserType.Supervisor,
}

interface IArchiveEventTextVariables {
  [key: string]: string;
}

export interface IArchiveEventDetails {
  name?: string;
  contentType: string;
  height?: number;
  width?: number;
  isSafetyConfirmed?: boolean;
  size: number;
  url: string;
}

export interface ISurveyResponse {
  id: string;
  question: string;
  answer: string;
  type: string;
}

export interface IArchiveEvent extends WithMarkdownEvent {
  id?: string;
  agentId?: string;
  authorName?: string;
  avatar?: string;
  date: string;
  details?: IArchiveEventDetails[];
  richMessageTemplate?: RichMessageTemplate;
  richMessageDetails?: RichMessageDetailsResult[];
  text: string;
  textVariables?: IArchiveEventTextVariables;
  timestampInMs: number;
  type: ChatEventType;
  subType?: ChatEventSubType;
  userType?: UserType;
  translation?: WithTranslation;
  customForm?: CustomFormSurvey;
  preChatSurvey?: ISurveyResponse[];
  messageReaction?: string;
  eventId?: string;
  formatting?: boolean;
}
export interface IArchiveAgent {
  displayName: string;
  email: string;
  type: ArchiveAgentType;
}

export interface IArchiveVisitor {
  city?: string;
  country?: string;
  countryCode?: string;
  email?: string;
  id: string;
  ip?: string;
  name: string;
  region?: string;
  timezone?: string;
  userAgent?: string;
  avatar?: string;
  longitude?: string;
  latitude?: string;
}

export interface ICustomVariable {
  key: string;
  value: string;
}

export interface IIntegrations {
  facebookMessenger: IFacebookMessengerIntegration;
}

export interface IArchive {
  agents?: IArchiveAgent[];
  cameFromURL: string;
  customVariables?: ICustomVariable[];
  chatClientId?: string; // marketplace application id, required to get channel icon
  events?: IArchiveEvent[];
  groupIds?: number[];
  goals?: IGoal[];
  id: string;
  chatId?: string;
  integrations?: IIntegrations;
  preChatSurvey?: ISurveyResponse[];
  postChatSurvey?: ISurveyResponse[];
  ticketForm?: ISurveyResponse[];
  rate: ArchiveRate;
  restrictedAccessReason?: string;
  startedOnURL: string;
  startedTimestamp: number;
  tags?: string[];
  tagsProcessing: string[];
  tagsFailureAdditions: string[];
  tagsFailureDeletions: string[];
  type: ArchiveType;
  queuedFor?: number;
  visitor: IArchiveVisitor;
  isUnassignedChat?: boolean;
  userIds?: string[];
  previousThreadId?: string;
  nextThreadId?: string;
  previousAccessibleThreadId?: string;
  nextAccessibleThreadId?: string;
  customProperties?: Properties;
}

export interface IDetailsConfiguration {
  selected: string[];
  order: string[];
}
