import { css } from '@emotion/css';
import {
  DesignToken,
  RadiusToken,
  SpacingToken,
  TransitionDurationToken,
} from '@livechat/design-system-react-components';

import { activeNavigationItemColor } from 'components/navigation/constants';
import { SIDE_NAVIGATION_WIDTH } from 'constants/navigation';

export const container = css`
  height: 100%;
  width: ${SIDE_NAVIGATION_WIDTH}px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  background-color: var(${DesignToken.NavbarBackground});
  padding: var(${SpacingToken.Spacing3}) var(${SpacingToken.Spacing1}) var(${SpacingToken.Spacing1})
    var(${SpacingToken.Spacing7});
  gap: var(${SpacingToken.Spacing5});
  color: var(${DesignToken.ContentBasicSecondary});
  overflow: hidden;
`;

export const headerRow = css`
  display: flex;
  align-items: center;
  padding: 0 var(${SpacingToken.Spacing3});
  height: 28px;
  flex-shrink: 0;
`;
export const title = css`
  color: var(${DesignToken.ContentLockedWhite});
`;

export const scrollbar = css`
  scrollbar-width: thin;
  scrollbar-color: var(${DesignToken.ActionNeutralHover}) transparent;

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    width: 4px;
  }
`;

export const navigationWrapper = (noGaps?: boolean): string => css`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  gap: ${noGaps ? '0' : `var(${SpacingToken.Spacing5})`};
  overflow-y: auto;
  padding-bottom: var(${SpacingToken.Spacing5});
  padding-right: var(${SpacingToken.Spacing7});

  ${scrollbar}
`;

export const listWrapper = css`
  width: 100%;
`;

export const list = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
  list-style: none;
  overflow: hidden;
  max-height: 0;
  margin-bottom: 0;
  transition: all var(${TransitionDurationToken.Moderate1}) cubic-bezier(0.645, 0.045, 0.355, 1);
  opacity: 0;
  > li {
    transition: all var(${TransitionDurationToken.Fast2}) cubic-bezier(0.645, 0.045, 0.355, 1);
    transform: translateY(-10px);
  }
`;

export const expandedList = css`
  max-height: 9999px; // maximum value to achieve the effect of "auto"
  opacity: 1;

  > li {
    transform: translateY(0);
  }
`;

export const expandedListGap = css`
  margin-bottom: var(${SpacingToken.Spacing5});
`;

export const item = css`
  display: block;
  margin-bottom: var(${SpacingToken.Spacing05});
  cursor: pointer;
`;

const highlightedSection = css`
  font-weight: 600;
`;

const selected = css`
  background-color: var(${DesignToken.SurfaceOpacityInvertActive});
  ${highlightedSection};
`;

export const anchor = (isActive?: boolean, isMainEntry?: boolean, isIconHidden?: boolean): string => css`
  border-radius: var(${RadiusToken.Radius3});
  padding: 5.5px ${isIconHidden ? 0 : `var(${SpacingToken.Spacing3})`};
  align-items: center;
  align-self: stretch;
  display: flex;
  text-decoration: none;
  color: var(${DesignToken.ContentLockedWhite});
  font-size: 13px;
  line-height: 20px;
  ${!isIconHidden && `gap: var(${SpacingToken.Spacing2});`};

  ${isMainEntry && highlightedSection};
  ${isActive && selected};

  &:hover {
    text-decoration: none;
    color: var(${DesignToken.ContentLockedWhite});

    ${!isActive && `background-color: ${activeNavigationItemColor};`}
    > p, > span {
      opacity: 1;
    }
  }
`;

export const icon = (isActive?: boolean): string => css`
  display: inline-block;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  opacity: ${isActive ? '1' : '0.6'};

  > * {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const defaultChevron = css`
  transition: transform var(${TransitionDurationToken.Moderate1}) cubic-bezier(0.645, 0.045, 0.355, 1);
`;
export const activeChevron = css`
  transform: rotate(90deg);
`;
