import type { FC } from 'react';

import { Button } from '@livechat/design-system-react-components';
import { useDispatch } from 'react-redux';

import { OneEntitiesActions } from 'store/entities/one/actions';
import { type IOneOnboardingActionPayload } from 'store/entities/one/interfaces';

import * as styles from './styles';

export const OnboardingActionButton: FC<IOneOnboardingActionPayload> = ({ eventId, content, props }) => {
  const dispatch = useDispatch();

  const continueOnboarding = (): void => {
    dispatch(OneEntitiesActions.oneOnboardingAction({ content, props, eventId }));
  };

  return (
    <Button key={`${eventId}-${content}`} className={styles.actionBtn} kind={props.kind} onClick={continueOnboarding}>
      {content}
    </Button>
  );
};
