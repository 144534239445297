import { type FC } from 'react';

import { cx } from '@emotion/css';
import { type InitialConfigType, LexicalComposer } from '@lexical/react/LexicalComposer';
import { useSelector } from 'react-redux';

import { getIsOneModalExpanded } from 'store/views/one/selectors';

import * as styles from './styles';

interface IProps {
  children: JSX.Element;
  className?: string;
  initialConfig?: InitialConfigType;
}

const DEFAULT_CONFIG: InitialConfigType = {
  namespace: 'MyEditor',
  onError: (error) => {
    throw error;
  },
};

export const OneMessageBoxWrapper: FC<IProps> = ({ children, className, initialConfig }) => {
  const isExpanded = useSelector(getIsOneModalExpanded);

  return (
    <LexicalComposer initialConfig={initialConfig ?? DEFAULT_CONFIG}>
      <div className={cx(styles.messageBoxWrapper(isExpanded), className)}>{children}</div>
    </LexicalComposer>
  );
};
