// @ts-strict-ignore
import { type FC } from 'react';

import { cx } from '@emotion/css';
import { type ToastProps, ToastWrapper } from '@livechat/design-system-react-components';
import { useDispatch, useSelector } from 'react-redux';

import { ToastsActions } from 'store/features/toasts/actions';
import {
  type IToastDetails,
  type ToastVerticalPosition,
  type ToastHorizontalPosition,
} from 'store/features/toasts/interfaces';
import { getToasts } from 'store/features/toasts/selectors';

import * as styles from './styles';

interface IProps {
  verticalPosition: ToastVerticalPosition;
  horizontalPosition: ToastHorizontalPosition;
  fixed?: boolean;
  name?: string;
  animationType?: string;
}

interface IToast extends Omit<ToastProps, 'content'> {
  id: string;
  content: React.ReactElement | string;
}

export const ToastConsumer: FC<IProps> = (props) => {
  const toasts = useSelector(getToasts);
  const dispatch = useDispatch();
  const onToastClose = (id: string): void => {
    dispatch(ToastsActions.removeToast({ id }));
  };

  const getSingleToastProps = (toast: IToastDetails): IToast => {
    const { onClose, horizontalPosition, verticalPosition, consumerName, ...toastProps } = toast;

    return {
      ...toastProps,
      onClose: () => onToastClose(toast.id),
    };
  };

  const validateToast = (toast: IToastDetails) => {
    if ((props.name || toast.consumerName) && props.name !== toast.consumerName) {
      return false;
    }
    if (props.verticalPosition !== toast.verticalPosition || props.horizontalPosition !== toast.horizontalPosition) {
      return false;
    }

    return true;
  };

  const { name, ...restProps } = props;

  return (
    <ToastWrapper
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...restProps}
      className={cx({
        [styles.globalToastWrapper]: !props.name,
      })}
      toasts={Object.values(toasts).filter(validateToast).map(getSingleToastProps)}
    />
  );
};

export const ToastContainer = ToastConsumer;
