import { css } from '@emotion/css';

export const header = css`
  font-size: 13px;
  font-weight: 400;
  opacity: 0.85;
  margin-bottom: 8px;
  margin-top: 0;
`;

const secondaryText = css`
  font-size: 13px;
  line-height: 18px;
  opacity: 0.8;
`;

export const name = css`
  min-width: 140px;
  flex: 2;

  > p {
    font-weight: 600;
    line-height: 18px;
    font-size: 16px;
  }

  > span {
    ${secondaryText};
  }
`;

export const price = css`
  min-width: 210px;
  flex: 1;

  > span {
    ${secondaryText};
  }
`;

export const quantity = css`
  max-width: 80px;

  .lc-text-field {
    margin-bottom: 0;
  }
`;

export const multipliers = css`
  display: flex;
  justify-content: end;
  align-items: flex-start;
  gap: 8px;

  div > p {
    margin-top: 18px;
    text-align: center;
  }
`;

export const multiplierIcon = css`
  padding-top: 32px;
  padding-right: 10px;
  padding-left: 10px;
  max-height: 40px;
  opacity: 0.5;
`;

export const resource = css`
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
`;

export const modal = css`
  width: 100%;
  min-height: 320px;
  max-width: 700px;
`;

export const modalContent = css`
  overflow-x: auto;
`;

export const footer = css`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 8px;
  margin-top: 16px;

  button {
    box-sizing: border-box;
  }
`;

export const divider = css`
  margin: 30px 0;
`;
