import { type SagaIterator } from 'redux-saga';
import { call, fork, put, select, takeEvery } from 'redux-saga/effects';

import { Interval } from 'constants/date';
import { GlobalModal } from 'constants/global-modal';
import { EventPlace } from 'helpers/analytics';
import { dateDifference } from 'helpers/date';
import { isMobileView } from 'helpers/device';
import { prepareEmailContent, sendEmail } from 'helpers/email';
import { navigate } from 'helpers/routing';
import { PartnerType } from 'interfaces/entities/current-license';
import { trackEvent } from 'services/event-tracking';
import { getSubscriptionManagersLogins } from 'store/entities/accounts/selectors';
import { getIsNonProfit, getPaymentRecurrentEnabled } from 'store/entities/license-properties/selectors';
import { GlobalModalActions } from 'store/features/global-modals/actions';
import {
  getCanManageSubscription,
  getExpirationTimestamp,
  getIsOnTrial,
  getPartnerType,
} from 'store/features/session/selectors';
import { InstallCodeActions } from 'store/views/install-code/actions';
import { TrialExpiringModalEnum } from 'store/views/trial-expiring-modal/actions';

import { TrialExpiringModalEvent } from './constants';

function* sendEmailSaga(): SagaIterator {
  const subscriptionManagerLogins: string[] = yield select(getSubscriptionManagersLogins);

  const emailContent = prepareEmailContent(
    (subscriptionManagerLogins || []).filter(Boolean).join(','),
    'Expiring LiveChat Trial',
    `Hi there!
We would like to continue using LiveChat on our website and we need your help. Please check our plan and seat number by clicking the link below and subscribe.

https://my.livechatinc.com/subscription/

Thanks!`
  );

  yield call(sendEmail, emailContent);
  yield put(GlobalModalActions.hideModal(GlobalModal.TrialExpiring));
}

function* goToSubscriptionSaga(): SagaIterator {
  yield call(navigate, '/subscription');
  yield put(GlobalModalActions.hideModal(GlobalModal.TrialExpiring));
}

function* trialExpiringSaga(): SagaIterator {
  const expiringAt: number = yield select(getExpirationTimestamp);
  const isNonProfit: boolean = yield select(getIsNonProfit);
  const isTrial: boolean = yield select(getIsOnTrial);
  const paymentRecurrentEnabled: boolean = yield select(getPaymentRecurrentEnabled);
  const partnerType: PartnerType = yield select(getPartnerType);
  const isMobileDevice: boolean = isMobileView();
  const isTrialWithCreatedSubscription = isTrial && paymentRecurrentEnabled;

  const shouldNotShowTrialExpiring =
    isMobileDevice || !isTrial || isTrialWithCreatedSubscription || partnerType === PartnerType.Reseller || isNonProfit;

  if (shouldNotShowTrialExpiring) {
    return;
  }

  const daysLeft = dateDifference(new Date(), new Date(expiringAt * 1000), Interval.Day, true);

  if (daysLeft > 3) {
    return;
  }

  trackEvent(TrialExpiringModalEvent.ModalDisplayed, EventPlace.TrialExpiringModal, { daysLeft });

  yield put(GlobalModalActions.showModal(GlobalModal.TrialExpiring));
}

function* modalButtonClickedSaga(): SagaIterator {
  const expiringAt: number = yield select(getExpirationTimestamp);
  const isInCodeInstalledReminder = window.location.pathname === '/install-code';
  const daysLeft: number = dateDifference(new Date(), new Date(expiringAt * 1000), Interval.Day, true);

  trackEvent(TrialExpiringModalEvent.CTAClicked, EventPlace.TrialExpiringModal, { daysLeft });

  if (isInCodeInstalledReminder) {
    yield put(InstallCodeActions.installCodeVisited());
  }

  const canManageSubscription: boolean = yield select(getCanManageSubscription);

  yield fork(canManageSubscription ? goToSubscriptionSaga : sendEmailSaga);
}

export function* trialExpiringModalSagas(): SagaIterator {
  yield takeEvery('APP_READY', trialExpiringSaga);
  yield takeEvery(TrialExpiringModalEnum.BUTTON_CLICKED, modalButtonClickedSaga);
}
