import { type Dispatch, type FC, type SetStateAction, createContext, useContext, useState } from 'react';

import { type ResourceModalType } from 'components/apps/constants';

interface IWidgetModalContext {
  selectedResourceId: string | null;
  setSelectedResourceId: Dispatch<SetStateAction<string | null>>;
  closeModal: () => void;
  openModal: () => void;
  openedModal: ResourceModalType | null;
  setOpenedModal: Dispatch<SetStateAction<ResourceModalType | null>>;
}

interface IProviderProps {
  closeModal: () => void;
  openModal: () => void;
}

export const WidgetModalContext = createContext<IWidgetModalContext | undefined>(undefined);

export const WidgetModalContextProvider: FC<IProviderProps> = ({ children, closeModal, openModal }) => {
  const [selectedResourceId, setSelectedResourceId] = useState<string | null>(null);
  const [openedModal, setOpenedModal] = useState<ResourceModalType | null>(null);

  const value: IWidgetModalContext = {
    selectedResourceId,
    setSelectedResourceId,
    closeModal,
    openModal,
    openedModal,
    setOpenedModal,
  };

  return <WidgetModalContext.Provider value={value}>{children}</WidgetModalContext.Provider>;
};

export const useWidgetModalContext = (): IWidgetModalContext => {
  const context = useContext(WidgetModalContext);

  if (context === undefined) {
    throw new Error('useWidgetModalContext must be used within a WidgetModalContextProvider');
  }

  return context;
};
