// @ts-strict-ignore
import { type IInboxMessage } from '../message/inbox/inbox-message';
import { denormalizeInboxMessage } from '../message/inbox/inbox-message-factory';
import { type IOutboxMessage } from '../message/outbox/outbox-message';
import { normalizeOutboxMessage } from '../message/outbox/outbox-message-factory';

import { type IConnection, type IInboxMessageSubscriber, POSTMESSAGE_UNAVAILABLE_ERROR_MESSAGE } from './connection';
import { createSubscriberNotifier } from './helpers';

interface IPlainConnectionOptions {
  onReconnect?(): void;
}

export async function createPlainConnection(
  iframe: HTMLIFrameElement,
  expectedOrigin: string,
  options: IPlainConnectionOptions = {}
): Promise<IConnection> {
  const child = iframe.contentWindow;
  const connectionId = String(Math.random());

  const isOwnEvent = (event: MessageEvent): boolean => {
    const { origin, source, data } = event;

    return origin === expectedOrigin && source === child && data.message && data.plugin_id;
  };

  const handshake = (): Promise<IInboxMessage> =>
    new Promise((resolve, reject) => {
      let connectionTimeout;

      const handleMessage = (event: MessageEvent): void => {
        if (!isOwnEvent(event)) {
          return;
        }

        clearTimeout(connectionTimeout);

        window.removeEventListener('message', handleMessage);

        const message = denormalizeInboxMessage(event.data);
        resolve(message);
      };

      const handleConnectionTimeout = () => {
        reject(new Error('Plain widget connection could not be established'));
      };

      window.addEventListener('message', handleMessage);

      iframe.addEventListener('load', () => {
        connectionTimeout = setTimeout(handleConnectionTimeout, 3000);
      });
    });

  const connection: IConnection = await handshake().then((initialMessage) => {
    const subscribers = new Set<IInboxMessageSubscriber>();
    const notifySubscribers = createSubscriberNotifier(subscribers);

    const handleMessage = (event: MessageEvent): void => {
      if (isOwnEvent(event)) {
        notifySubscribers(denormalizeInboxMessage(event.data), connectionId);
      }
    };

    window.addEventListener('message', handleMessage);

    return {
      connectionId,
      initialize() {
        notifySubscribers(initialMessage, connectionId);
      },
      subscribe(subscriber: IInboxMessageSubscriber) {
        subscribers.add(subscriber);
      },
      unsubscribe(subscriber: IInboxMessageSubscriber) {
        subscribers.delete(subscriber);
      },
      send(message: IOutboxMessage) {
        if (child && child.postMessage) {
          child.postMessage(normalizeOutboxMessage(message), expectedOrigin);

          return Promise.resolve();
        }

        return Promise.reject(new Error(POSTMESSAGE_UNAVAILABLE_ERROR_MESSAGE));
      },
      setContext(context) {
        this.msgContext = context;
      },
      getContext() {
        return this.msgContext || {};
      },
      destroy() {
        window.removeEventListener('message', handleMessage);
        subscribers.clear();
      },
    };
  });

  const handleReconnect = async () => {
    await handshake();
    if (options.onReconnect) {
      options.onReconnect();
    }
  };

  iframe.addEventListener('load', handleReconnect);

  return connection;
}
