import { DesignToken } from '@livechat/design-system-react-components';

/**
 * Login status - in api v3 it's changed to Routing Status
 * @see [RoutingStatus]{@link source/tsx/services/socket-lc3/agent/interfaces.ts}
 */
export enum LoginStatus {
  Away = 'away',
  Online = 'online',
  Offline = 'offline',
}

export enum NewLoginStatus {
  Away = 'Not accepting chats',
  Online = 'Accepting chats',
  Offline = 'Offline',
}

export enum AcceptingChatsStatus {
  AcceptingChats = 'accepting chats',
  AcceptingChatsDuringWorkingHours = 'work sheduler',
  NotAcceptingChats = 'not accepting chats',
}

export enum AgentActivityEventType {
  Away = 'status_away',
  Online = 'online',
}

export const LoginStatusScore = {
  [LoginStatus.Online]: 3,
  [LoginStatus.Away]: 2,
  [LoginStatus.Offline]: 1,
};

export const STATUS_TO_DESIGN_TOKEN = {
  [LoginStatus.Online]: DesignToken.SurfaceAccentEmphasisHighPositive,
  [LoginStatus.Offline]: DesignToken.ContentBasicDisabled,
  [LoginStatus.Away]: DesignToken.SurfaceAccentEmphasisHighNegative,
};
