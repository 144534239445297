import { css } from '@emotion/css';
import { DesignToken } from '@livechat/design-system-react-components';

export const iframeStyles = css`
  height: 100%;
  width: 100%;
`;

export const iframeHidden = css`
  visibility: hidden;
`;

export const loaderStyles = css`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(${DesignToken.ContentBasicSecondary});
  height: 100%;
  width: 100%;
  top: 0;
`;
