import { useMemo, useEffect, type FC, useCallback, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { WidgetIframe } from 'components/application-widget/WidgetIframe';
import { PostMessageEvent } from 'constants/post-message-event';
import { JSONParse } from 'helpers/json';
import { WidgetModalContextProvider } from 'hooks/fullscreen-widgets/use-widget-modal-context';
import type { PostMessage } from 'interfaces/post-message';
import { ApplicationWidgetPlacement } from 'store/entities/applications/interfaces';
import { getWidgetsWithPlacement } from 'store/entities/applications/selectors';
import { getLoadedFullscreenWidgets } from 'store/features/applications/selectors';
import { FullscreenWidgetsViewActions } from 'store/views/fullscreen-widgets/actions';

import { FULLSCREEN_WIDGETS_TEST_ID, WIDGET_TEST_ID } from './constants';
import { getWidgetDomain } from './helpers';
import { useMarketplacePostMessageListener } from './hooks/use-marketplace-post-message-listener';
import InAppUpgradesModal from './modals/InAppUpgradesModal';

import * as styles from './styles';

interface IProps {
  visibleWidgetSlugOrId: string | null;
}

export const FullscreenWidgets: FC<IProps> = ({ visibleWidgetSlugOrId }) => {
  const isVisible = visibleWidgetSlugOrId !== null;
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const dispatch = useDispatch();

  const widgets = useSelector((state) => getWidgetsWithPlacement(state, ApplicationWidgetPlacement.FullScreen));
  const loadedWidgetsIds = useSelector(getLoadedFullscreenWidgets);
  const loadedWidgets = useMemo(
    () => widgets.filter((widget) => loadedWidgetsIds.includes(widget.id)),
    [widgets, loadedWidgetsIds]
  );

  const openModal = (): void => setIsModalOpen(true);
  const closeModal = (): void => setIsModalOpen(false);

  const receiveAppPostMessage = useCallback((event: MessageEvent, origin: string): void => {
    if (!origin || origin !== event.origin) {
      return;
    }

    try {
      const data = JSONParse<PostMessage>(event.data);
      if (!data) {
        return;
      }

      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { event_name } = data;

      if (event_name === PostMessageEvent.OpenInAppUpgradesModal) {
        openModal();
      }
    } catch (error) {
      // do nothing
    }
  }, []);

  const visibleWidget = useMemo(() => {
    const widgetFound = widgets.find(
      (widget) => widget.appSlug === visibleWidgetSlugOrId || widget.id === visibleWidgetSlugOrId
    );
    if (!widgetFound) {
      return null;
    }

    return widgetFound;
  }, [visibleWidgetSlugOrId, widgets]);

  const visibleWidgetId = visibleWidget?.id || null;
  const widgetDomain = getWidgetDomain(visibleWidget?.url);

  const handleLoadWidget = useCallback(
    (widgetId: string): void => {
      dispatch(FullscreenWidgetsViewActions.load({ id: widgetId }));
    },
    [dispatch]
  );

  useEffect(() => {
    if (visibleWidgetId !== null) {
      handleLoadWidget(visibleWidgetId);
    }

    return () => closeModal();
  }, [visibleWidgetId, handleLoadWidget]);

  const receivePostMessage = useCallback(
    (e: MessageEvent): void => receiveAppPostMessage(e, widgetDomain),
    [widgetDomain, receiveAppPostMessage]
  );

  useEffect(() => {
    window.addEventListener('message', receivePostMessage);

    return () => window.removeEventListener('message', receivePostMessage);
  }, [receivePostMessage]);

  useMarketplacePostMessageListener();

  return (
    <div className={styles.container(isVisible)} data-test={FULLSCREEN_WIDGETS_TEST_ID}>
      {loadedWidgets.map((widget) => (
        <div
          data-testid={WIDGET_TEST_ID}
          key={widget.id}
          className={styles.widgetContainer(visibleWidgetId === widget.id)}
        >
          <WidgetIframe widget={widget} />
          {isModalOpen && visibleWidget?.appId && (
            <WidgetModalContextProvider openModal={openModal} closeModal={closeModal}>
              <InAppUpgradesModal appId={visibleWidget?.appId} />
            </WidgetModalContextProvider>
          )}
        </div>
      ))}
    </div>
  );
};
