// @ts-strict-ignore
import { QueryKey } from 'constants/query-key';
import { handleOnboardingStepSuccess } from 'hooks/api/onboarding/helpers';
import { completeOnboardingStepRequest, handleError } from 'hooks/api/onboarding/use-complete-onboarding-step';
import { type IProductOnboardingProgress, type ProductOnboardingStep } from 'interfaces/entities/product-onboarding';
import { getQueryClient } from 'services/query-client/client';

export const getIsStepCompleted = (stepName: ProductOnboardingStep): boolean => {
  const queryClient = getQueryClient();
  const onboardingProgress = queryClient.getQueryData<IProductOnboardingProgress>([QueryKey.OnboardingProgress]);

  return onboardingProgress?.[stepName] ?? true;
};

const getStepsLeftCount = (): number => {
  const queryClient = getQueryClient();
  const onboardingProgress = queryClient.getQueryData<IProductOnboardingProgress>([QueryKey.OnboardingProgress]);

  return onboardingProgress ? Object.values(onboardingProgress).filter((value) => !value).length : 0;
};

export async function sendOnboardingStepCompletedEvents(step: ProductOnboardingStep): Promise<void> {
  if (getIsStepCompleted(step)) {
    return;
  }

  return handleOnboardingStepSuccess({
    step,
    isLastStep: getStepsLeftCount() === 1,
    eventName: 'Checklist item completed',
  });
}

export async function markOnboardingStepComplete(step: ProductOnboardingStep): Promise<void> {
  if (getIsStepCompleted(step)) {
    return;
  }

  try {
    await completeOnboardingStepRequest({ step });
    await handleOnboardingStepSuccess({
      step,
      isLastStep: getStepsLeftCount() === 1,
      eventName: 'Checklist item completed',
    });
  } catch (error) {
    handleError(error);
  }
}
