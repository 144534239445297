import { type FC, useCallback, useState } from 'react';

import { cx } from '@emotion/css';
import { Modal } from '@livechat/design-system-react-components';

import { usePurchaseUpgrade } from 'components/apps/hooks/use-purchase-in-app-upgrade';
import { type ModalProps } from 'components/apps/interfaces';

import { useResourceContext } from '../../hooks/use-resource-context';

import { PaymentSummary } from './payment-summary/PaymentSummary';
import { PurchaseModalFooter } from './PurchaseModalFooter';
import { Resource } from './Resource';

import * as styles from './styles';

export const PurchaseModal: FC<ModalProps> = ({ closeModal }) => {
  const { appId, resourceId, upgradeId } = useResourceContext();
  const { purchase, isProcessing } = usePurchaseUpgrade();
  const [quantity, setQuantity] = useState<number>(1);

  const purchaseUpgrade = useCallback(() => {
    purchase(
      {
        applicationId: appId,
        resourceId,
        upgradeId,
        quantity,
      },
      {
        onSuccess: closeModal,
      }
    );
  }, [quantity, appId, resourceId, upgradeId, closeModal, purchase]);

  return (
    <Modal
      heading="Confirm and pay"
      className={cx(styles.modal, 'upgrades-modal')}
      contentClassName={styles.modalContent}
      onClose={closeModal}
      footer={
        <PurchaseModalFooter isLoading={isProcessing} purchaseTransaction={purchaseUpgrade} closeModal={closeModal} />
      }
    >
      <Resource onUpdateQuantity={setQuantity} />
      <div className={styles.divider} />
      <PaymentSummary quantity={quantity} />
    </Modal>
  );
};
