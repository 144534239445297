import { css } from '@emotion/css';

export const modal = css`
  max-width: 460px;
`;

export const footer = css`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  box-sizing: border-box;
`;
