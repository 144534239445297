export const enum SprigEvents {
  ChatSummaryCreateButtonClick = 'chat_summary_create_button_click',
  ReplySuggestionGenerated = 'ai-reply-suggested',
  ReplySuggestionGeneratedFirstTime = 'ai-reply-suggested-first-time',
  ChatClosed = 'chat-closed',
  ChatSummaryMenuClick = 'chat-summary-menu-click',
  TextFormattingUsed = 'text-formatting-used',
  OnboardingFinished = 'onboarding-finished',
  FirstMessageInOne = 'one_popover_first_message',
  OneConversation = 'one_popover_conversation',
  DictionaryAutocompleteUsed = 'dictionary-autocomplete-used',
  AICannedDisplayed = 'ai-canned-displayed',
  AICannedAccepted = 'ai-canned-accepted',
}
