import { getGoogleAnalyticsAppId } from 'helpers/applications';
import { getConfig } from 'helpers/config';
import { featureBranchURL } from 'helpers/regexp';
import type { Product } from 'interfaces/product-cart';
import { getAccessToken } from 'services/auth/auth-storage-manager';

export const getFullMarketplaceUrl = (): string => {
  const marketplaceUrl = new URL(getConfig().devMarketplace);

  // remove feature branch URL from pathname
  let marketplacePathname = window.location.pathname.replace(featureBranchURL, '');
  // remove /apps/marketplace so pathname would match marketplace routes
  marketplacePathname = marketplacePathname.replace('/apps/marketplace', '');
  // Marketplace should open on /apps page by default
  if (!window.location.pathname.includes('/apps/marketplace') || !marketplacePathname || marketplacePathname === '/') {
    marketplacePathname = '/apps';
  }

  // append pathname that would match marketplace routes
  marketplaceUrl.pathname = marketplacePathname;

  // append user token, so that marketplace can identify the user
  marketplaceUrl.hash = `#access_token=${getAccessToken() || ''}`;

  // append search params from the location.search
  marketplaceUrl.search = window.location.search;

  return marketplaceUrl.toString();
};

export const markGoogleAnalyticsForInstall = (product: Product): Product => {
  if (product.id === getGoogleAnalyticsAppId()) {
    return { ...product, isMarkedForInstall: true };
  }

  return product;
};
