import { type FC } from 'react';

import { cx } from '@emotion/css';
import { Close } from '@livechat/design-system-icons';
import { Icon } from '@livechat/design-system-react-components';

import * as styles from '../styles';

interface IProps {
  onSkip(): void;
}

export const ChangeBrowserBar: FC<IProps> = ({ onSkip }) => (
  <div className={cx(styles.contentContainer, styles.warningContainer)}>
    <div className={styles.skippableContent}>
      <span className={styles.textStyles}>
        Your browser doesn’t support browser notifications. We recommend using a modern browser, such as Chrome or
        Firefox.
      </span>
    </div>
    <button
      tabIndex={-1}
      className={styles.iconButton}
      onClick={onSkip}
      aria-label="Close the prompt about unsupported notifications"
    >
      <Icon source={Close} />
    </button>
  </div>
);
