import { type ConfigurationApiNormalizedError, type ConfigurationApiErrorResponse } from './types';

export function isConfigurationApiError(item: unknown): item is ConfigurationApiNormalizedError {
  const error = item as ConfigurationApiNormalizedError;

  return typeof error === 'object' && error !== null && 'status' in error && 'type' in error && 'message' in error;
}

export function isApiErrorResponse(item: unknown): item is ConfigurationApiErrorResponse {
  const error = item as ConfigurationApiErrorResponse;

  return (
    typeof error === 'object' && error !== null && 'error' in error && 'type' in error.error && 'message' in error.error
  );
}
