import { OneEntitiesActionNames, type OneEntitiesAction } from './actions';
import {
  getStateForAddNewOneEvent,
  getStateForAddOneError,
  getStateForCanceOneRequest,
  getStateForClearOneError,
  getStateForClearOneFailedMessageContent,
  getStateForOneLatestAgentEventId,
  getStateForOneMessageFromSpotlight,
  getStateForSetOneFailedMessageContent,
  getStateForSetOneSessionId,
  getStateForUpdateOneActionButtons,
} from './helpers';
import { type OneEntitiesState } from './interfaces';

export const initialState: OneEntitiesState = {
  events: {
    byIds: {},
    groupedIds: [[]],
    latestAgentEventId: null,
  },
  currentSessionId: null,
  isMessageFromSpotlight: false,
};

export const oneEntitiesReducer = (state = initialState, action: OneEntitiesAction): OneEntitiesState => {
  switch (action.type) {
    case OneEntitiesActionNames.ADD_ONE_MESSAGE: {
      return getStateForAddNewOneEvent(state, action.payload);
    }

    case OneEntitiesActionNames.ADD_ONE_ERROR: {
      return getStateForAddOneError(state, action.payload);
    }

    case OneEntitiesActionNames.CLEAR_ONE_ERROR: {
      return getStateForClearOneError(state);
    }

    case OneEntitiesActionNames.SET_ONE_SESSION_ID: {
      return getStateForSetOneSessionId(state, action.payload);
    }

    case OneEntitiesActionNames.CANCEL_ONE_REQUEST: {
      return getStateForCanceOneRequest(state);
    }

    case OneEntitiesActionNames.SET_FAILED_ONE_MESSAGE: {
      return getStateForSetOneFailedMessageContent(state, action.payload);
    }

    case OneEntitiesActionNames.CLEAR_FAILED_ONE_MESSAGE: {
      return getStateForClearOneFailedMessageContent(state);
    }

    case OneEntitiesActionNames.UPDATED_ONE_ACTION_BUTTONS: {
      return getStateForUpdateOneActionButtons(state, action.payload);
    }

    case OneEntitiesActionNames.ONE_MESSAGE_SPOTLIGHT: {
      return getStateForOneMessageFromSpotlight(state, action.payload);
    }

    case OneEntitiesActionNames.SET_LATEST_AGENT_EVENT_ID: {
      return getStateForOneLatestAgentEventId(state, action.payload);
    }

    default:
      return state;
  }
};
