import debug from 'debug';

import { App } from 'config/setup';
import { DebugLogsNamespace } from 'constants/debug-logs-namespace';
import { addLeadingZero } from 'helpers/time';

const logDebug = debug(DebugLogsNamespace.AppDebug);
const logError = debug(DebugLogsNamespace.AppErrors);

export function saveLog(type: 'error' | 'info', message: string): void {
  const logger = type === 'error' ? logError : logDebug;

  logger(message);

  if (App.SmartClient?.enableAdditionalLogs()) {
    const date = new Date();

    const time = [
      addLeadingZero(date.getHours()),
      addLeadingZero(date.getMinutes()),
      addLeadingZero(date.getSeconds()),
    ].join(':');

    const prefix = `[${type.toUpperCase()} ${time}]`;

    App.SmartClient?.saveAdvancedLog([prefix, message]);
  }
}
