import type { SagaIterator } from 'redux-saga';
import { put, select, takeEvery } from 'redux-saga/effects';

import { Section } from 'constants/section';
import { getVisibleNotifications } from 'helpers/notification-bar';
import { NotificationsBarActions } from 'store/features/notifications-bar/actions';

import { getNotifications } from '../notifications-bar/selectors';

import { RoutingActionsEnum, type RoutingAction } from './actions';
import { getIsPreviousSection, type IWithRoutingState } from './selectors';

const SECTIONS_WITHOUT_NOTIFICATIONS_BAR = [Section.InstallCode, Section.Onboarding, Section.Subscription];

function* watchRouteChange(action: RoutingAction): SagaIterator {
  if (action.type !== RoutingActionsEnum.SET_CURRENT_SECTION) {
    return;
  }

  const route = action.payload.route;
  const isPreviousSection = yield select((state: IWithRoutingState) => getIsPreviousSection(state, route));

  if (isPreviousSection) {
    return;
  }

  const shouldCollapseNotifcationBar = SECTIONS_WITHOUT_NOTIFICATIONS_BAR.some((section) => route.startsWith(section));
  const notifications = yield select(getNotifications);
  const hasVisibleNotifications = getVisibleNotifications(notifications).length > 0;

  if (shouldCollapseNotifcationBar || !hasVisibleNotifications) {
    yield put(NotificationsBarActions.hideAllNotificationsBar());
  } else {
    yield put(NotificationsBarActions.showAllNotificationsBar());
  }
}

export function* routingSaga(): SagaIterator {
  yield takeEvery(RoutingActionsEnum.SET_CURRENT_SECTION, watchRouteChange);
}
