import { type ActionsUnion, createAction } from 'store/helper';

import { type SetErrorPayload } from './interfaces';

export const enum StartupErrorActionNames {
  SET_ERROR = 'VIEWS/STARTUP_ERROR/SET_ERROR',
}

export const StartupErrorActions = {
  setError: (payload: SetErrorPayload) => createAction(StartupErrorActionNames.SET_ERROR, payload),
};

export type StartupErrorAction = ActionsUnion<typeof StartupErrorActions>;
