import { usePrevious } from '@livechat/react-hooks';
import { useSelector } from 'react-redux';

import { type NavigationBadgeComponent } from 'components/new-navigation/interfaces';
import { getNotificationsChatsCount } from 'store/views/chats/selectors';

import { NavigationBadge } from '../navigation-badge/NavigationBadge';

import { animation } from './styles';

export const ChatsBadge: NavigationBadgeComponent = ({ isActive }) => {
  const count = useSelector(getNotificationsChatsCount);
  const previousCount = usePrevious(count);
  const shouldBadgeAnimate = !isActive && count !== previousCount;
  const className = shouldBadgeAnimate ? animation : undefined;

  return count ? (
    <NavigationBadge
      type="counter"
      className={className}
      count={count}
      ariaLabel={`${count} unread chat notifications`}
    />
  ) : null;
};
