import { css } from '@emotion/css';
import { DesignToken, SpacingToken, RadiusToken } from '@livechat/design-system-react-components';
import { ellipsis } from 'polished';

import { SMALL_SCREEN_BREAKPOINT } from 'constants/responsive-breakpoint';
import { resetLegacyLcBorderBox } from 'styles/reset-border-box';

export const groupedEventsWrapper = (isExpanded = false): string => {
  const topMargin = isExpanded ? SpacingToken.Spacing7 : SpacingToken.Spacing5;
  const sideMargin = isExpanded ? SpacingToken.Spacing0 : SpacingToken.Spacing3;
  const bottomMargin = SpacingToken.Spacing3;

  return css`
    margin: var(${topMargin}) var(${sideMargin}) var(${bottomMargin});

    @media (max-width: ${SMALL_SCREEN_BREAKPOINT}px) {
      margin: var(${SpacingToken.Spacing5}) 0 var(${SpacingToken.Spacing3});
    }
  `;
};

export const bubblePosition = css`
  display: flex;
  flex-direction: row;
`;

export const eventsWrapper = (isAgentMessage = false, isExpanded = false): string => css`
  ${bubblePosition};
  width: ${isExpanded ? 'calc(100% - 40px)' : '100%'};
  max-width: ${isExpanded ? 'calc(100% - 40px)' : '100%'};
  flex-wrap: wrap;
  padding: ${isAgentMessage && isExpanded ? '0 40px' : '0'};
  gap: var(${SpacingToken.Spacing1});

  div[class*='bubble'] {
    border-radius: var(${RadiusToken[4]});
  }

  .bubble-wrapper {
    width: 100%;
  }
`;

export const eventsWrapperGroupedBorders = (isReverse = false): string => css`
  div[class*='bubble'] {
    border-top-left-radius: ${isReverse ? `var(${RadiusToken[4]})` : `var(${RadiusToken[1]})`};
    border-top-right-radius: ${isReverse ? `var(${RadiusToken[1]})` : `var(${RadiusToken[4]})`};
    border-bottom-right-radius: ${isReverse ? `var(${RadiusToken[1]})` : `var(${RadiusToken[4]})`};
    border-bottom-left-radius: ${isReverse ? `var(${RadiusToken[4]})` : `var(${RadiusToken[1]})`};
  }

  div[class*='bubble-wrapper']:first-child div[class*='bubble'] {
    border-top-left-radius: var(${RadiusToken[4]});
    border-top-right-radius: var(${RadiusToken[4]});
    border-bottom-right-radius: ${isReverse ? `var(${RadiusToken[1]})` : `var(${RadiusToken[4]})`};
    border-bottom-left-radius: ${isReverse ? `var(${RadiusToken[4]})` : `var(${RadiusToken[1]})`};
  }

  div[class*='bubble-wrapper']:last-child div[class*='bubble'] {
    border-top-left-radius: ${isReverse ? `var(${RadiusToken[4]})` : `var(${RadiusToken[1]})`};
    border-top-right-radius: ${isReverse ? `var(${RadiusToken[1]})` : `var(${RadiusToken[4]})`};
    border-bottom-right-radius: var(${RadiusToken[4]});
    border-bottom-left-radius: var(${RadiusToken[4]});
  }
`;

export const bubble = css`
  width: 100%;
  max-width: 100%;
  display: inline-block;
  font-size: 14px;
  line-height: 1.5em;
  word-break: break-word;
  color: var(${DesignToken.ContentBasicPrimary});
  background: var(${DesignToken.OnePopoverMessageBackgroundDefault});
`;

export const eventWrapper = (isAgentMessage = false): string => css`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${isAgentMessage ? 'flex-end' : 'flex-start'};
  color: ${isAgentMessage ? `var(${DesignToken.ContentLockedWhite})` : `var(${DesignToken.ContentBasicPrimary})`};
`;

export const eventText = css`
  padding: var(${SpacingToken.Spacing3}) var(${SpacingToken.Spacing4});
  display: flex;
`;

export const breakText = css`
  white-space: pre-line;
`;

export const botAvatar = css`
  min-width: 32px;
  margin: var(${SpacingToken.Spacing05}) var(${SpacingToken.Spacing2}) 0 0;
`;

export const agentAvatar = css`
  min-width: 32px;
  margin: var(${SpacingToken.Spacing05}) 0 0 var(${SpacingToken.Spacing2});
`;

export const typingIndicator = css`
  background: var(${DesignToken.OnePopoverMessageBackgroundDefault});
  padding: 12px 20px;
  height: auto;
  width: 60px;
  height: 46px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-top: var(${SpacingToken.Spacing1});

  span {
    width: 4px;
    height: 4px;
    background-color: var(${DesignToken.ContentBasicDisabled});
  }
`;

export const virtuosoEmptyElement = css`
  height: 1px;
`;

export const errorWrapper = css`
  padding: 10px 20px 12px;

  button {
    margin-top: var(${SpacingToken.Spacing2});
  }
`;

export const buttonContainer = css`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: var(${SpacingToken.Spacing4});

  &:has(button) {
    padding: 0 20px 12px;
  }
`;

export const linkTrigger = css`
  display: inline-block;
`;

export const linkInTextTooltip = css`
  max-width: 343px;
  display: flex;
  align-items: center;
  ${resetLegacyLcBorderBox};

  [class*='module__paragraph'] {
    height: 22px;
    line-height: 22px;
    overflow: hidden;
  }
`;

export const linkContent = css`
  ${ellipsis()};
`;

export const imageEvent = css`
  width: 100%;
  max-width: 500px;
  height: auto;
  max-height: 264px;
  border-top-left-radius: 1.4em;
  border-top-right-radius: 1.4em;
  border-bottom-right-radius: 1.4em;
  border-bottom-left-radius: 1.4em;
  margin: 2px;
`;

export const imageLoader = css`
  width: 500px;
  height: 264px;
`;

export const messageEventWrapper = (isAgentMessage = false): string => css`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: ${isAgentMessage ? 'flex-end' : 'flex-start'};
  align-items: ${isAgentMessage ? 'flex-end' : 'flex-start'};
`;

export const markdown = css`
  ul,
  ol {
    list-style-position: initial;
    list-style-image: initial;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  ul {
    list-style-type: disc;
  }
  ol {
    list-style-type: decimal;
  }
  li {
    margin-left: 1em;
  }

  li > li:marker + p {
    display: inline;
  }
`;

export const messageHeading = css`
  padding: 0 0 var(${SpacingToken.Spacing2}) 0;
  font-size: 15px;
  font-weight: 600;
`;

export const popoverAgentMessage = css`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: var(${DesignToken.ContentBasicPrimary});
  width: 100%;
  word-break: break-word;
`;

export const cancelledMessage = css`
  color: var(${DesignToken.ContentBasicDisabled});
`;
