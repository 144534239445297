export enum PaymentStatus {
  Pending = 'pending',
  Accepted = 'accepted',
  Declined = 'declined',
  Processed = 'processed',
  Active = 'active',
  Cancelled = 'cancelled',
  Frozen = 'frozen',
}

export interface ICreateRecurringRequestBody {
  name: string;
  return_url: string;
  order_client_id: string;
  seller_client_id?: string;
  price: number;
  trial_days: number;
  months: number;
  quantity: number;
  per_account: boolean;
  test?: boolean;
}

export interface ICreateRecurringRequestResult {
  id: string;
  buyer_license_id: number;
  buyer_entity_id: string;
  name: string;
  price: number;
  return_url: string;
  trial_days: number;
  months: number;
  test: boolean;
  per_account: boolean;
  status: PaymentStatus;
  next_charge_at: string;
  trial_ends_at: string;
}

export interface IFetchBillingInfoResult {
  card_type: string;
  first_six: string;
  last_four: string;
  year: number;
  month: number;
  first_name: string;
  last_name: string;
  company: string;
  address1: string;
  city: string;
  state: string;
  country: string;
  zip: string;
  vat_number: string;
}

export interface IFetchPricePreviewResult {
  tax_rate: number;
  tax_value: number;
  tax_region: string;
  total_price: number;
}
