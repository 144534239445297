import { type FC } from 'react';

import { ApplicationsDataSuspender } from 'components/ApplicationsDataSuspender';
import { MobileNavigation } from 'components/mobile-navigation/MobileNavigation';
import { Navigation } from 'components/navigation/navigation/Navigation';
import { NotificationsBar } from 'components/notifications-bar/NotificationsBar';
import { TopBarWrapper } from 'components/notifications-bar/TopBarWrapper';
import { SuspenseWrapper } from 'components/suspense-wrapper/SuspenseWrapper';
import MarketplaceRouter from 'routes/apps/marketplace/MarketplaceRouter';
import FullscreenWidgetsRouter from 'routes/fullscreen-widgets/FullscreenWidgetsRouter';

export const MainLayoutLegacy: FC = ({ children }) => {
  return (
    <div className="page-wrapper">
      <div id="navigation" className="nav-container">
        <Navigation />
      </div>
      <div id="page-content-container" className="content-container">
        <div id="notifications-bar-container">
          <NotificationsBar />
        </div>
        <div id="spotlight-container">
          <TopBarWrapper />
        </div>
        <div id="content" className="full-size">
          <SuspenseWrapper>
            {children}
            <ApplicationsDataSuspender />
            <FullscreenWidgetsRouter />
            <MarketplaceRouter />
          </SuspenseWrapper>
        </div>
        <div id="mobile-navigation">
          <MobileNavigation />
        </div>
      </div>
    </div>
  );
};
