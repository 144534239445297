import { type KeyMap } from 'helpers/interface';

import { ApiManager } from './api/api-manager';
import type { MLGatewaySendEvent } from './api/ml-gateway/interfaces/event-collector';

type UniqueId = string | number;

type ConstructorProps<D extends KeyMap<any>, E extends MLGatewaySendEvent> = {
  initialState: Record<UniqueId, D>;
  eventValidator: (event?: D) => boolean;
  prepareEvent: (data: D) => E;
};

export class EventCollector<D extends KeyMap<any>, E extends MLGatewaySendEvent> {
  private state: Record<UniqueId, D>;
  private eventValidator: (event?: D) => boolean;
  private prepareEvent: (data: D) => E;

  constructor({ initialState, eventValidator, prepareEvent }: ConstructorProps<D, E>) {
    this.state = initialState;
    this.eventValidator = eventValidator;
    this.prepareEvent = prepareEvent;
  }

  public addEvent(uniqueId: UniqueId, data: D): this {
    this.state[uniqueId] = data;

    return this;
  }

  private dumpEvent(uniqueId: UniqueId): this {
    delete this.state[uniqueId];

    return this;
  }

  public updateEvent(uniqueId: UniqueId, data: D extends (infer R)[] ? Partial<R>[] : Partial<D>): this {
    if (!this.state[uniqueId]) {
      return this;
    }

    const currentPayload = this.state[uniqueId];

    if (Array.isArray(currentPayload)) {
      const updatedEvent = [...currentPayload, ...(data as unknown as Partial<D>[])] as unknown as D;
      this.state[uniqueId] = updatedEvent;

      return this;
    }

    const updatedEvent = {
      ...currentPayload,
      ...data,
    } as D;

    this.state[uniqueId] = updatedEvent;

    return this;
  }

  public async sendEvent(uniqueId: UniqueId): Promise<void> {
    if (!this.state[uniqueId]) {
      return;
    }

    const currentEvent = this.state[uniqueId];

    if (!this.eventValidator(currentEvent)) {
      this.dumpEvent(uniqueId);

      return;
    }

    const eventToSend = this.prepareEvent(currentEvent);

    try {
      await ApiManager.mlGatewayApi.sendEvent(eventToSend);
    } catch {
      // eslint-disable-next-line no-console
      console.error('Failed to send custom event');
    } finally {
      this.dumpEvent(uniqueId);
    }
  }
}
