import type { FC } from 'react';

import { useSelector } from 'react-redux';

import { getSubscriptionIsPastDue } from 'store/entities/subscription/selectors';

import { NavigationBadge } from './navigation-badge/NavigationBadge';

export const SubscriptionBadge: FC = () => {
  const isPastDue = useSelector(getSubscriptionIsPastDue);

  return isPastDue ? <NavigationBadge type="alert" /> : null;
};
