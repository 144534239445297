import { css } from '@emotion/css';
import { DesignToken, RadiusToken, SpacingToken } from '@livechat/design-system-react-components';

import { mobileMediaQuery } from 'helpers/media-queries';

import { activeNavigationItemColor } from '../navigation/constants';

export const mobileNavigation = css`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  background-color: #131317;
  z-index: 11000;

  ${mobileMediaQuery} {
    display: block;
  }
`;

export const list = css`
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    flex-grow: 1;
  }
`;

export const whiteDot = css`
  height: 4px;
  width: 4px;
  border-radius: 100%;
  background-color: var(${DesignToken.ContentLockedWhite});
  display: block;
`;

export const mobileNavigationItem = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  padding: 4px 0;

  &:hover {
    text-decoration: none;
  }
`;

export const contentWrapper = (isActive: boolean): string => css`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 48px;
  border-radius: var(${RadiusToken['3']});
  background-color: ${isActive ? activeNavigationItemColor : ''};

  .icon-container {
    opacity: ${isActive ? '1' : '0.7'};
  }

  span {
    padding-top: 2px;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    color: var(${DesignToken.ContentLockedWhite});
    opacity: ${isActive ? '1' : '0.6'};
  }
`;

export const badgeContainer = css`
  position: absolute;
  top: 3px;
  right: 7px;
  z-index: 10;
`;

export const oneImage = css`
  width: 44px;
  height: 44px;
`;

export const badge = css`
  position: absolute;
  top: 8px;
  right: 9px;
  scale: 0.8;
  border: var(${SpacingToken.Spacing05}) solid var(${DesignToken.NavbarBackground});
  opacity: 1 !important;

  span {
    max-width: var(${SpacingToken.Spacing1});
    max-height: var(${SpacingToken.Spacing1});
  }
`;
