/* eslint-disable @typescript-eslint/naming-convention */
import { type SagaIterator } from 'redux-saga';
import { takeEvery, select, call } from 'redux-saga/effects';

import { browserMajorVersion, browserName } from 'helpers/browser';

import { LicensePropertiesActionNames } from './actions';
import { getNpsFirstChat } from './helpers';
import { getFirstChatDate } from './selectors';

function* setChatWidgetCustomProperties(): SagaIterator {
  const firstChatDate: Date | null = yield select(getFirstChatDate);

  yield call(window.LiveChatWidget.call, 'update_session_variables', {
    og_cv_browser_name: browserName,
    og_cv_browser_version: browserMajorVersion,
    og_cv_nps_first_chat: getNpsFirstChat(firstChatDate),
  });
}

export function* licensePropertiesSaga(): SagaIterator {
  yield takeEvery(
    [LicensePropertiesActionNames.SET_DATA, LicensePropertiesActionNames.UPDATE_DATA],
    setChatWidgetCustomProperties
  );
}
