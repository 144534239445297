import type { FC } from 'react';

import { SkeletonWrapper, SkeletonText, SkeletonAvatar } from '@livechat/design-system-react-components';

import { Divider } from 'components/divider/Divider';

import * as styles from './styles';

export const ContentLoader: FC = () => (
  <div>
    <div className={styles.loaderItemWrapper}>
      <SkeletonWrapper>
        <SkeletonAvatar size={48} square />
        <SkeletonWrapper vertical>
          <SkeletonText width={100} height={20} />
          <SkeletonText width={220} height={14} />
        </SkeletonWrapper>
      </SkeletonWrapper>

      <SkeletonWrapper vertical>
        <SkeletonText width={60} height={20} />
        <SkeletonText width={40} height={14} />
      </SkeletonWrapper>
    </div>

    <Divider className={styles.divider} />

    <div className={styles.loaderPaymentWrapper}>
      <SkeletonWrapper vertical>
        <SkeletonText width={100} height={20} />
        <SkeletonText width={220} height={14} />
      </SkeletonWrapper>

      <SkeletonText width={60} height={20} />
    </div>
  </div>
);
