import { DesignToken } from '@livechat/design-system-react-components';

import amexCard1x from 'assets/img/subscription/cards/american-express.png';
import amexCard2x from 'assets/img/subscription/cards/american-express@2x.png';
import discoverCard1x from 'assets/img/subscription/cards/discover.png';
import discoverCard2x from 'assets/img/subscription/cards/discover@2x.png';
import mastercardCard1x from 'assets/img/subscription/cards/mastercard.png';
import mastercardCard2x from 'assets/img/subscription/cards/mastercard@2x.png';
import visaCard1x from 'assets/img/subscription/cards/visa.png';
import visaCard2x from 'assets/img/subscription/cards/visa@2x.png';
import { RecurlyFieldType } from 'interfaces/recurly';

export const CARD_FORM_EXPANDER_TEST_ID = 'card-form-expander';
export const CARD_FORM_TEST_ID = 'subscription-card-form';

export const recurlyFieldStyles = {
  fontFamily: 'Inter',
  fontColor: `var(${DesignToken.ContentBasicPrimary})`,
  fontSize: '15px',
};

export const FIELD_VALIDATION_MAP = {
  [RecurlyFieldType.FirstName]: [],
  [RecurlyFieldType.LastName]: [RecurlyFieldType.FirstName],
  [RecurlyFieldType.CreditCard]: [RecurlyFieldType.FirstName, RecurlyFieldType.LastName],
  [RecurlyFieldType.PostalCode]: [RecurlyFieldType.FirstName, RecurlyFieldType.LastName, RecurlyFieldType.CreditCard],
};

/* eslint-disable @typescript-eslint/naming-convention */
export const amexCard = {
  '1x': amexCard1x,
  '2x': amexCard2x,
};
export const discoverCard = {
  '1x': discoverCard1x,
  '2x': discoverCard2x,
};
export const mastercardCard = {
  '1x': mastercardCard1x,
  '2x': mastercardCard2x,
};
export const visaCard = {
  '1x': visaCard1x,
  '2x': visaCard2x,
};
/* eslint-enable @typescript-eslint/naming-convention */

export const supportedCards = [visaCard, mastercardCard, discoverCard, amexCard];
