import { type FC } from 'react';

import { format } from 'date-fns';
import { useSelector } from 'react-redux';

import { getLastActiveTransaction, getPriceComponents } from 'components/apps/helpers';
import { DateFormat } from 'constants/date';
import { getActiveAgentsCount } from 'store/entities/agents/selectors';

import { useResourceContext } from '../../hooks/use-resource-context';

export const SummaryCell: FC = () => {
  const agentsCount = useSelector(getActiveAgentsCount);
  const { price, transactions, isActive, multiplied, totalPrice, paymentFrequency, payPerAgent, resourcePrice } =
    useResourceContext();

  if (isActive) {
    const lastActiveTransaction = getLastActiveTransaction(transactions);
    const lastActionDate = format(lastActiveTransaction.switch?.enabled as string, DateFormat.UsedSinceDate);

    if (!price) {
      if (!multiplied) {
        return <p>Activated at {lastActionDate} </p>;
      }

      return (
        <>
          <p>Activated {transactions.length} times</p>
          <span>Last enable at {lastActionDate} </span>
        </>
      );
    }

    if (!multiplied) {
      return <p>Purchased at {lastActionDate} </p>;
    }

    return (
      <>
        <p>Purchased {transactions.length} times</p>
        <span>Last purchase at {lastActionDate} </span>
      </>
    );
  }

  if (!price) {
    return <p> Free </p>;
  }

  const priceComponents = getPriceComponents({
    resourcePrice,
    payPerAgent,
    agentsCount,
  });

  return (
    <>
      <p>
        Total ${totalPrice} {paymentFrequency}{' '}
      </p>
      <span>{priceComponents}</span>
    </>
  );
};
