import type { FC } from 'react';

import { Text, DesignToken } from '@livechat/design-system-react-components';
import { useSelector } from 'react-redux';

import { withVar } from 'helpers/styles';
import { useOneClickPaymentContext } from 'routes/apps/one-click-payment/hooks/use-one-click-payment-context';
import { getActiveAgentsCount } from 'store/entities/agents/selectors';

import { DeveloperDetails } from '../PurchaseModal/Content/PurchaseItem/DeveloperDetails';
import { PriceFormatter } from '../PurchaseModal/Content/PurchaseItem/PriceFormatter';

import * as styles from './styles';

export const ItemSummary: FC = () => {
  const { item } = useOneClickPaymentContext();
  const agentsCount = useSelector(getActiveAgentsCount);

  if (!item) {
    return null;
  }

  const payment = item.payment;
  const unitPrice = payment.price;
  const isPerAccount = payment.perAccount;
  const totalPrice = isPerAccount ? unitPrice * agentsCount : unitPrice;

  return (
    <div className={styles.itemSummary}>
      <img className={styles.icon} src={item?.icons?.small} alt={`${item.name} logo`} />

      <div>
        <Text size="md" bold>
          {item.name}
        </Text>

        {item?.ownerId && <DeveloperDetails developerId={item?.ownerId} />}
      </div>

      <div className={styles.price}>
        <Text size="md" bold>
          <PriceFormatter value={totalPrice} frequency={payment.frequency} />
        </Text>

        <Text size="xs" customColor={withVar(DesignToken.ContentBasicSecondary)}>
          {isPerAccount ? (
            <>
              <PriceFormatter value={unitPrice} />
              {agentsCount > 1 && ` ✕ ${agentsCount} Agents`}
            </>
          ) : (
            'Per organization'
          )}
        </Text>
      </div>
    </div>
  );
};
