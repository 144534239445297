import type { FC } from 'react';

import { SkeletonText, SkeletonWrapper } from '@livechat/design-system-react-components';

export const HeaderLoader: FC = () => (
  <SkeletonWrapper vertical>
    <SkeletonText width={160} height={20} />
    <SkeletonText width={200} height={14} />
  </SkeletonWrapper>
);
