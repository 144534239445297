import { type FC, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { ResourceContextProvider } from 'components/apps/hooks/use-resource-context';
import { WidgetResourceModalContextProvider } from 'components/apps/hooks/use-widget-resource-modal-context';
import { useWidgetModalContext } from 'hooks/fullscreen-widgets/use-widget-modal-context';
import { IN_APP_UPGRADES_TAB } from 'routes/apps/apps-settings/applications/constants';
import { useFetchInAppUpgrades } from 'routes/apps/apps-settings/applications/in-app-upgrades/hooks/use-fetch-in-app-upgrades';
import { ApplicationsActions } from 'store/entities/applications/actions';
import { getIsFetchingPrivateApplications, hasFetchedPrivateApplications } from 'store/entities/applications/selectors';

import { type TabsStateType } from '../interfaces';

import InAppUpgradeTableModal from './InAppUpgradeTableModal';

import * as styles from './styles';

interface IProps {
  appId: string;
}

const InAppUpgradesModal: FC<IProps> = ({ appId }) => {
  const [tab, setTab] = useState<TabsStateType>(IN_APP_UPGRADES_TAB.all.id);
  const { data, isLoading: isFetchingUpgrades } = useFetchInAppUpgrades(appId);
  const { selectedResourceId } = useWidgetModalContext();
  const dispatch = useDispatch();
  const arePrivateAppsFetched = useSelector(hasFetchedPrivateApplications);
  const isFetchingPrivateApps = useSelector(getIsFetchingPrivateApplications);

  useEffect(() => {
    if (!arePrivateAppsFetched) {
      dispatch(ApplicationsActions.fetchPrivateApplications());
    }
  }, [dispatch, arePrivateAppsFetched]);

  const resources = data?.[tab] || data?.all || [];
  const selectedResource = resources.find(({ resourceId }) => selectedResourceId === resourceId);
  const isLoading = isFetchingUpgrades || isFetchingPrivateApps;

  return (
    <div className={styles.modalContainer}>
      {selectedResource ? (
        <ResourceContextProvider resource={selectedResource}>
          <WidgetResourceModalContextProvider isActive={selectedResource.isActive} isFree={!selectedResource.price} />
        </ResourceContextProvider>
      ) : (
        <InAppUpgradeTableModal resources={resources} data={data} isLoading={isLoading} tab={tab} setTab={setTab} />
      )}
    </div>
  );
};

export default InAppUpgradesModal;
