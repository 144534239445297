import { type FC } from 'react';

import { useResourceContext } from '../../hooks/use-resource-context';

export const NameCell: FC = () => {
  const { name, description } = useResourceContext();

  return (
    <>
      <p>{name}</p>
      <span>{description}</span>
    </>
  );
};
