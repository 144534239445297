import { css } from '@emotion/css';
import { DesignToken } from '@livechat/design-system-react-components';

import { type IColLabelProps } from './interfaces';

export const colLabel = ({ active, desc }: IColLabelProps): string => css`
  position: relative;
  display: inline-block;
  color: var(${active ? DesignToken.DecorGray600 : DesignToken.DecorGray200});
  cursor: pointer;
  user-select: none;
  padding-left: ${active ? '18px' : '0'};
  transition: padding 0.1s linear;

  &:before {
    position: absolute;
    top: 50%;
    left: 0;
    display: ${active ? 'inline-block' : 'none'};
    margin-right: 7px;
    content: ' ';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid var(${DesignToken.BorderSubtle});
    vertical-align: top;
    transition: transform 0.15s linear;
    transform: translateY(-50%) rotate(${desc ? 180 : 0}deg);
  }
`;
