// @ts-strict-ignore
import { ChatRecipients } from 'constants/chat-recipients';
import { ChatThreadStatus } from 'constants/chat-thread-status';
import { ChatType } from 'constants/chat-type';
import { UserType } from 'constants/user-type';
import { getConfig } from 'helpers/config';
import { type KeyMap } from 'helpers/interface';
import type { ChatUserResultType } from 'interfaces/api/chat';
import {
  type ChatResultType,
  type IChatResult,
  type ChatSummaryResult,
  type ThreadResultType,
} from 'services/api/chat/interfaces';
import { isChatCustomerResult } from 'services/api/interfaces/chat/helpers';
import { getPropertyValue } from 'services/serialization/property';
import { getThreadEntityTimestampInMs } from 'services/serialization/timestamp';
import { isClosedThread, isMyChat } from 'store/entities/chats/helpers/common';
import { deserializeThreadChattingAgent } from 'store/entities/chats/helpers/sagas';
import {
  type ChatEventEntity,
  type ChatThreadEntity,
  type IMyChat,
  type IOtherChat,
  type IQueuedChat,
  type IStartedThread,
  type ISupervisedChat,
  type IThreadBase,
  type IUnassignedChat,
} from 'store/entities/chats/interfaces';

import { deserializeThreadEventCollection } from './deserialize-event-collection';
import { isContinuousChatThread, type ICurrentAgent, type IEventDetails } from './helpers/common';
import { getChatType } from './helpers/get-chat-type';
import { getThreadEventsFromChatSummary } from './helpers/get-thread-events-from-chat-summary';

function deserializeChatBase(data: ChatResultType, type: ChatType, thread: ThreadResultType): IThreadBase {
  const { email, id, name } = data.users.find(isChatCustomerResult);
  const { access, users } = data;

  const clientId = getConfig().accountsClientId;
  const sentimentClientId = getConfig().chatSentimentNamespace;
  const groupIds = access && access.group_ids ? access.group_ids.map(String) : ['0'];
  const currentAgentId = deserializeThreadChattingAgent(users);

  const isContinuous = isContinuousChatThread(thread.properties);
  const wasUnassigned = getPropertyValue<boolean>(data.properties, 'routing', 'was_pinned', false);
  const threadClientId = getPropertyValue<string>(thread.properties, 'source', 'client_id');
  const chatClientId = getPropertyValue<string>(data.properties, 'source', 'client_id');

  const supervisingAgentsIds = users.reduce((ids: string[], chatUser) => {
    if (chatUser.type === UserType.Agent && chatUser.visibility === ChatRecipients.Agents && chatUser.present) {
      ids.push(chatUser.id);
    }

    return ids;
  }, []);

  const integrationProperties = {
    ...(thread.properties?.[clientId] && { [clientId]: thread.properties[clientId] }),
    ...(thread.properties?.[sentimentClientId] && { [sentimentClientId]: thread.properties[sentimentClientId] }),
  };

  return {
    currentAgentId,
    customerEmail: email,
    customerId: id,
    customerName: name,
    chatId: data.id,
    groupIds,
    threadId: thread.id,
    type,
    customProperties: {
      isContinuous,
      wasUnassigned,
      ...integrationProperties,
    },
    threadClientId,
    chatClientId,
    ...(supervisingAgentsIds?.length > 0 && {
      supervisorsIds: supervisingAgentsIds,
    }),
  };
}

function deserializeStartedChat(thread: ThreadResultType): IStartedThread {
  let status;
  if (thread.active) {
    status =
      getPropertyValue<boolean>(thread.properties, 'routing', 'idle') === true
        ? ChatThreadStatus.Idle
        : ChatThreadStatus.Active;
  } else {
    status = ChatThreadStatus.Closed;
  }

  const initiatorClientId = getPropertyValue<string>(thread.properties, 'source', 'client_id');
  const startedTimestamp = getThreadEntityTimestampInMs(thread);

  return {
    status,
    startedTimestamp,
    initiatorClientId,
  };
}

function deserializeMyChat(data: ChatResultType, type: ChatType, thread: ThreadResultType): IMyChat {
  return {
    ...deserializeChatBase(data, type, thread),
    ...deserializeStartedChat(thread),
  };
}

function deserializeUnassignedChat(data: ChatResultType, type: ChatType, thread: ThreadResultType): IUnassignedChat {
  const base = deserializeChatBase(data, type, thread);

  return {
    ...base,
    ...deserializeStartedChat(thread),
    customProperties: {
      ...base.customProperties,
      isContinuous: true,
    },
  };
}

function deserializeQueuedChat(data: ChatResultType, type: ChatType, thread: ThreadResultType): IQueuedChat {
  const queueWaitingTime = thread.queue?.wait_time;
  const queuePosition = thread.queue?.position;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const queuedAtInMs = getThreadEntityTimestampInMs({ created_at: thread.queue?.queued_at });

  return {
    ...deserializeChatBase(data, type, thread),
    startedTimestamp: deserializeStartedChat(thread).startedTimestamp,
    queuePosition,
    queueWaitingTime,
    queuedAtInMs,
  };
}

function deserializeSupervisedChat(data: ChatResultType, type: ChatType, thread: ThreadResultType): ISupervisedChat {
  return {
    ...deserializeChatBase(data, type, thread),
    ...deserializeStartedChat(thread),
  };
}

function deserializeOtherChat(data: ChatResultType, type: ChatType, thread: ThreadResultType): IOtherChat {
  return deserializeChatBase(data, type, thread);
}

function deserialize(data: ChatResultType, currentAgentId: string, thread: ThreadResultType): ChatThreadEntity {
  const type = getChatType(data, currentAgentId);

  const deserializers = {
    [ChatType.My]: deserializeMyChat,
    [ChatType.Queued]: deserializeQueuedChat,
    [ChatType.Supervised]: deserializeSupervisedChat,
    [ChatType.Unassigned]: deserializeUnassignedChat,
    [ChatType.Other]: deserializeOtherChat,
  };

  return deserializers[type] ? deserializers[type](data, type, thread) : null;
}

export function deserializeChatSummary(
  data: ChatSummaryResult,
  currentAgentId: string,
  resolveWasEventSeen: (event: IEventDetails, currentAgent: ICurrentAgent, chatUsers: ChatUserResultType[]) => boolean
): {
  thread: ChatThreadEntity;
  events: KeyMap<ChatEventEntity>;
} {
  const thread = deserialize(data, currentAgentId, data.last_thread_summary);

  const shouldStopDeserialization = thread === null || (isMyChat(thread) && isClosedThread(thread));
  if (shouldStopDeserialization) {
    return null;
  }

  const chatSummaryEvents = getThreadEventsFromChatSummary(data, thread.threadId) || [];

  const events = deserializeThreadEventCollection(
    chatSummaryEvents,
    data.users || [],
    currentAgentId,
    resolveWasEventSeen,
    thread.threadId
  );

  return {
    thread,
    events,
  };
}

export function deserializeThread(data: IChatResult, currentAgentId: string): ChatThreadEntity {
  return deserialize(data, currentAgentId, data.thread);
}
