import type { SagaIterator } from 'redux-saga';
import { select, takeLatest, put, spawn } from 'redux-saga/effects';

import { NavigationTooltipType } from 'constants/navigation';
import { getConfig } from 'helpers/config';
import { RequestAction } from 'store/entities/actions';
import { getShouldShowHelpDeskPromoTooltip } from 'store/entities/license-custom-properties/selectors';
import { AGENT_CUSTOM_PROPERTIES } from 'store/features/agent-custom-properties/actions';
import { AgentCustomPropertyName } from 'store/features/agent-custom-properties/interfaces';
import { getAgentCustomProperty } from 'store/features/agent-custom-properties/selectors';
import { NotificationsBarActionsNames } from 'store/features/notifications-bar/actions';

import { NavigationViewActions } from './actions';

function* showHelpDeskPromoTooltip(): SagaIterator {
  const shouldShowHelpDeskPromoTooltip = yield select(getShouldShowHelpDeskPromoTooltip);
  const hasAgentSeenHelpDeskPromoTooltip = yield select(
    getAgentCustomProperty,
    AgentCustomPropertyName.HelpDeskNavPromoSeen
  );
  if (shouldShowHelpDeskPromoTooltip && !hasAgentSeenHelpDeskPromoTooltip) {
    yield put(
      NavigationViewActions.showNavigationItemTooltip({
        itemId: getConfig().helpDeskAppWidgetId,
        type: NavigationTooltipType.HelpDeskPromo,
        kind: 'important',
      })
    );
  }
}

function* showTooltips(): SagaIterator {
  yield spawn(showHelpDeskPromoTooltip);
}

export function* navigationSagas(): SagaIterator {
  yield takeLatest(
    [
      AGENT_CUSTOM_PROPERTIES.FETCH_AGENT_CUSTOM_PROPERTIES[RequestAction.SUCCESS],
      NotificationsBarActionsNames.SHOW_NOTIFICATIONS_BAR,
      NotificationsBarActionsNames.HIDE_NOTIFICATIONS_BAR,
      NotificationsBarActionsNames.NOTIFICATIONS_BAR_TRANSITON_ENDED,
    ],
    showTooltips
  );
}
