import { css } from '@emotion/css';
import { SpacingToken } from '@livechat/design-system-react-components';

export const purchaseWrapper = css`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;
  gap: var(${SpacingToken.Spacing2});

  a {
    font-size: 12px;
    line-height: 16px;
  }

  @media (min-width: 500px) {
    flex-direction: row;
    flex-flow: row wrap;
  }
`;

export const buttonsWrapper = css`
  display: flex;
  gap: var(${SpacingToken.Spacing2});
`;

export const footerWrapper = css`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: var(${SpacingToken.Spacing2});
`;

export const footerLoader = css`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div:last-of-type {
    justify-content: flex-end;
  }
`;
