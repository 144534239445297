import { formatPrice } from 'helpers/subscription';

export const getTransactionSummary = ({
  price,
  perAgent,
  agentsCount,
  quantity,
}: {
  price: number;
  perAgent: boolean;
  agentsCount: number;
  quantity: number;
}): {
  priceComponents: string;
  totalPrice: string;
} => {
  if (!Number(price)) {
    return {
      priceComponents: 'Free',
      totalPrice: 'Free',
    };
  }

  const unitPrice = price / 100;
  let priceComponents = `$${unitPrice}`;
  let totalPrice = unitPrice * quantity;

  if (perAgent && agentsCount > 1) {
    priceComponents = `${priceComponents} x ${agentsCount} Agents`;
    totalPrice = totalPrice * agentsCount;
  }

  if (quantity > 1) {
    priceComponents = `${priceComponents} x ${quantity}`;
  }

  return {
    priceComponents,
    totalPrice: `$${formatPrice(totalPrice)}`,
  };
};

export const isRecurrentCharge = (paymentId: string): boolean => {
  const [transactionType] = paymentId.split(':');

  return transactionType === 'recurrent_charge';
};

export const getChargeDetails = (paymentId: string, perAgent = false): string => {
  if (!paymentId) {
    return '';
  }

  if (isRecurrentCharge(paymentId)) {
    return `| per month, ${perAgent ? 'per agent' : 'per license'}`;
  }

  return '| one-time payment';
};
