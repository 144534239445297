import { css } from '@emotion/css';
import { SpacingToken } from '@livechat/design-system-react-components';

import { withVar } from 'helpers/styles';

export const container = css`
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: center;
  padding-top: 6px;
`;

export const homeIcon = css`
  padding: ${withVar(SpacingToken.Spacing2)} ${withVar(SpacingToken.Spacing3)};

  svg {
    width: 28px;
    height: 28px;
  }
`;
