import { type SagaIterator } from 'redux-saga';
import { call, cancel, delay, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { TopBarNotificationType } from 'constants/notifications';
import { ToastContent } from 'constants/toasts';
import { EventPlace, trackGTMEvent } from 'helpers/analytics';
import { markOnboardingStepComplete } from 'helpers/product-onboarding';
import { getToastContent } from 'helpers/toast';
import type { RequestResult } from 'interfaces/api/client';
import { ProductOnboardingStep } from 'interfaces/entities/product-onboarding';
import { ApiManager } from 'services/api/api-manager';
import { trackEvent } from 'services/event-tracking';
import { LocalStorageKey, getItem } from 'services/local-storage';
import type { RequestFailure } from 'store/entities/interfaces';
import { getHadChatsRecently, getIsCodeInstalled } from 'store/features/code-installation/selectors';

import { NotificationsBarActions } from '../notifications-bar/actions';
import { SessionActionNames } from '../session/actions';
import { getIsOnTrial } from '../session/selectors';
import { ToastsActions } from '../toasts/actions';
import { ToastVariant } from '../toasts/interfaces';

import { CodeInstallationActions, CodeInstallationActionsNames } from './actions';
import { deserializeChannelsActivity, type IChannelActivityData } from './serializers';

function* initializeSaga(): SagaIterator {
  const isCodeInstalled = yield select(getIsCodeInstalled);
  const hadChatsRecently = yield select(getHadChatsRecently);

  if (!isCodeInstalled || !hadChatsRecently) {
    yield put(CodeInstallationActions.scanForCode());
  }
}

function* scanForCodeSaga(): SagaIterator {
  const API = ApiManager.websiteApi;
  const hasCodeInstalled = yield select(getIsCodeInstalled);

  while (true) {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { result }: RequestResult<{ days_ago }> = yield call(API.fetch, 'last_activity');

    const daysSinceLastActivity: number = result && result.days_ago && parseInt(result.days_ago, 10);
    const hasRecentActivity = Number.isInteger(daysSinceLastActivity) && daysSinceLastActivity >= 0;
    const codeNotInstalledMocked = getItem<boolean>(LocalStorageKey.MockCodeNotInstalled);

    const isCodeFound = daysSinceLastActivity < 3;
    if (hasRecentActivity && isCodeFound && !codeNotInstalledMocked) {
      const activityAction = hasCodeInstalled
        ? CodeInstallationActions.activityFound
        : CodeInstallationActions.codeFound;

      yield put(activityAction());
      yield cancel();
    }

    yield delay(5000);
  }
}

function* checkCodeInstallationSaga(): SagaIterator {
  const API = ApiManager.websiteApi;
  const hasCodeInstalled = yield select(getIsCodeInstalled);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { result }: RequestResult<{ days_ago }> = yield call(API.fetch, 'last_activity');

  const daysSinceLastActivity: number = result && result.days_ago && parseInt(result.days_ago, 10);
  const hasRecentActivity = Number.isInteger(daysSinceLastActivity) && daysSinceLastActivity >= 0;
  const codeNotInstalledMocked = getItem<boolean>(LocalStorageKey.MockCodeNotInstalled);

  const isCodeFound = daysSinceLastActivity < 3;
  if (hasRecentActivity && isCodeFound && !codeNotInstalledMocked) {
    const activityAction = hasCodeInstalled ? CodeInstallationActions.activityFound : CodeInstallationActions.codeFound;

    yield put(activityAction());
    yield put(
      ToastsActions.createToast({
        content: getToastContent(ToastContent.CODE_INSTALLATION_SUCCESS),
        kind: ToastVariant.Success,
      })
    );
    yield cancel();
  } else {
    yield put(
      ToastsActions.createToast({
        content: getToastContent(ToastContent.CODE_INSTALLATION_FAILURE),
        kind: ToastVariant.Error,
      })
    );
  }
}

function* fetchChannelsActivity(): SagaIterator {
  const API = ApiManager.channelsActivityApi;
  const { result, error }: RequestResult<IChannelActivityData[], RequestFailure> = yield call(API.fetch);

  if (error) {
    yield put(CodeInstallationActions.fetchChannelsActivityFailure(error));
    yield cancel();
  }

  if (result) {
    const activity = deserializeChannelsActivity(result);
    yield put(CodeInstallationActions.fetchChannelsActivitySuccess(activity));
  }
}

function* codeFoundSaga(): SagaIterator {
  trackEvent('Tracking code found on website', EventPlace.Installation);

  if (yield select(getIsOnTrial)) {
    yield call(trackGTMEvent, 'codeDetected', {
      codeDetectedValue: true,
    });
  }

  yield call(window.LiveChatWidget.call, 'update_session_variables', {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    og_cv_code_installed: true,
  });

  yield put(NotificationsBarActions.hideNotificationsBar(TopBarNotificationType.InstallCode));
  yield put(CodeInstallationActions.fetchChannelsActivity());

  yield call(markOnboardingStepComplete, ProductOnboardingStep.CodeInstalled);
}

function* sendGTMEvent(): SagaIterator {
  const hasActivity: ReturnType<typeof getIsCodeInstalled> = yield select(getIsCodeInstalled);
  const isOnTrialValue: ReturnType<typeof getIsOnTrial> = yield select(getIsOnTrial);

  if (isOnTrialValue) {
    yield call(trackGTMEvent, 'codeDetected', {
      codeDetectedValue: hasActivity,
    });
  }
}

export function* codeInstallationSaga(): SagaIterator {
  yield takeLatest('APP_READY', initializeSaga);
  yield takeEvery(CodeInstallationActionsNames.SCAN_FOR_CODE, scanForCodeSaga);
  yield takeEvery(CodeInstallationActionsNames.CODE_FOUND, codeFoundSaga);
  yield takeEvery(CodeInstallationActionsNames.FETCH_CHANNELS_ACTIVITY, fetchChannelsActivity);
  yield takeEvery(SessionActionNames.SAVE_CURRENT_LICENSE, sendGTMEvent);
  yield takeEvery(CodeInstallationActionsNames.CHECK_CODE_INSTALLATION, checkCodeInstallationSaga);
}
