import { type SagaIterator } from 'redux-saga';
import { takeEvery, call, put, select } from 'redux-saga/effects';

import { SoundNotification } from 'constants/sound-notifications';
import { type Notifications } from 'store/entities/agents/interfaces';
import { getLoggedInAgentNotifications } from 'store/entities/agents/selectors';
import { type IActionWithPayload } from 'store/helper';

import { SoundNotificationActions } from '../sound-notifications/actions';

import { BrowserNotificationsActionNames, BrowserNotificationsActions } from './actions';
import {
  areNotificationsSupported,
  showNotification,
  cancelAllNotifications,
  cancelNotification,
  cancelSomeNotifications,
} from './helpers';
import { type IExampleNotificationOptions, type INotification } from './interfaces';

function* handleShowNotification(action: IActionWithPayload<string, INotification>): SagaIterator {
  const { payload: notificationOptions } = action;

  const hasNotificationSupport = yield call(areNotificationsSupported);
  if (!hasNotificationSupport) {
    return;
  }

  yield call(showNotification, notificationOptions);
}

function* handleCancelNotification(action: IActionWithPayload<string, string>): SagaIterator {
  const { payload: notificationId } = action;
  yield call(cancelNotification, notificationId);
}

function* handleCancelSomeNotifications(action: IActionWithPayload<string, string[]>): SagaIterator {
  const { payload: notificationTags } = action;
  yield call(cancelSomeNotifications, notificationTags);
}

function* handleCancelAllNotifications(): SagaIterator {
  yield call(cancelAllNotifications);
}

function* handleExampleNotification(action: IActionWithPayload<string, IExampleNotificationOptions>): SagaIterator {
  const { type, mute } = action.payload;
  const settings: Notifications = yield select(getLoggedInAgentNotifications);

  let browserNotificationsOptions = {};
  const soundNotificationOptions = {
    sound: type,
    mute,
    repeat: settings.repeat_sound_notifications && type === SoundNotification.Message,
  };

  switch (type) {
    case SoundNotification.Message:
      browserNotificationsOptions = {
        title: '[Test] Mary Brown says...',
        text: "Hi, I'm going to buy your nike shoes. Do you have...",
        tag: 'test_notification',
        onClose: yield put(SoundNotificationActions.stopRepeatSound()),
      };
      break;

    case SoundNotification.IncomingChat:
      browserNotificationsOptions = {
        title: '[Test] Incoming chat',
        text: 'Mary Brown',
        tag: 'test_notification',
      };
      break;

    case SoundNotification.QueuedVisitor:
      browserNotificationsOptions = {
        title: '[Test] Queued visitor',
        text: 'Mary Brown',
        tag: 'test_notification',
      };
      break;

    case SoundNotification.NewVisitor:
      browserNotificationsOptions = {
        title: '[Test] New visitor',
        text: 'Mary Brown',
        tag: 'test_notification',
      };
      break;

    case SoundNotification.ReturningVisitor:
      browserNotificationsOptions = {
        title: '[Test] Returning visitor',
        text: 'Mary Brown',
        tag: 'test_notification',
      };
      break;

    case SoundNotification.NewTicket:
      browserNotificationsOptions = {
        title: '[Test] New ticket',
        tag: 'test_notification',
      };
      break;

    // no default
  }

  yield put(BrowserNotificationsActions.show(browserNotificationsOptions as INotification));
  yield put(SoundNotificationActions.playSound(soundNotificationOptions));
}

export default function* browserNotificationsSaga(): SagaIterator {
  yield takeEvery(BrowserNotificationsActionNames.SHOW_NOTIFICATION, handleShowNotification);
  yield takeEvery(BrowserNotificationsActionNames.CANCEL_NOTIFICATION, handleCancelNotification);
  yield takeEvery(BrowserNotificationsActionNames.CANCEL_SOME_NOTIFICATIONS, handleCancelSomeNotifications);
  yield takeEvery(BrowserNotificationsActionNames.CANCEL_ALL_NOTIFICATIONS, handleCancelAllNotifications);
  yield takeEvery(BrowserNotificationsActionNames.SHOW_EXAMPLE_NOTIFICATION, handleExampleNotification);
}
