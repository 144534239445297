import type { Scope } from 'constants/scope';
import type { KeyMap } from 'helpers/interface';

export enum RoleType {
  Primary = 'primary',
  Secondary = 'secondary',
  Custom = 'custom',
}

export enum InvitationMode {
  Strict = 'strict',
  Lax = 'lax',
}

export interface IAgentRole {
  roleId: string;
  predefined: boolean;
  roleType: RoleType;
  roleName: string;
  scopes: Scope[];
}

export interface IAccount {
  accountId: string;
  email: string;
  name: string;
  roles: string[];
  createdAt: string;
  mfaEnabled?: boolean;
}

export interface IAccountsState {
  roles: KeyMap<IAgentRole>;
  accounts: KeyMap<IAccount>;
  currentOrganizationId: string;
  invitationMode: InvitationMode;
}
