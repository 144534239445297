export enum BaseRouteName {
  Home = 'home',
  Customers = 'customers',
  Visitors = 'visitors',
  Reports = 'reports',
  Tickets = 'tickets',
  Traffic = 'traffic',
  Settings = 'settings',
  Subscription = 'subscription',
  Team = 'team',
  Chats = 'chats',
  Engage = 'engage',
  App = 'app',
}
