export const BILLING_OPTIONS = {
  monthly: {
    key: 'billed-monthly',
    name: 'Billed monthly',
  },
  annually: {
    key: 'billed-annual',
    name: 'Billed annually',
  },
};
