import type { FC } from 'react';

import { Text } from '@livechat/design-system-react-components';

import { useOneClickPaymentContext } from 'routes/apps/one-click-payment/hooks/use-one-click-payment-context';

import { BillingCyclePicker } from './BillingCyclePicker/BillingCyclePicker';
import { DeveloperDetails } from './DeveloperDetails';
import { PriceSummary } from './PriceSummary';

import * as styles from './styles';

type Props = {
  hidePicker?: boolean;
};

export const PurchaseItem: FC<Props> = ({ hidePicker }) => {
  const { item, charge } = useOneClickPaymentContext();
  const name = charge?.name || item?.name || '';

  return (
    <div className={styles.wrapper}>
      <img className={styles.icon} src={item?.icons?.small} alt={`${name} logo`} />

      <div className={styles.productDetails}>
        <Text size="md" bold>
          {name}
        </Text>

        {item?.ownerId && <DeveloperDetails developerId={item?.ownerId} />}
      </div>

      <div className={styles.price}>
        <PriceSummary />
      </div>

      {!hidePicker && (
        <div className={styles.picker}>
          <BillingCyclePicker />
        </div>
      )}
    </div>
  );
};
