import { createConfigurationApiClient } from '../client';
import { type ConfigurationApiErrorResponse } from '../types';

import {
  type UpdateLicensePropertiesResponse,
  type UpdateGroupPropertiesRequest,
  type UpdateGroupPropertiesResponse,
  type ListGroupsPropertiesResponse,
  type ListLicensePropertiesRequest,
  type ListGroupsPropertiesRequest,
  type ListLicensePropertiesResponse,
  type UpdateLicensePropertiesRequest,
} from './types';

const client = createConfigurationApiClient();

/**
 * Configuration API properties client.
 * @see {@link https://platform.text.com/docs/management/configuration-api#properties}
 */
export const propertiesClient = {
  /**
   * Lists all license properties.
   * @see {@link https://platform.text.com/docs/management/configuration-api#list-license-properties}
   */
  listLicense: async (data: ListLicensePropertiesRequest) => {
    return client.post<ListLicensePropertiesResponse, ConfigurationApiErrorResponse>('list_license_properties', {
      json: data,
    });
  },

  /**
   * Updates license properties.
   * @see {@link https://platform.text.com/docs/management/configuration-api#update-license-properties}
   */
  updateLicense: async (data: UpdateLicensePropertiesRequest) => {
    return client.post<UpdateLicensePropertiesResponse, ConfigurationApiErrorResponse>('update_license_properties', {
      json: data,
    });
  },

  /**
   * Lists all group properties.
   * @see {@link https://platform.text.com/docs/management/configuration-api#list-group-properties}
   */
  listGroups: async (params: ListGroupsPropertiesRequest) => {
    return client.post<ListGroupsPropertiesResponse, ConfigurationApiErrorResponse>('list_groups_properties', {
      json: params,
    });
  },

  /**
   * Updates group properties.
   * @see {@link https://platform.text.com/docs/management/configuration-api#update-group-properties}
   */
  updateGroup: async (data: UpdateGroupPropertiesRequest) => {
    return client.post<UpdateGroupPropertiesResponse, ConfigurationApiErrorResponse>('update_group_properties', {
      json: data,
    });
  },
};
