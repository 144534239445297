import { ThreeStateFeatureFlag } from 'helpers/get-status-from-feature-flag';

export type ForcedFeatureValue = 'enabled' | 'disabled' | 'none';

export const FORCED_FEATURE_MAPPER: Record<ThreeStateFeatureFlag, ForcedFeatureValue> = {
  [ThreeStateFeatureFlag.Inherited]: 'none',
  [ThreeStateFeatureFlag.Enabled]: 'enabled',
  [ThreeStateFeatureFlag.Disabled]: 'disabled',
};

export enum AIHubEvent {
  ModalOpened = 'Modal opened',
  FeatureToggled = 'Early access feature toggled',
  FeatureClicked = 'Feature button clicked',
  UpgradeClicked = 'Upgrade button clicked',
}

export const LOCK_ICON_TEST_ID = 'lock-icon';

export const AI_HUB_MODAL_TEST_ID = 'ai-hub-modal';

export const AI_HUB_ITEM_TEST_ID = 'ai-hub-item';

export const ONE_LOGO = 'https://cdn.livechat-static.com/api/file/lc/img/default/assets/one.png';

export const REQUEST_NEW_FEATURE_FORM_LINK =
  'https://docs.google.com/forms/d/1cKBH8V-8LA1tqGrVHIuQ1HCBDOWIYeAZW5oVzMgE_x4/viewform';
