/* eslint-disable @typescript-eslint/naming-convention */
import { type AgentType } from 'constants/agent-type';
import { type Permission } from 'constants/permission';
import { type Role } from 'constants/role';
import { getConfig } from 'helpers/config';
import { type ICurrentLicense } from 'interfaces/entities/current-license';
import { getItem, LocalStorageKey } from 'services/local-storage';
import { type IAgent } from 'store/entities/agents/interfaces';

const beamerProductId = getConfig().beamerId;
const isLivechatUser = true;

export interface IBeamer {
  product_id: string;
  license_id: number;
  license_type: string;
  license_plan: string;
  company_name: string;
  user_email?: string;
  user_id: string;
  user_role?: Role;
  user_permission?: Permission;
  user_name: string;
  user_type: AgentType;
  user_livechat: boolean;
  user_helpdesk: boolean;
  button: boolean;
}

interface IBeamerOptions {
  agent: IAgent;
  license: ICurrentLicense;
  companyName: string;
  isHelpdeskUser: boolean;
}

export function initBeamer({ agent, license, companyName, isHelpdeskUser }: IBeamerOptions): void {
  if (getItem(LocalStorageKey.BeamerHidden)) {
    return;
  }
  if (!beamerProductId) {
    return;
  }

  window.beamer_config = {
    product_id: beamerProductId,
    //---------------Visitor Information---------------
    license_id: license?.licenseId,
    license_plan: license?.plan,
    license_type: license?.type,
    company_name: companyName,
    user_email: agent.email,
    user_id: agent.login,
    user_role: agent.role,
    user_permission: agent.permission,
    user_name: agent.name,
    user_type: agent.type,
    user_livechat: isLivechatUser,
    user_helpdesk: isHelpdeskUser,
    button: false,
  };

  const script = document.createElement('script');
  script.src = 'https://app.getbeamer.com/js/beamer-embed.js';
  script.type = 'text/javascript';
  script.defer = true;

  const head = document.getElementsByTagName('head')[0];
  head.appendChild(script);
}
