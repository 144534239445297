import { MINUTE_IN_SECONDS } from 'constants/time-units';
import { formatTime } from 'helpers/format-time';
import { getFormattedPercent } from 'helpers/get-formatted-percent';
import { round } from 'helpers/numbers';
import { getSeriesDataFromPeriod } from 'helpers/reports';
import { DEFAULT_SERIES_COLOR } from 'constants/reports/chart-color-palette';

import type { IQueuedCustomersData, IQueuedCustomerPeriodData, IChatPeriodData } from '../../../../interfaces/reports';

const SECONDS_IN_HOUR = 60 * 60;

export function queuedCustomersDeserializer({ data }: any): IQueuedCustomersData {
  const queuedCustomers = getSeriesDataFromPeriod<IQueuedCustomerPeriodData>(data.queuedCustomers, 'queued').map(
    (el) => el.count
  );
  const leftTheQueue = getSeriesDataFromPeriod<IQueuedCustomerPeriodData>(data.queuedCustomers, 'left_queue').map(
    (el) => el.count
  );
  const enteredTheChat = getSeriesDataFromPeriod<IQueuedCustomerPeriodData>(data.queuedCustomers, 'entered_chat').map(
    (el) => el.count
  );

  const queuedCustomersSum = queuedCustomers.reduce((sum, current) => sum + current, 0);
  const leftTheQueueSum = leftTheQueue.reduce((sum, current) => sum + current, 0);
  const enteredTheChatSum = enteredTheChat.reduce((sum, current) => sum + current, 0);

  const enterChatTimePeriod = getSeriesDataFromPeriod<IChatPeriodData>(data.waitingTime, 'entered_chat');
  const leftQueueTimePeriod = getSeriesDataFromPeriod<IChatPeriodData>(data.waitingTime, 'left_queue');

  const timeToEnterChat = enterChatTimePeriod.map((el) => el.average.seconds);
  const timeToLeftQueue = leftQueueTimePeriod.map((el) => el.average.seconds);

  const enterChatSum = enterChatTimePeriod.reduce((sum, el) => sum + (el.average.seconds || 0) * el.count, 0);
  const leftQueueSum = leftQueueTimePeriod.reduce((sum, el) => sum + (el.average.seconds || 0) * el.count, 0);

  const enterChatCountSum = enterChatTimePeriod.reduce((sum, el) => sum + el.count, 0);
  const leftQueueCountSum = leftQueueTimePeriod.reduce((sum, el) => sum + el.count, 0);

  const averageTimeToEnterChat = enterChatSum / (enterChatCountSum || 1);
  const averageTimeToLeftQueue = leftQueueSum / (leftQueueCountSum || 1);

  return {
    queuedCustomers: {
      summary: [
        {
          name: 'Queued customers',
          color: DEFAULT_SERIES_COLOR,
          value: queuedCustomersSum,
        },
        {
          name: 'Entered the chat',
          color: DEFAULT_SERIES_COLOR,
          value: enteredTheChatSum,
          label: `(${getFormattedPercent(enteredTheChatSum / queuedCustomersSum, 1)})`,
        },
        {
          name: 'Left the queue',
          color: DEFAULT_SERIES_COLOR,
          value: leftTheQueueSum,
          label: `(${getFormattedPercent(leftTheQueueSum / queuedCustomersSum, 1)})`,
        },
      ],
      labels: Object.keys(data.queuedCustomers),
      series: [
        {
          name: 'Queued customers',
          color: DEFAULT_SERIES_COLOR,
          data: queuedCustomers,
        },
        {
          name: 'Entered the chat',
          color: DEFAULT_SERIES_COLOR,
          data: enteredTheChat,
        },
        {
          name: 'Left the queue',
          color: DEFAULT_SERIES_COLOR,
          data: leftTheQueue,
        },
      ],
    },
    waitingTime: {
      summary: [
        {
          name: 'Time to enter the chat',
          color: DEFAULT_SERIES_COLOR,
          value: formatTime(averageTimeToEnterChat / SECONDS_IN_HOUR),
        },
        {
          name: 'Time to leave the queue',
          color: DEFAULT_SERIES_COLOR,
          value: formatTime(averageTimeToLeftQueue / SECONDS_IN_HOUR),
        },
      ],
      labels: Object.keys(data.waitingTime),
      series: [
        {
          name: 'Time to enter the chat',
          color: DEFAULT_SERIES_COLOR,
          data: timeToEnterChat.map((el) => round(el / MINUTE_IN_SECONDS, 2)),
        },
        {
          name: 'Time to leave the queue',
          color: DEFAULT_SERIES_COLOR,
          data: timeToLeftQueue.map((el) => round(el / MINUTE_IN_SECONDS, 2)),
        },
      ],
    },
  };
}
