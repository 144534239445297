import { type FC, useContext } from 'react';

import { cx } from '@emotion/css';
import { Info } from '@livechat/design-system-icons';
import { Modal, ModalHeader, Button, Text } from '@livechat/design-system-react-components';

import { type ModalProps } from 'components/apps/interfaces';
import { navigate } from 'helpers/routing';
import { WidgetModalContext } from 'hooks/fullscreen-widgets/use-widget-modal-context';

import * as styles from './styles';

export const UpdateSubscriptionModal: FC<ModalProps> = ({ closeModal }) => {
  const widgetContext = useContext(WidgetModalContext);

  const navigateToSubscription = (): void => {
    navigate('/subscription');
    closeModal();
  };

  return (
    <Modal
      className={cx(styles.modal, 'upgrades-modal')}
      onClose={closeModal}
      heading={
        <ModalHeader
          title="Please update your subscription"
          iconProps={{
            source: Info,
            kind: 'primary',
            size: 'medium',
          }}
        />
      }
      footer={
        <div className={styles.footer}>
          <Button kind="secondary" onClick={closeModal}>
            {widgetContext ? 'Back' : 'Close'}
          </Button>
          <Button kind="primary" onClick={navigateToSubscription}>
            Go to subscription
          </Button>
        </div>
      }
    >
      <Text>
        To use paid In-App Upgrades, your license has to be subscribed to one of our plans. Please provide credit card
        details there.
      </Text>
    </Modal>
  );
};
