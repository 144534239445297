import { type KeyMap } from 'helpers/interface';
import type { RequestResult } from 'interfaces/api/client';
import type { LicenseProperties } from 'interfaces/license-properties';

import { BaseApi } from '../base-api';

import { type ISetLicenseCustomPropertyBody } from './interfaces';

export class LicensePropertiesApi extends BaseApi {
  protected readonly prefix = 'properties/license';

  /**
   * Fetches all properties for license.
   * @see https://github.com/livechat/livechat-docs/blob/master/livechat2/rest-api/docs/properties.md#list-all-license-properties
   */
  fetchAll = (): Promise<RequestResult<KeyMap<string>>> => this.get();

  /**
   * Sets custom property with a new value.
   * @param payload Custom property details to be saved.
   * @see https://github.com/livechat/livechat-docs/blob/master/livechat2/rest-api/docs/properties.md#set-custom-license-property
   */
  setCustom = (payload: ISetLicenseCustomPropertyBody): Promise<RequestResult<KeyMap<never>>> =>
    this.post(`custom/${payload.name}`, payload);

  /**
   * Sets license property with a new value.
   * @param payload License property details to be saved.
   * @see https://github.com/livechat/livechat-docs/blob/master/livechat2/rest-api/docs/properties.md#update-license-properties
   */
  updateLicenseProps = (payload: Partial<LicenseProperties>): Promise<RequestResult<KeyMap<string>>> =>
    this.put('', payload);

  /**
   * Fetches custom properties for license.
   * @see https://github.com/livechat/livechat-docs/blob/master/livechat2/rest-api/docs/properties.md#list-custom-license-properties
   */
  fetchAllCustom = (): Promise<RequestResult<KeyMap<string>>> => this.get('custom');

  /**
   * Fetches experiments for license.
   * @see https://github.com/livechat/livechat-docs/blob/master/livechat2/rest-api/docs/properties.md#list-custom-license-properties
   */
  fetchExperiments = (): Promise<RequestResult<KeyMap<string>>> => this.get('custom', { category: 'experiment' });

  /**
   * Fetches metrics for license.
   */
  fetchMetrics = (): Promise<RequestResult<KeyMap<string>>> => this.get('metrics');
}
