import {
  format,
  differenceInDays,
  differenceInCalendarDays,
  isSameDay,
  isAfter,
  getDay,
  addDays,
  startOfWeek,
  parse,
  formatDistance,
  formatDistanceStrict,
  isValid,
  getUnixTime,
  fromUnixTime,
  parseISO,
  subDays,
} from 'date-fns';
import isString from 'lodash.isstring';

import { type Interval, DateFormat, DAY_SUNDAY_INDEX } from 'constants/date';
import { type DayIndex } from 'interfaces/date';

type DateFormatInput = Parameters<typeof format>[0];
type FormatString = Parameters<typeof format>[1];
type FormatOptions = Parameters<typeof format>[2];

export function dateDifference(earlierDate: Date, laterDate: Date, interval: Interval, roundUp = false): number {
  switch (interval) {
    default:
      return roundUp ? differenceInCalendarDays(laterDate, earlierDate) : differenceInDays(laterDate, earlierDate);
  }
}

export function timeAgo(
  earlierDate: Date,
  laterDate: Date,
  opts?: {
    includeAgo?: boolean;
    dateFormat?: string;
    useStrict?: boolean;
  }
): string {
  const config = {
    includeAgo: true,
    dateFormat: 'MMMM d, yyyy',
    useStrict: true,
    ...opts,
  };

  if (earlierDate.getTime() > laterDate.getTime()) {
    return 'just now';
  }

  const diffInDays = differenceInCalendarDays(laterDate, earlierDate);
  if (diffInDays === 0) {
    if (!config.useStrict) {
      return formatDistance(earlierDate, laterDate, {
        addSuffix: config.includeAgo,
      });
    }

    return formatDistanceStrict(earlierDate, laterDate, {
      addSuffix: config.includeAgo,
      roundingMethod: 'floor',
    });
  }
  if (diffInDays === 1) {
    return 'yesterday';
  }

  return format(earlierDate, config.dateFormat);
}

export function prettyDateDifference(earlierDate: Date, laterDate: Date, shouldShowSeconds = true): string {
  const diff = Math.abs(Math.floor((laterDate.getTime() - earlierDate.getTime()) / 1000));
  const days = Math.floor((diff / 86400) % 86400);
  const hours = Math.floor((diff / 3600) % 3600);
  const minutes = Math.floor((diff / 60) % 60);
  const seconds = diff % 60;

  if (days > 2) {
    return `${days} days`;
  }

  let result = '';
  if (hours) {
    result += `${hours}h `;
  }

  if ((hours && !minutes) || minutes) {
    result += `${minutes}m `;
  }

  if (shouldShowSeconds) {
    result += `${seconds}s`;

    return result;
  }

  if (result === '') {
    return 'less than a minute';
  }

  return result.trim();
}

/**
 * Checks if a given timestamp is in milliseconds.
 * @param {number} timestamp - The timestamp to check.
 * @returns {boolean} Returns true if the timestamp is in milliseconds, false otherwise.
 */
function isMillisecondsTimestamp(timestamp: number): boolean {
  return String(timestamp).length === 13;
}

/**
 * Checks if a given timestamp is in seconds.
 * @param {number} timestamp - The timestamp to check.
 * @returns {boolean} Returns true if the timestamp is in seconds, false otherwise.
 */
function isSecondsTimestamp(timestamp: number): boolean {
  return String(timestamp).length === 10;
}

/**
 * Converts a given timestamp to Unix timestamp (seconds since the Unix Epoch).
 * If the timestamp is already in Unix format, it returns the timestamp unchanged.
 * @param {number} timestamp - The timestamp to convert.
 * @returns {number} The Unix timestamp.
 */
export function convertToUnixTimestamp(timestamp: number): number {
  if (isMillisecondsTimestamp(timestamp)) {
    return getUnixTime(timestamp);
  }

  return timestamp;
}

/**
 * Converts a given Unix timestamp to a Date object.
 * If the timestamp is not in Unix format, it creates a Date object directly from the timestamp.
 * @param {number} timestamp - The Unix timestamp to convert.
 * @returns {Date} The Date object.
 */
export function secondsTimestampToDate(timestamp: number): Date {
  if (!isSecondsTimestamp(timestamp)) {
    return new Date(timestamp);
  }

  return fromUnixTime(timestamp);
}

export function duration(totalSeconds: number): string {
  const hoursInSeconds = 3600;
  const minuteInSeconds = 60;
  const hours = Math.floor(totalSeconds / hoursInSeconds);
  const divisorForMinutes = totalSeconds % hoursInSeconds;
  const minutes = Math.floor(divisorForMinutes / minuteInSeconds);
  const divisorForSeconds = divisorForMinutes % minuteInSeconds;
  const seconds = Math.ceil(divisorForSeconds);

  let durationResult = `${seconds} s`;
  if (minutes > 0) {
    durationResult = `${minutes} min ${durationResult}`;
  }
  if (hours > 0) {
    durationResult = `${hours} h ${durationResult}`;
  }

  return durationResult;
}

export const isDateWithinRange = (date: Date, range: { from?: Date; to?: Date }): boolean => {
  const { from, to } = range;
  if (to && !isSameDay(date, to) && isAfter(date, to)) {
    return false;
  }
  if (from && !isSameDay(date, from) && !isAfter(date, from)) {
    return false;
  }

  return true;
};

const weekdays = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

export function getWeekdayName(date: Date = new Date()): string {
  return weekdays[getDay(date)];
}

export function getDateByWeekday(day: string): Date {
  return addDays(startOfWeek(new Date()), weekdays.indexOf(day));
}

/**
 * Adjusts a given date to a specified time zone.
 *
 * @param {Date} date - The date to be adjusted.
 * @param {string} timeZone - The target time zone. This should be a string representing a valid IANA time zone.
 *
 * @returns {Date} - The adjusted date in the specified time zone.
 *
 * @example
 *
 * const date = new Date('2022-01-01T00:00:00Z');
 * const timeZone = 'America/Los_Angeles';
 * const adjustedDate = adjustDateToTimeZone(date, timeZone);
 * // adjustedDate will be '2021-12-31T16:00:00.000Z' because Los Angeles is 8 hours behind UTC
 */
function adjustDateToTimeZone(date: Date, timeZone: string): Date {
  const formatter = new Intl.DateTimeFormat('en-US', {
    timeZone,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  });

  const validParts: Record<string, boolean> = {
    year: true,
    month: true,
    day: true,
    hour: true,
    minute: true,
    second: true,
  };

  const dateParts = formatter.formatToParts(date).reduce((parts: Record<string, string>, part) => {
    if (validParts[part.type]) {
      parts[part.type] = part.value;
    }

    return parts;
  }, {});

  const dateString = `${dateParts.year}-${dateParts.month}-${dateParts.day}T${
    dateParts.hour === '24' ? '00' : dateParts.hour
  }:${dateParts.minute}:${dateParts.second}`;

  return new Date(dateString);
}

/**
 * Attempts to format the input date to a specified format and time zone.
 *
 * @param {string | number | Date} dateInput - The date to be formatted. This can be a string, a number (timestamp), or a Date object.
 * @param {string} targetFormat - The desired output format for the date. This should be a string that specifies the format, as used by `date-fns`'s `format` function.
 * @param {Object} options - An object containing additional options for the function.
 * @param {string} options.timeZone - The IANA time zone to which the date should be converted before formatting.
 *
 * @returns {string | null} - The formatted date string in the specified format and time zone. If an error occurs during the process (e.g., an invalid date or time zone was provided), the function returns null.
 *
 * @example
 *
 * const date = new Date();
 * const format = 'yyyy-MM-dd HH:mm:ss';
 * const timeZone = 'America/Los_Angeles';
 * const formattedDate = tryFormatToTimeZone(date, format, { timeZone });
 * // formattedDate will be the current date and time in Los Angeles, formatted as 'yyyy-MM-dd HH:mm:ss'
 */
export function tryFormatToTimeZone(
  dateInput: string | number | Date,
  targetFormat: string,
  options: { timeZone: string }
): string | null {
  try {
    const date = new Date(dateInput);
    const timeZoneDate = adjustDateToTimeZone(date, options.timeZone);

    return format(timeZoneDate, targetFormat);
  } catch {
    return null;
  }
}

/**
 * Parses a date string in various standard formats.
 * @param {string} dateString - The date string to parse.
 * @returns {Date | null} The parsed date, or null if the date string could not be parsed.
 */
export function parseDate(dateString: string): Date | null {
  const formats = [
    DateFormat.ISO8601Date,
    DateFormat.ISO8601DateTime,
    DateFormat.ISO8601DateTimeWithMillisAndTimezone,
    DateFormat.ISO8601YearMonth,
  ];

  for (const format of formats) {
    const date = parse(dateString, format, new Date(0));
    if (isValid(date)) {
      return date;
    }
  }

  const dateFromString = new Date(dateString);
  if (isValid(dateFromString)) {
    return dateFromString;
  }

  try {
    const dateNumber = parseInt(dateString, 10);
    const dateFromNumber = new Date(dateNumber);
    if (isValid(dateFromNumber)) {
      return dateFromNumber;
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn(`Failed to parse date string "${dateString}"`, error);

    return null;
  }

  return null;
}

/**
 * Formats a date into a string using the specified format. If the date is invalid, returns an empty string.
 * Useful for ensuring all dates displayed in the application are valid and consistently formatted.
 *
 * @param {DateFormatInput} date - The date to format, can be a Date object, a timestamp, or a string.
 * @param {FormatString} formatStr - The pattern to format the date into.
 * @param {FormatOptions} options - Optional settings for the format function, such as locale.
 * @returns {string} - The formatted date string or an empty string if the date is invalid.
 *
 * @example
 * ```
 * console.log(safeFormat(new Date(), "yyyy-MM-dd")); // Output: "2023-01-01"
 * console.log(safeFormat("invalid-date", "yyyy-MM-dd")); // Output: ""
 * ```
 */
export function safeFormat(date: DateFormatInput, formatStr: FormatString, options?: FormatOptions): string {
  const dateObj = isString(date) ? parseISO(date) : date;
  if (!isValid(dateObj)) {
    return '';
  }

  return format(dateObj, formatStr, options);
}

export function getPreviousSundayDate(date: Date): Date {
  const indexOfDay = getDay(date);
  const daysToSub = indexOfDay === DAY_SUNDAY_INDEX ? 7 : indexOfDay;

  return subDays(date, daysToSub);
}

export function getDateOfDayFromFinishedWeek(date: Date, desiredDayIndex: DayIndex, weeksAgo: number): Date {
  const lastSunday = getPreviousSundayDate(date);
  const finalWeeksAgo = weeksAgo === 0 ? 1 : weeksAgo;

  const desiredWeekSunday = subDays(lastSunday, 7 * (finalWeeksAgo - 1));

  // if desired day was Sunday then we already got it
  if (desiredDayIndex === 0) {
    return desiredWeekSunday;
  }

  return subDays(desiredWeekSunday, 7 - desiredDayIndex);
}

export function convertSecondsToDays(seconds: string | number): number {
  return Number(seconds) / (60 * 60 * 24);
}
