// @ts-strict-ignore
/* eslint-disable @typescript-eslint/naming-convention */
import { type SagaIterator } from 'redux-saga';
import { all, select, call, put, cancel, takeEvery, takeLatest } from 'redux-saga/effects';

import { Cookie } from 'constants/cookies';
import { GlobalModal } from 'constants/global-modal';
import { ToastContent } from 'constants/toasts';
import { EventPlace } from 'helpers/analytics';
import { getCookie, setCookie } from 'helpers/cookies';
import { isMobileView } from 'helpers/device';
import { getToastContent } from 'helpers/toast';
import type { RequestResult } from 'interfaces/api/client';
import { type ICompanyDetails } from 'interfaces/entities/company-details';
import { AmplitudeUserProperty, setUserPropertiesForAmplitude } from 'services/amplitude';
import { ApiManager } from 'services/api/api-manager';
import CompanyDetailsSerializer from 'services/api/company-details/serializer';
import { trackEvent } from 'services/event-tracking';
import { GlobalModalActions } from 'store/features/global-modals/actions';
import { SessionActions } from 'store/features/session/actions';
import { getCanManageCompanyDetails } from 'store/features/session/selectors';
import { ToastsActions } from 'store/features/toasts/actions';
import { ToastVariant } from 'store/features/toasts/interfaces';
import { type IActionWithPayload } from 'store/helper';
import { OnboardingActions } from 'store/views/onboarding/actions';
import { getIsOnboardingFinished } from 'store/views/onboarding/selectors';

import { SubscriptionActions } from '../subscription/actions';

import { CompanyDetailsActions, CompanyDetailsActionNames } from './actions';
import { type IUpdateCompanyDetailsPayload } from './interfaces';
import { shouldShowCompanyDetailsModal, getCompanyDetails } from './selectors';

export function* fetchCompanyDetails(): IterableIterator<any> {
  const { result, error }: RequestResult<any> = yield call(ApiManager.companyDetailsApi.fetch);
  const isOnboardingFinished = yield select(getIsOnboardingFinished);

  if (result) {
    const companyDetailsData: ICompanyDetails = CompanyDetailsSerializer.deserialize(result);

    if (companyDetailsData.size && !isOnboardingFinished) {
      yield put(OnboardingActions.setIsCompanySizeDetected({ isCompanySizeDetected: true }));
      trackEvent('Company size step skipped', EventPlace.Onboarding);
    }

    yield put(CompanyDetailsActions.fetchSuccess(companyDetailsData));
  } else {
    yield put(CompanyDetailsActions.fetchFailure({ error: error.message }));
  }
}

function* fetchCompanyDetailsSuccess(): IterableIterator<any> {
  const companyDetails: ICompanyDetails = yield select(getCompanyDetails);

  yield call(window.LiveChatWidget.call, 'update_session_variables', {
    og_cv_company_size: companyDetails?.size,
    og_cv_company_industry: companyDetails?.industry,
  });

  yield call(setUserPropertiesForAmplitude, {
    [AmplitudeUserProperty.CompanyDetailsCountry]: companyDetails?.country,
    [AmplitudeUserProperty.CompanyDetailsCity]: companyDetails?.city,
  });
}

export function* updateCompanyDetails(
  action: IActionWithPayload<string, IUpdateCompanyDetailsPayload>
): IterableIterator<any> {
  const data = CompanyDetailsSerializer.serialize(action.payload);
  const { result, error }: RequestResult<any> = yield call(ApiManager.companyDetailsApi.update, data);

  if (action.payload.invoiceEmail) {
    yield put(SubscriptionActions.subscriptionUpdated({ email: action.payload.invoiceEmail }));

    if (result) {
      yield put(CompanyDetailsActions.updateSuccess(action.payload));

      if (action.payload.showToast) {
        yield put(
          ToastsActions.createToast({
            content: getToastContent(ToastContent.COMPANY_DETAILS_UPDATE_SUCCESS),
            kind: ToastVariant.Success,
          })
        );
      }
    } else {
      yield put(CompanyDetailsActions.updateFailure({ error: error.message }));

      if (action.payload.showToast) {
        yield put(
          ToastsActions.createToast({
            content: getToastContent(ToastContent.COMPANY_DETAILS_UPDATE_FAILURE),
            kind: ToastVariant.Error,
          })
        );
      }
    }
  }

  if (result) {
    yield put(CompanyDetailsActions.updateSuccess(action.payload));
  } else {
    yield put(CompanyDetailsActions.updateFailure({ error: error.message }));
    yield cancel();
  }
}

function* showCompanyDetailsModalOnLogout(): IterableIterator<any> {
  if (isMobileView()) {
    yield put(SessionActions.agentLogout());

    return;
  }

  const canManageCompanyDetails = yield select(getCanManageCompanyDetails);

  if (!canManageCompanyDetails) {
    yield put(SessionActions.agentLogout());

    return;
  }

  const showCompanyDetailsModal = yield select(shouldShowCompanyDetailsModal);
  if (!showCompanyDetailsModal) {
    yield put(SessionActions.agentLogout());

    return;
  }

  const companyDetailsCookie = getCookie(Cookie.CompanyDetailsShown);
  const today = new Date().getTime();
  const hasShownCompanyDetailsInLast7Days = today - Number(companyDetailsCookie) < 604800000;

  if (!!companyDetailsCookie && hasShownCompanyDetailsInLast7Days) {
    yield put(SessionActions.agentLogout());

    return;
  }

  yield put(GlobalModalActions.showModal(GlobalModal.CompanyDetails, { isLoggingOut: true }));

  const newCookieValue = `${new Date().getTime()}`;
  setCookie(Cookie.CompanyDetailsShown, newCookieValue);
}

export function* companyDetailsSagas(): SagaIterator {
  yield all([
    takeEvery('APP_READY', fetchCompanyDetails),
    takeLatest(CompanyDetailsActionNames.FETCH_COMPANY_DETAILS_REQUEST, fetchCompanyDetails),
    takeLatest(
      [
        CompanyDetailsActionNames.FETCH_COMPANY_DETAILS_SUCCESS,
        CompanyDetailsActionNames.UPDATE_COMPANY_DETAILS_SUCCESS,
      ],
      fetchCompanyDetailsSuccess
    ),
    takeEvery(CompanyDetailsActionNames.UPDATE_COMPANY_DETAILS_REQUEST, updateCompanyDetails),
    takeEvery(CompanyDetailsActionNames.SHOW_MODAL_ON_LOGOUT, showCompanyDetailsModalOnLogout),
  ]);
}
