import { type FC } from 'react';

import { HelpDeskColored } from '@livechat/design-system-icons';
import { Button, Heading, Icon, Text } from '@livechat/design-system-react-components';

import img from 'assets/img/helpdesk-in-livechat/start-trial-graphic.png';
import { EventPlace } from 'helpers/analytics';
import { getHelpDeskAppId } from 'helpers/applications';
import { navigate } from 'helpers/routing';
import { useInstallTrustedApplication } from 'hooks/use-install-trusted-application';
import { trackEvent } from 'services/event-tracking';

import { BODY, BUTTON, HEADING } from './constants';

import * as styles from './styles';

export const HelpDeskInLivechat: FC = () => {
  const { isProcessing, install } = useInstallTrustedApplication();

  const handleInstallHelpDesk = (): void => {
    trackEvent('Start free trial clicked', EventPlace.HDMenuPromoPage);

    install(getHelpDeskAppId(), {
      onSuccess: () => {
        navigate('/app/helpdesk');
      },
    });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <Icon source={HelpDeskColored} size="xxlarge" className={styles.iconStyles} />
        <div className={styles.promo}>
          <Heading size="lg">{HEADING}</Heading>
          <Text>{BODY}</Text>
          <Button kind="primary" className={styles.buttonStyles} loading={isProcessing} onClick={handleInstallHelpDesk}>
            {BUTTON}
          </Button>
        </div>
      </div>
      <div className={styles.imageWrapper}>
        <img className={styles.imageStyles} src={img} alt="promo" />
      </div>
    </div>
  );
};
