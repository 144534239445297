import { type FC } from 'react';

import { cx } from '@emotion/css';
import { Close, Info, Minimize } from '@livechat/design-system-icons';
import { Button, Icon, Text, Tooltip } from '@livechat/design-system-react-components';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import OneTag from 'assets/img/automation/one-tag.svg';
import { OneTrackEvent } from 'constants/one-event';
import { EventPlace } from 'helpers/analytics';
import { trackEvent } from 'services/event-tracking';
import { getOneAllEvents } from 'store/entities/one/selectors';
import { OneViewActions } from 'store/views/one/actions';
import { getIsOneDetailsOpened } from 'store/views/one/selectors';

import {
  HEADER_TOOLTIP_HOVER_DELAY,
  ONE_CLOSE_MODAL_TEST_ID,
  ONE_MODAL_DETAILS_BTN_TEST_ID,
  ONE_TOGGLE_MODAL_BTN_TEST_ID,
} from './constants';

import * as styles from './styles';

export const OneFullscreenHeader: FC = () => {
  const dispatch = useDispatch();
  const isDetailsOpened = useSelector(getIsOneDetailsOpened);
  const location = useLocation();
  const oneAllEvents = useSelector(getOneAllEvents);

  const handleOpenDetails = (): void => {
    dispatch(OneViewActions.openDetails());
    trackEvent(OneTrackEvent.OpenDetails, EventPlace.One);
  };

  const handleCloseDetails = (): void => {
    dispatch(OneViewActions.closeDetails());
    trackEvent(OneTrackEvent.CloseDetails, EventPlace.One);
  };

  const handleCloseModal = (): void => {
    dispatch(OneViewActions.hideModal());
    trackEvent(OneTrackEvent.ChatClosed, EventPlace.One, {
      state: 'fullscreen',
      url: location?.pathname,
      messages: Object.keys(oneAllEvents).length,
    });
  };

  const toggleModal = (): void => {
    dispatch(OneViewActions.toggleExpand());
    trackEvent(OneTrackEvent.Resized, EventPlace.One, { resizedTo: 'popover' });
  };

  return (
    <div className={styles.headerWrapperExpanded}>
      <div>
        <Text as="h2">One</Text>
        <img className={styles.aiSvgFullwidth} src={OneTag} alt="One AI Tag" />
      </div>

      <div>
        <Tooltip
          hoverOnDelay={HEADER_TOOLTIP_HOVER_DELAY}
          kind="invert"
          triggerRenderer={
            <Button
              className={cx({ [styles.activeDetailsButton]: isDetailsOpened })}
              kind="plain"
              onClick={isDetailsOpened ? handleCloseDetails : handleOpenDetails}
              icon={<Icon className={styles.icon} source={Info} />}
              data-testid={ONE_MODAL_DETAILS_BTN_TEST_ID}
            />
          }
        >
          <Text>Details</Text>
        </Tooltip>

        <Tooltip
          hoverOnDelay={HEADER_TOOLTIP_HOVER_DELAY}
          kind="invert"
          triggerRenderer={
            <Button
              kind="plain"
              onClick={toggleModal}
              icon={<Icon className={styles.icon} source={Minimize} />}
              data-testid={ONE_TOGGLE_MODAL_BTN_TEST_ID}
            />
          }
        >
          <Text>Collapse</Text>
        </Tooltip>

        <Tooltip
          hoverOnDelay={HEADER_TOOLTIP_HOVER_DELAY}
          kind="invert"
          triggerRenderer={
            <Button
              kind="plain"
              onClick={handleCloseModal}
              icon={<Icon className={styles.icon} source={Close} />}
              data-testid={ONE_CLOSE_MODAL_TEST_ID}
            />
          }
        >
          <Text>Close</Text>
        </Tooltip>
      </div>
    </div>
  );
};
