import { Section } from 'constants/section';
import { anyToBoolean } from 'helpers/boolean';
import { getConfig } from 'helpers/config';
import { trackEventOncePerUniqueKey } from 'helpers/event-saver';
import { navigate } from 'helpers/routing';
import { ApplicationLoadSource } from 'services/api/integrations/interfaces';
import { AppStateProvider } from 'services/app-state-provider';
import { trackAppLoad } from 'services/tracking/application-usage-tracking';
import { getLoggedInAgentRole } from 'store/entities/agents/selectors';
import { appIdByWidgetIdSelector } from 'store/entities/applications/selectors';
import { AgentCustomPropertiesActions } from 'store/features/agent-custom-properties/actions';
import { AgentCustomPropertyName } from 'store/features/agent-custom-properties/interfaces';
import { getAgentCustomProperty } from 'store/features/agent-custom-properties/selectors';
import { getTrialDaysLeft } from 'store/features/session/selectors';
import { NavigationViewActions } from 'store/views/navigation/actions';

const helpDeskWidgetId = getConfig().helpDeskAppWidgetId;

const helpDeskWidgetClick = (): void => {
  const isHelpDeskPromoSeen = AppStateProvider.selectFromStore((store) =>
    anyToBoolean(getAgentCustomProperty(store, AgentCustomPropertyName.HelpDeskNavPromoSeen))
  );

  AppStateProvider.dispatch(
    NavigationViewActions.hideNavigationItemTooltip({
      itemId: helpDeskWidgetId,
    })
  );
  if (!isHelpDeskPromoSeen) {
    AppStateProvider.dispatch(
      AgentCustomPropertiesActions.setAgentCustomProperty({
        [AgentCustomPropertyName.HelpDeskNavPromoSeen]: '1',
      })
    );
  }
};

export const executeItemClick = async (
  path: string,
  sectionId: string,
  trackEngagementTitle?: string
): Promise<void> => {
  const currentAgentRole = AppStateProvider.selectFromStore(getLoggedInAgentRole);
  const trialDaysLeft = AppStateProvider.selectFromStore(getTrialDaysLeft);
  const appIdByWidgetId = AppStateProvider.selectFromStore(appIdByWidgetIdSelector);

  let trackingOptions = {};

  navigate(path);

  const applicationId = appIdByWidgetId[sectionId];

  if (applicationId) {
    await trackAppLoad(applicationId, ApplicationLoadSource.FullScreenApp);
  }

  if (trackEngagementTitle) {
    if ((sectionId as Section) === Section.Subscription) {
      trackingOptions = {
        ...trackingOptions,
        permission: currentAgentRole,
        trialDaysLeft,
      };
    }

    trackEventOncePerUniqueKey('menu-trigger', trackEngagementTitle, null, trackingOptions);
  }

  if (sectionId === helpDeskWidgetId) {
    helpDeskWidgetClick();
  }
};

export const handleItemClick = (...args: Parameters<typeof executeItemClick>): void => void executeItemClick(...args);
