import { buildAccountsUrl } from 'helpers/redirect-to-accounts';

import { MarketplaceAppsRoutes } from '../../constants';

export const openPopup = ({
  url,
  windowName,
  width = 500,
  height = 700,
  onClose,
}: {
  url: string;
  windowName: string;
  width?: number;
  height?: number;
  onClose?: () => void;
}): Window | null => {
  const LeftPosition = window.screen.width ? (window.screen.width - width) / 2 : 0;
  const TopPosition = window.screen.height ? (window.screen.height - height) / 2 : 0;
  const settings = `height=${height},width=${width},top=${TopPosition},left=${LeftPosition},scrollbars=true,resizable`;

  const popup = window.open(url, windowName, settings);

  if (onClose) {
    const timer = setInterval(() => {
      if (popup?.closed) {
        clearInterval(timer);
        onClose();
      }
    }, 500);
  }

  return popup;
};

export const handleDirectInstallationUrlFlow = ({
  name,
  directInstallUrl,
  clientId,
  onClose,
}: {
  name: string;
  directInstallUrl?: string;
  clientId: string;
  onClose?: () => void;
}): void => {
  if (!directInstallUrl) {
    return;
  }

  const flow = 'code';
  const authorizedUrl = buildAccountsUrl({ redirectUri: directInstallUrl, clientId, responseType: flow });

  openPopup({
    url: authorizedUrl,
    windowName: name,
    onClose,
  });
};

export const getPathToApplications = (isPrivateApp: boolean, isPublished: boolean): string => {
  if (isPrivateApp) {
    return MarketplaceAppsRoutes.PrivateApps;
  } else if (!isPublished) {
    return MarketplaceAppsRoutes.InReviewApps;
  }

  return MarketplaceAppsRoutes.Apps;
};
