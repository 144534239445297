import { css } from '@emotion/css';
import { DesignToken } from '@livechat/design-system-react-components';

import { TOP_BAR_HEIGHT } from 'components/notifications-bar/constants';
import { NAVIGATION_BAR_WIDTH, SIDE_NAVIGATION_WIDTH } from 'constants/navigation';
import { NOTIFICATIONS_BAR_HEIGHT_WITH_MARGIN } from 'constants/notifications-bar';
import { SPOTLIGHT_MEDIA_QUERY, CONTENT_PAGE_MARGIN } from 'constants/spotlight';
import { isSpotlightEnabled } from 'helpers/spotlight';

export const router = (isMatch: boolean, hasNotifications = false): string => css`
  position: fixed;
  right: ${CONTENT_PAGE_MARGIN}px;
  bottom: ${CONTENT_PAGE_MARGIN}px;
  width: calc(100% - ${SIDE_NAVIGATION_WIDTH}px - ${NAVIGATION_BAR_WIDTH}px - ${CONTENT_PAGE_MARGIN}px);
  height: ${hasNotifications
    ? `calc(100% - ${TOP_BAR_HEIGHT} - ${CONTENT_PAGE_MARGIN}px - ${NOTIFICATIONS_BAR_HEIGHT_WITH_MARGIN})`
    : `calc(100% - ${TOP_BAR_HEIGHT} - ${CONTENT_PAGE_MARGIN}px)`};
  overflow: hidden;
  border-radius: ${isSpotlightEnabled ? 16 : 0}px;

  ${!isMatch &&
  `
  position: absolute;
  width: 0;
  height: 0;
  `}

  @media (max-width: ${SPOTLIGHT_MEDIA_QUERY}px) {
    border-radius: 0;
  }
`;

export const container = css`
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
`;

export const loader = css`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(${DesignToken.ContentLockedWhite});
`;

export const iframe = css`
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
`;
