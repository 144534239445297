import { css } from '@emotion/css';
import { DesignToken } from '@livechat/design-system-react-components';

export const filterButtonWrapper = css`
  padding: 0px 8px;
`;

export const withIcon = css`
  .icon {
    opacity: 1;
    visibility: visible;
  }
`;

export const withoutIcon = css`
  .remove {
    opacity: 1;
    visibility: visible;
  }
`;

export const removable = css`
  &:hover {
    .icon {
      opacity: 0;
      visibility: hidden;
    }

    .remove {
      opacity: 1;
      visibility: visible;
    }
  }
`;

export const filterButtonTitle = css`
  max-width: 190px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const bigFilterButtonTitle = css`
  max-width: 270px;
`;

export const tooltip = css`
  max-width: 290px;
`;

export const operatorsDropdown = css`
  margin-left: auto;
`;

export const header = (hasPreview: boolean) => css`
  display: flex;
  align-items: center;
  margin-bottom: ${hasPreview ? '12px' : '16px'};
`;

export const filterIcon = css`
  height: 20px;
`;

export const filterTooltipText = css`
  word-break: break-all;
`;

export const boxContainer = css`
  position: relative;
  min-width: 300px;
  padding: 10px 11px;
  max-width: 600px;
`;

export const body = css`
  font-size: 13px;
`;

export const footer = css`
  margin-top: 8px;
  box-sizing: border-box;
`;

export const title = css`
  margin-left: 7px;
  color: var(${DesignToken.ContentDefault});
  font-size: 13px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 0.3px;
`;

export const styledFilterTriggerWrapper = css`
  display: inline-flex;
`;
