import { type KeyMap } from 'helpers/interface';

import { type IToastDetails, type IToastsState } from './interfaces';

interface IWithToastsState {
  features: {
    toast: IToastsState;
  };
}

export function getToasts(state: IWithToastsState): KeyMap<IToastDetails> {
  return state.features.toast.items;
}
