import { type SagaIterator } from 'redux-saga';
import { all, fork } from 'redux-saga/effects';

import { facebookSaga } from 'store/entities/facebook/sagas';

import archivesViewSagas from './archives/sagas';
import { chatsViewSaga } from './chats/sagas';
import { customerDetailsSagas } from './customer-details/sagas';
import devToolsSagas from './dev-tools/sagas';
import fullscreenWidgetsViewSagas from './fullscreen-widgets/sagas';
import homeScreenSagas from './home-screen/sagas';
import { installCodeSaga } from './install-code/install-code-saga';
import { navigationSagas } from './navigation/sagas';
import { onboardingSaga } from './onboarding/sagas';
import reportsViewSagas from './reports/sagas';
import { settingsViewSaga } from './settings/sagas';
import { teamSagas } from './team/sagas';
import { ticketsViewSagas } from './tickets/sagas';
import { trafficSagas } from './traffic/sagas';

export function* viewSagas(): SagaIterator {
  yield all([
    fork(archivesViewSagas),
    fork(chatsViewSaga),
    fork(customerDetailsSagas),
    fork(devToolsSagas),
    fork(fullscreenWidgetsViewSagas),
    fork(homeScreenSagas),
    fork(installCodeSaga),
    fork(navigationSagas),
    fork(facebookSaga),
    fork(onboardingSaga),
    fork(reportsViewSagas),
    fork(settingsViewSaga),
    fork(teamSagas),
    fork(ticketsViewSagas),
    fork(trafficSagas),
  ]);
}
