import { type SagaIterator } from 'redux-saga';
import { put, select, takeLeading } from 'redux-saga/effects';

import { TopBarNotificationType } from 'constants/notifications';
import { type ISubscription } from 'interfaces/subscription';
import { getSubscriptionManagersLogins } from 'store/entities/accounts/selectors';
import { getIsNonProfit, getIsReseller, getPaymentRecurrentEnabled } from 'store/entities/license-properties/selectors';
import { SubscriptionActionNames } from 'store/entities/subscription/actions';
import { getSubscription } from 'store/entities/subscription/selectors';
import { NotificationsBarActions } from 'store/features/notifications-bar/actions';
import { getCanManageSubscription, getTrialDaysLeft, getIsOnTrial } from 'store/features/session/selectors';

function* toggleTrialExpiringTopBar(): SagaIterator {
  const isReseller = yield select(getIsReseller);
  const isTrial = yield select(getIsOnTrial);
  const subscription: ISubscription = yield select(getSubscription);
  const paymentRecurrentEnabled: boolean = yield select(getPaymentRecurrentEnabled);
  const isTrialWithCreatedSubscription = isTrial && paymentRecurrentEnabled;

  const isNonProfitLicense: boolean = yield select(getIsNonProfit);
  const isSubscriber = subscription && subscription.subscriber;

  if (isReseller || !isTrial || isSubscriber || isTrialWithCreatedSubscription || isNonProfitLicense) {
    yield put(NotificationsBarActions.hideNotificationsBar(TopBarNotificationType.TrialWarningImportant));
    yield put(NotificationsBarActions.hideNotificationsBar(TopBarNotificationType.TrialWarning));

    return;
  }

  const canManageSubscription = yield select(getCanManageSubscription);
  const viceOwnersEmails = yield select(getSubscriptionManagersLogins);
  const daysLeft: number = yield select(getTrialDaysLeft);

  const isTrialEnding = daysLeft <= 3;

  yield put(
    NotificationsBarActions.showNotificationsBar({
      name: isTrialEnding ? TopBarNotificationType.TrialWarningImportant : TopBarNotificationType.TrialWarning,
      props: { daysLeft, canManageSubscription, viceOwnersEmails },
    })
  );
}

export function* toggleTrialExpiringTopBarSaga(): SagaIterator {
  yield takeLeading(
    [SubscriptionActionNames.SUBSCRIPTION_CHANGED, SubscriptionActionNames.CREATE_SUBSCRIPTION_SUCCESS],
    toggleTrialExpiringTopBar
  );
}
