import type { FC, ReactElement } from 'react';

import { OpenInNew, ArrowForward, Add } from '@livechat/design-system-icons';
import { Button, Icon } from '@livechat/design-system-react-components';

import { navigate, openInNewCard } from 'helpers/routing';
import { trackEvent } from 'services/event-tracking';
import type { ButtonIconType, OneNavigateButton } from 'store/entities/one/interfaces';

import * as styles from './styles';

const iconMapping: Record<ButtonIconType, ReactElement> = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'arrow-forward': <Icon source={ArrowForward} />,
  add: <Icon source={Add} />,
};

export const NavigateButton: FC<OneNavigateButton> = ({
  url,
  content,
  isExternal,
  event,
  icon,
  kind,
  iconPosition,
}) => {
  const handleButtonClick = (): void => {
    if (event) {
      trackEvent(event.name, event.eventPlace, event?.eventProperties);
    }

    if (isExternal) {
      openInNewCard(url);

      return;
    }

    navigate(url);
  };

  const customIcon = icon ? iconMapping[icon] : undefined;
  const iconComponent = isExternal ? <Icon source={OpenInNew} /> : customIcon;

  return (
    <Button
      key={url}
      className={styles.navigateButton}
      kind={kind ?? 'secondary'}
      iconPosition={iconPosition ?? 'left'}
      onClick={handleButtonClick}
      icon={iconComponent}
    >
      {content}
    </Button>
  );
};
