import { type FC } from 'react';

import { getPriceInfo } from 'components/apps/helpers';
import { formatPrice } from 'helpers/subscription';

import { useResourceContext } from '../../hooks/use-resource-context';

import * as styles from './styles';

export const PriceCell: FC = () => {
  const { price } = useResourceContext();

  if (!price || !price?.cents) {
    return <p className={styles.priceFree}>Free</p>;
  }

  return (
    <>
      <p>${formatPrice(price.cents / 100)}</p>
      <span>{getPriceInfo(price)}</span>
    </>
  );
};
