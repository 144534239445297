import { type Dispatch, type FC, type SetStateAction } from 'react';

import { cx } from '@emotion/css';
import { Button, Modal, ModalHeader, Tab, TabsList, TabsWrapper } from '@livechat/design-system-react-components';

import { InAppUpgradeTable } from 'components/apps/in-app-upgrade-table/InAppUpgradeTable';
import { useWidgetModalContext } from 'hooks/fullscreen-widgets/use-widget-modal-context';
import type { INormalizedUpgrades } from 'routes/apps/apps-settings/applications/helpers';
import { tabsList } from 'routes/apps/apps-settings/applications/in-app-upgrades/InAppUpgrades';
import type { IInAppUpgrade } from 'store/entities/applications/interfaces';

import { type TabsStateType } from '../interfaces';

import * as styles from './styles';

interface IProps {
  data: INormalizedUpgrades | undefined;
  resources: IInAppUpgrade[];
  isLoading: boolean;
  tab: TabsStateType;
  setTab: Dispatch<SetStateAction<TabsStateType>>;
}

const InAppUpgradeTableModal: FC<IProps> = ({ data, resources, isLoading, tab, setTab }) => {
  const { closeModal } = useWidgetModalContext();

  return (
    <Modal
      heading={<ModalHeader title="In-App Upgrades" />}
      onClose={closeModal}
      className={cx(styles.tableModal, 'upgrades-modal')}
      contentClassName={styles.tableModalContent}
      footer={
        <div className={styles.footer}>
          <Button onClick={closeModal} kind="secondary">
            Close
          </Button>
        </div>
      }
    >
      <TabsWrapper>
        <TabsList className={styles.tabsList}>
          {tabsList.map(({ id, title }) => {
            const resourcesCounter = data?.[id]?.length;

            return (
              <Tab
                key={id}
                onClick={() => setTab(id)}
                isSelected={tab === id}
                count={resourcesCounter}
                disabled={!resourcesCounter}
              >
                {title}
              </Tab>
            );
          })}
        </TabsList>
      </TabsWrapper>
      <InAppUpgradeTable resources={resources} isLoading={isLoading} />
    </Modal>
  );
};

export default InAppUpgradeTableModal;
