import { type FC } from 'react';

import { cx } from '@emotion/css';

import { type IColLabelProps } from './interfaces';

import * as styles from './styles';

interface IProps extends IColLabelProps {
  onClick?: () => void;
  className?: string;
  title?: string;
}

/**
 * Column label for LightTable
 */
export const ColLabel: FC<IProps> = ({ children, active, desc, onClick, className, title }) => (
  <span className={cx(styles.colLabel({ active, desc }), className)} title={title} onClick={onClick}>
    {children}
  </span>
);
