import { FC } from 'react';

import { TourStep } from 'components/tours/TourStep';
import {
  FALLBACK_GROUP_IMG_URL,
  PRIORITY_IMG_URL,
  RULES_IMG_URL,
  TABLE_IMG_URL,
} from 'services/tour/routing-rules/constants';

export const RulesStep: FC = () => (
  <TourStep
    image={<img src={RULES_IMG_URL} width="272px" height="160px" alt="routing rules animation" />}
    title="What's a rule?"
    message="A rule says what happens if a customer meets a certain condition."
  />
);

export const TableHeaderStep: FC = () => (
  <TourStep
    image={<img src={TABLE_IMG_URL} width="272px" height="160px" alt="routing rules table header animation" />}
    title="Rules are listed in a table"
    message="This is where you get an overview of all your rules."
  />
);

export const PriorityStep: FC = () => (
  <TourStep
    image={<img src={PRIORITY_IMG_URL} width="272px" height="160px" alt="routing rules table sorting animation" />}
    title="Rules are run in priority order"
    message="Drag and drop rules to decide which will be applied in the first place."
  />
);

export const FallbackGroupStep: FC = () => (
  <TourStep
    image={
      <img src={FALLBACK_GROUP_IMG_URL} width="272px" height="160px" alt="routing rules fallback group animation" />
    }
    title="Have a backup plan"
    message="If a customer doesn't match any conditions, you can still route them to a group."
  />
);
