import { createSelector } from 'reselect';

import { PARTIAL_ONE_ASSISTANT_WELCOME_MESSAGE } from 'constants/one';
import { type KeyMap } from 'helpers/interface';
import { isOneMessageEvent } from 'helpers/one-guards';

import { buildEventGroups } from './helpers';
import { type IOneMessageEvent } from './interfaces';
import { getOneAllEvents, getOneGroupedEventsIds } from './selectors';

export const getOneMessageEvents = createSelector(getOneAllEvents, (allEvents) =>
  Object.entries(allEvents).reduce((acc, [eventId, event]) => {
    if (isOneMessageEvent(event)) {
      acc[eventId] = event;
    }

    return acc;
  }, {} as KeyMap<IOneMessageEvent>)
);

export const getOneGroupedEvents = createSelector(
  getOneGroupedEventsIds,
  getOneAllEvents,
  (groupedEventIds, oneEvents) => buildEventGroups(groupedEventIds, oneEvents)
);

export const getIsOnlyWelcomeMessage = createSelector(getOneMessageEvents, (events) => {
  const oneEvents = Object.values(events);

  if (!oneEvents.length || oneEvents.length >= 2) {
    return false;
  }

  return oneEvents[0].text.endsWith(PARTIAL_ONE_ASSISTANT_WELCOME_MESSAGE);
});
