/*
  TextEnhancement enum used by useTextEnhancement
  Each value in this enum should match name of method on MLGatewayApi class:
  `source/tsx/services/api/ml-gateway/ml-gateway-api.ts`
*/
export enum MLGatewayTextEnhancement {
  TextTranslation = 'createTextTranslation',
  TextSentiment = 'createTextSentiment',
  TextGrammar = 'createTextGrammarCorrection',
  TextReword = 'createTextReword',
}
