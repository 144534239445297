export const APP_SETTINGS_ROUTE = {
  settings: {
    id: 'settings',
    path: '/apps/settings/:slug',
    label: 'App Settings',
    exact: true,
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'in-app-upgrades': {
    id: 'in-app-upgrades',
    path: '/apps/settings/:slug/in-app-upgrades/:tab',
    label: 'In-App Upgrades',
  },
} as const;

export type AppSettingsRouteKeys = keyof typeof APP_SETTINGS_ROUTE;

export const IN_APP_UPGRADES_TAB = {
  all: {
    id: 'all',
    title: 'All',
  },
  purchased: {
    id: 'purchased',
    title: 'Purchased',
  },
} as const;
