import type { FC } from 'react';

import { Text } from '@livechat/design-system-react-components';
import { useDispatch, useSelector } from 'react-redux';

import { OneTrackEvent } from 'constants/one-event';
import { EventPlace } from 'helpers/analytics';
import { trackEvent } from 'services/event-tracking';
import { OneEntitiesActions } from 'store/entities/one/actions';
import { getIsOneDetailsOpened } from 'store/views/one/selectors';

import * as styles from './styles';

interface IProps {
  id: number;
  message: string;
  description: string;
  onClick?: () => void;
}

export const Suggestion: FC<IProps> = ({ id, message, description, onClick }) => {
  const dispatch = useDispatch();
  const isOneDetailsOpened = useSelector(getIsOneDetailsOpened);

  const handleSuggestionClick = (): void => {
    if (onClick) {
      onClick();
      trackEvent(OneTrackEvent.MessageSent, EventPlace.One, {
        source: 'suggestion chip',
        message,
        state: 'fullscreen',
      });

      return;
    }
    dispatch(OneEntitiesActions.sendOneMessage(message));
    trackEvent(OneTrackEvent.MessageSent, EventPlace.One, { source: 'suggestion chip', message, state: 'fullscreen' });
  };

  return (
    <div
      className={styles.suggestion(isOneDetailsOpened)}
      onClick={handleSuggestionClick}
      data-testid={`suggestion-${id}`}
    >
      <Text as="span">{message}</Text>

      <Text as="p">{description}</Text>
    </div>
  );
};
