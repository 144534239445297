import keyBy from 'lodash.keyby';

import { type SessionAction, SessionActionNames } from 'store/features/session/actions';

import { type AgentAction, AgentActionNames } from './actions';
import { type IAgentsState } from './interfaces';

export const initialState: IAgentsState = {
  agents: {},
  loggedInAgentLogin: '',
};

export const agentsReducer = (
  state: IAgentsState = initialState,
  action: AgentAction | SessionAction
): IAgentsState => {
  switch (action.type) {
    case AgentActionNames.AGENTS_FETCHED: {
      return {
        ...state,
        agents: {
          ...state.agents,
          ...keyBy(action.payload, 'login'),
        },
      };
    }

    case AgentActionNames.AGENT_ADD: {
      const payload = action.payload;

      return {
        ...state,
        agents: {
          ...state.agents,
          [payload.login]: { ...payload },
        },
      };
    }

    case AgentActionNames.AGENT_UPDATE: {
      const payload = action.payload;

      if (payload.login === undefined || state.agents[payload.login] === undefined) {
        return state;
      }

      return {
        ...state,
        agents: {
          ...state.agents,
          [payload.login]: {
            ...state.agents[payload.login],
            ...payload,
          },
        },
      };
    }

    case AgentActionNames.AGENT_ADD_TO_GROUP: {
      const payload = action.payload;

      return {
        ...state,
        agents: {
          ...state.agents,
          ...(state.agents[payload.login]
            ? {
                [payload.login]: {
                  ...state.agents[payload.login],
                  groups: [...state.agents[payload.login].groups, payload.groupId],
                },
              }
            : null),
        },
      };
    }

    case AgentActionNames.AGENT_REMOVE_FORM_GROUP: {
      const payload = action.payload;

      return {
        ...state,
        agents: {
          ...state.agents,
          ...(state.agents[payload.login]
            ? {
                [payload.login]: {
                  ...state.agents[payload.login],
                  groups: state.agents[payload.login].groups.filter((groupId) => groupId !== payload.groupId),
                },
              }
            : null),
        },
      };
    }

    case AgentActionNames.AGENT_REMOVE: {
      const payload = action.payload;
      const newAgents = { ...state.agents };
      delete newAgents[payload.login];

      return {
        ...state,
        agents: newAgents,
      };
    }

    case SessionActionNames.SAVE_AUTHENTICATION_CREDENTIALS: {
      return {
        ...state,
        loggedInAgentLogin: action.payload.login,
      };
    }

    default:
      return state;
  }
};
