export const enum UserGuidedTourType {
  ChatCannedResponses = 'ChatCannedResponses',
  CustomersList = 'CustomersList',
  CustomSegments = 'CustomSegments',
  TicketPrivacy = 'TicketPrivacy',
  TicketFormSettings = 'TicketFormSettings',
  TicketStatus = 'TicketStatus',
  TagsUsage = 'TagsUsage',
  TargetedMessages = 'TargetedMessages',
  HomeScreenExplore = 'HomeScreenExplore',
  OpenChat = 'OpenChat',
  UnassignedChat = 'UnassignedChat',
  RichGreeting = 'RichGreeting',
  MultipleGreetings = 'MultipleGreetings',
  ReportViews = 'ReportViews',
  RoutingRules = 'RoutingRules',
  Traffic = 'Traffic',
  AgentsPerformanceReport = 'AgentsPerformanceReport',
}
