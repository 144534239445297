import { type FC } from 'react';

import { Text } from '@livechat/design-system-react-components';

import { label } from 'components/side-navigation-v2/components/styles';

interface IProps {
  isActive?: boolean;
  isMainEntry?: boolean;
}
export const SideNavigationLabel: FC<IProps> = ({ children, isMainEntry, isActive }) => {
  return (
    <Text bold={isActive || isMainEntry} className={label(isActive, isMainEntry)}>
      {children}
    </Text>
  );
};
