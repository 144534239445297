// @ts-strict-ignore
// show plan price

import { PlanType, DisplayPlanType } from 'constants/plan-type';

interface ISubscriptionPlan {
  plan: PlanType;
  name: string;
  customersTrackingLimit?: number;
  overlayText?: string;
}

const subscriptionPlans: ISubscriptionPlan[] = [
  {
    plan: PlanType.Starter,
    name: 'Starter',
    customersTrackingLimit: 100,
  },
  {
    plan: PlanType.Team,
    name: 'Team',
    overlayText: 'Advanced customization, collaboration and flexibility for growing teams.',
    customersTrackingLimit: 400,
  },
  {
    plan: PlanType.Business,
    name: 'Business',
    overlayText: 'Detailed analytics, security, and control for high-volume teams.',
  },
  {
    plan: PlanType.EnterprisePlus,
    name: 'Enterprise',
  },
];

export function getSubscriptionPlanName(plan: PlanType): string {
  const foundSubscriptionPlan = subscriptionPlans.find((subscriptionPlan) => subscriptionPlan.plan === plan);

  return foundSubscriptionPlan ? foundSubscriptionPlan.name : null;
}

export function isLowerPlan(selectedPlan: PlanType, comparedPlan: PlanType): boolean {
  const keys = Object.values(PlanType);

  return keys.indexOf(selectedPlan) < keys.indexOf(comparedPlan);
}

export function isHigherPlan(selectedPlan: PlanType, comparedPlan: PlanType): boolean {
  const keys = Object.values(PlanType);

  return keys.indexOf(selectedPlan) > keys.indexOf(comparedPlan);
}

export function getHigherPlan(selectedPlan: PlanType): PlanType {
  const keys = Object.values(PlanType);

  return keys[keys.indexOf(selectedPlan) + 1];
}

export function getHigherPlanDisplayName(selectedPlan: PlanType): string {
  const plan = getHigherPlan(selectedPlan);

  return DisplayPlanType[plan];
}

export function getSubscriptionTrackingLimit(plan: PlanType): number {
  const foundSubscriptionPlan = subscriptionPlans.find((subscriptionPlan) => subscriptionPlan.plan === plan);

  return foundSubscriptionPlan ? foundSubscriptionPlan.customersTrackingLimit : null;
}

export function getIsOnePlanLevelDowngrade(newPlan: PlanType, oldPlan: PlanType): boolean {
  return getHigherPlan(newPlan) === oldPlan;
}
