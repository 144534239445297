import { type FC } from 'react';

import { Tooltip, Text, Button } from '@livechat/design-system-react-components';
import { useSelector } from 'react-redux';

import { getActionButtonCopy } from 'components/apps/helpers';
import useToggle from 'hooks/use-toggle';
import { getCanManageApplicationPayments } from 'store/features/session/selectors';

import { useResourceContext } from '../../hooks/use-resource-context';
import { useResourceModalContext } from '../../hooks/use-resource-modal-context';

import * as styles from './styles';

export const ActionCell: FC = () => {
  const { openModal } = useResourceModalContext();
  const { isActive, trialDays, totalPrice } = useResourceContext();

  const canManageApplications = useSelector(getCanManageApplicationPayments);

  const [isTooltipVisible, toggleTooltip] = useToggle();
  const toggleTooltipVisibility = (): void => {
    if (!canManageApplications) {
      toggleTooltip();
    }
  };
  const copy = getActionButtonCopy({ trialDays, totalPrice, isActive });

  const handleOpenModal = (): void => openModal();

  return (
    <Tooltip
      isVisible={isTooltipVisible}
      onClose={toggleTooltipVisibility}
      onOpen={toggleTooltipVisibility}
      triggerRenderer={() => (
        <div onMouseEnter={toggleTooltipVisibility} onMouseLeave={toggleTooltipVisibility}>
          <Button
            disabled={!canManageApplications}
            onClick={handleOpenModal}
            className={styles.actionBtn}
            kind="secondary"
          >
            {copy}
          </Button>
        </div>
      )}
    >
      <Text>Only license owner can active it</Text>
    </Tooltip>
  );
};
