import { type SagaIterator } from 'redux-saga';
import { all, fork, put, select, takeEvery } from 'redux-saga/effects';

import { GlobalModal } from 'constants/global-modal';
import { Section } from 'constants/section';
import { ToastContent } from 'constants/toasts';
import { getConfig } from 'helpers/config';
import { getToastContent } from 'helpers/toast';
import { CannedResponseActionNames, CannedResponseActions } from 'store/entities/canned-responses/actions';
import {
  type ICannedResponseSuccessPayload,
  type IFetchCannedResponsesPayload,
  type IRemoveCannedResponsePayload,
  type ISaveCannedResponsePayload,
} from 'store/entities/canned-responses/interfaces';
import { getGroup } from 'store/entities/groups/selectors';
import { IntegrationGroupPropertiesActions } from 'store/entities/integration-group-properties/actions';
import { GlobalModalActions } from 'store/features/global-modals/actions';
import { getIsOnSection } from 'store/features/routing/selectors';
import { SessionActionNames } from 'store/features/session/actions';
import { ToastsActions } from 'store/features/toasts/actions';
import { ToastVariant } from 'store/features/toasts/interfaces';
import { type IActionWithPayload } from 'store/helper';

import { SettingsViewActionNames } from './actions';
import { languageViewSaga } from './language/sagas';
import { getSelectedGroupId } from './selectors';

function* setTicketFormForwardingToHelpDesk(action: IActionWithPayload<string, { enabled: boolean }>): SagaIterator {
  const { enabled } = action.payload;
  const selectedGroupId: ReturnType<typeof getSelectedGroupId> = yield select(getSelectedGroupId);
  const clientId = getConfig().helpDeskAppClientId;

  yield put(
    IntegrationGroupPropertiesActions.update({
      namespace: clientId,
      groupId: parseInt(selectedGroupId, 10),
      properties: {
        forwardTicketFormToHelpdesk: enabled,
      },
    })
  );
}

function* fetchedCannedResponses(action: IActionWithPayload<string, IFetchCannedResponsesPayload>): SagaIterator {
  const { payload } = action;
  yield put(CannedResponseActions.fetchCannedResponses({ ...payload }));
}

function* savedCannedResponse(action: IActionWithPayload<string, ISaveCannedResponsePayload>): SagaIterator {
  const { payload } = action;
  yield put(CannedResponseActions.saveCannedResponse({ ...payload }));
}

function* savedCannedResponseSuccess({
  payload,
}: IActionWithPayload<string, ICannedResponseSuccessPayload>): SagaIterator {
  const group: ReturnType<typeof getGroup> = yield select(getGroup, payload.groupId);
  const isOnChats = yield select(getIsOnSection, Section.Chats, false);

  yield put(
    ToastsActions.createToast({
      content: getToastContent(ToastContent.CANNED_SAVE_SUCCESS, {
        groupName: isOnChats ? group?.name : null,
      }),
      kind: ToastVariant.Success,
    })
  );

  if (!payload.shouldCreateAnotherOne) {
    yield put(GlobalModalActions.hideModal(GlobalModal.AddCannedResponse));
  }
}

function* savedCannedResponseFailure(): SagaIterator {
  yield put(
    ToastsActions.createToast({
      content: getToastContent(ToastContent.CANNED_SAVE_ERROR),
      kind: ToastVariant.Error,
    })
  );
}

function* updatedCannedResponse(action: IActionWithPayload<string, ISaveCannedResponsePayload>): SagaIterator {
  const { payload } = action;

  yield put(CannedResponseActions.updateCannedResponse({ ...payload }));
}

function* updatedCannedResponseSuccess(): SagaIterator {
  yield put(
    ToastsActions.createToast({
      content: getToastContent(ToastContent.CANNED_UPDATE_SUCCESS),
      kind: ToastVariant.Success,
    })
  );

  yield put(GlobalModalActions.hideModal(GlobalModal.AddCannedResponse));
}

function* updatedCannedResponseFailure(): SagaIterator {
  yield put(
    ToastsActions.createToast({
      content: getToastContent(ToastContent.CANNED_UPDATE_ERROR),
      kind: ToastVariant.Error,
    })
  );
}

function* removedCannedResponse(action: IActionWithPayload<string, IRemoveCannedResponsePayload>): SagaIterator {
  const {
    payload: { id },
  } = action;
  yield put(CannedResponseActions.removeCannedResponse({ id }));
}

function* removedCannedResponseSuccess(): SagaIterator {
  yield put(
    ToastsActions.createToast({
      content: getToastContent(ToastContent.CANNED_REMOVE_SUCCESS),
      kind: ToastVariant.Success,
    })
  );
}

function* removedCannedResponseFailure(): SagaIterator {
  yield put(
    ToastsActions.createToast({
      content: getToastContent(ToastContent.CANNED_REMOVE_ERROR),
      kind: ToastVariant.Error,
    })
  );
}

function* settingsSaga(): SagaIterator {
  yield takeEvery(SettingsViewActionNames.SET_TICKET_FORM_FORWARDING_TO_HELPDESK, setTicketFormForwardingToHelpDesk);
  yield takeEvery(
    [
      SettingsViewActionNames.FETCH_CANNED_RESPONSES,
      SessionActionNames.SAVE_CURRENT_LICENSE,
      SessionActionNames.SAVE_LICENSE_PLAN,
    ],
    fetchedCannedResponses
  );
  yield takeEvery(SettingsViewActionNames.SAVE_CANNED_RESPONSE, savedCannedResponse);
  yield takeEvery(CannedResponseActionNames.SAVE_CANNED_RESPONSE_SUCCESS, savedCannedResponseSuccess);
  yield takeEvery(CannedResponseActionNames.SAVE_CANNED_RESPONSE_FAILURE, savedCannedResponseFailure);
  yield takeEvery(SettingsViewActionNames.COPY_CANNED_RESPONSE, savedCannedResponse);
  yield takeEvery(SettingsViewActionNames.UPDATE_CANNED_RESPONSE, updatedCannedResponse);
  yield takeEvery(CannedResponseActionNames.UPDATE_CANNED_RESPONSE_SUCCESS, updatedCannedResponseSuccess);
  yield takeEvery(CannedResponseActionNames.UPDATE_CANNED_RESPONSE_FAILURE, updatedCannedResponseFailure);
  yield takeEvery(SettingsViewActionNames.REMOVE_CANNED_RESPONSE, removedCannedResponse);
  yield takeEvery(CannedResponseActionNames.REMOVE_CANNED_RESPONSE_SUCCESS, removedCannedResponseSuccess);
  yield takeEvery(CannedResponseActionNames.REMOVE_CANNED_RESPONSE_FAILURE, removedCannedResponseFailure);
}

export function* settingsViewSaga(): SagaIterator {
  yield all([fork(settingsSaga), fork(languageViewSaga)]);
}
