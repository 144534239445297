import type { FC } from 'react';

import { SkeletonText, SkeletonWrapper } from '@livechat/design-system-react-components';

import * as styles from './styles';

export const FooterLoader: FC = () => (
  <div className={styles.footerLoader}>
    <SkeletonText width={260} height={14} />
    <SkeletonWrapper>
      <SkeletonText width={60} height={30} />
      <SkeletonText width={60} height={30} />
    </SkeletonWrapper>
  </div>
);
