import { type FC } from 'react';

import { ResourceModalContextProvider } from 'components/apps/hooks/use-resource-modal-context';
import { type IInAppUpgrade } from 'store/entities/applications/interfaces';

import { ResourceContextProvider } from '../../hooks/use-resource-context';

import { ActionCell } from './ActionCell';
import { NameCell } from './NameCell';
import { PriceCell } from './PriceCell';
import { SummaryCell } from './SummaryCell';

import * as styles from './styles';

interface IProps {
  resource: IInAppUpgrade;
}

export const InAppUpgradeRow: FC<IProps> = ({ resource }) => (
  <ResourceContextProvider resource={resource}>
    <ResourceModalContextProvider isFree={!resource.price} isActive={resource.isActive}>
      <div className={styles.row}>
        <div className={styles.name}>
          <NameCell />
        </div>

        <div className={styles.price}>
          <PriceCell />
        </div>

        <div className={styles.summary}>
          <SummaryCell />
        </div>

        <div className={styles.action}>
          <ActionCell />
        </div>
      </div>
    </ResourceModalContextProvider>
  </ResourceContextProvider>
);
