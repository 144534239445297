import { type FC } from 'react';

import { NOTIFICATIONS_NEW_TOP_BAR_TEST_ID } from './constants';
import { OneWithBadge } from './OneWithBadge';
import { SpotlightTrigger } from './spotlight/SpotlightTrigger';

import * as styles from './styles';

export const TopBar: FC = () => {
  return (
    <div className={styles.topBarWrapper} data-testid={NOTIFICATIONS_NEW_TOP_BAR_TEST_ID}>
      <SpotlightTrigger />

      <OneWithBadge />
    </div>
  );
};
