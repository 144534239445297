import debug from 'debug';

import { App } from 'config/setup';
import { DebugLogsNamespace } from 'constants/debug-logs-namespace';
import { QueryKey } from 'constants/query-key';
import { anyToBoolean } from 'helpers/boolean';
import { getConfig } from 'helpers/config';
import { convertToUnixTimestamp } from 'helpers/date';
import { delay } from 'helpers/delay';
import { AgentSerializer } from 'services/api/agent/v3/agent-serializer';
import { AgentBotSerializer } from 'services/api/agent-bot/serializer';
import { AppStateProvider } from 'services/app-state-provider';
import { licenseInfoClient } from 'services/connectivity/agent-chat-api/license-info/client';
import { routingStatusClient } from 'services/connectivity/agent-chat-api/status/client';
import { type RoutingStatus } from 'services/connectivity/agent-chat-api/status/types';
import { agentsClient } from 'services/connectivity/configuration-api/agents/client';
import { getAllAgentFields } from 'services/connectivity/configuration-api/agents/fields';
import { botsClient } from 'services/connectivity/configuration-api/bots/client';
import { getAllBotFields } from 'services/connectivity/configuration-api/bots/fields';
import { propertiesClient } from 'services/connectivity/configuration-api/properties/client';
import { tokensClient } from 'services/connectivity/global-accounts-api/tokens/client';
import { getQueryClient } from 'services/query-client/client';
import { QUERY_KEYS } from 'services/query-client/keys';
import { mapRoutingStatusToLoginStatus } from 'services/socket-lc3/agent/helpers';
import { AccountsActions } from 'store/entities/accounts/actions';
import { AgentActions } from 'store/entities/agents/actions';
import { type IAgent } from 'store/entities/agents/interfaces';
import { AgentBotActions } from 'store/entities/bots/actions';
import { type IAgentBot } from 'store/entities/bots/interfaces';
import { IntegrationLicensePropertiesActions } from 'store/entities/integration-license-properties/actions';
import { type IFetchLicensePropertiesSuccessPayload } from 'store/entities/integration-license-properties/interfaces';
import { SessionActions } from 'store/features/session/actions';

const log = debug(DebugLogsNamespace.AppServerConnection);
const queryClient = getQueryClient();

/**
 * Before AA connect to RTM API, we should have settle down core data.
 * To speed up fetch process, we are doing request in parallel, but the parsing
 * process follows needed order.
 */
async function fetchCoreData(): Promise<void> {
  const config = getConfig();
  const responses = await Promise.all([
    routingStatusClient.list({}),
    agentsClient.list({ fields: getAllAgentFields() }),
    botsClient.list({ all: true, fields: getAllBotFields() }),
    licenseInfoClient.getLicenseInfo(),
    tokensClient.info(),
    propertiesClient.listLicense({}),
  ]);

  // we are skipping the error check for `fetchRoutingStatusesList` because it throws an error in some cases (e.g. license expired), and then the app crashes
  // fetching this data is not crucial for the app - it's just a prefetch of agents' statuses, but we also receive that information via WebSocket
  const firstResponseWithError = [...responses].splice(1, 5).find((v) => v.error);

  if (firstResponseWithError) {
    throw new Error(JSON.stringify(firstResponseWithError.error));
  }

  const [
    { result: routingStatusesList },
    { result: agents },
    { result: chatbots },
    { result: licenseInfo },
    { result: tokenInfo },
    { result: licenseIntegrationProperties },
  ] = responses;

  if (licenseInfo) {
    const parsedCreatedAt = convertToUnixTimestamp(new Date(licenseInfo.created_at).getTime());

    AppStateProvider.dispatch(
      SessionActions.saveCurrentLicense({
        createdAt: parsedCreatedAt,
      })
    );
  }

  if (App.server && licenseIntegrationProperties) {
    App.server.websocketCompressionEnabled = anyToBoolean(
      licenseIntegrationProperties[config.accountsClientId]?.compress_websocket
    );
  }

  const parsedRoutingStatusesList: Record<string, RoutingStatus> = (routingStatusesList || []).reduce(
    // eslint-disable-next-line @typescript-eslint/naming-convention
    (acc, { agent_id, status }) => ({ ...acc, [agent_id]: status }),
    {}
  );

  // parse agents and prefetched statuses
  const mappedAgents: IAgent[] = (agents ?? []).map((agent) =>
    AgentSerializer.deserialize(agent, mapRoutingStatusToLoginStatus(parsedRoutingStatusesList[agent.id]))
  );

  const mappedBots: IAgentBot[] = (chatbots ?? []).map((chatbot) => {
    const chatbotStatus = mapRoutingStatusToLoginStatus(parsedRoutingStatusesList[chatbot.id]);

    return AgentBotSerializer.deserializeV3(chatbot, chatbotStatus);
  });

  AppStateProvider.dispatch(
    IntegrationLicensePropertiesActions.fetchSuccess(
      licenseIntegrationProperties as never as IFetchLicensePropertiesSuccessPayload
    )
  );
  AppStateProvider.dispatch(AgentActions.agentsFetched(mappedAgents));
  AppStateProvider.dispatch(AgentBotActions.botsFetched(mappedBots));
  if (tokenInfo) {
    AppStateProvider.dispatch(AccountsActions.setCurrentOrganizationId(tokenInfo.organization_id));
  }

  for (const namespace in licenseIntegrationProperties) {
    queryClient.setQueryData(
      QUERY_KEYS[QueryKey.LicenseProperties](namespace),
      licenseIntegrationProperties[namespace]
    );
  }
}

export async function fetchCoreDataWithRetry(retryAttempt = 1): Promise<void> {
  try {
    await fetchCoreData();
  } catch (e) {
    const error = e as Error;
    log(`Error while fetching core data, retrying...`);
    log(error);
    const timeout = Math.min(1000 * 2 ** retryAttempt, 60000);

    await delay(timeout);

    return fetchCoreDataWithRetry(retryAttempt + 1);
  }
}
