import { type EventPlace } from 'helpers/analytics';
import { type IKeyValue } from 'helpers/url';
import { type AmplitudeEventProperties } from 'services/amplitude';
import { trackEvent } from 'services/event-tracking';
import { getItem, saveItem } from 'services/local-storage';

/**
 * trackEventOncePerUniqueKey(
 *   uniqueKey: string,
 *   eventName: string,
 *   eventPlace: EventPlace,
 *   eventProperties: EventProperties
 *   )
 *
 * method for call trackEvent once per day / per uniqueKey / per eventName
 * uniqueKey can be a chatId or threadId
 *
 * for example:
 * trackEventOncePerUniqueKey("F67F8A8X","Chat Opened", "EventPlace.Chats")
 * ^^ this function will run only once, unless a day has passed.
 */

const storageKey = 'event:saver';

interface IEventSaverItem {
  ts: number;
  event: string;
}

interface IEventSaver {
  [key: string]: IEventSaverItem[];
}

const getTimestampOfDay = (): number => {
  const now = new Date();
  const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const timestamp = +now - +startOfDay;

  return timestamp;
};

export const simpleHash = (str: string): string =>
  [...str]
    .reduce((hash, c) => {
      const updatedHash = (hash << 5) - hash + c.charCodeAt(0);

      return updatedHash & updatedHash;
    }, 0)
    .toString();

const getEvents = (): IEventSaver => {
  try {
    const events = getItem<IEventSaver>(storageKey);

    return events || {};
  } catch (error) {
    return {};
  }
};

const hasThreadId = (uniqueKey: string, eventName: string): boolean =>
  !!Object.entries(getEvents()).find(
    ([key, events]) => key === uniqueKey && !!(events || []).find(({ event }) => event === simpleHash(eventName))
  );

const cleanup = (): void => {
  saveItem(storageKey, {});
};

function getMaxTimeStamp(): number {
  return Object.values(getEvents()).reduce((maxTimeStamp, events) => {
    const maxTs = Math.max(...events.map(({ ts }) => ts));

    return maxTs > maxTimeStamp ? maxTs : maxTimeStamp;
  }, 0);
}

const isNewDay = (): boolean => {
  const maxTsInLS = getMaxTimeStamp();

  return maxTsInLS <= 0 || getTimestampOfDay() < maxTsInLS;
};

const addNewEvent = (uniqueKey: string, eventName: string): void => {
  const prevValues = getEvents();
  const newValue: IEventSaver = {
    [uniqueKey]: [
      ...(prevValues[uniqueKey] || []),
      {
        event: simpleHash(eventName),
        ts: getTimestampOfDay(),
      },
    ],
  };
  const item = { ...prevValues, ...newValue } as unknown;
  saveItem(storageKey, item as IKeyValue);
};

export function trackEventOncePerUniqueKey(
  uniqueKey: string,
  eventName: string,
  eventPlace: EventPlace | null = null,
  eventProperties: AmplitudeEventProperties = {}
): void {
  if (isNewDay()) {
    cleanup();
  }

  if (hasThreadId(uniqueKey, eventName)) {
    return;
  }

  addNewEvent(uniqueKey, eventName);
  trackEvent(eventName, eventPlace, { eventSaver: uniqueKey, ...eventProperties });
}
