/**
 * Reorder item in array
 */
export function reorder(list: any[], startIndex: number, endIndex: number): any[] {
  const result = list.slice(0);
  const [removed] = result.splice(startIndex, 1);

  result.splice(endIndex, 0, removed);

  return result;
}
