import { type SagaIterator } from 'redux-saga';
import { all, fork } from 'redux-saga/effects';

import { accountsSagas } from './accounts/sagas';
import { applicationsSagas } from './applications/sagas';
import archiveSagas from './archives/sagas';
import { autoAccessRulesSagas } from './auto-access-rules/sagas';
import { badgesSagas } from './badges/sagas';
import { billingSagas } from './billing/sagas';
import { botsSagas } from './bots/sagas';
import { cannedResponsesWithAutotagsSagas } from './canned-responses/sagas';
import { chatbotInLivechatSagas } from './chatbot-in-livechat/sagas';
import { chatsSaga } from './chats/sagas';
import { companyDetailsSagas } from './company-details/sagas';
import { customersSaga } from './customers/sagas';
import { experimentsSaga } from './experiments/sagas';
import { formsSagas } from './forms/sagas';
import greetingsSagas from './greetings/sagas';
import { groupPropertiesSagas } from './group-properties/sagas';
import { groupsSagas } from './groups/sagas';
import { inAppMessagesSagas } from './in-app-messages/sagas';
import insightsSagas from './insights/sagas';
import { integrationGroupPropertiesSagas } from './integration-group-properties/sagas';
import { integrationLicensePropertiesSagas } from './integration-license-properties/sagas';
import { languagesSagas } from './languages/sagas';
import { licenseCustomPropertiesSagas } from './license-custom-properties/sagas';
import { licensePropertiesSaga } from './license-properties/sagas';
import { oneSaga } from './one/sagas';
import { recurlySaga } from './recurly/sagas';
import reportsSaga from './reports/sagas';
import tagsSagas from './tags/sagas';
import { ticketSagas } from './tickets/sagas';
import { trustedDomainsSagas } from './trusted-domains/sagas';

export function* entitiesSagas(): SagaIterator {
  yield all([
    fork(accountsSagas),
    fork(botsSagas),
    fork(applicationsSagas),
    fork(archiveSagas),
    fork(autoAccessRulesSagas),
    fork(badgesSagas),
    fork(cannedResponsesWithAutotagsSagas),
    fork(chatsSaga),
    fork(companyDetailsSagas),
    fork(customersSaga),
    fork(experimentsSaga),
    fork(greetingsSagas),
    fork(groupPropertiesSagas),
    fork(groupsSagas),
    fork(integrationGroupPropertiesSagas),
    fork(integrationLicensePropertiesSagas),
    fork(chatbotInLivechatSagas),
    fork(languagesSagas),
    fork(licenseCustomPropertiesSagas),
    fork(licensePropertiesSaga),
    fork(recurlySaga),
    fork(reportsSaga),
    fork(tagsSagas),
    fork(ticketSagas),
    fork(trustedDomainsSagas),
    fork(formsSagas),
    fork(insightsSagas),
    fork(inAppMessagesSagas),
    fork(oneSaga),
    fork(billingSagas),
  ]);
}
