// @ts-strict-ignore
import { LicensePropertiesNames } from 'constants/license-properties-names';
import { delay } from 'helpers/delay';
import { isAccountRelatedError } from 'helpers/request-decoder';
import type { LicenseProperties } from 'interfaces/license-properties';
import { ApiManager } from 'services/api/api-manager';
import { LicensePropertiesActions } from 'store/entities/license-properties/actions';

import { AppStateProvider } from './app-state-provider';
import { normalizeError } from './connectivity/configuration-api/helpers';
import { propertiesClient } from './connectivity/configuration-api/properties/client';

const MAX_RETRY_COUNT = 3;

export async function fetchLicensePropertiesWithRetry(retryAttempt = 1): Promise<void> {
  const { result, error } = await ApiManager.licensePropertiesApi.fetchAll();

  if (error) {
    if (isAccountRelatedError(error) || retryAttempt > MAX_RETRY_COUNT) {
      return Promise.reject(error);
    }

    return delay(1000 * 2 ** retryAttempt).then(() => fetchLicensePropertiesWithRetry(retryAttempt + 1));
  }

  const propertiesToSave = Object.keys(LicensePropertiesNames).reduce((acc, keyIndex) => {
    const key = LicensePropertiesNames[keyIndex];
    acc[key] = result[key];

    return acc;
  }, {});

  AppStateProvider.dispatch(LicensePropertiesActions.setData(propertiesToSave));

  return Promise.resolve();
}

export async function saveLicenseProperties(properties: LicenseProperties): Promise<void> {
  const { error } = await propertiesClient.updateLicense({
    properties: {
      core: properties,
    },
  });

  if (error) {
    const normalizedError = normalizeError(error);
    throw normalizedError;
  }
}
