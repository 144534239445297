// @ts-strict-ignore
import { Intent } from 'constants/intent';
import { SortOrder } from 'constants/sort-order';
import { VisitorState } from 'constants/visitor-state';
import { type KeyMap } from 'helpers/interface';
import { type IAgentBase } from 'interfaces/entities/agent-base';
import type { GroupBase } from 'interfaces/groups';
import { isMyChat, isQueuedChat } from 'store/entities/chats/helpers/common';
import { type ChatThreadEntity } from 'store/entities/chats/interfaces';
import { calculateAgentIdChattingWithCustomer, calculateCustomerActivity } from 'store/entities/customers/computed';
import { getCustomerNameForList } from 'store/entities/customers/helpers/customer';
import { CustomerCustomVariableName, type ICustomer, type IVisitedPage } from 'store/entities/customers/interfaces';
import type { IGreeting } from 'store/entities/greetings/interfaces';
import { type EntityData } from 'store/entities/interfaces';

import { TrafficColumn } from '../interfaces';

import { getReferrerHostname } from './customer-host-name';
import { getLastPageTitle } from './filtering';

export function compareNumericValues(valueA: number, valueB: number, isDescending: boolean): number {
  if (valueA == null && valueB == null) {
    return 0;
  }

  if (valueA == null) {
    return 1;
  }

  if (valueB == null) {
    return -1;
  }

  if (valueA === valueB) {
    return 0;
  }

  return (valueA > valueB ? 1 : -1) * (isDescending ? -1 : 1);
}

export function compareStringValues(valueA: string, valueB: string, isDescending: boolean): number {
  if (valueA.length === 0 && valueB.length === 0) {
    return 0;
  }

  if (valueA.length === 0) {
    return 1;
  }

  if (valueB.length === 0) {
    return -1;
  }

  return valueA.localeCompare(valueB) * (isDescending ? -1 : 1);
}

export function getActivityRank(customer: ICustomer, thread: ChatThreadEntity): number {
  const activity = calculateCustomerActivity(customer, thread);

  switch (activity) {
    case VisitorState.Chatting:
      return isMyChat(thread) ? 1 : 2;
    case VisitorState.Supervised:
      return 3;
    case VisitorState.Queued: {
      const queueStartTime = isQueuedChat(thread) ? thread.queuedAtInMs : 0;

      return parseFloat(`4.${queueStartTime || 0}`);
    }
    case VisitorState.WaitingForReply:
      return 5;
    case VisitorState.Invited:
      return 6;
    case VisitorState.Browsing:
      return 7;
    default:
      return null;
  }
}

function getIntentRank(intent: Intent): number {
  switch (intent) {
    case Intent.Transaction:
      return 1;
    case Intent.Support:
      return 2;
    case Intent.Exploring:
    default:
      return 3;
  }
}

function getCustomerIntent(customer: ICustomer): Intent {
  const intentValue = customer.customVariables?.[CustomerCustomVariableName.Intent];
  if (intentValue && Object.values(Intent).includes(intentValue as Intent)) {
    return intentValue as Intent;
  }

  return Intent.Exploring;
}

function getAgentName(customer: ICustomer, thread: ChatThreadEntity, allAgents: KeyMap<IAgentBase>): string {
  const agentId = calculateAgentIdChattingWithCustomer(customer, thread);

  return allAgents[agentId]?.name;
}

function getTargetedMessageName(targetedMessageId: string, allTargetedMessages: KeyMap<IGreeting>): string | undefined {
  return allTargetedMessages[targetedMessageId]?.name;
}

export function getGroupsString(customer: ICustomer, allGroups: KeyMap<GroupBase>): string {
  return customer.groupIds?.map((groupId) => allGroups[groupId]?.name ?? '').join(',') ?? '';
}

export function getVisitedPagesTotalTime(visitedPages: KeyMap<IVisitedPage>, lastActivityTimestamp: number): number {
  const pageIds = Object.keys(visitedPages ?? {});

  return pageIds.length > 0
    ? lastActivityTimestamp - Math.min(...pageIds.map((pageId) => visitedPages[pageId].timestampInMS))
    : null;
}

function getSortMethod(
  sortByColumn: TrafficColumn,
  sortDescending: boolean,
  threadsByCustomerId: KeyMap<ChatThreadEntity>,
  allAgents: KeyMap<IAgentBase>,
  allGroups: KeyMap<GroupBase>,
  allTargetedMessages: KeyMap<IGreeting>,
  allVisitedPages: KeyMap<EntityData<IVisitedPage>>,
  invokeTimestamp: number
): (customerA: ICustomer, customerB: ICustomer) => number {
  switch (sortByColumn) {
    case TrafficColumn.Actions:
    case TrafficColumn.Activity:
      return (a, b) =>
        compareNumericValues(
          getActivityRank(a, threadsByCustomerId[a.id]),
          getActivityRank(b, threadsByCustomerId[b.id]),
          sortDescending
        );
    case TrafficColumn.Intent:
      return (a, b) =>
        compareNumericValues(getIntentRank(getCustomerIntent(a)), getIntentRank(getCustomerIntent(b)), sortDescending);

    case TrafficColumn.Browser:
      return (a, b) =>
        compareStringValues(a.visitDetails?.browserName ?? '', b.visitDetails?.browserName ?? '', sortDescending);
    case TrafficColumn.ChatsCount:
      return (a, b) =>
        compareNumericValues(a.statistics?.chatsCount ?? 0, b.statistics?.chatsCount ?? 0, sortDescending);
    case TrafficColumn.ChattingWith:
      return (a, b) =>
        compareStringValues(
          getAgentName(a, threadsByCustomerId[a.id], allAgents) ?? '',
          getAgentName(b, threadsByCustomerId[b.id], allAgents) ?? '',
          sortDescending
        );
    case TrafficColumn.City:
      return (a, b) => compareStringValues(a.geolocation?.city ?? '', b.geolocation?.city ?? '', sortDescending);
    case TrafficColumn.Country:
      return (a, b) => compareStringValues(a.geolocation?.country ?? '', b.geolocation?.country ?? '', sortDescending);
    case TrafficColumn.CustomerId:
      return (a, b) => compareStringValues(a.id, b.id, sortDescending);
    case TrafficColumn.Device:
      return (a, b) =>
        compareStringValues(a.visitDetails?.deviceType ?? '', b.visitDetails?.deviceType ?? '', sortDescending);
    case TrafficColumn.Email:
      return (a, b) => compareStringValues(a.email ?? '', b.email ?? '', sortDescending);
    case TrafficColumn.GreetingsAccepted:
      return (a, b) =>
        compareNumericValues(
          a.statistics?.acceptedGreetingsCount ?? 0,
          b.statistics?.acceptedGreetingsCount ?? 0,
          sortDescending
        );
    case TrafficColumn.GreetingsIgnored:
      return (a, b) =>
        compareNumericValues(
          a.statistics?.greetingsCount - a.statistics?.acceptedGreetingsCount || 0,
          b.statistics?.greetingsCount - b.statistics?.acceptedGreetingsCount || 0,
          sortDescending
        );
    case TrafficColumn.GreetingsSent:
      return (a, b) =>
        compareNumericValues(a.statistics?.greetingsCount ?? 0, b.statistics?.greetingsCount ?? 0, sortDescending);
    case TrafficColumn.Groups:
      return (a, b) =>
        compareStringValues(getGroupsString(a, allGroups), getGroupsString(b, allGroups), sortDescending);
    case TrafficColumn.IP:
      return (a, b) => compareStringValues(a.ip ?? '', b.ip ?? '', sortDescending);
    case TrafficColumn.LastGreeting:
      return (a, b) =>
        compareStringValues(
          getTargetedMessageName(a.visitDetails?.targetedMessageId, allTargetedMessages) ?? '',
          getTargetedMessageName(b.visitDetails?.targetedMessageId, allTargetedMessages) ?? '',
          sortDescending
        );
    case TrafficColumn.LastPage:
      return (a, b) =>
        compareStringValues(
          getLastPageTitle(allVisitedPages[a.id]),
          getLastPageTitle(allVisitedPages[b.id]),
          sortDescending
        );
    case TrafficColumn.LastSeen:
      return (a, b) =>
        compareNumericValues(
          a.visitDetails?.lastVisitTimestampInMs ?? 0,
          b.visitDetails?.lastVisitTimestampInMs ?? 0,
          sortDescending
        );
    case TrafficColumn.Name:
      return (a, b) => compareStringValues(getCustomerNameForList(a), getCustomerNameForList(b), sortDescending);
    case TrafficColumn.OperatingSystem:
      return (a, b) =>
        compareStringValues(
          a.visitDetails?.operatingSystem ?? '',
          b.visitDetails?.operatingSystem ?? '',
          sortDescending
        );
    case TrafficColumn.Referrer:
      return (a, b) =>
        compareStringValues(
          getReferrerHostname(a.visitDetails?.cameFromURL) ?? '',
          getReferrerHostname(b.visitDetails?.cameFromURL) ?? '',
          sortDescending
        );
    case TrafficColumn.State:
      return (a, b) => compareStringValues(a.geolocation?.state ?? '', b.geolocation?.state ?? '', sortDescending);
    case TrafficColumn.VisitingTime:
      return (a, b) =>
        compareNumericValues(
          getVisitedPagesTotalTime(
            allVisitedPages[a.id]?.byIds,
            a.visitDetails?.visitEndedTimestampInMs ?? invokeTimestamp
          ),
          getVisitedPagesTotalTime(
            allVisitedPages[b.id]?.byIds,
            b.visitDetails?.visitEndedTimestampInMs ?? invokeTimestamp
          ),
          sortDescending
        );
    case TrafficColumn.VisitsCount:
      return (a, b) =>
        compareNumericValues(a.statistics?.visitsCount ?? 0, b.statistics?.visitsCount ?? 0, sortDescending);
  }
}

export function sortCustomers(
  customers: ICustomer[],
  sortByColumn: TrafficColumn,
  sortOrder: SortOrder,
  threadsByCustomerId: KeyMap<ChatThreadEntity>,
  allAgents: KeyMap<IAgentBase>,
  allGroups: KeyMap<GroupBase>,
  allTargetedMessages: KeyMap<IGreeting>,
  allVisitedPages: KeyMap<EntityData<IVisitedPage>>,
  invokeTimestamp: number
): ICustomer[] {
  const revertedOrder = sortOrder === SortOrder.Asc ? SortOrder.Desc : SortOrder.Asc;
  // we must revert the sort order of the "Last seen" column because it's visibly sorted by "days ago", not by "last seen timestamp"
  const sortOrderComputed = sortByColumn === TrafficColumn.LastSeen ? revertedOrder : sortOrder;
  const sortDescending = sortOrderComputed === SortOrder.Desc;

  const sortMethod = getSortMethod(
    sortByColumn,
    sortDescending,
    threadsByCustomerId,
    allAgents,
    allGroups,
    allTargetedMessages,
    allVisitedPages,
    invokeTimestamp
  );

  return customers.sort(sortMethod);
}
