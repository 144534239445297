/* eslint-disable @typescript-eslint/naming-convention */
import type { ChatRecipients } from 'constants/chat-recipients';
import type { WebSocketAction } from 'constants/web-socket-action';
import type { IRichMessageJSON } from 'helpers/rich-message';
import type { Properties } from 'interfaces/property';

import type { ApiVersion } from './api-version';
import type { EventIntegrationProperties } from './license-properties';

export type SendMessagePayload = {
  messageId: string;
  messageText: string;
  chatId: string;
  whisper: boolean;
  isRichMessage: boolean;
  json: IRichMessageJSON;
  properties?: {
    [key: string]: EventIntegrationProperties;
  };
};

export type SendSystemMessagePayload = {
  messageText: string;
  chatId: string;
  isWhisper: boolean;
  systemMessageType: string;
};

export type SendFilePayload = {
  messageId: string;
  url: string;
  chatId: string;
  visibility: ChatRecipients;
};

export type SendSupervisorMessagePayload = Omit<SendMessagePayload, 'whisper'>;

type EventBase = {
  request_id?: string;
  version: ApiVersion;
};

export type SendEventResponsePayload = {
  event_id: string;
};

export type SendMessageEventBody = {
  type: 'message' | 'rich_message';
  text: string;
  visibility: ChatRecipients;
  custom_id: string;
  author_id: string;
  properties?: Record<string, EventIntegrationProperties>;
};

export type SendSystemMessageEventBody = {
  type: 'system_message';
  system_message_type: string;
  text: string;
  custom_id?: string;
  visibility?: ChatRecipients;
};

export type SendFileEventBody = {
  type: 'file';
  url: string;
  visibility: ChatRecipients;
  custom_id: string;
  author_id: string;
};

// web socket events
export type SendMessageEvent = {
  action: WebSocketAction.SendEvent;
  payload: {
    chat_id: string;
    require_active_thread?: boolean; //TODO remove when supervisor will use send_event 3.4 https://livechatinc.atlassian.net/browse/AA-13859
    require_requester_presence?: boolean; //TODO remove when supervisor will use send_event 3.4 https://livechatinc.atlassian.net/browse/AA-13859
    event: SendMessageEventBody | SendSystemMessageEventBody;
  };
} & EventBase;

export type SendFileEvent = {
  action: WebSocketAction.SendEvent;
  payload: {
    chat_id: string;
    require_active_thread?: boolean; //TODO remove when supervisor will use send_event 3.4 https://livechatinc.atlassian.net/browse/AA-13859
    require_requester_presence?: boolean; //TODO remove when supervisor will use send_event 3.4 https://livechatinc.atlassian.net/browse/AA-13859
    event: SendFileEventBody;
  };
} & EventBase;

export type SendTypingIndicatorEvent = {
  action: WebSocketAction.SendTypingIndicator;
  payload: {
    chat_id: string;
    visibility: ChatRecipients;
    is_typing: boolean;
  };
} & EventBase;

export type SendMulticastEvent = {
  action: WebSocketAction.Multicast;
  payload: {
    content: Record<string, string | string[] | boolean>;
    recipients: Record<string, Record<string, boolean | string[]>>;
  };
} & EventBase;

export type MarkEventsAsSeenEvent = {
  action: WebSocketAction.MarkEventsAsSeen;
  payload: {
    chat_id: string;
    seen_up_to: string;
  };
} & EventBase;

export type TransferChatEvent = {
  action: WebSocketAction.TransferChat;
  payload: {
    id: string;
    target: {
      type: 'agent' | 'group';
      ids: string[] | number[];
    };
    ignore_requester_presence: boolean;
    ignore_agents_availability: boolean;
  };
} & EventBase;

export type DeactivateChatEvent = {
  action: WebSocketAction.DeactivateChat;
  payload: {
    id: string;
    ignore_requester_presence: boolean;
  };
} & EventBase;

export type UpdateChatPropertiesEvent = {
  action: WebSocketAction.UpdateChatProperties;
  payload: {
    id: string;
    properties: Properties;
  };
} & EventBase;

export type TagThreadEvent = {
  action: WebSocketAction.TagThread;
  payload: {
    chat_id: string;
    thread_id: string;
    tag: string;
  };
} & EventBase;

export type UntagThreadEvent = {
  action: WebSocketAction.UntagThread;
  payload: {
    chat_id: string;
    thread_id: string;
    tag: string;
  };
} & EventBase;

export type SetAwayStatusEvent = {
  action: WebSocketAction.SetAwayStatus;
  payload: {
    away: boolean;
  };
} & EventBase;

export type UpdateEventPropertiesEvent = {
  action: WebSocketAction.UpdateEventProperties;
  payload: {
    chat_id: string;
    thread_id: string;
    event_id: string;
    properties?: Properties;
  };
} & EventBase;

export type FollowChatEvent = {
  action: WebSocketAction.FollowChat;
  payload: {
    id: string;
  };
} & EventBase;

export type UnfollowChatEvent = {
  action: WebSocketAction.UnfollowChat;
  payload: {
    id: string;
  };
} & EventBase;

export enum CustomerMonitoringLevel {
  My = 'my',
  Chatting = 'chatting',
  Invited = 'invited',
  Online = 'online',
  HighestAvailable = 'highest_available',
}

export type LoginEvent = {
  action: WebSocketAction.Login;
  payload: {
    application: {
      name: string;
      version: string;
    };
    reconnect: boolean;
    away: boolean;
    token: string;
    timezone: string;
    customer_monitoring_level?: CustomerMonitoringLevel;
    pushes: Partial<Record<ApiVersion, string[]>>;
  };
} & EventBase;

export type LogoutEvent = {
  action: WebSocketAction.Logout;
  payload: Record<string, unknown>;
} & EventBase;

export type AddUserToChatEvent = {
  action: WebSocketAction.AddUserToChat;
  payload: {
    chat_id: string;
    user_id: string;
    user_type: 'agent' | 'customer';
    visibility: ChatRecipients;
  };
} & EventBase;

export type RemoveUserFromChatEvent = {
  action: WebSocketAction.RemoveUserFromChat;
  payload: {
    chat_id: string;
    user_id: string;
    user_type: 'agent';
  };
} & EventBase;

export type WebSocketEvent =
  | AddUserToChatEvent
  | DeactivateChatEvent
  | FollowChatEvent
  | LoginEvent
  | LogoutEvent
  | MarkEventsAsSeenEvent
  | RemoveUserFromChatEvent
  | SendFileEvent
  | SendMessageEvent
  | SendMulticastEvent
  | SendTypingIndicatorEvent
  | SetAwayStatusEvent
  | TagThreadEvent
  | TransferChatEvent
  | UnfollowChatEvent
  | UntagThreadEvent
  | UpdateChatPropertiesEvent
  | UpdateEventPropertiesEvent;
