import { type MutableRefObject, useCallback, useRef, useEffect } from 'react';

import debounce from 'lodash.debounce';
import { useDispatch, useSelector } from 'react-redux';

import { UserType } from 'constants/user-type';
import { useEffectOnce } from 'hooks/use-effect-once';
import { usePrevious } from 'hooks/use-previous';
import { type IOneEvent } from 'store/entities/one/interfaces';
import { OneViewActions } from 'store/views/one/actions';
import { getIsOneModalExpanded, getOneScrollPosition, getOneUndreadMessageCount } from 'store/views/one/selectors';

import { ONE_SCROL_TIMEOUT } from '../constants';

interface IUseScrollProps {
  oneChatFeedRef: MutableRefObject<HTMLDivElement | null>;
  groupedEvents: IOneEvent[][];
  additionalEvents: boolean;
}

export const useScroll = ({ oneChatFeedRef, groupedEvents, additionalEvents }: IUseScrollProps) => {
  const dispatch = useDispatch();
  const oneScrollPosition = useSelector(getOneScrollPosition);
  const isExpanded = useSelector(getIsOneModalExpanded);
  const oneUndreadMessageCount = useSelector(getOneUndreadMessageCount);
  const previousExpanded = usePrevious(isExpanded);
  const currentScrollPosition = useRef<number | null>(oneScrollPosition);
  const isScrolledToBottom = useRef<boolean>(oneScrollPosition === null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const currentLength = groupedEvents.length;
  const previousLength = usePrevious(currentLength, 0);

  const scrollToBottom = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      oneChatFeedRef?.current?.scrollTo({ top: oneChatFeedRef?.current?.scrollHeight });
      dispatch(OneViewActions.setScrollPosition(oneChatFeedRef?.current?.scrollHeight || 1));
    }, ONE_SCROL_TIMEOUT);
  }, [dispatch]);

  useEffectOnce(() => {
    return () => {
      dispatch(OneViewActions.setScrollPosition(isScrolledToBottom.current ? null : currentScrollPosition.current));

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  });

  useEffectOnce(() => {
    if (oneScrollPosition === null) {
      scrollToBottom();

      return;
    }

    oneChatFeedRef?.current?.scrollTo({ top: oneScrollPosition });
    currentScrollPosition.current = oneScrollPosition;
  });

  useEffect(() => {
    if (additionalEvents && isScrolledToBottom.current) {
      scrollToBottom();
    }
  }, [groupedEvents, dispatch, scrollToBottom, additionalEvents]);

  useEffect(() => {
    const [lastGroupEvent] = groupedEvents.slice(-1)[0];
    const isLastMessageFromAgent = lastGroupEvent?.authorType === UserType.Agent;

    if (isLastMessageFromAgent || isScrolledToBottom.current) {
      scrollToBottom();

      return;
    }

    if (previousLength && currentLength > previousLength && !isScrolledToBottom.current) {
      dispatch(OneViewActions.increaseOneUnreadMessagesCount(1));
    }
  }, [dispatch, groupedEvents, scrollToBottom]);

  useEffect(() => {
    if (isExpanded !== previousExpanded && previousExpanded !== null) {
      scrollToBottom();
    }
  }, [isExpanded, previousExpanded, dispatch]);

  const handleScroll = (): void => {
    const container = oneChatFeedRef.current;

    if (!container) {
      return;
    }

    currentScrollPosition.current = container?.scrollTop ?? null;
    isScrolledToBottom.current = container?.scrollHeight - container?.scrollTop - container?.clientHeight < 1;

    if (isScrolledToBottom.current && oneUndreadMessageCount) {
      dispatch(OneViewActions.resetOneUnreadMessagesCount());
    }
  };

  const debouncedScroll = debounce(handleScroll, 200);

  return {
    handleScroll: debouncedScroll,
    handleScrollToBottom: scrollToBottom,
  };
};
