import { useCallback, type FC, type MouseEvent } from 'react';

import { cx } from '@emotion/css';
import { Close } from '@livechat/design-system-icons';
import { Button, Icon } from '@livechat/design-system-react-components';
import { useDispatch } from 'react-redux';

import { GlobalModal } from 'constants/global-modal';
import { TopBarNotificationType } from 'constants/notifications';
import { EventPlace } from 'helpers/analytics';
import { GlobalModalActions } from 'store/features/global-modals/actions';

import { CLOSE_BUTTON_TEST_ID } from './constants';

import * as styles from './styles';

interface IProps {
  onFollowLinkClicked(): void;
  onSendCodeBtnClicked(): void;
  onSkip(name: TopBarNotificationType): void;
}

export const CodeNotInstalledBar: FC<IProps> = ({ onSendCodeBtnClicked, onFollowLinkClicked, onSkip }) => {
  const dispatch = useDispatch();

  const handleSendToDeveloperClick = useCallback((): void => {
    dispatch(GlobalModalActions.showModal(GlobalModal.InviteAgents, { eventPlace: EventPlace.TopBar }));

    onSendCodeBtnClicked();
  }, [onSendCodeBtnClicked, dispatch]);

  const handleSkipClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>): void => {
      e.stopPropagation();
      onSkip(TopBarNotificationType.InstallCode);
    },
    [onSkip]
  );

  return (
    <div className={cx(styles.contentContainer, styles.warningContainer)}>
      <div className={styles.codeNotInstalledBarContent}>
        <span className={styles.codeNotInstalledBarText}>Install LiveChat to start chatting with your customers.</span>
        <div className={styles.codeNotInstalledDesktopButtons}>
          <Button
            tabIndex={-1}
            className={styles.blackButton}
            kind="secondary"
            onClick={onFollowLinkClicked}
            size="compact"
          >
            Install now
          </Button>
          <Button
            tabIndex={-1}
            className={styles.blackButton}
            kind="secondary"
            onClick={handleSendToDeveloperClick}
            size="compact"
          >
            Invite developer
          </Button>
        </div>
        <div className={styles.codeNotInstalledMobileButtons}>
          <Button kind="link" onClick={onFollowLinkClicked} size="xcompact">
            Install now
          </Button>
          <Button kind="link" onClick={handleSendToDeveloperClick} size="xcompact">
            Invite developer
          </Button>
        </div>
      </div>
      <button tabIndex={-1} className={styles.iconButton} onClick={handleSkipClick} data-testid={CLOSE_BUTTON_TEST_ID}>
        <Icon source={Close} />
      </button>
    </div>
  );
};
