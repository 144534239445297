import { agentAssignmentFilterConfig } from 'components/filters/agent-assignment-filter/default-config';
import { agentFilterConfigMultiple } from 'components/filters/agent-filter/default-config-multiple';
import { availabilityFilterConfig } from 'components/filters/availability-filter/default-config';
import { channelFilterConfigMultiple } from 'components/filters/channel-filter/default-config';
import { countryIsoFilterConfigMultiple } from 'components/filters/country-iso-filter/country-iso-config';
import { dateFilterConfig } from 'components/filters/date-filter/default-config';
import { dateRangeFilterConfig } from 'components/filters/date-range-filter/default-config';
import { goalFilterConfigMultiple } from 'components/filters/goal-filter/default-config-multiple';
import { greetingFilterConfigMultiple } from 'components/filters/greeting-filter/default-config-multiple';
import { groupFilterConfigMultiple } from 'components/filters/group-filter/default-config-multiple';
import { type AvailableFilter, type FilterConfig } from 'components/filters/interfaces';
import { surveyTypeConfig } from 'components/filters/survey-type-filter/default-config';
import { tagFilterConfigMultiple } from 'components/filters/tag-filter/default-config-multiple';
import { weekdayFilterConfig } from 'components/filters/weekday-filter/default-config';
import { Filter, FilterOperator } from 'constants/filters/filter';

import { ReportType } from './report-type';

const MAX_VALUES_WITH_OPERATORS = 20;

export const filters: Partial<Record<Filter, AvailableFilter>> = {
  [Filter.DateRange]: {
    filter: Filter.DateRange,
    config: {
      ...dateRangeFilterConfig,
      removable: false,
      withCompare: true,
    },
  },
  [Filter.Date]: {
    filter: Filter.Date,
    config: {
      ...dateFilterConfig,
      removable: false,
    },
  },
  [Filter.Weekday]: {
    filter: Filter.Weekday,
    config: {
      ...weekdayFilterConfig,
      removable: false,
    },
  },
  [Filter.Agent]: {
    filter: Filter.Agent,
    config: {
      ...agentFilterConfigMultiple,
      withCompare: true,
    },
  },
  [Filter.Goal]: { filter: Filter.Goal, config: goalFilterConfigMultiple },
  [Filter.Group]: {
    filter: Filter.Group,
    config: {
      ...groupFilterConfigMultiple,
      withCompare: true,
    },
  },
  [Filter.SaleGoal]: {
    filter: Filter.SaleGoal,
    config: { ...goalFilterConfigMultiple, title: 'Sale', mobileViewTitle: 'Select sales' },
  },
  [Filter.Tag]: { filter: Filter.Tag, config: { ...tagFilterConfigMultiple, valuesLimit: MAX_VALUES_WITH_OPERATORS } },
  [Filter.Greeting]: { filter: Filter.Greeting, config: greetingFilterConfigMultiple },
  [Filter.SurveyType]: { filter: Filter.SurveyType, config: { ...surveyTypeConfig, removable: false } },
  [Filter.Channel]: { filter: Filter.Channel, config: channelFilterConfigMultiple },
  [Filter.AgentAssignment]: { filter: Filter.AgentAssignment, config: agentAssignmentFilterConfig },
  [Filter.Availability]: { filter: Filter.Availability, config: availabilityFilterConfig },
  [Filter.CountryISO]: {
    filter: Filter.CountryISO,
    config: { ...countryIsoFilterConfigMultiple },
  },
};

export type ICustomReportConfig = Partial<{
  [key in Filter]: FilterConfig;
}>;

export type IReportFiltersConfig = {
  filters: Filter[];
  customConfig?: ICustomReportConfig;
};

type ReportsFiltersConfig = {
  [key in ReportType]: IReportFiltersConfig;
};

export const reportsFilters: Partial<ReportsFiltersConfig> = {
  [ReportType.Activity]: { filters: [Filter.Date, Filter.Group, Filter.Agent] },
  [ReportType.TotalChats]: {
    filters: [
      Filter.DateRange,
      Filter.Channel,
      Filter.Group,
      Filter.Agent,
      Filter.Tag,
      Filter.AgentAssignment,
      Filter.Availability,
      Filter.CountryISO,
    ],

    customConfig: {
      [Filter.Tag]: { ...tagFilterConfigMultiple, withOperator: true },
      [Filter.CountryISO]: {
        ...countryIsoFilterConfigMultiple,
        withOperator: [FilterOperator.Or, FilterOperator.Exclude],
      },
    },
  },
  [ReportType.TotalChatsHeatmap]: {
    filters: [
      Filter.DateRange,
      Filter.Channel,
      Filter.Group,
      Filter.Agent,
      Filter.Tag,
      Filter.AgentAssignment,
      Filter.Availability,
      Filter.CountryISO,
    ],

    customConfig: {
      [Filter.Tag]: { ...tagFilterConfigMultiple, withOperator: true },
      [Filter.CountryISO]: {
        ...countryIsoFilterConfigMultiple,
        withOperator: [FilterOperator.Or, FilterOperator.Exclude],
      },
    },
  },
  [ReportType.ChatEngagement]: {
    filters: [Filter.DateRange, Filter.Group, Filter.Agent, Filter.Tag, Filter.CountryISO],
    customConfig: {
      [Filter.Tag]: { ...tagFilterConfigMultiple, withOperator: true },
      [Filter.CountryISO]: {
        ...countryIsoFilterConfigMultiple,
        withOperator: [FilterOperator.Or, FilterOperator.Exclude],
      },
    },
  },
  [ReportType.ChatRatings]: {
    filters: [Filter.DateRange, Filter.Channel, Filter.Agent, Filter.Group, Filter.Tag, Filter.CountryISO],
    customConfig: {
      [Filter.Tag]: { ...tagFilterConfigMultiple, withOperator: true },
      [Filter.CountryISO]: {
        ...countryIsoFilterConfigMultiple,
        withOperator: [FilterOperator.Or, FilterOperator.Exclude],
      },
    },
  },
  [ReportType.TicketRatings]: { filters: [Filter.DateRange, Filter.Agent, Filter.Group, Filter.Tag] },
  [ReportType.ChatDuration]: {
    filters: [
      Filter.DateRange,
      Filter.Channel,
      Filter.Agent,
      Filter.Group,
      Filter.Tag,
      Filter.AgentAssignment,
      Filter.Availability,
      Filter.CountryISO,
    ],
    customConfig: {
      [Filter.Tag]: { ...tagFilterConfigMultiple, withOperator: true },
      [Filter.CountryISO]: {
        ...countryIsoFilterConfigMultiple,
        withOperator: [FilterOperator.Or, FilterOperator.Exclude],
      },
    },
  },
  [ReportType.ChatResponseTime]: {
    filters: [Filter.DateRange, Filter.Channel, Filter.Agent, Filter.Group, Filter.Tag, Filter.CountryISO],
    customConfig: {
      [Filter.CountryISO]: {
        ...countryIsoFilterConfigMultiple,
        withOperator: [FilterOperator.Or, FilterOperator.Exclude],
      },
    },
  },
  [ReportType.ChatAvailability]: { filters: [Filter.DateRange, Filter.Agent, Filter.Group] },
  [ReportType.MissedChats]: {
    filters: [Filter.DateRange, Filter.Agent, Filter.Group, Filter.Tag, Filter.CountryISO],
    customConfig: {
      [Filter.Tag]: { ...tagFilterConfigMultiple, withOperator: true },
      [Filter.CountryISO]: {
        ...countryIsoFilterConfigMultiple,
        withOperator: [FilterOperator.Or, FilterOperator.Exclude],
      },
    },
  },
  [ReportType.MissedChatsHeatmap]: {
    filters: [Filter.DateRange, Filter.Agent, Filter.Group, Filter.Tag, Filter.CountryISO],
    customConfig: {
      [Filter.Tag]: { ...tagFilterConfigMultiple, withOperator: true },
      [Filter.CountryISO]: {
        ...countryIsoFilterConfigMultiple,
        withOperator: [FilterOperator.Or, FilterOperator.Exclude],
      },
    },
  },
  [ReportType.Greetings]: {
    filters: [Filter.DateRange, Filter.Group, Filter.Greeting, Filter.CountryISO],
    customConfig: {
      [Filter.CountryISO]: {
        ...countryIsoFilterConfigMultiple,
        withOperator: [FilterOperator.Or, FilterOperator.Exclude],
      },
    },
  },
  [ReportType.QueuedCustomers]: { filters: [Filter.DateRange, Filter.Group] },
  [ReportType.SolvedTickets]: { filters: [Filter.DateRange, Filter.Agent, Filter.Group, Filter.Tag] },
  [ReportType.TicketFirstResponseTime]: { filters: [Filter.DateRange, Filter.Agent, Filter.Group, Filter.Tag] },
  [ReportType.NewTickets]: { filters: [Filter.DateRange, Filter.Group, Filter.Tag] },
  [ReportType.TicketResolutionTime]: { filters: [Filter.DateRange, Filter.Agent, Filter.Group, Filter.Tag] },
  [ReportType.Goals]: { filters: [Filter.DateRange, Filter.Agent, Filter.Group, Filter.Goal, Filter.Tag] },
  [ReportType.Sales]: { filters: [Filter.DateRange, Filter.Agent, Filter.Group, Filter.SaleGoal, Filter.Tag] },
  [ReportType.StaffingPrediction]: { filters: [Filter.Weekday, Filter.Group] },
  [ReportType.ChatForms]: { filters: [Filter.DateRange, Filter.SurveyType, Filter.Group, Filter.Tag, Filter.Agent] },
  [ReportType.QueueAbandonment]: { filters: [Filter.DateRange, Filter.Group] },
  [ReportType.TagsUsage]: {
    filters: [Filter.DateRange, Filter.Group, Filter.Agent, Filter.Tag, Filter.AgentAssignment, Filter.CountryISO],
    customConfig: {
      [Filter.Tag]: { ...tagFilterConfigMultiple, withOperator: true },
      [Filter.CountryISO]: {
        ...countryIsoFilterConfigMultiple,
        withOperator: [FilterOperator.Or, FilterOperator.Exclude],
      },
    },
  },
  [ReportType.AgentsPerformance]: { filters: [Filter.DateRange, Filter.Group] },
  // ChatTopics report is fetched from different API than reports API
  // make sure that filters are compatible before adding a new one
  // filters that are currently supported can be found in schema definition
  // https://github.com/livechat/chats-analyser/blob/6bd4f98785bb67849a3c801abe2d087afa8d40b1/packages/common-schemas/topic-sentiment.ts#L14
  [ReportType.ChatTopics]: {
    filters: [Filter.DateRange, Filter.Agent, Filter.Group, Filter.Tag, Filter.Channel, Filter.CountryISO],
    customConfig: {
      [Filter.Tag]: { ...tagFilterConfigMultiple, withOperator: true },
      [Filter.CountryISO]: {
        ...countryIsoFilterConfigMultiple,
        withOperator: [FilterOperator.Or, FilterOperator.Exclude],
      },
    },
  },
};
