import { type FC } from 'react';

import { IN_APP_UPGRADES_HEADER } from '../constants';

import { ColLabel } from './ColLabel';
import * as styles from './in-app-upgrade-row/styles';

export const InAppUpgradeHeader: FC = () => (
  <div className={styles.headerRowWrapper}>
    <div className={styles.headerRow}>
      <div className={styles.name}>
        <ColLabel>{IN_APP_UPGRADES_HEADER.name}</ColLabel>
      </div>

      <div className={styles.price}>
        <ColLabel>{IN_APP_UPGRADES_HEADER.price}</ColLabel>
      </div>

      <div className={styles.summary}>
        <ColLabel>{IN_APP_UPGRADES_HEADER.summary}</ColLabel>
      </div>

      <div className={styles.action} />
    </div>
  </div>
);
