export const CLOSE_BUTTON_TEST_ID = 'close-button-testId';
export const TOP_BAR_HEIGHT = '48px';
export const MOBILE_TOP_BAR_HEIGHT = '70px';
export const MAIN_SECTION_PADDING = '6px';

export const CONNECTION_STATUS_OFFLINE_BAR_TEXT = "You're offline, check your connection.";
export const CONNECTION_STATUS_ONLINE_BAR_TEXT = 'Connection restored';
export const CONNECTION_STATUS_RECONNECTING_BAR_TEXT = 'Reconnecting...';
export const CONNECTION_STATUS_REDIRECTING_BAR_TEXT = 'Redirecting...';

export const NOTIFICATIONS_TOP_BAR_TEST_ID = 'notifications-top-bar';
export const NOTIFICATIONS_NEW_TOP_BAR_TEST_ID = 'notifications-new-top-bar';

export const NAVIGATION_WIDTH = 65;

export const CLOSE_ALERT_TEST_ID = 'close-alert';

export const ONE_TRIGGER_TEST_ID = 'one-trigger';
