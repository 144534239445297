import type { FC } from 'react';

import { cx } from '@emotion/css';

import { CONNECTION_STATUS_OFFLINE_BAR_TEXT } from './constants';

import * as styles from './styles';

export const OfflineBar: FC = () => {
  return (
    <div className={cx(styles.contentContainer, styles.warningContainer)}>
      <span>{CONNECTION_STATUS_OFFLINE_BAR_TEXT}</span>
    </div>
  );
};
