// @ts-strict-ignore
import { type IConnection } from './connection';
import { type ConnectionPool } from './connection-pool';
import { getIframeOrigin } from './helpers';
import { createPlainConnection } from './plain-connection';
import { createTrustedConnection } from './trusted-connection';

interface IConnectionOptions {
  onReconnect?(): void;
}

export interface IIframeConnector {
  (iframe: HTMLIFrameElement, iframeUrl: string, opts?: IConnectionOptions): Promise<IConnection>;
}

export const createConnection: IIframeConnector = (
  iframe: HTMLIFrameElement,
  url: string,
  options?: IConnectionOptions
): Promise<IConnection> =>
  new Promise((resolve, reject) => {
    let canProceed = true;

    const onConnectionError = (): void => {
      if (canProceed) {
        canProceed = false;

        return;
      }
      reject(new Error(`Could not establish connection with the widget under ${url}`));
    };

    // Perform a race between two connections and see which one will resolve first.
    createTrustedConnection(iframe, options).then(resolve).catch(onConnectionError);

    createPlainConnection(iframe, getIframeOrigin(url)).then(resolve).catch(onConnectionError);
  });

export const destroyConnection = (connection: Promise<IConnection>, connectionPool: ConnectionPool) => {
  connection.then((connectionToDestroy) => {
    if (!connectionToDestroy) {
      return;
    }

    connectionPool.remove(connectionToDestroy.connectionId);
    try {
      connectionToDestroy.destroy();
    } catch (err) {
      if (err.code !== 'DESTROYED') {
        throw err;
      }
    }
  });
};
