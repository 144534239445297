import { differenceInCalendarDays } from 'date-fns';
import uniqBy from 'lodash.uniqby';
import { type SagaIterator } from 'redux-saga';
import { call, delay, put, select, takeEvery } from 'redux-saga/effects';

import { ChatType } from 'constants/chat-type';
import { CHAT_FEED_TEXT_AREA_TEST_ID } from 'constants/chats/chat-message-box';
import { FAILED_TO_FETCH_ERROR } from 'constants/failed-to-fetch';
import { GlobalModal } from 'constants/global-modal';
import { ReasonCode } from 'constants/knowledge-sources-reason-codes';
import { PlanType } from 'constants/plan-type';
import { MINUTE_IN_MS, ReplySuggestionEvent } from 'constants/reply-suggestions';
import { SprigEvents } from 'constants/sprig-events';
import { UserType } from 'constants/user-type';
import { EventPlace } from 'helpers/analytics';
import { type KeyMap } from 'helpers/interface';
import { mapSources } from 'helpers/one/one';
import { shouldShowReplySuggestionsPromo } from 'helpers/should-show-reply-suggestions-promo';
import { uniqueId } from 'helpers/string';
import type { RequestResult } from 'interfaces/api/client';
import { ApiManager } from 'services/api/api-manager';
import type {
  IGenerateReplyError,
  IGenerateReplyRequest,
  IGenerateReplyResponse,
} from 'services/api/ml-gateway/interfaces/ai-agents';
import { trackEvent } from 'services/event-tracking';
import { knowledgeSourcesEventCollector } from 'services/knowledge-sources-event-collector';
import { getSprigService } from 'services/sprig';
import { ChatsEntitiesActionNames } from 'store/entities/chats/actions';
import { getMessagesForResponseSuggestion } from 'store/entities/chats/computed';
import { type ChatEventEntity, type ChatThreadEntity, type INewMessagePayload } from 'store/entities/chats/interfaces';
import { getThread, getThreadEvents } from 'store/entities/chats/selectors';
import { AgentCustomPropertiesActions } from 'store/features/agent-custom-properties/actions';
import {
  AgentCustomPropertyName,
  type IReplySuggestionsPromoCustomProperty,
} from 'store/features/agent-custom-properties/interfaces';
import {
  getIsReplySuggestionsPromoModalSeen,
  getKnowledgeSourcesTooltipSeen,
  getReplySuggestionsOnboardingSeen,
  getReplySuggestionsPromo,
} from 'store/features/agent-custom-properties/selectors';
import { GlobalModalActions } from 'store/features/global-modals/actions';
import { getPlanType } from 'store/features/session/selectors';
import { type IActionWithPayload } from 'store/helper';

import { ChatsViewActions, ChatsViewActionsNames } from '../actions';
import { MAXIMUM_REQUESTS_FOR_GENERATE_REPLY } from '../constants';
import type { IReplySuggestionsGenerateReplyPayload } from '../interfaces';
import {
  getCanUseReplySuggestionsFeature,
  getCurrentMessageBoxValue,
  getIsReplySuggestionTriggered,
  getNumberOfCustomerMessagesInThread,
  getReplySuggestionsCurrentSessionId,
  getSelectedThreadId,
} from '../selectors';

import { getHasAnyKnowledgeSources, getIsTheSameMessage } from './helpers';

function* handleReplySuggestionPromo(): SagaIterator {
  const replySuggestionsPromo: IReplySuggestionsPromoCustomProperty = yield select(getReplySuggestionsPromo);

  if (!replySuggestionsPromo || replySuggestionsPromo?.displayedSprigFormCount >= 2) {
    return;
  }

  const { replySuggestionCount, dateOfFirstUsage, displayedSprigFormCount } = replySuggestionsPromo;

  const updatedReplySuggestionCount = replySuggestionCount + 1;
  const updatedDateOfFirstUsage = dateOfFirstUsage ? dateOfFirstUsage : new Date();
  const differenceInDaysWithFirstUsage = differenceInCalendarDays(new Date(), updatedDateOfFirstUsage);

  const isFirstUsage = updatedReplySuggestionCount === 1;
  const isSecondUsage =
    updatedReplySuggestionCount > 15 && differenceInDaysWithFirstUsage > 7 && displayedSprigFormCount < 2;
  const shouldDisplayReplySuggestionForm = isFirstUsage || isSecondUsage;

  const updatedDisplayedSprigFormCount = shouldDisplayReplySuggestionForm
    ? displayedSprigFormCount + 1
    : displayedSprigFormCount;

  if (shouldDisplayReplySuggestionForm) {
    const sprigEvent = isFirstUsage
      ? SprigEvents.ReplySuggestionGeneratedFirstTime
      : SprigEvents.ReplySuggestionGenerated;

    const sprig = getSprigService();
    yield call([sprig, sprig.initSprigEvent], sprigEvent);
  }

  yield put(
    AgentCustomPropertiesActions.setAgentCustomProperty({
      [AgentCustomPropertyName.ReplySuggestionsPromo]: {
        dateOfFirstUsage: updatedDateOfFirstUsage,
        replySuggestionCount: updatedReplySuggestionCount,
        displayedSprigFormCount: updatedDisplayedSprigFormCount,
      },
    })
  );
}

function handleReplySuggestionEvents(reasonCode?: ReasonCode): void {
  switch (reasonCode) {
    case ReasonCode.NoAgents:
    case ReasonCode.NoSkills: {
      trackEvent(ReplySuggestionEvent.NoSourcesErrorShown, EventPlace.Chats);
      break;
    }

    case ReasonCode.NoKnowledge: {
      trackEvent(ReplySuggestionEvent.NoDataErrorShown, EventPlace.Chats);
      break;
    }

    default:
      trackEvent(ReplySuggestionEvent.ErrorShown, EventPlace.Chats);
  }
}

/**
 * This is a helper function, which retries a call to AI Agent due to long response time.
 * It's basically a simple long-polling mechanism, which retries a call (max 5 times) and waits for response
 */
function* regenerateResponse(payload: IGenerateReplyRequest, threadId: string, currentCount = 0): SagaIterator {
  const response: RequestResult<IGenerateReplyResponse, IGenerateReplyError> = yield call(
    ApiManager.mlGatewayApi.generateReply,
    payload
  );
  const currentSessionId = yield select(getReplySuggestionsCurrentSessionId, threadId);

  if (currentSessionId !== payload.session_id) {
    return {};
  }

  if (currentCount >= MAXIMUM_REQUESTS_FOR_GENERATE_REPLY) {
    return {};
  }

  // If "Failed to fetch" is present we can be sure to re-fetch such call
  if (response.error?.message === FAILED_TO_FETCH_ERROR) {
    yield call(regenerateResponse, payload, threadId, currentCount + 1);
  }

  return response;
}

function* generateResponse(action: IActionWithPayload<string, IReplySuggestionsGenerateReplyPayload>): SagaIterator {
  const { threadId } = action.payload;
  const customerMessagesCount = yield select(getNumberOfCustomerMessagesInThread, threadId);

  if (!customerMessagesCount) {
    return;
  }

  const message: string = yield select(getMessagesForResponseSuggestion, threadId);

  yield put(
    ChatsViewActions.saveReplySuggestionToolbarState({
      threadId,
      isLoading: true,
      metadata: {
        message,
        replyTime: 0,
      },
    })
  );

  const sessionId = uniqueId();
  const params: IGenerateReplyRequest = {
    messages: [
      {
        value: message,
      },
    ],
    /* eslint-disable-next-line @typescript-eslint/naming-convention */
    session_id: sessionId,
    executor: 'simple',
  };

  yield put(
    ChatsViewActions.setReplySuggestionSessionId({
      threadId,
      sessionId,
    })
  );

  const { result, error }: RequestResult<IGenerateReplyResponse, IGenerateReplyError> = yield call(
    regenerateResponse,
    params,
    threadId
  );

  const currentRequestId = yield select(getReplySuggestionsCurrentSessionId, threadId);

  if (error) {
    if (error?.innerError?.session_id !== sessionId) {
      return;
    }

    handleReplySuggestionEvents(error.innerError?.reason_code);

    yield put(
      ChatsViewActions.saveReplySuggestionToolbarState({
        threadId,
        isLoading: false,
        error: {
          code: error.innerError?.code || 500,
          message: error.innerError?.message,
          reasonCode: error.innerError?.reason_code,
        },
        metadata: {
          message,
          replyTime: 0,
        },
      })
    );

    return;
  }

  if (!result || result.session_id !== currentRequestId) {
    return;
  }

  knowledgeSourcesEventCollector.addEvent(threadId, {
    agentResponse: '',
    requestId: sessionId,
    useButtonClick: false,
    isAutomaticSuggestion: false,
  });

  yield call(handleReplySuggestionPromo);

  yield put(
    ChatsViewActions.saveReplySuggestionToolbarState({
      threadId,
      isLoading: false,
      metadata: {
        message,
        replyTime: result.metadata.response_time,
        traceId: result.trace_id,
      },
      data: {
        response: result.response,
        sources: uniqBy(mapSources(result.sources.filter(({ type }) => type === 'api_call' || type === 'url')), 'url'),
      },
    })
  );

  trackEvent(ReplySuggestionEvent.ReplyReceived, EventPlace.Chats, { version: 'B' });
}

function* showPromoModal(): SagaIterator {
  yield delay(10000);

  const plan = yield select(getPlanType);
  const isBusiness = plan === PlanType.Business;

  const isReplySuggestionsEnabled = yield select(getCanUseReplySuggestionsFeature);
  const shouldShowPromo = shouldShowReplySuggestionsPromo();

  const isReplySuggestionsPromoModalEnterpriseSeen = yield select(getKnowledgeSourcesTooltipSeen);
  const isReplySuggestionsPromoModalSeen = yield select(getIsReplySuggestionsPromoModalSeen);

  const isAlreadySeen = isBusiness ? isReplySuggestionsPromoModalSeen : isReplySuggestionsPromoModalEnterpriseSeen;

  if (!isReplySuggestionsEnabled || !shouldShowPromo || isAlreadySeen) {
    return;
  }

  yield put(GlobalModalActions.showModal(GlobalModal.KnowledgeSourcesPromoModal));
}

export function* getShouldShowReplySuggestionsOnboarding(payload: INewMessagePayload): SagaIterator {
  const { threadId, message } = payload;
  const selectedThreadId: string = yield select(getSelectedThreadId);
  const threadEvents: KeyMap<ChatEventEntity> = yield select(getThreadEvents, selectedThreadId);
  const currentMessageBoxValue: string = yield select(getCurrentMessageBoxValue);

  const isTheSameThread = threadId === selectedThreadId;
  const isTheSameMessage = getIsTheSameMessage(threadEvents, message.id);
  const hasAnyKnowledgeSources = getHasAnyKnowledgeSources();

  const isMessageBoxFocused =
    document.activeElement instanceof HTMLElement
      ? document.activeElement.dataset?.testid === CHAT_FEED_TEXT_AREA_TEST_ID
      : false;

  const isAgentTyping = isMessageBoxFocused || currentMessageBoxValue.length > 0;

  return !isAgentTyping && hasAnyKnowledgeSources && isTheSameThread && isTheSameMessage;
}

export function* showReplySuggestionOnboarding({
  payload,
}: IActionWithPayload<string, INewMessagePayload>): SagaIterator {
  const isOnboardingAlreadySeen = yield select(getReplySuggestionsOnboardingSeen);
  const canUseReplySuggestion = yield select(getCanUseReplySuggestionsFeature);
  const thread: ChatThreadEntity = yield select(getThread, payload.threadId);

  const isMyChat = thread.type === ChatType.My;
  const isCustomerMessage = payload.message.authorType === UserType.Customer;

  /**
   * There are two checks if onboarding can be seen - one can be done at start to provide early return
   * the other checks needs to be verified after ONE_MINUTE as requirement from business perspective
   */
  if (isOnboardingAlreadySeen || !canUseReplySuggestion || !isMyChat || !isCustomerMessage) {
    return;
  }

  yield delay(MINUTE_IN_MS);

  const shouldShowReplySuggestionsOnboarding = yield call(getShouldShowReplySuggestionsOnboarding, payload);
  const isReplySuggestionTriggered = yield select(getIsReplySuggestionTriggered, payload.threadId);

  if (!shouldShowReplySuggestionsOnboarding || isReplySuggestionTriggered) {
    return;
  }

  const message: string = yield select(getMessagesForResponseSuggestion, payload.threadId);
  const sessionId = uniqueId();

  const response: RequestResult<IGenerateReplyResponse, IGenerateReplyError> = yield call(
    ApiManager.mlGatewayApi.generateReply,
    {
      messages: [
        {
          value: message,
        },
      ],

      /* eslint-disable @typescript-eslint/naming-convention */
      session_id: sessionId,
      auto_triggered: true,
      /* eslint-enable @typescript-eslint/naming-convention */
      executor: 'simple',
    }
  );

  const isReplySuggestionTriggeredAfterGenerate = yield select(getIsReplySuggestionTriggered, payload.threadId);

  if (isReplySuggestionTriggeredAfterGenerate || response.error) {
    return;
  }

  knowledgeSourcesEventCollector.addEvent(payload.threadId, {
    agentResponse: '',
    requestId: sessionId,
    useButtonClick: false,
    isAutomaticSuggestion: true,
  });

  yield put(
    ChatsViewActions.saveReplySuggestionToolbarState({
      threadId: payload.threadId,
      isLoading: false,
      metadata: {
        message,
        replyTime: response.result.metadata.response_time,
      },
      data: {
        response: response.result.response,
        sources: uniqBy(
          mapSources(response.result.sources.filter(({ type }) => type === 'api_call' || type === 'url')),
          'url'
        ),
      },
      isOnboardingSuggestion: true,
    })
  );

  trackEvent(ReplySuggestionEvent.ReplySuggestionOnboardingShown, EventPlace.Chats);
}

export function* replySuggestionsSaga(): SagaIterator {
  yield takeEvery(ChatsViewActionsNames.GENERATE_REPLY_SUGGESTIONS_REPLY, generateResponse);
  yield takeEvery(ChatsEntitiesActionNames.NEW_MESSAGE, showReplySuggestionOnboarding);
  yield takeEvery('APP_READY', showPromoModal);
}
