import { type FC, type ReactElement, useEffect } from 'react';

import { cx } from '@emotion/css';
import { Close, OneColored } from '@livechat/design-system-icons';
import { Button, Icon, Tag, Text, Tooltip } from '@livechat/design-system-react-components';
import uniqueId from 'lodash.uniqueid';
import { useDispatch, useSelector } from 'react-redux';

import { getModKey } from 'helpers/keyboard';
import { getLoggedInAgentName } from 'store/entities/agents/selectors';
import { OneEntitiesActions } from 'store/entities/one/actions';
import { AgentCustomPropertiesActions } from 'store/features/agent-custom-properties/actions';
import { AgentCustomPropertyName } from 'store/features/agent-custom-properties/interfaces';
import { getSectionName } from 'store/features/routing/selectors';
import { OneViewActions } from 'store/views/one/actions';

import * as styles from '../styles';

import { SPOTLIGHT_ACTIONS_ONBOARDING_MESSAGE } from './constants';

interface SpotlightActionsOnboardingTooltipProps {
  isVisible: boolean;
  children: ReactElement;
}

const SpotlightActionsOnboardingTooltip: FC<SpotlightActionsOnboardingTooltipProps> = ({ isVisible, children }) => {
  const dispatch = useDispatch();
  const loggedAgentName = useSelector(getLoggedInAgentName);
  const currentSection = useSelector(getSectionName);

  const handleTellMeMoreClick = (): void => {
    dispatch(OneViewActions.showModal());

    dispatch(
      AgentCustomPropertiesActions.setAgentCustomProperty({
        [AgentCustomPropertyName.SpotlightActionsOnboardingSeen]: '1',
      })
    );

    dispatch(
      OneEntitiesActions.addOneConversation([
        {
          event: {
            authorId: 'agent',
            authorType: 'agent',
            eventId: uniqueId(),
            text: SPOTLIGHT_ACTIONS_ONBOARDING_MESSAGE.agent,
            timestampInMs: Date.now(),
            type: 'text-message',
          },
          delay: SPOTLIGHT_ACTIONS_ONBOARDING_MESSAGE.delay,
        },
        {
          event: {
            authorId: 'live-assistant',
            authorType: 'live-assistant',
            eventId: uniqueId(),
            text: SPOTLIGHT_ACTIONS_ONBOARDING_MESSAGE.one,
            timestampInMs: Date.now(),
            type: 'text-message',
          },
        },
      ])
    );
  };

  const handleSetOnboardingSeen = (): void => {
    if (isVisible) {
      dispatch(
        AgentCustomPropertiesActions.setAgentCustomProperty({
          [AgentCustomPropertyName.SpotlightActionsOnboardingSeen]: '1',
        })
      );
    }
  };

  useEffect(() => {
    handleSetOnboardingSeen();
  }, [currentSection]);

  return (
    <Tooltip
      isVisible={isVisible}
      placement="bottom"
      triggerRenderer={children}
      triggerClassName={styles.actionsOnboardingTrigger}
      className={cx(styles.actionsOnboardingTooltip, 'lc-dark-theme')}
    >
      <div>
        <div className={styles.actionsOnboardingHeader}>
          <Icon source={OneColored} />
          <Text>Hey {loggedAgentName}</Text>
        </div>
        <Button className={styles.closeBtn} kind="text" onClick={handleSetOnboardingSeen}>
          <Icon source={Close} />
        </Button>
        <Text className={styles.actionsOnboardingText}>
          Did you know you can change your chatting status directly from the search bar? Check all available actions!
        </Text>
        <div className={styles.actionsOnboardingShortcutWrapper}>
          <Tag className={styles.actionsOnboardingBlackTag}>
            <Text>{getModKey(true)}</Text>
          </Tag>
          <Tag className={styles.actionsOnboardingBlackTag}>
            <Text>K</Text>
          </Tag>
        </div>
        <Button kind="high-contrast" onClick={handleTellMeMoreClick}>
          Tell me more
        </Button>
      </div>
    </Tooltip>
  );
};

export default SpotlightActionsOnboardingTooltip;
