import { addHours, differenceInHours, getTime } from 'date-fns';
import throttle from 'lodash.throttle';

import { App } from 'config/setup';
import { ApplicationPlatforms, getApplicationPlatform, wasPageRefreshed } from 'helpers/browser';
import { trackEvent } from 'services/event-tracking';

import { type AmplitudeEventProperties } from './amplitude';

enum SocketActivityLocalStorageKey {
  LastActivity = 'last-activity',
  NextContinuousSession = 'next-continuous-session',
}

enum SocketActivitySessionType {
  New = 'new',
  Continuous = 'continuous',
}

const NEW_SESSION_THRESHOLD_HOURS = 4;
const CONTINUOUS_SESSION_THRESHOLD_HOURS = 24;
const SAVE_LAST_ACTIVITY_THROTTLE_IN_MS = 30000;

function isAFreshApplicationStartup(): boolean {
  return !App.INITED && !wasPageRefreshed();
}

export function isNewSession(): boolean {
  const lastActivityValue = localStorage.getItem(SocketActivityLocalStorageKey.LastActivity);
  if (!lastActivityValue) {
    return true;
  }

  const lastActivityTimeStamp = parseInt(lastActivityValue, 10);

  return differenceInHours(new Date(), lastActivityTimeStamp) >= NEW_SESSION_THRESHOLD_HOURS;
}

export function isNewContinuousSession(): boolean {
  const nextContinuousSessionValue = localStorage.getItem(SocketActivityLocalStorageKey.NextContinuousSession);
  if (!nextContinuousSessionValue) {
    return true;
  }

  const nextContinuousSession = parseInt(nextContinuousSessionValue, 10);

  return nextContinuousSession <= getTime(new Date());
}

export function saveLastActivity(): void {
  const now = String(getTime(new Date()));
  localStorage.setItem(SocketActivityLocalStorageKey.LastActivity, now);
}

export function saveNextContinuousSession(): void {
  const nextContinuousSession = String(getTime(addHours(new Date(), CONTINUOUS_SESSION_THRESHOLD_HOURS)));
  localStorage.setItem(SocketActivityLocalStorageKey.NextContinuousSession, nextContinuousSession);
}

export function clearSocketActivity(): void {
  localStorage.removeItem(SocketActivityLocalStorageKey.LastActivity);
  localStorage.removeItem(SocketActivityLocalStorageKey.NextContinuousSession);
}

function reportLoggedInEvent(session: SocketActivitySessionType): void {
  const applicationType = getApplicationPlatform();
  const isDesktop = applicationType === ApplicationPlatforms.Desktop;

  const properties: AmplitudeEventProperties = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'app version': isDesktop ? App.SmartClient?.getVersion() : null,
    app: applicationType,
    session,
  };

  trackEvent('Logged in', null, properties);
  saveLastActivity();
  saveNextContinuousSession();
}

export const triggerLastActivity = throttle(() => {
  if (!isAFreshApplicationStartup()) {
    saveLastActivity();
  }
}, SAVE_LAST_ACTIVITY_THROTTLE_IN_MS);

export function tryToLogUserSession(): void {
  if (isNewSession()) {
    reportLoggedInEvent(SocketActivitySessionType.New);
  } else if (isNewContinuousSession()) {
    reportLoggedInEvent(SocketActivitySessionType.Continuous);
  }
}
