import type { FC } from 'react';

import { cx } from '@emotion/css';

import { CONNECTION_STATUS_ONLINE_BAR_TEXT } from './constants';

import * as styles from './styles';

export const ConnectionRestoredBar: FC = () => {
  return (
    <div className={cx(styles.contentContainer, styles.containerSuccess)}>
      <span>{CONNECTION_STATUS_ONLINE_BAR_TEXT}</span>
    </div>
  );
};
