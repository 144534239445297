import type { KeyMap } from 'helpers/interface';
import type { RequestFailure , EntityData } from 'store/entities/interfaces';

import type {
  IGeolocationDetails,
  ICustomerStatistics,
  ICustomerBase,
  ICustomerVisitDetails,
  ICustomerIntegrations,
} from '../common/interfaces';

export interface ICustomer extends ICustomerBase {
  geolocation?: IGeolocationDetails;
  statistics?: ICustomerStatistics;
  visitDetails?: ICustomerVisitDetails;
  integrations?: ICustomerIntegrations; // To be merged with integrationVariables
  customVariables?: KeyMap<CustomerCustomVariableName | string>;
  integrationVariables?: KeyMap<IIntegrationVariables>;
}

export interface IField {
  name: string;
  value: string;
  url?: string;
}

export interface IIntegrationVariables {
  id: string;
  icon?: string;
  fields?: IField[];
}

export enum IntegrationVariableId {
  LiveChatTickets = 'livechat-tickets',
  ChatIdIntegration = 'chat-id-integration',
  CrmClientInfo = 'crm-client-info',
}

export interface IVisitedPage {
  pageURL: string;
  timestampInMS: number;
  title: string;
}

export interface ICustomersState extends EntityData<ICustomer> {
  visitedPages: KeyMap<EntityData<IVisitedPage>>;
  removedByIds?: KeyMap<ICustomer>;
}

export interface ICustomerDetails {
  customer: ICustomer;
  visitedPages?: KeyMap<IVisitedPage>;
}

export interface IRemoveCustomerPayload {
  customerId: string;
}

export interface IRemoveCustomersPayload {
  customerIds: string[];
}

export interface ISetCustomersDataPayload {
  customers: KeyMap<ICustomer>;
}

export interface IAddOrUpdateCustomersPayload {
  customers: KeyMap<ICustomer>;
  visitedPages?: KeyMap<KeyMap<IVisitedPage>>;
}

export interface IAddVisitedPagePayload {
  customerId: string;
  visitedPage: IVisitedPage;
}

export interface IUpdateCustomerPayload extends ICustomerDetails {}

export interface IUpdateCustomerInfoPayload {
  userId: string;
  name: string;
  email: string;
}

export interface UpdateAccessibleThreadNavigation {
  previousAccessibleThreadId?: string;
  nextAccessibleThreadId?: string;
  archiveId: string;
}

export interface IUpdateCustomerInfoSuccessPayload extends IUpdateCustomerInfoPayload {}
export interface IUpdateCustomerInfoFailurePayload extends RequestFailure {}

export enum CustomerCustomVariableName {
  Intent = '__intent',
}

export const CustomerInternalVariables: string[] = Object.keys(CustomerCustomVariableName).map(
  (key) => CustomerCustomVariableName[key] as string
);
