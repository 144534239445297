import { PlanType } from 'constants/plan-type';
import { AppStateProvider } from 'services/app-state-provider';
import {
  getReplySuggestionsStatus,
  getTextEnhancementsStatus,
} from 'store/entities/integration-license-properties/selectors';

import { FORCED_FEATURE_MAPPER } from './constants';
import type { IEarlyAccessItemConfig, IReadyToUseItemConfig, IWorthCheckingOutConfig } from './interfaces';

export const EARLY_ACCESS_ITEM_CONFIG: IEarlyAccessItemConfig[] = [];

export const READY_TO_USE_CONFIG: IReadyToUseItemConfig[] = [
  {
    readyToUseKey: 'reply-suggestions',
    title: 'Reply suggestions',
    description:
      'Get ready-to-use reply suggestions while chatting. These are generated from the knowledge sources added by your admins.',
    image: {
      url: 'https://cdn.livechat-static.com/api/file/lc/img/default/assets/reply-suggestions-v2.png',
      darkModeUrl: 'https://cdn.livechat-static.com/api/file/lc/img/default/assets/reply-suggestions-dark-v2.png',
    },
    navigateButton: { path: '/settings/reply-suggestions', label: 'Go to settings' },
    requiredPlan: PlanType.Team,
    getFeatureStatus: () => FORCED_FEATURE_MAPPER[AppStateProvider.selectFromStore(getReplySuggestionsStatus)],
  },
  {
    readyToUseKey: 'auto-tags',
    title: 'Auto-tags with canned responses',
    description:
      'Assign tags to specific canned responses to automatically apply tags to chats whenever you use the canned response.',
    image: {
      url: 'https://cdn.livechat-static.com/api/file/lc/img/default/assets/auto-tags-v2.png',
      darkModeUrl: 'https://cdn.livechat-static.com/api/file/lc/img/default/assets/auto-tags-dark-v2.png',
    },
    learnMoreUrl: 'https://www.livechat.com/help/set-canned-responses/#combine-tags-with-canned-responses',
    navigateButton: { path: '/settings/canned-responses', label: 'Go to settings' },
    requiredPlan: PlanType.Team,
  },
  {
    readyToUseKey: 'chat-summary',
    title: 'Chat Summary',
    description:
      'Get a bullet point summary of your chats to get the gist of your conversation without reading through the entire transcript.',
    image: {
      url: 'https://cdn.livechat-static.com/api/file/lc/img/default/assets/chat-summary-v2.png',
      darkModeUrl: 'https://cdn.livechat-static.com/api/file/lc/img/default/assets/chat-summary-dark-v2.png',
    },
    learnMoreUrl: 'https://www.livechat.com/help/customer-details/#chat-summary',
    requiredPlan: PlanType.Business,
  },
  {
    readyToUseKey: 'chatbot',
    title: 'ChatBot',
    description:
      'Build a bot with no coding skills that will provide round the clock support and take a thousand chats at the same time.',
    image: {
      url: 'https://cdn.livechat-static.com/api/file/lc/img/default/assets/chatbot-v2.png',
      darkModeUrl: 'https://cdn.livechat-static.com/api/file/lc/img/default/assets/chatbot-dark-v2.png',
    },
    navigateButton: { path: '/team/chatbots', label: 'Go to chatbot' },
    requiredPlan: PlanType.Starter,
  },
  {
    readyToUseKey: 'text-enhancements',
    title: 'Text Enhancements',
    description:
      'Adjust the tone, rephrase, summarize, and expand your messages. You can also run a grammar and spell check.',
    image: {
      url: 'https://cdn.livechat-static.com/api/file/lc/img/default/assets/text-enhancements.png',
      darkModeUrl: 'https://cdn.livechat-static.com/api/file/lc/img/default/assets/text-enhancements-dark.png',
    },
    learnMoreUrl: 'https://www.livechat.com/help/ai-text-suggestions-your-agents-writing-assistant/',
    requiredPlan: PlanType.Business,
    getFeatureStatus: () => FORCED_FEATURE_MAPPER[AppStateProvider.selectFromStore(getTextEnhancementsStatus)],
  },

  {
    readyToUseKey: 'tag-suggestions',
    title: 'Tag Suggestions',
    description: 'Get one-click suggestions to tag your chats. Requires a minimum of 100 manually tagged chats.',
    image: {
      url: 'https://cdn.livechat-static.com/api/file/lc/img/default/assets/tag-suggestions-v2.png',
      darkModeUrl: 'https://cdn.livechat-static.com/api/file/lc/img/default/assets/tag-suggestions-dark-v2.png',
    },
    requiredPlan: PlanType.Team,
  },
  {
    readyToUseKey: 'customer-sentiment',
    title: 'Customer sentiment',
    description: 'Monitor customer satisfaction level in real time to improve your communication with clients.',
    image: {
      url: 'https://cdn.livechat-static.com/api/file/lc/img/default/assets/customer-sentiment-v2.png',
      darkModeUrl: 'https://cdn.livechat-static.com/api/file/lc/img/default/assets/customer-sentiment-dark-v2.png',
    },
    learnMoreUrl: 'https://www.livechat.com/help/customer-sentiment-analysis/',
    requiredPlan: PlanType.EnterprisePlus,
  },
  {
    readyToUseKey: 'canned-suggestions',
    title: 'Event-based canned response suggestions',
    description: 'Get canned response suggestions triggered by specific events happening in the chat.',
    image: {
      url: 'https://cdn.livechat-static.com/api/file/lc/img/default/assets/event-based-canned-response-v2.png',
      darkModeUrl:
        'https://cdn.livechat-static.com/api/file/lc/img/default/assets/event-based-canned-response-dark-v2.png',
    },
    navigateButton: { path: '/settings/suggested-responses', label: 'Go to settings' },
    requiredPlan: PlanType.Team,
  },
];

export const WORTH_CHECKING_OUT_CONFIG: IWorthCheckingOutConfig[] = [
  {
    worthCheckingOutKey: 'agent-trainer',
    title: 'Agent Trainer',
    description: 'Automated agent training with customer simulation.',
    image: {
      url: 'https://cdn.livechat-static.com/api/file/lc/img/default/assets/agent-trainer.png',
      darkModeUrl: 'https://cdn.livechat-static.com/api/file/lc/img/default/assets/agent-trainer.png',
    },
    marketplacePath: '/apps/agenttrainer',
  },
  {
    worthCheckingOutKey: 'user-evaluation',
    title: 'User Evaluation',
    description: 'Get AI insights from LiveChat chat conversations',
    image: {
      url: 'https://cdn.livechat-static.com/api/file/lc/img/default/assets/user-evaluation.png',
      darkModeUrl: 'https://cdn.livechat-static.com/api/file/lc/img/default/assets/user-evaluation.png',
    },
    marketplacePath: '/apps/user-evaluation',
  },
];
