import { CustomerDetailsSectionMessageDenormalizer } from './customer-details-section-message';
import { type IInboxMessage, type INormalizedInboxMessage } from './inbox-message';
import { type IInboxMessageDenormalizer } from './inbox-message-denormalizer';
import { PluginInitedMessageDenormalizer } from './plugin-inited-message';
import { PluginLoadedMessageDenormalizer } from './plugin-loaded-message';

const inboxMessageDenormalizers = new Map(
  [PluginLoadedMessageDenormalizer, PluginInitedMessageDenormalizer, CustomerDetailsSectionMessageDenormalizer].map(
    (denormalizer) => [denormalizer.messageTitle, denormalizer] as [string, IInboxMessageDenormalizer]
  )
);

export function createInboxMessage(title: string, pluginId: string, data: any = null): IInboxMessage {
  return { title, pluginId, data };
}

export function denormalizeInboxMessage(message: INormalizedInboxMessage): IInboxMessage {
  const denormalizer = inboxMessageDenormalizers.get(message.message);

  return denormalizer
    ? denormalizer.createMessage(message)
    : createInboxMessage(message.message, message.plugin_id, message.data);
}
