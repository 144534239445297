// @ts-strict-ignore
import { saveAs } from 'file-saver';
import { type AnyAction, type Store } from 'redux';

import { LEGAL_REMINDER_FOR_BUG_REPORT } from 'constants/report-bug';
import type { IStoreState } from 'interfaces/store/store-state';
import { consoleLogHistoryManager } from 'services/console-log-history-manager';
import { eventsStack as requestsEventsStack } from 'services/request-events-history-manager';
import { websocketEventsHistoryManager } from 'services/web-sockets-events-history-manager';

export async function generateIssueReport(store: Store<IStoreState, AnyAction>, data): Promise<void> {
  const wsEventsStack = websocketEventsHistoryManager.geEvents();

  const JSZip = (await import(/* webpackMode: "lazy" */ 'jszip')).default;
  const jsZip = new JSZip();
  const state = store.getState();

  jsZip.file('legal.txt', LEGAL_REMINDER_FOR_BUG_REPORT);
  jsZip.file('livechat_actions.json', data.payload);
  jsZip.file('livechat_state.json', JSON.stringify(state));
  jsZip.file('livechat_web_sockets_events.json', JSON.stringify(wsEventsStack));
  jsZip.file('livechat_request_events.json', JSON.stringify(requestsEventsStack));
  jsZip.file('livechat_console_logs.json', JSON.stringify(consoleLogHistoryManager.history));

  const content = await jsZip.generateAsync({ type: 'blob' });
  saveAs(content, 'livechat_bug_report.zip');
}
