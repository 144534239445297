import { type CustomDateRange } from 'constants/filters/date-range-filter';
import { type Filter } from 'constants/filters/filter';

export interface IMobileDateRangeFilterOption {
  id: string;
  value: {
    from: Date;
    to: Date;
  };
}

export interface ISingleItemProps {
  label: string;
  value: string;
  isManual?: boolean;
}

export interface ISingleItem {
  key: string;
  props: ISingleItemProps;
}

export type DateRangeFilterOnChange = (filter: {
  name: Filter;
  value: CustomDateRange;
  compare?: CustomDateRange;
  isDraft?: boolean;
}) => void;

export enum InputName {
  From = 'from',
  To = 'to',
}

export interface IRangeDatePickerOption {
  id: string;
  label: string;
  isManual?: boolean;
  value: {
    from: Date;
    to: Date;
  };
}
