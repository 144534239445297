import { isError, isKyHTTPError } from './type-guards';
import { HTTPStatus, type ApiClientResponse, type KyHTTPError } from './types';

/**
 * Handles an `ApiHTTPError` by attempting to parse the error response.
 * If the response is JSON, it is parsed using the `json` method.
 * If the response is not JSON, it is parsed as text using the `text` method.
 * If parsing the response throws an error, the function falls back to handling the error as an unknown error.
 *
 * @param error - The `ApiHTTPError` to handle.
 * @returns A promise that resolves to an `ApiClientResponse` with a `null` result and an error that is either the parsed error response, a string, or an `Error` object.
 * @template E - The type of the error data in the error response.
 */
async function handleApiHTTPError<E>(error: KyHTTPError): Promise<ApiClientResponse<null, E | null>> {
  try {
    const contentType = error.response?.headers.get('Content-Type');
    const isJsonResponse = contentType?.includes('application/json');
    const httpError: E | Error = isJsonResponse
      ? await error.response?.json<E>()
      : new Error(await error.response?.text());

    return {
      response: error.response,
      result: null,
      error: {
        http: httpError,
        status: error.response?.status,
        local: null,
      },
    };
  } catch (parseError: unknown) {
    if (isError(parseError)) {
      return {
        response: error.response,
        result: null,
        error: {
          http: null,
          status: error.response?.status,
          local: parseError,
        },
      };
    } else {
      return handleUnknownError(parseError);
    }
  }
}

/**
 * Handles an unknown error by attempting to stringify it.
 * If stringifying the error throws an error (for example, if the error contains circular references), a default error message is used.
 *
 * @param error - The unknown error to handle.
 * @returns An `ApiClientResponse` with a `null` result and an `Error` object. The `Error` object's `message` property is either the stringified error or a default error message.
 */
function handleUnknownError(error: unknown): ApiClientResponse<null, null> {
  let errorMessage = '';
  try {
    errorMessage = JSON.stringify(error);
  } catch {
    errorMessage = 'Error contains circular references';
  }

  return {
    response: new Response(),
    result: null,
    error: {
      http: null,
      status: HTTPStatus.Empty,
      local: { name: 'UnknownError', message: errorMessage || 'Unknown error' },
    },
  };
}

/**
 * Handles errors that occur during API requests.
 *
 * This function distinguishes between different types of errors (e.g., HTTP errors, local errors)
 * and formats them into a standardized response object.
 *
 * @param error - The error that occurred during the API request. This can be of any type.
 * @returns A promise that resolves to an `ApiClientResponse` object with the error information.
 *          The `result` field of the response is always `null` in case of errors.
 */
export async function handleError<E>(error: unknown): Promise<ApiClientResponse<null, E | null>> {
  // eslint-disable-next-line no-console
  console.error('HTTP Error:', error);

  if (isKyHTTPError(error)) {
    return await handleApiHTTPError<E>(error);
  } else if (isError(error)) {
    return Promise.resolve({
      response: new Response(),
      result: null,
      error: {
        http: null,
        status: HTTPStatus.Empty,
        local: error,
      },
    });
  } else {
    return handleUnknownError(error);
  }
}
