// @ts-strict-ignore
import { GlobalModal } from 'constants/global-modal';
import { NavigationPath } from 'constants/navigation';
import { OnboardingRoute } from 'constants/onboarding/routes';
import { Right } from 'constants/right';
import { isMobileDevice } from 'helpers/device';
import { AppStateProvider } from 'services/app-state-provider';
import { GlobalModalActions } from 'store/features/global-modals/actions';
import { ChatsViewActions } from 'store/views/chats/actions';
import { OnboardingActions } from 'store/views/onboarding/actions';
import type { IOnboardingStep } from 'store/views/onboarding/interfaces';

import { navigate } from './routing';

export const getFirstAvailableStep = (steps: IOnboardingStep[], shouldSkipInstallCode: boolean): OnboardingRoute => {
  return steps.find((step) => !!step.route && (step.route !== OnboardingRoute.InstallCode || !shouldSkipInstallCode))
    ?.route;
};

export const getNextStep = (currentRoute: OnboardingRoute, steps: IOnboardingStep[]): OnboardingRoute => {
  const currentIndex = steps.findIndex((step) => step.route === currentRoute);
  const nextStep = steps[currentIndex + 1];

  return nextStep ? nextStep.route : null;
};

export const getPreviousStep = (currentRoute: OnboardingRoute, steps: IOnboardingStep[]): OnboardingRoute => {
  const currentIndex = steps.findIndex((step) => step.route === currentRoute);
  const nextStep = steps[currentIndex - 1];

  return nextStep ? nextStep.route : null;
};

export const navigateToNextStep = (
  currentRoute: OnboardingRoute,
  steps: IOnboardingStep[],
  chatThreadId?: string
): void => {
  const nextStep = getNextStep(currentRoute, steps);
  const isMobile = isMobileDevice();
  const isOwner = AppStateProvider.getState().features.session.rights[Right.OrganizationManagement];

  if (nextStep) {
    navigate(nextStep);
  } else {
    AppStateProvider.dispatch(OnboardingActions.onboardingFinished());
    if (isMobile || chatThreadId) {
      if (chatThreadId) {
        AppStateProvider.dispatch(ChatsViewActions.navigateToChat({ threadId: chatThreadId, shouldReplace: true }));
      }
      navigate(NavigationPath.Chats);

      return;
    }

    if (isOwner) {
      AppStateProvider.dispatch(GlobalModalActions.showModal(GlobalModal.TestChat));
    }

    navigate(NavigationPath.Home);
  }
};

export const navigateToPreviousStep = (currentRoute: OnboardingRoute, steps: IOnboardingStep[]): void => {
  const previousStep = getPreviousStep(currentRoute, steps);

  if (previousStep) {
    navigate(previousStep);
  }
};
