/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { createStore, applyMiddleware, compose, type Action } from 'redux';
import { enableBatching } from 'redux-batched-actions';
import { createLogger } from 'redux-logger';
import remotedev from 'redux-remotedev';
import 'regenerator-runtime/runtime'; // eslint-disable-line import/no-extraneous-dependencies

import { isDevelopmentEnvironment } from 'helpers/feature-toggle';
import type { IStoreState } from 'interfaces/store/store-state';

import { actionStreamMiddleware } from './action-stream';
import { ReportBugActionNames } from './features/report-bug/actions';
import { generateIssueReport } from './helpers/store';
import { reducers } from './reducers';
import { sagaMiddleware } from './sagas/middleware';

const composeEnhancers =
  (typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === 'function' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ maxAge: isDevelopmentEnvironment() ? 150 : 50 })) ||
  compose;
const middlewares = [sagaMiddleware, actionStreamMiddleware];

if (localStorage.debugStore) {
  middlewares.push(
    createLogger({
      collapsed: true,
    })
  );
}

export const store = createStore<IStoreState, Action<any>, unknown, unknown>(
  enableBatching(reducers),
  composeEnhancers(
    applyMiddleware(...middlewares),
    remotedev({
      sender: (data) => generateIssueReport(store, data),
      sendOn: ReportBugActionNames.DOWNLOAD_DATA,
      maxAge: 150,
    })
  )
);
