import type { FC } from 'react';

import { Alert, Heading } from '@livechat/design-system-react-components';

import * as styles from './styles';

export const CannotBuyAlert: FC = () => (
  <Alert kind="warning" className={styles.alert}>
    <Heading size="xs">Sorry, you can&apos;t buy apps</Heading>
    Add this app to the Marketplace cart for future approval and inform your subscription admin that you&apos;re
    interested in trying it.
  </Alert>
);
