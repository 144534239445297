import type { FC } from 'react';

import { CreditCardOutline } from '@livechat/design-system-icons';
import { Icon } from '@livechat/design-system-react-components';

import { visaCard, mastercardCard, amexCard } from 'components/card-form/constants';

import * as styles from './styles';

interface IProps {
  cardType: string;
}

export const CreditCardLogo: FC<IProps> = ({ cardType }) => {
  const type = cardType && cardType.toLowerCase();

  switch (type) {
    case 'visa':
      return <img alt="visa" className={styles.creditCardLogo} src={visaCard['1x']} />;

    case 'master':
    case 'mastercard':
      return <img alt="mastercard" className={styles.creditCardLogo} src={mastercardCard['1x']} />;

    case 'american express':
    case 'american_express':
      return <img alt="american express" className={styles.creditCardLogo} src={amexCard['1x']} />;

    default:
      return <Icon size="large" className={styles.creditCardLogo} source={CreditCardOutline} />;
  }
};
