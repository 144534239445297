// @ts-strict-ignore
import { type SagaIterator } from 'redux-saga';
import { all, takeEvery, call, put, select } from 'redux-saga/effects';

import { GlobalModal } from 'constants/global-modal';
import { ReportType } from 'constants/reports/report-type';
import { ToastContent, ToastAutoHideDelay } from 'constants/toasts';
import { ViewActionSource } from 'constants/view-actions-source';
import * as analytics from 'helpers/analytics-reports';
import { navigate } from 'helpers/routing';
import { getToastContent } from 'helpers/toast';
import type { TicketCreateParams } from 'interfaces/api/ticket';
import { ApiManager } from 'services/api/api-manager';
import { type ITicketRequestBody } from 'services/api/helpdesk/interfaces';
import { CompanyDetailsActionNames } from 'store/entities/company-details/actions';
import { isHelpDeskAppConnected } from 'store/entities/integration-license-properties/selectors';
import { GlobalModalActions } from 'store/features/global-modals/actions';
import { getLicenseId } from 'store/features/session/selectors';
import { ToastsActions } from 'store/features/toasts/actions';
import { ToastVariant } from 'store/features/toasts/interfaces';
import type { IActionWithPayload } from 'store/helper';
import { getAvailableFilters } from 'store/views/reports/computed';
import {
  getCurrentView,
  shouldBenchmarkDataBeFetched,
  getDistribution,
  getFiltersOperators,
} from 'store/views/reports/selectors';

import { RequestAction } from '../actions';

import {
  REPORT,
  ReportActions,
  CreateReportActionNames,
  EditScheduledReportActionNames,
  DeleteScheduledReportActionNames,
  CreateTicketFromQueueAbandonmentActionNames,
} from './actions';
import {
  type ICreateReportPayload,
  type IEditReportPayload,
  type IDeleteReportPayload,
  type ICreateTicketFromQueueAbandonmentPayload,
} from './interfaces';
import { fetchAchievedGoals } from './sagas/fetch-achieved-goals';
import { fetchActivity } from './sagas/fetch-activity';
import { fetchAgentsPerformance } from './sagas/fetch-agents-performance';
import { fetchChatDuration } from './sagas/fetch-chat-duration';
import { fetchQueuedCustomers } from './sagas/fetch-chat-queued-customers';
import { fetchChatRating } from './sagas/fetch-chat-rating';
import { fetchChatResponseTime } from './sagas/fetch-chat-response-time';
import { fetchChatSurveys } from './sagas/fetch-chat-surveys';
import { fetchGreetingsConversion } from './sagas/fetch-greetings-conversion';
import { fetchMissedChats } from './sagas/fetch-missed-chats';
import { fetchNewTickets } from './sagas/fetch-new-tickets';
import { fetchQueueAbandonment } from './sagas/fetch-queue-abandonment';
import { fetchScheduledReports } from './sagas/fetch-scheduled-reports';
import { fetchSolvedTickets } from './sagas/fetch-solved-tickets';
import { fetchStaffingPrediction } from './sagas/fetch-staffing-prediction';
import { fetchTagsUsage } from './sagas/fetch-tags-usage';
import { fetchTicketFirstResponseTime } from './sagas/fetch-ticket-first-response-time';
import { fetchTicketRating } from './sagas/fetch-ticket-rating';
import { fetchTicketResolutionTime } from './sagas/fetch-ticket-resolution-time';
import { fetchTrackedSales } from './sagas/fetch-tracked-sales';

function* createReport(action: IActionWithPayload<string, ICreateReportPayload>): any {
  const {
    payload: { data, source },
  } = action;

  const { email, format: editFormat, encoding, type, period, dateFrom, dateTo } = data;

  const reportData = period
    ? yield call(ApiManager.exportApi.createReportRecurring, {
        email,
        format: editFormat,
        encoding,
        type,
        period,
      })
    : yield call(ApiManager.exportApi.createReportOneTime, {
        email,
        format: editFormat,
        encoding,
        type,
        dateFrom,
        dateTo,
      });

  if (reportData.result && !reportData.result.error) {
    if (period) {
      yield put(
        ReportActions.createReportSuccess({
          data: reportData.result,
          source,
        })
      );
    } else {
      yield put(
        ReportActions.createReportSuccess({
          data: null,
          source: null,
        })
      );
      yield put(GlobalModalActions.showModal(GlobalModal.OneTimeReportSended));
    }
  } else {
    yield put(ReportActions.createReportFailure({ error: reportData.error, data }));
  }
}

function* editScheduledReport(action: IActionWithPayload<string, IEditReportPayload>): any {
  const {
    payload: { data, source },
  } = action;

  const { email, format: editFormat, encoding, type, period, typeCurrent, periodCurrent } = data;

  const reportData = yield call(ApiManager.exportApi.editScheduledReport, {
    email,
    format: editFormat,
    encoding,
    type,
    period,
    typeCurrent,
    periodCurrent,
  });

  if (reportData.result && !reportData.result.error) {
    reportData.result.periodCurrent = periodCurrent;
    reportData.result.typeCurrent = typeCurrent;

    yield put(
      ReportActions.editScheduledReportSuccess({
        data: reportData.result,
        source,
      })
    );
  } else {
    yield put(ReportActions.editScheduledReportFailure({ error: reportData.error, data }));
  }
}

function* deleteScheduledReport(action: IActionWithPayload<string, IDeleteReportPayload>): any {
  const {
    payload: {
      source,
      data: { id },
    },
  } = action;
  const [type, recurs] = id.split('-');

  const data = yield call(ApiManager.exportApi.deleteScheduledReport, { type, recurs });

  if (data.result) {
    yield put(
      ReportActions.deleteScheduledReportSuccess({
        data: { id },
        source,
      })
    );
  } else {
    yield put(ReportActions.deleteScheduledReportFailure({ error: data.error, data: { id } }));
  }
}

function* createTicketForHelpDesk(data: TicketCreateParams): any {
  const licenseId: number = yield select(getLicenseId);

  const ticketBody: ITicketRequestBody = {
    integration: {
      type: 'livechat',
      ID: licenseId.toString(),
    },
    message: {
      text: data.message,
    },
    requester: {
      name: data.requester.name,
      email: data.requester.mail,
    },
    subject: data.subject,
  };

  const ticket = yield call(ApiManager.helpDeskApi.createTicket, ticketBody);
  if (ticket.result) {
    yield put(
      ToastsActions.createToast({
        content: getToastContent(ToastContent.CREATE_TICKET_FROM_QUEUE_ABANDONMENT_SUCCESS),
        autoHideDelayTime: ToastAutoHideDelay.Long,
        kind: ToastVariant.Success,
      })
    );
    analytics.messageFollowupSentSuccess(ReportType.QueueAbandonment);
    navigate('/app/helpdesk');
  } else {
    yield put(
      ToastsActions.createToast({
        content: getToastContent(ToastContent.CREATE_TICKET_FROM_QUEUE_ABANDONMENT_ERROR),
        autoHideDelayTime: ToastAutoHideDelay.Long,
        kind: ToastVariant.Error,
      })
    );
    navigate('/app/helpdesk');
  }
  yield put(GlobalModalActions.hideModal(GlobalModal.QueueAbandonmentMessage));
}

function* createTicketFromQueueAbandonment(
  action: IActionWithPayload<string, ICreateTicketFromQueueAbandonmentPayload>
): any {
  const { data } = action.payload;
  const state = yield select();
  const shouldUseHelpDeskApi = isHelpDeskAppConnected(state);

  if (shouldUseHelpDeskApi) {
    yield call(createTicketForHelpDesk, data);

    return;
  }

  const ticket = yield call(ApiManager.ticketApi.createTicket, data);

  if (ticket.result) {
    yield put(ReportActions.createTicketFromQueueAbandonmentSuccess({ ticketId: ticket.result.id }));
  } else {
    yield put(ReportActions.createTicketFromQueueAbandonmentFailure({ error: ticket.error, data: ticket }));
  }
}

function* fetchBenchmarkDataAfterCompanyDetailsUpdate(): SagaIterator {
  const shouldBenchmarkFetch = yield select(shouldBenchmarkDataBeFetched);

  if (!shouldBenchmarkDataBeFetched) {
    return;
  }

  const currentReport: ReportType = yield select(getCurrentView);
  const availableFilters = yield select(getAvailableFilters);
  const filtersOperators = yield select(getFiltersOperators);
  const distribution = yield select(getDistribution);

  yield put(
    ReportActions.fetch({
      reportType: currentReport,
      source: ViewActionSource.Reports,
      filters: availableFilters,
      filtersOperators,
      distribution,
      shouldBenchmarkFetch,
    })
  );
}

const reportCallMap = {
  [ReportType.Activity]: fetchActivity,
  [ReportType.AgentsPerformance]: fetchAgentsPerformance,
  [ReportType.ChatRatings]: fetchChatRating,
  [ReportType.TicketRatings]: fetchTicketRating,
  [ReportType.ChatDuration]: fetchChatDuration,
  [ReportType.ChatResponseTime]: fetchChatResponseTime,
  [ReportType.Greetings]: fetchGreetingsConversion,
  [ReportType.ScheduledReports]: fetchScheduledReports,
  [ReportType.QueuedCustomers]: fetchQueuedCustomers,
  [ReportType.SolvedTickets]: fetchSolvedTickets,
  [ReportType.TicketFirstResponseTime]: fetchTicketFirstResponseTime,
  [ReportType.NewTickets]: fetchNewTickets,
  [ReportType.TicketResolutionTime]: fetchTicketResolutionTime,
  [ReportType.Goals]: fetchAchievedGoals,
  [ReportType.MissedChats]: fetchMissedChats,
  [ReportType.Sales]: fetchTrackedSales,
  [ReportType.StaffingPrediction]: fetchStaffingPrediction,
  [ReportType.ChatForms]: fetchChatSurveys,
  [ReportType.QueueAbandonment]: fetchQueueAbandonment,
  [ReportType.TagsUsage]: fetchTagsUsage,
};

export default function* reportsSaga(): SagaIterator {
  yield all([
    takeEvery(CreateReportActionNames[RequestAction.REQUEST], createReport),
    takeEvery(EditScheduledReportActionNames[RequestAction.REQUEST], editScheduledReport),
    takeEvery(DeleteScheduledReportActionNames[RequestAction.REQUEST], deleteScheduledReport),
    takeEvery(CreateTicketFromQueueAbandonmentActionNames[RequestAction.REQUEST], createTicketFromQueueAbandonment),
    takeEvery(CompanyDetailsActionNames.UPDATE_COMPANY_DETAILS_SUCCESS, fetchBenchmarkDataAfterCompanyDetailsUpdate),
    ...Object.keys(reportCallMap).map((reportName: ReportType) =>
      takeEvery(REPORT[reportName][RequestAction.REQUEST], reportCallMap[reportName])
    ),
  ]);
}
