import { ApiErrorMessagePart, HTTPStatusCode } from 'services/api/constants';
import type { RequestError } from 'services/api/types';

export type ApiError = { error?: ApiErrorMessagePart; status?: HTTPStatusCode };

export const isRequestUnauthorized = ({ status, error }: ApiError): boolean =>
  status === HTTPStatusCode.Unauthorized ||
  status === HTTPStatusCode.Empty ||
  error === ApiErrorMessagePart.Unauthorized;

export const isRequestForbidden = ({ status }: RequestError): boolean => status === HTTPStatusCode.Forbidden;

export const isRequestSuspended = ({ error }: ApiError): boolean => error === ApiErrorMessagePart.AccountSuspended;

export const isRequestWaitingForApproval = ({ error }: ApiError): boolean =>
  error === ApiErrorMessagePart.AwaitingApproval;

export const isLicenseExpired = ({ error }: ApiError): boolean => error === ApiErrorMessagePart.LicenseExpired;

export const isLicenseBlockedInCRM = ({ error }: RequestError): boolean => error === ApiErrorMessagePart.UserNotActive;

export const isAccountRelatedError = (error: ApiError): boolean =>
  isRequestUnauthorized(error) ||
  isRequestSuspended(error) ||
  isRequestWaitingForApproval(error) ||
  isLicenseExpired(error);

/**
 * Type guard function to check if a given object is of type ApiError.
 * @param obj - The object to check.
 * @returns True if the object is of type ApiError, false otherwise.
 */
export function isWithApiError(obj: unknown): obj is ApiError {
  if (typeof obj !== 'object' || obj === null) {
    return false;
  }

  const apiErrorObj = obj as ApiError;

  if (apiErrorObj.error === undefined && apiErrorObj.status === undefined) {
    return false;
  }

  return (
    ('error' in apiErrorObj === false ||
      apiErrorObj.error === undefined ||
      Object.values(ApiErrorMessagePart).includes(apiErrorObj.error)) &&
    ('status' in apiErrorObj === false ||
      apiErrorObj.status === undefined ||
      Object.values(HTTPStatusCode).includes(apiErrorObj.status))
  );
}
