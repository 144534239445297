import { type FC, createContext, useContext, useState, useCallback } from 'react';

import noop from 'lodash.noop';
import { useSelector } from 'react-redux';

import { ResourceModal } from 'components/apps/modals/ResourceModal';
import { getBillingInfo } from 'store/entities/billing/selectors';

import { type ResourceModalType, RESOURCE_MODAL } from '../constants';

import { type ResourceModalContext, type ResourceModalContextProviderProps } from './interfaces';

const SettingsResourceModalContext = createContext<ResourceModalContext>({
  openModal: noop,
  closeModal: noop,
});

export const SettingsResourceModalContextProvider: FC<ResourceModalContextProviderProps> = ({
  isFree,
  isActive,
  children,
}) => {
  const [openedModal, setOpenedModal] = useState<ResourceModalType | null>(null);

  const billingInfo = useSelector(getBillingInfo);
  const canPurchaseUpgrade = billingInfo || (!billingInfo && isFree);

  const closeModal = useCallback((): void => {
    setOpenedModal(null);
  }, []);

  const openModal = useCallback(
    (purchaseOnly?: boolean): void => {
      if (!canPurchaseUpgrade) {
        setOpenedModal(RESOURCE_MODAL.updateSubscription);

        return;
      }

      if (isActive && !purchaseOnly) {
        setOpenedModal(RESOURCE_MODAL.manage);
      } else {
        setOpenedModal(RESOURCE_MODAL.purchase);
      }
    },
    [canPurchaseUpgrade, isActive]
  );

  const value: ResourceModalContext = {
    openModal,
    closeModal,
  };

  return (
    <SettingsResourceModalContext.Provider value={value}>
      {children}
      {openedModal && <ResourceModal closeModal={closeModal} openedModal={openedModal} openModal={openModal} />}
    </SettingsResourceModalContext.Provider>
  );
};

export function useSettingsResourceModalContext(): ResourceModalContext {
  const context = useContext(SettingsResourceModalContext);

  if (context === undefined) {
    throw new Error('useSettingsResourceModalContext must be used within a SettingsResourceModalContextProvider');
  }

  return context;
}
