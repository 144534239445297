import type { FC } from 'react';

import { Button } from '@livechat/design-system-react-components';
import { useDispatch } from 'react-redux';

import { navigateToMarketplace } from 'helpers/routing';
import { useParamSelector } from 'hooks/use-param-selector';
import { useOneClickPaymentContext } from 'routes/apps/one-click-payment/hooks/use-one-click-payment-context';
import { ProductCartActions } from 'store/entities/product-cart/actions';
import { getIsAddingProductToCart, getIsProductInCart } from 'store/entities/product-cart/selectors';

import * as styles from './styles';

export const RequestBuyModalFooter: FC = () => {
  const { item, closeModal } = useOneClickPaymentContext();

  const itemId = item?.id || '';

  const dispatch = useDispatch();
  const isInCart = useParamSelector(getIsProductInCart, itemId);
  const isAddingToCart = useParamSelector(getIsAddingProductToCart, itemId);

  if (!item) {
    return null;
  }

  const handleAddToCart = (): void => {
    dispatch(
      ProductCartActions.addApplication({
        id: itemId,
      })
    );
  };

  const handleViewCart = (): void => {
    navigateToMarketplace('/cart');
    closeModal();
  };

  return (
    <div className={styles.footer}>
      <Button kind="secondary" onClick={closeModal}>
        Cancel
      </Button>
      {isInCart ? (
        <Button kind="primary" onClick={handleViewCart}>
          View Cart
        </Button>
      ) : (
        <Button kind="primary" onClick={handleAddToCart} loading={isAddingToCart}>
          Add to Cart
        </Button>
      )}
    </div>
  );
};
