import type { FC } from 'react';

import { cx } from '@emotion/css';
import { Text } from '@livechat/design-system-react-components';
import { useSelector } from 'react-redux';

import { getCanUseReplySuggestionsFeature } from 'store/views/chats/selectors';
import { getIsOneDetailsOpened } from 'store/views/one/selectors';

import { AboutOneAccordion } from './AboutOneAccordion';
import { FeedbackBanner } from './FeedbackBanner';
import { ReplySuggestionsAccordion } from './ReplySuggestionsAccordion';

import * as styles from './styles';

export const OneDetails: FC = () => {
  const isKnowledgeSourcesEnabled = useSelector(getCanUseReplySuggestionsFeature);
  const isDetailsOpened = useSelector(getIsOneDetailsOpened);

  return (
    <div className={cx(styles.wrapper, { [styles.openedDetailsWrapper]: isDetailsOpened })}>
      <div className={styles.detailsWrapper}>
        <AboutOneAccordion />
        <FeedbackBanner />
        {isKnowledgeSourcesEnabled && <ReplySuggestionsAccordion />}
        <Text className={styles.detailsOneInfo}>
          *One is an AI assistant and can make mistakes. Consider checking important information.
        </Text>
      </div>
    </div>
  );
};
