// @ts-strict-ignore
import { type SagaIterator } from 'redux-saga';
import { call, put, select, takeEvery, takeLatest, throttle } from 'redux-saga/effects';

import { CustomSegmentsSection , TRAFFIC_DEFAULT_SEGMENT_CONFIG } from 'constants/custom-segment';
import { type LogicOperator } from 'constants/logic-operator';
import { Section } from 'constants/section';
import { ToastContent, ToastAutoHideDelay } from 'constants/toasts';
import { TrafficEvent } from 'constants/traffic-event';
import { EventPlace } from 'helpers/analytics';
import { type KeyMap } from 'helpers/interface';
import { navigate } from 'helpers/routing';
import { getToastContent } from 'helpers/toast';
import type { CustomSegment, CustomSegmentValue } from 'interfaces/custom-segment';
import { trackEvent } from 'services/event-tracking';
import { RequestAction } from 'store/entities/actions';
import { getLoggedInAgentLogin } from 'store/entities/agents/selectors';
import { ChatsEntitiesActionNames, ChatsEntitiesActions } from 'store/entities/chats/actions';
import { type ChatThreadEntity } from 'store/entities/chats/interfaces';
import { getAllChats, getQueuedIds, getThread } from 'store/entities/chats/selectors';
import { CustomerActionNames } from 'store/entities/customers/actions';
import { getAllCustomers, getAllCustomersVisitedPages } from 'store/entities/customers/selectors';
import { greetingsSelector } from 'store/entities/greetings/selectors';
import { groupsSelector } from 'store/entities/groups/selectors';
import { AGENT_CUSTOM_PROPERTIES, AgentCustomPropertiesActions } from 'store/features/agent-custom-properties/actions';
import {
  AgentCustomPropertyName,
  type IFetchAgentCustomPropertiesSuccessPayload,
  type ISetAgentCustomPropertyFailurePayload,
} from 'store/features/agent-custom-properties/interfaces';
import { CustomSegmentsActionsNames } from 'store/features/custom-segments/actions';
import { type SelectCustomSegmentPayload } from 'store/features/custom-segments/interfaces';
import {
  getSectionCustomSegmentsData,
  getSectionSegmentDataValue,
  getSectionSelectedSegmentId,
  type WithCustomSegmentsState,
} from 'store/features/custom-segments/selectors';
import { RoutingActionsEnum } from 'store/features/routing/actions';
import { getIsOnSection } from 'store/features/routing/selectors';
import { ToastsActions } from 'store/features/toasts/actions';
import { ToastVariant } from 'store/features/toasts/interfaces';
import { type IActionWithPayload } from 'store/helper';
import { createChatPath } from 'store/views/chats/helpers/navigation';
import { agentsAndBotsSelector } from 'store/views/team/computed';

import { TrafficActionNames, TrafficActions } from './actions';
import { LIST_AUTO_REFRESH_THROTTLE_TIME, LIST_INSTANT_REFRESH_THROTTLE_TIME } from './constants';
import {
  customersToTrafficColumns,
  mapTrafficFiltersToCustomSegmentFilters,
  shouldThrottleCustomersListRefresh,
  trafficToCustomersColumns,
} from './helpers/sagas';
import {
  type ISaveColumnsOrderPayload,
  type ISaveColumnsVisibilityPayload,
  type TrafficColumn,
  type TrafficFilter,
} from './interfaces';
import { getIsLastFilterADraft, getTrafficFilters, getTrafficFiltersOperator } from './selectors';

export function* updateAgentCustomPropertiesColumnsVisibility(
  action: IActionWithPayload<string, ISaveColumnsVisibilityPayload>
): SagaIterator {
  const { visibleColumns, hiddenColumns } = action.payload;
  const visibleColumnsString = visibleColumns.map(trafficToCustomersColumns).join(',');
  const hiddenColumnsString = hiddenColumns.map(trafficToCustomersColumns).join(',');

  yield put(
    AgentCustomPropertiesActions.setAgentCustomProperty({
      [AgentCustomPropertyName.CustomerCustomColumns]: visibleColumnsString,
      [AgentCustomPropertyName.CustomerHiddenColumns]: hiddenColumnsString,
    })
  );

  yield put(TrafficActions.setColumnsVisibility({ visibleColumns, hiddenColumns }));
}

export function* updateAgentCustomPropertiesColumnsOrder(
  action: IActionWithPayload<string, ISaveColumnsOrderPayload>
): SagaIterator {
  const { payload } = action;

  const columnsOrder = payload.columnsOrder.map(trafficToCustomersColumns).join(',');

  yield put(
    AgentCustomPropertiesActions.setAgentCustomProperty({
      [AgentCustomPropertyName.CustomerCustomColumnsOrder]: columnsOrder,
    })
  );

  yield put(TrafficActions.setColumnsOrder({ columnsOrder: payload.columnsOrder }));
}

export function* loadCustomersColumnsConfiguration(
  action: IActionWithPayload<string, IFetchAgentCustomPropertiesSuccessPayload>
): SagaIterator {
  const {
    customer_custom_columns: selected,
    customer_custom_columns_order: order,
    customer_hidden_columns: hidden,
  } = action.payload;

  let columnsVisibilityPayload = {};
  if (selected) {
    const visibleColumns: TrafficColumn[] = (selected as string).split(',').map(customersToTrafficColumns);
    columnsVisibilityPayload = { ...columnsVisibilityPayload, visibleColumns };
  }

  if (hidden) {
    const hiddenColumns: TrafficColumn[] = (hidden as string).split(',').map(customersToTrafficColumns);
    columnsVisibilityPayload = { ...columnsVisibilityPayload, hiddenColumns };
  }

  if (Object.keys(columnsVisibilityPayload).length > 0) {
    yield put(TrafficActions.setColumnsVisibility(columnsVisibilityPayload));
  }

  if (order) {
    const columnsOrder = (order as string).split(',').map(customersToTrafficColumns);
    yield put(TrafficActions.setColumnsOrder({ columnsOrder }));
  }
}

function* buildCurrentSegments(selectedSegmentId: string): SagaIterator<KeyMap<CustomSegment>> {
  const segments: KeyMap<CustomSegment> = yield select(getSectionCustomSegmentsData, CustomSegmentsSection.Traffic);
  const currentlySelectedSegment: CustomSegment =
    selectedSegmentId === TRAFFIC_DEFAULT_SEGMENT_CONFIG.id
      ? TRAFFIC_DEFAULT_SEGMENT_CONFIG
      : segments?.[selectedSegmentId];

  let currentlyEditedSegment: CustomSegment = null;
  if (currentlySelectedSegment) {
    const trafficFilters: TrafficFilter[] = yield select(getTrafficFilters);
    const isLastFilterADraft = yield select(getIsLastFilterADraft);
    const filters = mapTrafficFiltersToCustomSegmentFilters(
      isLastFilterADraft ? trafficFilters.slice(0, -1) : trafficFilters
    );
    const operator: LogicOperator = yield select(getTrafficFiltersOperator);
    currentlyEditedSegment = {
      ...currentlySelectedSegment,
      value: {
        filters,
        operator,
      },
    };
  }

  return {
    [TRAFFIC_DEFAULT_SEGMENT_CONFIG.id]: TRAFFIC_DEFAULT_SEGMENT_CONFIG,
    ...segments,
    ...(currentlyEditedSegment && { [currentlyEditedSegment.id]: currentlyEditedSegment }),
  };
}

export function* updateCustomersList(): SagaIterator {
  const isOnEngage = yield select(getIsOnSection, Section.Engage, true);
  const isOnEngageTraffic = yield select(getIsOnSection, Section.EngageTraffic, true);
  const customers = yield select(getAllCustomers);
  const allThreads = yield select(getAllChats);

  if (isOnEngageTraffic || isOnEngage) {
    const visitedPages = yield select(getAllCustomersVisitedPages);
    const allGroups = yield select(groupsSelector);
    const allAgents = yield select(agentsAndBotsSelector);
    const allTargetedMessages = yield select(greetingsSelector);
    const currentAgentId = yield select(getLoggedInAgentLogin);
    const selectedSegmentId: string = yield select((state: WithCustomSegmentsState) =>
      getSectionSelectedSegmentId(state, CustomSegmentsSection.Traffic)
    );
    const segments = yield call(buildCurrentSegments, selectedSegmentId);
    const refreshTimestampInMs = Date.now();

    yield put(
      TrafficActions.refreshList({
        customers,
        allThreads,
        visitedPages,
        allGroups,
        allAgents,
        allTargetedMessages,
        currentAgentId,
        refreshTimestampInMs,
        segments,
        selectedSegmentId,
      })
    );
  } else {
    // The refresh of the list also updates the counters, so we can use "else" here
    yield put(
      TrafficActions.updateCustomersCounters({
        customers,
        allThreads,
      })
    );
  }
}

export function* refreshCustomersList(): SagaIterator {
  const shouldThrottle = yield select(shouldThrottleCustomersListRefresh);

  if (shouldThrottle) {
    yield put(TrafficActions.refreshListThrottled());
  } else {
    yield call(updateCustomersList);
  }
}

function* applySegmentFilters(action: IActionWithPayload<string, SelectCustomSegmentPayload>): SagaIterator {
  const { sectionName, segmentId } = action.payload;

  if (CustomSegmentsSection.Traffic === sectionName) {
    let segmentValue: CustomSegmentValue = TRAFFIC_DEFAULT_SEGMENT_CONFIG.value;

    if (action.payload.segmentId !== TRAFFIC_DEFAULT_SEGMENT_CONFIG.id) {
      segmentValue = yield select(getSectionSegmentDataValue, CustomSegmentsSection.Traffic, segmentId);
    }

    yield put(TrafficActions.applySegmentFilters({ segmentValue }));
  }
}

function* informAboutAgentCustomPropertyFailure(
  action: IActionWithPayload<string, ISetAgentCustomPropertyFailurePayload>
): SagaIterator {
  const { payload } = action;

  if (
    payload.customPropertyIdentifier === AgentCustomPropertyName.CustomerDetailsSectionsOrder ||
    payload.customPropertyIdentifier === AgentCustomPropertyName.CustomerDetailsSectionSelected
  ) {
    yield put(
      ToastsActions.createToast({
        content: getToastContent(ToastContent.CUSTOMER_DETAILS_WIDGETS_CONFIGURATOR_UPDATE_FAILURE),
        autoHideDelayTime: ToastAutoHideDelay.Long,
        kind: ToastVariant.Error,
      })
    );
  }
}

function* pickFromQueue(): SagaIterator {
  const queudIds: string[] = yield select(getQueuedIds);

  if (queudIds.length) {
    const firstThreadId = queudIds[0];
    const thread: ChatThreadEntity = yield select(getThread, firstThreadId);

    if (thread) {
      trackEvent(TrafficEvent.PickFromQueueWithShortcut, EventPlace.Traffic);
      yield put(ChatsEntitiesActions.pickFromQueue({ customerId: thread.customerId }));
      navigate(createChatPath(thread.chatId, thread.threadId));
    } else {
      yield put(
        ToastsActions.createToast({
          content: getToastContent(ToastContent.NO_CHAT_FOUND_ERROR),
          kind: ToastVariant.Error,
        })
      );
    }
  }
}

export function* trafficSagas(): SagaIterator {
  // Throttles frequent actions that cause the list to be refreshed
  yield takeLatest(
    [
      CustomerActionNames.ADD_CUSTOMER,
      CustomerActionNames.UPDATE_CUSTOMER,
      CustomerActionNames.ADD_OR_UPDATE_CUSTOMERS,
      CustomerActionNames.REMOVE_CUSTOMER,
      CustomerActionNames.REMOVE_CUSTOMERS,
      TrafficActionNames.CUSTOMER_THREAD_STATUS_CHANGE,
    ],
    refreshCustomersList
  );

  yield throttle(LIST_AUTO_REFRESH_THROTTLE_TIME, TrafficActionNames.REFRESH_LIST_THROTTLED, updateCustomersList);

  // Refreshes the list with a small throttle when major updates are triggered (especially after page refresh)
  yield throttle(
    LIST_INSTANT_REFRESH_THROTTLE_TIME,
    [
      CustomerActionNames.SET_DATA,
      ChatsEntitiesActionNames.FETCH_MORE_UNASSIGNED_CHATS_SUMMARY_SUCCESS,
      TrafficActionNames.CUSTOMERS_LIST_MOUNT,
      TrafficActionNames.BANNED_CUSTOMERS_LIST_UPDATE,
      CustomSegmentsActionsNames.LOAD_CUSTOM_SEGMENTS,
      RoutingActionsEnum.SET_CURRENT_SECTION,
    ],
    updateCustomersList
  );

  // Instantly refreshes the list on demand (user interactions like sort column, etc.)
  yield takeLatest(
    [
      TrafficActionNames.SORT_COLUMN,
      TrafficActionNames.REMOVE_FILTER,
      TrafficActionNames.UPDATE_FILTER,
      TrafficActionNames.CHANGE_OPERATOR,
      TrafficActionNames.CLEAR_FILTERS,
      TrafficActionNames.APPLY_SEGMENT_FILTERS,
      CustomSegmentsActionsNames.CREATE_CUSTOM_SEGMENT_SUCCESS,
      CustomSegmentsActionsNames.UPDATE_CUSTOM_SEGMENT_SUCCESS,
      CustomSegmentsActionsNames.SAVE_CUSTOM_SEGMENTS,
      CustomSegmentsActionsNames.UPDATE_CUSTOM_SEGMENTS_ORDER_SUCCESS,
      CustomSegmentsActionsNames.UPDATE_CUSTOM_SEGMENT_LABEL_SUCCESS,
      CustomSegmentsActionsNames.DELETE_CUSTOM_SEGMENT_SUCCESS,
    ],
    updateCustomersList
  );

  yield takeLatest(CustomSegmentsActionsNames.SELECT_CUSTOM_SEGMENT, applySegmentFilters);
  yield takeLatest(TrafficActionNames.SAVE_COLUMNS_VISIBILITY, updateAgentCustomPropertiesColumnsVisibility);
  yield takeLatest(TrafficActionNames.SAVE_COLUMNS_ORDER, updateAgentCustomPropertiesColumnsOrder);
  yield takeLatest(
    AGENT_CUSTOM_PROPERTIES.FETCH_AGENT_CUSTOM_PROPERTIES[RequestAction.SUCCESS],
    loadCustomersColumnsConfiguration
  );

  yield takeLatest(
    AGENT_CUSTOM_PROPERTIES.SET_AGENT_CUSTOM_PROPERTY[RequestAction.FAILURE],
    informAboutAgentCustomPropertyFailure
  );
  yield takeEvery(TrafficActionNames.PICK_FROM_QUEUE, pickFromQueue);
}
