// @ts-strict-ignore
import type { BusinessModel } from 'constants/business-model';
import { LicensePropertiesNames } from 'constants/license-properties-names';
import type { PlanType } from 'constants/plan-type';
import { anyToBoolean } from 'helpers/boolean';
import { PartnerType } from 'interfaces/entities/current-license';
import { type LicenseProperties } from 'interfaces/license-properties';

import type { LicensePropertiesState } from './interfaces';

const NO_FIRST_CHAT_DATE = '0000-01-01';

export interface IWithLicensePropertiesState {
  entities: {
    licenseProperties: LicensePropertiesState;
  };
}

export function getLicenseProperties(state: IWithLicensePropertiesState): LicenseProperties {
  return state.entities.licenseProperties;
}

export function getLicenseProperty(state: IWithLicensePropertiesState, property: LicensePropertiesNames): string {
  return state.entities.licenseProperties?.[property];
}

export function getPaymentRecurrentEnabled(state: IWithLicensePropertiesState): boolean {
  return anyToBoolean(getLicenseProperty(state, LicensePropertiesNames.PaymentRecurrentEnabled));
}

export function getHelpDeskInboundForwarding(state: IWithLicensePropertiesState): boolean {
  return anyToBoolean(getLicenseProperty(state, LicensePropertiesNames.HelpDeskInboundForwarding));
}

export function getHelpDeskReplacedTickets(state: IWithLicensePropertiesState): boolean {
  return anyToBoolean(getLicenseProperty(state, LicensePropertiesNames.HelpDeskReplaceTickets));
}

export function getEmailForwardingToTicketsSetUp(state: IWithLicensePropertiesState): boolean {
  return anyToBoolean(getLicenseProperty(state, LicensePropertiesNames.LastTicketForwardedTimestamp));
}

export function getContinuousChatWidgetEnabled(state: IWithLicensePropertiesState): boolean {
  return anyToBoolean(getLicenseProperty(state, LicensePropertiesNames.ContinuousChatWidgetEnabled));
}

export function getIsNonProfit(state: IWithLicensePropertiesState): boolean {
  return anyToBoolean(getLicenseProperty(state, LicensePropertiesNames.NonProfit));
}

export function getIsReseller(state: IWithLicensePropertiesState): boolean {
  return (getLicenseProperty(state, LicensePropertiesNames.PartnerType) as PartnerType) === PartnerType.Reseller;
}

export function getIsAutomaticUpsellingEnabled(state: IWithLicensePropertiesState): boolean {
  return anyToBoolean(getLicenseProperty(state, LicensePropertiesNames.AutomaticUpsellingEnabled));
}

export function getDiscountCoupon(state: IWithLicensePropertiesState): string {
  return getLicenseProperty(state, LicensePropertiesNames.DiscountCoupon);
}

export function getFirstChatDate(state: IWithLicensePropertiesState): Date | null {
  const firstChatDate = getLicenseProperty(state, LicensePropertiesNames.FirstChatDate);

  if (firstChatDate === NO_FIRST_CHAT_DATE) {
    return null;
  }

  return new Date(firstChatDate);
}

export function getIsFormerChatio(state: IWithLicensePropertiesState): boolean {
  return anyToBoolean(getLicenseProperty(state, LicensePropertiesNames.FormerChatio));
}

export function getIsAdsMonitoringEnabled(state: IWithLicensePropertiesState): boolean {
  return anyToBoolean(getLicenseProperty(state, LicensePropertiesNames.AdsMonitoring));
}

export function getPartnerType(state: IWithLicensePropertiesState): PartnerType {
  return getLicenseProperty(state, LicensePropertiesNames.PartnerType) as PartnerType;
}

export function getSalesPlan(state: IWithLicensePropertiesState): PlanType | undefined {
  return getLicenseProperty(state, LicensePropertiesNames.SalesPlan)?.toLowerCase() as PlanType;
}

export function getBusinessModel(state: IWithLicensePropertiesState): BusinessModel | undefined {
  return getLicenseProperty(state, LicensePropertiesNames.BusinessModel) as BusinessModel;
}

export function getAgentAllowedIps(state: IWithLicensePropertiesState): string[] {
  return getLicenseProperty(state, LicensePropertiesNames.OperatorsAllowedIp).split(';');
}
