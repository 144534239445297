import { useEffect, useRef, useState, type FC } from 'react';

import { cx } from '@emotion/css';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { SPOTLIGHT_MEDIA_QUERY } from 'constants/spotlight';
import { getVisibleNotifications } from 'helpers/notification-bar';
import { usePrevious } from 'hooks/use-previous';
import { useScreenSize } from 'hooks/use-screen-size';
import { isDarkModeEnabled } from 'store/features/agent-custom-properties/selectors';
import { getNotifications } from 'store/features/notifications-bar/selectors';
import { OneViewActions } from 'store/views/one/actions';
import { getIsOneModalExpanded, getIsOneModalOpened } from 'store/views/one/selectors';

import { collapseAnimation, expandAnimation, expandContentAnimation } from './animations';
import { ONE_MODAL_TEST_ID } from './constants';
import { OneDetails } from './details/OneDetails';
import { OneMessageBox } from './message-box/OneMessageBox';
import { OneChatFeedContent } from './OneChatFeedContent';
import { OneFullscreenHeader } from './OneFullscreenHeader';
import { OnePopoverHeader } from './OnePopoverHeader';

import * as styles from './styles';

const OneModal: FC = () => {
  const dispatch = useDispatch();
  const isOneModalOpened = useSelector(getIsOneModalOpened);
  const isExpanded = useSelector(getIsOneModalExpanded);
  const prevIsExpanded = usePrevious(isExpanded, false);
  const isDarkMode = useSelector(isDarkModeEnabled);
  const containerRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const prevLocation = usePrevious(location);
  const [locationChanged, setLocationChanged] = useState(false);

  const notifications = useSelector(getNotifications);
  const hasVisibleNotifications = getVisibleNotifications(notifications).length > 0;

  const { isCustomScreen: isDesktop } = useScreenSize({ minWidth: SPOTLIGHT_MEDIA_QUERY });

  const currentHeight = containerRef.current?.clientHeight || 0;
  const shouldHideGlowAnimation = isExpanded || (prevIsExpanded && !isExpanded);
  const shouldApplyExpandAnimation = isExpanded && !prevIsExpanded && isDesktop;
  const shouldApplyCollapseAnimation = !isExpanded && shouldHideGlowAnimation;
  const shouldApplyGlowAnimation = !shouldHideGlowAnimation && !locationChanged;

  useEffect(() => {
    if (!prevLocation || !isOneModalOpened) {
      return;
    }

    const hasLocationChanged = location.key !== prevLocation?.key;

    if (hasLocationChanged && isExpanded) {
      dispatch(OneViewActions.toggleExpand());
    }

    setLocationChanged(hasLocationChanged);
  }, [location]);

  useEffect(() => {
    if (isOneModalOpened) {
      setLocationChanged(false);
      dispatch(OneViewActions.resetOneUnreadMessagesCount());
    }
  }, [dispatch, isOneModalOpened]);

  useEffect(() => {
    if (isDesktop || !isOneModalOpened) {
      return;
    }

    if (isExpanded) {
      dispatch(OneViewActions.closeDetails());

      return;
    }

    if (locationChanged) {
      dispatch(OneViewActions.hideModal());
      setLocationChanged(false);

      return;
    }

    dispatch(OneViewActions.toggleExpand());
  }, [dispatch, isExpanded, isOneModalOpened, isDesktop]);

  if (!isOneModalOpened) {
    return null;
  }

  return (
    <div
      ref={containerRef}
      className={cx('lc-dark-theme', styles.container(isDarkMode, hasVisibleNotifications), {
        [styles.containerExpanded(hasVisibleNotifications)]: isExpanded,
        [expandAnimation(currentHeight)]: shouldApplyExpandAnimation,
        [collapseAnimation]: shouldApplyCollapseAnimation,
        [styles.oneAnimation(isDarkMode)]: shouldApplyGlowAnimation,
      })}
      data-testid={ONE_MODAL_TEST_ID}
    >
      {!isExpanded && <OnePopoverHeader />}
      {isExpanded && <OneFullscreenHeader />}

      <div className={cx(styles.chatContainer(isExpanded))}>
        <div className={cx(styles.chatBody, { [expandContentAnimation]: shouldApplyExpandAnimation })}>
          <OneChatFeedContent />
          <OneMessageBox />
        </div>
        {isExpanded && <OneDetails />}
      </div>
    </div>
  );
};

export default OneModal;
