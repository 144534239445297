import { type MouseEvent, type FC } from 'react';

import { cx } from '@emotion/css';
import { Search } from '@livechat/design-system-icons';
import { Icon, Tag, Text } from '@livechat/design-system-react-components';
import { isBefore } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';

import { GlobalModal } from 'constants/global-modal';
import { SpotlightTrackEvent } from 'constants/spotlight';
import { getModKey } from 'helpers/keyboard';
import { useParamSelector } from 'hooks/use-param-selector';
import { trackEvent } from 'services/event-tracking';
import { getAccountCreateDate } from 'store/entities/accounts/selectors';
import { getLoggedInAgentAccountId } from 'store/entities/agents/selectors';
import { AgentCustomPropertiesActions } from 'store/features/agent-custom-properties/actions';
import { AgentCustomPropertyName } from 'store/features/agent-custom-properties/interfaces';
import {
  isDarkModeEnabled,
  getSpotlightActionsOnboardingSeen,
  getOneOnboardingSeen,
} from 'store/features/agent-custom-properties/selectors';
import { GlobalModalActions } from 'store/features/global-modals/actions';

import * as styles from '../styles';

import { SPOTLIGHT_ACTIONS_RELEASE_DATE, SPOTLIGHT_TRIGGER_TEST_ID } from './constants';
import SpotlightActionsOnboardingTooltip from './SpotlightActionsOnboarding';

export const SpotlightTrigger: FC = () => {
  const dispatch = useDispatch();
  const isDarkMode = useSelector(isDarkModeEnabled);
  const hasSeenOnboarding = useSelector(getSpotlightActionsOnboardingSeen);
  const accountId = useSelector(getLoggedInAgentAccountId);
  const agentCreationDate = useParamSelector(getAccountCreateDate, accountId);
  const hasSeenOneOnboarding = useSelector(getOneOnboardingSeen);

  const shouldShowSpotlightActionsOnboarding = Boolean(
    !hasSeenOnboarding &&
      hasSeenOneOnboarding &&
      accountId &&
      agentCreationDate &&
      isBefore(agentCreationDate, SPOTLIGHT_ACTIONS_RELEASE_DATE)
  );

  const handleTriggerClick = (event: MouseEvent<HTMLDivElement>): void => {
    event.preventDefault();

    dispatch(GlobalModalActions.showModal(GlobalModal.Spotlight));

    trackEvent(SpotlightTrackEvent.SpotlightTrigger, null, {
      source: 'search input click',
    });
  };

  return (
    <SpotlightActionsOnboardingTooltip isVisible={shouldShowSpotlightActionsOnboarding}>
      <div
        className={styles.spotlightTrigger(isDarkMode)}
        onClick={handleTriggerClick}
        data-testid={SPOTLIGHT_TRIGGER_TEST_ID}
        data-is-spotlight="true"
      >
        <div className={cx('spotlightTrigger', styles.triggerBackground(isDarkMode))} />
        <div className={styles.searchWrapper}>
          <Icon source={Search} />
          <Text>Search or ask</Text>
        </div>
        <div className={styles.shortcutWrapper}>
          <Tag className={styles.blackTag}>
            <Text>{getModKey(true)}</Text>
          </Tag>
          <Tag className={styles.blackTag}>
            <Text>K</Text>
          </Tag>
        </div>
      </div>
    </SpotlightActionsOnboardingTooltip>
  );
};
