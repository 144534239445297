// @ts-strict-ignore
import { type KeyMap } from 'helpers/interface';
import { trimObject } from 'helpers/object';
import { type ISerializedFiltersWithRawDates } from 'interfaces/reports';

import { BaseApi } from '../base-api';

export class QueueApi extends BaseApi {
  protected readonly prefix = 'queue';

  fetchQueueAbandonment = ({
    dateFrom = null,
    dateTo = null,
    groups = null,
    page = 1,
  }: ISerializedFiltersWithRawDates) =>
    this.get<KeyMap>('left', trimObject({ date_from: dateFrom, date_to: dateTo, groups, page }));
}
