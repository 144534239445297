import isEmpty from 'lodash.isempty';

import { type KeyMap } from 'helpers/interface';

import { featureBranchURL, leadingSlash, trailingSlash, urlProtocol, domainPrefixes } from './regexp';

export type ParamValue = string | number | boolean;

export type IKeyAny<T = any> = Record<string, T>;
export type IKeyValue<T = ParamValue> = Record<string, T | T[]>;

export const UTM_PARAMS = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term'];

/**
 * Returns URL that can be safely used in:
 * - "<img src>" HTML attribute
 * - "background-image: url()" CSS attribute
 */
export function normalizeAssetUrl(url: string): string {
  let targetUrl = url;
  if (!/^(http|\/\/)/.test(url)) {
    targetUrl = `//${url}`;
  }

  return targetUrl;
}

/**
 * Appends URI param to given URI address
 * @example
 *   updateQueryString(
 *     'https://www.livechat.com/',
 *     'foo',
 *     'bar'
 *   ); // https://www.livechat.com/?foo=bar
 */
export function updateQueryString(uri: string, key: string, value: string): string {
  const re = new RegExp(`([?&])${key}=.*?(&|$)`, 'i');
  const separator = uri.indexOf('?') !== -1 ? '&' : '?';

  if (uri.match(re)) {
    return uri.replace(re, `$1${key}=${encodeURIComponent(value)}$2`);
  }

  return `${uri}${separator}${key}=${encodeURIComponent(value)}`;
}

export function removeQueryParam(uri: string, key: string): string {
  const re = new RegExp(`([?&])${key}(=.*?)?(&|$)`, 'i');
  if (uri.match(re)) {
    return uri
      .replace(re, '$1$3')
      .replace(/\?&/, '?')
      .replace(/&&/, '&')
      .replace(/(\?|&)$/, '');
  }

  return uri;
}

export function encodeURIIfNeeded(uri: string): string {
  try {
    if (decodeURI(uri) === uri) {
      return uri;
    }

    return encodeURI(decodeURIComponent(uri));
  } catch (error) {
    return uri;
  }
}

/**
 * in: http://www.google.com/test/
 * out: www.google.com
 */
export function getUriHostname(uri: string): string {
  const a = document.createElement('a');
  a.href = uri;

  return a.hostname;
}

/**
 * Checks if current URL contains given path name.
 *
 * Example:
 * - in: ghost for http://my.livechatinc.com/ghost?someParam=someValue
 * - out: true
 */
export function urlContainsPathName(pathName: string): boolean {
  return window.location.pathname.includes(pathName);
}

/**
 * Returns URL using CDN usable by CSS and img component.
 * @param imageUrl Image url in any possible format (with protocol, already with CDN etc.)
 */
export function getImageWithCDN(imageUrl?: string): string | undefined {
  if (!imageUrl) {
    return;
  }

  const dataPrefix = 'data:image';
  const fileApiPrefix = 'https://cdn.livechat-files.com/api/file/lc/main';
  const s3FileBucket = 'livechat.s3.amazonaws.com';
  const hasProtocol = urlProtocol.test(imageUrl);

  if (imageUrl.includes(s3FileBucket)) {
    return fileApiPrefix + imageUrl.split(s3FileBucket)[1];
  }

  if (imageUrl.includes(dataPrefix)) {
    return imageUrl;
  }

  const isExternalUrl = !imageUrl.includes('amazonaws.com') && !imageUrl.includes('api/file/lc');
  if (isExternalUrl) {
    return hasProtocol ? imageUrl : `//${imageUrl}`;
  }

  const withProtocol = addProtocolIfNeeded(imageUrl);

  return withProtocol;
}

export function trimProtocol(path: string): string {
  if (!path) {
    return '';
  }

  return path.replace(/(^\w+:|^)\/\//, '');
}

export enum QueryParamsArrayType {
  Brackets = 'brackets',
  None = 'none',
}

export function stringifyQueryParams(params: IKeyValue, arrayParsingType = QueryParamsArrayType.Brackets): string {
  if (!params) {
    return '';
  }

  return Object.keys(params)
    .sort()
    .map((key) => {
      if (!Array.isArray(params[key])) {
        const value = params[key] === undefined || params[key] === null ? '' : params[key].toString();

        return value ? `${encodeURIComponent(key)}=${encodeURIComponent(value)}` : `${encodeURIComponent(key)}`;
      }

      const stringArray = (params[key] as ParamValue[]).map((item) => item.toString());

      switch (arrayParsingType) {
        case QueryParamsArrayType.None:
          return stringArray
            .map((value) =>
              value ? `${encodeURIComponent(key)}=${encodeURIComponent(value)}` : `${encodeURIComponent(key)}`
            )
            .join('&');
        default:
          return stringArray
            .map((value) =>
              value ? `${encodeURIComponent(key)}[]=${encodeURIComponent(value)}` : `${encodeURIComponent(key)}[]`
            )
            .join('&');
      }
    })
    .join('&');
}

export function parseQueryParams(params: string): IKeyValue<string> {
  if (!params) {
    return {};
  }

  const pairs = new URLSearchParams(params);
  const entries = Array.from(pairs.entries());
  const mergedPairs = entries.reduce((acc, [key, value]) => {
    if (isEmpty(value)) {
      return acc;
    }

    const isArrayParam = key.endsWith('[]');
    const valueToSet = isArrayParam ? [decodeURIComponent(value)] : decodeURIComponent(value);
    const paramName = isArrayParam ? key.slice(0, -2) : key;
    const shouldUseArray = isArrayParam && Array.isArray(acc[paramName]);

    return {
      ...acc,
      [paramName]: shouldUseArray ? [...acc[paramName], ...valueToSet] : valueToSet,
    };
  }, {});

  return mergedPairs;
}

export function getAppRoot(path = window.location.pathname): string {
  const matches = path.match(featureBranchURL);

  if (matches) {
    return `${matches[0]}/`;
  }

  return '/';
}

export function getAppRootWithOrigin(origin = window.location.origin, path = window.location.pathname): string {
  const appRoot = getAppRoot(path);
  if (appRoot !== '/') {
    return `${origin}${appRoot}`;
  }

  return `${origin}/`;
}

export const getCleanDomain = (url: string): string => {
  return url.replace(leadingSlash, '').replace(domainPrefixes, '').replace(trailingSlash, '');
};

export const getRootDomain = (hostname = window.location.hostname): string => {
  const includesEnvironmentSubdomain = /labs|staging/.test(hostname);
  const sliceNumber = includesEnvironmentSubdomain ? -3 : -2;

  return hostname.split('.').slice(sliceNumber).join('.');
};

export function getMarketplaceDomain(url: string): string {
  const marketplaceUrl = new URL(url);

  return `${marketplaceUrl.protocol}//${marketplaceUrl.host}`;
}

/**
 * Decodes query string
 * - should return false on empty query string
 * - should merge existing keys' values with \n
 * @param queryString - string to be decoded
 * @param dontAppend - if true values with the same keys won't be concatenated after new lines
 */
export function decodeQueryString(s: string, dontAppend = false): KeyMap<string> | false {
  let string = '';
  // remove last & in query string
  if (s.substr(s.length - 1) === '&') {
    string = s.substr(0, s.length - 1);
  } else {
    string = s;
  }

  if (!string.length) {
    return false;
  }

  const o: KeyMap<string> = {};
  const urlParams = string.split('&');
  urlParams.forEach((urlParam) => {
    const v = urlParam.split('=');
    if (v[0] && v[0].length) {
      // prevent not-named query string variable
      let newval: string;
      try {
        newval = typeof v[1] !== 'undefined' ? decodeURIComponent(v[1]) : '';
      } catch (error) {
        newval = v[1];
      }
      try {
        v[0] = decodeURIComponent(v[0]);
      } catch (error) {
        // ignore
      }
      if (typeof o[v[0]] === 'string' && o[v[0]].length && !dontAppend) {
        // we have this key already set, if dontAppend is not set, append the new value
        o[v[0]] += '\n' + newval;
      } else {
        o[v[0]] = newval;
      }
    }
  });

  return o;
}

/**
 * Retrieves the value of a specified query parameter from the current URL.
 *
 * @param paramName - The name of the query parameter to retrieve.
 * @returns The value of the query parameter if it exists, otherwise null.
 */
export function getUrlParam(paramName: string): string | null {
  const url = new URL(window.location.href);
  const paramValue = url.searchParams.get(paramName);

  return paramValue;
}

/**
 * Retrieves the value of a specified parameter from the hash fragment of the current URL.
 *
 * @param paramName - The name of the hash parameter to retrieve.
 * @returns The value of the hash parameter if it exists, otherwise null.
 */
export function getHashParam(paramName: string): string | null {
  const url = new URL(window.location.href);
  const hashParams = new URLSearchParams(url.hash.substring(1));
  const paramValue = hashParams.get(paramName);

  return paramValue;
}

export function addProtocolIfNeeded(url: string): string {
  return urlProtocol.test(url) ? url : `https://${url}`;
}
