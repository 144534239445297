import type { StateSnapshot } from 'react-virtuoso';

import { type OneViewState } from './interfaces';

export function getStateForResetOneUnreadMessagesCount(state: OneViewState): OneViewState {
  return {
    ...state,
    unreadMessagesCount: 0,
  };
}

export function getStateForOneIsLoading(state: OneViewState, isLoading: boolean): OneViewState {
  return {
    ...state,
    isLoading,
  };
}

export function getStateForIncreaseOneUnreadMessagesCount(state: OneViewState, count: number): OneViewState {
  return {
    ...state,
    unreadMessagesCount: state.unreadMessagesCount + count,
  };
}

export function getStateForSetOneListStateSnapshot(
  state: OneViewState,
  listStateSnapshot: StateSnapshot | null
): OneViewState {
  return {
    ...state,
    listStateSnapshot,
  };
}

export function getStateForSaveOneDraftMessage(state: OneViewState, draftMessage: string): OneViewState {
  return {
    ...state,
    draftMessage,
  };
}

export function getStateForRemoveOneDraftMessage(state: OneViewState): OneViewState {
  return {
    ...state,
    draftMessage: '',
  };
}

export function getStateForCancelOneRequest(state: OneViewState): OneViewState {
  return {
    ...state,
    isLoading: false,
  };
}

export function getStateForShowOneModal(state: OneViewState): OneViewState {
  return {
    ...state,
    modal: {
      ...state.modal,
      isOpened: true,
    },
  };
}

export function getStateForHideOneModal(state: OneViewState): OneViewState {
  return {
    ...state,
    modal: {
      ...state.modal,
      isOpened: false,
    },
  };
}

export function getStateForToggleOneModalExpand(state: OneViewState): OneViewState {
  return {
    ...state,
    modal: {
      ...state.modal,
      isExpanded: !state.modal.isExpanded,
    },
  };
}

export function getStateForToggleOneModal(state: OneViewState): OneViewState {
  return {
    ...state,
    modal: {
      ...state.modal,
      isOpened: !state.modal.isOpened,
    },
  };
}

export function getStateForOpenOneDetails(state: OneViewState): OneViewState {
  return {
    ...state,
    modal: {
      ...state.modal,
      isDetailsOpened: true,
    },
  };
}

export function getStateForCloseOneDetails(state: OneViewState): OneViewState {
  return {
    ...state,
    modal: {
      ...state.modal,
      isDetailsOpened: false,
    },
  };
}

export function getStateForSetScrollPosition(state: OneViewState, scrollPosition: number | null): OneViewState {
  return {
    ...state,
    modal: {
      ...state.modal,
      scrollPosition,
    },
  };
}

export function getStateForShowOneNewMessageTooltip(state: OneViewState): OneViewState {
  return {
    ...state,
    isNewMessageToastVisible: true,
  };
}

export function getStateForHideOneNewMessageTooltip(state: OneViewState): OneViewState {
  return {
    ...state,
    isNewMessageToastVisible: false,
  };
}

export function getStateForOpenCommandSuggestions(state: OneViewState): OneViewState {
  return {
    ...state,
    isCommandSuggestionsOpened: true,
  };
}

export function getStateForCloseCommandSuggestions(state: OneViewState): OneViewState {
  return {
    ...state,
    isCommandSuggestionsOpened: false,
  };
}
