import { ITourStep, TourStepType } from '../interfaces';

import { ColumnConfigButtonStep, TargetConfigButtonStep } from './tour-steps';
export enum AgentsPerformanceReportStep {
  TargetConfigButton = 'TargetConfigButton',
  ColumnConfigButton = 'ColumnConfigButton',
}
export const agentsPerformanceReportSteps: ITourStep[] = [
  {
    id: AgentsPerformanceReportStep.TargetConfigButton,
    type: TourStepType.GuideTooltip,
    tooltip: {
      placement: 'right',
      content: <TargetConfigButtonStep />,
    },
  },
  {
    id: AgentsPerformanceReportStep.ColumnConfigButton,
    type: TourStepType.GuideTooltip,
    tooltip: {
      placement: 'right',
      content: <ColumnConfigButtonStep />,
    },
  },
];
