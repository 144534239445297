// @ts-strict-ignore
import * as Sentry from '@sentry/react';
import type { ErrorEvent, EventHint, SamplingContext, StartSpanOptions, WorkerLocation } from '@sentry/types';

import { EnvironmentType } from 'constants/environment';
import envConfig from 'env-config';

import { isAudioPlayNotAllowedException } from './cases/audio-play-not-allowed';
import { ignoredErrors } from './cases/ignored-errors';
import { sentryContext } from './context';
import { isEventWithInTheLimit } from './helpers/events-limitation';
import { isUnsupportedSystemOrBrowser } from './helpers/is-unsupported-system-or-browser';

declare const BUILD_NUMBER: string;

interface TransactionTrackingConfig {
  pathNames: string[];
  sampleRatesMap: Record<EnvironmentType, number>;
}

const enum TransactionType {
  Pageload = 'pageload',
  Navigation = 'navigation',
}

const CHAT_ID_REGEX = /\/[A-Z0-9]{10}/g;
const UUID_REGEX = /\/[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[a-f0-9]{4}-[a-f0-9]{12}/g;

const TRACEABLE_MEASUREMENTS = ['fcp', 'lcp', 'cls', 'fid'];

const TRANSACTION_IDLE_TIMEOUT = 5000;
const EXCLUDED_SUBPATHS = ['/team/groups', '/team/chatbots', '/team'];

const transactionTrackingConfig: Record<TransactionType, TransactionTrackingConfig> = {
  [TransactionType.Pageload]: {
    pathNames: ['/chats/', '/traffic', '/home', '/reports/', '/archives/', '/team/'],
    sampleRatesMap: {
      [EnvironmentType.Labs]: 1,
      [EnvironmentType.Staging]: 0,
      [EnvironmentType.Production]: 0.05,
    },
  },
  [TransactionType.Navigation]: {
    pathNames: ['/chats/', '/traffic'],
    sampleRatesMap: {
      [EnvironmentType.Labs]: 1,
      [EnvironmentType.Staging]: 0,
      [EnvironmentType.Production]: 0.0002,
    },
  },
};

/**
 * Handles any modifications or ignores before sending the event to Sentry.
 * @param event Sentry event.
 */
function handleBeforeSend(event: ErrorEvent, hint?: EventHint): Promise<ErrorEvent> | ErrorEvent {
  if (!isEventWithInTheLimit(event, hint)) {
    return null;
  }

  if (isAudioPlayNotAllowedException(event)) {
    return null;
  }

  return event;
}

function getIsTraceablePath(traceablePathNames: string[], location: WorkerLocation): boolean {
  return traceablePathNames.some((pathname) => location.pathname.includes(pathname)) || location.pathname === '/';
}

function handleTracesSampler(samplingContext: SamplingContext): number {
  const { location, attributes } = samplingContext;

  const transactionConfig: TransactionTrackingConfig = transactionTrackingConfig?.[attributes?.['sentry.op']];
  if (!transactionConfig) {
    return 0;
  }
  const isTraceablePath = getIsTraceablePath(transactionConfig.pathNames, location);

  return isTraceablePath ? (transactionConfig.sampleRatesMap[envConfig.env] as number) : 0;
}

const replacePath = (originalPath: string, subPaths: string[]): string => {
  for (let i = 0; i < subPaths.length; i += 1) {
    const subPath = subPaths[i];
    if (originalPath.includes(subPath)) {
      return subPath;
    }
  }

  return originalPath;
};

function handleBeforeStartSpan(options: StartSpanOptions): StartSpanOptions {
  return {
    ...options,
    name: replacePath(
      window.location.pathname.replace(CHAT_ID_REGEX, '/:id').replace(UUID_REGEX, '/:id'),
      EXCLUDED_SUBPATHS
    ),
  };
}

function shouldExtendEventContext(event: Sentry.Event): boolean {
  return !event.user?.license || !event.user?.login;
}

async function eventProcessor(event: Sentry.Event): Promise<Sentry.Event> {
  if (shouldExtendEventContext(event)) {
    const context = await sentryContext.waitForContext();

    event.tags = { ...event.tags, ...context };

    event.user = { ...event.tags, ...context };
  }

  const { contexts, measurements } = event;

  if (
    (contexts?.trace?.op as TransactionType) === TransactionType.Pageload &&
    (!measurements || !TRACEABLE_MEASUREMENTS.some((measurement) => !!measurements[measurement]))
  ) {
    return null;
  }

  return event;
}

function initialize(): void {
  Sentry.addEventProcessor(eventProcessor);
  Sentry.init({
    dsn: envConfig.sentry_dsn,
    environment: envConfig.env,
    release: `agentapp-${envConfig.env}-${BUILD_NUMBER}`,
    ignoreErrors: ignoredErrors,
    beforeSend: handleBeforeSend,
    integrations: [
      Sentry.browserTracingIntegration({
        beforeStartSpan: handleBeforeStartSpan,
        idleTimeout: TRANSACTION_IDLE_TIMEOUT,
      }),
    ],
    tracesSampler: handleTracesSampler,
  });
}

if (envConfig.sentry_dsn && !isUnsupportedSystemOrBrowser()) {
  initialize();
}
