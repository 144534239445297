// @ts-strict-ignore

import { AccountStatus } from 'constants/account-status';
import { AgentType } from 'constants/agent-type';
import { AcceptingChatsStatus, LoginStatus } from 'constants/login-status';
import { Permission } from 'constants/permission';
import { Role } from 'constants/role';
import { SessionType } from 'constants/session-type';
import { isLoggedOnDesktop, isLoggedOnMobile, type ISessionInstance } from 'helpers/agent';
import { anyToBoolean } from 'helpers/boolean';
import { type PickRequired, type Subset } from 'helpers/interface';
import { isDefined } from 'helpers/is-defined';
import { AgentBaseSerializerV3 } from 'services/api/common/agent-base/agent-base-serializer-v3';
import { AppStateProvider } from 'services/app-state-provider';
import {
  EmailSubscription,
  Notification,
  type Agent,
  type UpdateAgentRequest,
} from 'services/connectivity/configuration-api/agents/types';
import { type IAgent, type IEmailUpdates, type Notifications } from 'store/entities/agents/interfaces';
import { getAgentEmailSubscriptions, getAgentNotifications } from 'store/entities/agents/selectors';

import { AgentStatusSerializerV3 } from './agent-status-serializer';

export abstract class AgentSerializer {
  static deserializeSessionTypes(instances: ISessionInstance[]): SessionType[] {
    const sessionTypes: SessionType[] = [];

    if (isLoggedOnDesktop(instances)) {
      sessionTypes.push(SessionType.Desktop);
    }

    if (isLoggedOnMobile(instances)) {
      sessionTypes.push(SessionType.Mobile);
    }

    return sessionTypes;
  }

  static deserializeLastLogout(accountStatus: LoginStatus, lastLogout: number): number {
    return accountStatus && accountStatus === LoginStatus.Online && !lastLogout
      ? new Date().getTime() / 1000
      : lastLogout;
  }

  static deserializeAccountStatus(data: Pick<Agent, 'suspended' | 'awaiting_approval'>): AccountStatus {
    if (data.suspended) {
      return AccountStatus.Suspended;
    }

    if (data.awaiting_approval) {
      return AccountStatus.AwaitingApproval;
    }

    return AccountStatus.Active;
  }

  static permissionToRoleMap(data: Permission): Role {
    switch (data) {
      case Permission.Owner:
        return Role.Owner;
      case Permission.ViceOwner:
      case Permission.Administrator:
        return Role.Admin;
      default:
        return Role.Agent;
    }
  }

  static deserializeEmailUpdates(emailSubscriptions: EmailSubscription[]): IEmailUpdates {
    return Object.values(EmailSubscription).reduce<Record<string, boolean>>(
      (acc, subsc) => ({ ...acc, [subsc]: emailSubscriptions?.includes(subsc) }),
      {}
    );
  }

  static deserializeNotifications(notifications?: Notification[]): Notifications {
    return Object.values(Notification).reduce<Record<string, boolean>>(
      (acc, not) => ({ ...acc, [not]: notifications?.includes(not) }),
      {}
    );
  }

  /**
   * Deserializes agent data provided by API.
   * @param data Agent data provided in API structure.
   */
  static deserialize(data: Agent, status: LoginStatus): IAgent {
    return {
      ...AgentBaseSerializerV3.deserialize(data, status),
      statusAfterLogin: AgentStatusSerializerV3.deserializeStatusAfterLogin(data.login_status),
      isOnboardingFinished: data.visited_welcome_tutorial,
      accountId: data.account_id,
      accountStatus: AgentSerializer.deserializeAccountStatus(data),
      role: AgentSerializer.permissionToRoleMap(data.role),
      emailBounced: anyToBoolean(data['email.bounce.type']),
      permission: data.role || Permission.Normal,
      emailSubscriptions: AgentSerializer.deserializeEmailUpdates(data.email_subscriptions),
      notifications: AgentSerializer.deserializeNotifications(data.notifications),
      type: AgentType.Agent,
    };
  }

  static partialDeserialize(data: PickRequired<Agent, 'id'>): PickRequired<IAgent, 'login'> {
    const result = AgentBaseSerializerV3.partialDeserialize(data) as PickRequired<IAgent, 'login'>;

    if (isDefined(data.login_status)) {
      result.statusAfterLogin = AgentStatusSerializerV3.deserializeStatusAfterLogin(data.login_status);
    }

    if (isDefined(data.account_id)) {
      result.accountId = data.account_id;
    }

    if (isDefined(data.suspended) || isDefined(data.awaiting_approval)) {
      result.accountStatus = AgentSerializer.deserializeAccountStatus(
        data as Pick<Agent, 'suspended' | 'awaiting_approval'>
      );
    }

    if (isDefined(data['email.bounce'])) {
      result.emailBounced = anyToBoolean(data['email.bounce']);
    }

    if (isDefined(data.visited_welcome_tutorial)) {
      result.isOnboardingFinished = data.visited_welcome_tutorial;
    }

    if (isDefined(data.role)) {
      result.permission = data.role;
      result.role = AgentSerializer.permissionToRoleMap(data.role);
    }

    if (isDefined(data.email_subscriptions)) {
      result.emailSubscriptions = AgentSerializer.deserializeEmailUpdates(data.email_subscriptions);
    }

    if (isDefined(data.notifications)) {
      result.notifications = AgentSerializer.deserializeNotifications(data.notifications);
    }

    return result;
  }

  /**
   * Serializes client data into structure required by API.
   * @param model Client structure to be transformed into API structure.
   */
  static serialize(model: PickRequired<Subset<IAgent>, 'login'>): UpdateAgentRequest {
    const serialized: UpdateAgentRequest = AgentBaseSerializerV3.serialize(model);

    if (
      isDefined(model.statusAfterLogin) &&
      model.statusAfterLogin !== AcceptingChatsStatus.AcceptingChatsDuringWorkingHours
    ) {
      serialized.login_status = model.statusAfterLogin;
    }

    if (isDefined(model.permission)) {
      serialized.role = model.permission;
    }

    if (isDefined(model.emailSubscriptions)) {
      const emailSubscriptions = AppStateProvider.selectFromStore(getAgentEmailSubscriptions, model.login);
      serialized.email_subscriptions = Object.entries(Object.assign(emailSubscriptions, model.emailSubscriptions))
        .filter(([, value]) => !!value)
        .map(([key]) => key) as EmailSubscription[];
    }

    if (isDefined(model.notifications)) {
      const notifications = AppStateProvider.selectFromStore(getAgentNotifications, model.login);
      serialized.notifications = Object.entries(Object.assign(notifications, model.notifications))
        .filter(([, value]) => !!value)
        .map(([key]) => key) as Notification[];
    }

    return serialized;
  }
}
