import { select } from 'redux-saga/effects';

import { parseDate } from 'helpers/date';

import { getQueueAbandonmentPage } from '../selectors';

export function* queueAbandonmentDeserializer({ data: rawData }: any): any {
  const { queueAbandonment: data } = rawData;
  const page = yield select(getQueueAbandonmentPage);

  const surveys = data.left_queue
    .filter(
      (element) => element.prechat_survey && element.prechat_survey.map((field) => field.value).filter(Boolean).length
    )
    .map((element) => ({
      id: element.id,
      name: element.visitor.name,
      email: element.visitor.email,
      date: parseDate(element.queue.start_date),
      duration: element.queue.duration,
      groupId: element.queue.group,
      ...(element.prechat_survey
        ? {
            prechat: element.prechat_survey.map((prechatQuestion) => ({
              label: prechatQuestion.key,
              value: prechatQuestion.value,
            })),
          }
        : {}),
    }));

  return {
    page,
    pages: data.pages,
    total: data.total,
    withPrechat: surveys.length,
    surveys,
  };
}
