import { css } from '@emotion/css';
import { DesignToken, SpacingToken } from '@livechat/design-system-react-components';

export const inAppUpgradesWrapper = css`
  padding: var(${SpacingToken.Spacing1});
`;

export const tabsList = css`
  padding: 0 20px;

  .lc-tab,
  .lc-tab__description,
  .lc-tab--selected .lc-tab__description,
  .lc-tab:hover .lc-tab__description {
    color: var(${DesignToken.ContentDefault});

    &:disabled,
    &[disabled] {
      opacity: 0.5;
    }
  }
`;
