import type { FC } from 'react';

import { useSelector } from 'react-redux';

import { useListSuggestions } from 'hooks/use-list-suggestions';
import { useParamSelector } from 'hooks/use-param-selector';
import { getIsOnlyWelcomeMessage } from 'store/entities/one/computed';
import { AgentCustomPropertyName } from 'store/features/agent-custom-properties/interfaces';
import { getAgentCustomProperty } from 'store/features/agent-custom-properties/selectors';

import { MAX_NUMBER_OF_API_SUGGESTIONS, SUGGESTIONS_WRAPPER_DATA_TEST_ID } from './constants';
import { getRandomSuggestions } from './helpers';
import { StaticSuggestion } from './StaticSuggestion';
import { Suggestion } from './Suggestion';

import * as styles from './styles';

export const Suggestions: FC = (): JSX.Element | null => {
  const suggestions = useListSuggestions();
  const isOnlyWelcomeMessage = useSelector(getIsOnlyWelcomeMessage);
  const hasSeenOneOnboarding = useParamSelector(
    getAgentCustomProperty,
    AgentCustomPropertyName.OnePopoverOnboardingSeen
  );

  if (!isOnlyWelcomeMessage || !suggestions.data || !hasSeenOneOnboarding) {
    return null;
  }

  const items = getRandomSuggestions(suggestions.data, MAX_NUMBER_OF_API_SUGGESTIONS);

  return (
    <div className={styles.suggestionsWrapper} data-testid={SUGGESTIONS_WRAPPER_DATA_TEST_ID}>
      {items.map(({ message, description }, index) => (
        <Suggestion key={index} id={index} message={message} description={description} />
      ))}
      <StaticSuggestion />
    </div>
  );
};
