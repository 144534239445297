import uniqueId from 'lodash.uniqueid';

import type { Suggestion } from 'interfaces/one';
import type { Source, IOneErrorEvent, IOneEvent, IOneLoaderEvent } from 'store/entities/one/interfaces';

import { TOP_SOURCES_LIMIT } from './constants';

const EMPTY_ARRAY = [];

function createLoadingEvent(): IOneLoaderEvent {
  return {
    authorType: 'live-assistant',
    eventId: uniqueId(),
    timestampInMs: Date.now(),
    type: 'loader-message',
    authorId: 'live-assistant',
  };
}

function createErrorEvent(): IOneErrorEvent {
  return {
    authorType: 'live-assistant',
    eventId: uniqueId(),
    timestampInMs: Date.now(),
    type: 'error-message',
    authorId: 'live-assistant',
  };
}

interface IBuildGroupedEventsParams {
  groupedEvents: IOneEvent[][];
  isOneLoading: boolean;
  isOneError: boolean | null;
}

export function createGroupedEvents({
  groupedEvents,
  isOneLoading: isOneLoading,
  isOneError: isOneError,
}: IBuildGroupedEventsParams): IOneEvent[][] {
  if (isOneLoading) {
    return [...groupedEvents, [createLoadingEvent()]];
  }

  if (isOneError) {
    return [...groupedEvents, [createErrorEvent()]];
  }

  return groupedEvents;
}

export const getSources = (sources?: Source[]): { topSources: Source[]; remainingSources: number } => {
  if (!sources) {
    return {
      topSources: [],
      remainingSources: 0,
    };
  }

  return {
    topSources: sources.slice(0, TOP_SOURCES_LIMIT),
    remainingSources: sources.slice(TOP_SOURCES_LIMIT)?.length,
  };
};

export function deserializeSuggestionWithShortcuts(suggestions?: Suggestion[]): Required<Suggestion>[] {
  return (
    (suggestions?.filter(({ shortcut }) => !!shortcut) as Required<Suggestion>[]) ||
    (EMPTY_ARRAY as Required<Suggestion>[])
  );
}
