import { type ToastsAction, ToastsActionsNames } from './actions';
import { type IToastsState } from './interfaces';

export const initialState = {
  items: {},
};

export const toastReducer = (state: IToastsState = initialState, action: ToastsAction): IToastsState => {
  switch (action.type) {
    case ToastsActionsNames.SHOW: {
      const { autoHideDelayTime, ...toast } = action.payload;

      return {
        ...state,
        items: {
          ...state.items,
          [toast.id]: {
            ...toast,
          },
        },
      };
    }
    case ToastsActionsNames.REMOVE: {
      const { id } = action.payload;
      const { [id]: removedToast, ...items } = state.items;

      return {
        ...state,
        items,
      };
    }
    case ToastsActionsNames.REMOVE_ALL: {
      return {
        ...state,
        items: {},
      };
    }
    default:
      return state;
  }
};
