import { type FC } from 'react';

import { Loader } from '@livechat/design-system-react-components';

import { type IInAppUpgrade } from 'store/entities/applications/interfaces';

import * as styles from './in-app-upgrade-row/styles';
import { InAppUpgradeHeader } from './InAppUpgradeHeader';
import { InAppUpgradeList } from './InAppUpgradeList';

interface IProps {
  resources: IInAppUpgrade[];
  isLoading: boolean;
}

export const InAppUpgradeTable: FC<IProps> = ({ resources, isLoading }) => (
  <div className={styles.tableWrapper}>
    <InAppUpgradeHeader />

    {isLoading ? (
      <div className={styles.loader}>
        <Loader size="large" />
      </div>
    ) : (
      <InAppUpgradeList resources={resources} />
    )}
  </div>
);
