export enum SubscriptionRouteEvent {
  ChangeInvoiceEmail = 'Changed email for invoices',
  DownloadInvoice = 'Invoice downloaded',
  PayForInvoice = 'Pay for invoice clicked',
  ChangedOwnership = 'Changed ownership',
  EnterpriseDowngradeRequest = 'Send enterprise license downgrade CTA clicked',
  ExploreMarketplaceClicked = 'Explore marketplace link clicked',
  InvoicesSubscribeClick = 'Invoices - "subscribe now" button clicked',
  ManageClicked = 'Manage button clicked',
  UpgradeButtonClicked = 'Upgrade button clicked',
  SubscriptionFailed = 'Card form - subscription failed',
  ProductCartRealizationFailed = 'Product Cart realization failed',
  CreateSubscriptionSideEffectFailed = 'Create subscription - side effect failed',
  UpdateSubscriptionSideEffectFailed = 'Update subscription - side effect failed',
  UpgradeSubscriptionSideEffectFailed = 'Upgrade subscription - side effect failed',
  FirstPaymentStarted = 'First payment - form displayed',
  FirstPaymentFinished = 'First payment - finished',
  SubscriptionUpdated = 'Updated subscription',
  ViewPlans = 'View plans button clicked',
  CancelPageContactSupport = 'Contacted support via Cancel Form page',
  CancelAccountClick = 'Clicked cancel link',
  ProviderAddPayment = 'Provider - clicked add payment method',
  NoAccessPageShow = 'Displayed subscription page with no access',
  ContactSubscriptionAdminClick = 'Contact subscription admin clicked',
  LogoutLinkClick = 'Logout link clicked',
  OpenInstallCodeSection = 'Install code step visited',
  OpenRestartTrialSection = 'Restart trial step visited',
  PreviewFetchError = 'Preview fetch error',
  PricePreviewFetchError = 'Price preview fetch error',
  RestartTrialClicked = 'Restart trial clicked',
  MarketplaceLinkClicked = 'Marketplace link clicked',
  IntegrationClicked = 'Integration clicked',
  SuggestedIntegrationClicked = 'Suggested integration clicked',
  LessInstallationOptionsClicked = 'Less installation options clicked',
  MoreInstallationOptionsClicked = 'More installation options clicked',
  SubscribeButtonClick = 'Subscribe cta clicked',
  CheckoutButtonClick = 'Checkout cta clicked',
  ThankYouGoToMarketplaceClicked = 'Thank you page - Go to marketplace',
  ThankYouGoToAccountDetailsClicked = 'Thank you page - Go to account details',
  KeepMyPlanClick = 'Keep my plan CTA clicked',
  StillWantToDowngradeClick = 'I still want to downgrade CTA clicked',
  ConfirmDowngradeClick = 'Downgrade confirmed clicked',
  KeepMyPlanConfirmClick = 'Keep my plan confirmed clicked',
  ReviewClick = ' - add review button clicked',
  PlanChosen = 'Plan chosen',
  ModalOpened = 'modal opened',
  ContactSupportClicked = 'Contact support button clicked',
  ThankYouComparePlansClicked = 'MP Trial page Compare plans clicked',
  ThankYouContactSalesClicked = 'MP Trial page Contact sales clicked',
  ThankYouChoosePlanClicked = 'MP Trial page Choose plan clicked',
  SuspendAgentsClicked = 'Suspended agents clicked',
  CustomerDetailsClicked = 'Customer details clicked',
  CollectPayment = 'Collect payment button clicked',
  CollectPaymentSuccess = 'Collect payment success',
  CollectPaymentFailed = 'Collect payment failed',
}
