import type { FC } from 'react';

import { useSelector } from 'react-redux';
import { matchRoutes, useLocation } from 'react-router-dom';

import { getVisibleNotifications } from 'helpers/notification-bar';
import { getNotifications } from 'store/features/notifications-bar/selectors';

import { Marketplace } from './Marketplace';

import * as styles from './styles';

const MarketplaceRouter: FC = () => {
  const location = useLocation();
  const matchedPath = matchRoutes([{ path: 'apps/marketplace/*' }], location);
  const notifications = useSelector(getNotifications);
  const hasVisibleNotifications = getVisibleNotifications(notifications).length > 0;

  return (
    <div className={styles.router(!!matchedPath, hasVisibleNotifications)}>
      <Marketplace />
    </div>
  );
};

export default MarketplaceRouter;
