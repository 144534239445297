import { type IInboxMessage } from '../message/inbox/inbox-message';

import { type IInboxMessageSubscriber } from './connection';

export const getIframeOrigin = (url: string) => new URL(url).origin;

export const createSubscriberNotifier =
  (subscribers: Set<IInboxMessageSubscriber>) => (message: IInboxMessage, connectionId: string) => {
    subscribers.forEach((subscriber) => {
      subscriber(message, connectionId);
    });
  };
