import { ChatThreadStatus } from 'constants/chat-thread-status';
import { ChatType } from 'constants/chat-type';
import type { IAppState } from 'services/app-state-provider';
import { getPropertyValue } from 'services/serialization/property';
import { ChatsEntitiesActions } from 'store/entities/chats/actions';
import { type ChatThreadEntity } from 'store/entities/chats/interfaces';
import { getThread } from 'store/entities/chats/selectors';

import { type ThreadPropertiesUpdatedPushEvent } from '../interfaces';

import { isStatusUpdate, isPinnedUpdate } from './helpers/thread-properties-updated';

/**
 * Handles thread properties updated push event.
 * 1. Handle `routing.pinned` property update.
 * 2. Handle `routing.idle` property update.
 * @param {ThreadPropertiesUpdatedPushEvent} payload Push event payload.
 * @param {IAppState} store Redux store.
 */
export function handleThreadPropertiesUpdated(payload: ThreadPropertiesUpdatedPushEvent, store: IAppState): void {
  const { thread_id: threadId, properties } = payload;
  const state = store.getState();
  const thread = getThread(state, threadId);

  if (thread) {
    let threadDetailsToUpdate: Partial<ChatThreadEntity> = {};
    /**
     * 1. update chat pinned status
     */
    const isPinnedPropertyUpdate = isPinnedUpdate(payload.properties);
    if (isPinnedPropertyUpdate) {
      /**
       * thread can be unpinned,
       * we should remove it from unassigned chats collection
       */
      const isPinned = getPropertyValue(payload.properties, 'routing', 'pinned');
      const isUnassigned = thread.type === ChatType.Unassigned;

      if (!isPinned && isUnassigned) {
        store.dispatch(
          ChatsEntitiesActions.removeChatThread({
            threadId,
          })
        );

        store.dispatch(
          ChatsEntitiesActions.updateUnassignedChatsCount({
            threadId,
            type: 'decrease',
          })
        );

        return;
      }
    }

    /**
     * 2. update chat status
     */
    const isStatusPropertyUpdate = isStatusUpdate(payload.properties);
    if (isStatusPropertyUpdate) {
      const isIdle = getPropertyValue(payload.properties, 'routing', 'idle');

      threadDetailsToUpdate = {
        ...threadDetailsToUpdate,
        status: isIdle ? ChatThreadStatus.Idle : ChatThreadStatus.Active,
      };
    }

    /**
     * 3. update thread custom properties
     */
    const threadProps = thread.customProperties;
    if (properties) {
      threadDetailsToUpdate = {
        ...threadDetailsToUpdate,
        customProperties: {
          ...threadProps,
          ...properties,
        },
      };
    }

    // dispatch action if there is anything to update
    if (Object.keys(threadDetailsToUpdate).length > 0) {
      store.dispatch(
        ChatsEntitiesActions.updateChatThread({
          threadId,
          thread: threadDetailsToUpdate,
        })
      );
    }
  }
}
