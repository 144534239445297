import { combineReducers } from 'redux';

import type { IEntitiesState } from 'interfaces/store/entities-state';
import { facebookReducer } from 'store/entities/facebook/reducer';

import { accountsReducer } from './accounts/reducer';
import { agentsReducer } from './agents/reducer';
import { applicationsReducer } from './applications/reducer';
import { autoAccessRulesReducer } from './auto-access-rules/reducer';
import { badgesReducer } from './badges/reducer';
import { billingReducer } from './billing/reducer';
import { botsReducer } from './bots/reducer';
import { cannedResponsesReducer } from './canned-responses/reducer';
import { chatbotInLivechatReducer } from './chatbot-in-livechat/reducer';
import { chatsReducer } from './chats/reducer';
import { companyDetailsReducer } from './company-details/reducer';
import { customersReducer } from './customers/reducer';
import { experimentsReducer } from './experiments/reducer';
import { eyeCatcherReducer } from './eye-catcher/reducer';
import { formsReducer } from './forms/reducer';
import { goalsReducer } from './goals/reducer';
import { greetingsReducer } from './greetings/reducer';
import { groupPropertiesReducer } from './group-properties/reducer';
import { groupsReducer } from './groups/reducer';
import { insightsReducer } from './insights/reducer';
import { integrationGroupPropertiesReducer } from './integration-group-properties/reducer';
import { integrationLicensePropertiesReducer } from './integration-license-properties/reducer';
import { languagesReducer } from './languages/reducer';
import { licenseCustomPropertiesReducer } from './license-custom-properties/reducer';
import { licensePropertiesReducer } from './license-properties/reducer';
import { oneEntitiesReducer } from './one/reducer';
import { productCartReducer } from './product-cart/reducer';
import { recurlyReducer } from './recurly/reducer';
import { subscriptionReducer } from './subscription/reducer';
import { tagsReducer } from './tags/reducer';
import { trustedDomainsReducer } from './trusted-domains/reducer';

export const entitiesReducer = combineReducers<IEntitiesState>({
  accounts: accountsReducer,
  agents: agentsReducer,
  bots: botsReducer,
  applications: applicationsReducer,
  badges: badgesReducer,
  billing: billingReducer,
  cannedResponses: cannedResponsesReducer,
  chatbotInLivechat: chatbotInLivechatReducer,
  chats: chatsReducer,
  companyDetails: companyDetailsReducer,
  customers: customersReducer,
  experiments: experimentsReducer,
  facebook: facebookReducer,
  goals: goalsReducer,
  greetings: greetingsReducer,
  groups: groupsReducer,
  integrationLicenseProperties: integrationLicensePropertiesReducer,
  integrationGroupProperties: integrationGroupPropertiesReducer,
  licenseCustomProperties: licenseCustomPropertiesReducer,
  licenseProperties: licensePropertiesReducer,
  recurly: recurlyReducer,
  tags: tagsReducer,
  domains: trustedDomainsReducer,
  productCart: productCartReducer,
  autoAccessRules: autoAccessRulesReducer,
  groupProperties: groupPropertiesReducer,
  languages: languagesReducer,
  forms: formsReducer,
  insights: insightsReducer,
  eyeCatcher: eyeCatcherReducer,
  subscription: subscriptionReducer,
  one: oneEntitiesReducer,
});
