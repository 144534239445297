import { type FC, useCallback } from 'react';

import { cx } from '@emotion/css';
import { Text } from '@livechat/design-system-react-components';

import { getIconForShortcut } from './menu-icon';
import { type OnePickerOption } from './one-picker-option';

import * as styles from './styles';

interface IProps {
  option: OnePickerOption;
  isSelected: boolean;
  onSelect: (option: OnePickerOption) => void;
  onMouseEnter: () => void;
}

export const CommandMenuItem: FC<IProps> = ({ option, onSelect, isSelected, onMouseEnter }) => {
  const handleClick = useCallback(() => {
    onSelect(option);
  }, [option, onSelect]);

  return (
    <li
      className={cx(
        {
          [styles.activeItem]: isSelected,
        },
        styles.optionContainer
      )}
      onClick={handleClick}
      onMouseEnter={onMouseEnter}
    >
      {getIconForShortcut(option.title)}
      <div>
        <Text className={styles.optionTitle} bold>
          {option.title}
        </Text>
        <Text size="sm" className={styles.optionDescription}>
          {option.description}
        </Text>
      </div>
    </li>
  );
};
