// @ts-strict-ignore
import { createSelector } from 'reselect';

import { ChatThreadStatus } from 'constants/chat-thread-status';
import { GENERAL_GROUP_ID } from 'constants/groups';
import { removeDuplicates } from 'helpers/array';
import { getConfig } from 'helpers/config';
import { shouldShowSuggestedResponses } from 'helpers/device';
import type { KeyMap } from 'helpers/interface';
import { suggestedResponsesTriggerList } from 'helpers/suggested-responses';
import { type CannedResponse } from 'interfaces/canned-responses';
import { type GroupId } from 'interfaces/groups';
import type { IStoreState } from 'interfaces/store/store-state';
import { getLoggedInAgent, getLoggedInAgentGroupsIds } from 'store/entities/agents/selectors';
import {
  getChatGroupIds,
  getIsChatSupervised,
  getThreadIntegrationProperties,
  getThreadStatus,
  type IWithChatsEntityState,
} from 'store/entities/chats/selectors';
import { getSuggestedResponseVisible } from 'store/features/agent-custom-properties/selectors';
import { getIsStarterPlan } from 'store/features/session/selectors';
import { getIsTextAreaVisible } from 'store/views/chats/computed';

import { caseInsensitiveCompare } from './helpers/selectors';
import type {
  CannedResponsesPredictions,
  CannedResponsesPredictionsDataPayload,
  ICannedResponseAutotags,
  ICannedResponseTag,
} from './interfaces';
import {
  type IWithCannedResponsesState,
  getCannedResponsesIdsByTags,
  getCannedResponses,
  getCannedResponsesForGroup,
  getCannedResponseAutotagsById,
  getCannedResponseAutotags,
} from './selectors';

export const getCannedResponsesByTags: (state: IWithCannedResponsesState) => ICannedResponseTag[] = createSelector(
  [getCannedResponses, getCannedResponsesIdsByTags, getLoggedInAgentGroupsIds],
  (cannedResponses, idsByTags, myGroupIds) =>
    Object.keys(idsByTags)
      .sort(caseInsensitiveCompare)
      .reduce((prevValue, tag) => {
        const values = Array.from(
          idsByTags[tag].reduce((acc, id) => {
            const { groupId } = cannedResponses[id];
            const isSuggestedResponse = suggestedResponsesTriggerList.includes(tag);

            if (myGroupIds.includes(groupId) && !isSuggestedResponse) {
              acc.add(cannedResponses[id]);
            }

            return acc;
          }, new Set<CannedResponse>())
        );
        if (values.length > 0) {
          prevValue.push({
            name: tag,
            lowerCaseName: tag.toLocaleLowerCase(),
            values,
          });
        }

        return prevValue as ICannedResponseTag[];
      }, []) as ICannedResponseTag[]
);

const getCannedResponsesForCurrentAndGeneralGroup = (
  state: IWithCannedResponsesState,
  groupId: string
): CannedResponse[] => {
  const generalGroup = GENERAL_GROUP_ID.toString();
  const cannedResponsesForGroupId = groupId !== generalGroup ? getCannedResponsesForGroup(state, groupId) : [];

  return cannedResponsesForGroupId.concat(getCannedResponsesForGroup(state, generalGroup));
};

/**
 * order: [privateCannedResponseFromThreadGroup,privateCannedResponseFromGeneralGroup, sharedCannedResponse]
 */
export const getCannedResponsesForCurrentAndGeneralGroupByThreadId = (
  state: IWithCannedResponsesState & IWithChatsEntityState,
  threadId: string
): CannedResponse[] => {
  const [groupId] = getChatGroupIds(state, threadId);

  return getCannedResponsesForCurrentAndGeneralGroup(state, groupId).sort((first, second) => {
    const left = first.isPrivate;
    const right = second.isPrivate;

    if (left && !right) {
      return -1;
    }
    if (!left && right) {
      return 1;
    }

    return 0;
  });
};

const getCannedResponseTextByMyGroup = (
  singleCannedResponses: CannedResponse[],
  myGroupIds: GroupId[],
  groupId: string
): CannedResponse[] =>
  Array.from(
    singleCannedResponses.reduce((acc, item) => {
      if (item.groupId === groupId && myGroupIds.includes(item.groupId)) {
        acc.add(item);
      }

      return acc;
    }, new Set<CannedResponse>())
  );

export const getCannedResponsesByTagsPerGroups: (state: IWithCannedResponsesState) => ICannedResponseTag[] =
  createSelector(
    [getCannedResponses, getCannedResponsesIdsByTags, getLoggedInAgentGroupsIds],
    (cannedResponses, idsByTags, myGroupIds) =>
      Object.keys(idsByTags)
        .sort(caseInsensitiveCompare)
        .filter((tag) => !suggestedResponsesTriggerList.includes(tag))
        .map((tag) => {
          if (suggestedResponsesTriggerList.includes(tag)) {
            return;
          }

          const singleCannedResponses = idsByTags[tag].map((id) => cannedResponses[id]);
          const allGroups = removeDuplicates(singleCannedResponses.map(({ groupId }) => groupId));

          return allGroups.reduce((prevValue, groupId) => {
            const values = getCannedResponseTextByMyGroup(singleCannedResponses, myGroupIds, groupId);
            const lowerCaseName = tag.toLocaleLowerCase();
            if (values.length > 0) {
              prevValue.push({ name: tag, lowerCaseName, groupId, values });
            }

            return prevValue as ICannedResponseTag[];
          }, []) as ICannedResponseTag[];
        }, [])
        .flat()
  );

export const getShouldShowSuggestedResponses = (state: IStoreState, threadId: string): boolean => {
  const isSuggestedResponseVisible = getSuggestedResponseVisible(state);
  const isSupervisedChat = getIsChatSupervised(state, threadId);
  const threadStatus = getThreadStatus(state, threadId);
  const isTextAreaVisible = getIsTextAreaVisible(state, threadId);
  const isStarterPlan = getIsStarterPlan(state);

  return (
    isSuggestedResponseVisible &&
    !isSupervisedChat &&
    threadStatus !== ChatThreadStatus.Closed &&
    isTextAreaVisible &&
    !isStarterPlan &&
    shouldShowSuggestedResponses()
  );
};

export const getCannedResponseAutotagsByGroupId = (
  state: IStoreState,
  groupId: string
): KeyMap<ICannedResponseAutotags> => {
  const autotags = state.entities.cannedResponses.autotags;

  return Object.entries(autotags).reduce<KeyMap<ICannedResponseAutotags>>((acc, [cannedId, canned]) => {
    if (canned.groupId === groupId) {
      acc[cannedId] = canned;
    }

    return acc;
  }, {});
};

export const getAutotagsByCannedList = (state: IStoreState, cannedList: string[]): string[] =>
  cannedList.reduce<string[]>((acc, cannedId) => {
    const cannedAutotags = getCannedResponseAutotagsById(state, +cannedId);

    cannedAutotags.forEach((autotag) => {
      if (!acc.includes(autotag)) {
        acc.push(autotag);
      }
    });

    return acc;
  }, []);

export const getIsAutotagConnectedWithCannedResponse = createSelector(
  getCannedResponseAutotags,
  (_, tagName: string) => tagName,
  (autotags: KeyMap<ICannedResponseAutotags>, tagName: string) => {
    const autotagsValues = Object.values(autotags);
    let isAutotagConnectedWithCannedResponse = false;

    for (let index = 0; index < autotagsValues.length; index++) {
      if (autotagsValues[index].tags.includes(tagName)) {
        isAutotagConnectedWithCannedResponse = true;
        break;
      }
    }

    return isAutotagConnectedWithCannedResponse;
  }
);

export const getCannedResponsesForAutocomplete = createSelector(
  [
    getCannedResponses,
    getLoggedInAgent,
    (state: IStoreState, threadId: string) => getThreadIntegrationProperties(state, threadId, getConfig().crsNamespace),
  ],
  (cannedResponses, loggedAgent, cannedResponsesPredictionsProperties): CannedResponsesPredictions | null => {
    const version = 'crs_v1';
    const { email: loggedAgentEmail } = loggedAgent || {};

    if (!cannedResponsesPredictionsProperties) {
      return null;
    }

    const {
      predictions,
      model_version: modelVersion,
      request_id: requestId,
    }: CannedResponsesPredictionsDataPayload = JSON.parse(
      cannedResponsesPredictionsProperties[version] as unknown as string
    );

    const cannedResponsesPredictions = predictions.map(({ id }) => id);

    if (!cannedResponsesPredictions.length) {
      return null;
    }

    const selectedPredictions = Object.values(cannedResponses).reduce((acc, val) => {
      if (cannedResponsesPredictions.includes(val.id) && !(val.isPrivate && val.createdBy !== loggedAgentEmail)) {
        const selectedCanned = predictions.find(({ id }) => id === val.id);

        if (selectedCanned) {
          acc.push({ value: val.text, score: selectedCanned.score, id: val.id });
        }
      }

      return acc;
    }, [] as { value: string; score: number; id: string }[]);

    const sortedPredictions = selectedPredictions?.sort((a, b) => b.score - a.score);

    if (!selectedPredictions) {
      return null;
    }

    const canneds = sortedPredictions.map(({ value, id }) => {
      return { value, id };
    });

    if (!canneds.length) {
      return null;
    }

    const predictionsIds = canneds.map((canned) => canned.id);

    return {
      predictions: canneds,
      predictionsIds,
      modelVersion,
      requestId,
    };
  }
);
