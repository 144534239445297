import type { LicenseProperties } from 'interfaces/license-properties';

export const enum NpsFirstChatValue {
  NoFirstChat = 'N/A',
  FirstPeriod = '0-30',
  SecondPeriod = '31-90',
  SecondQuarter = '91-180',
  ThirdQuarter = '181-270',
  FourthQuarter = '271-360',
  AfterFourthQuarter = '360+',
}

export interface LicensePropertiesState extends LicenseProperties {}

export interface SetLicensePropertiesDataPayload extends LicenseProperties {}
export interface UpdateLicensePropertiesDataPayload extends LicenseProperties {}

export interface InactivityTimeouts {
  chatCloseTimeout: string;
  chatTransferTimeout: string;
  chatInactiveTimeout: string;
}
