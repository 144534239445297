import { type FC, useMemo, useRef } from 'react';

import { cx } from '@emotion/css';
import { useSelector } from 'react-redux';

import { getIsOnlyWelcomeMessage, getOneGroupedEvents } from 'store/entities/one/computed';
import { getIsOneError } from 'store/entities/one/selectors';
import { getIsCommandSuggestionsOpened, getIsOneModalExpanded, getOneIsLoading } from 'store/views/one/selectors';

import { expandChatContentAnimation } from './animations';
import { ONE_CHAT_FEED_ID } from './constants';
import { OneEventsGroup } from './events/OneEventsGroup';
import { createGroupedEvents } from './helpers';
import { useScroll } from './hooks/use-scroll';
import { OneChatFeedSkeleton } from './OneChatFeedSkeleton';
import { OneFullscreenEmptyState } from './OneFullscreenEmptyState';
import { UnseenNotificationBadge } from './UnseenNotificationBadge';

import * as styles from './styles';

export const OneChatFeedContent: FC = () => {
  const oneChatFeedRef = useRef<HTMLDivElement>(null);
  const isExpanded = useSelector(getIsOneModalExpanded);
  const groupedEvents = useSelector(getOneGroupedEvents);
  const isOneLoading = useSelector(getOneIsLoading);
  const isOneError = useSelector(getIsOneError);
  const isOnlyWelcomeMessage = useSelector(getIsOnlyWelcomeMessage);
  const isCommandSuggestionsOpened = useSelector(getIsCommandSuggestionsOpened);

  const eventGroups = useMemo(
    () =>
      createGroupedEvents({
        groupedEvents,
        isOneLoading,
        isOneError,
      }),
    [groupedEvents, isOneError, isOneLoading]
  );

  const hasAdditionalEvents = eventGroups.length !== groupedEvents.length;

  const { handleScroll, handleScrollToBottom } = useScroll({
    oneChatFeedRef,
    groupedEvents,
    additionalEvents: hasAdditionalEvents,
  });

  if (groupedEvents.flat().length === 0) {
    return <OneChatFeedSkeleton />;
  }

  if (isOnlyWelcomeMessage && isExpanded) {
    return <OneFullscreenEmptyState />;
  }

  return (
    <div
      id={ONE_CHAT_FEED_ID}
      onScroll={handleScroll}
      ref={oneChatFeedRef}
      className={cx(styles.scroller(isExpanded, isCommandSuggestionsOpened), {
        [expandChatContentAnimation]: isExpanded,
      })}
    >
      <div className={styles.chatMessagesWrapper(isExpanded)}>
        {eventGroups.map((eventGroup, index) => (
          <OneEventsGroup key={index} eventGroup={eventGroup} />
        ))}
        <UnseenNotificationBadge onScrollToBottom={handleScrollToBottom} />
      </div>
    </div>
  );
};
