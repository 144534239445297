import { type ProductId } from '@livechat/design-system-react-components';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { QueryKey } from 'constants/query-key';
import type { ProductData } from 'services/api/accounts/interfaces';
import { ApiManager } from 'services/api/api-manager';
import { getOrganizationId } from 'store/entities/accounts/selectors';

export const fetchMyProducts = async () => {
  const { result, error } = await ApiManager.accountsApi.getMyProducts();

  if (error) {
    throw error;
  }

  return result;
};
export const fetchSubscriptions = async () => {
  const { result, error } = await ApiManager.billingApiV3.fetchActivatedSubscriptions();

  if (error) {
    throw error;
  }

  return result;
};

interface UseProductData {
  organizationId: string;
  installedProducts: ProductData[];
  subscriptions: Partial<
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Record<ProductId, { status: 'trial' | 'active' | 'cancelled' | 'past_due' | 'expired'; next_charge_at?: string }>
  >;
}

export const useProductData = (): UseProductData => {
  const organizationId = useSelector(getOrganizationId);
  const { data: installedProducts } = useQuery([QueryKey.MyProducts], fetchMyProducts, {
    refetchOnWindowFocus: false,
    initialData: [],
  });
  const {
    data: { subscriptions },
  } = useQuery([QueryKey.ActivatedSubscriptions], fetchSubscriptions, {
    refetchOnWindowFocus: false,
    initialData: () => ({ subscriptions: {} }),
  });

  return {
    organizationId,
    installedProducts,
    subscriptions,
  };
};
