import { type FC } from 'react';

import { useSelector } from 'react-redux';

import { getResourcePriceSummary } from 'components/apps/helpers';
import { getActiveAgentsCount } from 'store/entities/agents/selectors';

import { useResourceContext } from '../../../hooks/use-resource-context';

import { CreditCard } from './CreditCard';

import * as styles from './styles';

export const PaymentSummary: FC<{ quantity: number }> = ({ quantity }) => {
  const { trialDays, price, multiplied } = useResourceContext();
  const agentsCount = useSelector(getActiveAgentsCount);

  const { totalPrice, paymentFrequency } = getResourcePriceSummary({
    price,
    multiplied,
    agentsCount,
    quantity,
  });

  return (
    <div className={styles.wrapper}>
      <CreditCard />

      <div className={styles.summary}>
        <p>
          Total {'  '} ${totalPrice}
          {paymentFrequency && <span>{paymentFrequency}</span>}
        </p>

        {!!trialDays && <span>Subscription will start right after {trialDays}-day trial period end</span>}
      </div>
    </div>
  );
};
