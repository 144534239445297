import { useMutation, type UseMutateFunction } from '@tanstack/react-query';

import { QueryKey } from 'constants/query-key';
import { ToastContent } from 'constants/toasts';
import { showToast } from 'helpers/toast';
import { ApiManager } from 'services/api/api-manager';
import { type IDeactivateTransactionResponse } from 'services/api/in-app-upgrades/interfaces';
import { getQueryClient } from 'services/query-client/client';
import { ToastVariant } from 'store/features/toasts/interfaces';

interface IUseDeactivateTransaction {
  deactivateTransaction: UseMutateFunction<
    IDeactivateTransactionResponse,
    string,
    IDeactivateTransactionPayload,
    unknown
  >;
  isProcessing: boolean;
  isSuccess: boolean;
  error: unknown;
}

interface IDeactivateTransactionPayload {
  applicationId: string;
  resourceId: string;
  upgradeId: string | null;
  transactionId: string;
}

const deactivateTransaction = async ({
  applicationId,
  resourceId,
  upgradeId,
  transactionId,
}: IDeactivateTransactionPayload): Promise<IDeactivateTransactionResponse> => {
  if (!upgradeId) {
    throw new Error('Upgrade ID is required');
  }

  const { result, error } = await ApiManager.inAppUpgradesApi.deactivateTransaction(
    applicationId,
    resourceId,
    upgradeId,
    transactionId
  );

  if (error) {
    throw error;
  }

  return result;
};

const handleSuccess = async (): Promise<void> => {
  showToast(ToastVariant.Success, ToastContent.IN_APP_UPGRADE_DEACTIVATE_TRANSACTION_SUCCESS);

  const queryClient = getQueryClient();
  await queryClient.invalidateQueries([QueryKey.InAppUpgrade]);
};

const handleFailure = (): void =>
  showToast(ToastVariant.Error, ToastContent.IN_APP_UPGRADE_DEACTIVATE_TRANSACTION_FAILURE);

export const useDeactivateTransaction = (): IUseDeactivateTransaction => {
  const { mutate, isLoading, isSuccess, error } = useMutation<
    IDeactivateTransactionResponse,
    string,
    IDeactivateTransactionPayload,
    unknown
  >(deactivateTransaction, {
    onSuccess: handleSuccess,
    onError: handleFailure,
  });

  return {
    deactivateTransaction: mutate,
    isProcessing: isLoading,
    isSuccess,
    error,
  };
};
