import { css } from '@emotion/css';
import { DesignToken, RadiusToken, SpacingToken } from '@livechat/design-system-react-components';

import { withVar } from 'helpers/styles';

const activeBackground = `background: color-mix(in srgb, ${withVar(DesignToken.SurfaceLockedWhite)} 15%, transparent)`;

export const tooltip = css`
  position: fixed !important;
  min-width: 200px;
  max-width: 295px;
`;

export const container = css`
  position: relative;
`;

export const link = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  border-radius: var(${RadiusToken['3']});
  height: 42px;
  width: 42px;
  text-decoration: none;
  cursor: pointer;
  color: var(${DesignToken.ContentLockedWhite});

  span {
    transition: all 0.2s ease-in-out;

    &:not([class*='lc-Badge-module__badge']) {
      opacity: 0.49;
    }
  }
  > span svg {
    transform: scale(0.9);
  }

  &:hover {
    text-decoration: none;
    ${activeBackground};

    span {
      opacity: 1;
    }
    > span svg {
      transform: scale(1);
    }
  }
`;

export const active = css`
  ${activeBackground};

  span,
  span[class] {
    // to override [class*='lc-Badge-module__badge']
    opacity: 1;
  }
`;

export const itemTooltip = css`
  padding-top: ${withVar(SpacingToken.Spacing2)};
  padding-bottom: ${withVar(SpacingToken.Spacing2)};
`;
