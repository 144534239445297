import type { FC } from 'react';

import { type IOneEvent, type IOneImageEvent, type IOneMessageEvent } from 'store/entities/one/interfaces';

import { OneErrorEvent } from './OneErrorEvent';
import { OneImageEvent } from './OneImageEvent';
import { OneLoaderEvent } from './OneLoaderEvent';
import { OneMessageEvent } from './OneMessageEvent';

import { virtuosoEmptyElement } from './styles';

interface IProps {
  event: IOneEvent;
  isAgentMessage: boolean;
}

export const OneEvent: FC<IProps> = ({ event, isAgentMessage }) => {
  switch (event.type) {
    case 'text-message': {
      return <OneMessageEvent event={event as IOneMessageEvent} isAgentMessage={isAgentMessage} />;
    }

    case 'loader-message': {
      return <OneLoaderEvent eventId={event.eventId} />;
    }

    case 'error-message': {
      return <OneErrorEvent eventId={event.eventId} />;
    }

    case 'image-message': {
      const imgUrl = (event as IOneImageEvent).imageUrl;

      return <OneImageEvent imageUrl={imgUrl} />;
    }

    default:
      // We must return empty element with fixed height for Virtuoso to work properly
      return <div className={virtuosoEmptyElement} />;
  }
};
