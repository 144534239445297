import { ML_GATEWAY_API_VERSION } from 'constants/ml-gateway';
import type { IChatTopics } from 'interfaces/reports';

import { BaseApi, PassVersionMode } from '../base-api';

import type {
  IAddKnowledgeSource,
  IAnalyzeSourcePayload,
  IAnalyzeSourceResponse,
  IAnnotateReplyRequest,
  ICancelLearning,
  IGenerateReplyRequest,
  IGenerateReplyResponse,
  IKnowledgeSourceResponse,
  ILearnSkill,
  IListSuggestionsResponse,
  IRemoveSkill,
  ISendFeedbackRequest,
  ISendMessageRequest,
  IUpdateSkill,
  ListSkillsResponse,
} from './interfaces/ai-agents';
import type {
  ICreateCannedResponseAutotagsRequest,
  ICreateCannedResponseAutotagsResponse,
  IDeleteCannedResponseAutotagRequest,
  IDeleteCannedResponseAutotagsRequest,
  IGetCannedResponseAutotagsForOrganization,
  IRemoveCannedResponseAutotagResponse,
  IRemoveCannedResponseAutotagsResponse,
  ISetupCannedResponseAutotagsRequest,
  IUpdateCannedResponseAutotagsRequest,
  IUpdateCannedResponseAutotagsResponse,
} from './interfaces/autotags-storage';
import type {
  IAddChatSummaryRequest,
  IAddChatSummaryResponse,
  ICreateChatSummaryRequest,
  ICreateChatSummaryResponse,
  IGetChatSummaryRequest,
  IGetChatSummaryResponse,
  IRemoveChatSummaryRequest,
  IRemoveChatSummaryResponse,
  IUpdateChatSummaryRequest,
  IUpdateChatSummaryResponse,
} from './interfaces/chat-summary-storage';
import type { GetTopicsWithSentimentReportRequest } from './interfaces/chat-topics';
import type { MLGatewaySendEvent } from './interfaces/event-collector';
import type { IFetchTagSuggestionsRequest, IFetchTagSuggestionsResponse } from './interfaces/tag-suggestions';
import type {
  ICreateTextGrammarCorrectionRequest,
  ICreateTextRewordRequest,
  ICreateTextSentimentRequest,
  ICreateTextTranslationRequest,
  IOpenAIChatCompletionRequest,
  ITextEnhancementResponse,
} from './interfaces/text-enhancements';

export class MLGatewayApi extends BaseApi {
  protected readonly version = ML_GATEWAY_API_VERSION;
  protected readonly passVersionMode = PassVersionMode.Prefix;

  fetchTagSuggestions = (payload: IFetchTagSuggestionsRequest) =>
    this.post<IFetchTagSuggestionsResponse>('predict/tag-suggestions', payload);

  createOpenAIChatCompletion = (payload: IOpenAIChatCompletionRequest) =>
    this.post<string[]>('proxy/openai-chat-completions', payload);

  createTextTranslation = (payload: ICreateTextTranslationRequest) =>
    this.post<ITextEnhancementResponse>('text-enhancements/translations', payload);

  createTextSentiment = (payload: ICreateTextSentimentRequest) =>
    this.post<ITextEnhancementResponse>('text-enhancements/sentiments', payload);

  createTextGrammarCorrection = (payload: ICreateTextGrammarCorrectionRequest) =>
    this.post<ITextEnhancementResponse>('text-enhancements/grammar-corrections', payload);

  createTextReword = (payload: ICreateTextRewordRequest) =>
    this.post<ITextEnhancementResponse>('text-enhancements/rewordings', payload);

  createChatSummary = (payload: ICreateChatSummaryRequest) =>
    this.post<ICreateChatSummaryResponse>('chats/generate-summary', payload);

  addChatSummary = (payload: IAddChatSummaryRequest) =>
    this.post<IAddChatSummaryResponse>('chats/add-summary', payload);

  getChatSummary = (payload: IGetChatSummaryRequest) =>
    this.post<IGetChatSummaryResponse>('chats/get-summary', payload);

  updateChatSummary = (payload: IUpdateChatSummaryRequest) =>
    this.post<IUpdateChatSummaryResponse>('chats/update-summary', payload);

  removeChatSummary = (payload: IRemoveChatSummaryRequest) =>
    this.post<IRemoveChatSummaryResponse>('chats/remove-summary', payload);

  getAutotagsConfig = () => this.post<IGetCannedResponseAutotagsForOrganization>('autotags/list-configs', {});

  createCannedResponseAutotags = (payload: ICreateCannedResponseAutotagsRequest) =>
    this.post<ICreateCannedResponseAutotagsResponse>('autotags/add-config', payload);

  updateCannedResponseAutotags = (payload: IUpdateCannedResponseAutotagsRequest) =>
    this.post<IUpdateCannedResponseAutotagsResponse>('autotags/set-config', payload);

  deleteCannedResponseAutotags = (payload: IDeleteCannedResponseAutotagsRequest) =>
    this.post<IRemoveCannedResponseAutotagsResponse>('autotags/delete-config', payload);

  deleteCannedResponseAutotag = (payload: IDeleteCannedResponseAutotagRequest) =>
    this.post<IRemoveCannedResponseAutotagResponse>('autotags/delete-tag', payload);

  setupCanedResponseAutotags = (payload: ISetupCannedResponseAutotagsRequest) =>
    this.post('autotags/create-initial-config', payload);

  getTopicsWithSentimentReport = (payload: GetTopicsWithSentimentReportRequest) =>
    this.post<IChatTopics>('reports/get-topic-sentiment', payload);

  generateReply = (payload: IGenerateReplyRequest) =>
    this.post<IGenerateReplyResponse>('ai-agents/responses/generate', payload);

  sendMessage = (payload: ISendMessageRequest) =>
    this.post<IGenerateReplyResponse>('ai-agents/chat/send-message', payload);

  sendAiAgentFeedback = (payload: ISendFeedbackRequest) => this.post('ai-agents/responses/send-feedback', payload);

  analyzeSource = (payload: IAnalyzeSourcePayload) =>
    this.post<IAnalyzeSourceResponse>('ai-agents/skills/analyze-source', payload);

  addUrlSkill = (payload: IAddKnowledgeSource) =>
    this.post<IKnowledgeSourceResponse>('ai-agents/skills/add-url', payload);

  listSkills = () => this.post<ListSkillsResponse>('ai-agents/skills/list', {});

  learnSkill = (payload: ILearnSkill) => this.post<ILearnSkill>('ai-agents/skills/learn', payload);

  removeSkill = (payload: IRemoveSkill) => this.post<IRemoveSkill>('ai-agents/skills/remove', payload);

  cancelLearningSkill = (payload: ICancelLearning) =>
    this.post<ICancelLearning>('ai-agents/skills/cancel-learning', payload);

  sendEvent = (payload: MLGatewaySendEvent) => this.post('events/send-event', payload);

  updateSkill = (payload: IUpdateSkill) => this.post<IUpdateSkill>('ai-agents/skills/update', payload);

  batchCancelLearning = (payload: string[]) =>
    this.post<ICancelLearning>('ai-agents/skills/batch-cancel-learning', { ids: payload });

  batchLearnSkill = (payload: string[]) => this.post<ILearnSkill>('ai-agents/skills/batch-learn', { ids: payload });

  listSuggestions = () => this.post<IListSuggestionsResponse>('ai-agents/chat/list-suggestions', {});

  sendAnnotation = (payload: IAnnotateReplyRequest) => this.post('ai-agents/responses/annotate', payload);
}
