import { type FC } from 'react';

import { TabsWrapper, TabsList, Tab } from '@livechat/design-system-react-components';
import { generatePath, useParams } from 'react-router-dom';

import { InAppUpgradeTable } from 'components/apps/in-app-upgrade-table/InAppUpgradeTable';
import { navigate } from 'helpers/routing';

import { IN_APP_UPGRADES_TAB, APP_SETTINGS_ROUTE } from '../constants';

import { useFetchInAppUpgrades } from './hooks/use-fetch-in-app-upgrades';

import * as styles from './styles';

export const tabsList = Object.values(IN_APP_UPGRADES_TAB);

const handleTabClick = (slug: string, tabId: string): void => {
  const linkToTabSection = generatePath(APP_SETTINGS_ROUTE['in-app-upgrades'].path, { slug, tab: tabId });
  navigate(linkToTabSection);
};

export const InAppUpgrades: FC = () => {
  const params = useParams<'tab' | 'slug'>();

  const tab = params.tab || '';
  const slug = params.slug || '';

  const { data, isLoading } = useFetchInAppUpgrades(slug);
  const resources = data?.[tab] || data?.all || [];

  return (
    <div className={styles.inAppUpgradesWrapper}>
      <TabsWrapper>
        <TabsList className={styles.tabsList}>
          {tabsList.map(({ id, title }) => {
            const resourcesCounter = data?.[id]?.length;

            return (
              <Tab
                key={id}
                onClick={() => handleTabClick(slug, id)}
                isSelected={tab === id}
                count={resourcesCounter}
                disabled={!resourcesCounter}
              >
                {title}
              </Tab>
            );
          })}
        </TabsList>
      </TabsWrapper>

      <InAppUpgradeTable resources={resources} isLoading={isLoading} />
    </div>
  );
};
