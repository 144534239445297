import { isMobileSafari } from './browser';

const copyContentToClipboardOnIOs = (element: HTMLInputElement | HTMLTextAreaElement): void => {
  const maxRange = element.value.length;
  const range = document.createRange();
  element.contentEditable = 'true';
  element.readOnly = false;
  range.selectNodeContents(element);
  const selection = window.getSelection();
  selection?.removeAllRanges();
  selection?.addRange(range);
  element.setSelectionRange(0, maxRange);
  element.contentEditable = 'false';
  element.readOnly = true;
  document.execCommand('copy');
};

const copyContentToClipboard = (element: HTMLInputElement | HTMLTextAreaElement): void => {
  element.select();
  document.execCommand('copy');
};

export const copyToClipboard = (element: HTMLInputElement | HTMLTextAreaElement): void => {
  // Mobile Safari has some additional requirements.
  // Element holding content to copy must be contentEditable
  // and mustn't be read-only.
  // Additionally, content must be in the selected range.
  if (isMobileSafari) {
    copyContentToClipboardOnIOs(element);
  } else {
    copyContentToClipboard(element);
  }
};

// Clipboard API with fallback see (https://stackoverflow.com/a/30810322)
export const fallbackCopyTextToClipboard = (text: string): boolean => {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '-999px';
  textArea.style.left = '-999px';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
    document.body.removeChild(textArea);

    return true;
  } catch (err) {
    document.body.removeChild(textArea);

    return false;
  }
};

export const copyTextToClipboard = async (text: string): Promise<boolean> => {
  if (!navigator.clipboard) {
    return fallbackCopyTextToClipboard(text);
  }

  try {
    await navigator.clipboard.writeText(text);

    return true;
  } catch (err) {
    return false;
  }
};
