import { css } from '@emotion/css';
import { SpacingToken } from '@livechat/design-system-react-components';

export const listWrapper = css`
  width: 100%;
  height: 100%;
  min-width: min-content;
`;

export const emptyList = css`
  margin-top: var(${SpacingToken.Spacing5});
`;

export const tableWrapper = css`
  height: calc(100% - 35px);
  overflow-x: auto;
`;

export const headerRowWrapper = css`
  box-shadow: rgb(228, 228, 228) 0 1px 5px 0;
  border-bottom: solid 1px #dde2e6;
  height: auto;
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.3);
  position: relative;
  vertical-align: middle;
  text-align: left;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  min-width: min-content;
`;

export const row = css`
  width: 100%;
  display: flex;
  flex: 0 1 auto;
  height: 72px;
  padding: 0 10px;
  align-items: center;
  transition: background-color 0.25s;
  border-bottom: solid 1px #dde2e6;
  box-sizing: border-box;
  cursor: pointer;

  &:hover {
    background-color: rgba(241, 246, 248, 0.7);
  }
`;

export const headerRow = css`
  ${row};

  height: 36px;
`;

const cell = css`
  display: inline-flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  align-items: center;
  padding: 0 20px;
  font-size: 14px;

  > p {
    width: 100%;
    line-height: 18px;
  }

  > span {
    font-size: 13px;
    line-height: 15px;
    color: #7a8289;
  }
`;

export const action = css`
  ${cell};

  flex: 1;
  min-width: 160px;
  justify-content: flex-end;
`;

export const name = css`
  ${cell};

  flex: 3;
  min-width: 250px;
`;

export const price = css`
  ${cell};

  flex: 1;
  min-width: 160px;
`;

export const priceFree = css`
  padding: 8px 0;
`;

export const quantity = css`
  ${cell};

  flex: 1;
  width: 70px;

  .lc-text-field {
    margin-bottom: 0;

    input {
      max-width: 70px;
      width: 100%;
    }
  }
`;

export const summary = css`
  ${cell};

  flex: 2;
  min-width: 220px;
`;

export const actionBtn = css`
  white-space: nowrap;
  margin: 10px 0;
`;

export const loader = css`
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
