import debug from 'debug';

import { DebugLogsNamespace } from 'constants/debug-logs-namespace';
import { delay } from 'helpers/delay';
import { isLabsEnvironment, isProductionEnvironment } from 'helpers/feature-toggle';
import { getServer } from 'helpers/get-server';
import { isApiErrorResponse } from 'services/connectivity/configuration-api/type-guards';
import { HTTPStatus, type RequestErrorParams } from 'services/connectivity/http/types';
import { connectivityStatusClient } from 'services/connectivity/status/client';
import { getLicenseId } from 'store/features/session/selectors';

import { AppStateProvider } from '../app-state-provider';
import { isError, isHTTPError } from '../connectivity/http/type-guards';
import { logsClient } from '../connectivity/queue-api/logs/client';

const log = debug(DebugLogsNamespace.AppServerConnectionErrors);

// a workaround for the case when the license id is not available (it is required in the request, but we want to log all errors)
const FAKE_LICENSE_ID = 12345;
const LOG_THRESHOLD = 0.001; // 0.1%
const MAX_RETRIES = 10;

const logRandomCast = Math.random();

function shouldSkipForRequest(endpoint: string, status: HTTPStatus | null): boolean {
  if (status === HTTPStatus.Unauthorized) {
    log('Unauthorized request, skip logging');

    return true;
  }

  if (endpoint === 'add_user_to_chat' && status === HTTPStatus.UnprocessableEntity) {
    log('Other agent already assigned to chat, skip logging');

    return true;
  }

  return false;
}

function shouldSkipForEnvironment(): boolean {
  return isLabsEnvironment() || (isProductionEnvironment() && logRandomCast >= LOG_THRESHOLD);
}

function getRequestBodyToBeLogged(requestBody: unknown, status: HTTPStatus | null): string | undefined {
  if (status === HTTPStatus.BadRequest) {
    return JSON.stringify(requestBody);
  }

  return undefined;
}

async function sendLog(logObject: Record<string, unknown>, retryAttempt = 1): Promise<void> {
  const licenseId = AppStateProvider.selectFromStore(getLicenseId) || FAKE_LICENSE_ID;

  try {
    log('Sending log', logObject);
    await logsClient.log({
      message: `[livechat-web] ${JSON.stringify(logObject)}`,
      /* eslint-disable @typescript-eslint/naming-convention */
      event_id: 'webapp_log',
      licence_id: licenseId,
      /* eslint-enable @typescript-eslint/naming-convention */
    });
  } catch (error) {
    log('Failed to send log', error);

    if (retryAttempt >= MAX_RETRIES) {
      log('Max retries reached, skip logging');

      return;
    }

    log('Retrying...');

    const timeout = Math.min(1000 * 2 ** retryAttempt, 60000);

    await delay(timeout);

    await sendLog(logObject, retryAttempt + 1);
  }
}

async function logError(error: unknown, params: RequestErrorParams): Promise<void> {
  log('Logging request error', params);
  log(error);

  const { endpoint, method, timestamp, parsedError, requestBody: reqBody } = params;
  const isOnline = connectivityStatusClient.getIsOnline();
  const connectionInfo = {
    ['connection.onLine']: navigator.onLine,
    ['connection.type']: navigator.connection?.effectiveType,
    ['connection.downlink']: navigator.connection?.downlink,
    ['connection.rtt']: navigator.connection?.rtt,
    ['connection.saveData']: navigator.connection?.saveData,
    ['client.transport']: getServer().getTransports(),
    ['client.isCompressed']: getServer().websocketCompressionEnabled,
    ['store.isOnline']: isOnline,
  };
  const status = isHTTPError(error) ? (error.response.status as HTTPStatus) : null;

  if (shouldSkipForRequest(endpoint, status)) {
    return;
  }

  const { message: errorMessage, name: errorName } = isError(error)
    ? error
    : { message: 'No error message', name: 'UnknownError' };

  const { type: apiErrorType, message: apiErrorMessage } = isApiErrorResponse(parsedError)
    ? parsedError.error
    : { message: JSON.stringify(parsedError), type: 'UnknownParsedError' };

  const requestBody = getRequestBodyToBeLogged(reqBody, status);

  const logObject = {
    errorMessage,
    errorName,
    apiErrorType,
    apiErrorMessage,
    endpoint,
    method,
    timestamp,
    status,
    requestBody,
    ...connectionInfo,
  };

  if (shouldSkipForEnvironment()) {
    log('Skipping logging due to environment limitations', logObject);

    return;
  }

  await sendLog(logObject);
}

export function logRequestError(error: unknown, params: RequestErrorParams): void {
  void logError(error, params);
}
