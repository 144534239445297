import type { FC } from 'react';

import { Button, Text } from '@livechat/design-system-react-components';

import { getConfig } from 'helpers/config';
import { useAcceptRecurrentCharge } from 'hooks/api/billing/use-accept-recurrent-charge';
import { useOneClickPaymentContext } from 'routes/apps/one-click-payment/hooks/use-one-click-payment-context';

import * as styles from './styles';

const { livechatPageUrl } = getConfig();

export const AcceptRecurrentChargeFooter: FC = () => {
  const { closeModal, charge, item, isProcessable, isUpdating } = useOneClickPaymentContext();
  const { acceptRecurrentCharge, isProcessing } = useAcceptRecurrentCharge();

  if (!charge) {
    return null;
  }

  const acceptCharge = (): void => {
    acceptRecurrentCharge({ chargeId: charge.id, itemId: item?.id });
  };

  return (
    <div className={styles.purchaseWrapper}>
      <Text size="xs" as="div">
        By continuing you agree to <strong>Text</strong>{' '}
        <Button
          kind="link-light"
          size="xcompact"
          href={`${livechatPageUrl}/legal/terms/`}
          target="_blank"
          rel="noopener noreferrer"
        >
          terms
        </Button>
      </Text>

      <div className={styles.buttonsWrapper}>
        <Button kind="secondary" disabled={isProcessing} onClick={closeModal}>
          Cancel
        </Button>
        <Button
          kind="primary"
          disabled={!isProcessable || isUpdating}
          loading={isProcessing}
          loaderLabel="Processing"
          onClick={acceptCharge}
        >
          Confirm & Install
        </Button>
      </div>
    </div>
  );
};
