import { forwardRef } from 'react';

import { Alert } from '@livechat/design-system-react-components';
import { useSelector } from 'react-redux';

import { isGhostLogin } from 'store/features/session/selectors';

interface ISafeIframeProps extends React.IframeHTMLAttributes<HTMLIFrameElement> {
  allow?: string;
}

export const SafeIframe = forwardRef<HTMLIFrameElement, ISafeIframeProps>(({ ...restProps }, ref) => {
  const isGhost = useSelector(isGhostLogin);

  if (isGhost) {
    return <Alert kind="warning">Iframe blocked due to security reasons</Alert>;
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <iframe ref={ref} {...restProps} />;
});
