import type { FC } from 'react';

import { Alert, Heading, Text } from '@livechat/design-system-react-components';

import * as styles from './styles';

export const ActivationProductAlert: FC = () => {
  return (
    <Alert className={styles.alert} kind="info">
      <Heading size="xs">This app requires activation</Heading>
      <Text>
        The app is now installed on your license. To finish the installation process, please activate this app.
      </Text>
    </Alert>
  );
};
