import type { FC } from 'react';

import { type OneActionButtonTypes, type OneOnboardingActionButtons } from 'store/entities/one/interfaces';

import { type IRequiredActionButtonProps } from './interfaces';
import { OnboardingActionButton } from './OnboardingActionButton';

type PossibleProps = IRequiredActionButtonProps & Omit<OneOnboardingActionButtons, 'type'>;

export const ActionButtonsMap: Record<OneActionButtonTypes, FC<PossibleProps>> = {
  'onboarding-button': OnboardingActionButton,
};
