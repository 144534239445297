import { type FC } from 'react';

import { OpenInNewFilled } from '@livechat/design-system-icons';
import { Button, Icon, Text } from '@livechat/design-system-react-components';

import { OneTrackEvent } from 'constants/one-event';
import { EventPlace } from 'helpers/analytics';
import { openInNewCard } from 'helpers/routing';
import { trackEvent } from 'services/event-tracking';

import { ONE_HELP_CENTER_LINK } from './constants';
import { OneAccordion } from './OneAccordion';

import { aboutOneText } from './styles';

const handleLearnMore = (): void => {
  openInNewCard(ONE_HELP_CENTER_LINK);
  trackEvent(OneTrackEvent.LearnMoreClicked, EventPlace.One);
};

export const AboutOneAccordion: FC = () => (
  <OneAccordion title="About One" openOnInit>
    <Text className={aboutOneText}>Your source of information on your own business and LiveChat.</Text>

    <Button icon={<Icon source={OpenInNewFilled} />} iconPosition="right" kind="link-light" onClick={handleLearnMore}>
      <strong>Learn more</strong>
    </Button>
  </OneAccordion>
);
