// @ts-strict-ignore
/* eslint-disable @typescript-eslint/no-explicit-any */
import debug from 'debug';
import jstz from 'jstz';

import { DebugLogsNamespace } from 'constants/debug-logs-namespace';
import { getConfig, updateDatacenter } from 'helpers/config';
import { JSONParse } from 'helpers/json';
import { stringifyQueryParams, type IKeyValue, parseQueryParams } from 'helpers/url';
import type { RequestResult } from 'interfaces/api/client';
import { getAccessToken } from 'services/auth/auth-storage-manager';
import { type IRequestEvent, addRequestEvent } from 'services/request-events-history-manager';

import type { UploadEvents, UploadFileResult } from './types';

const log = debug(DebugLogsNamespace.AppApiClient);
const config = getConfig();

const enum HttpMethod {
  Get = 'GET',
  Put = 'PUT',
  Patch = 'PATCH',
  Post = 'POST',
  Delete = 'DELETE',
}

interface IRequestOptions {
  path: string;
  method: HttpMethod;
  body?: BodyInit;
  headers?: IKeyValue;
  params?: IKeyValue;
}

interface IApiClientOptions {
  passTimezone?: boolean;
}

export default class ApiClient implements ApiClient {
  private configBaseName: string;
  private options: IApiClientOptions = {};

  constructor(configBaseName: string, options: IApiClientOptions = {}) {
    this.configBaseName = configBaseName;
    this.options.passTimezone = options.passTimezone ?? true;
  }

  private isUploadFileResult = (response: any): response is UploadFileResult => {
    return 'url' in response || 'path' in response;
  };

  private getBaseRootPath(): string {
    return `${config[this.configBaseName]}/`;
  }

  private get accessToken(): string {
    return getAccessToken();
  }

  private get authorizationHeaders() {
    return this.accessToken ? { Authorization: `Bearer ${this.accessToken}` } : {};
  }

  private getCombinedHeaders(isFormData: boolean) {
    if (isFormData) {
      return { ...this.authorizationHeaders };
    }

    return {
      ...this.authorizationHeaders,
      'Content-Type': 'application/json',
    };
  }

  private parseResponse(response: Response, parsedResponse: unknown): RequestResult<unknown, unknown> {
    const result = {
      result: null,
      error: null,
    };

    if (!response.ok) {
      result.error = parsedResponse;
    } else {
      result.result = parsedResponse;
    }

    return result;
  }

  private async request<TResult>({
    path,
    params,
    body,
    headers,
    method,
  }: IRequestOptions): Promise<RequestResult<TResult>> {
    const [pathWithBaseRoot, pathQuery] = (path.startsWith('http') ? path : `${this.getBaseRootPath()}${path}`).split(
      '?'
    );

    const parsedParams = { ...(pathQuery && parseQueryParams(pathQuery)), ...(params || {}) };

    if (this.options.passTimezone) {
      parsedParams.timezone = jstz.determine().name();
    }

    const pathWithQueryParams = `${pathWithBaseRoot}?${stringifyQueryParams(parsedParams)}`;

    const requestResult = {
      result: null,
      error: null,
    };

    const requestEvent: IRequestEvent = {
      name: '',
      request: null,
      response: {
        result: null,
        error: null,
      },
    };

    try {
      const isFormData = body instanceof FormData;

      const fetchOptions = {
        headers: {
          ...this.getCombinedHeaders(isFormData),
          ...headers,
        },
        method,
        body: isFormData ? body : JSON.stringify(body),
      };

      log(`===>>> ${method} ${pathWithQueryParams}`);
      const response = await fetch(pathWithQueryParams, fetchOptions);

      const isImageResponse = response.headers.get('content-type').includes('image/jpeg');
      const isPdfResponse = response.headers.get('content-type').includes('application/pdf');
      const isBlobResponse = isImageResponse || isPdfResponse;
      const parsedResponse = isBlobResponse ? URL.createObjectURL(await response.blob()) : await response.json();

      const { result, error } = this.parseResponse(response, parsedResponse);
      requestResult.result = result;
      requestResult.error = error;

      requestEvent.name = `${fetchOptions.method} ${path}`;
      requestEvent.request = { finalPath: pathWithQueryParams, ...fetchOptions };
      log(`<<<=== ${method} ${pathWithQueryParams} (${response.status}): ${response.statusText}`);
    } catch (e) {
      log(`<<<=== ${method} ${pathWithQueryParams} (5XX): ${e.message}`);
      requestResult.error = e;
    }

    // Set data center region for correct
    if (requestResult.error && requestResult.error.region) {
      updateDatacenter(config, requestResult.error.region);

      const options = {
        path,
        method,
        headers,
        body,
        params,
      };

      return this.request<TResult>(options);
    }

    requestEvent.response = requestResult;
    addRequestEvent(requestEvent);

    return requestResult;
  }

  private async uploadRequest<TResult>(options: IRequestOptions, opts?: UploadEvents): Promise<RequestResult<TResult>> {
    const UPLOAD_ERROR_MESSAGE = "Couldn't upload file";
    let fullPath = options.path.startsWith('http') ? options.path : `${this.getBaseRootPath()}${options.path}`;

    const timezone = jstz.determine().name();
    fullPath += `?${stringifyQueryParams({ timezone })}`;

    const requestResult = {
      result: null,
      error: null,
    };

    try {
      const requestOptions = {
        headers: {
          ...this.getCombinedHeaders(true),
          ...options.headers,
        },
        method: options.method,
        body: options.body,
      };

      log(`===>>> ${options.method} ${fullPath}`);

      const response = await new Promise((resolve, reject) => {
        const xml = new XMLHttpRequest();
        xml.open(HttpMethod.Post, fullPath, true);
        Object.keys(requestOptions.headers).forEach((headerKey) => {
          xml.setRequestHeader(headerKey, requestOptions.headers[headerKey]);
        });

        if (opts?.onProgress) {
          xml.upload.addEventListener('progress', opts.onProgress, false);
        }

        xml.onreadystatechange = () => {
          /**
           * `readyState = 4` means that `send` operation is complete.
           */
          if (xml.readyState === 4 && xml.status === 200) {
            const attachmentUrl = JSONParse<{ url?: string; path?: string }>(xml.responseText);

            if (attachmentUrl === null) {
              return reject(new Error(UPLOAD_ERROR_MESSAGE));
            }

            return resolve({ status: xml.status, url: attachmentUrl.url, path: attachmentUrl.path });
          }

          if (xml.readyState === 4 && xml.status !== 200) {
            if (opts?.onError) {
              opts.onError();
            }

            return reject(new Error(xml.statusText));
          }
        };

        xml.upload.addEventListener(
          'error',
          () => {
            if (opts?.onError) {
              opts.onError();
            }

            return reject(new Error(`Error (${xml.status}): ${xml.statusText}`));
          },
          false
        );

        xml.send(options.body as any);
      });

      if (response instanceof Error) {
        requestResult.error = response;
      } else {
        if (opts?.onSuccess && this.isUploadFileResult(response)) {
          const url = 'path' in response ? response.path : response.url;
          opts.onSuccess(url);
        }

        requestResult.result = response;
      }

      log(`<<<=== ${options.method} ${fullPath} (${requestResult.result.status})`);
    } catch (e) {
      log(`<<<=== ${options.method} ${fullPath} (5XX): ${e.message}`);
      requestResult.error = e;
    }

    // Set data center region for correct
    if (requestResult.error && requestResult.error.region) {
      updateDatacenter(config, requestResult.error.region);

      return this.uploadRequest<TResult>(options);
    }

    return requestResult;
  }

  async get<TResult>(path: string, params?: IKeyValue, headers?: IKeyValue): Promise<RequestResult<TResult>> {
    return this.request<TResult>({ path, params, headers, method: HttpMethod.Get });
  }

  async post<TResult>(path: string, body: BodyInit, headers?: IKeyValue): Promise<RequestResult<TResult>> {
    return this.request<TResult>({
      path,
      body,
      headers,
      method: HttpMethod.Post,
    });
  }

  async put<TResult>(path: string, body: BodyInit, headers?: IKeyValue): Promise<RequestResult<TResult>> {
    return this.request<TResult>({ path, body, headers, method: HttpMethod.Put });
  }

  async patch<TResult>(path: string, body: BodyInit, headers?: IKeyValue): Promise<RequestResult<TResult>> {
    return this.request<TResult>({ path, body, headers, method: HttpMethod.Patch });
  }

  async delete<TResult>(path: string, body: BodyInit, headers?: IKeyValue): Promise<RequestResult<TResult>> {
    return this.request<TResult>({ path, body, headers, method: HttpMethod.Delete });
  }

  async upload<TResult>(
    path: string,
    body: BodyInit,
    headers?: IKeyValue,
    events?: UploadEvents
  ): Promise<RequestResult<TResult>> {
    return this.uploadRequest<TResult>({ path, body, headers, method: HttpMethod.Post }, events);
  }
}
