import { css } from '@emotion/css';
import { DesignToken, RadiusToken, SpacingToken } from '@livechat/design-system-react-components';

import { mobileDeviceMediaQuery } from 'helpers/media-queries';
import { VERTICAL_VIEW_BREAKPOINT } from 'routes/subscription/constants';

export const wrapper = css`
  align-items: flex-start;
  display: flex;
  height: 100%;
`;

export const contentWrapper = css`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  border-radius: var(${SpacingToken.Spacing4});
  background-color: var(${DesignToken.SurfacePrimaryDefault});
`;

export const headerWrapper = css`
  padding: var(${SpacingToken.Spacing4}) var(${SpacingToken.Spacing5});
  border-bottom: 1px solid var(${DesignToken.BorderBasicSecondary});
`;

export const header = css`
  width: 110px;
  height: var(${SpacingToken.Spacing6});
  margin: 0;
`;

export const content = css`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  background-color: var(${DesignToken.SurfacePrimaryDefault});

  @media (max-width: ${VERTICAL_VIEW_BREAKPOINT}px) {
    box-sizing: border-box;
  }
`;

export const tabSkeletonWrapper = (isExpired: boolean) => css`
  display: flex;
  gap: 10px;
  padding: 20px;
  ${isExpired && 'margin-top: 52px;'};
  background-color: var(${DesignToken.SurfacePrimaryDefault});
`;

export const tabSkeleton = css`
  width: 130px;
  height: 14px;
`;

export const plansSkeletonWrapper = css`
  display: flex;
  margin-top: var(${SpacingToken.Spacing4});
  gap: var(${SpacingToken.Spacing2});
  padding: 0 var(${SpacingToken.Spacing4});

  @media (max-width: ${VERTICAL_VIEW_BREAKPOINT}px) {
    flex-direction: column;

    div {
      width: 100%;
      height: 280px;
    }
  }

  ${mobileDeviceMediaQuery} {
    flex-direction: column;

    div {
      width: 100%;
      height: 280px;
    }
  }
`;

export const planSkeleton = css`
  width: 25%;
  height: 540px;
`;

export const planTitleSkeleton = css`
  div:first-child {
    margin-bottom: var(${SpacingToken.Spacing5});
    margin-top: var(${SpacingToken.Spacing12});
    height: 28px;
    width: 150px;
  }

  div {
    width: 130px;
    height: 16px;
    margin-bottom: var(${SpacingToken.Spacing2});
  }

  padding: 0 var(${SpacingToken.Spacing4});
`;

export const overViewSkeletonWrapper = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1080px;

  div:first-child {
    padding: 0 var(${SpacingToken.Spacing6});
  }
`;

export const selectedPlanSkeletonWrapper = css`
  width: 100%;
  flex-shrink: 1;
  flex-direction: column;
  max-width: 960px;
`;

export const overviewSkeletonHeader = css`
  width: 300px;
  height: 40px;
  margin: var(${SpacingToken.Spacing8}) 0 0;
`;

export const overviewSkeletonContent = css`
  width: 100%;
  height: 460px;
  margin-top: var(${SpacingToken.Spacing9});
`;

export const bannerContainer = css`
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 1040px;
  margin-top: var(${SpacingToken.Spacing12});
`;

export const expiredBannerSkeleton = css`
  width: 100%;
  height: 62px;
`;

export const accountDetailsSkeleton = css`
  max-width: 700px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(${SpacingToken.Spacing5});
  margin-top: var(${SpacingToken.Spacing8});
`;

export const cardSkeleton = css`
  width: 340px;
  border-radius: var(${RadiusToken.Radius4});
  height: 220px;
`;
