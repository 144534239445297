import { LicensePropertiesActionNames, type LicensePropertiesAction } from './actions';
import { type LicensePropertiesState } from './interfaces';

const initialState: LicensePropertiesState = {};

export function licensePropertiesReducer(
  state: LicensePropertiesState = initialState,
  action: LicensePropertiesAction
): LicensePropertiesState {
  switch (action.type) {
    case LicensePropertiesActionNames.SET_DATA:
      return {
        ...action.payload,
      };

    case LicensePropertiesActionNames.UPDATE_DATA:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}
