import { type FC } from 'react';

import { Button } from '@livechat/design-system-react-components';
import { format } from 'date-fns';

import { DateFormat } from 'constants/date';
import { type IResourceTransaction } from 'store/entities/applications/interfaces';

import { useDeactivateTransaction } from '../../hooks/use-deactivate-transaction';
import { useResourceContext } from '../../hooks/use-resource-context';

import { getTransactionSummary, isRecurrentCharge, getChargeDetails } from './helpers';

import * as styles from './styles';

interface IProps {
  transaction: IResourceTransaction;
  isLastTransaction: boolean;
  closeModal: () => void;
}

export const TransactionDetails: FC<IProps> = ({ transaction, isLastTransaction, closeModal }) => {
  const { deactivateTransaction, isProcessing } = useDeactivateTransaction();

  const { appId, resourceId, upgradeId, name } = useResourceContext();

  const handleDeactivate = (): void => {
    deactivateTransaction(
      {
        upgradeId,
        resourceId,
        applicationId: appId,
        transactionId: transaction.id,
      },
      {
        onSuccess: () => {
          if (isLastTransaction) {
            closeModal();
          }
        },
      }
    );
  };

  const { centsPerUnit, perAgent, agents, id } = transaction.payment;
  const { priceComponents, totalPrice } = getTransactionSummary({
    perAgent,
    price: centsPerUnit,
    agentsCount: agents,
    quantity: transaction.quantity,
  });
  const chargeDetails = getChargeDetails(id, perAgent);
  const usedSinceDate = format(transaction.switch.enabled as string, DateFormat.UsedSinceDate);
  const isSubscription = isRecurrentCharge(id);

  return (
    <div className={styles.resource}>
      <div className={styles.name}>
        <p>{name}</p>
        <span>
          {priceComponents} {chargeDetails}
        </span>
      </div>

      <div className={styles.price}>
        <p>{!centsPerUnit ? 'Free' : `Total ${totalPrice}`}</p>
        <span>Using since {usedSinceDate} </span>
      </div>

      <div className={styles.action}>
        <Button kind="plain" loading={isProcessing} onClick={handleDeactivate}>
          {isSubscription ? 'Cancel' : 'Deactivate'}
        </Button>
      </div>
    </div>
  );
};
