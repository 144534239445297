import { type FC, useContext } from 'react';

import {
  SettingsResourceModalContextProvider,
  useSettingsResourceModalContext,
} from 'components/apps/hooks/use-settings-resource-modal-context';
import {
  WidgetResourceModalContext,
  WidgetResourceModalContextProvider,
} from 'components/apps/hooks/use-widget-resource-modal-context';
import { WidgetModalContext } from 'hooks/fullscreen-widgets/use-widget-modal-context';

import { type ResourceModalContext, type ResourceModalContextProviderProps } from './interfaces';

export const ResourceModalContextProvider: FC<ResourceModalContextProviderProps> = ({ isFree, isActive, children }) => {
  const widgetModalContext = useContext(WidgetModalContext);

  const ProviderComponent = widgetModalContext
    ? WidgetResourceModalContextProvider
    : SettingsResourceModalContextProvider;

  return (
    <ProviderComponent isFree={isFree} isActive={isActive}>
      {children}
    </ProviderComponent>
  );
};

export function useResourceModalContext(): ResourceModalContext {
  const widgetModalContext = useContext(WidgetModalContext);
  const widgetResourceModalContext = useContext(WidgetResourceModalContext);
  const settingsResourceModalContext = useSettingsResourceModalContext();

  const context =
    widgetModalContext && widgetResourceModalContext ? widgetResourceModalContext : settingsResourceModalContext;

  return context;
}
