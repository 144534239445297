import { UserType } from '../types';
import {
  type Event,
  EventType,
  type WithAuthorEvent,
  type FileEvent,
  type FormEvent,
  type FilledFormEvent,
  type MessageEvent,
  type RichMessageEvent,
  type SystemMessageEvent,
  type WithTextEvent,
} from '../types/event';
import { type AgentUser, type CustomerUser, type User } from '../types/user';

import { type ListChatsFilterOptions, type ListChatsPaginationOption, type ListChatsRequest } from './types/list-chats';

export function isCustomerChatUser(user: User): user is CustomerUser {
  return user.type === UserType.CUSTOMER;
}

export function isAgentChatUser(user: User): user is AgentUser {
  return user.type === UserType.AGENT;
}

export function isWithAuthorEvent(event: Event): event is WithAuthorEvent {
  return (
    'author_id' in event &&
    [
      EventType.CUSTOM,
      EventType.FILE,
      EventType.FILLED_FORM,
      EventType.FORM,
      EventType.MESSAGE,
      EventType.RICH_MESSAGE,
    ].includes(event?.type)
  );
}

export function isWithTextEvent(event: Event): event is WithTextEvent {
  return 'text' in event && [EventType.MESSAGE, EventType.SYSTEM_MESSAGE].includes(event?.type);
}

export function isFileEvent(event: Event): event is FileEvent {
  return event.type === EventType.FILE;
}

export function isFormEvent(event: Event): event is FormEvent {
  return event.type === EventType.FORM;
}

export function isFilledFormEvent(event: Event): event is FilledFormEvent {
  return event.type === EventType.FILLED_FORM;
}

export function isMessageEvent(event: Event): event is MessageEvent {
  return event.type === EventType.MESSAGE;
}

export function isRichMessageEvent(event: Event): event is RichMessageEvent {
  return event.type === EventType.RICH_MESSAGE;
}

export function isSystemMessageEvent(event: Event): event is SystemMessageEvent {
  return event.type === EventType.SYSTEM_MESSAGE;
}

export function isListChatsFilterOptions(request: ListChatsRequest): request is ListChatsFilterOptions {
  return 'filters' in request || 'sort_order' in request || 'limit' in request;
}

export function isListChatsPaginationOption(request: ListChatsRequest): request is ListChatsPaginationOption {
  return 'page_id' in request;
}
