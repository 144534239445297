import type { FC } from 'react';

import { matchRoutes, useLocation } from 'react-router-dom';

import { FullscreenWidgets } from './FullscreenWidgets';

const FullscreenWidgetsRouter: FC = () => {
  const location = useLocation();
  const matchedPath = matchRoutes([{ path: 'app/:slugOrId' }], location);
  const match = matchedPath ? matchedPath[0].params.slugOrId : null;

  return <FullscreenWidgets visibleWidgetSlugOrId={match ?? null} />;
};

export default FullscreenWidgetsRouter;
