import { css } from '@emotion/css';

export const container = css`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: center;
  white-space: nowrap;
  display: flex;
  line-height: 1.3;
  vertical-align: middle;
`;

export const avatar = css`
  margin-right: 6px;
`;

export const details = css`
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - 30px);
  overflow: hidden;

  span {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const agentNameClass = css`
  font-size: 14px;
`;

export const agentEmailClass = css`
  font-size: 13px;
  opacity: 0.5;
`;

export const item = css`
  display: inline-block;
  font-size: 13px;
  line-height: 1.3;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  vertical-align: middle;
`;

export const exceededItem = css`
  ${item}
  color: #a0a6ab;
`;
