import { useEffect, useCallback, useState } from 'react';

import { PostMessageEvent } from 'constants/post-message-event';
import { getConfig } from 'helpers/config';
import { JSONParse } from 'helpers/json';
import { sendPostMessageToMarketplace } from 'helpers/post-message';
import { getMarketplaceDomain } from 'helpers/url';
import type { PostMessage } from 'interfaces/post-message';
import { PaymentStatus } from 'services/api/billing/interfaces';

import type { Charge, TransactionItem } from '../interfaces';

type Props = {
  onModalOpen: (canUserBuy: boolean) => void;
  onModalClose: () => void;
};

type UseOneClickPaymentPostMessageEvents = {
  isLoading: boolean;
  isUpdating: boolean;
  isProcessable: boolean;
  error: string | null;
  item: TransactionItem | null;
  charge: Charge | null;
  updateBillingCycle: (billingCycle: number) => void;
  closeModal: () => void;
};

const marketplaceDomain = getMarketplaceDomain(getConfig().devMarketplace);

export const useOneClickPaymentPostMessageEvents = ({
  onModalOpen,
  onModalClose,
}: Props): UseOneClickPaymentPostMessageEvents => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const [item, setItem] = useState<TransactionItem | null>(null);
  const [charge, setCharge] = useState<Charge | null>(null);

  const closeModal = useCallback(() => {
    setItem(null);
    setCharge(null);
    setError(null);
    setIsLoading(false);

    sendPostMessageToMarketplace(PostMessageEvent.ClosePaymentModal);
    onModalClose();
  }, [onModalClose]);

  const updateBillingCycle = useCallback(
    (billingCycle: number) => {
      setIsLoading(true);

      if (item) {
        sendPostMessageToMarketplace(PostMessageEvent.UpdateBillingCycle, { item, billingCycle });
      }
    },
    [item]
  );

  const receivePostMessage = useCallback(
    (event: MessageEvent<string>): void => {
      if (event.origin !== marketplaceDomain) {
        return;
      }

      const data = JSONParse<PostMessage>(event?.data);

      if (!data) {
        return;
      }

      switch (data.event_name) {
        case PostMessageEvent.OpenPaymentModal: {
          const { item, canUserBuy } = data.event_data;

          setError(null);
          setIsLoading(true);
          setItem(item);
          onModalOpen(canUserBuy);
          break;
        }

        case PostMessageEvent.OpenPaymentModalReady: {
          const { charge } = data.event_data;

          setError(null);
          setCharge(charge);
          setIsLoading(false);
          break;
        }

        case PostMessageEvent.OpenPaymentModalFailure: {
          const { error } = data.event_data;

          setError(error);
          setIsLoading(false);
          break;
        }
      }
    },
    [onModalOpen]
  );

  useEffect(() => {
    window.addEventListener('message', receivePostMessage);

    return () => window.removeEventListener('message', receivePostMessage);
  }, [receivePostMessage]);

  return {
    isLoading: isLoading && !charge,
    isUpdating: isLoading && !!charge,
    isProcessable: charge?.status === PaymentStatus.Pending,
    error,
    item,
    charge,
    closeModal,
    updateBillingCycle,
  };
};
