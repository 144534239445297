import { css } from '@emotion/css';
import { DesignToken, RadiusToken, SpacingToken } from '@livechat/design-system-react-components';

import { resetLegacyLcBorderBox } from 'styles/reset-border-box';

import { BOTTOM_BAR_HEIGHT, ONE_MESSAGE_BOX_HEIGHT } from '../constants';

const MESSAGE_BOX_WIDTH = '800px';

export const contentEditable = css`
  height: calc(100% - ${BOTTOM_BAR_HEIGHT}px);
  overflow: auto;
  line-height: 21px;
  font-size: 14px;
  outline: none;
  color: var(${DesignToken.ContentBasicPrimary});
  max-width: ${MESSAGE_BOX_WIDTH};

  &:focus-visible {
    border-color: var(${DesignToken.BorderDefault});
  }
`;

export const popoverContentEditable = css`
  min-height: 21px;
  width: 270px;
  margin-right: var(${SpacingToken.Spacing1});
  white-space: nowrap !important;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &&::-webkit-scrollbar {
    display: none;
  }

  @-moz-document url-prefix() {
    white-space: pre-wrap !important;
  }
`;

export const messageBoxWrapper = (isExpanded = false): string => css`
  position: relative;
  height: ${ONE_MESSAGE_BOX_HEIGHT}px;
  max-height: 152px;
  padding: var(${SpacingToken.Spacing3}) var(${SpacingToken.Spacing4});
  border-radius: 12px;
  border: 1px solid var(${DesignToken.BorderBasicPrimary});
  line-height: 21px;
  font-size: 16px;
  outline: none;
  width: ${isExpanded ? '100%' : 'auto'};
  max-width: ${MESSAGE_BOX_WIDTH};
  margin-bottom: ${isExpanded ? 'var(${SpacingToken.Spacing3})' : 0};

  &:focus-visible {
    border-color: var(${DesignToken.BorderDefault});
  }
`;

export const popoverMessageBoxWrapper = css`
  height: 32px;
  padding: var(${SpacingToken.Spacing4});
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: auto;
`;

export const placeholder = css`
  color: var(${DesignToken.ContentBasicDisabled});
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  top: 12px;
  left: 18px;
  font-size: 14px;
  line-height: 21px;
  user-select: none;
  display: inline-block;
`;

export const popoverPlaceholder = css`
  top: auto;
  left: auto;
  pointer-events: none;
  position: absolute;
  padding: 0;
`;

export const bottomBar = css`
  ${resetLegacyLcBorderBox};
  height: 32px;
  margin-top: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const rightNodes = css`
  display: flex;
  align-items: center;
`;

export const sendButton = css`
  display: block;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const sendButtonTooltip = css`
  width: 196px;
`;

export const stopGeneratingTooltip = css`
  width: 136px;
`;

export const cancelGenerateButton = css`
  margin-right: 6px;
`;

export const cancelGenerateButtonPopover = css`
  margin-right: 6px;
  height: 32px;
  min-width: 32px;
  padding: 6px;
`;

export const characterLimit = css`
  margin: 0 var(${SpacingToken.Spacing3});
`;

export const aboveLimitTooltip = css`
  width: 220px;
  text-align: center;
`;

export const messageBoxTip = css`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 24px;
  gap: var(${SpacingToken.Spacing2});
  color: var(${DesignToken.ContentBasicDisabled});

  strong {
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(${DesignToken.ContentBasicPrimary});
    border-radius: var(${RadiusToken[3]});
    background-color: var(${DesignToken.SurfaceSecondaryDefault});
  }
`;
