import { type FC } from 'react';

import { useSelector } from 'react-redux';

import { getOneIsLoading } from 'store/views/one/selectors';

import { StopGenerateButton } from '../hooks/StopGenerateButton';

import { AskButton } from './AskButton';
import { CharacterLimit } from './CharacterLimit';
import { MessageBoxTip } from './MessageBoxTip';

import * as styles from './styles';

interface IMessageBoxBottomBarProps {
  isExpanded: boolean;
}

export const OneMessageBoxBottomBar: FC<IMessageBoxBottomBarProps> = ({ isExpanded }) => {
  const isOneLoading = useSelector(getOneIsLoading);
  if (!isExpanded) {
    return (
      <>
        {!isOneLoading && <AskButton />}
        <StopGenerateButton />
      </>
    );
  }

  return (
    <div className={styles.bottomBar}>
      <div>
        <MessageBoxTip />
      </div>
      <div className={styles.rightNodes}>
        <StopGenerateButton />
        <CharacterLimit />
        <AskButton />
      </div>
    </div>
  );
};
