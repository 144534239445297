import { delay } from 'helpers/delay';
import { mergeGroupProperties } from 'helpers/group';
import { ApiManager } from 'services/api/api-manager';
import { type GroupDTO } from 'services/api/group/interfaces';
import { deserializeGroups } from 'services/api/group/serializer';
import { AppStateProvider } from 'services/app-state-provider';
import { groupsClient } from 'services/connectivity/configuration-api/groups/client';
import { type ListGroupsRequest } from 'services/connectivity/configuration-api/groups/types';
import { GroupActions } from 'store/entities/groups/actions';

/**
 * We need to fetch groups data after connection to the server
 * has been established, because requesting routing status
 * on expired licenses results in error.
 * @param shouldFetchRoutingStatus If we should fetch routing status
 */
async function fetchGroupData(shouldFetchRoutingStatus = false): Promise<void> {
  const fields: ListGroupsRequest['fields'] = shouldFetchRoutingStatus
    ? ['routing_status', 'agent_priorities']
    : ['agent_priorities'];

  const responses = await Promise.all([
    groupsClient.list({ fields }),
    ApiManager.groupApi.fetchGroupsProperties({
      prefix: 'add_tag_reminder',
    }),
  ]);

  const firstResponseWithError = responses.find((v) => v.error);

  if (firstResponseWithError) {
    throw new Error(JSON.stringify(firstResponseWithError.error));
  }

  const [{ result: groups }, { result: groupsProperties }] = responses;

  // Temporary cast as groups response does not contain properties
  const mergedGroups = mergeGroupProperties(groups as GroupDTO[], groupsProperties);

  AppStateProvider.dispatch(GroupActions.groupsReset(deserializeGroups(mergedGroups)));
}

export async function fetchGroupDataWithRetry(shouldFetchRoutingStatus = false, retryAttempt = 1): Promise<void> {
  let fetchRoutingStatus = shouldFetchRoutingStatus;
  try {
    await fetchGroupData(fetchRoutingStatus);
  } catch (e: any) {
    const error: Error = e;
    const isLicenseExpired = error.message.includes('expired');
    const timeout = Math.min(1000 * 2 ** retryAttempt, 60000);

    if (isLicenseExpired) {
      fetchRoutingStatus = false;
    }

    await delay(timeout);

    return fetchGroupDataWithRetry(fetchRoutingStatus, retryAttempt + 1);
  }
}
