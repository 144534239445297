import { useState, type FC, useContext } from 'react';

import { Button, Tooltip, Text } from '@livechat/design-system-react-components';

import { WidgetModalContext } from 'hooks/fullscreen-widgets/use-widget-modal-context';
import { useParamSelector } from 'hooks/use-param-selector';
import { getPrivateApplication } from 'store/entities/applications/selectors';

import { useResourceContext } from '../../hooks/use-resource-context';

import * as styles from './styles';

interface IProps {
  purchaseTransaction(): void;
  isLoading: boolean;
  closeModal: () => void;
}

export const PurchaseModalFooter: FC<IProps> = ({ purchaseTransaction, isLoading, closeModal }) => {
  const widgetContext = useContext(WidgetModalContext);
  const { appId, price, isActive, multiplied } = useResourceContext();

  const privateApp = useParamSelector(getPrivateApplication, appId);

  // Private resources cannot be purchased by developers because they would be charging themselves
  const isPrivateAndPaid = privateApp && !!price;
  // Some resources are singular and cannot be bought multiple times
  const isAlreadyPurchased = isActive && !multiplied;
  const cannotPurchase = isPrivateAndPaid || isAlreadyPurchased;

  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const showTooltip = (): void => {
    if (cannotPurchase) {
      setIsTooltipVisible(true);
    }
  };
  const hideTooltip = (): void => setIsTooltipVisible(false);

  const tooltipCopy = isAlreadyPurchased
    ? 'This upgrade is already active'
    : 'To test your upgrade, please make it free.';

  return (
    <div className={styles.footer}>
      <Button onClick={closeModal} kind="secondary">
        {widgetContext ? 'Back' : 'Close'}
      </Button>

      <Tooltip
        isVisible={isTooltipVisible}
        triggerRenderer={() => (
          <div onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
            <Button disabled={cannotPurchase} loading={isLoading} kind="primary" onClick={purchaseTransaction}>
              Confirm purchase
            </Button>
          </div>
        )}
      >
        <Text>{tooltipCopy}</Text>
      </Tooltip>
    </div>
  );
};
