import { type FC, type ReactNode, createContext, useContext, useMemo } from 'react';

import { useSelector } from 'react-redux';

import { getActiveAgentsCount } from 'store/entities/agents/selectors';
import { type IInAppUpgrade } from 'store/entities/applications/interfaces';

import { getResourcePriceSummary, type GetResourcePriceSummary } from '../helpers';

interface IResourceContextProviderProps {
  resource: IInAppUpgrade;
  children: ReactNode;
}

interface IResourceContext extends IInAppUpgrade, GetResourcePriceSummary {}

const ResourceContext = createContext<IResourceContext>({} as IResourceContext);

export const ResourceContextProvider: FC<IResourceContextProviderProps> = ({ resource, children }) => {
  const agentsCount = useSelector(getActiveAgentsCount);
  const { price, multiplied } = resource;

  const resourcePriceSummary = useMemo(
    () =>
      getResourcePriceSummary({
        price,
        multiplied,
        agentsCount,
      }),
    [price, multiplied, agentsCount]
  );

  const value: IResourceContext = {
    ...resource,
    ...resourcePriceSummary,
  };

  return <ResourceContext.Provider value={value}>{children}</ResourceContext.Provider>;
};

export function useResourceContext(): IResourceContext {
  const context = useContext(ResourceContext);

  if (context === undefined) {
    throw new Error('useResourceContext must be used within a ResourceContextProvider');
  }

  return context;
}
