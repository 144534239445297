import { type FC, type MouseEvent, type ReactNode } from 'react';

import { cx } from '@emotion/css';

import {
  SIDE_NAVIGATION_ACTIVE_ITEM_TEST_ID,
  SIDE_NAVIGATION_ITEM_TEST_ID,
  SIDE_NAVIGATION_PARENT_ICON_TEST_ID,
} from 'components/side-navigation/constants';
import { SideNavigationLabel } from 'components/side-navigation-v2/components/SideNavigationLabel';
import { UserGuidedTourStep } from 'components/tours/UserGuidedTourStep';
import { noop } from 'helpers/noop';
import { type ISideNavigationItem } from 'interfaces/side-navigation-item';

import * as styles from './styles';

interface IProps {
  label: ReactNode;
  rightNode?: ReactNode;
  icon?: ReactNode;
  shouldKeepIconSpace?: boolean;
  url?: string;
  className?: string;
  isActive?: boolean;
  isMainEntry?: boolean;
  onClick: () => void;
  onItemHover?: () => void;
  isIconHidden?: boolean;
  tourStep?: ISideNavigationItem['tourStep'];
}

export const SideNavigationItem: FC<IProps> = ({
  icon,
  shouldKeepIconSpace = true,
  url,
  label,
  className,
  rightNode,
  onClick,
  isActive,
  isMainEntry,
  onItemHover,
  tourStep,
  isIconHidden,
}) => {
  const isTextLabel = typeof label === 'string';

  const handleClick = (event: MouseEvent<HTMLAnchorElement>): void => {
    event.preventDefault();
    onClick?.();
  };

  const navigationItem = (
    <li
      className={cx([styles.item, className])}
      data-testid={isActive ? SIDE_NAVIGATION_ACTIVE_ITEM_TEST_ID : SIDE_NAVIGATION_ITEM_TEST_ID}
      data-active={isActive}
    >
      <a
        className={styles.anchor(isActive, isMainEntry, isIconHidden)}
        type="button"
        href={url}
        onClick={handleClick}
        onMouseEnter={onItemHover || noop}
      >
        {shouldKeepIconSpace && (
          <span className={styles.icon(isActive)} data-testid={SIDE_NAVIGATION_PARENT_ICON_TEST_ID}>
            {icon}
          </span>
        )}
        {isTextLabel ? (
          <SideNavigationLabel isActive={isActive} isMainEntry={isMainEntry}>
            {label}
          </SideNavigationLabel>
        ) : (
          label
        )}
        {rightNode}
      </a>
    </li>
  );

  return tourStep ? (
    <UserGuidedTourStep tourId={tourStep.id} step={tourStep.step}>
      {navigationItem}
    </UserGuidedTourStep>
  ) : (
    navigationItem
  );
};
