import { addDays, isBefore } from 'date-fns';

import { EARLY_ACCESS_ITEM_CONFIG } from 'components/global-modals/ai-hub/ai-hub-config';

import { AI_HUB_RELEASE_DATE, TWO_WEEKS } from './constants';

export const shouldShowNewBadgeOnItem = (dateAdded: Date, badgeExpireDate?: Date): boolean => {
  const currentDate = new Date();
  const dateToCompare = badgeExpireDate || addDays(dateAdded, TWO_WEEKS);

  return isBefore(currentDate, dateToCompare);
};

export const shouldShowNewBadge = (): boolean => {
  const currentDate = new Date();
  const dateToCompare = addDays(AI_HUB_RELEASE_DATE, 7);

  return isBefore(currentDate, dateToCompare);
};

const isAIHubNewBadgeVisible = shouldShowNewBadge(); //TODO can be deleted one week after release

const isNewBadgeOnItemVisible = EARLY_ACCESS_ITEM_CONFIG.some((item) => {
  return shouldShowNewBadgeOnItem(item.dateAdded, item.badgeExpireDate);
});

export const shouldShowAIHubNewBadge = isAIHubNewBadgeVisible || isNewBadgeOnItemVisible;
