import type { FC } from 'react';

import { useParamSelector } from 'hooks/use-param-selector';
import { BadgeKey } from 'store/entities/badges/interfaces';
import { getBadge } from 'store/entities/badges/selectors';

import { NavigationBadge } from './navigation-badge/NavigationBadge';

export const ReportsBadge: FC = () => {
  const recentInsightsCount = useParamSelector(getBadge, BadgeKey.InsightsRecentInsights);
  const topCustomerQuestionsCount = useParamSelector(getBadge, BadgeKey.InsightsTopCustomerQuestions);
  const insightsCount = recentInsightsCount + topCustomerQuestionsCount;

  return insightsCount > 0 ? <NavigationBadge count={insightsCount} /> : null;
};
