// @ts-strict-ignore
import { type SagaIterator } from 'redux-saga';
import { call, put, race, select, take, throttle } from 'redux-saga/effects';

import { App } from 'config/setup';
import { Section } from 'constants/section';
import { isDesktopClient } from 'helpers/browser';
import { ChatsEntitiesActionNames } from 'store/entities/chats/actions';
import { ChatsViewActions, ChatsViewActionsNames } from 'store/views/chats/actions';
import { getCountForNotification } from 'store/views/chats/computed';

import { TabNotificationsActionNames, TabNotificationsActions } from '../actions';

import { NOTIFICATION_COUNT_UPDATE_THROTTLE_TIME } from './constants';
import { type INotificationEvent, type NotificationChannel } from './interfaces';

function createNotificationEvent(count: number): INotificationEvent {
  return { type: 'chats', count };
}

/**
 * TODO: https://livechatinc.atlassian.net/browse/AA-12978
 */
function* recalculateNotifications(notificationChannel: NotificationChannel): SagaIterator {
  const { [Section.Chats]: countForChats, [Section.Engage]: countForTraffic } = yield select(getCountForNotification);

  const countForBrowserTabAndDesktopIcon = countForChats + countForTraffic;

  yield put(ChatsViewActions.updateNotificationsCount({ count: countForChats }));
  notificationChannel.put(createNotificationEvent(countForBrowserTabAndDesktopIcon));

  if (isDesktopClient()) {
    App.SmartClient.handleBadgeContentChange(countForBrowserTabAndDesktopIcon);
  }
}

function* listenToChangesInStore(notificationChannel: NotificationChannel): SagaIterator {
  const recalculateTriggerActions = [
    ChatsEntitiesActionNames.FETCH_CHAT_HISTORY_COMPLETED,
    ChatsEntitiesActionNames.FINALIZE_CHAT_DATA_LOADING,
    ChatsEntitiesActionNames.NEW_ATTACHMENT,
    ChatsEntitiesActionNames.NEW_MESSAGE,
    ChatsEntitiesActionNames.REMOVE_CHAT_THREAD,
    ChatsEntitiesActionNames.UPDATE_CHAT,
    ChatsEntitiesActionNames.UPDATE_CHAT_THREAD,
    ChatsEntitiesActionNames.UPDATE_EVENTS,
    ChatsEntitiesActionNames.UPDATE_MESSAGE,
    ChatsEntitiesActionNames.UPDATE_QUEUE_POSITIONS,
    ChatsEntitiesActionNames.UPDATE_UNASSIGNED_CHATS_COUNT,
    ChatsViewActionsNames.LOAD_MORE_UNASSIGNED_CHATS,
    ChatsViewActionsNames.MARK_CHAT_AS_NEW,
    ChatsViewActionsNames.UNMARK_CHAT_AS_NEW,
  ];

  yield throttle(
    NOTIFICATION_COUNT_UPDATE_THROTTLE_TIME,
    [TabNotificationsActionNames.TRIGGER_CHATS_COUNT_RECALCULATE],
    recalculateNotifications,
    notificationChannel
  );

  while (true) {
    const { shouldRecalculate }: { shouldRecalculate: boolean } = yield race({
      shouldRecalculate: take(recalculateTriggerActions),
      shouldClear: take(TabNotificationsActionNames.CHATS_COUNT_CLEAR),
    });

    if (shouldRecalculate) {
      yield put(TabNotificationsActions.triggerChatsCountRecalculate());
    } else {
      notificationChannel.put(createNotificationEvent(0));
      // reset SmartClient notification badge
      if (isDesktopClient()) {
        App.SmartClient.handleBadgeContentChange(0);
      }
    }
  }
}

export function* watchChatNotificationCount(notificationChannel: NotificationChannel): SagaIterator {
  yield call(listenToChangesInStore, notificationChannel);
}
