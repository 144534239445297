import { useState, type FC } from 'react';

import { ArrowForward, Report } from '@livechat/design-system-icons';
import { Icon } from '@livechat/design-system-react-components';
import { useDispatch, useSelector } from 'react-redux';

import { OneTrackEvent } from 'constants/one-event';
import { EventPlace } from 'helpers/analytics';
import { navigate } from 'helpers/routing';
import { trackEvent } from 'services/event-tracking';
import { type APISource } from 'store/entities/one/interfaces';
import { OneViewActions } from 'store/views/one/actions';
import { getIsOneModalExpanded } from 'store/views/one/selectors';
import { ReportsViewActions } from 'store/views/reports/actions';

import * as styles from './styles';

type Props = APISource;

const handleSourceClick = (): void => {
  trackEvent(OneTrackEvent.ActionPerformed, EventPlace.One, { action: 'source clicked' });
};

export const ApiSourceItem: FC<Props> = ({ name, url, params }) => {
  const isExpanded = useSelector(getIsOneModalExpanded);
  const [isHovered, setIsHovered] = useState(false);
  const dispatch = useDispatch();

  const handleApiSourceClick = (filters: APISource['params'], url: string): void => {
    dispatch(ReportsViewActions.setFilters(filters));
    navigate(url);
    if (isExpanded) {
      dispatch(OneViewActions.toggleExpand());
    }
  };

  return (
    <li
      onClick={handleSourceClick}
      className={styles.linkContainer('api_call')}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Icon source={Report} />
      <p onClick={() => handleApiSourceClick(params, url)} className={styles.sourceItem}>
        {name}
      </p>
      {isHovered && <Icon source={ArrowForward} className={styles.openInNewIcon} />}
    </li>
  );
};
