import { createGlobalAccountsApiClient } from '../client';
import { type GlobalAccountsApiErrorResponse } from '../types';

import { type ListAccountsResponse, type GetAccountResponse } from './types';

const client = createGlobalAccountsApiClient();

/**
 * Global Accounts API accounts client.
 * @see {@link https://platform.text.com/docs/authorization/global-accounts-api#tag/Accounts}
 */
export const accountsClient = {
  /**
   * Retrieves a list of accounts tied to organization_id.
   * @see {@link https://platform.text.com/docs/authorization/global-accounts-api#tag/Accounts/paths/~1accounts/get}
   */
  list: async () => {
    return client.get<ListAccountsResponse, GlobalAccountsApiErrorResponse>('accounts');
  },

  /**
   * Retrieves the details of an account.
   * @see {@link https://platform.text.com/docs/authorization/global-accounts-api#tag/Accounts/paths/~1accounts~1%7Baccount_id%7D/get}
   */
  get: async (accountId: string) => {
    return client.get<GetAccountResponse, GlobalAccountsApiErrorResponse>(`accounts/${accountId}`);
  },
};
