export enum InviteAgentsSource {
  Team = 'team-button',
  AgentsList = 'agents-list',
  Checklist = 'checklist',
  LiveStats = 'liveStats',
  Onboarding = 'onboarding',
  SuggestedList = 'suggestedList',
  PendingInvitesTopBar = 'pendingInvitesTopBar',
  PendingInvitesBanner = 'pendingInvitesBanner',
  TransferChatModal = 'transferChatModal',
  TransferChatTooltip = 'transferChatTooltip',
  TargetedMessagesBanner = 'targetedMessagesBanner',
  AgentsPerformanceReport = 'agentsPerformanceReport',
  GroupMembersUpdate = 'groupMembersUpdate',
  GroupCreate = 'groupCreate',
  SessionsLimitExceeded = 'sessionsLimitExceeded',
  Reviews = 'reviews',
  AccessBlocked = 'accessBlocked',
  OnboardingInviteDeveloper = 'onboardingInviteDeveloper',
  DirectLink = 'directLink',
}
