import { type FC, type MutableRefObject, useState, useCallback, useEffect } from 'react';

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { LexicalTypeaheadMenuPlugin } from '@lexical/react/LexicalTypeaheadMenuPlugin';
import { CLEAR_EDITOR_COMMAND, type TextNode } from 'lexical';
import { useSelector } from 'react-redux';

import { OneTrackEvent } from 'constants/one-event';
import { EventPlace } from 'helpers/analytics';
import { usePrevious } from 'hooks/use-previous';
import { type Suggestion } from 'interfaces/one';
import { trackEvent } from 'services/event-tracking';
import { getIsOneModalExpanded, getOneIsLoading } from 'store/views/one/selectors';

import { CommandMenu } from '../picker/CommandMenu';
import { EmptyState } from '../picker/EmptyState';
import { type OnePickerOption } from '../picker/one-picker-option';

import { useCustomTypeahead } from './hooks/use-custom-typeahead';
import { useDynamicPickerOptions } from './hooks/use-dynamic-options';

interface IProps {
  suggestions: Required<Suggestion>[];
  isCommandMenuVisibleRef: MutableRefObject<boolean>;
}

export const CommandMenuPlugin: FC<IProps> = ({ suggestions, isCommandMenuVisibleRef }) => {
  const [editor] = useLexicalComposerContext();
  const [queryString, setQueryString] = useState<string | null>(null);

  const { checkTriggerMatch } = useCustomTypeahead();
  const { options } = useDynamicPickerOptions({ suggestions, queryString });
  const isLoading = useSelector(getOneIsLoading);
  const isExpanded = useSelector(getIsOneModalExpanded);
  const prevIsExpanded = usePrevious(isExpanded);

  useEffect(() => {
    const shouldClearEditor = isCommandMenuVisibleRef.current && isExpanded !== prevIsExpanded;

    if (shouldClearEditor) {
      editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
    }
  }, [isExpanded, prevIsExpanded, editor]);

  // eslint-disable-next-line react-compiler/react-compiler
  isCommandMenuVisibleRef.current = !!options.length;

  const onSelectOption = useCallback(
    (selectedOption: OnePickerOption, nodeToRemove: TextNode | null, closeMenu: () => void) => {
      if (isLoading) {
        return;
      }

      editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
      editor.update(() => {
        nodeToRemove?.remove();
        selectedOption.onSelect();
        closeMenu();
      });

      trackEvent(OneTrackEvent.MessageSent, EventPlace.One, {
        source: 'command popover',
        state: isExpanded ? 'fullscreen' : 'popover',
      });
    },
    [editor, isLoading, isExpanded]
  );

  return (
    <LexicalTypeaheadMenuPlugin<OnePickerOption>
      onQueryChange={setQueryString}
      triggerFn={checkTriggerMatch}
      onSelectOption={onSelectOption}
      options={options}
      onClose={() => (isCommandMenuVisibleRef.current = false)}
      menuRenderFn={(_, { selectOptionAndCleanUp, setHighlightedIndex, selectedIndex }) => {
        if (isLoading) {
          return <EmptyState />;
        }

        return options.length ? (
          <CommandMenu
            options={options}
            selectOption={selectOptionAndCleanUp}
            selectedIndex={selectedIndex}
            setHighlightedIndex={setHighlightedIndex}
          />
        ) : null;
      }}
    />
  );
};
