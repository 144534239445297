import { AgentChatApiErrorType, type AgentChatApiClientError, type AgentChatApiNormalizedError } from './types';

/**
 * Normalizes an API error object.
 * @param error - The API error object.
 * @returns The normalized error object.
 */
export function normalizeError(error: AgentChatApiClientError): AgentChatApiNormalizedError {
  if (error.http instanceof Error) {
    return {
      type: AgentChatApiErrorType.INTERNAL,
      message: error.http.message,
      status: error.status,
    };
  }

  if (error.http && 'error' in error.http) {
    return {
      ...error.http.error,
      status: error.status,
    };
  }

  return {
    type: AgentChatApiErrorType.INTERNAL,
    message: JSON.stringify(error),
    status: error.status,
  };
}
