// @ts-strict-ignore
import { addMonths, format } from 'date-fns';
import isString from 'lodash.isstring';
import { createSelector } from 'reselect';

import { DateFormat } from 'constants/date';
import { PlanType } from 'constants/plan-type';
import { PaymentType } from 'constants/product-cart';
import { secondsTimestampToDate } from 'helpers/date';
import { LCBillingCycleToProductCycleMap } from 'helpers/subscription';
import { getIsOnePlanLevelDowngrade, isHigherPlan, isLowerPlan } from 'helpers/subscription-plans';
import { type Product } from 'interfaces/product-cart';
import type { CreditCardType } from 'interfaces/recurly';
import { BillingCycleType } from 'interfaces/subscription';
import {
  type WithAgentsState,
  getActiveAgentsCount,
  getLoggedInAgentLogin,
  getLoggedInAgentName,
} from 'store/entities/agents/selectors';
import {
  type IWithBillingEntitiesState,
  getHasApplicationsToUninstall,
  getIsSavingCreditCard,
  getBillingInfo,
} from 'store/entities/billing/selectors';
import {
  type IWithLicensePropertiesState,
  getPaymentRecurrentEnabled,
} from 'store/entities/license-properties/selectors';
import {
  type IWithProductCartEntitiesState,
  getCurrentCartProductsToSubscribe,
  getIsProductCartFetching,
} from 'store/entities/product-cart/selectors';
import {
  getCouponRedemptionError,
  getIsLoadingCoupons,
  type IWithRecurlyEntitiesState,
  getNewRecurlyCoupon,
} from 'store/entities/recurly/selectors';
import { SubscriptionRequestFetchingSelectorsNames } from 'store/entities/subscription/actions';
import {
  getIsSubscriptionInTrial,
  getSubscriptionChange,
  getSubscriptionChangeSeats,
  getPlanPrices,
  getSubscription,
  getIsSubscriptionUnchanged,
  getSubscriptionIsPastDue,
  getWasSubscriptionCreated,
  type IWithSubscriptionState,
  getSubscriptionChangePlanCode,
  getSubscriptionChangePlan,
  getIsSubscriptionProcessing,
} from 'store/entities/subscription/selectors';
import {
  type IWithSessionState,
  getIsPPABusinessModel,
  getIsSubscriptionExpired,
  getCanManageSubscription,
  type IWithAgentsSessionState,
  getIsAfterStarterLimit,
} from 'store/features/session/selectors';
import {
  type IWithRequestsState,
  createRequestErrorSelector,
  createRequestFetchingSelector,
} from 'store/requests/selectors';

import { getPlanPrice } from './helpers';
import type { CalendlyData, ISubscriptionViewState } from './interfaces';

export const CALENDLY_URL_FOR_TRIAL = 'https://calendly.com/d/ckt4-fb6-v6g';
export const CALENDLY_URL_FOR_PAID = 'https://calendly.com/livechat-csm/livechat-enterprise-consultation';

export interface IWithSubscriptionViewState {
  views: {
    subscription: ISubscriptionViewState;
  };
}

export function getSubscriptionError(state: IWithRequestsState): string {
  return (
    createRequestErrorSelector([SubscriptionRequestFetchingSelectorsNames.CREATE_SUBSCRIPTION_REQUEST])(state) ||
    createRequestErrorSelector([SubscriptionRequestFetchingSelectorsNames.UPDATE_SUBSCRIPTION_REQUEST])(state) ||
    createRequestErrorSelector([SubscriptionRequestFetchingSelectorsNames.UPGRADE_SUBSCRIPTION_REQUEST])(state)
  );
}

export function getIsAddingCardAndSubscription(state: IWithRequestsState): boolean {
  return getIsSavingCreditCard(state) || getIsSubscriptionProcessing(state);
}

export function getRecurlyError(state: IWithSubscriptionViewState): string | null {
  const recurlyError = state.views.subscription.recurlyError;

  if (isString(recurlyError) && recurlyError.includes('insufficient funds in your account')) {
    return 'Transaction declined due to insufficient funds. Use a different card or contact your bank';
  }

  return recurlyError;
}

export function getIsSubscriptionLoading(
  state: IWithRequestsState & IWithSubscriptionState & IWithSubscriptionViewState & IWithAgentsSessionState
): boolean {
  return (
    getCanManageSubscription(state) &&
    (!getSubscription(state) || getIsProductCartFetching(state) || getIsLoadingCoupons(state)) &&
    !getIsAddingCardAndSubscription(state)
  );
}

export function getIsOwnerContactProcessing(state: IWithSubscriptionViewState & IWithRequestsState): boolean {
  return createRequestFetchingSelector([SubscriptionRequestFetchingSelectorsNames.CONTACT_SUBSCRIPTION_OWNER_REQUEST])(
    state
  );
}

export function getIsLicenseDataRefreshFailure(state: IWithSubscriptionViewState): boolean {
  return state.views.subscription.licenseDataRefreshFailure;
}

export function getIsDowngradeConfirmFlowEnabled(state: IWithSubscriptionViewState): boolean {
  return state.views.subscription.isDowngradeConfirmFlowEnabled;
}

export function getCanRestartTrial(state: IWithSubscriptionViewState): boolean {
  return state.views.subscription.canRestartTrial;
}

export function getRecommendedPlan(state: IWithSubscriptionViewState): PlanType {
  return state.views.subscription.recommendedPlan;
}

export function getCompanySizeRecommendedPlan(state: IWithSubscriptionViewState): PlanType {
  return state.views.subscription.companySizeRecommendedPlan;
}

export function getHighlightedFeatures(state: IWithSubscriptionViewState): string[] {
  return state.views.subscription.highlightedFeatures || [];
}

export function getCurrentCardBrand(state: IWithSubscriptionViewState): CreditCardType {
  return state.views.subscription.currentCardBrand;
}

export function getIsSubscribeButtonDisabled(state: IWithSubscriptionViewState): boolean {
  return state.views.subscription.isSubscribeButtonDisabled;
}

export function getHasRecurlyAccount(state: IWithSubscriptionState & IWithBillingEntitiesState): boolean {
  const billingInfo = getBillingInfo(state);
  const subscription = getSubscription(state);

  return !!billingInfo || !!subscription?.card || !!subscription?.subscriber || subscription?.origin === 'recurly';
}

export function getIsNavigationVisible(state: IWithSubscriptionViewState): boolean {
  return state.views.subscription.showNavigation;
}

export function getWillSubscriptionExpire(
  state: IWithSubscriptionViewState & IWithLicensePropertiesState & IWithSessionState
): boolean {
  return !getPaymentRecurrentEnabled(state) && !getIsSubscriptionExpired(state);
}

export function getShouldForcePPABusinessModel(state: IWithSessionState): boolean {
  const isPPABusinessModel = getIsPPABusinessModel(state);
  const isExpired = getIsSubscriptionExpired(state);

  return isPPABusinessModel || (!isPPABusinessModel && isExpired);
}

export function getCanApplyCovidDiscount(
  state: IWithSubscriptionViewState & IWithSubscriptionState & IWithRecurlyEntitiesState & IWithSessionState
): boolean {
  const { plan: oldPlan } = getSubscription(state);
  const { billingCycle, plan: newPlan } = getSubscriptionChange(state);
  const newRecurlyCoupon = getNewRecurlyCoupon(state);
  const isOnePlanLevelDowngrade = getIsOnePlanLevelDowngrade(newPlan, oldPlan);
  const isPaidMonthly = billingCycle === BillingCycleType.Monthly;
  const newPlanIsNotStarter = newPlan !== PlanType.Starter;

  return isPaidMonthly && isOnePlanLevelDowngrade && newRecurlyCoupon.exists && newPlanIsNotStarter;
}

export const getIsSubscriptionChanged = createSelector(
  getSubscription,
  getIsSubscriptionUnchanged,
  (subscription, isSubscriptionUnchanged) =>
    subscription?.isExpired || !subscription?.subscriber || !isSubscriptionUnchanged
);

export const getShouldAllowCheckoutStep = createSelector(
  getSubscription,
  getBillingInfo,
  (subscription, billingInfo) => subscription?.isPastDue || !billingInfo?.lastFour
);

export const getIsNewCouponApplicable: (
  state: IWithSubscriptionViewState & IWithSubscriptionState & IWithRecurlyEntitiesState
) => boolean = createSelector(
  getSubscriptionChangePlanCode,
  getNewRecurlyCoupon,
  (newSubscriptionPlanCode, newCoupon) =>
    newSubscriptionPlanCode &&
    newCoupon.exists &&
    !newCoupon.hideDiscount &&
    (newCoupon.appliesToAllPlans || newCoupon.plans.includes(newSubscriptionPlanCode))
);

function getIsDownsellInfoVisible(
  state: IWithSubscriptionViewState & IWithSubscriptionState & IWithSessionState & WithAgentsState
): boolean {
  const isPPABusinessModel = getShouldForcePPABusinessModel(state);
  const activeAgentsCount = getActiveAgentsCount(state);
  const newSubscriptionSeats = getSubscriptionChangeSeats(state);

  return isPPABusinessModel && activeAgentsCount > newSubscriptionSeats;
}

function getIsStarterUpsellInfoVisible(
  state: IWithSubscriptionViewState & IWithSubscriptionState & IWithSessionState & WithAgentsState
): boolean {
  const isAfterStarterLimit = getIsAfterStarterLimit(state);
  const isExpired = getIsSubscriptionExpired(state);
  const newSubscriptionChangePlan = getSubscriptionChangePlan(state);
  const activeAgentsCount = getActiveAgentsCount(state);

  return (isExpired || isAfterStarterLimit) && activeAgentsCount > 1 && newSubscriptionChangePlan === PlanType.Starter;
}

export function getIsSubscriptionUpdateDisabled(
  state: IWithSubscriptionViewState &
    IWithSubscriptionState &
    IWithSessionState &
    WithAgentsState &
    IWithRecurlyEntitiesState &
    IWithBillingEntitiesState &
    IWithProductCartEntitiesState
): boolean {
  const subscription = getSubscription(state);
  const isSubscriptionParamsUnchanged = getIsSubscriptionUnchanged(state);
  const isPastDue = getSubscriptionIsPastDue(state);
  const isExpired = getIsSubscriptionExpired(state);
  const isSubscriber = getWasSubscriptionCreated(state);
  const isDownsellInfoVisible = getIsDownsellInfoVisible(state);
  const isStarterUpsellInfoVisible = getIsStarterUpsellInfoVisible(state);
  const isNewCouponApplicable = getIsNewCouponApplicable(state);
  const areAppsAddedToCart = getCurrentCartProductsToSubscribe(state).length > 0;
  const hasApplicationsToUninstall = getHasApplicationsToUninstall(state);
  const couponRedemptionError = getCouponRedemptionError(state);
  const isSubscriptionUnchanged =
    isSubscriber && isSubscriptionParamsUnchanged && !hasApplicationsToUninstall && !areAppsAddedToCart;
  const hasPendingSeatsUpdate = subscription.pendingChange && subscription.pendingChange.seats !== subscription.seats;

  return (
    (!isExpired && isSubscriptionUnchanged && !isNewCouponApplicable && !isPastDue && !hasPendingSeatsUpdate) ||
    isDownsellInfoVisible ||
    isStarterUpsellInfoVisible ||
    !!couponRedemptionError
  );
}

/**
 * Map new subscription to IProduct interface.
 * To be used on manage view (cartItemCard and ReceiptItem).
 */
export const getNewSubscriptionAsProduct: (
  state: IWithSubscriptionViewState & IWithSubscriptionState & IWithSessionState
) => Product = createSelector(
  getSubscription,
  getSubscriptionChange,
  getIsSubscriptionInTrial,
  getPlanPrices,
  getIsSubscriptionExpired,
  (subscription, newSubscription, inTrial, planPrices, isExpired) => {
    const isUpgrade = isHigherPlan(newSubscription.plan, subscription.plan);
    const isDowngrade = isLowerPlan(newSubscription.plan, subscription.plan);

    // Calculate monthly price to see the difference between monthly and current subscription billing cycle
    // In case new billing cycle is monthly, there shouldn;t be any change
    // In case new billing cycle is annual we should show the difference in price and the discount
    const planPrice = getPlanPrice(newSubscription, subscription, planPrices, BillingCycleType.Monthly);
    const planPriceForChoosenBillingCycle = getPlanPrice(
      newSubscription,
      subscription,
      planPrices,
      newSubscription.billingCycle
    );

    const initialPrice = newSubscription.billingCycle * planPrice;
    const regularPrice = initialPrice * newSubscription.seats * 100;
    const totalPrice = planPriceForChoosenBillingCycle * newSubscription.billingCycle * newSubscription.seats * 100;
    const endDate =
      !isExpired && (subscription.billingCycle === newSubscription.billingCycle || subscription.inTrial)
        ? format(secondsTimestampToDate(subscription.currentPeriodEndsAt), DateFormat.TrialEndDate)
        : format(addMonths(Date.now(), newSubscription.billingCycle), DateFormat.TrialEndDate);

    const isSubscribed = subscription?.currentPeriodStartedAt;
    const nameSuffix = `${isUpgrade ? ' (Upgrade)' : ''}${isDowngrade ? ' (Downgrade)' : ''}`;
    const name = `${newSubscription.planName} plan${inTrial && !isSubscribed ? '' : nameSuffix}`;

    return {
      id: 'livechat',
      name,
      plans: [
        {
          id: 'plan',
          billingCycle: LCBillingCycleToProductCycleMap[newSubscription.billingCycle],
          selected: true,
          type: PaymentType.PerAgent,
          price: {
            regular: regularPrice,
            discount: regularPrice - totalPrice,
            initial: initialPrice,
            total: totalPrice,
          },
          discount: parseFloat((((regularPrice - totalPrice) / regularPrice) * 100).toFixed(2)),
        },
      ],
      trialEndDate: inTrial && endDate,
      trialDays: 0,
      renewDate: !inTrial && endDate,
    };
  }
);

export const getIsCalendlyModalOpen = (state: IWithSubscriptionViewState): boolean =>
  state.views.subscription.isCalendlyModalOpen;

export const getIsComparePlansModalOpen = (state: IWithSubscriptionViewState): boolean =>
  state.views.subscription.isComparePlansModalOpen;

export const getShouldGoBackToPlansModal = (state: IWithSubscriptionViewState): boolean =>
  state.views.subscription.shouldGoBackToPlansModal;

export const getCalendlyData = (
  state: IWithSubscriptionViewState & IWithSubscriptionState & WithAgentsState
): CalendlyData => {
  const agentLogin = getLoggedInAgentLogin(state);
  const agentName = getLoggedInAgentName(state);
  const isPaid = getSubscription(state)?.subscriber;

  const calendlyUrl = isPaid ? CALENDLY_URL_FOR_PAID : CALENDLY_URL_FOR_TRIAL;

  return {
    url: calendlyUrl,
    prefill: {
      email: agentLogin,
      name: agentName,
    },
  };
};
