export enum SettingsSection {
  AccessRestrictions = 'Access restrictions',
  AgentAuthentication = 'Agent authentication',
  Android = 'Android',
  AskForEmail = 'Ask for email',
  Availability = 'Availability',
  RoutingRules = 'Routing rules',
  BannedVisitors = 'Banned visitors',
  CannedResponses = 'Canned responses',
  SuggestedCannedResponses = 'Suggested responses',
  ChatBoosters = 'Chat boosters',
  WelcomeScreen = 'Welcome screen',
  ChatButtons = 'Chat buttons',
  ChatPage = 'Chat page',
  ChatPageSetup = 'Chat page setup',
  ChatAssignment = 'Chat assignment',
  ChatWidget = 'Chat widget',
  CreditCardMasking = 'Credit card masking',
  Customization = 'Customization',
  Desktop = 'Desktop',
  Email = 'Email',
  HelpDesk = 'HelpDesk',
  EmailNotifications = 'Email notifications',
  EyeCatcher = 'Eye-catcher',
  FacebookMessenger = 'Facebook Messenger',
  AppleBusinessChat = 'Apple Business Chat',
  FileSharing = 'File sharing',
  Goals = 'Goals',
  Greetings = 'Greetings',
  IPad = 'iPad',
  IPhone = 'iPhone',
  InactivityTimeouts = 'Inactivity timeouts',
  Language = 'Language',
  MessagingMode = 'Messaging Mode',
  PostChatSurvey = 'Post-chat survey',
  PreChatSurvey = 'Pre-chat survey',
  QualityBadgeAndPage = 'Quality showcase',
  QualityBadgeCustomization = 'Quality badge customization',
  SalesTracker = 'Sales tracker',
  Tags = 'Tags',
  TicketForm = 'Ticket form',
  TranscriptForwarding = 'Transcript forwarding',
  TrustedDomains = 'Trusted domains',
  URLRules = 'URL rules',
  WebsiteCode = 'Code',
  KnowledgeSources = 'Knowledge sources',
  CreditCardsMasking = 'Credit cards masking',
  LoginSettings = 'Login settings',
  Overview = 'Overview',
}
