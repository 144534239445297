import { type PickRequired } from 'helpers/interface';
import { createAction, type ActionsUnion } from 'store/helper';

import {
  type ISetOneSessionIdPayload,
  type IOneEvent,
  type IOneOnboardingActionPayload,
  type OneAddConversationPayload,
} from './interfaces';

export enum OneEntitiesActionNames {
  ADD_ONE_MESSAGE = 'ENTITIES/CHATS/ADD_LIVE_ASSISTANT_MESSAGE',
  ADD_ONE_ERROR = 'ENTITIES/CHATS/ADD_LIVE_ASSISTANT_ERROR',
  CLEAR_ONE_ERROR = 'ENTITIES/CHATS/CLEAR_LIVE_ASSISTANT_ERROR',
  SEND_ONE_MESSAGE = 'ENTITIES/CHATS/SEND_LIVE_ASSISTANT_MESSAGE',
  SET_ONE_SESSION_ID = 'ENTITIES/CHATS/SET_LIVE_ASSISTANT_SESSION_ID',
  CANCEL_ONE_REQUEST = 'ENTITIES/CHATS/CANCEL_LIVE_ASSISTANT_REQUEST',
  SET_FAILED_ONE_MESSAGE = 'ENTITIES/CHATS/SET_FAILED_LIVE_ASSISTANT_MESSAGE',
  CLEAR_FAILED_ONE_MESSAGE = 'ENTITIES/CHATS/CLEAR_FAILED_LIVE_ASSISTANT_MESSAGE',
  RETRY_FAILED_ONE_MESSAGE = 'ENTITIES/CHATS/RETRY_FAILED_LIVE_ASSISTANT_MESSAGE',
  CONTINUE_ONE_ONBOARDING = 'ENTITIES/CHATS/CONTINUE_LIVE_ASSISTANT_ONBOARDING',
  UPDATED_ONE_ACTION_BUTTONS = 'ENTITIES/CHATS/UPDATED_LIVE_ASSISTANT_ACTION_BUTTONS',
  ONE_MESSAGE_SPOTLIGHT = 'ENTITIES/CHATS/ONE_MESSAGE_SPOTLIGHT',
  ONE_ONBOARDING_ACTION = 'ENTITIES/CHATS/ONE_ONBOARDING_ACTION',
  ONE_ADD_CONVERSATION = 'ENTITIES/CHATS/ONE_ADD_CONVERSATION',
  SET_LATEST_AGENT_EVENT_ID = 'ENTITIES/CHATS/SET_LATEST_AGENT_EVENT_ID',
}

export const OneEntitiesActions = {
  addOneMessage: (payload: IOneEvent) => createAction(OneEntitiesActionNames.ADD_ONE_MESSAGE, payload),
  addOneError: (payload: boolean) => createAction(OneEntitiesActionNames.ADD_ONE_ERROR, payload),
  clearOneError: () => createAction(OneEntitiesActionNames.CLEAR_ONE_ERROR),
  sendOneMessage: (payload: string) => createAction(OneEntitiesActionNames.SEND_ONE_MESSAGE, payload),
  setOneSessionId: (payload: ISetOneSessionIdPayload) =>
    createAction(OneEntitiesActionNames.SET_ONE_SESSION_ID, payload),
  cancelOneRequest: () => createAction(OneEntitiesActionNames.CANCEL_ONE_REQUEST),
  setFailedOneMessage: (payload: string) => createAction(OneEntitiesActionNames.SET_FAILED_ONE_MESSAGE, payload),
  clearFailedOneMessage: () => createAction(OneEntitiesActionNames.CLEAR_FAILED_ONE_MESSAGE),
  retryFailedOneMessage: () => createAction(OneEntitiesActionNames.RETRY_FAILED_ONE_MESSAGE),
  continueOneOnboarding: (step?: string) => createAction(OneEntitiesActionNames.CONTINUE_ONE_ONBOARDING, step),
  oneOnboardingAction: (payload: IOneOnboardingActionPayload) =>
    createAction(OneEntitiesActionNames.ONE_ONBOARDING_ACTION, payload),
  updateOneEvent: (payload: PickRequired<IOneEvent, 'eventId'>) =>
    createAction(OneEntitiesActionNames.UPDATED_ONE_ACTION_BUTTONS, payload),
  setOneMessageSpotlight: (payload: boolean) => createAction(OneEntitiesActionNames.ONE_MESSAGE_SPOTLIGHT, payload),
  addOneConversation: (payload: OneAddConversationPayload) =>
    createAction(OneEntitiesActionNames.ONE_ADD_CONVERSATION, payload),
  setLatestAgentEventId: (payload: PickRequired<IOneEvent, 'eventId'>) =>
    createAction(OneEntitiesActionNames.SET_LATEST_AGENT_EVENT_ID, payload),
};

export type OneEntitiesAction = ActionsUnion<typeof OneEntitiesActions>;
