/* eslint-disable @typescript-eslint/naming-convention */
import { useCallback } from 'react';

import debug from 'debug';

import { DebugLogsNamespace } from 'constants/debug-logs-namespace';
import { PostMessageEvent } from 'constants/post-message-event';
import { getConfig } from 'helpers/config';
import { JSONParse } from 'helpers/json';
import { navigate } from 'helpers/routing';
import { getMarketplaceDomain } from 'helpers/url';
import { useEffectOnce } from 'hooks/use-effect-once';
import type { PostMessage } from 'interfaces/post-message';

const { publicDevMarketplace } = getConfig();
const publicMarketplaceDomain = getMarketplaceDomain(publicDevMarketplace);

const log = debug(DebugLogsNamespace.AppDebug);

export function useMarketplacePostMessageListener(): void {
  const handlePostMessage = useCallback((event: MessageEvent<string>) => {
    if (event.origin !== publicMarketplaceDomain) {
      return;
    }

    try {
      const parsedEvent = JSONParse<PostMessage>(event.data);

      if (!parsedEvent) {
        return;
      }

      const { event_name, event_data } = parsedEvent;

      if (event_name === PostMessageEvent.Navigate) {
        const { pathname } = event_data;
        if (!pathname) {
          return;
        }

        navigate(pathname);
      }
    } catch (error) {
      // do nothing
    }
  }, []);

  useEffectOnce(() => {
    log('useMarketplacePostMessageListener: adding post message event listener');

    window.addEventListener('message', handlePostMessage);

    return () => {
      log('useMarketplacePostMessageListener: removing post message event listener');

      window.removeEventListener('message', handlePostMessage);
    };
  });
}
