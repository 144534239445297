// @ts-strict-ignore
import { GoalType } from 'constants/goal';
import { ReportDistribution } from 'constants/reports/distribution';
import { type KeyMap } from 'helpers/interface';
import { trimObject } from 'helpers/object';
import type { RequestResult } from 'interfaces/api/client';
import type { PerformanceStatsApiResponse } from 'interfaces/api/performance-stats';
import { type GroupId } from 'interfaces/groups';
import {
  type IAchievedGoalsPeriodData,
  type ILast7DaysRequestData,
  type ISerializedFiltersWithRawDates,
} from 'interfaces/reports';
import { BaseApi } from 'services/api/base-api';

export class ReportApiV2 extends BaseApi {
  protected readonly prefix = 'reports';

  fetchLast7Days = () => this.get<ILast7DaysRequestData>('dashboard', { categorized: 1 });

  fetchAgentPerformanceStatsForLast7Days = (login: string): Promise<RequestResult<PerformanceStatsApiResponse>> =>
    this.get<PerformanceStatsApiResponse>(`dashboard/agent/${login}`, { categorized: 1 });

  fetchGroupPerformanceStatsForLast7Days = (id: GroupId): Promise<RequestResult<PerformanceStatsApiResponse>> =>
    this.get<PerformanceStatsApiResponse>(`dashboard/group/${id}`, { categorized: 1 });

  fetchGoals = ({
    dateFrom = null,
    dateTo = null,
    distribution = ReportDistribution.Day,
    agents = null,
    groups = null,
    tags = null,
    tagged = null,
    type = null,
    goals = null,
    sales = null,
  }: ISerializedFiltersWithRawDates) =>
    this.get<KeyMap<IAchievedGoalsPeriodData>>(
      'chats/goals',
      trimObject({
        date_from: dateFrom,
        date_to: dateTo,
        group_by: distribution,
        agents,
        groups,
        tag: tags,
        tagged,
        sales_tracker: type === GoalType.Sale,
        goals,
        sales,
      })
    );

  fetchQueuedCustomers = ({
    dateFrom = null,
    dateTo = null,
    distribution = ReportDistribution.Day,
    groups = null,
  }: ISerializedFiltersWithRawDates) =>
    this.get<KeyMap>(
      'chats/queued_visitors',
      trimObject({ date_from: dateFrom, date_to: dateTo, group_by: distribution, groups })
    );

  fetchWaitingTime = ({
    dateFrom = null,
    dateTo = null,
    distribution = ReportDistribution.Day,
    groups = null,
  }: ISerializedFiltersWithRawDates) =>
    this.get<KeyMap>(
      'chats/queued_visitors/waiting_times',
      trimObject({ date_from: dateFrom, date_to: dateTo, group_by: distribution, groups })
    );

  fetchNewTickets = ({
    dateFrom = null,
    dateTo = null,
    distribution = ReportDistribution.Day,
    groups = null,
    tags = null,
    tagged = null,
  }: ISerializedFiltersWithRawDates) =>
    this.get<KeyMap>(
      'tickets/new_tickets',
      trimObject({ date_from: dateFrom, date_to: dateTo, group_by: distribution, groups, tag: tags, tagged })
    );

  fetchTicketSources = ({
    dateFrom = null,
    dateTo = null,
    distribution = ReportDistribution.Day,
    groups = null,
    tags = null,
    tagged = null,
  }: ISerializedFiltersWithRawDates) =>
    this.get<KeyMap>(
      'tickets/ticket_sources',
      trimObject({ date_from: dateFrom, date_to: dateTo, group_by: distribution, groups, tag: tags, tagged })
    );

  fetchTicketsResolutionTime = ({
    dateFrom = null,
    dateTo = null,
    distribution = ReportDistribution.Day,
    agents = null,
    groups = null,
    tags = null,
    tagged = null,
  }: ISerializedFiltersWithRawDates) =>
    this.get<KeyMap>(
      'tickets/resolution_time',
      trimObject({
        date_from: dateFrom,
        date_to: dateTo,
        group_by: distribution,
        agents,
        groups,
        tag: tags,
        tagged,
      })
    );

  fetchTicketsFirstResponseTime = ({
    dateFrom = null,
    dateTo = null,
    distribution = ReportDistribution.Day,
    agents = null,
    groups = null,
    tags = null,
    tagged = null,
  }: ISerializedFiltersWithRawDates) =>
    this.get<KeyMap>(
      'tickets/first_response_time',
      trimObject({
        date_from: dateFrom,
        date_to: dateTo,
        group_by: distribution,
        agents,
        groups,
        tag: tags,
        tagged,
      })
    );

  fetchTicketsSatisfaction = ({
    dateFrom = null,
    dateTo = null,
    distribution = ReportDistribution.Day,
    agents = null,
    groups = null,
    tags = null,
    tagged = null,
  }: ISerializedFiltersWithRawDates) =>
    this.get<KeyMap>(
      'tickets/ratings',
      trimObject({
        date_from: dateFrom,
        date_to: dateTo,
        group_by: distribution,
        agents,
        groups,
        tag: tags,
        tagged,
      })
    );

  fetchTicketsRanking = ({
    dateFrom = '',
    dateTo = '',
    distribution = ReportDistribution.Day,
    groups = null,
    tags = null,
    tagged = null,
  }: ISerializedFiltersWithRawDates) =>
    this.get<KeyMap>(
      'tickets/ratings/ranking',
      trimObject({ date_from: dateFrom, date_to: dateTo, group_by: distribution, groups, tag: tags, tagged })
    );

  fetchSolvedTickets = ({
    dateFrom = null,
    dateTo = null,
    distribution = ReportDistribution.Day,
    agents = null,
    groups = null,
    tags = null,
    tagged = null,
  }: ISerializedFiltersWithRawDates) =>
    this.get<KeyMap>(
      'tickets/solved_tickets',
      trimObject({
        date_from: dateFrom,
        date_to: dateTo,
        group_by: distribution,
        agents,
        groups,
        tag: tags,
        tagged,
      })
    );

  fetchAgentOccupancy = ({ weekday = null, groups = null, channel = null }: ISerializedFiltersWithRawDates) =>
    this.get<KeyMap>('chats/agents_occupancy', trimObject({ weekday, groups, client_id: channel }));

  fetchAgentActivity = ({ date = null, groups = null }: ISerializedFiltersWithRawDates) =>
    this.get<KeyMap>('agents/events', trimObject({ date, groups }));

  fetchChatSurveys = ({
    dateFrom = '',
    dateTo = '',
    survey = null,
    groups = null,
    tags = null,
    tagged = null,
    agents = null,
  }: ISerializedFiltersWithRawDates) =>
    this.get<KeyMap>(
      'chats/chat_surveys',
      trimObject({ date_from: dateFrom, date_to: dateTo, survey, groups, tag: tags, tagged, agents })
    );
}
