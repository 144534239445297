// @ts-strict-ignore
import { type SagaIterator } from 'redux-saga';
import { takeLatest, put, select } from 'redux-saga/effects';

import { ChatsEntitiesActions } from 'store/entities/chats/actions';
import { type ChatThreadEntity } from 'store/entities/chats/interfaces';
import { getThreadByCustomerId } from 'store/entities/chats/selectors';
import {
  type IUpdateCustomerInfoPayload,
  type ICustomer,
  type IUpdateCustomerPayload,
} from 'store/entities/customers/interfaces';
import { type IActionWithPayload } from 'store/helper';
import { ArchivesViewActions } from 'store/views/archives/actions';

import { CustomerActionNames, CustomerActions } from './actions';
import { getCustomer } from './selectors';

/**
 * Handles update of name/customer data performed by currently logged in agent.
 * @param action With payload containing name and email update.
 */
function* handleCustomerInfoUpdate(action: IActionWithPayload<string, IUpdateCustomerInfoPayload>): SagaIterator {
  const { userId, name, email } = action.payload;

  const customer: ICustomer = yield select(getCustomer, userId);

  if (customer) {
    yield put(
      CustomerActions.updateCustomer({
        customer: {
          ...customer,
          name,
          email,
        },
      })
    );
  }
}

/**
 * Whenever Customer is updated it might be required to update other parts of the store that hold customer data.
 * It's mostly due to the fact that currently Backbone is the main source of truth for Customers data.
 * Shouldn't be necessary with Backbone removal.
 * @param action With payload containing new customer data.
 */
function* handleCustomerUpdate(action: IActionWithPayload<string, IUpdateCustomerPayload>): SagaIterator {
  const {
    customer: { id, name, email },
  } = action.payload;

  const customerThread: ChatThreadEntity = yield select(getThreadByCustomerId, id);
  if (customerThread?.threadId) {
    yield put(
      ChatsEntitiesActions.updateChatThread({
        threadId: customerThread.threadId,
        thread: {
          customerName: name,
        },
      })
    );

    yield put(
      ChatsEntitiesActions.updateChatThreadHistoryName({
        threadId: customerThread.threadId,
        thread: {
          customerName: name,
        },
      })
    );
  }

  yield put(
    ArchivesViewActions.updateCustomerInfo({
      userId: id,
      name,
      email,
    })
  );
}

export function* customersSaga(): SagaIterator {
  yield takeLatest(CustomerActionNames.UPDATE_CUSTOMER_INFO_SUCCESS, handleCustomerInfoUpdate);
  yield takeLatest(CustomerActionNames.UPDATE_CUSTOMER, handleCustomerUpdate);
}
