import { type KeyMap } from 'helpers/interface';

import { type IOneEvent, type WithOneEntitiesState } from './interfaces';

const EMPTY_ARRAY_OF_ARRAYS: string[][] = [];
const EMPTY_OBJECT = {};

export function getOneAllEvents(state: WithOneEntitiesState): KeyMap<IOneEvent> {
  return state.entities.one.events.byIds || EMPTY_OBJECT;
}

export function getOneGroupedEventsIds(state: WithOneEntitiesState): string[][] {
  return state.entities.one.events.groupedIds || EMPTY_ARRAY_OF_ARRAYS;
}
export function getIsOneError(state: WithOneEntitiesState): boolean | null {
  return state.entities.one.isError || null;
}

export function getOneCurrentSessionId(state: WithOneEntitiesState): string | null {
  return state.entities.one.currentSessionId;
}

export function getFailedOneMessage(state: WithOneEntitiesState): string {
  return state.entities.one.failedMessageContent ?? '';
}

export function getOneLatestAgentEventId(state: WithOneEntitiesState): string | null {
  return state.entities.one.events.latestAgentEventId;
}
