import { Interval } from 'constants/date';
import { QueryKey } from 'constants/query-key';
import { dateDifference } from 'helpers/date';
import { PlatformNamespace, StatisticsProperty } from 'services/connectivity/configuration-api/properties/constants';
import { type PlatformNamespaceProperties } from 'services/connectivity/configuration-api/properties/types';
import { getQueryClient } from 'services/query-client/client';
import { QUERY_KEYS } from 'services/query-client/keys';

import { calculateHasWebsiteLastActivity } from './helpers/calculate-has-website-last-activity';

export function isWebsiteLastActivityStored(): boolean {
  const queryState = getQueryClient().getQueryState(
    QUERY_KEYS[QueryKey.LicenseProperties](PlatformNamespace.STATISTICS),
  );

  return queryState?.status === 'success';
}

function getStoredWebsiteLastActivity(): string | undefined {
  const cachedData: PlatformNamespaceProperties[PlatformNamespace.STATISTICS] = getQueryClient().getQueryData(
    QUERY_KEYS[QueryKey.LicenseProperties](PlatformNamespace.STATISTICS),
  );

  return cachedData?.[StatisticsProperty.WebsiteLastActivity] as string | undefined;
}

export function hasWebsiteLastActivity(): boolean {
  if (!isWebsiteLastActivityStored()) {
    throw new Error('Website activity not stored');
  }

  return calculateHasWebsiteLastActivity(getStoredWebsiteLastActivity());
}

export function getLastActivityInDays(): number | undefined {
  if (!isWebsiteLastActivityStored()) {
    throw new Error('Website activity not stored');
  }

  const lastActivity = getStoredWebsiteLastActivity();

  if (!lastActivity || !hasWebsiteLastActivity()) {
    return undefined;
  }

  const lastActivityDate = new Date(lastActivity);

  if (isNaN(lastActivityDate.getTime())) {
    return undefined;
  }

  const dateDiff = dateDifference(lastActivityDate, new Date(), Interval.Day);

  return Math.abs(dateDiff);
}
