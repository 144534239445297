/* eslint-disable @typescript-eslint/naming-convention */
export type KnowledgeSourceType = 'URL';

export enum KnowledgeSourceItemStatus {
  IDLE = 'IDLE',
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}

export type MaxUrlDepth = 0 | 3;

export interface IKnowledgeSourceItemBase {
  id: string;
  name: string;
  status: KnowledgeSourceItemStatus;
  sourceType: KnowledgeSourceType;
  customDescription: string;
  createdAt: string;
  updatedAt: string;
  learnedAt: string | null;
  authorId: string | null;
}

export interface IKnowledgeSourceUrlItem extends IKnowledgeSourceItemBase {
  url: string;
  depth: MaxUrlDepth;
  pageCount: number;
}

export type KnowledgeSourceItem = IKnowledgeSourceUrlItem;

export enum AnnotateReplyType {
  ResponseUsed = 'response_used',
  ConversationRate = 'conversation_rate',
}

export type AnnotateReplyValue = 0 | 1;
