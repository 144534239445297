import { type FC, createContext, useCallback, useContext } from 'react';

import { useSelector } from 'react-redux';

import { useResourceContext } from 'components/apps/hooks/use-resource-context';
import { ResourceModal } from 'components/apps/modals/ResourceModal';
import { useWidgetModalContext } from 'hooks/fullscreen-widgets/use-widget-modal-context';
import { getBillingInfo } from 'store/entities/billing/selectors';

import { RESOURCE_MODAL } from '../constants';

import { type ResourceModalContext } from './interfaces';

export const WidgetResourceModalContext = createContext<ResourceModalContext | undefined>(undefined);

interface ProviderProps {
  isFree: boolean;
  isActive: boolean;
}

export const WidgetResourceModalContextProvider: FC<ProviderProps> = ({ isFree, isActive, children }) => {
  const { setOpenedModal, openedModal, setSelectedResourceId } = useWidgetModalContext();
  const { resourceId } = useResourceContext();

  const billingInfo = useSelector(getBillingInfo);
  const canPurchaseUpgrade = billingInfo || (!billingInfo && isFree);

  const closeModal = useCallback((): void => {
    setOpenedModal(null);
    setSelectedResourceId(null);
  }, [setOpenedModal, setSelectedResourceId]);

  const openModal = useCallback(
    (purchaseOnly?: boolean): void => {
      if (!canPurchaseUpgrade) {
        setOpenedModal(RESOURCE_MODAL.updateSubscription);

        return;
      }

      if (isActive && !purchaseOnly) {
        setOpenedModal(RESOURCE_MODAL.manage);
      } else {
        setOpenedModal(RESOURCE_MODAL.purchase);
      }

      setSelectedResourceId(resourceId);
    },
    [canPurchaseUpgrade, isActive, resourceId, setOpenedModal, setSelectedResourceId]
  );

  const value: ResourceModalContext = {
    openModal,
    closeModal,
  };

  return (
    <WidgetResourceModalContext.Provider value={value}>
      {children}
      {openedModal && <ResourceModal closeModal={closeModal} openedModal={openedModal} openModal={openModal} />}
    </WidgetResourceModalContext.Provider>
  );
};

export const useWidgetResourceModalContext = (): ResourceModalContext => {
  const context = useContext(WidgetResourceModalContext);

  if (context === undefined) {
    throw new Error('useWidgetResourceModalContext must be used within a WidgetResourceModalContextProvider');
  }

  return context;
};
