import { createElement, memo, type FC, type MouseEvent } from 'react';

import { cx } from '@emotion/css';
import { type ITooltipProps, Tooltip } from '@livechat/design-system-react-components';
import { useSelector } from 'react-redux';

import type { NavigationId } from 'constants/navigation';
import { getCurrentSection } from 'store/features/routing/selectors';

import { navigationItems } from '../../configuration';
import { useNavigationTooltip } from '../../hooks/use-navigation-tooltip';
import { NavigationIcon } from '../../navigation-icon/NavigationIcon';

import { activityComparator as defaultActivityComparator, onItemClick as defaultOnItemClick } from './helpers';

import * as styles from './styles';

interface IProps {
  id: NavigationId;
  className?: string;
}

const tooltipHooksConfig: Pick<ITooltipProps, 'useClickHookProps' | 'useDismissHookProps'> = {
  useClickHookProps: {
    ignoreMouse: true,
  },
  useDismissHookProps: {
    referencePress: true,
    referencePressEvent: 'click',
  },
};

export const NavigationItem: FC<IProps> = memo(({ id, className }) => {
  const section = useSelector(getCurrentSection);
  const tooltip = useNavigationTooltip(id);
  const config = navigationItems.get(id);

  if (!config) {
    return null;
  }

  const {
    icon: { inactiveIcon, activeIcon, className: iconClassName },
    badge,
    title,
    label,
    testId,
    activityComparator,
    onItemClick,
    onItemHover,
  } = config;
  /* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
  const trackingTitle = config.trackingTitle!;
  const path = config.path!;
  /* eslint-enable @typescript-eslint/no-unnecessary-type-assertion */
  const handleItemClick = (event: MouseEvent<HTMLAnchorElement>): void =>
    onItemClick
      ? onItemClick({ event, path, trackingTitle, defaultOnItemClick })
      : defaultOnItemClick({ event, path, trackingTitle });

  const isActive = activityComparator
    ? activityComparator({ section, id })
    : defaultActivityComparator({ section, id });
  const handleItemHover = (event: MouseEvent<HTMLAnchorElement>): void => onItemHover?.({ event, path });

  const shouldShowTooltip = !label ? false : undefined;

  const item = (
    <Tooltip
      offsetMainAxis={12}
      hoverOnDelay={400}
      isVisible={shouldShowTooltip}
      className={styles.itemTooltip}
      kind="invert"
      floatingStrategy="fixed"
      placement="right"
      triggerRenderer={
        <a
          aria-label={title || label}
          href={path}
          onClick={handleItemClick}
          onMouseEnter={handleItemHover}
          data-testid={testId}
          className={cx(styles.link, { [styles.active]: isActive })}
        >
          {badge && createElement(badge, { isActive })}
          <NavigationIcon
            inactiveIcon={inactiveIcon}
            activeIcon={activeIcon}
            isActive={isActive}
            className={iconClassName}
          />
        </a>
      }
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...tooltipHooksConfig}
    >
      {label}
    </Tooltip>
  );

  return (
    <li className={cx(styles.container, className)}>
      {tooltip && tooltip.content ? (
        <Tooltip
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...tooltip.props}
          className={styles.tooltip}
          triggerRenderer={() => item}
        >
          {createElement(tooltip.content)}
        </Tooltip>
      ) : (
        item
      )}
    </li>
  );
});
