import type { FC } from 'react';

import { useSelector } from 'react-redux';

import { getCartItemsCount } from 'store/entities/product-cart/selectors';

import { NavigationBadge } from './navigation-badge/NavigationBadge';

export const AppsBadge: FC = () => {
  const cartItemsCount = useSelector(getCartItemsCount);

  return cartItemsCount > 0 ? <NavigationBadge count={cartItemsCount} /> : null;
};
