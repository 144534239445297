// @ts-strict-ignore
import type { FC } from 'react';

import { useSelector } from 'react-redux';

import { getBillingInfo } from 'store/entities/billing/selectors';

import { CreditCardLogo } from './CreditCardLogo';

import * as styles from './styles';

const Bullets: FC = () => <span className={styles.bullets}>&bull;&bull;&bull;&bull;</span>;

export const CreditCard: FC = () => {
  const billingInfo = useSelector(getBillingInfo);

  if (!billingInfo) {
    return null;
  }

  const { lastFour, cardType } = billingInfo;

  return (
    <div className={styles.creditCard}>
      <p>Payment</p>

      <CreditCardLogo cardType={cardType} />

      <span>
        <Bullets />
        <Bullets />
        <Bullets />
        <span>{lastFour}</span>
      </span>
    </div>
  );
};
