/**
 * Facebook Messenger integration field names.
 * Available with values in `chat.properties.{client_id}.
 */
export enum FacebookMessengerProperty {
  FirstName = 'first_name',
  Gender = 'gender',
  LastName = 'last_name',
  Locale = 'locale',
  Name = 'name',
  PageId = 'page_id',
  PageName = 'page_name',
  PageScopedId = 'id',
  ProfilePicture = 'avatar_url',
  Timezone = 'timezone',
}

/**
 * Legacy field names for Facebook Messenger integration.
 * They're provided inside `custom_variables` field for threads.
 * To be replaced by `chat.properties.{client_id}` that resembles
 * specific integration.
 */
export enum FacebookMessengerCustomVariableProperty {
  FirstName = 'facebook.first_name',
  Gender = 'facebook.gender',
  LastName = 'facebook.last_name',
  Locale = 'facebook.locale',
  Name = 'facebook.name',
  PageId = 'facebook.page.id',
  PageName = 'facebook.page.name',
  PageScopedId = 'facebook.id',
  Prefix = 'facebook.',
  ProfilePicture = 'facebook.profile_pic',
  Timezone = 'facebook.timezone',
}

export interface IFacebookMessengerIntegration {
  [FacebookMessengerProperty.FirstName]?: string;
  [FacebookMessengerProperty.Gender]?: string;
  [FacebookMessengerProperty.LastName]?: string;
  [FacebookMessengerProperty.Locale]?: string;
  [FacebookMessengerProperty.Name]?: string;
  [FacebookMessengerProperty.PageId]?: string;
  [FacebookMessengerProperty.PageName]?: string;
  [FacebookMessengerProperty.PageScopedId]?: string;
  [FacebookMessengerProperty.ProfilePicture]?: string;
  [FacebookMessengerProperty.Timezone]?: string;
}

export const FACEBOOK_MESSENGER_PROPERTY_LABELS = {
  [FacebookMessengerProperty.FirstName]: 'First name',
  [FacebookMessengerProperty.Gender]: 'Gender',
  [FacebookMessengerProperty.LastName]: 'Last name',
  [FacebookMessengerProperty.Locale]: 'Locale',
  [FacebookMessengerProperty.Name]: 'Name',
  [FacebookMessengerProperty.PageId]: 'Page id',
  [FacebookMessengerProperty.PageName]: 'Page name',
  [FacebookMessengerProperty.PageScopedId]: 'Page scoped Facebook id',
  [FacebookMessengerProperty.ProfilePicture]: 'Profile picture',
  [FacebookMessengerProperty.Timezone]: 'Timezone',
};

export const FACEBOOK_MESSENGER_LEGACY_TO_PROPERTY_MAP = {
  [FacebookMessengerCustomVariableProperty.FirstName]: FacebookMessengerProperty.FirstName,
  [FacebookMessengerCustomVariableProperty.Gender]: FacebookMessengerProperty.Gender,
  [FacebookMessengerCustomVariableProperty.LastName]: FacebookMessengerProperty.LastName,
  [FacebookMessengerCustomVariableProperty.Locale]: FacebookMessengerProperty.Locale,
  [FacebookMessengerCustomVariableProperty.Name]: FacebookMessengerProperty.Name,
  [FacebookMessengerCustomVariableProperty.PageId]: FacebookMessengerProperty.PageId,
  [FacebookMessengerCustomVariableProperty.PageName]: FacebookMessengerProperty.PageName,
  [FacebookMessengerCustomVariableProperty.PageScopedId]: FacebookMessengerProperty.PageScopedId,
  [FacebookMessengerCustomVariableProperty.ProfilePicture]: FacebookMessengerProperty.ProfilePicture,
  [FacebookMessengerCustomVariableProperty.Timezone]: FacebookMessengerProperty.Timezone,
};
