import { css } from '@emotion/css';
import { SpacingToken } from '@livechat/design-system-react-components';

import { withVar } from 'helpers/styles';

export const wrapper = css`
  display: flex;
  flex-direction: column;
  padding-right: ${withVar(SpacingToken.Spacing2)};
`;

export const text = css`
  font-size: 14px;
  line-height: 1.3;
`;

export const icon = css`
  position: absolute;
  right: 0;
  top: 0;
  padding: ${withVar(SpacingToken.Spacing2)};
  cursor: pointer;
`;

export const link = css`
  text-decoration: underline;
  cursor: pointer;
  color: currentColor;
`;

export const description = css`
  margin: ${withVar(SpacingToken.Spacing2)} 0;
`;

export const header = css`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 6px;
`;
