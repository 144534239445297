import { type IInboxMessage, type INormalizedInboxMessage } from './inbox-message';
import { type IInboxMessageDenormalizer } from './inbox-message-denormalizer';
import { InboxMessageTitles } from './inbox-message-titles';

interface IPluginLoadedMessage extends IInboxMessage {
  readonly title: InboxMessageTitles.PluginLoaded;
}

function createPluginLoadedMessage(pluginId: string): IPluginLoadedMessage {
  return { title: InboxMessageTitles.PluginLoaded, pluginId };
}

export const PluginLoadedMessageDenormalizer: IInboxMessageDenormalizer<IPluginLoadedMessage> = {
  createMessage: (message: INormalizedInboxMessage) => createPluginLoadedMessage(message.plugin_id),
  messageTitle: InboxMessageTitles.PluginLoaded,
};
