import { useQuery } from '@tanstack/react-query';

import { QueryKey } from 'constants/query-key';
import { MINUTE_IN_MS } from 'constants/reply-suggestions';
import {
  type IKnowledgeSourceUrlItem,
  type KnowledgeSourceItem,
  KnowledgeSourceItemStatus,
} from 'interfaces/knowledge-sources';
import { ApiManager } from 'services/api/api-manager';
import type { IKnowledgeSourceUrlItemResponse, ListSkillsResponse } from 'services/api/ml-gateway/interfaces/ai-agents';

type UseReplySuggestionsSourcesList = {
  data?: KnowledgeSourceItem[];
  isLoading: boolean;
  isError: boolean;
};

const supportedStatuses = Object.values(KnowledgeSourceItemStatus);

function deserializeReplySuggestionsSourcesList(data: ListSkillsResponse): KnowledgeSourceItem[] {
  const list: KnowledgeSourceItem[] = [];

  for (const knowledgeSourceItem of data) {
    switch (knowledgeSourceItem.source_type) {
      case 'URL': {
        const knowledgeSourceUrlItem = knowledgeSourceItem as unknown as IKnowledgeSourceUrlItemResponse;
        const urlItem: IKnowledgeSourceUrlItem = {
          id: knowledgeSourceUrlItem.id,
          createdAt: knowledgeSourceUrlItem.created_at,
          customDescription: knowledgeSourceUrlItem.custom_description,
          depth: knowledgeSourceUrlItem.depth,
          name: knowledgeSourceUrlItem.name,
          pageCount: knowledgeSourceUrlItem.page_count,
          sourceType: knowledgeSourceUrlItem.source_type,
          status: knowledgeSourceItem.status,
          updatedAt: knowledgeSourceUrlItem.updated_at,
          learnedAt: knowledgeSourceUrlItem.learned_at,
          url: knowledgeSourceUrlItem.url,
          authorId: knowledgeSourceItem.created_by || null,
        };

        list.push(urlItem);

        break;
      }

      default: {
        continue;
      }
    }
  }

  return list
    .filter(({ status }) => supportedStatuses.includes(status))
    .sort((a, b) => new Date(b?.learnedAt ?? b.updatedAt).getTime() - new Date(a?.learnedAt ?? a.updatedAt).getTime());
}

interface IProps {
  withInterval?: boolean;
  refetchOnMount?: boolean;
}

export async function listSkills(): Promise<KnowledgeSourceItem[]> {
  const { result, error } = await ApiManager.mlGatewayApi.listSkills();

  if (error) {
    throw error;
  }

  return deserializeReplySuggestionsSourcesList(result);
}

export const useReplySuggestionsSourcesList = ({
  withInterval = false,
  refetchOnMount = false,
}: IProps = {}): UseReplySuggestionsSourcesList => {
  const { data, isLoading, isError } = useQuery([QueryKey.KnowledgeSourcesList], listSkills, {
    refetchOnMount,
    refetchOnWindowFocus: false,
    refetchInterval: (data) => {
      if (!withInterval) {
        return false;
      }

      const isProcessingAnyItemOnList = data?.some((item) =>
        [KnowledgeSourceItemStatus.IN_PROGRESS, KnowledgeSourceItemStatus.PENDING].includes(item.status)
      );

      if (isProcessingAnyItemOnList) {
        return MINUTE_IN_MS;
      }

      return false;
    },
  });

  return {
    data,
    isLoading,
    isError,
  };
};
