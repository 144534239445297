// @ts-strict-ignore
import { type IFacebookMessengerIntegration } from 'constants/integrations/facebook-messenger';
import { ChatChannel, getClientIdByChatChannel } from 'helpers/chat-channels';
import { getBrowserByUserAgent } from 'helpers/device-by-user-agent';
import { type KeyMap } from 'helpers/interface';
import { type GroupId } from 'interfaces/groups';
import { getChatIdByThreadId, type IWithChatsEntityState } from 'store/entities/chats/selectors';

import { type DeviceGeneralType, type ICustomerStatistics, type ICustomerVisitDetails } from '../common/interfaces';
import { getGreeting, type IWithGreetingsState } from '../greetings/selectors';
import { type EntityData } from '../interfaces';

import { type ICustomer, type ICustomersState, type IIntegrationVariables, type IVisitedPage } from './interfaces';

const EMPTY_OBJECT = {};
const EMPTY_ARRAY: string[] = [];

export interface IWithCustomersEntityState {
  entities: {
    customers: ICustomersState;
  };
}

export function getAllCustomers(state: IWithCustomersEntityState): KeyMap<ICustomer> {
  return state.entities.customers.byIds;
}

export function getAllCustomerIds(state: IWithCustomersEntityState): string[] {
  return state.entities.customers.allIds;
}

export function getAllCustomerIdsCount(state: IWithCustomersEntityState): number {
  return state.entities.customers.allIds.length;
}

export function getCustomer(state: IWithCustomersEntityState, customerId: string): ICustomer | undefined {
  return state.entities.customers.byIds[customerId] || state.entities.customers.removedByIds?.[customerId];
}

export function customerExists(state: IWithCustomersEntityState, customerId: string): boolean {
  return !!getCustomer(state, customerId);
}

export function isCustomerRemoved(state: IWithCustomersEntityState, customerId: string): boolean {
  return customerExists(state, customerId) && !!state.entities.customers.removedByIds?.[customerId];
}

export function getCustomerName(state: IWithCustomersEntityState, customerId: string): string {
  const customer = getCustomer(state, customerId);

  return customer?.name;
}

export function getCustomerEmail(state: IWithCustomersEntityState, customerId: string): string {
  const customer = getCustomer(state, customerId);

  return customer?.email;
}

export function getCustomerGroupIds(state: IWithCustomersEntityState, customerId: string): GroupId[] | undefined {
  const customer = getCustomer(state, customerId);

  return customer?.groupIds;
}

export function getCustomerIP(state: IWithCustomersEntityState, customerId: string): string {
  const customer = getCustomer(state, customerId);

  return customer?.ip;
}

export function getCustomerAvatarUrl(
  state: IWithCustomersEntityState & IWithChatsEntityState,
  customerId: string,
  threadId?: string
): string {
  const customer = getCustomer(state, customerId);
  if (customer?.avatarUrl) {
    return customer.avatarUrl;
  }

  if (!threadId) {
    return null;
  }

  const chatId = getChatIdByThreadId(state, threadId);

  const user = state.entities.chats.chatsUsers[chatId]?.find(({ id, avatar }) => id === customerId && !!avatar);

  if (user) {
    return user.avatar;
  }

  return null;
}

export function getCustomerRevokeChatId(state: IWithCustomersEntityState, customerId: string): string | undefined {
  const customer = getCustomer(state, customerId);

  return customer?.revokeChatId;
}

export function getCustomerVisitEndedTimestampInMs(
  state: IWithCustomersEntityState,
  customerId: string
): number | undefined {
  const customer = getCustomer(state, customerId);

  return customer?.visitDetails?.visitEndedTimestampInMs;
}

export function getCustomerVisitStartedTimestampInMs(
  state: IWithCustomersEntityState,
  customerId: string
): number | undefined {
  const customer = getCustomer(state, customerId);

  return customer?.visitDetails?.visitStartedTimestampInMs;
}

export function getAllCustomersVisitedPages(state: IWithCustomersEntityState): KeyMap<EntityData<IVisitedPage>> {
  return state.entities.customers.visitedPages;
}

function getCustomerVisitedPages(state: IWithCustomersEntityState, customerId: string): EntityData<IVisitedPage> {
  return state.entities.customers.visitedPages[customerId];
}

export function getAllVisitedPages(state: IWithCustomersEntityState, customerId: string): KeyMap<IVisitedPage> {
  const visitedPages = getCustomerVisitedPages(state, customerId);

  return visitedPages?.byIds || EMPTY_OBJECT;
}

export function getVisitedPagesIds(state: IWithCustomersEntityState, customerId: string): string[] {
  const visitedPages = getCustomerVisitedPages(state, customerId);

  return visitedPages?.allIds || EMPTY_ARRAY;
}

export function getLastVisitedPage(state: IWithCustomersEntityState, customerId: string): IVisitedPage {
  const visitedPages = getAllVisitedPages(state, customerId);
  const visitedPagesIds = getVisitedPagesIds(state, customerId);

  return visitedPagesIds.length > 0 && visitedPages[visitedPagesIds[visitedPagesIds.length - 1]];
}

export function getIntegrationVariables(
  state: IWithCustomersEntityState,
  customerId: string
): KeyMap<IIntegrationVariables> | undefined {
  const customer = getCustomer(state, customerId);

  return customer?.integrationVariables;
}

export function getCustomerFacebookData(
  state: IWithCustomersEntityState,
  customerId: string
): IFacebookMessengerIntegration {
  const customer = getCustomer(state, customerId);
  const facebookMessengerClientId = getClientIdByChatChannel(ChatChannel.FacebookMessenger);

  return customer?.integrations?.[facebookMessengerClientId];
}

export function getCustomerStatistics(state: IWithCustomersEntityState, customerId: string): ICustomerStatistics {
  const customer = getCustomer(state, customerId);

  return customer?.statistics;
}

export function getCustomerVisitDetails(state: IWithCustomersEntityState, customerId: string): ICustomerVisitDetails {
  const customer = getCustomer(state, customerId);

  return customer?.visitDetails;
}

export function getCustomVariables(state: IWithCustomersEntityState, customerId: string): KeyMap<string> | undefined {
  const customer = getCustomer(state, customerId);

  return customer?.customVariables;
}

export function getCustomerBrowserName(state: IWithCustomersEntityState, customerId: string): string {
  const customer = getCustomer(state, customerId);
  const customerUserAgent = customer?.visitDetails?.userAgent;

  return customerUserAgent && getBrowserByUserAgent(customerUserAgent).name;
}

export function getCustomerBrowserDisplayName(state: IWithCustomersEntityState, customerId: string): string {
  const customer = getCustomer(state, customerId);

  return customer?.visitDetails?.browserName;
}

export function getCustomerOperatingSystem(state: IWithCustomersEntityState, customerId: string): string {
  const customer = getCustomer(state, customerId);

  return customer?.visitDetails?.operatingSystem;
}

export function getCustomerChatsCount(state: IWithCustomersEntityState, customerId: string): number {
  const customer = getCustomer(state, customerId);

  return customer?.statistics?.chatsCount;
}

export function getCustomerCity(state: IWithCustomersEntityState, customerId: string): string {
  const customer = getCustomer(state, customerId);

  return customer?.geolocation?.city;
}

export function getCustomerCountry(state: IWithCustomersEntityState, customerId: string): string {
  const customer = getCustomer(state, customerId);

  return customer?.geolocation?.country;
}

export function getCustomerCountryState(state: IWithCustomersEntityState, customerId: string): string {
  const customer = getCustomer(state, customerId);

  return customer?.geolocation?.state;
}

export function getCustomerCountryCode(state: IWithCustomersEntityState, customerId: string): string {
  const customer = getCustomer(state, customerId);

  return customer?.geolocation?.countryCode;
}

export function getCustomerDeviceType(
  state: IWithCustomersEntityState,
  customerId: string
): DeviceGeneralType | undefined {
  const customer = getCustomer(state, customerId);

  return customer?.visitDetails?.deviceType;
}

export function getCustomerAcceptedGreetingsCount(state: IWithCustomersEntityState, customerId: string): number {
  const customer = getCustomer(state, customerId);

  return customer?.statistics?.acceptedGreetingsCount;
}

export function getCustomerGreetingsCount(state: IWithCustomersEntityState, customerId: string): number {
  const customer = getCustomer(state, customerId);

  return customer?.statistics?.greetingsCount;
}

export function getCustomerIgnoredGreetingsCount(state: IWithCustomersEntityState, customerId: string): number {
  const greetingsCount = getCustomerGreetingsCount(state, customerId);
  const acceptedGreetingsCount = getCustomerAcceptedGreetingsCount(state, customerId);

  return greetingsCount - acceptedGreetingsCount;
}

export function getCustomerTargetedMessage(
  state: IWithCustomersEntityState & IWithGreetingsState,
  customerId: string
): string | undefined {
  const customer = getCustomer(state, customerId);
  const messageId = customer?.visitDetails?.targetedMessageId;
  const targetedMessage = getGreeting(state, messageId);

  return targetedMessage?.name;
}

export function getCustomerVisitsCount(state: IWithCustomersEntityState, customerId: string): number {
  const customer = getCustomer(state, customerId);

  return customer?.statistics?.visitsCount;
}

export function getIsCustomerReturning(state: IWithCustomersEntityState, customerId: string): boolean {
  const visitsCount = getCustomerVisitsCount(state, customerId);

  return visitsCount > 1;
}

export function getCustomerLastVisitTimestamp(state: IWithCustomersEntityState, customerId: string): number {
  const customer = getCustomer(state, customerId);

  return customer?.visitDetails?.lastVisitTimestampInMs;
}

export function getCustomerCameFromUrl(state: IWithCustomersEntityState, customerId: string): string {
  const customer = getCustomer(state, customerId);

  return customer?.visitDetails?.cameFromURL;
}
