import { css } from '@emotion/css';
import { DesignToken, RadiusToken, SpacingToken } from '@livechat/design-system-react-components';
import { ellipsis } from 'polished';

import { withVar } from 'helpers/styles';
import { type SourceType } from 'store/entities/one/interfaces';

export const navigateButton = css`
  margin: 0 var(${SpacingToken.Spacing4}) 0 0;
`;

export const actionBtn = css`
  margin-right: var(${SpacingToken.Spacing1});
`;

export const bottomBarContainer = css`
  min-height: 24px;
  width: 100%;
`;

export const bottomBar = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  margin: var(${SpacingToken.Spacing2}) var(${SpacingToken.Spacing4}) 0 var(${SpacingToken.Spacing4});

  &:hover .tooltip {
    opacity: 1;
    visibility: visible;
  }
`;

export const sourcesList = css`
  margin: var(${SpacingToken.Spacing3}) 0 0 var(${SpacingToken.Spacing3});
`;

export const bottomBarIcons = css`
  display: flex;
`;

export const sourcesButton = css`
  background: none;
  border-radius: var(${RadiusToken[3]});
  padding: var(${SpacingToken.Spacing05}) var(${SpacingToken.Spacing2}) var(${SpacingToken.Spacing05}) 6px;

  color: var(${DesignToken.ContentBasicSecondary});

  &:hover {
    background: none;
  }
`;

export const sourcesButtonActive = css`
  color: var(${DesignToken.ContentBasicPrimary});
`;

export const button = css`
  border: none;
  background: none;
  padding: 0;
  margin: 0;

  svg {
    color: var(${DesignToken.ContentBasicSecondary});
  }

  &:hover {
    cursor: pointer;
    svg {
      color: var(${DesignToken.ContentBasicPrimary});
    }
  }
`;

export const tooltip = css`
  width: auto;
  font-size: 14px;
  text-align: center;
`;

export const linkContainer = (type: SourceType): string => css`
  display: flex;
  align-items: center;
  margin-bottom: var(${SpacingToken.Spacing2});

  &:hover {
    cursor: pointer;
  }

  div:first-child {
    width: 100%;
  }

  span {
    color: var(${DesignToken.ContentBasicPrimary});
    background-color: ${withVar(
      type === 'url' ? DesignToken.SurfacePrimaryActiveColored : DesignToken.SurfaceTertiaryDefault
    )};
    width: 24px;
    height: 24px;
    padding: var(${SpacingToken.Spacing1});
    border-radius: var(${RadiusToken[1]});
    display: flex;
    margin-right: var(${SpacingToken.Spacing2});
  }
`;

export const tooltipSourceLink = css`
  font-size: 13px;
  font-weight: 400;
`;

export const defaultCursor = css`
  &:hover {
    cursor: default;
  }

  &:active,
  &:focus {
    box-shadow: none;
  }
`;

export const moreSourcesText = css`
  color: var(${DesignToken.ContentInvertDefault});
`;

export const urlSourceName = css`
  display: flex;
  align-items: center;
  max-width: 300px;
`;

export const sourceMaxWidth = css`
  max-width: 712px;
`;

export const sourceItem = css`
  color: var(${DesignToken.ContentBasicPrimary});
  font-size: 13px;
  font-weight: 400;
  text-decoration-line: underline;
  line-height: 22px;
  ${ellipsis()}
  cursor: pointer;
`;

export const openInNewIcon = css`
  background-color: unset !important;
  margin: 0 0 var(${SpacingToken.Spacing1}) var(${SpacingToken.Spacing2}) !important;
  width: 20px !important;
  height: 20px !important;
  padding: 0 !important;
`;

export const sourcesContainer = css`
  display: flex;
  margin-top: var(${SpacingToken.Spacing1});
  justify-content: center;
  align-items: center;
`;

export const sourcesBadgeActive = css`
  color: var(${DesignToken.ContentBasicPrimary});
`;
