import { useState, type FC, type ChangeEvent } from 'react';

import { Input } from '@livechat/design-system-react-components';

import { useResourceContext } from '../../hooks/use-resource-context';

const RESOURCE_QUANTITY_MIN_VALUE = 1;
const RESOURCE_QUANTITY_MAX_VALUE = 999;

interface IProps {
  editable?: boolean;
  onUpdateQuantity?(quantity: number): void;
}

export const QuantityCell: FC<IProps> = ({ onUpdateQuantity, editable }) => {
  const { multiplied, isActive, quantity: quantitySum } = useResourceContext();
  const [quantity, setQuantity] = useState(1);

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    let updatedQuantity = Number(e.target.value);

    if (!updatedQuantity || updatedQuantity < RESOURCE_QUANTITY_MIN_VALUE) {
      updatedQuantity = 1;
    } else if (updatedQuantity > RESOURCE_QUANTITY_MAX_VALUE) {
      updatedQuantity = RESOURCE_QUANTITY_MAX_VALUE;
    }

    if (onUpdateQuantity) {
      onUpdateQuantity(updatedQuantity);
    }

    setQuantity(updatedQuantity);
  };

  if ((isActive && !multiplied) || (editable && !multiplied)) {
    return <p>1</p>;
  }

  if (!quantitySum && !multiplied) {
    return <p>n/a</p>;
  }

  if (!editable) {
    return <p>{quantitySum || 'n/a'}</p>;
  }

  return (
    <Input
      min={RESOURCE_QUANTITY_MIN_VALUE}
      max={RESOURCE_QUANTITY_MAX_VALUE}
      id="resource-quantity"
      type="number"
      value={quantity}
      onChange={handleChange}
    />
  );
};
