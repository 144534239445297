import { type FC, useState, useCallback } from 'react';

import { Button } from '@livechat/design-system-react-components';

import { PostMessageEvent } from 'constants/post-message-event';
import { ToastContent } from 'constants/toasts';
import { sendPostMessageToMarketplace } from 'helpers/post-message';
import { showToast } from 'helpers/toast';
import { handleDirectInstallationUrlFlow } from 'routes/apps/apps-settings/manage-apps/components/actions-dropdown/helpers';
import { useOneClickPaymentContext } from 'routes/apps/one-click-payment/hooks/use-one-click-payment-context';
import { ToastVariant } from 'store/features/toasts/interfaces';

import * as styles from './styles';

export const ActivateProductFooter: FC = () => {
  const [isActivating, setIsActivating] = useState(false);
  const { closeModal, item } = useOneClickPaymentContext();

  const activateProduct = useCallback(() => {
    if (!item) return;

    setIsActivating(true);

    handleDirectInstallationUrlFlow({
      clientId: item.authorization?.clientId,
      directInstallUrl: item?.directInstallUrl,
      name: item.name,
      onClose: () => {
        sendPostMessageToMarketplace(PostMessageEvent.CheckOnboarding);
        showToast(ToastVariant.Success, ToastContent.ONE_CLICK_PAYMENT_ACTIVATED);
        closeModal();
      },
    });
  }, [item, closeModal]);

  return (
    <div className={styles.footerWrapper}>
      <Button kind="secondary" onClick={closeModal}>
        Cancel
      </Button>
      <Button kind="primary" loading={isActivating} loaderLabel="Activating..." onClick={activateProduct}>
        Activate app
      </Button>
    </div>
  );
};
