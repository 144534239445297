import { type FC } from 'react';

import { cx } from '@emotion/css';
import { useSelector } from 'react-redux';

import { getIsOneModalExpanded } from 'store/views/one/selectors';

import { messageBox, popoverMessageBox } from '../styles';

import { OneMessageBoxBottomBar } from './OneMessageBoxBottomBar';
import { OneMessageBoxPlugins } from './OneMessageBoxPlugins';
import { OneMessageBoxWrapper } from './OneMessageBoxWrapper';

import { popoverMessageBoxWrapper } from './styles';

export const OneMessageBox: FC = () => {
  const isExpanded = useSelector(getIsOneModalExpanded);

  return (
    <div className={cx({ [popoverMessageBox]: !isExpanded, [messageBox]: isExpanded })}>
      <OneMessageBoxWrapper className={cx({ [popoverMessageBoxWrapper]: !isExpanded })}>
        <>
          <OneMessageBoxPlugins isExpanded={isExpanded} />
          <OneMessageBoxBottomBar isExpanded={isExpanded} />
        </>
      </OneMessageBoxWrapper>
    </div>
  );
};
