import { type FC, type ReactNode, useCallback, useMemo } from 'react';

import { cx } from '@emotion/css';
import { Button } from '@livechat/design-system-react-components';

import { getReconnector } from 'services/connectivity/reconnector/service';
import { ReconnectionStatus } from 'services/connectivity/reconnector/types';

import {
  CONNECTION_STATUS_OFFLINE_BAR_TEXT,
  CONNECTION_STATUS_RECONNECTING_BAR_TEXT,
  CONNECTION_STATUS_REDIRECTING_BAR_TEXT,
} from '../constants';
import { useReconnectionDetails } from '../hooks/use-reconnection-details';
import * as styles from '../styles';

import { useSecondsUntilNextReconnect } from './hooks/use-seconds-until-next-reconnect';

const getOfflineMessage = (seconds: number): string =>
  `You're offline. Trying to reconnect in ${seconds === 1 ? 'less than a second.' : `${seconds} s.`}`;

export const ReconnectingBar: FC = () => {
  const { status, allowManual, nextReconnectAttemptTimestamp } = useReconnectionDetails();
  const secondsTillNextReconnect = useSecondsUntilNextReconnect(nextReconnectAttemptTimestamp);

  const handleManualReconnect = useCallback((): void => {
    const reconnector = getReconnector();
    void reconnector.reconnect({ reason: 'manual' });
  }, []);

  const getStatus = useCallback((): ReactNode => {
    if (status === ReconnectionStatus.Scheduled && secondsTillNextReconnect) {
      return getOfflineMessage(secondsTillNextReconnect);
    }

    if (status === ReconnectionStatus.Retrying) {
      return CONNECTION_STATUS_RECONNECTING_BAR_TEXT;
    }

    if (status === ReconnectionStatus.Failed) {
      if (allowManual) {
        return CONNECTION_STATUS_OFFLINE_BAR_TEXT;
      }

      // we assume that if we don't allow manual reconnect
      // then we will redirect agent to a dedicated error page
      return CONNECTION_STATUS_REDIRECTING_BAR_TEXT;
    }
  }, [allowManual, secondsTillNextReconnect, status]);

  const isReconnectButtonDisplayed = useMemo(
    () => status === ReconnectionStatus.Failed && allowManual,
    [allowManual, status]
  );

  return (
    <div className={cx(styles.contentContainer, styles.warningContainer)}>
      <span>{getStatus()}</span>{' '}
      {isReconnectButtonDisplayed && (
        <Button tabIndex={-1} className={styles.button} kind="secondary" onClick={handleManualReconnect} size="compact">
          Retry
        </Button>
      )}
    </div>
  );
};
