import { type IFetchUpgradesResponse, type IResourceDefinition } from 'services/api/in-app-upgrades/interfaces';
import {
  type IInAppUpgrade,
  type IResourcePrice,
  type IResourceTransaction,
} from 'store/entities/applications/interfaces';

interface IParsedResourceDefinition {
  name: string;
  appId: string;
  resourceId: string;
  description: string;
  multiplied: boolean;
  price: IResourcePrice | null;
  trialDays: number;
  payPerAgent: boolean;
}

const parseDefinition = (definition: IResourceDefinition): IParsedResourceDefinition => {
  const { id, description, product, multiplied, application } = definition || {};
  const { name, price } = product || {};

  return {
    name,
    description,
    multiplied,
    appId: application,
    resourceId: id,
    price: price || null,
    trialDays: price?.trialDays || 0,
    payPerAgent: price?.perAgent || false,
  };
};

export const getActiveTransactions = (transactions: IResourceTransaction[] = []): IResourceTransaction[] => {
  return transactions?.filter((transaction) => {
    const { enabled, disabled } = transaction.switch;

    return enabled && !disabled;
  });
};

const sumActiveTransactionsQuantity = (transactions: IResourceTransaction[] = []): number => {
  return transactions.reduce((acc, { quantity }) => acc + quantity, 0);
};

export interface INormalizedUpgrades {
  all: IInAppUpgrade[];
  purchased: IInAppUpgrade[];
}

export const normalizeUpgrades = (upgrades: IFetchUpgradesResponse[] = []): INormalizedUpgrades =>
  upgrades
    .sort((a, b) => (a.definition.product.name > b.definition.product.name ? 1 : -1))
    .reduce(
      (acc: INormalizedUpgrades, { id, transactions, definition }) => {
        const upgradeDetails = parseDefinition(definition);
        const activeTransactions = getActiveTransactions(transactions);
        const quantity = sumActiveTransactionsQuantity(activeTransactions);
        const isActive = activeTransactions?.length > 0;

        const upgrade: IInAppUpgrade = {
          isActive,
          quantity,
          upgradeId: id || null,
          transactions: activeTransactions,
          ...upgradeDetails,
        };

        acc.all.push(upgrade);

        if (isActive) {
          acc.purchased.push(upgrade);
        }

        return acc;
      },
      { all: [], purchased: [] }
    );
