import type { LogicOperator } from 'constants/logic-operator';
import type { SortOrder } from 'constants/sort-order';
import type { KeyMap } from 'helpers/interface';
import type { Filter } from 'interfaces/components/filter/filter';
import type { CustomSegment, CustomSegmentValue } from 'interfaces/custom-segment';
import type { IAgentBase } from 'interfaces/entities/agent-base';
import type { Group } from 'interfaces/groups';
import type { ChatThreadEntity } from 'store/entities/chats/interfaces';
import type { ICustomer, IVisitedPage } from 'store/entities/customers/interfaces';
import type { IGreeting } from 'store/entities/greetings/interfaces';
import type { EntityData } from 'store/entities/interfaces';

export const enum TrafficColumn {
  Actions = 'Actions',
  Activity = 'Activity',
  Browser = 'Browser',
  ChatsCount = 'ChatsCount',
  ChattingWith = 'ChattingWith',
  City = 'City',
  Country = 'Country',
  CustomerId = 'CustomerId',
  Device = 'Device',
  Email = 'Email',
  GreetingsAccepted = 'GreetingsAccepted',
  GreetingsIgnored = 'GreetingsIgnored',
  GreetingsSent = 'GreetingsSent',
  Groups = 'Groups',
  IP = 'IP',
  LastGreeting = 'LastGreeting',
  LastPage = 'LastPage',
  LastSeen = 'LastSeen',
  Name = 'Name',
  OperatingSystem = 'OperatingSystem',
  Referrer = 'Referrer',
  State = 'State',
  VisitingTime = 'VisitingTime',
  VisitsCount = 'VisitsCount',
  Intent = 'Intent',
}

export const enum FilterType {
  Activity = 'activity',
  AssignedAgent = 'assigned-agent',
  Country = 'country',
  Group = 'group',
  Intent = 'intent',
  ReturningCustomer = 'returning-customer',
  NumberOfVisits = 'number-of-visits',
  WebCrawlers = 'web-crawlers',
  IP = 'ip',
  Name = 'name',
  Email = 'email',
  City = 'city',
  State = 'state',
  CameFrom = 'came-from',
  LastPageTitle = 'last-page-title',
}

export const enum FilterValueType {
  String = 'String',
  Numeric = 'Numeric',
  SetInclusive = 'SetInclusive',
  Boolean = 'Boolean',
  IPRange = 'IPRange',
}

export const enum FilterStringOperator {
  IsExactly = 'is exactly',
  IsNot = 'is not',
  Contains = 'contains',
  DoesNotContain = 'does not contain',
  HasAnyValue = 'has any value',
}

export const enum FilterNumericOperator {
  IsExactly = 'is exactly',
  IsNot = 'is not',
  IsGreaterThan = 'is greater than',
  IsGreaterThanOrEqualTo = 'is greater than or equal to',
  IsLessThan = 'is less than',
  IsLessThanOrEqualTo = 'is less than or equal to',
  IsBetween = 'is between',
}

export const enum FilterBooleanOperator {
  IsTrue = 'is true',
  IsFalse = 'is false',
}

export const enum FilterIpOperator {
  IsNotMy = 'is not my',
  IsNotOther = 'is not other',
}

export enum FilterSetOperator {
  Is = 'is',
  IsNot = 'is not',
}

type StringFilterValue =
  | {
      operator: Exclude<FilterStringOperator, FilterStringOperator.HasAnyValue>;
      value: string;
    }
  | {
      operator: FilterStringOperator.HasAnyValue;
    };

type NumericFilterValue =
  | {
      operator: Exclude<FilterNumericOperator, FilterNumericOperator.IsBetween>;
      value: number;
    }
  | { operator: FilterNumericOperator.IsBetween; firstValue: number; secondValue: number };

type BooleanFilterValue = { operator: FilterBooleanOperator };

type IpFilterValue = { operator: FilterIpOperator; value: string };

type SetInclusiveFilterValue = {
  operator: FilterSetOperator;
  value: string[];
};

type FilterBase = {
  id: string;
  filterType: FilterType;
};

type StringFilter = { valueType: FilterValueType.String } & StringFilterValue & FilterBase;

type NumericFilter = { valueType: FilterValueType.Numeric } & NumericFilterValue & FilterBase;

type BooleanFilter = { valueType: FilterValueType.Boolean } & BooleanFilterValue & FilterBase;

type IpFilter = { valueType: FilterValueType.IPRange } & IpFilterValue & FilterBase;

export type SetInclusiveFilter = { valueType: FilterValueType.SetInclusive } & SetInclusiveFilterValue & FilterBase;

export type TrafficFilter = StringFilter | NumericFilter | BooleanFilter | IpFilter | SetInclusiveFilter;

export interface IRefreshCustomersPayload {
  customers: KeyMap<ICustomer>;
  allThreads: KeyMap<ChatThreadEntity>;
  visitedPages: KeyMap<EntityData<IVisitedPage>>;
  allGroups: KeyMap<Group>;
  allAgents: KeyMap<IAgentBase>;
  allTargetedMessages: KeyMap<IGreeting>;
  refreshTimestampInMs: number;
  currentAgentId: string;
  segments: KeyMap<CustomSegment>;
  selectedSegmentId: string;
}

export interface IResizeColumnPayload {
  column: TrafficColumn;
  width: number;
}

export interface ISortColumnPayload {
  sortedByColumn: TrafficColumn;
  sortOrder: SortOrder;
}

interface IWithFilterId {
  id: string;
}

interface IWithFilter {
  filter: TrafficFilter;
}

export interface IAddFilterPayload extends IWithFilter {
  addAsDraft: boolean;
}

export interface IRemoveFilterPayload extends IWithFilterId {}

export interface IUpdateFilterPayload extends IWithFilter {}

export interface IChangeOperatorPayload {
  operator: LogicOperator;
}

export interface IApplySegmentFilterPayload {
  segmentValue: CustomSegmentValue;
}

export interface IColumnsVisibilityPayload {
  visibleColumns?: TrafficColumn[];
  hiddenColumns?: TrafficColumn[];
}

export interface IColumnsOrderPayload {
  columnsOrder: TrafficColumn[];
}

export interface ISetColumnsVisibilityPayload extends IColumnsVisibilityPayload {}

export interface ISaveColumnsVisibilityPayload extends IColumnsVisibilityPayload {}

export interface ISetColumnsOrderPayload extends IColumnsOrderPayload {}

export interface ISaveColumnsOrderPayload extends IColumnsOrderPayload {}

export interface ISetCustomMessageModalStatePayload {
  isOpen: boolean;
  customerId?: string;
}

export interface ISetIntentCampaignModalStatePayload {
  isOpen: boolean;
  customerId?: string;
}

export interface IUpdateCustomersCountersPayload {
  customers: KeyMap<ICustomer>;
  allThreads: KeyMap<ChatThreadEntity>;
}

interface ILegacySegmentFilter {
  type: Filter['type'];
  filterValues: Filter['filterValues'];
}

export interface ILegacySegment {
  id: string;
  label: string;
  filters: ILegacySegmentFilter[];
  operator: LogicOperator;
}

export interface ITrafficViewState {
  customersList: ICustomer[];
  sortedByColumn: TrafficColumn;
  sortOrder: SortOrder;
  filterOperator?: LogicOperator;
  filters: TrafficFilter[];
  segmentsCounters?: KeyMap<number>;
  isLastFilterADraft?: boolean;
  visibleColumns: TrafficColumn[];
  hiddenColumns?: TrafficColumn[];
  columnsOrder: TrafficColumn[];
  isCustomMessageModalOpened?: boolean;
  customMessageModalCustomerId?: string;
  customersOnlineCount: number;
  isIntentCampaignModalOpen?: boolean;
  intentCampaignModalCustomerId?: string;
}
