// @ts-strict-ignore
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/naming-convention */
import union from 'lodash.union';
import { type SagaIterator } from 'redux-saga';
import { all, call, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';

import { type SectionName } from 'constants/section-name';
import { TeamEvent } from 'constants/team/event';
import { getGreetingIdFromClickedPropertyName, getGreetingIdFromSkippedPropertyName } from 'helpers/agent-custom-properties';
import { EventPlace } from 'helpers/analytics';
import { anyToBoolean } from 'helpers/boolean';
import { type KeyMap } from 'helpers/interface';
import type { RequestResult } from 'interfaces/api/client';
import { AmplitudeUserProperty, setUserPropertiesForAmplitude } from 'services/amplitude';
import { ApiManager } from 'services/api/api-manager';
import { trackEvent } from 'services/event-tracking';
import { RequestAction } from 'store/entities/actions';
import { getLoggedInAgentLogin, getLoggedInAgentRole } from 'store/entities/agents/selectors';
import { getIsNonProfit } from 'store/entities/license-properties/selectors';
import { getIsCodeInstalled } from 'store/features/code-installation/selectors';
import { getLicenseId, getSubscriptionType, getTrialDay } from 'store/features/session/selectors';
import { type IActionWithPayload } from 'store/helper';

import { AGENT_CUSTOM_PROPERTIES, AgentCustomPropertiesActions } from './actions';
import {
  AgentCustomPropertyName,
  type AgentCustomPropertyDataType,
  type IDeleteAgentCustomPropertyPayload,
  type ISetAgentCustomPropertyPayload,
  type IUpdateSectionDetailsOpen,
} from './interfaces';
import {
  getAgentCustomProperties,
  getChatWindowClickedGreetingCount,
  getChatWindowSkippedGreetingCount,
  getSectionsWithDetailsOpen,
  hasFetchedAgentCustomProperties,
} from './selectors';

export function* handleSetCustomProperty(payload: ISetAgentCustomPropertyPayload): SagaIterator {
  const propertiesToSet = [];

  Object.keys(payload).forEach((key): void => {
    const value = typeof payload[key] !== 'string' ? JSON.stringify(payload[key]) : payload[key];
    propertiesToSet.push([key, value]);
  });

  const currentUserLogin: string = yield select(getLoggedInAgentLogin);
  const responses: RequestResult<never>[] = yield all(
    propertiesToSet.map((prop) => call(ApiManager.agentPropertiesApi.setCustom, prop[0], prop[1], currentUserLogin))
  );

  const responsesErrors = responses
    .map((res) => {
      if (res.error) {
        if (res.error.message) {
          return res.error.message;
        }

        if (res.error.errors) {
          return res.error.errors.join('. ');
        }
      }

      return null;
    })
    .filter(Boolean);

  if (responsesErrors.length > 0) {
    const combinedErrors = responsesErrors
      .reduce((acc, error): string[] => (error ? [...acc, error] : acc), [])
      .join('. ');

    const customPropertyIdentifier = propertiesToSet[0][0];

    yield put(
      AgentCustomPropertiesActions.setAgentCustomPropertyFailure({ error: combinedErrors, customPropertyIdentifier })
    );
  } else {
    const themePropIndex = propertiesToSet.findIndex((prop) => prop[0] === AgentCustomPropertyName.CurrentTheme);
    if (themePropIndex > -1) {
      const theme = propertiesToSet[themePropIndex][1];
      setUserPropertiesForAmplitude({ [AmplitudeUserProperty.Theme]: theme });
    }

    const chatListSentimentPropIndex = propertiesToSet.findIndex(
      (prop) => prop[0] === AgentCustomPropertyName.ChatsListSentiment
    );

    if (chatListSentimentPropIndex > -1) {
      const chatListSentiment = propertiesToSet[chatListSentimentPropIndex][1];
      setUserPropertiesForAmplitude({ [AmplitudeUserProperty.Sentiment]: chatListSentiment });
    }

    const firstProperty = propertiesToSet[0];
    if (firstProperty && firstProperty[0] === AgentCustomPropertyName.TimeFormat) {
      trackEvent(TeamEvent.TimeFormatChanged, EventPlace.Team, { value: firstProperty[1] });
    }

    yield put(AgentCustomPropertiesActions.setAgentCustomPropertySuccess({ values: payload }));
  }
}

export function* deleteCustomProperty({
  payload,
}: IActionWithPayload<string, IDeleteAgentCustomPropertyPayload>): SagaIterator {
  const { id } = payload;

  const currentUserLogin: string = yield select(getLoggedInAgentLogin);
  const response: RequestResult<never> = yield call(ApiManager.agentPropertiesApi.expireCustom, id, currentUserLogin);
  if (response.result) {
    yield put(AgentCustomPropertiesActions.deleteAgentCustomPropertySuccess({ id }));
  } else {
    yield put(AgentCustomPropertiesActions.deleteAgentCustomPropertyFailure({ error: response.error.message }));
  }
}

export function* setCustomProperty(action: IActionWithPayload<string, ISetAgentCustomPropertyPayload>): SagaIterator {
  const { payload } = action;
  yield call(handleSetCustomProperty, payload);
}

export function* fetchCustomProperties(): SagaIterator {
  const currentUserLogin: string = yield select(getLoggedInAgentLogin);
  const response: RequestResult<Record<string, string>> = yield call(
    ApiManager.agentPropertiesApi.fetchAllCustom,
    currentUserLogin
  );

  if (response.result) {
    const deserializedData: KeyMap<AgentCustomPropertyDataType> = {};
    Object.keys(response.result).forEach((key): void => {
      const value = response.result[key];

      try {
        const parsed = JSON.parse(value);
        deserializedData[key] = Array.isArray(parsed) ? parsed.filter(Boolean) : parsed;
      } catch (e) {
        deserializedData[key] = value;
      }
    });

    setUserPropertiesForAmplitude({
      [AmplitudeUserProperty.Theme]: deserializedData.current_theme as string,
      [AmplitudeUserProperty.Sentiment]: anyToBoolean(deserializedData.chat_list_sentiment),
    });

    yield put(AgentCustomPropertiesActions.fetchAgentCustomPropertiesSuccess(deserializedData));
  } else {
    yield put(AgentCustomPropertiesActions.fetchAgentCustomPropertiesFailure({ error: response.error.message }));
  }
}

export function* updateSectionDetailsOpen(action: IActionWithPayload<string, IUpdateSectionDetailsOpen>): SagaIterator {
  const { section, isOpen } = action.payload;
  const sectionsWithDetailsOpen: string[] = yield select(getSectionsWithDetailsOpen);

  const sectionList = isOpen
    ? union(sectionsWithDetailsOpen, [section])
    : sectionsWithDetailsOpen.filter((s: SectionName): boolean => s !== section);

  const propertyToUpdate = {
    [AgentCustomPropertyName.SectionDetailsOpen]: sectionList,
  };

  yield call(handleSetCustomProperty, propertyToUpdate);
}

export function* setCWInitialCustomVariables(action: IActionWithPayload<string, KeyMap>): SagaIterator {
  const hasFetchedCustomProps = yield select(hasFetchedAgentCustomProperties);
  if (!hasFetchedCustomProps) {
    yield take(AGENT_CUSTOM_PROPERTIES.FETCH_AGENT_CUSTOM_PROPERTIES[RequestAction.SUCCESS]);
  }

  const agentCustomProperties: KeyMap<string | string[]> = yield select(getAgentCustomProperties);
  const greetingsSkipped = agentCustomProperties[AgentCustomPropertyName.ChatWindowGreetingsSkipped] || 0;
  const state = yield select();

  const greetingsSkippedById = Object.keys(agentCustomProperties).reduce((prevValue, propertyName): KeyMap<string> => {
    const greetingId = getGreetingIdFromSkippedPropertyName(propertyName as AgentCustomPropertyName);
    const chatWindowCustomVariableName = `og_cv_${greetingId}_dismissed`;
    if (greetingId) {
      return { ...prevValue, [chatWindowCustomVariableName]: getChatWindowSkippedGreetingCount(state, greetingId) };
    }

    return prevValue;
  }, {});

  const greetingsClickedById = Object.keys(agentCustomProperties).reduce((prevValue, propertyName): KeyMap<string> => {
    const greetingId = getGreetingIdFromClickedPropertyName(propertyName as AgentCustomPropertyName);
    const chatWindowCustomVariableName = `og_cv_${greetingId}_clicked`;
    if (greetingId) {
      return { ...prevValue, [chatWindowCustomVariableName]: getChatWindowClickedGreetingCount(state, greetingId) };
    }

    return prevValue;
  }, {});

  const metrics: KeyMap = action.payload;

  const utmSource = metrics.utm_source || null;
  const utmCampaign = metrics.utm_campaign || null;
  const utmMedium = metrics.utm_medium || null;

  yield call(window.LiveChatWidget.call, 'update_session_variables', {
    greetings_skipped: greetingsSkipped,
    og_cv_day: getTrialDay(state),
    og_cv_account_type: getSubscriptionType(state),
    og_cv_permission: getLoggedInAgentRole(state), // GLOBAL ACCOUNTS TODO: ROLE SCOPE CHECK
    og_cv_code_installed: getIsCodeInstalled(state),
    og_cv_nonprofit: getIsNonProfit(state),
    og_cv_license: getLicenseId(state),
    og_cv_utm_source: utmSource,
    og_cv_utm_campaign: utmCampaign,
    og_cv_utm_medium: utmMedium,
    ...greetingsSkippedById,
    ...greetingsClickedById,
  });
}

export function* fetchMetrics(): SagaIterator {
  const { result }: RequestResult<KeyMap> = yield call(ApiManager.licensePropertiesApi.fetchMetrics);
  if (result && result.result) {
    yield put(AgentCustomPropertiesActions.fetchMerticsSuccess(result.result));
  } else {
    yield put(AgentCustomPropertiesActions.fetchMerticsFailure());
  }
}

export default function* agentCustomPropertiesSagas(): SagaIterator {
  yield all([
    takeLatest('APP_READY', fetchCustomProperties),
    takeEvery(AGENT_CUSTOM_PROPERTIES.DELETE_AGENT_CUSTOM_PROPERTY[RequestAction.REQUEST], deleteCustomProperty),
    takeEvery(AGENT_CUSTOM_PROPERTIES.SET_AGENT_CUSTOM_PROPERTY[RequestAction.REQUEST], setCustomProperty),
    takeEvery(AGENT_CUSTOM_PROPERTIES.FETCH_AGENT_CUSTOM_PROPERTIES[RequestAction.REQUEST], fetchCustomProperties),
    takeEvery(AGENT_CUSTOM_PROPERTIES.UPDATE_SECTION_DETAILS_OPEN, updateSectionDetailsOpen),
    takeEvery(AGENT_CUSTOM_PROPERTIES.FETCH_METRICS_REQUEST, fetchMetrics),
    takeEvery(AGENT_CUSTOM_PROPERTIES.FETCH_METRICS_SUCCESS, setCWInitialCustomVariables),
  ]);
}
