import type { PostMessageEvent } from 'constants/post-message-event';
import type { PostMessage } from 'interfaces/post-message';

import { getConfig } from './config';

export const sendPostMessageToMarketplace = (eventName: PostMessageEvent, data?: PostMessage['event_data']): void => {
  const marketplaceIframe = document.getElementById('marketplace') as HTMLIFrameElement;

  const message = JSON.stringify({
    /* eslint-disable @typescript-eslint/naming-convention */
    event_name: eventName,
    event_data: data || null,
    /* eslint-enable @typescript-eslint/naming-convention */
  });

  marketplaceIframe?.contentWindow?.postMessage(message, getConfig().devMarketplace);
};
