// @ts-strict-ignore
import { useRef, useEffect, type IframeHTMLAttributes, type FC, useState } from 'react';

import { cx } from '@emotion/css';
import { Loader, Text } from '@livechat/design-system-react-components';
import { useDispatch, useSelector } from 'react-redux';

import { SafeIframe } from 'components/safe-iframe/SafeIframe';
import { IFRAME_SCOPES } from 'constants/integration-widget';
import { noop } from 'helpers/noop';
import { updateQueryString } from 'helpers/url';
import { useLatest } from 'hooks/use-latest';
import type { IConnection } from 'services/widget/connection/connection';
import { createConnection } from 'services/widget/connection/connection-factory';
import connectionPool from 'services/widget/connection/connection-pool';
import type { IInboxMessage } from 'services/widget/message/inbox/inbox-message';
import { type ProfileMessageSource } from 'services/widget/message/outbox/customer-profile-message';
import { type IWidget, type IApplicationWidget } from 'store/entities/applications/interfaces';
import { ApplicationsActions } from 'store/features/applications/actions';
import { isGhostLogin } from 'store/features/session/selectors';

import * as styles from './styles';

interface IProps extends IframeHTMLAttributes<HTMLIFrameElement> {
  widget: Pick<IWidget, 'id' | 'url'>;
  onMessage?(message: IInboxMessage): void;
  connectionContext?: {
    customerId: string;
    chatId: string;
    source: ProfileMessageSource;
  };
}

interface IConnectionProps extends IProps {
  handleInboxMessage(message: IInboxMessage, connectionId: string): void;
}

type ConnectionCallbacks = Pick<IConnectionProps, 'onMessage' | 'handleInboxMessage'>;

export const WidgetIframe: FC<IProps> = ({ widget, onMessage = noop, connectionContext, ...props }) => {
  const [isIframeLoading, setIsIframeLoading] = useState(false);
  const isGhost = useSelector(isGhostLogin);
  const iframeRef = useRef<HTMLIFrameElement>();
  const dispatch = useDispatch();

  const handleInboxMessage = (message: IInboxMessage, connectionId: string): void => {
    dispatch(ApplicationsActions.receiveInboxMessage(message, connectionId));
  };

  const onConnectionEstablished = (
    pluginId: IApplicationWidget['id'],
    connectionId: IConnection['connectionId']
  ): void => {
    dispatch(ApplicationsActions.connectionEstablished(pluginId, connectionId));
  };

  const connectionCallbacks = useLatest<ConnectionCallbacks>({
    onMessage,
    handleInboxMessage,
  });
  const { id: widgetId, url: widgetUrl } = widget;

  const iframeSrc = updateQueryString(widgetUrl, 'plugin_id', widgetId);

  useEffect(() => {
    if (isGhost) {
      return;
    }
    let connection: IConnection = null;
    let connectionCanceled = false;

    void createConnection(iframeRef.current, iframeSrc).then((newConnection: IConnection) => {
      if (connectionCanceled) {
        return;
      }

      newConnection.setContext(connectionContext);

      connectionPool.add(widgetId, newConnection);
      newConnection.subscribe((message) => {
        connectionCallbacks.current.handleInboxMessage(message, newConnection.connectionId);
        if (connectionCallbacks.current.onMessage) {
          connectionCallbacks.current.onMessage(message);
        }
      });

      newConnection.initialize();
      onConnectionEstablished(widgetId, newConnection.connectionId);
      connection = newConnection;
    });

    return () => {
      if (connection) {
        connectionPool.remove(connection.connectionId);
        connection.destroy();
        connection = null;
      } else {
        connectionCanceled = true;
      }
    };
    /*
  keep in mind that this effect MUST NOT be called
  until widgetId has changed - otherwise parent & child iframe
  will have different postMessage connections
  */
  }, [widgetId, isGhost]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const connection = connectionPool.get(widgetId);

    if (!connectionContext || !connection) {
      return;
    }

    connection.setContext({
      customerId: connectionContext.customerId,
      chatId: connectionContext.chatId,
      source: connectionContext.source,
    });
  }, [
    widgetId,
    connectionContext,
    connectionContext?.customerId,
    connectionContext?.chatId,
    connectionContext?.source,
  ]);

  // passing the key props will cause the iframe to be remounted on widget change,
  // this is necessary for the old connection to be properly garbage collected

  return (
    <>
      {isIframeLoading && (
        <div className={styles.loaderStyles}>
          <Loader size="medium" />
          <Text as="span">Loading application...</Text>
        </div>
      )}
      <SafeIframe
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        id={widgetId}
        key={widgetId}
        ref={iframeRef}
        src={iframeSrc}
        className={cx(styles.iframeStyles, {
          [styles.iframeHidden]: isIframeLoading,
        })}
        allow={IFRAME_SCOPES}
        onLoad={() => {
          setIsIframeLoading(false);
        }}
      />
    </>
  );
};
