import { type FC } from 'react';

import { type ResourceModalType, RESOURCE_MODAL } from '../constants';
import { type ModalProps } from '../interfaces';

import { ManageResourcesModal } from './ManageResourcesModal/ManageResourcesModal';
import { PurchaseModal } from './PurchaseModal/PurchaseModal';
import { UpdateSubscriptionModal } from './UpdateSubscriptionModal/UpdateSubscriptionModal';

export const modalComponentMap = new Map([
  [RESOURCE_MODAL.updateSubscription, UpdateSubscriptionModal],
  [RESOURCE_MODAL.manage, ManageResourcesModal],
  [RESOURCE_MODAL.purchase, PurchaseModal],
]);

interface Props {
  closeModal: () => void;
  openedModal: ResourceModalType | null;
  openModal?: (purchaseOnly?: boolean) => void;
}

export const ResourceModal: FC<Props> = ({ closeModal, openedModal, openModal }) => {
  const Component: FC<ModalProps> | undefined = modalComponentMap.get(openedModal as ResourceModalType);

  if (!openedModal || !Component) {
    return null;
  }

  if (openedModal === RESOURCE_MODAL.manage) {
    return <Component closeModal={closeModal} openModal={openModal} />;
  }

  return <Component closeModal={closeModal} />;
};
