import { css } from '@emotion/css';
import { DesignToken, SpacingToken } from '@livechat/design-system-react-components';
import { ellipsis } from 'polished';

import { resetLegacyLcBorderBox } from 'styles/reset-border-box';

export const wrapper = css`
  ${resetLegacyLcBorderBox};
  height: 100%;
  min-width: 0;
  width: 0;
  visibility: hidden;
  opacity: 0;
  transition: width 0.3s ease-in-out, min-width 0.3s ease-in-out, visibility 0.2s ease-in-out, opacity 0.2s ease;
  border-left: 1px solid var(${DesignToken.BorderBasicTertiary});
`;

export const openedDetailsWrapper = css`
  height: 100%;
  min-width: 403px;
  width: 403px;
  visibility: visible;
  opacity: 1;
  border-left: 1px solid var(${DesignToken.BorderBasicTertiary});
`;

export const detailsWrapper = css`
  height: 100%;
  overflow-y: auto;
`;

export const header = css`
  padding: 0 var(${SpacingToken.Spacing6});
  height: 64px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(${DesignToken.ContentBasicPrimary});
  border-bottom: 1px solid var(${DesignToken.BorderBasicTertiary});
`;

export const accordion = css`
  padding: 24px;
  border-bottom: 1px solid var(${DesignToken.BorderBasicTertiary});
`;

export const text = css`
  color: var(${DesignToken.ContentBasicSecondary});
`;

export const oneTitle = css`
  margin-top: 8px;
  color: var(${DesignToken.ContentBasicPrimary});
  text-align: center;
`;

export const oneSubTitle = css`
  color: var(${DesignToken.ContentBasicSecondary});
  text-align: center;
  margin-bottom: 16px;
`;

export const oneText = css`
  color: var(${DesignToken.ContentBasicPrimary});
  text-align: center;
`;

export const noSourcesContainer = css`
  color: var(${DesignToken.ContentBasicPrimary});

  button {
    margin-top: 14px;
  }
`;

export const sourcesList = css`
  margin-left: 20px;
  list-style: disc;

  button {
    ${ellipsis()};
    max-width: 90%;
    font-weight: 400;

    div {
      ${ellipsis()};
      display: block;
      height: 100%;
    }
  }

  li {
    color: var(${DesignToken.ContentBasicPrimary});
    margin-bottom: var(${SpacingToken.Spacing2});
  }
`;

export const loaderWrapper = css`
  display: flex;
  justify-content: center;
  padding: 12px;
`;

export const icon = css`
  display: block;
  margin: 0 auto;
  width: 64px;
  border-radius: 50%;
`;

export const logo = css`
  width: 32px;
  height: 32px;
  margin-right: 10px;
`;

export const moreTooltip = css`
  display: inline-block;
  min-width: 85px;
`;

export const textWithLogo = css`
  display: flex;
  align-items: flex-start;
`;

export const moreKnowledgeSources = css`
  width: 80px;
`;

export const expandButton = css`
  display: flex;
  margin: 4px auto;

  span {
    width: 24px;
    height: 24px;
  }
`;

export const settingsButton = css`
  margin-top: 14px;
  display: flex;
  align-items: center;
  align-self: stretch;
  color: var(${DesignToken.ActionPrimaryDefault});

  h3 {
    font-size: 14px;
  }
`;

export const starterUpsellText = css`
  margin-bottom: 14px;
`;

export const tooltipTrigger = css`
  display: inline-block;
`;

export const accordionText = css`
  padding: var(${SpacingToken.Spacing3}) 0 var(${SpacingToken.Spacing4});
  color: var(${DesignToken.ContentBasicPrimary});
`;

export const detailsCard = css`
  border-bottom: 1px solid var(${DesignToken.BorderBasicTertiary});
  background-color: var(${DesignToken.SurfacePrimaryDefault});
`;

export const detailsOneInfo = css`
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: var(${DesignToken.ContentBasicSecondary});
  padding: var(${SpacingToken.Spacing6});
`;

export const aboutOneText = css`
  color: var(${DesignToken.ContentBasicPrimary});
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: var(${SpacingToken.Spacing4});
`;

export const feedbackBanner = css`
  margin: var(${SpacingToken.Spacing5});
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  background: var(${DesignToken.SurfaceGradient10});

  p {
    color: var(${DesignToken.ContentBasicPrimary});
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
`;
