import { type FC } from 'react';

import { Heading } from '@livechat/design-system-react-components';

import { type IInAppUpgrade } from 'store/entities/applications/interfaces';

import { InAppUpgradeRow } from './in-app-upgrade-row/InAppUpgradeRow';
import * as styles from './in-app-upgrade-row/styles';

interface IProps {
  resources: IInAppUpgrade[];
}

export const InAppUpgradeList: FC<IProps> = ({ resources }) => {
  if (!resources.length) {
    return (
      <Heading size="xs" textAlign="center" className={styles.emptyList}>
        No upgrades available yet.
      </Heading>
    );
  }

  return (
    <div className={styles.listWrapper}>
      {resources.map((resource) => (
        <InAppUpgradeRow key={resource.resourceId} resource={resource} />
      ))}
    </div>
  );
};
