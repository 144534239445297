import type { FC } from 'react';

import { useSelector } from 'react-redux';

import { ProductSwitcher } from 'components/new-navigation/product-switcher/ProductSwitcher';
import { Right } from 'constants/right';
import { useFetchFeatures } from 'hooks/api/feature-control/use-fetch-features';
import { useParamSelector } from 'hooks/use-param-selector';
import { selectIsDisableNativeTicketsExperiment } from 'store/entities/experiments/selectors';
import { getHelpDeskReplacedTickets } from 'store/entities/license-properties/selectors';
import { hasRight } from 'store/features/session/selectors';

import { getTopNavigationIds } from '../configuration';
import { ChatbotItem } from '../items/ChatbotItem';
import { HelpDeskItem } from '../items/HelpDeskItem';
import { NavigationItem } from '../items/navigation-item/NavigationItem';

import * as styles from './styles';

export const TopNavigation: FC = () => {
  const { data } = useFetchFeatures();
  const tickets = {
    replaced: useSelector(getHelpDeskReplacedTickets),
    disabled: useSelector(selectIsDisableNativeTicketsExperiment),
  };
  const ids = getTopNavigationIds({
    shouldShowTickets: !tickets.replaced && !tickets.disabled,
    shouldShowTeam: useParamSelector(hasRight, Right.AgentsSectionAccess),
  });

  return (
    <ul className={styles.container}>
      {data.productSwitcher && <ProductSwitcher />}
      {ids.map((id) => (
        <NavigationItem key={id} id={id} />
      ))}
      <ChatbotItem />
      <HelpDeskItem />
    </ul>
  );
};
