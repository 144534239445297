// @ts-strict-ignore
import property from 'lodash.property';
import { type SagaIterator } from 'redux-saga';
import { put, call, takeEvery, select } from 'redux-saga/effects';

import { PlanType } from 'constants/plan-type';
import { SettingsSection } from 'helpers/analytics-settings';
import { navigate } from 'helpers/routing';
import type { RequestResult } from 'interfaces/api/client';
import { ApiManager } from 'services/api/api-manager';
import { deserializeAutoAccessRule, serializeAutoAccessRule } from 'services/api/auto-access-rules/serialization';
import { trackSettingsEvent } from 'services/event-tracking';
import { SessionActionNames, type SessionActions } from 'store/features/session/actions';
import { getCanUseAutoAccessRules } from 'store/features/session/selectors';

import { AutoAccessRulesActionNames, AutoAccessRulesActions } from './actions';
import { GroupsRoutingRoutes } from './constants';
import { type IApiAutoAccessRule, type IAutoAccessRule } from './interfaces';
import { getSingleAutoAccessRule } from './selectors';

interface RequestError {
  type: string;
  message: string;
}

export function* fetchCollection(): SagaIterator {
  const canUseAutoAccessRules = yield select(getCanUseAutoAccessRules);
  if (!canUseAutoAccessRules) {
    yield put(AutoAccessRulesActions.fetchCollectionSuccess({ values: [] }));

    return;
  }
  const { autoAccessRulesApi } = ApiManager;
  const { result, error }: RequestResult<IApiAutoAccessRule[], RequestError> = yield call(
    autoAccessRulesApi.fetchAll
  );

  yield put(
    error
      ? AutoAccessRulesActions.fetchCollectionFailure({ error: error.message })
      : AutoAccessRulesActions.fetchCollectionSuccess({ values: result.map(deserializeAutoAccessRule) })
  );
}

export function* create(action: ReturnType<typeof AutoAccessRulesActions.create>): SagaIterator {
  const { autoAccessRulesApi } = ApiManager;
  const {
    result,
    error,
  }: RequestResult<Pick<IApiAutoAccessRule, 'id'>, RequestError> = yield call(
    autoAccessRulesApi.create,
    serializeAutoAccessRule(action.payload)
  );

  if (error) {
    yield put(AutoAccessRulesActions.createFailure({ error: error.message }));

    return;
  }

  trackSettingsEvent('Routing rule created', SettingsSection.RoutingRules, {
    hasURLCondition: !!property(['conditions', 'url'])(action.payload),
    hasDomainCondition: !!property(['conditions', 'domain'])(action.payload),
    hasGeolocationCondition: !!property(['conditions', 'geolocation'])(action.payload),
  });
  yield put(AutoAccessRulesActions.createSuccess({ id: result.id, value: action.payload }));

  navigate(GroupsRoutingRoutes.Base);
}

export function* remove(action: ReturnType<typeof AutoAccessRulesActions.delete>): SagaIterator {
  const { autoAccessRulesApi } = ApiManager;
  const {
    error,
  }: RequestResult<Pick<IApiAutoAccessRule, 'id'>, RequestError> = yield call(
    autoAccessRulesApi.remove,
    action.payload.id
  );

  if (error) {
    yield put(AutoAccessRulesActions.deleteFailure({ id: action.payload.id, error: error.message }));

    return;
  }

  trackSettingsEvent('Routing rule removed', SettingsSection.RoutingRules);
  yield put(AutoAccessRulesActions.deleteSuccess({ id: action.payload.id }));
}

export function* update(action: ReturnType<typeof AutoAccessRulesActions.update>): SagaIterator {
  const { autoAccessRulesApi } = ApiManager;
  const { error }: RequestResult<void, RequestError> = yield call(
    autoAccessRulesApi.update,
    serializeAutoAccessRule(action.payload)
  );

  if (error) {
    yield put(AutoAccessRulesActions.updateFailure({ error: error.message }));

    return;
  }

  trackSettingsEvent('Routing rule updated', SettingsSection.RoutingRules);
  yield put(AutoAccessRulesActions.updateSuccess({ id: action.payload.id, value: action.payload }));

  navigate(GroupsRoutingRoutes.Base);
}

export function* reorder(action: ReturnType<typeof AutoAccessRulesActions.reorder>): SagaIterator {
  const { autoAccessRulesApi } = ApiManager;
  const { error }: RequestResult<void, RequestError> = yield call(
    autoAccessRulesApi.reorder,
    action.payload.id,
    action.payload.nextId
  );

  if (error) {
    yield put(AutoAccessRulesActions.reorderFailure({ id: action.payload.id, error: error.message }));
    yield put(AutoAccessRulesActions.fetchCollection());

    return;
  }

  trackSettingsEvent('Routing rule reordered', SettingsSection.RoutingRules);
  yield put(AutoAccessRulesActions.reorderSuccess({ id: action.payload.id, nextId: action.payload.nextId }));
}

function* duplicate(action: ReturnType<typeof AutoAccessRulesActions.duplicate>): SagaIterator {
  const { autoAccessRulesApi } = ApiManager;
  const { id, nextId, description, ...rule }: IAutoAccessRule = yield select(
    getSingleAutoAccessRule,
    action.payload.id
  );
  const duplicatedRule = { ...rule, description: description ? `${description} (Copy)` : 'New rule' };
  const {
    error,
    result,
  }: RequestResult<Pick<IApiAutoAccessRule, 'id'>, RequestError> = yield call(
    autoAccessRulesApi.create,
    serializeAutoAccessRule(duplicatedRule)
  );

  if (error) {
    yield put(AutoAccessRulesActions.duplicateFailure({ id: action.payload.id, error: error.message }));

    return;
  }

  trackSettingsEvent('Routing rule duplicated', SettingsSection.RoutingRules);
  yield put(AutoAccessRulesActions.duplicateSuccess({ id: action.payload.id }));
  yield put(AutoAccessRulesActions.createSuccess({ id: result.id, value: duplicatedRule }));
}

function* handlePlanUpdate(action: ReturnType<typeof SessionActions.saveLicensePlan>): SagaIterator {
  if (action.payload.plan === PlanType.Starter) {
    yield put(AutoAccessRulesActions.setCollection({}));
  }
}

export function* autoAccessRulesSagas(): SagaIterator {
  yield takeEvery(AutoAccessRulesActionNames.CREATE, create);
  yield takeEvery(AutoAccessRulesActionNames.DELETE, remove);
  yield takeEvery(AutoAccessRulesActionNames.UPDATE, update);
  yield takeEvery(AutoAccessRulesActionNames.REORDER, reorder);
  yield takeEvery(AutoAccessRulesActionNames.DUPLICATE, duplicate);
  yield takeEvery(AutoAccessRulesActionNames.FETCH_COLLECTION, fetchCollection);
  yield takeEvery(SessionActionNames.SAVE_LICENSE_PLAN, handlePlanUpdate);
}
