// @ts-strict-ignore
import { useCallback, type FC } from 'react';

import { cx } from '@emotion/css';
import { Close, Notifications } from '@livechat/design-system-icons';
import { Button, Icon } from '@livechat/design-system-react-components';
import { useDispatch } from 'react-redux';

import { type NotificationStatus } from 'constants/browser-notifications';
import { Cookie } from 'constants/cookies';
import { TopBarNotificationType } from 'constants/notifications';
import { EventPlace } from 'helpers/analytics';
import { setCookie } from 'helpers/cookies';
import { trackEvent } from 'services/event-tracking';
import { NotificationsBarActions } from 'store/features/notifications-bar/actions';

import * as styles from '../styles';

const COOKIE_DAYS_EXPIRATION = 5;

interface IProps {
  onSkip(): void;
}

export const EnableNotificationsBar: FC<IProps> = ({ onSkip }) => {
  const dispatch = useDispatch();

  const handleEnableNotifications = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    Notification.requestPermission((notificationStatus: NotificationStatus) => {
      trackEvent('Enable notifications button clicked', EventPlace.TopBar, {
        topBar: TopBarNotificationType.EnableNotifications,
      });
      trackEvent(`Permission requested - status ${notificationStatus}`, EventPlace.TopBar, {
        topBar: TopBarNotificationType.EnableNotifications,
      });
      dispatch(NotificationsBarActions.hideNotificationsBar(TopBarNotificationType.BrowserNotifications));
    });
  }, [dispatch]);

  const handleSkip = useCallback(() => {
    setCookie(Cookie.EnableNotificationsBarSkipped, 1, { days: COOKIE_DAYS_EXPIRATION });
    onSkip();
  }, [onSkip]);

  return (
    <div className={cx(styles.contentContainer, styles.infoContainer)}>
      <div className={styles.skippableContent}>
        <span>Never miss a chat!</span>
        <Button
          tabIndex={-1}
          className={styles.button}
          kind="secondary"
          onClick={handleEnableNotifications}
          size="compact"
          icon={<Icon source={Notifications} />}
        >
          Enable browser notifications
        </Button>
      </div>
      <button
        tabIndex={-1}
        className={styles.iconButton}
        onClick={handleSkip}
        aria-label="Close the prompt about enabling browser notifications"
      >
        <Icon className={styles.whiteIcon} source={Close} />
      </button>
    </div>
  );
};
