export const enum PostMessageEvent {
  AppInstalled = 'app_installed',
  AppPurchased = 'app_purchased',
  AppRemoved = 'app_removed',
  AppSettingsOpened = 'app_settings_opened',
  AppEvent = 'app_event',
  CartPurchased = 'cart_purchased',
  CartUpdated = 'cart_updated',
  Loaded = 'loaded',
  MarketplaceCartUpdate = 'marketplace_cart_update',
  Navigate = 'navigate',
  OpenInAppUpgradesModal = 'open_in_app_upgrades_modal',
  Preload = 'preload',
  RouteChange = 'route_change',
  RefreshInstalledApps = 'refresh_installed_apps',
  TogglePinApp = 'toggle_pin_app',
  MailTo = 'mailto',
  RefreshSubscriptionInfo = 'refresh_subscription_info',

  // one-click payment
  OpenPaymentModal = 'open_payment_modal',
  OpenPaymentModalReady = 'open_payment_modal_ready',
  OpenPaymentModalFailure = 'open_payment_modal_failure',
  ClosePaymentModal = 'close_payment_modal',
  PaymentSuccess = 'payment_success',
  PaymentFailure = 'payment_failure',
  CheckOnboarding = 'check_onboarding',
  UpdateBillingCycle = 'update_billing_cycle',
}
