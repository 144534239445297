import { type FC } from 'react';

import * as styles from './styles';

interface IProps {
  title?: string;
  id?: string;
}

export const ExceededItem: FC = ({ children }) => <div className={styles.exceededItem}>{children}</div>;

export const Item: FC<IProps> = ({ children, id, title }) => (
  <div title={title} id={id} className={styles.item}>
    {children}
  </div>
);
