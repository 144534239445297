import { ThemeName } from 'constants/themes';
import { shouldApplySelectedTheme } from 'helpers/dark-mode';
import { featureBranchURL } from 'helpers/regexp';

export function applyCurrentTheme(theme: ThemeName): void {
  document.body.classList.remove('lc-legacy-theme', 'lc-light-theme', 'lc-dark-theme');
  document.body.classList.add(`lc-${theme}-theme`);
}

export function applyCurrentThemeOnAvailableSections(theme: ThemeName): void {
  const pathname = window.location.pathname.replace(featureBranchURL, '');
  const themeName = shouldApplySelectedTheme(pathname) ? theme : ThemeName.Light;
  applyCurrentTheme(themeName);
}
