import { ChatThreadStatus } from 'constants/chat-thread-status';
import { ChatType } from 'constants/chat-type';
import { Section } from 'constants/section';
import { getIsHelpDeskInstalled, type IWithApplicationsState } from 'store/entities/applications/selectors';
import {
  getIsQueuedChatVisuallyClosed,
  getMyChatsIds,
  getOtherAgentsChatsIds,
  getQueuedIds,
  getSupervisedIds,
  getThread,
  getThreadStatus,
  getThreadType,
  getUnassignedIds,
  type IWithChatsEntityState,
} from 'store/entities/chats/selectors';
import {
  getContinuousChatWidgetEnabled,
  type IWithLicensePropertiesState,
} from 'store/entities/license-properties/selectors';
import { AgentCustomPropertyName } from 'store/features/agent-custom-properties/interfaces';
import {
  getAgentCustomProperty,
  type IWithAgentCustomPropertiesState,
} from 'store/features/agent-custom-properties/selectors';

import { countThreadsByStatus } from './helpers/selectors';
import {
  getMyChats,
  getSupervised,
  getTotalUnassignedChats,
  getUnreadAllEventsCount,
  hasUnseenMessages,
  type IWithChatsViewState,
} from './selectors';

/**
 * Returns section with corresponding notification counter
 * Chats section counter includes
 * - count of unread messages from assigned and supervised chats
 * - count of unassigned threads
 * - count of assigned threads without messages
 *
 * Traffic section counter includes
 * - count of queued chats without queued chats remaining in the store after other agent picked them
 */
export function getCountForNotification(state: IWithChatsViewState): {
  [Section.Chats]: number;
  [Section.Engage]: number;
} {
  const { newChatThreadIds } = state.views.chats;
  const myAndSupervisedThreadsIds = [...getMyChatsIds(state), ...getSupervisedIds(state)];
  const totalUnassignedChats = getTotalUnassignedChats(state);
  const queuedChatsIds = getQueuedIds(state);
  const excludedIds = [...queuedChatsIds, ...getUnassignedIds(state), ...getOtherAgentsChatsIds(state)];
  const unreadNewThreadIds = newChatThreadIds.filter((id): boolean => !excludedIds.includes(id));

  const unreadMessagesCount = myAndSupervisedThreadsIds.reduce((acc, threadId): number => {
    const thread = getThread(state, threadId);

    if (!thread) {
      return acc;
    }

    if (hasUnseenMessages(state, threadId)) {
      const allUnreadEventsCount = getUnreadAllEventsCount(state, threadId);
      const modifier = newChatThreadIds.includes(threadId) ? -1 : 0;

      return acc + allUnreadEventsCount + modifier;
    }

    return acc;
  }, 0);

  const queuedChatsCount = queuedChatsIds.filter((threadId) => !getIsQueuedChatVisuallyClosed(state, threadId)).length;

  return {
    [Section.Chats]: unreadNewThreadIds.length + totalUnassignedChats + unreadMessagesCount,
    [Section.Engage]: queuedChatsCount,
  };
}

export function getMyChatsCountByStatus(state: IWithChatsViewState, status: ChatThreadStatus): number {
  const myChats = getMyChats(state);

  return countThreadsByStatus(myChats, status);
}

export function getSupervisedCountByStatus(state: IWithChatsViewState, status: ChatThreadStatus): number {
  const supervised = getSupervised(state);

  return countThreadsByStatus(supervised, status);
}

export const getIsChatFeedActionBarVisible = (
  state: IWithChatsEntityState & IWithLicensePropertiesState,
  threadId: string
): boolean => {
  const threadType = getThreadType(state, threadId);
  const threadStatus = getThreadStatus(state, threadId);
  const isContinuousEnabled = getContinuousChatWidgetEnabled(state);

  return (
    [ChatType.Unassigned, ChatType.Queued, ChatType.Other].includes(threadType) ||
    (threadType === ChatType.My && threadStatus === ChatThreadStatus.Closed && isContinuousEnabled)
  );
};

export const getIsHelpDeskInfoboxVisible = (
  state: IWithChatsEntityState & IWithAgentCustomPropertiesState & IWithApplicationsState,
  threadId: string
): boolean => {
  const threadType = getThreadType(state, threadId);
  const propertyValue = getAgentCustomProperty(state, AgentCustomPropertyName.ChatFeedHelpDeskInfoboxSeen);
  const isHelpDeskInstalled = getIsHelpDeskInstalled(state);

  return threadType === ChatType.Unassigned && !propertyValue && !isHelpDeskInstalled;
};

export const getIsTextAreaVisible = (
  state: IWithChatsEntityState & IWithLicensePropertiesState,
  threadId: string
): boolean => {
  const isActionBarVisible = getIsChatFeedActionBarVisible(state, threadId);
  const threadType = getThreadType(state, threadId);

  return !isActionBarVisible && [ChatType.Supervised, ChatType.My].includes(threadType);
};
