import { css } from '@emotion/css';
import { DesignToken, SpacingToken } from '@livechat/design-system-react-components';

import { withVar } from 'helpers/styles';
import { StackingContextLevel } from 'styles/stacking-context-level';

export { active, container } from '../navigation-item/styles';
import { link as defaultLink } from '../navigation-item/styles';

export const link = css`
  ${defaultLink}

  span[class] {
    // to override [class*='lc-Badge-module__badge']
    opacity: 1;
  }
`;

export const agentAvatar = css`
  > div[class^='lc-Avatar-module__avatar__status'] {
    border-color: var(${DesignToken.SurfaceLockedBlack});
    width: 12px;
    height: 12px;
    bottom: 73%;
    left: 73%;
  }
`;

export const goodRates = css`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
`;

export const popover = css`
  margin-left: ${withVar(SpacingToken.Spacing1)};
  z-index: ${StackingContextLevel.Dropdown};

  &::before {
    content: '';
    position: absolute;
    bottom: 16px;
    left: -14px;
    border-color: transparent ${withVar(DesignToken.ColorWhite)} transparent transparent;
    border-width: 7px;
    border-style: solid;
  }
`;
