export enum Section {
  App = 'app',
  Apps = 'apps',
  Archives = 'archives',
  ChatbotInLivechat = 'chatbot-in-livechat',
  Chats = 'chats',
  Engage = 'engage',
  EngageCampaigns = 'engage/campaigns',
  EngageGoals = 'engage/goals',
  EngageTraffic = 'engage/traffic',
  HelpDeskInLivechat = 'helpdesk-in-livechat',
  HomePage = 'home',
  InstallCode = 'install-code',
  Marketplace = 'marketplace',
  MobileProfile = 'mobile-profile',
  Onboarding = 'onboarding',
  Reports = 'reports',
  Settings = 'settings',
  Subscription = 'subscription',
  Team = 'team',
  Tickets = 'tickets',
  Traffic = 'traffic',
}
