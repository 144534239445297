import { useMutation } from '@tanstack/react-query';
import { ApiManager } from 'services/api/api-manager';
import { AnnotateReplyType, AnnotateReplyValue } from 'interfaces/knowledge-sources';
import { IAnnotateReplyRequest } from 'services/api/ml-gateway/interfaces/ai-agents';

type AnnotationData = {
  traceId: string;
  comment?: string;
  type: AnnotateReplyType;
  value: AnnotateReplyValue;
};

type UseSendAnnotation = {
  sendAnnotation: (data: AnnotationData) => void;
};

export const useSendAnnotation = (): UseSendAnnotation => {
  const { mutate } = useMutation<void, Error, AnnotationData>({
    mutationFn: async ({ traceId, comment, type, value }) => {
      const request: IAnnotateReplyRequest = { trace_id: traceId, comment, name: type, value };

      const { error } = await ApiManager.mlGatewayApi.sendAnnotation(request);
      if (error) {
        throw error;
      }
    },
  });

  return {
    sendAnnotation: mutate,
  };
};
