import { type StartupErrorAction, StartupErrorActionNames } from './actions';
import { type StartupErrorViewState } from './interfaces';

const initialState: StartupErrorViewState = {
  isVisible: false,
};

export function startupErrorReducer(
  state: StartupErrorViewState = initialState,
  action: StartupErrorAction
): StartupErrorViewState {
  switch (action.type) {
    case StartupErrorActionNames.SET_ERROR: {
      const {
        payload: { isVisible },
      } = action;

      return {
        ...state,
        isVisible,
      };
    }

    default:
      return state;
  }
}
