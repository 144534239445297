import { type KeyMap } from 'helpers/interface';

export enum PlanType {
  Starter = 'starter',
  Team = 'team',
  Business = 'enterprise',
  EnterprisePlus = 'enterpriseplus',
}

export const DisplayPlanType = {
  [PlanType.Starter]: 'starter',
  [PlanType.Team]: 'team',
  [PlanType.Business]: 'business',
  [PlanType.EnterprisePlus]: 'enterprise',
};

export const allButStarter = [PlanType.Team, PlanType.Business, PlanType.EnterprisePlus];

export const atLeastEnterprise = [PlanType.EnterprisePlus];

export const atLeastBusiness = [PlanType.Business, PlanType.EnterprisePlus];

export const atLeastTeam = [PlanType.Team, PlanType.Business, PlanType.EnterprisePlus];

export type IPlanPrices = KeyMap<number>;
