import { createSelector } from 'reselect';

import { anyToBoolean } from 'helpers/boolean';
import { getConfig } from 'helpers/config';
import { type ThreeStateFeatureFlag } from 'helpers/get-status-from-feature-flag';
import { type KeyMap } from 'helpers/interface';
import { CoreProperty, PlatformNamespace } from 'services/connectivity/configuration-api/properties/constants';
import { getLicenseCustomProperties } from 'store/entities/license-custom-properties/selectors';
import { createHasFetchedSelector, type IWithRequestsState } from 'store/requests/selectors';

import type { ReportTargets } from '../reports/interfaces';

import { IntegrationLicensePropertiesActionNames } from './actions';
import { ChatAnalyserLicenseProperty } from './constants/chat-analyser';
import {
  IntegrationLicensePropertiesNames,
  type IIntegrationLicensePropertiesState,
  type IIntegrationLicenseProperties,
  type IExternalPaymentProvider,
} from './interfaces';

export interface IWithIntegrationLicensePropertiesState {
  entities: {
    integrationLicenseProperties: IIntegrationLicenseProperties;
  };
}

export const getIntegrationLicenseProperties = (
  state: IIntegrationLicensePropertiesState
): IIntegrationLicenseProperties => state.entities.integrationLicenseProperties;

export const isHelpDeskAppConnected = createSelector(getIntegrationLicenseProperties, (licenseProperties) => {
  const clientId = getConfig().helpDeskAppClientId;
  const helpdeskProperties = licenseProperties[clientId] as { hdLicenseID?: number };
  if (!helpdeskProperties) {
    return false;
  }

  const licenseId = helpdeskProperties.hdLicenseID;

  return !!licenseId && licenseId >= 0;
});

const getAccountsIntegrationLicenseProperties = createSelector(
  getIntegrationLicenseProperties,
  (licenseProperties) => licenseProperties[getConfig().accountsClientId] || {}
);

const getCoreIntegrationLicenseProperties = createSelector(
  getIntegrationLicenseProperties,
  (licenseProperties) => licenseProperties[PlatformNamespace.CORE] || {}
);

export const getIsQueueMessagingEnabled = createSelector(
  getAccountsIntegrationLicenseProperties,
  (licenseProperties) => licenseProperties[IntegrationLicensePropertiesNames.MessagingInQueueEnabled] as boolean
);

export function getHasFetchedIntegrationLicenseProperties(state: IWithRequestsState): boolean {
  return createHasFetchedSelector([IntegrationLicensePropertiesActionNames.FETCH_REQUEST])(state);
}

export const getIsNewAvailabilityPromoModalEnabled = createSelector(
  getAccountsIntegrationLicenseProperties,
  (licenseProperties) => licenseProperties?.[IntegrationLicensePropertiesNames.AvailabilityPromoEnabled]
);

export const getIsChatTakeOverDisabled = createSelector(
  getAccountsIntegrationLicenseProperties,
  (licenseProperties) => !!licenseProperties?.[IntegrationLicensePropertiesNames.ChatTakeOverDisabled]
);

export const getTextEnhancementsStatus = createSelector(
  getAccountsIntegrationLicenseProperties,
  (licenseProperties): ThreeStateFeatureFlag =>
    licenseProperties?.[IntegrationLicensePropertiesNames.TextEnhancementsStatus] as ThreeStateFeatureFlag
);

export const getIsChatSummaryDisabled = createSelector(
  getAccountsIntegrationLicenseProperties,
  (licenseProperties) => !!licenseProperties?.[IntegrationLicensePropertiesNames.ChatSummaryDisabled]
);

export const getCustomerHistoryEnabled = createSelector(getCoreIntegrationLicenseProperties, (licenseProperties) =>
  anyToBoolean(!!licenseProperties?.[CoreProperty.CustomerHistoryEnabled])
);

export const getChatAnalyserFirstInstallationDate = createSelector(
  getIntegrationLicenseProperties,
  (licenseProperties): string | null => {
    const namespace = getConfig().chatsAnalyserNamespace;
    const properties = (licenseProperties[namespace] || {}) as KeyMap;

    const dateString = properties[ChatAnalyserLicenseProperty.FirstInstallationAt] as string | undefined;

    if (!dateString) {
      return null;
    }

    return dateString;
  }
);

export const getReplySuggestionsStatus = createSelector(
  getAccountsIntegrationLicenseProperties,
  (licenseProperties): ThreeStateFeatureFlag =>
    licenseProperties?.[IntegrationLicensePropertiesNames.ReplySuggestionsStatus] as ThreeStateFeatureFlag
);

export const getOneChatStatus = createSelector(
  getAccountsIntegrationLicenseProperties,
  (licenseProperties): ThreeStateFeatureFlag =>
    licenseProperties?.[IntegrationLicensePropertiesNames.OneChatStatus] as ThreeStateFeatureFlag
);

export const getReportTargets: (state: IIntegrationLicensePropertiesState) => ReportTargets = createSelector(
  getAccountsIntegrationLicenseProperties,
  (licenseProperties) => {
    const reportTargetsString = licenseProperties?.[IntegrationLicensePropertiesNames.ReportTargets];
    if (typeof reportTargetsString === 'string') {
      try {
        return JSON.parse(reportTargetsString) as ReportTargets;
      } catch (e) {
        return {};
      }
    }

    return {};
  }
);

export const getExternalPaymentProvider = createSelector(
  [getIntegrationLicenseProperties, getLicenseCustomProperties],
  (integrationLicenseProperties, licenseCustomProperties): IExternalPaymentProvider => {
    const integrationLicensePropertiesValues = Object.values(integrationLicenseProperties || {});

    // search for the integration that is an external payment provider, take the first one
    const paymentProviderProperties = integrationLicensePropertiesValues.find(
      (properties) => !!properties.lc4x_payment_provider
    );

    if (paymentProviderProperties) {
      return {
        name: String(paymentProviderProperties.lc4x_payment_provider),
        url: String(paymentProviderProperties.lc4x_payment_provider_subscription_url),
        widgetId: String(paymentProviderProperties.lc4x_payment_provider_widget_id),
      };
    }

    // fallback to the v2 license custom properties when no v3.5 properties were found
    return {
      name: licenseCustomProperties?.lc4x_payment_provider && String(licenseCustomProperties.lc4x_payment_provider),
      url:
        licenseCustomProperties?.lc4x_payment_provider_subscription_url &&
        String(licenseCustomProperties.lc4x_payment_provider_subscription_url),
      widgetId:
        licenseCustomProperties?.lc4x_payment_provider_widget_id &&
        String(licenseCustomProperties.lc4x_payment_provider_widget_id),
    };
  }
);

export const getPaymentProvider = createSelector(
  [getExternalPaymentProvider],
  (paymentProvider: IExternalPaymentProvider): string | undefined => paymentProvider.name
);

export const getIsPaymentProvider = createSelector(
  [getPaymentProvider],
  (paymentProvider): boolean => !!paymentProvider
);
