// @ts-strict-ignore
import { createSelector } from 'reselect';

import { LoginStatus } from 'constants/login-status';
import { type Nullable } from 'helpers/interface';
import { sortByName } from 'store/helper';
import {
  createHasFetchedSelector,
  createRequestFetchingSelector,
  type IWithRequestsState,
} from 'store/requests/selectors';

import { AgentBotActionNames } from './actions';
import type { AgentBotWithSubscription, IAgentBot, IBots, IBotsState, IChatbotInfoItem } from './interfaces';

export interface IWithBotsState {
  entities: {
    bots: IBotsState;
  };
}

export function botsSelector(state: IWithBotsState): IBots {
  return state.entities.bots;
}

export const getBotsCount = createSelector(botsSelector, (bots) => Object.keys(bots).length);

export function botExists(state: IWithBotsState, login: string): boolean {
  return !!state.entities.bots[login];
}

export const getBots = createSelector(botsSelector, (bots) => Object.values(bots).sort(sortByName));

export function getBot(state: IWithBotsState, login: string): IAgentBot {
  return state.entities.bots[login];
}

export function getIsBotFromLogin(state: IWithBotsState, login: string): boolean {
  const allBots = botsSelector(state);

  return !!allBots[login];
}

export const hasFetchedChatbotInfo = createHasFetchedSelector([AgentBotActionNames.CHATBOT_INFO_FETCH]);

export function getChatbotInfoByLogin(
  state: IWithBotsState,
  login: string | undefined
): IChatbotInfoItem | null | undefined {
  const chatbotInfo = getBot(state, login).chatbotInfo;

  return chatbotInfo || null;
}

export const getActiveChatbots = (state: IWithBotsState): IAgentBot[] =>
  getBots(state).filter(({ status }) => [LoginStatus.Online, LoginStatus.Away].includes(status));

export const getInactiveChatbots = (state: IWithBotsState): IAgentBot[] => {
  const chatbots = getBots(state);

  return chatbots.filter((bot) => bot.status === LoginStatus.Offline);
};

type AgentProcessingSelector = (state: IWithRequestsState) => boolean;

export const getIsSavingChatbot = (botId: string): AgentProcessingSelector =>
  createRequestFetchingSelector([`UPDATE_AGENT_BOT_${botId}`]);

export const getChatbotLicenseUsedChannels = createSelector(
  getActiveChatbots,
  (_, licenseId: Nullable<string>) => licenseId,
  (chatbots: AgentBotWithSubscription[], licenseId) =>
    chatbots.reduce((acc, bot) => {
      if (bot.chatbotInfo?.licenseId === licenseId) {
        // eslint-disable-next-line no-param-reassign
        acc += 1;

        return acc;
      }

      return acc;
    }, 0)
);

export const getIsSavingBotsInBatch = (state: IWithRequestsState): boolean =>
  createRequestFetchingSelector(['ENTITIES/BOTS/BATCH_BOTS_UPDATE'])(state);
