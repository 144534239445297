import { capitalizeFirstLetter } from 'helpers/string';

const technologiesAvailable: string[] = [
  // in order of recommendation:

  // Ecommerce
  'Shopify',
  'WooCommerce',
  'BigCommerce',
  'Ecwid',
  'Magento',
  'SquareOnline',
  // CMS
  'WordPress',
  'SquareSpace',
  'Wix',
  'Webflow',
  'Weebly',
  'Joomla',
  'Drupal',
  'Segment',
  // Tag Managers
  'Google Tag Manager',
];

function treatAsNotFound(technology: string): boolean {
  return technology === 'Cloudflare';
}

export function isTechnologyAvailable(technology: string): boolean {
  return technologiesAvailable.indexOf(technology) >= 0 && !treatAsNotFound(technology);
}

export function sortByRecommendation(technologies: string[]): string[] {
  return technologiesAvailable.filter((tech) => technologies.indexOf(tech) >= 0);
}

export function getAllTechnologies(): string[] {
  return technologiesAvailable;
}

export function normalizeResponseTechnology(responseTechnology: string): string {
  const technology = responseTechnology.split('_').map(capitalizeFirstLetter).join(' ');

  switch (technology) {
    case 'Wordpress':
      return 'WordPress';
    case 'Ios':
      return 'iOS';
    case 'Bigcommerce':
      return 'BigCommerce';
    case 'Cloud Flare':
      return 'Cloudflare';
    case 'CloudFlare':
      return 'Cloudflare';
    case 'Woo Commerce':
      return 'WooCommerce';
    default:
      return technology;
  }
}

export function normalizeCardName(cardName: string): string {
  switch (cardName) {
    case 'Google Tag Manager':
      return 'GTM';
    default:
      return cardName;
  }
}

export function normalizeIntegrationName(integration: string): string {
  return normalizeCardName(integration).toLowerCase();
}
