import { useEffect, type FC, type MouseEvent } from 'react';

import { cx } from '@emotion/css';
import { Close } from '@livechat/design-system-icons';
import { Avatar, Text, Button, Icon } from '@livechat/design-system-react-components';
import { useDispatch, useSelector } from 'react-redux';

import { ONE_LOGO } from 'components/global-modals/ai-hub/constants';
import { OneTrackEvent } from 'constants/one-event';
import { EventPlace } from 'helpers/analytics';
import { getVisibleNotifications } from 'helpers/notification-bar';
import { trackEvent } from 'services/event-tracking';
import { isDarkModeEnabled } from 'store/features/agent-custom-properties/selectors';
import { getNotifications } from 'store/features/notifications-bar/selectors';
import { OneViewActions } from 'store/views/one/actions';
import {
  getIsOneModalOpened,
  getIsOneNewMessageToastVisible,
  getOneUndreadMessageCount,
} from 'store/views/one/selectors';

import { ONE_MESSAGE_TOAST_DURATION } from './constants';

import * as styles from './styles';

export const OneNewMessageToast: FC = () => {
  const dispatch = useDispatch();
  const isOneNewMessageToastVisible = useSelector(getIsOneNewMessageToastVisible);
  const unreadOneMessages = useSelector(getOneUndreadMessageCount);
  const isOneModalOpened = useSelector(getIsOneModalOpened);
  const isDarkMode = useSelector(isDarkModeEnabled);
  const notifications = useSelector(getNotifications);
  const hasVisibleNotifications = getVisibleNotifications(notifications).length > 0;

  const handleCloseTooltip = (event: MouseEvent): void => {
    event.stopPropagation();
    dispatch(OneViewActions.hideMessageToast());
  };

  const handleShowOneModal = (): void => {
    dispatch(OneViewActions.showModal());
  };

  useEffect(() => {
    if (unreadOneMessages > 0) {
      dispatch(OneViewActions.showMessageToast());

      const timer = setTimeout(() => {
        dispatch(OneViewActions.hideMessageToast());
      }, ONE_MESSAGE_TOAST_DURATION);

      return () => clearTimeout(timer);
    }
  }, [unreadOneMessages, dispatch]);

  useEffect(() => {
    if (isOneModalOpened) {
      dispatch(OneViewActions.resetOneUnreadMessagesCount());
    }
  }, [isOneModalOpened, dispatch]);

  useEffect(() => {
    if (isOneNewMessageToastVisible) {
      trackEvent(OneTrackEvent.NewMessage, EventPlace.One);
    }
  }, [isOneNewMessageToastVisible]);

  if (!isOneNewMessageToastVisible || unreadOneMessages === 0 || (unreadOneMessages > 0 && isOneModalOpened)) {
    return null;
  }

  return (
    <div
      className={cx(
        styles.toastContainer(isDarkMode, hasVisibleNotifications),
        { [styles.oneAnimation(isDarkMode)]: isOneNewMessageToastVisible },
        'lc-dark-theme'
      )}
    >
      <div className={styles.toastWrapper} onClick={handleShowOneModal}>
        <div>
          <Avatar size="xsmall" type="image" src={ONE_LOGO} className={styles.oneLogo} />
          <Text as="h2">New message</Text>
        </div>
        <div>
          <Button
            kind="plain"
            size="compact"
            onClick={handleCloseTooltip}
            icon={<Icon className={styles.icon} source={Close} />}
          />
        </div>
      </div>
    </div>
  );
};
