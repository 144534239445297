import { css } from '@emotion/css';
import { DesignToken, SpacingToken } from '@livechat/design-system-react-components';

import { NAVIGATION_BAR_WIDTH } from 'constants/navigation';

const SMALL_SCREEN_BREAKPOINT = 705;

export const modalContainer = css`
  .upgrades-modal {
    width: calc(100% - 128px);
    transform: translateX(${NAVIGATION_BAR_WIDTH / 2}px);

    @media (max-width: ${SMALL_SCREEN_BREAKPOINT}px) {
      width: calc(100% - var(${SpacingToken.Spacing16}));
      transform: translateX(0);
    }
  }
`;

export const tableModal = css`
  max-width: 900px;
  max-height: 620px;
`;

export const tableModalContent = css`
  overflow-y: auto;
  min-height: calc(5 * 73px + 37px + 42px); // (5x table row) + header + tabList with borders
`;

export const tabsList = css`
  padding: 0 var(${SpacingToken.Spacing5});
  margin: 0;

  .lc-tab,
  .lc-tab__description,
  .lc-tab--selected .lc-tab__description,
  .lc-tab:hover .lc-tab__description {
    color: var(${DesignToken.ContentDefault});

    &:disabled,
    &[disabled] {
      opacity: 0.5;
    }
  }
`;

export const footer = css`
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: var(${SpacingToken.Spacing4});
`;
