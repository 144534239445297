import type { ReactElement } from 'react';

import { type ToastProps } from '@livechat/design-system-react-components';

import { type ToastAutoHideDelay } from 'constants/toasts';
import type { KeyMap } from 'helpers/interface';

export const enum ToastVariant {
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
}

export const enum ToastHorizontalPosition {
  Left = 'left',
  Right = 'right',
  Center = 'center',
}

export const enum ToastVerticalPosition {
  Top = 'top',
  Bottom = 'bottom',
}

interface IToastAction {
  onClick: () => void;
  label: string;
  closeOnClick?: boolean;
}

export interface IToastDetails extends ToastProps {
  id: string;
  content: ReactElement | string;
  horizontalPosition: ToastHorizontalPosition;
  verticalPosition: ToastVerticalPosition;
  consumerName?: string;
}

export interface IToastsState {
  items: KeyMap<IToastDetails>;
}

interface IToastsBasePayload {
  id: string;
  autoHideDelayTime: ToastAutoHideDelay;
  horizontalPosition: ToastHorizontalPosition;
  verticalPosition: ToastVerticalPosition;
  kind: ToastVariant;
  content: ReactElement | string;
  removable: boolean;
  action?: IToastAction;
}

export interface ICreateToastActionParams {
  content: ReactElement | string;
  autoHideDelayTime?: ToastAutoHideDelay;
  horizontalPosition?: ToastHorizontalPosition;
  verticalPosition?: ToastVerticalPosition;
  kind?: ToastVariant;
  action?: IToastAction;
}

export interface IToastsCreatePayload extends IToastsBasePayload {}

export interface IToastsShowPayload extends IToastsBasePayload {}

export interface IToastsRemovePayload {
  id: string;
}
