import { PlanType } from './plan-type';

export const ONE_AI_ASSISTANT_TYPING_URL =
  'https://cdn.livechat-static.com/api/file/lc/img/default/assets/one-ai-assistant.mp4';

export const REPLY_SUGGESTIONS_ARTICLE_URL = 'https://www.livechat.com/help/reply-suggestions';

export enum ReplySuggestionEvent {
  CreateReply = 'Replies suggestion requested',
  ReplyReceived = 'Replies suggestion received',
  ReplyUsed = 'Replies suggestion pasted into message box',
  ReplyDismissed = 'Replies suggestion dismissed',
  RegenerateClicked = 'Replies regenerate button clicked',
  CreateReplyShortcutUsed = 'Replies suggestion requested with shortcut',
  ReplyLoaderDismissed = 'Replies loader dismissed',
  ReplySourceClicked = 'Replies source clicked',
  OnboardingLearnMoreClicked = 'Replies onboarding learn more clicked',
  OnboardingAddSourceClicked = 'Replies onboarding add source clicked',
  ErrorShown = 'Replies general error shown',
  NoDataErrorShown = 'Replies no replies error shown',
  NoSourcesErrorShown = 'Replies no sources error shown',
  NoKsAddSourceClicked = 'Replies no knowledge source add source clicked',
  NoKsLearnMoreClicked = 'Replies no knowledge source learn more clicked',
  DetailsAddSourceClicked = 'Details section add source button clicked',
  HideButtonClicked = 'Hide button switch clicked',
  ReplySuggestionOnboardingShown = 'Replies suggestion onboarding shown',
}

export enum ReplySuggestionSettingsEvent {
  LearnMoreClicked = 'Replies learn more clicked',
  UploadSourceCancelled = 'Replies uploading source cancelled',
  DeleteSource = 'Replies knowledge source deleted',
  KnowledgeSourceRenamed = 'Replies knowledge source renamed',
  AddKnowledgeSourceClicked = 'Replies add knowledge sources clicked',
  AddKnowledgeSourceSaveClicked = 'Replies add knowledge source save clicked',
  AddKnowledgeSourceStepCompleted = 'Replies adding source step completed',
  DiscardClicked = 'Replies adding source discarded',
  BannerClosed = 'Reply Suggestions banner closed',
}

export const MINUTE_IN_MS = 60 * 1000;

export const PLAN_LIMITS = {
  [PlanType.Starter]: 0,
  [PlanType.Team]: 3,
  [PlanType.Business]: 10,
  [PlanType.EnterprisePlus]: 10,
};

export const KNOWLEDGE_SOURCES_LIMITS_RELEASE_DATE = '2024-03-12';
