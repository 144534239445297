import type { IAppState } from 'services/app-state-provider';
import { invokeCustomerUpdateWithNotifications } from 'services/customers/incoming-customer';
import { deserializeVisitedPages } from 'services/socket-lc3/chat/serialization/deserialize-customer-visited-pages';

import { type IncomingCustomerPushEvent } from '../interfaces';
import { deserializeIncomingCustomerPushEvent } from '../serialization/deserialize';

export const handleIncomingCustomer = (payload: IncomingCustomerPushEvent, store: IAppState): void => {
  const customer = deserializeIncomingCustomerPushEvent(payload);
  const visitedPages = deserializeVisitedPages(payload.last_visit);

  invokeCustomerUpdateWithNotifications(store, { customer, visitedPages });
};
