import { type FC, useContext } from 'react';

import { Button } from '@livechat/design-system-react-components';

import { getActionButtonCopy } from 'components/apps/helpers';
import { WidgetModalContext } from 'hooks/fullscreen-widgets/use-widget-modal-context';

import { useResourceContext } from '../../hooks/use-resource-context';

import * as styles from './styles';

interface Props {
  closeModal: () => void;
  openModal?: (purchaseOnly?: boolean) => void;
}

export const ManageResourcesModalFooter: FC<Props> = ({ openModal, closeModal }) => {
  const widgetContext = useContext(WidgetModalContext);
  const { trialDays, totalPrice, isActive } = useResourceContext();

  const copy = getActionButtonCopy({ trialDays, totalPrice, isActive, purchaseOnly: true });

  const handleOpenModal = (): void => {
    if (openModal) openModal(true);
  };

  return (
    <div className={styles.footer}>
      <Button onClick={closeModal} kind="secondary">
        {widgetContext ? 'Back' : 'Close'}
      </Button>

      <Button onClick={handleOpenModal} kind="primary">
        {copy}
      </Button>
    </div>
  );
};
