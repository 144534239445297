// @ts-strict-ignore
import intersection from 'lodash.intersection';

import { AgentRole } from 'constants/agent-role';
import { Scope } from 'constants/scope';
import { type KeyMap } from 'helpers/interface';
import { type IAgentsState } from 'store/entities/agents/interfaces';

import { getLoggedInAgentLogin } from '../agents/selectors';

import { type IAccount, type IAccountsState, type IAgentRole, type InvitationMode } from './interfaces';

export interface WithAccountsState {
  entities: {
    accounts: IAccountsState;
    agents: IAgentsState;
  };
}

export const hasFetchedAccountsAndRoles = (state: WithAccountsState): boolean => {
  const accounts = getAccounts(state);

  return Object.keys(accounts).length > 0;
};

function getAccounts(state: WithAccountsState): KeyMap<IAccount> {
  return state.entities.accounts.accounts;
}

function getRoles(state: WithAccountsState): KeyMap<IAgentRole> {
  return state.entities.accounts.roles;
}

function getAccountRoles(state: WithAccountsState, account: string): string[] {
  const data = state.entities.accounts.accounts[account];

  if (!data) {
    return null;
  }

  return data.roles;
}

function getAccountById(state: WithAccountsState, accountId: string): IAccount {
  const accounts = getAccounts(state);

  return Object.values(accounts).find((item) => item.accountId === accountId);
}

export function getIs2faEnabled(state: WithAccountsState, agentAccountId: string): boolean {
  const account = getAccountById(state, agentAccountId);

  return account?.mfaEnabled;
}

export function getAccountsWithGivenScope(state: WithAccountsState, scope: Scope): IAccount[] {
  const roles = Object.values(getRoles(state))
    .filter((item) => item.scopes.some((availableScope) => availableScope === scope))
    .map((item) => item.roleId);

  if (!roles || !roles.length) {
    return [];
  }

  const agents = getAccounts(state);

  return Object.values(agents).filter((account) => {
    const accountRoles = getAccountRoles(state, account.accountId);

    return !!intersection(roles, accountRoles).length;
  });
}

function getOrganizationOwner(state: WithAccountsState): IAccount {
  const ownerRole = Object.values(getRoles(state)).find(({ roleName }) => (roleName as AgentRole) === AgentRole.Owner);

  if (!ownerRole) {
    return;
  }

  const agents = getAccounts(state);

  return Object.values(agents).find((account) => {
    const accountRoles = getAccountRoles(state, account.accountId);

    return accountRoles.includes(ownerRole.roleId);
  });
}

export function getIsCurrentUserOrganizationOwner(state: WithAccountsState): boolean {
  const accountLogin = getLoggedInAgentLogin(state);
  const owner = getOrganizationOwner(state);

  return accountLogin?.toLowerCase() === owner?.email?.toLowerCase();
}

export function getOrganizationId(state: WithAccountsState): string {
  return state.entities.accounts.currentOrganizationId;
}

export function getInvitationMode(state: WithAccountsState): InvitationMode {
  return state.entities.accounts.invitationMode;
}

export const getAccountCreateDate = (state: WithAccountsState, accountId: string): string | null => {
  if (!state.entities.accounts.accounts[accountId]) {
    return null;
  }

  return state.entities.accounts.accounts[accountId]?.createdAt;
};

function getAgentLoginsWithGivenScope(state: WithAccountsState, scope: Scope): string[] {
  return getAccountsWithGivenScope(state, scope).map((account) => account.email);
}

export function getSubscriptionManagersLogins(state: WithAccountsState): string[] {
  const accountOwners = getAgentLoginsWithGivenScope(state, Scope.SubscriptionRW);
  const liveChatProductOwners = getAgentLoginsWithGivenScope(state, Scope.SubscriptionLCRW);

  return [...new Set([...accountOwners, ...liveChatProductOwners])]; // remove duplicates
}

export function getSubscriptionManagers(state: WithAccountsState): IAccount[] {
  const accountOwners = getAccountsWithGivenScope(state, Scope.SubscriptionRW);
  const liveChatProductOwners = getAccountsWithGivenScope(state, Scope.SubscriptionLCRW);

  return [...new Set([...accountOwners, ...liveChatProductOwners])]; // remove duplicates
}

export function getApplicationsManagers(state: WithAccountsState): IAccount[] {
  return getAccountsWithGivenScope(state, Scope.IntegrationsManage);
}

function getBillingManagers(state: WithAccountsState): IAccount[] {
  return getAccountsWithGivenScope(state, Scope.BillingManage);
}

export function getPaidApplicationsManagers(state: WithAccountsState): IAccount[] {
  const applicationManagers = getApplicationsManagers(state);
  const billingManagers = getBillingManagers(state);

  return intersection(applicationManagers, billingManagers);
}
