import { OnboardingRoute } from 'constants/onboarding/routes';

export const supportedIntegrations = [
  'wordpress.org',
  'bigcommerce.com',
  'ecwid.com',
  'magento.com',
  'marketplace.magento.com',
  'shopify.com',
  'woocommerce.com',
  'weebly.com',
  'opencart.com',
  'nopcommerce.com',
  'wix.com',
  'whmcs.com',
  'cloudflare.com',
  'suiteapp.com',
  'joomla.org',
];

export const defaultOnboardingRoute = {
  route: OnboardingRoute.Base,
};
