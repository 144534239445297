export enum ProductOnboardingStep {
  AccountActivated = 'accountActivated',
  AvatarUploaded = 'avatarUploaded',
  CodeInstalled = 'codeInstalled',
  DesktopAppDownloaded = 'desktopAppDownloaded',
  SampleChat = 'sampleChat',
  InviteTeammates = 'inviteTeammates',
  /**
   * This step is not present in REST API so it must be excluded from IProductOnboardingProgress type
   */
  SetupChatbot = 'setupChatbot',
}

export interface IProductOnboardingProgress
  extends Record<Exclude<ProductOnboardingStep, ProductOnboardingStep.SetupChatbot>, boolean> {}
