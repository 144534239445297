import { getStatusFromFeatureFlag } from 'helpers/get-status-from-feature-flag';
import { type IIntegrationLicensePropertiesState } from 'store/entities/integration-license-properties/interfaces';
import { getOneChatStatus } from 'store/entities/integration-license-properties/selectors';
import { type IWithAgentCustomPropertiesState } from 'store/features/agent-custom-properties/selectors';
import { type IWithSessionState } from 'store/features/session/selectors';

import { type WithOneViewState } from './interfaces';

export function getCanUseOnePopover(
  state: IWithSessionState & IIntegrationLicensePropertiesState & IWithAgentCustomPropertiesState
): boolean {
  const oneChatStatus = getOneChatStatus(state);

  return getStatusFromFeatureFlag(oneChatStatus, true);
}

export function getOneDraftMessage(state: WithOneViewState): string {
  return state.views.one.draftMessage;
}

export function getOneUndreadMessageCount(state: WithOneViewState): number {
  return state.views.one.unreadMessagesCount;
}

export function getOneIsLoading(state: WithOneViewState): boolean {
  return state.views.one.isLoading;
}

export function getIsOneModalOpened(state: WithOneViewState): boolean {
  return state.views.one.modal.isOpened || false;
}

export function getIsOneModalExpanded(state: WithOneViewState): boolean {
  return state.views.one.modal.isExpanded || false;
}

export function getOneScrollPosition(state: WithOneViewState): number | null {
  return state.views.one.modal.scrollPosition;
}

export function getIsOneDetailsOpened(state: WithOneViewState): boolean {
  return state.views.one.modal.isDetailsOpened || false;
}

export function getIsOneNewMessageToastVisible(state: WithOneViewState): boolean {
  return state.views.one.isNewMessageToastVisible;
}

export function getIsCommandSuggestionsOpened(state: WithOneViewState): boolean {
  return state.views.one.isCommandSuggestionsOpened;
}
