import type { FC } from 'react';

import { Tooltip } from '@livechat/design-system-react-components';

import { FilterButtonTrigger } from './FilterButtonTrigger';
import type { FilterButton as Button } from './types';

import * as styles from './styles';

const TOOLTIP_TRANSITION_DELAY = 1000;

export const FilterButton: FC<Button> = (props) => {
  return (
    <Tooltip
      placement="top"
      transitionDelay={TOOLTIP_TRANSITION_DELAY}
      className={styles.tooltip}
      kind="invert"
      useClickHookProps={{ enabled: false }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      triggerRenderer={<FilterButtonTrigger {...props} />}
    >
      <div className={styles.filterTooltipText}>
        {!props.shouldHideTitle && props.title && <strong>{props.title}</strong>}{' '}
        {!!props.selectedValuesLabel && props.selectedValuesLabel}
      </div>
    </Tooltip>
  );
};
