// @ts-strict-ignore
/* eslint-disable @typescript-eslint/naming-convention */
import { useMutation, type UseMutateFunction } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { type AnyAction, type Dispatch } from 'redux';

import { App } from 'config/setup';
import { NavigationTooltipType } from 'constants/navigation';
import { ToastContent } from 'constants/toasts';
import { getHelpDeskAppId, getHelpDeskAppNamespace, getKBAppId } from 'helpers/applications';
import { getConfig } from 'helpers/config';
import { showToast } from 'helpers/toast';
import type { RequestResult } from 'interfaces/api/client';
import { ApiManager } from 'services/api/api-manager';
import { type IFetchApplicationDataRequestResult } from 'services/api/integrations/interfaces';
import { ApplicationsActions } from 'store/entities/applications/actions';
import { IntegrationLicensePropertiesActions } from 'store/entities/integration-license-properties/actions';
import { LicenseCustomPropertiesActions } from 'store/entities/license-custom-properties/actions';
import { LicenseCustomPropertiesNames } from 'store/entities/license-custom-properties/interfaces';
import { ToastVariant } from 'store/features/toasts/interfaces';
import { NavigationViewActions } from 'store/views/navigation/actions';

interface TrustedInstallationRequestResult {
  accountStatus?: string;
}

const installTrustedApplication = async (id: string): Promise<TrustedInstallationRequestResult> => {
  const { error: fetchAppDataError, result: application }: RequestResult<IFetchApplicationDataRequestResult> =
    await ApiManager.integrationsApi.fetchAppData(id);
  const trustedUrl = application?.customProps?.trustedUrl;

  if (fetchAppDataError) {
    throw fetchAppDataError;
  }

  if (!trustedUrl) {
    throw new Error('Trusted url is not defined');
  }

  const options: RequestInit = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${App.getAccessToken()}`,
      'Content-Type': 'application/json',
    },
  };

  if (id === getKBAppId()) {
    const payloadKb = {
      metrics: {
        source_url: window.location.href,
        source_id: 'install_button',
      },
    };

    options.body = JSON.stringify(payloadKb);
  }

  const response = await fetch(trustedUrl, options);

  if (!response.ok) {
    throw new Error('Trusted url response error');
  }

  const { error: installationError } = await ApiManager.integrationsApi.install(id);

  if (installationError) {
    throw installationError;
  }

  const data: TrustedInstallationRequestResult = await response.json();

  return data;
};

function handleSuccess(appId: string, dispatch: Dispatch<AnyAction>): void {
  showToast(ToastVariant.Success, ToastContent.APPLICATION_INSTALL_SUCCESS);
  dispatch(ApplicationsActions.refreshInstalledApplicationsData({ notifyMarketplace: true }));

  if (appId === getHelpDeskAppId()) {
    dispatch(
      LicenseCustomPropertiesActions.saveLicenseCustomProperty({
        name: LicenseCustomPropertiesNames.ShouldShowHelpDeskPromoTooltip,
        value: '1',
      })
    );
    dispatch(
      NavigationViewActions.showNavigationItemTooltip({
        itemId: getConfig().helpDeskAppWidgetId,
        type: NavigationTooltipType.HelpDeskPromo,
        kind: 'important',
      })
    );
    dispatch(IntegrationLicensePropertiesActions.fetchForNamespace({ namespace: getHelpDeskAppNamespace() }));
  }
}

function handleError(): void {
  showToast(ToastVariant.Error, ToastContent.APPLICATION_INSTALL_ERROR);
}

export const useInstallTrustedApplication = (): {
  status: string;
  isProcessing: boolean;
  install: UseMutateFunction<TrustedInstallationRequestResult, unknown, string, unknown>;
} => {
  const dispatch: Dispatch<AnyAction> = useDispatch();
  const installTrustedApplicationMutation = useMutation<TrustedInstallationRequestResult, unknown, string, unknown>(
    installTrustedApplication,
    {
      onSuccess: (_, appId) => handleSuccess(appId, dispatch),
      onError: () => handleError(),
    }
  );

  return {
    status: installTrustedApplicationMutation.status,
    isProcessing: installTrustedApplicationMutation.isLoading,
    install: installTrustedApplicationMutation.mutate,
  };
};
