import type { FC } from 'react';

import { BadgeType } from 'components/badge/Badge';
import { usePinnedFullscreenWidgetList } from 'hooks/fullscreen-widgets/use-pinned-fullscreen-widget-list';

import { NavigationItem } from '../NavigationItem';
import { NavigationList } from '../NavigationList';

interface IProps {
  onItemClick(path: string, id: string, trackEngagementTitle?: string, redirectTo?: string): void;
}

export const NavigationApps: FC<IProps> = ({ onItemClick }) => {
  const pinnedWidgets = usePinnedFullscreenWidgetList();

  return (
    <NavigationList>
      {pinnedWidgets.map((item) => (
        <NavigationItem
          key={item.path}
          name={item.name}
          path={item.path}
          id={item.id}
          testId={item.testId}
          trackEngagementTitle={item.trackEngagementTitle}
          onItemClick={onItemClick}
          badgeType={BadgeType.Alert}
          icon={item.iconName}
          customIconUrl={item.customIconUrl}
          badgeComponent={item.badgeComponent}
          getIsActive={item.activityCallback}
        />
      ))}
    </NavigationList>
  );
};
