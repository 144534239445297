import { type FC, useRef, useEffect } from 'react';

import { cx } from '@emotion/css';
import { useDispatch, useSelector } from 'react-redux';

import { OneTrackEvent } from 'constants/one-event';
import { EventPlace } from 'helpers/analytics';
import { useEffectOnce } from 'hooks/use-effect-once';
import { trackEvent } from 'services/event-tracking';
import { OneViewActions } from 'store/views/one/actions';
import { getIsOneModalExpanded } from 'store/views/one/selectors';

import { CommandMenuItem } from './CommandMenuItem';
import { COMMAND_MENU_DATA_TEST_ID } from './constants';
import { type OnePickerOption } from './one-picker-option';

import * as styles from './styles';

interface IProps {
  options: OnePickerOption[];
  selectOption: (option: OnePickerOption) => void;
  setHighlightedIndex: (index: number) => void;
  selectedIndex: number | null;
}

export const CommandMenu: FC<IProps> = ({ options, selectOption, selectedIndex, setHighlightedIndex }) => {
  const dispatch = useDispatch();
  const isExpanded = useSelector(getIsOneModalExpanded);

  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!menuRef?.current) {
      return;
    }

    const elements = menuRef?.current.getElementsByTagName('li');
    const isAnyElements = !!elements.length;
    const isValidSelectedIndex = selectedIndex !== null && selectedIndex >= 0 && selectedIndex < elements.length;

    if (isAnyElements && isValidSelectedIndex) {
      elements[selectedIndex].scrollIntoView({ behavior: 'auto', block: 'nearest' });
    }
  }, [selectedIndex]);

  useEffectOnce(() => {
    dispatch(OneViewActions.openCommandSuggestions());
    trackEvent(OneTrackEvent.QuickSuggestionsDisplayed, EventPlace.One);

    return () => {
      dispatch(OneViewActions.closeCommandSuggestions());
    };
  });

  return (
    <div
      data-testid={COMMAND_MENU_DATA_TEST_ID}
      ref={menuRef}
      className={cx(styles.menu, 'lc-dark-theme', { [styles.popoverMenu]: !isExpanded })}
    >
      <ul>
        {options.map((option, index) => (
          <CommandMenuItem
            isSelected={selectedIndex === index}
            onMouseEnter={() => setHighlightedIndex(index)}
            key={option.title}
            option={option}
            onSelect={selectOption}
          />
        ))}
      </ul>
    </div>
  );
};
