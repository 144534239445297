import type { FC } from 'react';

import { SkeletonWrapper, SkeletonAvatar, SkeletonText } from '@livechat/design-system-react-components';
import { useSelector } from 'react-redux';

import { getIsOneModalExpanded } from 'store/views/one/selectors';

import * as styles from './styles';

export const OneChatFeedSkeleton: FC = () => {
  const isExpanded = useSelector(getIsOneModalExpanded);

  if (!isExpanded) {
    return (
      <SkeletonWrapper animated className={styles.loaderItem}>
        <SkeletonText height={46} />
      </SkeletonWrapper>
    );
  }

  return (
    <div className={styles.loaderContainerExpanded}>
      <SkeletonWrapper vertical animated className={styles.loaderItemExpanded}>
        <SkeletonAvatar size={32} />
        <SkeletonText height={240} />
        <div className={styles.loaderEmptyPlaceholder}></div>
      </SkeletonWrapper>

      <SkeletonWrapper animated className={styles.loaderItemExpanded}>
        <div className={styles.loaderEmptyPlaceholder}></div>
        <SkeletonText height={240} />
        <SkeletonAvatar size={32} />
      </SkeletonWrapper>
    </div>
  );
};
