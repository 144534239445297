// @ts-strict-ignore
import kebabCase from 'lodash.kebabcase';
import memoizeOne from 'memoize-one';

import { UserGuidedTourType } from 'constants/user-guided-tour-type';

import { agentsPerformanceReportSteps } from './agents-performance-report/steps';
import { eventTourName } from './constants';
import { customSegmentsSteps } from './customers-custom-segments/steps';
import { customersListSteps } from './customers-list/steps';
import { type ITourStep, TourStepType } from './interfaces';
import { reportViewsSteps } from './report-views/steps';
import { routingRulesSteps } from './routing-rules/steps';
import { tagsUsageTourSteps } from './tags-usage/steps';
import { targetedMessagesSteps } from './targeted-messages/steps';
import { ticketFormSettingsSteps } from './ticket-form-settings/steps';

// eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return
export const constructStepId = (tourId: UserGuidedTourType, step: string): string => kebabCase(`${tourId}-${step}`);

export const getFirstStep = (tourId: UserGuidedTourType): ITourStep => getTourSteps(tourId)[0];

export const getTooltipSteps = memoizeOne((tourId: UserGuidedTourType): ITourStep[] =>
  getTourSteps(tourId).filter((step) => step.type === TourStepType.GuideTooltip)
);

export const getTourStepsCount = (tourId: UserGuidedTourType): number => getTourSteps(tourId).length;

export const getTourEventName = (tourId: UserGuidedTourType): string => eventTourName[tourId] || tourId;

export const getTourStepNumber = (tourId: UserGuidedTourType, stepId: string): number =>
  getTourSteps(tourId).findIndex((s) => s.id === stepId) + 1;

export const getTourSteps = (tourId: UserGuidedTourType): ITourStep[] => {
  const tourStepsConfig = tourSteps[tourId];

  if (tourStepsTypeguard(tourStepsConfig)) {
    return tourStepsConfig;
  }

  return tourStepsConfig();
};

const tourSteps: Partial<Record<UserGuidedTourType, ITourStep[] | (() => ITourStep[])>> = {
  [UserGuidedTourType.CustomersList]: customersListSteps,
  [UserGuidedTourType.TicketFormSettings]: ticketFormSettingsSteps,
  [UserGuidedTourType.TagsUsage]: tagsUsageTourSteps,
  [UserGuidedTourType.TargetedMessages]: targetedMessagesSteps,
  [UserGuidedTourType.CustomSegments]: customSegmentsSteps,
  [UserGuidedTourType.ReportViews]: reportViewsSteps,
  [UserGuidedTourType.RoutingRules]: routingRulesSteps,
  [UserGuidedTourType.Traffic]: customersListSteps,
  [UserGuidedTourType.AgentsPerformanceReport]: agentsPerformanceReportSteps,
};

function tourStepsTypeguard(tourSteps: unknown): tourSteps is ITourStep[] {
  return Array.isArray(tourSteps as ITourStep[]);
}
