import { type IKnowledgeSourceUrlItem, KnowledgeSourceItemStatus } from 'interfaces/knowledge-sources';

const extractKnowledgeSources = (size: number, data: IKnowledgeSourceUrlItem[]): IKnowledgeSourceUrlItem[] => {
  return data.filter((item) => item.status === KnowledgeSourceItemStatus.COMPLETED).slice(0, size);
};

const getRemainingSources = (size: number, data: IKnowledgeSourceUrlItem[]): number | null => {
  const filteredData = data.filter((item) => item.status === KnowledgeSourceItemStatus.COMPLETED);
  const result = filteredData.length - size;

  return result > 0 ? result : null;
};

export const getKnowledgeSourcesData = (
  data: IKnowledgeSourceUrlItem[] | undefined,
  extractedSize: number
): { extractedSources: IKnowledgeSourceUrlItem[]; remainingSourcesCount: number | null } => {
  if (!data) {
    return {
      extractedSources: [],
      remainingSourcesCount: null,
    };
  }
  const extractedSources = extractKnowledgeSources(extractedSize, data);
  const remainingSourcesCount = getRemainingSources(extractedSize, data);

  return {
    extractedSources,
    remainingSourcesCount,
  };
};
