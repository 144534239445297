import { type CustomSectionDefinition } from 'interfaces/custom-section';

import { type IInboxMessage, type INormalizedInboxMessage } from './inbox-message';
import { type IInboxMessageDenormalizer } from './inbox-message-denormalizer';
import { InboxMessageTitles } from './inbox-message-titles';

export interface ICustomerDetailsSectionMessage extends IInboxMessage {
  readonly title: InboxMessageTitles.CustomerDetailsSection;
  readonly data: CustomSectionDefinition;
}

function createCustomerDetailsSectionMessage(
  pluginId: string,
  section: CustomSectionDefinition
): ICustomerDetailsSectionMessage {
  return { title: InboxMessageTitles.CustomerDetailsSection, pluginId, data: section };
}

export const CustomerDetailsSectionMessageDenormalizer: IInboxMessageDenormalizer<ICustomerDetailsSectionMessage> = {
  createMessage: (message: INormalizedInboxMessage) =>
    createCustomerDetailsSectionMessage(message.plugin_id, message.data),
  messageTitle: InboxMessageTitles.CustomerDetailsSection,
};
