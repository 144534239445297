import { QueryKey } from 'constants/query-key';
import { type KeyMap } from 'helpers/interface';
import { type IKnowledgeSourceUrlItem, KnowledgeSourceItemStatus } from 'interfaces/knowledge-sources';
import { getQueryClient } from 'services/query-client/client';
import { type ChatEventEntity } from 'store/entities/chats/interfaces';

export const getIsTheSameMessage = (threadEvents: KeyMap<ChatEventEntity>, messageId: string): boolean => {
  const threadArray = Object.values(threadEvents);
  const threadLastMessage = threadArray[threadArray.length - 1];
  const isTheSameMessage = threadLastMessage?.id === messageId;

  return isTheSameMessage;
};

export const getHasAnyKnowledgeSources = (): boolean => {
  const knowledgeSources = getQueryClient()?.getQueryData<IKnowledgeSourceUrlItem[]>([QueryKey.KnowledgeSourcesList]);
  const hasAnyKnowledgeSources = knowledgeSources?.some((item) => item.status === KnowledgeSourceItemStatus.COMPLETED);

  return !!hasAnyKnowledgeSources;
};
