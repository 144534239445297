import { isBefore, startOfDay } from 'date-fns';

import { type PlanType } from 'constants/plan-type';
import { KNOWLEDGE_SOURCES_LIMITS_RELEASE_DATE, PLAN_LIMITS } from 'constants/reply-suggestions';
import { type IKnowledgeSourceUrlItem, KnowledgeSourceItemStatus } from 'interfaces/knowledge-sources';

export const getIsAnyKnowledgeSource = (data: IKnowledgeSourceUrlItem[] = []): boolean => {
  const completedList = data.filter(
    (item) =>
      item.status === KnowledgeSourceItemStatus.COMPLETED ||
      (item.status === KnowledgeSourceItemStatus.FAILED && !!item?.learnedAt)
  );

  return !!completedList.length;
};

export const getFilteredKnowledgeSourceLength = (
  data: IKnowledgeSourceUrlItem[] = [],
  filterStatus: KnowledgeSourceItemStatus
): number => {
  const filteredList = data.filter(
    (item) => item.status !== filterStatus || (item.status === filterStatus && !!item?.learnedAt)
  );

  return filteredList.length;
};

export const isBelowKnowledgeSourceLimit = (data: IKnowledgeSourceUrlItem[] = [], plan: PlanType | null): boolean => {
  if (!plan) {
    return false;
  }

  const planLimit = PLAN_LIMITS[plan];
  const filteredKnowledgeSources = getFilteredKnowledgeSourceLength(data, KnowledgeSourceItemStatus.FAILED);

  return filteredKnowledgeSources < planLimit;
};

export const getIsBeforeLimitsReleaseDate = (): boolean => {
  const currentDate = startOfDay(new Date());
  const limitsReleaseDate = startOfDay(new Date(KNOWLEDGE_SOURCES_LIMITS_RELEASE_DATE));

  return isBefore(currentDate, limitsReleaseDate);
};
