import { type FC } from 'react';

import { Badge } from '@livechat/design-system-react-components';
import { useDispatch, useSelector } from 'react-redux';

import oneActive from 'assets/img/automation/One-icon-active.png';
import { OneViewActions } from 'store/views/one/actions';
import { getIsOneModalOpened, getOneUndreadMessageCount } from 'store/views/one/selectors';

import * as styles from './styles';

export const MobileNavigationOne: FC = () => {
  const dispatch = useDispatch();

  const isOneOpened = useSelector(getIsOneModalOpened);
  const unreadOneMessages = useSelector(getOneUndreadMessageCount);

  const shouldDisplayOneBadge = unreadOneMessages > 0 && !isOneOpened;

  const toggleOneModal = (): void => {
    if (isOneOpened) {
      dispatch(OneViewActions.hideModal());

      return;
    }

    dispatch(OneViewActions.showModal());
  };

  return (
    <div className={styles.mobileNavigationItem} onClick={toggleOneModal}>
      <div className={styles.contentWrapper(isOneOpened)}>
        <img className={styles.oneImage} src={oneActive} alt="One AI assistant" />
        {shouldDisplayOneBadge && <Badge type="dot" size="compact" className={styles.badge} />}
      </div>
    </div>
  );
};
