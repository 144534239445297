import { useQuery } from '@tanstack/react-query';

import { QueryKey } from 'constants/query-key';
import { useParamSelector } from 'hooks/use-param-selector';
import { ApiManager } from 'services/api/api-manager';
import { getApplicationBySlug, getPrivateApplication } from 'store/entities/applications/selectors';

import { normalizeUpgrades, type INormalizedUpgrades } from '../../helpers';

interface IUseFetchCampaign {
  data?: INormalizedUpgrades;
  isLoading: boolean;
  isError: boolean;
}

const fetchInAppUpgrades = async (applicationId: string): Promise<INormalizedUpgrades> => {
  const { result, error } = await ApiManager.inAppUpgradesApi.fetchUpgrades(applicationId);

  if (error) {
    throw error;
  }

  return normalizeUpgrades(result);
};

export const useFetchInAppUpgrades = (slug: string): IUseFetchCampaign => {
  const application = useParamSelector(getApplicationBySlug, slug);
  const privateApplication = useParamSelector(getPrivateApplication, slug);

  const applicationId = application?.id || privateApplication?.id;

  const { data, isLoading, isError } = useQuery(
    [QueryKey.InAppUpgrade, slug],
    () => fetchInAppUpgrades(applicationId),
    { refetchOnWindowFocus: false }
  );

  return { data, isLoading, isError };
};
