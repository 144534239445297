import ky from 'ky';

import { fetchRequest } from './fetch-request';
import { isFileUpload, setHeaders } from './helpers';
import {
  type ApiClientInstance,
  type ApiClientOptions,
  type ApiClientRequestOptions,
  type ApiClientResponse,
  AuthType,
} from './types';
import { xhrRequest } from './xhr-request';

/**
 * Creates an instance of the API client.
 *
 * The API client is an object with two properties:
 * - `instance`: A `KyInstance` created using the `ky.create` method. This instance is configured with the provided `baseURL`, `token`, `authType`, and `prefix`.
 * - `get`, `post`, `put`, `patch`, `delete`: Functions that make a request using the `instance` and handle any errors that occur during the request.
 *
 * @param options - An object containing the options for the API client. This includes the `baseURL` for the API, an optional `token` for authentication, an optional `authType` that defaults to `'Bearer'`, and an optional `prefix` for the API URL.
 * @returns An `ApiClientInstance` with the created `KyInstance` and request functions.
 */
export const createApiClient = (options: ApiClientOptions): ApiClientInstance => {
  const { baseURL, getCredentials, getRegion, onError, authType = AuthType.Bearer, prefix = '' } = options;
  const fullUrl = new URL(prefix, baseURL).toString();

  const client = ky.create({
    prefixUrl: fullUrl,
    hooks: {
      beforeRequest: [
        (request) => {
          setHeaders({ request, getCredentials, getRegion, authType });
        },
      ],
    },
  });

  /**
   * Makes a request using the provided `KyInstance` and handles any errors that occur during the request.
   *
   * The function first attempts to make the request and parse the response based on the provided `responseType`. If the request is successful, the function returns a response object with the parsed data and a `null` error.
   *
   * If an error occurs during the request, the function handles the error based on its type. If the error is an `ApiClientError`, the function handles it using the `handleApiClientError` function. If the error is an `Error`, the function returns a response object with a `null` result and the error. If the error is an unknown error, the function handles it using the `handleUnknownError` function.
   *
   * @param url - The URL for the request.
   * @param options - An optional object containing the options for the request. This includes the `responseType` for the response. The `responseType` can be 'json', 'text', 'blob', 'arrayBuffer', or 'formData'. If no `responseType` is provided, it defaults to 'json'.
   * @returns A promise that resolves to an `ApiClientResponse` with the result of the request and any error that occurred. The type of the result depends on the `responseType`: 'json' and no `responseType` return `T | null`, 'text' returns `string | null`, 'blob' returns `Blob | null`, 'arrayBuffer' returns `ArrayBuffer | null`, and 'formData' returns `FormData | null`.
   * @template T - The type of the data in the response. This is used when the `responseType` is 'json' or not provided.
   * @template E - The type of the error data in the error response.
   */
  function request<T, E>(url: string, options: ApiClientRequestOptions): Promise<ApiClientResponse<T, E>>;
  function request<T, E>(
    url: string,
    options: ApiClientRequestOptions & { responseType: 'json' }
  ): Promise<ApiClientResponse<T, E>>;
  function request<E>(
    url: string,
    options: ApiClientRequestOptions & { responseType: 'blob' }
  ): Promise<ApiClientResponse<Blob, E>>;
  function request<E>(
    url: string,
    options: ApiClientRequestOptions & { responseType: 'text' }
  ): Promise<ApiClientResponse<string, E>>;
  function request<E>(
    url: string,
    options: ApiClientRequestOptions & { responseType: 'arrayBuffer' }
  ): Promise<ApiClientResponse<ArrayBuffer, E>>;
  function request<E>(
    url: string,
    options: ApiClientRequestOptions & { responseType: 'formData' }
  ): Promise<ApiClientResponse<FormData, E>>;
  async function request<T, E>(
    url: string,
    options: ApiClientRequestOptions
  ): Promise<ApiClientResponse<unknown, unknown>> {
    const { responseType = 'json', onProgress, ...kyOptions } = options;

    const shouldUseXHR = kyOptions.body && isFileUpload(kyOptions.body);

    if (shouldUseXHR) {
      return await xhrRequest<T, E>(new URL(url, fullUrl), {
        ...options,
        getCredentials,
        getRegion,
        authType,
        baseURL,
        onError,
      });
    }

    return await fetchRequest<T, E>(url, { client, responseType, onError, ...kyOptions });
  }

  return {
    instance: client,
    baseURL,
    get: <T, E>(url: string, options?: ApiClientRequestOptions) => request<T, E>(url, { ...options, method: 'get' }),
    post: <T, E>(url: string, options?: ApiClientRequestOptions) => request<T, E>(url, { ...options, method: 'post' }),
    put: <T, E>(url: string, options?: ApiClientRequestOptions) => request<T, E>(url, { ...options, method: 'put' }),
    patch: <T, E>(url: string, options?: ApiClientRequestOptions) =>
      request<T, E>(url, { ...options, method: 'patch' }),
    delete: <T, E>(url: string, options?: ApiClientRequestOptions) =>
      request<T, E>(url, { ...options, method: 'delete' }),
  };
};
