import { useCallback, type FC } from 'react';

import { cx } from '@emotion/css';
import { Close, Notifications } from '@livechat/design-system-icons';
import { Button, Icon } from '@livechat/design-system-react-components';
import { useDispatch } from 'react-redux';

import { Cookie } from 'constants/cookies';
import { GlobalModal } from 'constants/global-modal';
import { TopBarNotificationType } from 'constants/notifications';
import { EventPlace } from 'helpers/analytics';
import { setCookie } from 'helpers/cookies';
import { trackEvent } from 'services/event-tracking';
import { GlobalModalActions } from 'store/features/global-modals/actions';
import { NotificationsBarActions } from 'store/features/notifications-bar/actions';

import * as styles from '../styles';

interface IProps {
  onSkip(): void;
}

const COOKIE_DAYS_EXPIRATION = 5;

export const UnblockNotificationsBar: FC<IProps> = ({ onSkip }) => {
  const dispatch = useDispatch();

  const handleUnblockNotificationsBtnClick = (): void => {
    trackEvent('Unblock notifications button clicked', EventPlace.TopBar, {
      topBar: TopBarNotificationType.UnblockNotifications,
    });
    dispatch(GlobalModalActions.showModal(GlobalModal.Notifications));
    dispatch(NotificationsBarActions.hideNotificationsBar(TopBarNotificationType.BrowserNotifications));
  };

  const handleSkip = useCallback(() => {
    setCookie(Cookie.UnblockNotificationsBarSkipped, 1, { days: COOKIE_DAYS_EXPIRATION });
    onSkip();
  }, [onSkip]);

  return (
    <div className={cx(styles.contentContainer, styles.warningContainer)}>
      <div className={styles.skippableContent}>
        <Icon source={Notifications} />
        <span className={styles.unblockNotificationTextStyles}>
          We strongly recommend unblocking notifications so you don’t miss chats!
        </span>
        <Button
          tabIndex={-1}
          className={styles.blackButton}
          kind="secondary"
          onClick={handleUnblockNotificationsBtnClick}
          size="compact"
        >
          Unblock browser notifications
        </Button>
      </div>
      <button
        tabIndex={-1}
        className={styles.iconButton}
        onClick={handleSkip}
        aria-label="Close the prompt about blocked notifications"
      >
        <Icon source={Close} />
      </button>
    </div>
  );
};
