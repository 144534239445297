// @ts-strict-ignore
import { isAfter } from 'date-fns';

import { Cookie } from 'constants/cookies';
import { Interval } from 'constants/date';
import { type PlanType } from 'constants/plan-type';
import { setCookie, getCookie } from 'helpers/cookies';
import { dateDifference, convertToUnixTimestamp } from 'helpers/date';
import { isDefined } from 'helpers/is-defined';
import { isBlackFridayCoupon, isCovidCoupon } from 'helpers/recurly';
import { preparePlanCode } from 'helpers/subscription';
import { getSubscriptionPlanName } from 'helpers/subscription-plans';
import {
  type ISubscription,
  type ICard,
  BillingCycleType,
  type INewSubscription,
  type ICoupon,
} from 'interfaces/subscription';
import { AppStateProvider } from 'services/app-state-provider';
import { browserHistory } from 'services/browser-history';
import { getDiscountCoupon, getIsAutomaticUpsellingEnabled } from 'store/entities/license-properties/selectors';

import { type ICardDTO, type ICouponDTO, type ISubscriptionDTO } from './interfaces';

interface ISubscriptionSerializeProps {
  isNewCouponApplicable: boolean;
}

interface ISerializedSubscription {
  plan: PlanType;
  seats: number;
  months: BillingCycleType;
  coupon_code: string | null;
}

export abstract class SubscriptionSerializer {
  static deserializeCard(card: ICardDTO): ICard | null {
    if (!card) {
      return null;
    }

    return {
      type: card.type,
      firstSix: card.first_six,
      lastFour: card.last_four,
    };
  }

  static deserializeCoupon(coupon: ICouponDTO): ICoupon | null {
    if (!coupon) {
      return null;
    }

    const { name, type, value, single_use } = coupon;

    return {
      name,
      type,
      value,
      singleUse: single_use,
    };
  }

  static getCouponFromParams(isSubscriber: boolean): string | null {
    const couponName = browserHistory.queryParams.coupon as string;

    // Black Friday promotion (can be used for every year promotion)
    // Prevent active licenses to use black Friday coupon
    if (isSubscriber && isBlackFridayCoupon(couponName)) {
      return null;
    }

    // Don't allow to use these coupons directly from params
    if (isCovidCoupon(couponName)) {
      return null;
    }

    return couponName;
  }

  static getCouponCode(isSubscriber: boolean): string | null {
    const couponCodeFromParams = this.getCouponFromParams(isSubscriber);
    const couponCodeFromCookie = getCookie<string>(Cookie.CouponCode);

    if (couponCodeFromParams !== couponCodeFromCookie) {
      setCookie(Cookie.CouponCode, couponCodeFromCookie, { days: 7 });
    }

    return couponCodeFromParams || couponCodeFromCookie || AppStateProvider.selectFromStore(getDiscountCoupon);
  }

  static deserialize(data: ISubscriptionDTO): ISubscription {
    const isExpired = isAfter(new Date(), new Date(data.end_timestamp));
    const billingCycle = Object.values(BillingCycleType).includes(data.months)
      ? data.months
      : BillingCycleType.Annually12;
    const currentPeriodStartedAt = convertToUnixTimestamp(new Date(data.current_period_started_at).getTime());
    const currentPeriodEndsAt = convertToUnixTimestamp(
      new Date(isExpired ? data.end_timestamp : data.current_period_ends_at || data.end_timestamp).getTime()
    );
    const daysLeft = dateDifference(new Date(), new Date(currentPeriodEndsAt * 1000), Interval.Day, true);
    const isCustomDeal =
      !data.in_trial && (!Object.values(BillingCycleType).includes(data.months) || data.collection_method === 'manual');

    const subscription: ISubscription = {
      licenseId: parseInt(data.account) || data.licence_id,
      plan: data.plan,
      planName: getSubscriptionPlanName(data.plan),
      planCode: preparePlanCode(data.plan, billingCycle),
      billingCycle,
      seats: data.seats,
      amount: isDefined(data.amount) ? +data.amount / 100 : null,
      currentPeriodStartedAt,
      currentPeriodEndsAt,
      daysLeft,
      isExpired,
      isExpiringSoon: daysLeft < 3,
      isPastDue: data.past_due,
      isCustomDeal,
      hasManualPayments: data.collection_method === 'manual',
      card: this.deserializeCard(data.card),
      inTrial: data.in_trial,
      isTaxed: data.is_taxed,
      isCanceled: data.canceled,
      credit: data.credit || 0,
      subscriber: data.subscriber,
      email: data.email,
      coupon: this.deserializeCoupon(data.coupon),
      couponCode: this.getCouponCode(data.subscriber),
      automaticUpsellingEnabled: AppStateProvider.selectFromStore(getIsAutomaticUpsellingEnabled),
      partnerName: data.partner_name,
      partnerEmail: data.partner_email,
      origin: data.origin,
      pendingChange: data.pending_change && {
        amount: +data.pending_change.amount / 100,
        billingCycle: Object.values(BillingCycleType).includes(data.pending_change.months)
          ? data.pending_change.months
          : BillingCycleType.Annually12,
        seats: data.pending_change.seats,
        plan: data.pending_change.plan,
        isCustomDeal: !Object.values(BillingCycleType).includes(data.pending_change.months),
      },
    };

    return subscription;
  }

  static serialize(
    newSubscription: INewSubscription,
    { isNewCouponApplicable }: ISubscriptionSerializeProps
  ): ISerializedSubscription {
    const { plan, seats, billingCycle, couponCode } = newSubscription;

    return {
      plan,
      seats,
      months: billingCycle,
      coupon_code: isNewCouponApplicable ? couponCode : null,
    };
  }
}
