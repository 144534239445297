export enum OneTrackEvent {
  AddSourceClicked = 'One add source clicked', //?
  CloseDetails = 'One details closed',
  OpenDetails = 'One details opened',
  QuickSuggestionsDisplayed = 'One quick access suggestion displayed',
  Onboarding = 'One onboarding',
  ChatOpened = 'One chat opened',
  ChatClosed = 'One chat closed',
  MessageSent = 'One message sent',
  MessageReceived = 'One message received',
  Resized = 'One chat resized',
  NewMessage = 'One message toast displayed',
  ActionPerformed = 'One message action performed',
  LearnMoreClicked = 'One learn more clicked',
  ViewSourceClicked = 'One view sources clicked',
}
