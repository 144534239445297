import { type ChatChannelClientId } from 'helpers/chat-channels';

/**
 * Enum for namespaces.
 */
export const enum PlatformNamespace {
  CORE = 'core',
  CHATS = 'chats',
  SMART_ACTIONS = 'smart_actions',
  STATISTICS = 'statistics',
  LIMITS = 'limits',
}

/**
 * Combined type of namespace and chat channel client ID.
 */
export type Namespace = PlatformNamespace | ChatChannelClientId;

/**
 * Enum for core properties.
 */
export const enum CoreProperty {
  ArchivedChatsReplyEnabled = 'archived_chats_reply_enabled',
  AttachmentsEnableForAgents = 'attachments.enable_for_agents',
  AttachmentsEnableForVisitors = 'attachments.enable_for_visitors',
  ChatBetweenGroups = 'chat_between_groups',
  ContinuousChatWidgetEnabled = 'continuous_chat_widget_enabled',
  CustomerHistoryEnabled = 'customer_history_enabled',
  DefaultGroup = 'default_group',
  DefaultWidget = 'default_widget',
  MaskCreditCards = 'mask_credit_cards',
  MessagingModeForceDisable = 'messaging_mode_force_disable',
  TranscriptEmails = 'transcript_emails',
}

export const enum ChatsProperty {
  ArchiveChatAfterMin = 'archive_chat_after_min',
  ArchiveOfflineChatAfterMin = 'archive_offline_chat_after_min',
  ChatInactiveAfterMin = 'chat_inactive_after_min',
  CustomerTimeoutAfterMin = 'customer_timeout_after_min',
  ManualRouting = 'manual_routing',
  MaxAgentActiveChats = 'max_agent_active_chats',
  PreferRecentAgent = 'prefer_recent_agent',
  QueueEnabled = 'queue.enabled',
  QueueLimit = 'queue.limit',
  QueueReopenThreshold = 'queue.reopen_threshold',
  TransferChatAfterMin = 'transfer_chat_after_min',
}

export const enum SmartActionsProperty {
  GreetingMaxInvites = 'greeting.max_invites',
}

export const enum StatisticsProperty {
  WebsiteLastActivity = 'website_last_activity',
}

export const enum LimitsProperty {
  AccessRulesAdditional = 'access_rules.additional',
  AccessRulesBase = 'access_rules.base',
  AccessRulesHard = 'access_rules.hard',
  AgentsAdditional = 'agents.additional',
  AgentsBase = 'agents.base',
  AgentsHard = 'agents.hard',
  ArchivesRetentionAdditional = 'archives_retention.additional',
  ArchivesRetentionBase = 'archives_retention.base',
  ArchivesRetentionHard = 'archives_retention.hard',
  BannedAdditional = 'banned.additional',
  BannedBase = 'banned.base',
  BannedHard = 'banned.hard',
  ConcurrentChatsAdditional = 'concurrent_chats.additional',
  ConcurrentChatsBase = 'concurrent_chats.base',
  ConcurrentChatsHard = 'concurrent_chats.hard',
  CustomersRetentionAdditional = 'customers_retention.additional',
  CustomersRetentionBase = 'customers_retention.base',
  CustomersRetentionHard = 'customers_retention.hard',
  GroupChooserGroupsAdditional = 'group_chooser_groups.additional',
  GroupChooserGroupsBase = 'group_chooser_groups.base',
  GroupChooserGroupsHard = 'group_chooser_groups.hard',
  GroupsAdditional = 'groups.additional',
  GroupsBase = 'groups.base',
  GroupsHard = 'groups.hard',
  GroupsPerAgentAdditional = 'groups_per_agent.additional',
  GroupsPerAgentBase = 'groups_per_agent.base',
  GroupsPerAgentHard = 'groups_per_agent.hard',
  MonitoredCustomersAdditional = 'monitored_customers.additional',
  MonitoredCustomersBase = 'monitored_customers.base',
  MonitoredCustomersHard = 'monitored_customers.hard',
  QueueAdditional = 'queue.additional',
  QueueBase = 'queue.base',
  QueueHard = 'queue.hard',
  QueueContinuousAdditional = 'queue_continuous.additional',
  QueueContinuousBase = 'queue_continuous.base',
  QueueContinuousHard = 'queue_continuous.hard',
  TagsAdditional = 'tags.additional',
  TagsBase = 'tags.base',
  TagsHard = 'tags.hard',
}

export const NO_WEBSITE_ACTIVITY_DATE = '0000-01-01';
