import { BaseApi } from '../base-api';

export interface IEmailiniumTranscriptBody {
  recipient: string;
  chat_id: string;
  thread_id: string;
  include_system_messages?: boolean;
  include_supervisors_and_private_notes?: boolean;
  include_session_fields?: boolean;
  include_geolocation?: boolean;
  include_referrer?: boolean;
  use_visitor_timezone?: boolean;
}

export interface IEmailiniumContactAccountManagerBody {
  message: string;
}

export interface IEmailiniumResponse {
  code: string;
  message: string;
}

export class EmailiniumApi extends BaseApi {
  sendTranscript = (body: IEmailiniumTranscriptBody) => this.post('transcript', body);
  contactAccountManager = (body: IEmailiniumContactAccountManagerBody) =>
    this.post<IEmailiniumResponse, IEmailiniumResponse>('subscription/contact_account_manager', body);
}
