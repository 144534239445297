import debug from 'debug';

import { DebugLogsNamespace } from 'constants/debug-logs-namespace';
import { ServerError } from 'constants/server-error';
import { getServer } from 'helpers/get-server';
import { isWithApiError } from 'helpers/request-decoder';
import { type IServer } from 'interfaces/server';
import { fetchWebsiteLastActivity } from 'services/activity/fetch-website-last-activity';
import { AppStateProvider } from 'services/app-state-provider';
import { isConfigurationApiError } from 'services/connectivity/configuration-api/type-guards';
import { accountsClient } from 'services/connectivity/global-accounts-api/accounts/client';
import { ME_ACCOUNT_ID } from 'services/connectivity/global-accounts-api/accounts/constants';
import { fetchLicensePropertiesWithRetry } from 'services/license-properties';
import { markUserAsLoggedIn } from 'services/mark-user-as-logged-in';
import { SessionActions } from 'store/features/session/actions';

import { fetchCoreDataWithRetry } from './fetch-core-data';
import { fetchGroupDataWithRetry } from './fetch-group-data';
import { handleServerError } from './handle-server-error';
import { login } from './login';
import { handleApiError, handleConfigurationApiError } from './login-failure';

const log = debug(DebugLogsNamespace.AppServerConnection);

interface IServerConnectOptions {
  accessToken: string;
  isGhost: boolean;
}

function connectToServer(server: IServer, options: IServerConnectOptions): Promise<void> {
  return new Promise((resolve, reject) => {
    const connectHandler = (): void => {
      log('Connected to server');
      server.off('connect', connectHandler);
      resolve();
    };

    if (!options.isGhost) {
      server.on('connect', connectHandler);
    }

    try {
      // must connect even in ghost mode because it sets the flag sever.isGhost()
      server.connect(options);
    } catch (error) {
      reject(error);
    }

    if (options.isGhost) {
      resolve();
    }
  });
}

export async function connectAndLogin(options: IServerConnectOptions): Promise<void> {
  const { isGhost, accessToken } = options;
  const server: IServer = getServer();

  try {
    log('Fetching core data');
    await Promise.all([
      fetchLicensePropertiesWithRetry(),
      fetchCoreDataWithRetry(),
      fetchGroupDataWithRetry(!isGhost),
      fetchWebsiteLastActivity(),
    ]);
    log('Core data fetched and stored');
  } catch (error) {
    if (isConfigurationApiError(error)) {
      log('Received configuration API error', error);
      await handleConfigurationApiError(error);
    } else if (isWithApiError(error)) {
      log('Received status error', error);
      await handleApiError(error);
    } else {
      log('Failed to fetch core data');
      await handleServerError(ServerError.UnexpectedError, error);
    }

    // do not attempt to connect any further
    return;
  }

  log('Connecting to server');
  await connectToServer(server, options);

  if (isGhost) {
    const { result: currentAccount } = await accountsClient.get(ME_ACCOUNT_ID);
    const email = currentAccount?.email?.toLowerCase();

    if (email) {
      AppStateProvider.dispatch(
        SessionActions.saveAuthenticationCredentials({
          login: email,
        })
      );

      log('Logging in as ghost');
      void markUserAsLoggedIn();
    }

    return;
  }

  log('Logging in');
  await login(server, accessToken);
}
