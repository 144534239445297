import { type MouseEvent, type FC } from 'react';

import { cx } from '@emotion/css';
import { Button, Text } from '@livechat/design-system-react-components';

import * as styles from './styles';

interface IProps {
  onChangeStatusToOnlineClicked: (event: MouseEvent<HTMLButtonElement> | MouseEvent<HTMLAnchorElement>) => void;
  isMessagingModeEnabled: boolean;
}

export const NotAcceptingChatsBar: FC<IProps> = ({ onChangeStatusToOnlineClicked, isMessagingModeEnabled }) => {
  const info = isMessagingModeEnabled
    ? 'You are not accepting chats, but customers can leave a message.'
    : 'You are currently not accepting chats.';

  return (
    <div className={cx(styles.contentContainer, styles.errorContainer, styles.stayAwayContainer)}>
      <Text>{info} </Text>
      <Button kind="link" size="xcompact" className={styles.linkStyles} onClick={onChangeStatusToOnlineClicked}>
        Start accepting chats now
      </Button>

      <Button
        tabIndex={-1}
        kind="secondary"
        className={cx(styles.button, styles.buttonStyles)}
        onClick={onChangeStatusToOnlineClicked}
        size="compact"
      >
        Start accepting chats
      </Button>
    </div>
  );
};
