import { UserGuidedTourType } from 'constants/user-guided-tour-type';
import { AgentCustomPropertyName } from 'store/features/agent-custom-properties/interfaces';

import type { IUserGuidedTourProperties } from './interfaces';

export const tourProperties: { [key: string]: IUserGuidedTourProperties } = {
  [UserGuidedTourType.RichGreeting]: { tourDoneAgentProp: AgentCustomPropertyName.RichGreetingGuidedTourDone },
  [UserGuidedTourType.CustomSegments]: { tourDoneAgentProp: AgentCustomPropertyName.CustomersSegmentsTourDone },
  [UserGuidedTourType.TargetedMessages]: { tourDoneAgentProp: AgentCustomPropertyName.GreetingsTourSeen },
  [UserGuidedTourType.ReportViews]: { tourDoneAgentProp: AgentCustomPropertyName.ReportViewsTourDone },
  [UserGuidedTourType.AgentsPerformanceReport]: {
    tourDoneAgentProp: AgentCustomPropertyName.AgentsPerformanceTourSeen,
  },
};

export const eventTourName: { [key: string]: string } = {
  [UserGuidedTourType.ChatCannedResponses]: 'onboardingChatsCanneds',
  [UserGuidedTourType.CustomersList]: 'onboardingCustomersFiltersSegments',
  [UserGuidedTourType.TicketPrivacy]: 'onboardingTicketsMessageType',
  [UserGuidedTourType.TicketStatus]: 'onboardingTicketsStatus',
  [UserGuidedTourType.TagsUsage]: 'onboardingTagsUsage',
  [UserGuidedTourType.HomeScreenExplore]: 'onboardingHomeScreenExplore',
  [UserGuidedTourType.OpenChat]: 'openChat',
  [UserGuidedTourType.RichGreeting]: 'onboardingRichGreeting',
  [UserGuidedTourType.MultipleGreetings]: 'multipleGreetings',
  [UserGuidedTourType.TargetedMessages]: 'onboardingTargetedMessages',
  [UserGuidedTourType.RoutingRules]: 'onboardingRoutingRules',
  [UserGuidedTourType.Traffic]: 'trafficFiltersRules',
  [UserGuidedTourType.AgentsPerformanceReport]: 'onboardingAgentsPerformanceReport',
};
