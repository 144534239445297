import { useQuery } from '@tanstack/react-query';

import { QueryKey } from 'constants/query-key';
import { ApiManager } from 'services/api/api-manager';
import type { DeveloperProfile } from 'services/api/developers/interfaces';

interface UseDeveloperProfile {
  data?: DeveloperProfile;
  isLoading: boolean;
  isError: boolean;
}

const fetchDeveloperProfile = async (id?: string): Promise<DeveloperProfile> => {
  if (!id) {
    throw new Error('Developer ID is required');
  }

  const { result, error } = await ApiManager.developersApi.fetchDeveloperProfile(id);

  if (error) {
    throw error;
  }

  return result;
};

export const useDeveloperProfile = (id?: string): UseDeveloperProfile => {
  const { data, isLoading, isError } = useQuery([QueryKey.DeveloperProfile, id], () => fetchDeveloperProfile(id), {
    refetchOnWindowFocus: false,
  });

  return { data, isLoading, isError };
};
