import { type FC } from 'react';

import { Close } from '@livechat/design-system-icons';
import { Icon } from '@livechat/design-system-react-components';
import { useSelector } from 'react-redux';

import { getActiveAgentsCount } from 'store/entities/agents/selectors';

import { useResourceContext } from '../../hooks/use-resource-context';
import { NameCell } from '../../in-app-upgrade-table/in-app-upgrade-row/NameCell';
import { PriceCell } from '../../in-app-upgrade-table/in-app-upgrade-row/PriceCell';
import { QuantityCell } from '../../in-app-upgrade-table/in-app-upgrade-row/QuantityCell';

import * as styles from './styles';

interface IProps {
  onUpdateQuantity(quantity: number): void;
}

export const Resource: FC<IProps> = ({ onUpdateQuantity }) => {
  const agentsCount = useSelector(getActiveAgentsCount);
  const { payPerAgent } = useResourceContext();

  return (
    <div className={styles.resource}>
      <div className={styles.name}>
        <h6 className={styles.header}>NAME</h6>
        <NameCell />
      </div>

      <div className={styles.price}>
        <h6 className={styles.header}>PRICE</h6>
        <PriceCell />
      </div>

      <div className={styles.multipliers}>
        {payPerAgent && (
          <>
            <div className={styles.multiplierIcon}>
              <Icon source={Close} size="small" />
            </div>

            <div>
              <h6 className={styles.header}>AGENTS</h6>
              <p>{agentsCount}</p>
            </div>
          </>
        )}

        <div className={styles.multiplierIcon}>
          <Icon source={Close} size="small" />
        </div>

        <div className={styles.quantity}>
          <h6 className={styles.header}>QUANTITY</h6>
          <QuantityCell onUpdateQuantity={onUpdateQuantity} editable />
        </div>
      </div>
    </div>
  );
};
