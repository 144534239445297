import type { RequestResult } from 'interfaces/api/client';
import type {
  IApiBaseGoal,
  IApiExtendedCreateGoal,
  IApiDeleteGoal,
  IApiExtendedGoal,
  IApiUpdateGoal,
  IApiGetGoal,
} from 'store/entities/goals/interfaces';

import { BaseApi } from '../base-api';

export class GoalApi extends BaseApi {
  protected readonly prefix = 'goals';

  fetchGoals = (): Promise<RequestResult<IApiBaseGoal[]>> => this.get();
  fetchGoalById = (goalId: IApiGetGoal): Promise<RequestResult<IApiExtendedGoal>> => this.get(`${goalId}`);
  createGoal = (goalData: IApiExtendedCreateGoal): Promise<RequestResult<IApiExtendedGoal>> => this.post('', goalData);
  updateGoal = (goal: IApiUpdateGoal): Promise<RequestResult<IApiExtendedGoal>> => this.put(`${goal.id}`, goal);
  deleteGoal = (goalId: IApiDeleteGoal): Promise<RequestResult<void>> => this.delete(`${goalId}`);
}
