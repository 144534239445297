import type { FC } from 'react';

import { useOneClickPaymentContext } from 'routes/apps/one-click-payment/hooks/use-one-click-payment-context';
import { Frequency } from 'store/entities/applications/interfaces';

import { AcceptDirectChargeFooter } from './AcceptDirectCharge';
import { AcceptRecurrentChargeFooter } from './AcceptRecurrentCharge';
import { ActivateProductFooter } from './ActivateProduct';
import { ErrorFooter } from './ErrorFooter';
import { FooterLoader } from './FooterLoader';

type Props = {
  isActivationRequired: boolean;
};

export const PurchaseModalFooter: FC<Props> = ({ isActivationRequired }) => {
  const { isLoading, charge } = useOneClickPaymentContext();

  if (isLoading) {
    return <FooterLoader />;
  }

  if (isActivationRequired) {
    return <ActivateProductFooter />;
  }

  if (charge?.type === Frequency.Once) {
    return <AcceptDirectChargeFooter />;
  }

  if (charge?.type === Frequency.Monthly) {
    return <AcceptRecurrentChargeFooter />;
  }

  return <ErrorFooter />;
};
