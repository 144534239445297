import type { FC } from 'react';

import { Text } from '@livechat/design-system-react-components';

import { formatDecimalPrice } from 'routes/apps/one-click-payment/helpers';
import { Frequency } from 'store/entities/applications/interfaces';

import * as styles from './styles';

interface Props {
  value: number;
  frequency?: Frequency;
  months?: number;
}

export const PriceFormatter: FC<Props> = ({ value, frequency, months = 1 }) => {
  if (!value) {
    return null;
  }

  const formattedValue = formatDecimalPrice(value);

  return (
    <>
      ${formattedValue}
      {frequency === Frequency.Monthly && (
        <Text className={styles.frequency} as="span" size="sm">
          {months === 1 && '/mo'}
          {months === 12 && '/yr'}
        </Text>
      )}
    </>
  );
};
