import { type StartupErrorViewState } from './interfaces';

export interface WithStartupErrorViewState {
  views: {
    startupError: StartupErrorViewState;
  };
}

export function getIsStartupErrorVisible(state: WithStartupErrorViewState): boolean {
  return state.views.startupError.isVisible;
}
