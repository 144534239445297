import { type OneEntitiesAction, OneEntitiesActionNames } from 'store/entities/one/actions';

import { OneViewActionNames, type OneViewAction } from './actions';
import {
  getStateForCancelOneRequest,
  getStateForCloseOneDetails,
  getStateForCloseCommandSuggestions,
  getStateForHideOneModal,
  getStateForHideOneNewMessageTooltip,
  getStateForIncreaseOneUnreadMessagesCount,
  getStateForOneIsLoading,
  getStateForOpenOneDetails,
  getStateForOpenCommandSuggestions,
  getStateForRemoveOneDraftMessage,
  getStateForResetOneUnreadMessagesCount,
  getStateForSaveOneDraftMessage,
  getStateForSetOneListStateSnapshot,
  getStateForSetScrollPosition,
  getStateForShowOneModal,
  getStateForShowOneNewMessageTooltip,
  getStateForToggleOneModal,
  getStateForToggleOneModalExpand,
} from './helpers';
import { type OneViewState } from './interfaces';

const initialState: OneViewState = {
  draftMessage: '',
  unreadMessagesCount: 0,
  isLoading: false,
  listStateSnapshot: null,
  modal: {
    isOpened: false,
    isExpanded: false,
    scrollPosition: null,
    isDetailsOpened: false,
  },
  isNewMessageToastVisible: false,
  isCommandSuggestionsOpened: false,
};

export const oneViewReducer = (
  state: OneViewState = initialState,
  action: OneViewAction | OneEntitiesAction
): OneViewState => {
  switch (action.type) {
    case OneViewActionNames.RESET_ONE_UNREAD_MESSAGES_COUNT: {
      return getStateForResetOneUnreadMessagesCount(state);
    }
    case OneViewActionNames.SET_ONE_IS_LOADING: {
      return getStateForOneIsLoading(state, action.payload);
    }
    case OneViewActionNames.INCREASE_ONE_UNREAD_MESSAGES_COUNT: {
      return getStateForIncreaseOneUnreadMessagesCount(state, action.payload);
    }
    case OneViewActionNames.SET_ONE_LIST_STATE_SNAPSHOT: {
      return getStateForSetOneListStateSnapshot(state, action.payload);
    }
    case OneViewActionNames.SAVE_ONE_DRAFT_MESSAGE: {
      return getStateForSaveOneDraftMessage(state, action.payload);
    }
    case OneViewActionNames.REMOVE_ONE_DRAFT_MESSAGE: {
      return getStateForRemoveOneDraftMessage(state);
    }
    case OneEntitiesActionNames.CANCEL_ONE_REQUEST: {
      return getStateForCancelOneRequest(state);
    }
    case OneViewActionNames.SHOW_ONE_MODAL: {
      return getStateForShowOneModal(state);
    }
    case OneViewActionNames.HIDE_ONE_MODAL: {
      return getStateForHideOneModal(state);
    }
    case OneViewActionNames.TOGGLE_ONE_MODAL_EXPAND: {
      return getStateForToggleOneModalExpand(state);
    }
    case OneViewActionNames.TOGGLE_ONE_MODAL: {
      return getStateForToggleOneModal(state);
    }
    case OneViewActionNames.SET_SCROLL_POSITION: {
      return getStateForSetScrollPosition(state, action.payload);
    }
    case OneViewActionNames.OPEN_ONE_DETAILS: {
      return getStateForOpenOneDetails(state);
    }
    case OneViewActionNames.CLOSE_ONE_DETAILS: {
      return getStateForCloseOneDetails(state);
    }
    case OneViewActionNames.SHOW_ONE_NEW_MESSAGE_TOAST: {
      return getStateForShowOneNewMessageTooltip(state);
    }
    case OneViewActionNames.HIDE_ONE_NEW_MESSAGE_TOAST: {
      return getStateForHideOneNewMessageTooltip(state);
    }
    case OneViewActionNames.OPEN_COMMAND_SUGGESTIONS: {
      return getStateForOpenCommandSuggestions(state);
    }
    case OneViewActionNames.CLOSE_COMMAND_SUGGESTIONS: {
      return getStateForCloseCommandSuggestions(state);
    }
    default:
      return state;
  }
};
