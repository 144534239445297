import { type FC } from 'react';

import { SPOTLIGHT_MEDIA_QUERY } from 'constants/spotlight';
import { isSpotlightEnabled } from 'helpers/spotlight';
import { useScreenSize } from 'hooks/use-screen-size';

import { TopBar } from './TopBar';

import { topBarContainer } from './styles';

export const TopBarWrapper: FC = () => {
  const { isCustomScreen } = useScreenSize({ minWidth: SPOTLIGHT_MEDIA_QUERY });

  if (isCustomScreen && isSpotlightEnabled) {
    return (
      <div className={topBarContainer}>
        <TopBar />
      </div>
    );
  }

  return null;
};
