import * as React from 'react';

import type { IPickerListItem } from '@livechat/design-system-react-components';
import { addDays, format, isToday, isTomorrow } from 'date-fns';
import memoizeOne from 'memoize-one';

import { Item } from 'components/ds-multiselect/Item';
import { DateFormat } from 'constants/date';
import { type Weekday } from 'constants/filters/weekday-filter';
import { getWeekdayName } from 'helpers/date';

interface ISingleItemProps {
  label: string;
  value: Weekday;
}

export interface ISingleItem {
  key: string;
  props: ISingleItemProps;
}

function getLabel(date: Date) {
  let label = format(date, DateFormat.DayOfWeekWithMonth);

  if (isToday(date)) {
    label = `Today (${label})`;
  } else if (isTomorrow(date)) {
    label = `Tomorrow (${label})`;
  }

  return label;
}

const calculateItems = memoizeOne((options: Date[]) =>
  options.map((date) => {
    const day = getWeekdayName(date);

    return {
      key: day,
      props: {
        label: getLabel(date),
        value: day,
      },
    };
  })
);

export function getItems(startDate: Date): ISingleItem[] {
  const items = new Array(7).fill(null).map((_element, index) => addDays(startDate, index));

  return calculateItems(items) as ISingleItem[];
}

export const getPickerItems = (items: ISingleItem[]): IPickerListItem[] => {
  return items.map((item) => ({
    key: item.key,
    name: item.props.label,
  }));
};

export function getItemBody(props: ISingleItemProps): React.ReactNode {
  return (
    <Item title={props.label} id={props.value}>
      {props.label}
    </Item>
  );
}
