import { getConfig } from 'helpers/config';

export const DEV_CONSOLE_URL = `${getConfig().platformUrl}/console`;
export const PLATFORM_DOCS_URL = `${getConfig().platformUrl}/docs`;

export enum MarketplaceSettingsPaths {
  InstalledApps = '/apps/settings/manage-apps',
  PrivateApps = '/apps/settings/manage-apps/private',
  InReviewApps = '/apps/settings/manage-apps/in-review',
  ApiPromo = '/apps/settings/manage-apps/api',
}

export enum MarketplaceAppsRoutes {
  Apps = '/apps/marketplace/apps',
  PrivateApps = '/apps/marketplace/private-apps',
  InReviewApps = '/apps/marketplace/in-review-apps',
}
