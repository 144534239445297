import { type AccountAction, AccountsActionNames } from './actions';
import { type IAccountsState, InvitationMode } from './interfaces';

const initialState: IAccountsState = {
  accounts: {},
  roles: {},
  currentOrganizationId: '',
  invitationMode: InvitationMode.Strict,
};

export const accountsReducer = (state: IAccountsState = initialState, action: AccountAction): IAccountsState => {
  switch (action.type) {
    case AccountsActionNames.SET_ACCOUNTS: {
      return {
        ...state,
        accounts: action.payload.reduce((acc, item) => {
          acc[item.accountId] = item;

          return acc;
        }, {}),
      };
    }

    case AccountsActionNames.SET_ROLES: {
      return {
        ...state,
        roles: action.payload.reduce((acc, item) => {
          acc[item.roleId] = item;

          return acc;
        }, {}),
      };
    }

    case AccountsActionNames.SET_CURRENT_ORGANIZATION_ID: {
      const { organizationId } = action.payload;

      return {
        ...state,
        currentOrganizationId: organizationId,
      };
    }

    case AccountsActionNames.SET_INVITATION_MODE: {
      const { invitationMode } = action.payload;

      return {
        ...state,
        invitationMode,
      };
    }

    default:
      return state;
  }
};
