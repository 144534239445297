// @ts-strict-ignore
import cloneDeep from 'lodash.clonedeep';
import { type SagaIterator } from 'redux-saga';
import { select } from 'redux-saga/effects';

import { type IKeyValue } from 'helpers/url';
import { type IArchiveFilters } from 'services/api/archive/interfaces';
import { type ReportsViewFilters } from 'interfaces/reports';
import { allAgentsIdsSelector } from 'store/views/team/computed';

function isReportFilters(params: unknown): params is ReportsViewFilters {
  return !!(<ReportsViewFilters>params)?.agent?.agents;
}

type IParams = IKeyValue<string> | ReportsViewFilters | IArchiveFilters;

export function* filterOutInactiveAgents(params: IParams): SagaIterator<IParams> {
  const activeAgents: string[] = yield select(allAgentsIdsSelector);

  if (isReportFilters(params)) {
    const { agents, compare } = params.agent;
    if (!agents) {
      return params;
    }

    const filterdAgents = agents.filter((agentId) => activeAgents.includes(agentId));
    const newParams: ReportsViewFilters = cloneDeep(params);
    newParams.agent = !filterdAgents.length ? null : { agents: filterdAgents };

    if (compare?.length && filterdAgents.length) {
      const filterdAgentsToCompare = compare.filter((agentId) => activeAgents.includes(agentId));
      newParams.agent.compare = filterdAgentsToCompare.length ? filterdAgentsToCompare : null;
    }

    return newParams;
  }

  return params;
}
