// @ts-strict-ignore
import {
  urlWithProtocol as urlWithProtocolRegex,
  urlWithoutProtocol as urlWithoutProtocolRegex,
  email as emailRegex,
  hexColor as hexColorRegex,
  rgbaColor as rgbaColorRegex,
  rgbColor as rgbColorRegex,
  phoneNumber as phoneNumberRegex,
  urlValidCharacters as urlValidCharactersRegex,
} from './regexp';

export function isURLWithProtocol(value: string): boolean {
  return urlWithProtocolRegex.test(value);
}

export function isEmail(value: string): boolean {
  return emailRegex.test(value);
}

export function isPhoneNumber(value: string): boolean {
  return phoneNumberRegex.test(value);
}

export function isValidUrl(
  url: string,
  options: { protocols: Array<'http' | 'https'> } = { protocols: ['http', 'https'] }
): boolean {
  if (typeof url !== 'string') {
    return false;
  }

  return isURLWithProtocol(url) && options.protocols.some((protocol) => url.startsWith(`${protocol}://`));
}

export function isValidUrlWithoutPrefix(url: string): boolean {
  return urlWithoutProtocolRegex.test(url);
}

export function containsValidUrlCharacters(str: string): boolean {
  return urlValidCharactersRegex.test(str);
}

export function isHexColor(color: string): boolean {
  return hexColorRegex.test(color);
}

export function isRGBA(color: string): boolean {
  return rgbaColorRegex.test(color);
}

export function isRGB(color: string): boolean {
  return rgbColorRegex.test(color);
}

export function isColor(color: string): boolean {
  return isHexColor(color) || color === 'transparent' || isRGBA(color) || isRGB(color);
}

export function validateColorField(color: string): string | undefined {
  if (!isColor(color)) {
    return 'Invalid color value.';
  }

  return undefined;
}

export function validateFileSize(file: File, maxSizeInMB = 2): string {
  if (file.size > maxSizeInMB * 1024 * 1024) {
    return `File size must be less than ${maxSizeInMB} MB.`;
  }

  return null;
}
