import { createAction, type ActionsUnion } from 'store/helper';

import { type SetLicensePropertiesDataPayload, type UpdateLicensePropertiesDataPayload } from './interfaces';

export enum LicensePropertiesActionNames {
  SET_DATA = 'ENTITIES/LICENSE_PROPERTIES/SET_DATA',
  UPDATE_DATA = 'ENTITIES/LICENSE_PROPERTIES/UPDATE_DATA',
}

export const LicensePropertiesActions = {
  setData: (payload: SetLicensePropertiesDataPayload) => createAction(LicensePropertiesActionNames.SET_DATA, payload),
  updateData: (payload: UpdateLicensePropertiesDataPayload) =>
    createAction(LicensePropertiesActionNames.UPDATE_DATA, payload),
};

export type LicensePropertiesAction = ActionsUnion<typeof LicensePropertiesActions>;
