// @ts-strict-ignore
import { type SagaIterator } from 'redux-saga';
import { put, select, takeEvery, take } from 'redux-saga/effects';

import { PlanType } from 'constants/plan-type';
import { calculateRecommendedPlan } from 'helpers/subscription';
import { isHigherPlan } from 'helpers/subscription-plans';
import { type ISubscription } from 'interfaces/subscription';
import { CompanyDetailsActionNames } from 'store/entities/company-details/actions';
import { getCompanyDetails } from 'store/entities/company-details/selectors';
import { SubscriptionActionNames, SubscriptionActions } from 'store/entities/subscription/actions';
import { getSubscription } from 'store/entities/subscription/selectors';
import { type IActionWithPayload } from 'store/helper';
import { SubscriptionViewActionNames, SubscriptionViewActions } from 'store/views/subscription/actions';
import { type ISubscriptionViewSetQueryParamsFromURLPayload } from 'store/views/subscription/interfaces';

// TODO: This saga calls itself by dispatching event on which this saga is subscribed
function* setRecommendedPlan(
  action: IActionWithPayload<SubscriptionViewActionNames, ISubscriptionViewSetQueryParamsFromURLPayload>
): SagaIterator {
  // TODO: To be verified if it's still required, after old subscription cleaning
  let subscription = yield select(getSubscription);
  if (!subscription) {
    yield take(SubscriptionActionNames.SUBSCRIPTION_FETCHED);
    subscription = yield select(getSubscription);
  }

  const { recommendedPlan, cycle } = action.payload.params;
  const { plan: currentPlan, billingCycle }: ISubscription = subscription;
  const isRecommendedHigher = isHigherPlan(recommendedPlan, currentPlan);

  if (cycle && cycle === billingCycle) {
    yield put(
      SubscriptionViewActions.setRecommendedPlanFromQueryParams({
        params: {
          cycle: null,
        },
      })
    );
  }

  if (recommendedPlan && !isRecommendedHigher) {
    yield put(
      SubscriptionViewActions.setRecommendedPlanFromQueryParams({
        params: {
          recommendedPlan: null,
          highlightedFeatures: null,
        },
      })
    );

    return;
  }

  const currentPlanIncludingLegacy = Object.values(PlanType).includes(currentPlan) ? currentPlan : PlanType.Business;

  const selectedPlan = recommendedPlan && isRecommendedHigher ? recommendedPlan : currentPlanIncludingLegacy;
  yield put(
    SubscriptionActions.subscriptionChangeUpdated({
      plan: selectedPlan,
      ...(cycle && { billingCycle: cycle }),
    })
  );
}

function* removeRecommendedPlan(): SagaIterator {
  const subscription: ISubscription = yield select(getSubscription);

  yield put(
    SubscriptionActions.subscriptionChangeUpdated({
      plan: subscription.plan,
      billingCycle: subscription.billingCycle,
    })
  );
}

function* setRecommendedPlanForCompanySize(): SagaIterator {
  let companyDetails: ReturnType<typeof getCompanyDetails> = yield select(getCompanyDetails);

  if (!companyDetails) {
    yield take(CompanyDetailsActionNames.FETCH_COMPANY_DETAILS_SUCCESS);
    companyDetails = yield select(getCompanyDetails);
  }

  let subscription: ReturnType<typeof getSubscription> = yield select(getSubscription);

  if (!subscription) {
    yield take(SubscriptionActionNames.SUBSCRIPTION_FETCHED);
    subscription = yield select(getSubscription);
  }

  yield put(
    SubscriptionViewActions.setRecommendedPlanForCompanySize(
      calculateRecommendedPlan(subscription.plan, companyDetails.size)
    )
  );
}

export function* recommendedPlanSaga(): SagaIterator {
  yield takeEvery('APP_READY', setRecommendedPlanForCompanySize);
  yield takeEvery(SubscriptionViewActionNames.SET_RECOMMENDED_PLAN_FROM_QUERY_PARAMS, setRecommendedPlan);
  yield takeEvery(SubscriptionViewActionNames.REMOVE_RECOMMENDED_PLAN, removeRecommendedPlan);
}
