/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { isBefore, startOfDay } from 'date-fns';
import debug from 'debug';
import isEmpty from 'lodash.isempty';
import uniqBy from 'lodash.uniqby';
import type { SagaIterator } from 'redux-saga';
import { call, debounce, delay, put, race, select, take, takeEvery, takeLatest } from 'redux-saga/effects';

import { DebugLogsNamespace } from 'constants/debug-logs-namespace';
import { FAILED_TO_FETCH_ERROR } from 'constants/failed-to-fetch';
import { PARTIAL_ONE_ASSISTANT_WELCOME_MESSAGE } from 'constants/one';
import { OneTrackEvent } from 'constants/one-event';
import { QueryKey } from 'constants/query-key';
import { type ReportType, reportViewsDetails } from 'constants/reports/report-type';
import { Section } from 'constants/section';
import { SprigEvents } from 'constants/sprig-events';
import { TeamSubPaths } from 'constants/team/routes';
import { EventPlace } from 'helpers/analytics';
import { mapSources } from 'helpers/one/one';
import { navigate } from 'helpers/routing';
import { uniqueId } from 'helpers/string';
import type { RequestResult } from 'interfaces/api/client';
import { type KnowledgeSourceItem } from 'interfaces/knowledge-sources';
import { ApiManager } from 'services/api/api-manager';
import { type Features } from 'services/api/feature-control/interfaces';
import type {
  IGenerateReplyError,
  IGenerateReplyResponse,
  ISendMessageRequest,
} from 'services/api/ml-gateway/interfaces/ai-agents';
import { trackEvent } from 'services/event-tracking';
import { getQueryClient } from 'services/query-client/client';
import { getSprigService } from 'services/sprig';
import { RequestAction } from 'store/entities/actions';
import type { IAgent } from 'store/entities/agents/interfaces';
import { getLoggedInAgent, getLoggedInAgentAccountId, getLoggedInAgentName } from 'store/entities/agents/selectors';
import { AGENT_CUSTOM_PROPERTIES, AgentCustomPropertiesActions } from 'store/features/agent-custom-properties/actions';
import { AgentCustomPropertyName } from 'store/features/agent-custom-properties/interfaces';
import {
  getAgentCustomProperty,
  getOneMessageSentCount,
  getOneOpenedCount,
  getOneSprigSurveySeen,
  hasFetchedAgentCustomProperties,
} from 'store/features/agent-custom-properties/selectors';
import { getCurrentSection } from 'store/features/routing/selectors';
import type { IActionWithPayload } from 'store/helper';
import { MAXIMUM_REQUESTS_FOR_GENERATE_REPLY } from 'store/views/chats/constants';
import { OnboardingActionsEnum } from 'store/views/onboarding/actions';
import { getIsOnboardingFinished } from 'store/views/onboarding/selectors';
import { OneViewActions } from 'store/views/one/actions';
import { getCanUseOnePopover, getIsOneModalOpened } from 'store/views/one/selectors';
import { getCurrentView } from 'store/views/reports/selectors';
import { getPageTitle, getRecentPage } from 'store/views/settings/selectors';
import { getCurrentTeamPage } from 'store/views/team/selectors';

import { AccountsActionNames } from '../accounts/actions';
import { getAccountCreateDate } from '../accounts/selectors';

import { OneEntitiesActionNames, OneEntitiesActions } from './actions';
import {
  MAX_ONE_ONBOARDING_MESSAGES_COUNT,
  MAX_ONE_ONBOARDING_OPENED_COUNT,
  ONE_ONBOARDING_DELAY,
  ONE_POPOVER_RELEASE_DATE,
} from './constants';
import { formatTitle, getSettingsCategoryName, getUserContextString } from './helpers';
import { type IOneOnboardingActionPayload, type OneAddConversationPayload } from './interfaces';
import { type OnboardingConfig, ONE_ONBOARDING_CONFIG, ONE_ONBOARDING_CONFIG_OLD_USERS } from './one-onboarding-config';
import { getFailedOneMessage, getOneAllEvents, getOneCurrentSessionId } from './selectors';

const log = debug(DebugLogsNamespace.AppDebug);

/**
 * It's a simple long-polling mechanism. This is a helper function, which retries a call to AI Agent due to long response time.
 */
function* regenerateResponse(payload: ISendMessageRequest, currentCount = 0): SagaIterator {
  const response: RequestResult<IGenerateReplyResponse, IGenerateReplyError> = yield call(
    ApiManager.mlGatewayApi.sendMessage,
    payload
  );
  const currentSessionId = yield select(getOneCurrentSessionId);

  if (currentSessionId !== payload.session_id || currentCount >= MAXIMUM_REQUESTS_FOR_GENERATE_REPLY) {
    return {};
  }

  // If "Failed to fetch" is present we can be sure to re-fetch such call
  if (response.error?.message === FAILED_TO_FETCH_ERROR) {
    yield call(regenerateResponse, payload, currentCount + 1);
  }

  return response;
}

function* setUnreadMessagesCount(payload?: number): SagaIterator {
  const isOneModalOpened = yield select(getIsOneModalOpened);

  if (!isOneModalOpened) {
    yield put(OneViewActions.increaseOneUnreadMessagesCount(payload ?? 1));
  }
}

function* generateOneMessage(message: string, context?: string): SagaIterator {
  const sessionId = uniqueId();
  const params: ISendMessageRequest = {
    /* eslint-disable @typescript-eslint/naming-convention */
    messages: [
      {
        value: message,
      },
    ],
    session_id: sessionId,
    user_context: context,
    /* eslint-enable @typescript-eslint/naming-convention */
  };

  yield put(
    OneEntitiesActions.setOneSessionId({
      sessionId,
    })
  );

  const { result, error }: RequestResult<IGenerateReplyResponse, IGenerateReplyError> = yield call(
    regenerateResponse,
    params
  );

  const currentRequestId = yield select(getOneCurrentSessionId);

  if (error) {
    yield put(OneViewActions.setOneIsLoading(false));
    if (error?.innerError?.session_id !== sessionId) {
      return;
    }

    yield put(OneEntitiesActions.addOneError(true));

    yield put(OneEntitiesActions.setFailedOneMessage(message));

    trackEvent(OneTrackEvent.MessageReceived, EventPlace.One, { result: 'error' });

    return;
  }

  if (!result || result.session_id !== currentRequestId) {
    return;
  }

  yield put(OneViewActions.setOneIsLoading(false));

  const sources = result?.sources
    ? mapSources(result.sources.filter(({ type }) => type === 'api_call' || type === 'url'))
    : [];

  const uniqueSources = uniqBy(sources, 'url');

  yield put(
    OneEntitiesActions.addOneMessage({
      authorId: 'live-assistant',
      authorType: 'live-assistant',
      eventId: uniqueId(),
      sessionId: result.session_id,
      traceId: result.trace_id,
      text: result.response,
      timestampInMs: Date.now(),
      type: 'text-message',
      properties: {
        sources: uniqueSources,
      },
    })
  );
  yield put(OneEntitiesActions.clearFailedOneMessage());

  trackEvent(OneTrackEvent.MessageReceived, EventPlace.One, { result: 'success' });

  yield call(setUnreadMessagesCount);
}

function* getAppContext(): SagaIterator {
  const currentSection: Section | undefined = yield select(getCurrentSection);
  switch (currentSection) {
    case Section.EngageTraffic: {
      const userSections = [currentSection, 'traffic'];

      return getUserContextString(userSections);
    }

    case Section.EngageCampaigns: {
      const userSections = [currentSection, 'campaigns'];

      return getUserContextString(userSections);
    }

    case Section.EngageGoals: {
      const userSections = [currentSection, 'goals'];

      return getUserContextString(userSections);
    }

    case Section.Team: {
      const currentTeamView: TeamSubPaths = yield select(getCurrentTeamPage);
      const teamView = currentTeamView === TeamSubPaths.Agents ? 'agents' : currentTeamView.split(`/`)[1];

      const userSections = [currentSection, formatTitle(teamView)];

      return getUserContextString(userSections);
    }

    case Section.Reports: {
      const currentReportView: ReportType = yield select(getCurrentView);
      const reportCategory = reportViewsDetails[currentReportView]?.category;
      const userSections = [currentSection, formatTitle(currentReportView), reportCategory];

      return getUserContextString(userSections);
    }

    case Section.Settings: {
      const currentPageTitle: string = yield select(getPageTitle);
      const recentSettingsPage: string = yield select(getRecentPage);
      const userSections = [currentSection, getSettingsCategoryName(recentSettingsPage), currentPageTitle];

      return getUserContextString(userSections);
    }

    default: {
      return getUserContextString([currentSection as string]);
    }
  }
}

function* sendOneMessage(action: IActionWithPayload<string, string>): SagaIterator {
  const canUseOne = yield select(getCanUseOnePopover);
  const oneMessageSentCount = yield select(getOneMessageSentCount);

  if (!canUseOne) {
    return;
  }

  const currentAgent: IAgent | null = yield select(getLoggedInAgent);

  if (!currentAgent) {
    return;
  }

  const uniqueAgentEventId = uniqueId();

  yield put(
    OneEntitiesActions.addOneMessage({
      authorId: currentAgent.login,
      authorType: 'agent',
      eventId: uniqueAgentEventId,
      text: action.payload,
      timestampInMs: Date.now(),
      type: 'text-message',
    })
  );

  yield put(
    OneEntitiesActions.setLatestAgentEventId({
      eventId: uniqueAgentEventId,
    })
  );

  yield put(OneViewActions.setOneIsLoading(true));
  yield put(OneEntitiesActions.clearOneError());

  if (oneMessageSentCount < MAX_ONE_ONBOARDING_MESSAGES_COUNT) {
    yield put(
      AgentCustomPropertiesActions.setAgentCustomProperty({
        [AgentCustomPropertyName.OneMessageSentCount]: oneMessageSentCount + 1,
      })
    );
  }

  const contextString = yield call(getAppContext);
  yield call(generateOneMessage, action.payload, contextString);
  yield call(handleOnePromo);
}

function* retryGenerateOnLastAgentMessage(): SagaIterator {
  const failedMessageContent: string = yield select(getFailedOneMessage);

  if (!failedMessageContent) {
    return;
  }

  yield put(OneViewActions.setOneIsLoading(true));
  yield put(OneEntitiesActions.clearOneError());
  yield call(generateOneMessage, failedMessageContent);
}

function* waitForQuerySuccess(): SagaIterator {
  const queryClient = getQueryClient();
  let queryState = queryClient.getQueryState<Features>([QueryKey.Features]);

  log('[Features] waitForQuerySuccess queryState', queryState?.data?.customerIntentEnabled);

  while (queryState?.fetchStatus === 'fetching') {
    yield delay(200);

    queryState = queryClient.getQueryState<Features>([QueryKey.Features]);

    log('[Features] waitForQuerySuccess queryState assigned', queryState?.data?.customerIntentEnabled);
  }
}

function* waitForAgentCustomProperties(): SagaIterator {
  const hasFetchedCustomProperties = yield select(hasFetchedAgentCustomProperties);

  if (!hasFetchedCustomProperties) {
    yield race({
      success: take(AGENT_CUSTOM_PROPERTIES.FETCH_AGENT_CUSTOM_PROPERTIES[RequestAction.SUCCESS]),
      failure: take(AGENT_CUSTOM_PROPERTIES.FETCH_AGENT_CUSTOM_PROPERTIES[RequestAction.FAILURE]),
    });
  }
}

function* isAccountCreatedBeforeOnePopoverRelease(): SagaIterator {
  const currentAgentId: string = yield select(getLoggedInAgentAccountId);
  let agentCreationDate: string = yield select(getAccountCreateDate, currentAgentId);

  if (!agentCreationDate) {
    yield take(AccountsActionNames.SET_ACCOUNTS);

    agentCreationDate = yield select(getAccountCreateDate, currentAgentId);
  }

  if (!agentCreationDate) {
    return false;
  }

  const sectionReleaseDate = startOfDay(new Date(ONE_POPOVER_RELEASE_DATE));

  return isBefore(agentCreationDate, sectionReleaseDate);
}

function* sendWelcomeMessage(): SagaIterator {
  yield call(waitForQuerySuccess);
  yield call(waitForAgentCustomProperties);

  const canUseOne: boolean = yield select(getCanUseOnePopover);
  const oneAllEvents = yield select(getOneAllEvents);

  if (!canUseOne || !isEmpty(oneAllEvents)) {
    return;
  }

  const loggedAgentName: string = yield select(getLoggedInAgentName);
  const hasSeenOnboarding: boolean = yield select(
    getAgentCustomProperty,
    AgentCustomPropertyName.OnePopoverOnboardingSeen
  );

  if (hasSeenOnboarding) {
    yield put(
      OneEntitiesActions.addOneMessage({
        authorId: 'live-assistant',
        authorType: 'live-assistant',
        eventId: uniqueId(),
        text: `Hey ${loggedAgentName} 👋. ${PARTIAL_ONE_ASSISTANT_WELCOME_MESSAGE}`,
        timestampInMs: Date.now(),
        type: 'text-message',
        properties: {
          withoutActions: true,
        },
      })
    );

    return;
  }
}

function* handleOneOnboardingAction(action: IActionWithPayload<string, IOneOnboardingActionPayload>): SagaIterator {
  const { content, props, eventId } = action.payload;

  yield put(
    OneEntitiesActions.updateOneEvent({
      eventId,
      type: 'text-message',
      properties: {
        withoutActions: true,
      },
    })
  );

  yield put(
    OneEntitiesActions.addOneMessage({
      authorId: 'agent',
      authorType: 'agent',
      eventId: uniqueId(),
      text: content,
      timestampInMs: Date.now(),
      type: 'text-message',
    })
  );

  if (props.navigateUrl) {
    navigate(props.navigateUrl);
    trackEvent(OneTrackEvent.Onboarding, EventPlace.One, { step: 'Adding source clicked' });
  }

  if (props.delay) {
    yield put(OneViewActions.setOneIsLoading(true));
    yield delay(props.delay);
    yield put(OneViewActions.setOneIsLoading(false));
  }

  yield put(
    OneEntitiesActions.addOneMessage({
      authorId: 'live-assistant',
      authorType: 'live-assistant',
      eventId: uniqueId(),
      text: `I’m here if you need any help! 👍`,
      timestampInMs: Date.now(),
      type: 'text-message',
      properties: {
        withoutActions: true,
      },
    })
  );
  yield call(setUnreadMessagesCount);
}

function* triggerOneOnboarding(): SagaIterator {
  yield call(waitForAgentCustomProperties);

  const hasSeenOnboarding: boolean = yield select(
    getAgentCustomProperty,
    AgentCustomPropertyName.OnePopoverOnboardingSeen
  );

  if (hasSeenOnboarding) {
    return;
  }

  trackEvent(OneTrackEvent.Onboarding, EventPlace.One, { step: 'Onboarding started' });

  const isOldUser = yield call(isAccountCreatedBeforeOnePopoverRelease);
  const isOnboardingFinished = yield select(getIsOnboardingFinished);

  const config: OnboardingConfig = isOldUser ? ONE_ONBOARDING_CONFIG_OLD_USERS : ONE_ONBOARDING_CONFIG;

  if (!isOnboardingFinished) {
    yield take(OnboardingActionsEnum.ONBOARDING_FINISHED);
    yield delay(ONE_ONBOARDING_DELAY);
  }

  yield call(startOneOnboarding, config);
}

function* startOneOnboarding(config: OnboardingConfig): SagaIterator {
  const loggedAgentName: string = yield select(getLoggedInAgentName);

  const queryClient = getQueryClient();
  const knowledgeSources = queryClient.getQueryData<KnowledgeSourceItem[]>([QueryKey.KnowledgeSourcesList]);
  const hasAnyKnowledgeSources = !!knowledgeSources?.length;

  for (const getMessage of config) {
    const message = getMessage({ name: loggedAgentName, hasAnyKnowledgeSources });

    if (!message) {
      continue;
    }

    yield put(
      OneEntitiesActions.addOneMessage({
        authorId: 'live-assistant',
        authorType: 'live-assistant',
        eventId: uniqueId(),
        text: message.text,
        timestampInMs: Date.now(),
        type: 'text-message',
        properties: {
          actionButtons: message.actionButtons ?? undefined,
          withoutActions: true,
        },
      })
    );

    if (message.delay) {
      yield put(OneViewActions.setOneIsLoading(true));
      yield delay(message.delay);
      yield put(OneViewActions.setOneIsLoading(false));
    }

    yield call(setUnreadMessagesCount);
  }

  yield put(
    AgentCustomPropertiesActions.setAgentCustomProperty({
      [AgentCustomPropertyName.OnePopoverOnboardingSeen]: true,
    })
  );
  trackEvent(OneTrackEvent.Onboarding, EventPlace.One, { step: 'Onboarding finished' });
}

function* handleOnePromo(): SagaIterator {
  const oneOpenedCount = yield select(getOneOpenedCount);
  const oneMessageSentCount = yield select(getOneMessageSentCount);
  const oneSprigSurveySeen = yield select(getOneSprigSurveySeen);

  if (oneSprigSurveySeen) {
    return;
  }

  const sprig = getSprigService();
  const isOldUser = yield call(isAccountCreatedBeforeOnePopoverRelease);

  if (isOldUser && oneMessageSentCount === 1) {
    yield call([sprig, sprig.initSprigEvent], SprigEvents.FirstMessageInOne);
  }

  if (oneMessageSentCount === MAX_ONE_ONBOARDING_MESSAGES_COUNT && oneOpenedCount === MAX_ONE_ONBOARDING_OPENED_COUNT) {
    yield put(
      AgentCustomPropertiesActions.setAgentCustomProperty({
        [AgentCustomPropertyName.OneSprigSurveySeen]: 1,
      })
    );
    yield call([sprig, sprig.initSprigEvent], SprigEvents.OneConversation);
  }
}

function* handleOneConversation(action: IActionWithPayload<string, OneAddConversationPayload>): SagaIterator {
  const payload = action.payload;

  for (const message of payload) {
    yield put(OneEntitiesActions.addOneMessage(message.event));

    if (message.delay) {
      yield put(OneViewActions.setOneIsLoading(true));
      yield delay(message.delay);
      yield put(OneViewActions.setOneIsLoading(false));
    }
  }
}

export function* oneSaga(): SagaIterator {
  yield takeEvery(OneEntitiesActionNames.SEND_ONE_MESSAGE, sendOneMessage);
  yield takeEvery(OneEntitiesActionNames.RETRY_FAILED_ONE_MESSAGE, retryGenerateOnLastAgentMessage);
  yield takeLatest(['APP_READY'], sendWelcomeMessage);
  yield debounce(ONE_ONBOARDING_DELAY, ['APP_READY'], triggerOneOnboarding);
  yield takeEvery(OneEntitiesActionNames.ONE_ONBOARDING_ACTION, handleOneOnboardingAction);
  yield takeEvery(OneEntitiesActionNames.ONE_ADD_CONVERSATION, handleOneConversation);
}
