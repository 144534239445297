import memoizeOne from 'memoize-one';

import { shuffleArray } from 'helpers/sort';
import { type Suggestion } from 'interfaces/one';

const getRandomItem = (arr: Suggestion[]): Suggestion => arr[Math.floor(Math.random() * arr.length)];

export const getRandomSuggestions = memoizeOne((suggestions: Suggestion[], maxSize = 3): Suggestion[] => {
  const groupedSuggestions = shuffleArray(suggestions).reduce((acc, suggestion) => {
    if (!acc[suggestion.description]) {
      acc[suggestion.description] = [];
    }
    acc[suggestion.description].push(suggestion);

    return acc;
  }, {} as Record<string, Suggestion[]>);

  const selectedItems = Object.values(groupedSuggestions).map(getRandomItem);

  return selectedItems.slice(0, maxSize);
});
