import type { FC } from 'react';

import { Button, Text, Tooltip, Loader, Heading } from '@livechat/design-system-react-components';
import { useSelector } from 'react-redux';

import { OneTrackEvent } from 'constants/one-event';
import { type PlanType } from 'constants/plan-type';
import { PLAN_LIMITS } from 'constants/reply-suggestions';
import { SettingsRoutePage } from 'constants/settings/routing';
import { EventPlace } from 'helpers/analytics';
import { isBelowKnowledgeSourceLimit } from 'helpers/reply-suggestions/reply-suggestions';
import { navigate, openInNewCard } from 'helpers/routing';
import { useReplySuggestionsSourcesList } from 'hooks/use-reply-suggestions-list';
import { trackEvent } from 'services/event-tracking';
import { getIsStarterPlan, getPlanType } from 'store/features/session/selectors';

import { ONE_SOURCES_DESCRIPTION } from './constants';
import { getKnowledgeSourcesData } from './helpers';
import { OneAccordion } from './OneAccordion';
import { ReplySuggestionsUpsell } from './ReplySuggestionsUpsell';

import * as styles from './styles';

const DEFAULT_EXTRACTED_SIZE = 6;

function navigateToReplySuggestionsList(): void {
  navigate(`settings/${SettingsRoutePage.ReplySuggestions}`);
  trackEvent(OneTrackEvent.ViewSourceClicked, EventPlace.One, { from: 'details' });
}

function navigateToReplySuggestionsForm(): void {
  navigate(`settings/${SettingsRoutePage.ReplySuggestionsNew}`);
  trackEvent(OneTrackEvent.AddSourceClicked, EventPlace.One, { from: 'details' });
}

export const ReplySuggestionsAccordion: FC = () => {
  const plan = useSelector(getPlanType);
  const { data, isLoading } = useReplySuggestionsSourcesList();
  const isStarter = useSelector(getIsStarterPlan);

  const canAddNewSource = isBelowKnowledgeSourceLimit(data, plan);
  const shouldDisplayUpsell = isStarter && !data?.length;

  if (isLoading) {
    return (
      <OneAccordion title="Knowledge sources">
        <div className={styles.loaderWrapper}>
          <Loader />
        </div>
      </OneAccordion>
    );
  }

  const { extractedSources, remainingSourcesCount } = getKnowledgeSourcesData(data, DEFAULT_EXTRACTED_SIZE);
  const isEmpty = extractedSources?.length === 0;
  const limit = PLAN_LIMITS[plan as unknown as PlanType];

  const button = (
    <Button kind="secondary" onClick={navigateToReplySuggestionsForm} disabled={!canAddNewSource}>
      Add source
    </Button>
  );

  return (
    <OneAccordion title="Knowledge sources">
      <div>
        {shouldDisplayUpsell && <ReplySuggestionsUpsell />}
        {isEmpty && !shouldDisplayUpsell && (
          <div className={styles.noSourcesContainer}>
            <Text>{ONE_SOURCES_DESCRIPTION}</Text>

            {canAddNewSource && button}
            {!canAddNewSource && (
              <Tooltip triggerClassName={styles.tooltipTrigger} placement="top" kind="invert" triggerRenderer={button}>
                <Text>You reached the maximum of {limit} knowledge sources</Text>
              </Tooltip>
            )}
          </div>
        )}
        {!isEmpty && !shouldDisplayUpsell && (
          <div>
            <ul className={styles.sourcesList}>
              {extractedSources.map(({ name, url }) => (
                <li key={url} onClick={() => openInNewCard(url)}>
                  <Button kind="text">{name}</Button>
                </li>
              ))}
              {!!remainingSourcesCount && (
                <li>
                  <Tooltip
                    className={styles.moreTooltip}
                    placement="top-start"
                    kind="invert"
                    triggerClassName={styles.moreKnowledgeSources}
                    triggerRenderer={
                      <Button kind="text" onClick={navigateToReplySuggestionsList}>
                        and {remainingSourcesCount} more
                      </Button>
                    }
                  >
                    <Text>View all in settings</Text>
                  </Tooltip>
                </li>
              )}
            </ul>
            <div className={styles.settingsButton}>
              <Button kind="secondary" onClick={navigateToReplySuggestionsList}>
                <Heading>Settings</Heading>
              </Button>
            </div>
          </div>
        )}
      </div>
    </OneAccordion>
  );
};
