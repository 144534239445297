/* eslint-disable @typescript-eslint/naming-convention */

import type { IAnswerUsageEvent } from './api/ml-gateway/interfaces/event-collector';
import { EventCollector } from './event-collector';

interface IThreadData {
  requestId: string;
  useButtonClick: boolean;
  agentResponse: string;
  isAutomaticSuggestion: boolean;
}

export const knowledgeSourcesEventCollector = new EventCollector<IThreadData, IAnswerUsageEvent>({
  initialState: {},
  eventValidator: (event) => {
    if (!event) {
      return false;
    }

    return !!event.agentResponse;
  },

  prepareEvent: (payload) => {
    const { requestId, useButtonClick, agentResponse, isAutomaticSuggestion } = payload;

    return {
      project: 'knowledge-buddy',
      type: 'answer_usage',
      request_id: requestId,
      template_version: '1.0.0',
      data: {
        version: '1.0.0',
        use_button_click: useButtonClick,
        agent_response: agentResponse,
        is_automatic_suggestion: isAutomaticSuggestion,
      },
    };
  },
});
