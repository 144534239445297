import { type ReactChild, type MouseEvent, type FC } from 'react';

import { cx } from '@emotion/css';
import { useSelector } from 'react-redux';

import { AgentAvatar } from 'components/avatar/AgentAvatar';
import { type BadgeType, Badge } from 'components/badge/Badge';
import { getLoggedInAgent } from 'store/entities/agents/selectors';

import * as styles from './styles';

interface IProps {
  path: string;
  active: boolean;
  badgeType: BadgeType;
  badgeContent?: ReactChild;
  onItemClick?(path?: string): void;
}

const handleClick = (event: MouseEvent<HTMLAnchorElement>, path?: string, callback?: IProps['onItemClick']): void => {
  event.preventDefault();

  callback?.(path);
};

const MobileNavigationMyProfileItem: FC<IProps> = ({ path, active, badgeContent, badgeType, onItemClick }) => {
  const currentAgent = useSelector(getLoggedInAgent);

  return (
    <a className={styles.mobileNavigationItem} href="/" onClick={(event) => handleClick(event, path, onItemClick)}>
      <div className={cx(styles.contentWrapper(active))}>
        {badgeContent && (
          <div className={styles.badgeContainer}>
            <Badge type={badgeType}>{badgeContent}</Badge>
          </div>
        )}
        <AgentAvatar src={currentAgent.avatar} status={currentAgent.status} text={currentAgent.name} size="small" />
      </div>
    </a>
  );
};

export default MobileNavigationMyProfileItem;
