import { css } from '@emotion/css';
import { TransitionDurationToken, TransitionTimingToken } from '@livechat/design-system-react-components';

import { ONE_COLUMN_BREAKPOINT } from 'constants/column-layout';

export const wrapper = (hidden: boolean) => css`
  @media (max-width: ${ONE_COLUMN_BREAKPOINT}px) {
    display: none;
  }
  height: 100%;
  margin-left: ${hidden ? '0' : '-296px'};
  transition: margin-left
    ${hidden
      ? `var(${TransitionDurationToken.Fast2}) var(${TransitionTimingToken.EaseIn})`
      : `var(${TransitionDurationToken.Fast2}) var(${TransitionTimingToken.EaseOut})`};
`;
