import { css } from '@emotion/css';
import { SpacingToken, DesignToken, RadiusToken } from '@livechat/design-system-react-components';

export const modal = css`
  width: 95vw;
  max-width: 600px;

  > div {
    padding: var(${SpacingToken.Spacing5}) var(${SpacingToken.Spacing7});
  }

  > div:first-of-type {
    border-bottom: 1px solid var(${DesignToken.BorderBasicTertiary});
  }

  > div:last-of-type {
    border-top: 1px solid var(${DesignToken.BorderBasicTertiary});
    margin-top: var(${SpacingToken.Spacing3});
  }

  p {
    margin: 0;
  }
`;

export const alert = css`
  margin-bottom: 30px;

  div {
    margin-right: 0;
  }

  h5 {
    margin: 0 0 var(${SpacingToken.Spacing2});
  }
`;

export const footer = css`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: var(${SpacingToken.Spacing2});
`;

export const itemSummary = css`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: var(${SpacingToken.Spacing2});
  width: 100%;
`;

export const icon = css`
  width: 48px;
  height: 48px;
  border-radius: var(${RadiusToken.Radius1});
  object-fit: contain;
`;

export const price = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(${SpacingToken.Spacing1});
  width: 50%;
  margin-left: calc(48px + var(${SpacingToken.Spacing2}));

  @media (min-width: 450px) {
    width: 30%;
    align-items: flex-end;
    margin-left: auto;
  }
`;
