import { LoginStatus } from 'constants/login-status';
import { AgentSerializer } from 'services/api/agent/v3/agent-serializer';
import type { IAppState } from 'services/app-state-provider';
import { type Agent } from 'services/connectivity/configuration-api/agents/types';
import { AgentActions } from 'store/entities/agents/actions';
import { GroupActions } from 'store/entities/groups/actions';
import { SubscriptionViewActions } from 'store/views/subscription/actions';

export function handleAgentCreated(payload: Agent, store: IAppState): void {
  store.dispatch(SubscriptionViewActions.agentsCountChanged());
  const deserializedPayload = AgentSerializer.deserialize(payload, LoginStatus.Offline);
  store.dispatch(AgentActions.agentAdd(deserializedPayload));
  store.dispatch(GroupActions.groupsFetch());
}
