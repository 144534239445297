// @ts-strict-ignore
import { type SagaIterator } from 'redux-saga';
import { select } from 'redux-saga/effects';

import { ReportType } from 'constants/reports/report-type';
import { ApiManager } from 'services/api/api-manager';
import { type IActionWithPayload } from 'store/helper';
import { getQueueAbandonmentPage } from 'store/views/reports/selectors';

import {
  type IFetchReportPayload,
  type IReportRequest,
  type IFetchReportOptions,
  ReportRequestName,
} from '../interfaces';

import { makeFetchingSaga } from './helpers';

export function* fetchQueueAbandonment(action: IActionWithPayload<string, IFetchReportPayload>): SagaIterator {
  const requests: IReportRequest[] = [
    {
      name: ReportRequestName.QueueAbandonment,
      interface: ApiManager.queueApi.fetchQueueAbandonment,
    },
  ];

  const page = yield select(getQueueAbandonmentPage);

  const options: IFetchReportOptions = {
    extendedFilters: {
      page,
    },
  };

  yield makeFetchingSaga(ReportType.QueueAbandonment, action, requests, options);
}
