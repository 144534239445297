// @ts-strict-ignore
import {
  GoogleChromeColored as ChromeIcon,
  EdgeColored as EdgeIcon,
  FirefoxColored as FirefoxIcon,
  InternetExplorerColored as IEIcon,
  OperaColored as OperaIcon,
  SafariColored as SafariIcon,
} from '@livechat/design-system-icons';
import type { IconSource } from '@livechat/design-system-react-components';
import { UAParser } from 'ua-parser-js';

import { App } from 'config/setup';
import { CustomPercyUserAgent, Platform } from 'constants/app-platform';

const userAgent = new UAParser(window.navigator.userAgent);

export const isPercyUserAgent = (): boolean =>
  new UAParser(window.navigator.userAgent).getUA() === CustomPercyUserAgent;

const browser = userAgent.getBrowser();

export const browserName = browser.name as BrowserName;
export const browserMajorVersion = parseInt(browser.major, 10);

export enum BrowserName {
  Firefox = 'Firefox',
  Safari = 'Safari',
  IE = 'IE',
  Edge = 'Edge',
  Chrome = 'Chrome',
  MobileSafari = 'Mobile Safari',
  Opera = 'Opera',
}

export const isFirefox = browserName === BrowserName.Firefox;
export const isSafari = browserName === BrowserName.Safari;
const isIE = browserName === BrowserName.IE;
const isChrome = browserName === BrowserName.Chrome;
export const isMobileSafari = browserName === BrowserName.MobileSafari;

// Rules of browsers that we wouldn't support
// General rule: we wouldn't track errors from browsers older than 1 years
const isLegacyChrome = isChrome && browserMajorVersion < 115;
const isLegacyFirefox = isFirefox && browserMajorVersion < 115;
const isLegacyIE = isIE && browserMajorVersion < 11;
export const isLegacyBrowser = isLegacyChrome || isLegacyFirefox || isLegacyIE;

export enum ApplicationPlatforms {
  Web = 'web',
  Desktop = 'desktop',
}

export enum DesktopClientPlatform {
  Windows = 'win',
  MacOS = 'mac',
}

export function getApplicationPlatform(): ApplicationPlatforms {
  return App.SmartClient?.isDetected() ? ApplicationPlatforms.Desktop : ApplicationPlatforms.Web;
}

export const isWindowsDesktopClient = (): boolean => App.SmartClient?.getPlatform() === DesktopClientPlatform.Windows;

export const isDesktopClient = (): boolean => getApplicationPlatform() === ApplicationPlatforms.Desktop;

export const isWebClient = (): boolean => getApplicationPlatform() === ApplicationPlatforms.Web;

export const hasAudioSupport = !!window.document.createElement('audio').canPlayType;

export function isTabFocused(): boolean {
  return document.hasFocus();
}

export function wasPageRefreshed(): boolean {
  return (
    window.performance
      .getEntriesByType('navigation')
      .filter(({ type }: PerformanceNavigationTiming) => type === 'reload').length > 0
  );
}

export function isMacOS(): boolean {
  return userAgent.getOS().name === 'Mac OS';
}

export function isWindows(): boolean {
  return userAgent.getOS().name === 'Windows';
}

function isIOS(): boolean {
  return userAgent.getOS().name === 'iOS';
}

export function isAndroid(): boolean {
  return userAgent.getOS().name === 'Android';
}

export const isIpadOnSafari = !!(isMacOS() && navigator.maxTouchPoints && navigator.maxTouchPoints > 2);

const browserIcon = new Map<BrowserName, IconSource>([
  [BrowserName.Firefox, FirefoxIcon],
  [BrowserName.Safari, SafariIcon],
  [BrowserName.MobileSafari, SafariIcon],
  [BrowserName.IE, IEIcon],
  [BrowserName.Edge, EdgeIcon],
  [BrowserName.Chrome, ChromeIcon],
  [BrowserName.Opera, OperaIcon],
]);

export function getBrowserIconSource(browserName: string | undefined): IconSource | null {
  const name = String(browserName || '');

  for (const [browser, icon] of browserIcon) {
    if (name.startsWith(browser)) {
      return icon;
    }
  }

  return null;
}

export const getAppPlatform = (): Platform => {
  if (isMacOS()) return Platform.Mac;
  if (isWindows()) return Platform.Windows;
  if (isIOS()) return Platform.iOS;
  if (isAndroid()) return Platform.Android;

  return Platform.Other;
};
