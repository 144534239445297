// @ts-strict-ignore
/* eslint-disable @typescript-eslint/naming-convention */
import { format } from 'date-fns';

import { DateFormat } from 'constants/date';
import { type AgentAssignmentFilter, AgentAssignmentFilterKey } from 'constants/filters/agent-assignment-filter';
import { type AgentResponseFilter } from 'constants/filters/agent-response-filter';
import { type AvailabilityFilter } from 'constants/filters/availability-filter';
import { type CustomDateRange } from 'constants/filters/date-range-filter';
import { Filter, OperatorQueryParam } from 'constants/filters/filter';
import { type OfflineMessageFilter } from 'constants/filters/offline-message-filter';
import {
  RatingFilter,
  RatingFilters,
  RatingFiltersWithComment,
  RatingFiltersWithoutComment,
} from 'constants/filters/rating-filter';
import { type RepliedFilter } from 'constants/filters/replied-filter';
import { type SentimentFilter } from 'constants/filters/sentiment-filter';
import { ensureFlat, ensureArray } from 'helpers/data';
import { deserializeCustomDateRangeURLParams, extractOperatorsToQueryParams } from 'helpers/filters';
import { type IKeyValue, stringifyQueryParams } from 'helpers/url';
import { getSearchPhrase, getFilters, getFiltersOperators } from 'store/views/archives/selectors';

import { type IArchivesViewParams, type IWithArchivesViewState } from './interfaces';

const textToPascalCase = (s: string): string =>
  s.replace(/\.?([A-Z])/g, (_, y: string): string => `_${y.toLowerCase()}`).replace(/^_/, '');

const deserializeRatingURLParams = (queryParams: IKeyValue<string>): RatingFilter => {
  const { rate, comment } = queryParams;
  if (!rate || (!comment && !rate)) {
    return null;
  }

  if (!comment) {
    return rate as RatingFilter;
  }

  switch (rate) {
    case RatingFilter.Rated:
      return RatingFilter.RatedCommented;
    case RatingFilter.RatedGood:
      return RatingFilter.RatedGoodCommented;
    case RatingFilter.RatedBad:
      return RatingFilter.RatedBadCommented;
    default:
      return null;
  }
};

export function deserializeArchivesViewURLParams(queryParams: IKeyValue<string>): IArchivesViewParams {
  const result: IArchivesViewParams = {};

  if (queryParams[OperatorQueryParam.And]) {
    result.operators = {
      and: queryParams[OperatorQueryParam.And] as Filter[],
    };
  }

  if (queryParams[OperatorQueryParam.Exclude]) {
    result.operators = {
      exclude: queryParams[OperatorQueryParam.Exclude] as Filter[],
    };
  }

  const ratingFilter = deserializeRatingURLParams(queryParams);
  if (RatingFilters.includes(ratingFilter)) {
    result[Filter.Rating] = [ratingFilter];
  }

  if (queryParams.channel) {
    result[Filter.Channel] = ensureArray(queryParams.channel);
  }

  const dateRangeFilter = deserializeCustomDateRangeURLParams(
    queryParams.date_from as string,
    queryParams.date_to as string
  );
  if (dateRangeFilter) {
    result[Filter.DateRange] = dateRangeFilter;
  }

  if (queryParams.tag) {
    result[Filter.Tag] = ensureArray(queryParams.tag);
  }

  if (queryParams.agent) {
    result[Filter.Agent] = ensureArray(queryParams.agent);
  }

  if (queryParams.group) {
    result[Filter.Group] = ensureArray(queryParams.group);
  }

  if (queryParams.goal) {
    result[Filter.Goal] = ensureArray(queryParams.goal);
  }

  if (queryParams.sale) {
    result[Filter.SaleGoal] = ensureArray(queryParams.sale);
  }

  if (queryParams.greeting) {
    result[Filter.Greeting] = ensureArray(queryParams.greeting);
  }

  if (queryParams.survey_answer && queryParams.survey_question_label && queryParams.survey_answer_label) {
    result[Filter.Survey] = {
      id: queryParams.survey_answer as string,
      answerLabel: queryParams.survey_answer_label as string,
      questionLabel: queryParams.survey_question_label as string,
    };
  }

  if (queryParams.query) {
    result.searchPhrase = queryParams.query as string;
  }

  if (queryParams.chat_id) {
    result.currentArchiveId = queryParams.chat_id as string;
  }

  if (queryParams[AgentAssignmentFilterKey]) {
    result.agentAssignment = ensureArray(queryParams[AgentAssignmentFilterKey]) as AgentAssignmentFilter[];
  }

  if (!Number.isNaN(parseInt(queryParams.replied as string, 10))) {
    result[Filter.Replied] = Number(queryParams.replied) as RepliedFilter;
  }

  if (!Number.isNaN(parseInt(queryParams.offline_message as string, 10))) {
    result[Filter.OfflineMessage] = Number(queryParams.offline_message) as OfflineMessageFilter;
  }

  if (!Number.isNaN(parseInt(queryParams.agent_response as string, 10))) {
    result[Filter.AgentResponse] = Number(queryParams.agent_response) as AgentResponseFilter;
  }

  if (queryParams.availability) {
    result[Filter.Availability] = ensureFlat(queryParams.availability as AvailabilityFilter);
  }

  if (queryParams.countries) {
    result[Filter.CountryISO] = ensureArray(queryParams.countries as string[]);
  }

  if (queryParams.topic) {
    result.topic = ensureArray(queryParams.topic);
  }

  if (queryParams.sentiment) {
    result.sentiment = ensureFlat(queryParams.sentiment as SentimentFilter);
  }

  return result;
}

export const mapRatingFilter = (filter: RatingFilter): IKeyValue<string> => {
  if (RatingFiltersWithoutComment.includes(filter)) {
    return { rate: filter };
  }

  if (RatingFiltersWithComment.includes(filter)) {
    switch (filter) {
      case RatingFilter.RatedCommented:
        return { rate: RatingFilter.Rated, comment: '1' };
      case RatingFilter.RatedBadCommented:
        return { rate: RatingFilter.RatedBad, comment: '1' };
      case RatingFilter.RatedGoodCommented:
        return { rate: RatingFilter.RatedGood, comment: '1' };
      // no default
    }
  }

  return {};
};

const mapDateRangeFilter = (filterValue: CustomDateRange): IKeyValue<string> => {
  if (filterValue === null || typeof filterValue !== 'object' || !filterValue.from || !filterValue.to) {
    return {};
  }

  return {
    date_from: format(filterValue.from, DateFormat.ISO8601Date),
    date_to: format(filterValue.to, DateFormat.ISO8601Date),
  };
};

export function buildArchivesViewQueryString(state: IWithArchivesViewState): string {
  const query = getSearchPhrase(state);
  const { rating, dateRange, ...filters } = getFilters(state);
  const operators = getFiltersOperators(state);
  const ratingParams = mapRatingFilter(ensureFlat(rating));
  const dateRangeParams = mapDateRangeFilter(ensureFlat(dateRange));

  let toStringify: IKeyValue<string> = {};

  if (query) {
    toStringify = {
      query,
    };
  }

  if (Object.keys(ratingParams).length > 0) {
    toStringify = {
      ...toStringify,
      ...ratingParams,
    };
  }

  if (Object.keys(dateRangeParams).length > 0) {
    toStringify = {
      ...toStringify,
      ...dateRangeParams,
    };
  }

  const customFilters = [Filter.SaleGoal, Filter.Greeting, Filter.Survey, Filter.CountryISO];

  const baseFilters = Object.keys(filters).filter((f) => !customFilters.includes(f as Filter));
  baseFilters.forEach((filterName) => {
    if (filters[filterName] || Number.isInteger(filters[filterName])) {
      toStringify = {
        ...toStringify,
        [textToPascalCase(filterName)]: filters[filterName],
      };
    }
  });

  if (filters.saleGoal) {
    toStringify.sale = filters.saleGoal;
  }

  if (filters.greeting) {
    toStringify.greeting = ensureFlat(filters.greeting);
  }

  if (filters.survey) {
    const survey = ensureFlat(filters.survey);
    toStringify.survey_answer = survey.id;
    toStringify.survey_answer_label = survey.answerLabel;
    toStringify.survey_question_label = survey.questionLabel;
  }

  if (filters.countryISO) {
    toStringify.countries = ensureArray(filters.countryISO);
  }

  if (operators) {
    toStringify = { ...toStringify, ...extractOperatorsToQueryParams(operators) };
  }

  return stringifyQueryParams(toStringify);
}
