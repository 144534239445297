import { useMutation, type UseMutateFunction } from '@tanstack/react-query';

import { QueryKey } from 'constants/query-key';
import { ToastContent } from 'constants/toasts';
import { showToast } from 'helpers/toast';
import { ApiManager } from 'services/api/api-manager';
import { getQueryClient } from 'services/query-client/client';
import { ToastVariant } from 'store/features/toasts/interfaces';

interface UsePurchaseUpgrade {
  purchase: UseMutateFunction<void, string, PurchaseUpgradePayload, unknown>;
  isProcessing: boolean;
  isSuccess: boolean;
  error: unknown;
}

interface PurchaseUpgradePayload {
  applicationId: string;
  resourceId: string;
  upgradeId: string | null;
  quantity?: number;
}

const purchase = async ({ applicationId, resourceId, quantity = 1 }: PurchaseUpgradePayload): Promise<void> => {
  const { error } = await ApiManager.inAppUpgradesApi.purchaseResource(applicationId, resourceId, quantity);

  if (error) {
    throw error;
  }
};

const handleSuccess = async (): Promise<void> => {
  showToast(ToastVariant.Success, ToastContent.IN_APP_UPGRADE_PURCHASE_TRANSACTION_SUCCESS);

  const queryClient = getQueryClient();
  await queryClient.invalidateQueries([QueryKey.InAppUpgrade]);
};

const handleFailure = (): void => {
  showToast(ToastVariant.Error, ToastContent.IN_APP_UPGRADE_PURCHASE_TRANSACTION_FAILURE);
};

export const usePurchaseUpgrade = (): UsePurchaseUpgrade => {
  const { mutate, isLoading, isSuccess, error } = useMutation<void, string, PurchaseUpgradePayload, unknown>(purchase, {
    onError: handleFailure,
    onSuccess: handleSuccess,
  });

  return {
    purchase: mutate,
    isProcessing: isLoading,
    isSuccess,
    error,
  };
};
