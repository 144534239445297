import { createSelector } from 'reselect';

import { GENERAL_GROUP_ID } from 'constants/groups';
import { type KeyMap } from 'helpers/interface';
import { type IStoreState } from 'interfaces/store/store-state';
import { getLoggedInAgentGroupsIds, type WithAgentsState } from 'store/entities/agents/selectors';
import { getCurrentArchiveGroupsIds } from 'store/views/archives/selectors';
import { getSelectedGroupId } from 'store/views/settings/selectors';

import { getChatGroupIds, type IWithChatsEntityState } from '../chats/selectors';

import { type ITag } from './interfaces';

interface IWithTagsState {
  entities: {
    tags: KeyMap<ITag>;
  };
}

function tagsSelector(state: IWithTagsState): { [key: string]: ITag } {
  return state.entities.tags;
}

export const getTags = createSelector(tagsSelector, (tags) => Object.keys(tags).map((id) => tags[id]));

export const getTagsFromLoggedInAgentGroups = createSelector(getLoggedInAgentGroupsIds, getTags, (groupIds, tags) =>
  tags.filter(({ group }) => groupIds.includes(group))
);

export const getTagsFromCurrentGroup = createSelector(getSelectedGroupId, getTags, (groupId, tags) =>
  tags.filter(({ group }) => groupId === group)
);

export const getTagsAvailableForThread: (
  state: IWithTagsState & WithAgentsState & IWithChatsEntityState,
  threadId?: string
) => ITag[] = createSelector(
  getTags,
  getLoggedInAgentGroupsIds,
  (state: IWithChatsEntityState, threadId?: string) => (threadId ? getChatGroupIds(state, threadId) : []),
  (tags, agentGroupIds, threadGroupIds) =>
    tags.filter(
      ({ group }) => group === GENERAL_GROUP_ID || (agentGroupIds.includes(group) && threadGroupIds.includes(group))
    )
);

export const getTagsAvailableForArchive: (state: IStoreState) => ITag[] = createSelector(
  getTags,
  getCurrentArchiveGroupsIds,
  getLoggedInAgentGroupsIds,
  (tags, archiveGroupIds, agentGroupIds) =>
    tags.filter(
      ({ group }) => group === GENERAL_GROUP_ID || (agentGroupIds.includes(group) && archiveGroupIds.includes(group))
    )
);
