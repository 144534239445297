export const IN_APP_UPGRADES_HEADER = {
  name: 'NAME',
  price: 'PRICE',
  summary: 'SUMMARY',
};

export const RESOURCE_MODAL = {
  manage: 'manage',
  purchase: 'purchase',
  updateSubscription: 'update_subscription',
} as const;

export type ResourceModalType = typeof RESOURCE_MODAL[keyof typeof RESOURCE_MODAL];
