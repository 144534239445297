import { App } from 'config/setup';
import { BusinessModel } from 'constants/business-model';
import { LicenseType } from 'interfaces/entities/current-license';
import { getLastActivityInDays } from 'services/activity/website-last-activity';
import { AppStateProvider } from 'services/app-state-provider';
import { markUserAsLoggedIn } from 'services/mark-user-as-logged-in';
import { handleLogin as handleLoginForChats } from 'services/socket-lc3/chat/event-handling/login';
import type { ILoginResponsePushEvent } from 'services/web-socket/interfaces';
import { getBusinessModel, getPartnerType, getSalesPlan } from 'store/entities/license-properties/selectors';
import { CodeInstallationActions } from 'store/features/code-installation/actions';
import { SessionActions } from 'store/features/session/actions';

export function handleLoginSuccessful(payload: ILoginResponsePushEvent): void {
  handleLoginForChats(payload);

  AppStateProvider.dispatch(
    CodeInstallationActions.parseLicenseInfo({
      daysSinceLastActivity: getLastActivityInDays(),
    })
  );

  const { email: login } = payload.my_profile;

  AppStateProvider.dispatch(
    SessionActions.saveAuthenticationCredentials({
      login,
    })
  );

  if (App.SmartClient) {
    App.SmartClient.agentLoggedIn({
      login,
      appUrl: `${window.location.origin}/`,
    });
  }

  const expirationTimestamp = payload.license.expiration_timestamp;
  const licenseType = payload.license.in_trial ? LicenseType.Trial : LicenseType.Paid;

  const plan = AppStateProvider.selectFromStore(getSalesPlan);
  const partnerType = AppStateProvider.selectFromStore(getPartnerType);
  const businessModel = AppStateProvider.selectFromStore(getBusinessModel) || BusinessModel.PayPerSeat;

  AppStateProvider.dispatch(
    SessionActions.saveCurrentLicense({
      expiresAt: expirationTimestamp,
      type: licenseType,
      plan,
      partnerType,
      businessModel,
      licenseId: parseInt(payload.license.id, 10),
      createdAt: payload.license.creation_timestamp,
    })
  );

  App.platformProtocolParser?.handleSuccessfulLogin(payload.chats_summary);
  void markUserAsLoggedIn();
}
