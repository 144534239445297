// @ts-strict-ignore
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/naming-convention */
import AccountsSDK from '@livechat/accounts-sdk';
import * as Sentry from '@sentry/browser';
import debug from 'debug';

import { type IConfig } from '@config/interfaces';
import botEngineLogo from 'assets/img/integrations/botengine-app-logo.png';
import { App } from 'config/setup';
import { DebugLogsNamespace } from 'constants/debug-logs-namespace';
import { GlobalModal } from 'constants/global-modal';
import { LC3IncomingEvent } from 'constants/lc3-incoming-event';
import { LoginStatus } from 'constants/login-status';
import { TopBarNotificationType } from 'constants/notifications';
import { ToastContent } from 'constants/toasts';
import { getConfig, updateDatacenter } from 'helpers/config';
import { redirectToAccounts, redirectToAccountsSignout } from 'helpers/redirect-to-accounts';
import { redirectToLicenseExpired, redirectToSignOut, redirectToSubscription } from 'helpers/routing';
import { showToast } from 'helpers/toast';
import type { IArchive } from 'interfaces/entities/archive';
import type { IncomingMessage } from 'interfaces/incoming-message';
import type { IProtocolParser } from 'interfaces/protocol-parser';
import { SignoutReason } from 'interfaces/session';
import type { GroupRTMDTO } from 'services/api/group/interfaces';
import { deserializeGroup, deserializePartialGroup } from 'services/api/group/serializer';
import { storeCredentials } from 'services/auth/auth-storage-manager';
import {
  attachConnectionStatusEventListeners,
  DEFAULT_RECONNECT_RETRY_COUNT,
} from 'services/connectivity/reconnector/events';
import { initializeReconnector, type Reconnector } from 'services/connectivity/reconnector/service';
import { saveLog } from 'services/save-log';
import { connectAndLogin } from 'services/server/connect-and-login';
import { removeSessionAndRedirectOut } from 'services/session';
import {
  AgentDisconnectedReason,
  type IAgentDisconnectedMisdirectedConnectionReasonPushEvent,
  type IAgentDisconnectedPushEvent,
} from 'services/socket-lc3/agent/interfaces';
import { partialDeserialize as partialDeserializeAgent } from 'services/socket-lc3/agent/serialization/agent-serializer';
import { IncomingMulticastIwcsCommand } from 'services/socket-lc3/chat/event-handling/constants';
import {
  isMulticastBotEnginePayload,
  isMulticastInsightsPayload,
  isMulticastLc2IwcsPayload,
  isMulticastLc2Payload,
} from 'services/socket-lc3/chat/event-handling/helpers/incoming-multicast';
import {
  type IIncomingMulticastBotEnginePayload,
  type IIncomingMulticastInsightsPayload,
  type IIncomingMulticastLC2Payload,
  type IIncomingMulticastLc2IwcsPayload,
  type IThreadTaggedPushEvent,
  type IThreadUntaggedPushEvent,
  type IncomingMulticastPayload,
} from 'services/socket-lc3/chat/interfaces';
import { handleLC3IncomingEvent } from 'services/socket-lc3/socket-event-handler';
import { websocketEventsHistoryManager } from 'services/web-sockets-events-history-manager';
import { AgentActions } from 'store/entities/agents/actions';
import { getLoggedInAgent, getLoggedInAgentLogin } from 'store/entities/agents/selectors';
import { ArchiveActions } from 'store/entities/archives/actions';
import { BadgeActions } from 'store/entities/badges/actions';
import { BadgeKey } from 'store/entities/badges/interfaces';
import { getBot } from 'store/entities/bots/selectors';
import { ChatsEntitiesActions } from 'store/entities/chats/actions';
import { GroupActions } from 'store/entities/groups/actions';
import { GlobalModalActions } from 'store/features/global-modals/actions';
import { NotificationsBarActions } from 'store/features/notifications-bar/actions';
import { getCanManageSubscription } from 'store/features/session/selectors';
import { ToastVariant } from 'store/features/toasts/interfaces';
import { runAppSagas, stopAppSagas } from 'store/sagas/app-sagas';
import { store } from 'store/store';
import { getArchive } from 'store/views/archives/selectors';
import { OneViewActions } from 'store/views/one/actions';

import { JsonProtocol } from './json-protocol';

const log = debug(DebugLogsNamespace.AppLc3PlatformProtocolParser);

export class PlatformProtocolParser implements IProtocolParser {
  reconnector: Reconnector;
  jsonProtocol: JsonProtocol;

  config: IConfig;

  inited = false;

  constructor(config: IConfig) {
    this.config = config;
    this.reconnector = initializeReconnector(this);
    attachConnectionStatusEventListeners();

    this.jsonProtocol = new JsonProtocol(config);
  }

  parseWithReconnect(message: IncomingMessage): void {
    this.reconnector.parseMessage(message);
  }

  parse(message: IncomingMessage): void {
    log(`parse incoming message: ${message.action}`, message.payload);

    websocketEventsHistoryManager.addWebSocketEvent({
      name: message.action,
      type: message.type,
      request_id: message.type === 'response' ? message.request_id : undefined,
      payload: message.payload,
    });

    if (message.type === 'response') {
      return;
    }

    handleLC3IncomingEvent(message.action, message.payload, message.version);

    // only messages with type === 'push'
    switch (message.action) {
      case LC3IncomingEvent.AgentDisconnected:
        this.agentDisconnected(message.payload);
        break;

      case LC3IncomingEvent.GroupCreated:
        this.groupCreated(message.payload);
        break;

      case LC3IncomingEvent.GroupUpdated:
        this.groupUpdated(message.payload);
        break;

      case LC3IncomingEvent.GroupDeleted:
        this.groupDeleted(message.payload);
        break;

      case LC3IncomingEvent.GroupsStatusUpdated:
        this.groupsStatusUpdated(message.payload);
        break;

      case LC3IncomingEvent.IncomingMulticast:
        this.incomingMulticast(message.payload);
        break;

      case LC3IncomingEvent.ThreadTagged:
        this.chatThreadTagged(message.payload);
        break;

      case LC3IncomingEvent.ThreadUntagged:
        this.chatThreadUntagged(message.payload);
        break;

      default:
        saveLog('info', `Platform protocol not supported: ${message.action}`);
        break;
    }
  }

  agentDisconnected(payload: IAgentDisconnectedPushEvent): void {
    store.dispatch(GlobalModalActions.hideAllModals());
    store.dispatch(OneViewActions.hideModal());

    switch (payload.reason) {
      case AgentDisconnectedReason.LicenseExpired:
        this.tryToReconnect(payload.reason, DEFAULT_RECONNECT_RETRY_COUNT, this.handleLicenseExpiredError);
        break;

      case AgentDisconnectedReason.LoggedOutRemotely:
        redirectToAccountsSignout(SignoutReason.SignedOutRemotely);
        break;

      case AgentDisconnectedReason.AgentDeleted:
        void removeSessionAndRedirectOut();
        break;

      case AgentDisconnectedReason.TooManyUnauthorizedConnections:
        this.tryToReconnect(payload.reason);
        break;

      case AgentDisconnectedReason.TooManyConnections:
        this.tryToReconnect(payload.reason);
        break;

      case AgentDisconnectedReason.AccessTokenExpired:
      case AgentDisconnectedReason.PingTimeout:
        redirectToAccounts();
        break;

      case AgentDisconnectedReason.LicenseVersionChanged:
        redirectToAccountsSignout(SignoutReason.LicenseVersionChanged);
        break;

      case AgentDisconnectedReason.MisdirectedConnection:
        this.handleMisdirectedConnectionError(payload);
        break;
      case AgentDisconnectedReason.MisdirectedRequest:
        if (payload.data && payload.data.region) {
          App.server.disconnect();
          updateDatacenter(this.config, payload.data.region);
          void App.loginWithCookies();
        }
        break;
      case AgentDisconnectedReason.AgentDisconnectedByServer:
      case AgentDisconnectedReason.ConnectionEvicted:
      case AgentDisconnectedReason.UnsupportedVersion:
      case AgentDisconnectedReason.LicenseNotFound:
      case AgentDisconnectedReason.AgentLoggedOutRemotely:
        this.closeConnectionAndStopSagas();
        redirectToSignOut(payload.reason);
        break;

      case AgentDisconnectedReason.InternalError:
        this.tryToReconnect(payload.reason);
        break;

      case AgentDisconnectedReason.ConnectionTimeout:
        this.tryToReconnect(payload.reason);
        break;

      case AgentDisconnectedReason.ServiceTemporarilyUnavailable:
        this.tryToReconnect(payload.reason);
        break;

      case AgentDisconnectedReason.AccessTokenRevoked:
      case AgentDisconnectedReason.RolePermissionChanged: {
        this.handlePermissionChanged(payload.reason);
        break;
      }

      default:
        this.handleUnknownReasonError(payload);
        break;
    }
  }

  groupCreated(payload): void {
    const deserializedGroup = deserializeGroup(payload);
    store.dispatch(GroupActions.groupAdded(deserializedGroup));

    if (Object.keys(deserializedGroup.agentsPriorities).length > 0) {
      Object.keys(deserializedGroup.agentsPriorities).forEach((login) => {
        store.dispatch(
          AgentActions.agentAddToGroup({
            login,
            groupId: deserializedGroup.id,
          })
        );
      });
    }
  }

  groupUpdated(payload): void {
    store.dispatch(GroupActions.groupUpdated(deserializePartialGroup(payload)));
  }

  groupDeleted(payload): void {
    store.dispatch(GroupActions.groupRemoved(deserializePartialGroup(payload)));
  }

  groupsStatusUpdated({ groups }: { groups: GroupRTMDTO[] }): void {
    groups.forEach((group) => {
      store.dispatch(GroupActions.groupUpdated(deserializePartialGroup(group)));
    });
  }

  incomingMulticast(payload: IncomingMulticastPayload): void {
    if (isMulticastLc2Payload(payload)) {
      this.incomingLc2Multicast(payload);
    } else if (isMulticastLc2IwcsPayload(payload)) {
      this.incomingLc2IwcsMulticast(payload);
    } else if (isMulticastBotEnginePayload(payload)) {
      this.incomingBotEngineMulticast(payload);
    } else if (isMulticastInsightsPayload(payload)) {
      this.incomingInsightsMulticast(payload);
    }
  }

  handleSuccessfulLogin(chatsSummary): void {
    this.parseUsersFromChatsSummary(chatsSummary);
  }

  parseUsersFromChatsSummary(chatsSummary): void {
    chatsSummary.forEach((chat) => {
      if (chat && chat.users) {
        store.dispatch(ChatsEntitiesActions.addChatUsers({ chatId: chat.id, chatUsers: chat.users }));
      }
    });
  }

  incomingLc2Multicast(payload: IIncomingMulticastLC2Payload): void {
    this.jsonProtocol.performCommand(payload.content);
  }

  incomingLc2IwcsMulticast(payload: IIncomingMulticastLc2IwcsPayload): void {
    switch (payload.content.command) {
      case IncomingMulticastIwcsCommand.AgentStatusUpdate:
        this.incomingAgentMulticast(payload.content.agent);
        break;
    }
  }

  incomingBotEngineMulticast(payload: IIncomingMulticastBotEnginePayload): void {
    if (payload.author_id !== getLoggedInAgentLogin(store.getState())) {
      return;
    }

    const login = payload.content.bot_engine.bot_id;
    const botModel = getBot(store.getState(), login);

    let botPayload = {
      name: 'ChatBot',
      avatarUrl: botEngineLogo,
    };

    if (botModel) {
      botPayload = {
        name: botModel.name,
        avatarUrl: botModel.avatar,
      };
    }

    if (payload.content.bot_engine.status === 'installed') {
      store.dispatch(GlobalModalActions.showModal(GlobalModal.ConnectChatbotSuccess));
    } else if (payload.content.bot_engine.status === 'enabled' && login) {
      store.dispatch(GlobalModalActions.showModal(GlobalModal.BotEngineEnabled, botPayload));
    } else if (payload.content.bot_engine.status === 'disabled' && login) {
      store.dispatch(GlobalModalActions.showModal(GlobalModal.BotEngineDisabled, botPayload));
    }
  }

  incomingInsightsMulticast(payload: IIncomingMulticastInsightsPayload): void {
    if (payload.author_id !== getLoggedInAgentLogin(store.getState())) {
      return;
    }
    const { recentInsights, topCustomerQuestions } = payload.content.insights;
    store.dispatch(BadgeActions.setBadge(BadgeKey.InsightsRecentInsights, recentInsights));
    store.dispatch(BadgeActions.setBadge(BadgeKey.InsightsTopCustomerQuestions, topCustomerQuestions));
  }

  incomingAgentMulticast(agent) {
    const deserializedAgent = partialDeserializeAgent(agent);
    const currentAgent = getLoggedInAgent(store.getState());
    store.dispatch(AgentActions.agentUpdate(deserializedAgent));

    if (
      !currentAgent ||
      currentAgent.login !== deserializedAgent.login ||
      currentAgent.status === deserializedAgent.status
    ) {
      return;
    }

    if (deserializedAgent.status === LoginStatus.Away) {
      store.dispatch(NotificationsBarActions.showNotificationsBar({ name: TopBarNotificationType.StatusAway }));
    } else {
      store.dispatch(NotificationsBarActions.hideNotificationsBar(TopBarNotificationType.StatusAway));
    }
  }

  handleArchivedChatThreadTagged(archivedThread: IArchive, tag: string): void {
    store.dispatch(
      ArchiveActions.updateArchiveTagsSuccess({
        archiveId: archivedThread.id,
        tags: [...archivedThread.tags, tag],
      })
    );
  }

  handleArchivedChatThreadUntagged(archivedThread: IArchive, tag: string): void {
    const updatedTags = archivedThread.tags.filter((singleTag) => singleTag !== tag);

    store.dispatch(
      ArchiveActions.updateArchiveTagsSuccess({
        archiveId: archivedThread.id,
        tags: updatedTags,
      })
    );
  }

  chatThreadTagged(payload: IThreadTaggedPushEvent): void {
    // Specific chat model finder for history thread model
    const { thread_id, tag } = payload;
    const archivedThread = getArchive(store.getState(), thread_id);

    if (archivedThread && !archivedThread?.tags.includes(tag)) {
      this.handleArchivedChatThreadTagged(archivedThread, tag);
    }
  }

  chatThreadUntagged(payload: IThreadUntaggedPushEvent): void {
    // Specific chat model finder for history thread model
    const { thread_id, tag } = payload;
    const archivedThread = getArchive(store.getState(), thread_id);

    if (archivedThread && archivedThread?.tags.includes(tag)) {
      this.handleArchivedChatThreadUntagged(archivedThread, tag);
    }
  }

  closeConnectionAndStopSagas(): void {
    App.server.close();
    stopAppSagas();
  }

  handleLicenseExpiredError(): void {
    const canManageSubscription = getCanManageSubscription(store.getState());

    if (canManageSubscription) {
      store.dispatch({ type: 'APP_ERROR' });
      redirectToSubscription();
    } else {
      redirectToLicenseExpired();
    }

    App.server.close();
  }

  tryToReconnect(
    reason: AgentDisconnectedReason,
    maxAttempts = DEFAULT_RECONNECT_RETRY_COUNT,
    onFail?: () => void
  ): void {
    // if "onFail" was not passed then manual reconnect button will be displayed
    // when reconnector failed to reconnect
    void this.reconnector.reconnect({ reason, maxAttempts, onFail });
  }

  handleMisdirectedConnectionError(payload: IAgentDisconnectedMisdirectedConnectionReasonPushEvent): void {
    updateDatacenter(this.config, payload.data.region);

    this.tryToReconnect(payload.reason, 3, () => {
      this.closeConnectionAndStopSagas();

      redirectToSignOut(payload.reason);
    });
  }

  handleUnknownReasonError(payload: IAgentDisconnectedPushEvent): void {
    Sentry.captureException(
      new Error(`lc3: agent disconnected with unknown reason (payload=${JSON.stringify(payload)})`)
    );

    this.closeConnectionAndStopSagas();

    redirectToSignOut(payload.reason);
  }

  handlePermissionChanged(reason: AgentDisconnectedReason): void {
    const { accountsClientId, accounts } = getConfig();
    const options = {
      client_id: accountsClientId,
      server_url: accounts,
    };

    this.closeConnectionAndStopSagas();

    const sdk = new AccountsSDK(options);
    sdk
      .iframe(options)
      .authorize()
      .then((response) => {
        storeCredentials({
          token: response.access_token,
          scopesString: response.scope,
          shouldSaveCookies: true,
          expiresIn: response.expires_in,
        });
        void connectAndLogin({
          accessToken: response.access_token,
          isGhost: false,
        });

        runAppSagas();
        if (reason === AgentDisconnectedReason.RolePermissionChanged) {
          showToast(ToastVariant.Info, ToastContent.YOU_HAVE_BEEN_RE_LOGGED);
        }
      })
      .catch(() => {
        // Resource owner identity is not known or consent is missing.
        redirectToAccounts();
      });
  }
}
