import { useEffect, useCallback, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { NavigationTooltipType } from 'constants/navigation';
import { PostMessageEvent } from 'constants/post-message-event';
import { EventPlace } from 'helpers/analytics';
import { getConfig } from 'helpers/config';
import { JSONParse } from 'helpers/json';
import { openInNewCard } from 'helpers/routing';
import { getMarketplaceDomain } from 'helpers/url';
import { updatePinnedFullscreenWidgets } from 'hooks/fullscreen-widgets/use-pinned-fullscreen-widget';
import { usePinnedFullscreenWidgetStorage } from 'hooks/fullscreen-widgets/use-pinned-fullscreen-widgets-storage';
import type { PostMessage } from 'interfaces/post-message';
import { trackEvent } from 'services/event-tracking';
import { ApplicationsActions } from 'store/entities/applications/actions';
import { LicenseCustomPropertiesActions } from 'store/entities/license-custom-properties/actions';
import { LicenseCustomPropertiesNames } from 'store/entities/license-custom-properties/interfaces';
import { ProductCartActions } from 'store/entities/product-cart/actions';
import { getIsSubscriptionInTrialAndNotSubscribed } from 'store/entities/subscription/selectors';
import { NavigationViewActions } from 'store/views/navigation/actions';

import { markGoogleAnalyticsForInstall } from './helpers';

type UseMarketplacePostMessages = {
  isMarketplaceReady: boolean;
};

export const useMarketplacePostMessages = (): UseMarketplacePostMessages => {
  const [isMarketplaceReady, setIsMarketplaceReady] = useState(false);
  const markAsReady = useCallback(() => setIsMarketplaceReady(true), [setIsMarketplaceReady]);
  const { data: pinnedWidgetIds, save } = usePinnedFullscreenWidgetStorage();

  const dispatch = useDispatch();
  const isNewLicense = useSelector(getIsSubscriptionInTrialAndNotSubscribed);

  const receivePostMessage = useCallback(
    (event: MessageEvent<string>): void => {
      if (event.origin !== getMarketplaceDomain(getConfig().devMarketplace)) {
        return;
      }

      const data = JSONParse<PostMessage>(event?.data);

      if (!data) {
        return;
      }

      switch (data.event_name) {
        case PostMessageEvent.AppInstalled: {
          // Refetch installed apps list
          dispatch(ApplicationsActions.refreshInstalledApplicationsData({}));

          if (data.event_data.app_name === getConfig().helpdeskAppId) {
            dispatch(
              LicenseCustomPropertiesActions.saveLicenseCustomProperty({
                name: LicenseCustomPropertiesNames.ShouldShowHelpDeskPromoTooltip,
                value: '1',
              })
            );
            dispatch(
              NavigationViewActions.showNavigationItemTooltip({
                itemId: getConfig().helpDeskAppWidgetId,
                type: NavigationTooltipType.HelpDeskPromo,
                kind: 'important',
              })
            );
          }
          break;
        }

        case PostMessageEvent.CartPurchased:
        case PostMessageEvent.AppPurchased:
        case PostMessageEvent.AppRemoved: {
          // Refetch installed apps list
          dispatch(ApplicationsActions.refreshInstalledApplicationsData({}));
          break;
        }

        case PostMessageEvent.AppEvent: {
          trackEvent(data.event_data.app_event_action, EventPlace.Application, data.event_data.app_event_options);
          break;
        }

        case PostMessageEvent.Loaded: {
          markAsReady();
          break;
        }

        case PostMessageEvent.MarketplaceCartUpdate: {
          const cart = data.event_data.cart;

          if (isNewLicense) {
            dispatch(
              ProductCartActions.saveCurrentCart({
                ...cart,
                products: cart.products?.map(markGoogleAnalyticsForInstall),
              })
            );
          } else {
            dispatch(ProductCartActions.saveCurrentCart(cart));
          }
          break;
        }

        case PostMessageEvent.TogglePinApp: {
          const appId = data.event_data.app_id;
          const updatedValue = updatePinnedFullscreenWidgets(pinnedWidgetIds, appId);
          save(updatedValue);

          break;
        }

        case PostMessageEvent.MailTo: {
          const { recipients } = data.event_data;
          openInNewCard(`mailto:${recipients}`);
          break;
        }
      }
    },
    [dispatch, markAsReady, isNewLicense, pinnedWidgetIds, save]
  );

  useEffect(() => {
    window.addEventListener('message', receivePostMessage);

    return () => window.removeEventListener('message', receivePostMessage);
  }, [receivePostMessage]);

  return { isMarketplaceReady };
};
