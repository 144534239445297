import type { FC } from 'react';

import { Heading, Text } from '@livechat/design-system-react-components';
import { useSelector } from 'react-redux';

import { calculateChargePrice } from 'routes/apps/one-click-payment/helpers';
import { useOneClickPaymentContext } from 'routes/apps/one-click-payment/hooks/use-one-click-payment-context';
import { getActiveAgentsCount } from 'store/entities/agents/selectors';

import { PriceFormatter } from '../PurchaseItem/PriceFormatter';

import * as styles from './styles';

export const BilledNow: FC = () => {
  const { charge } = useOneClickPaymentContext();
  const agentsCount = useSelector(getActiveAgentsCount);

  if (!charge) {
    return null;
  }

  const { totalPrice } = calculateChargePrice({
    agentsCount,
    charge,
  });

  return (
    <div className={styles.wrapper}>
      <div className={styles.price}>
        <Heading size="sm">Billed now</Heading>
        <Heading size="sm">
          <PriceFormatter value={totalPrice} frequency={charge.type} months={charge.months} />
        </Heading>
      </div>

      <Text size="sm">Secure payment will be processed using your card.</Text>
    </div>
  );
};
